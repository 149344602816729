import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  activationDigitMismatchSchema,
  userIsPendingScreeningSchema,
  getPhysicalCardSchema,
  getPhysicalCardsMigrationProgressSchema,
  putRecardOrdersSchema,
  putRecardOrdersSchemaResponse,
} from './physical-cards';
import { getEmployeeSfsCardSchema } from './physical-cards/get-employee-sfs-card';
import { getPhysicalCardsMigrationSchema } from './physical-cards/get-physical-cards-migration';
import { invalidShippingSchema } from './physical-cards/invalid-shipping-schema';
import { postReorderSfsCardSchema } from './physical-cards/post-reorder-sfs-card';
import { addressSchema } from './physical-cards/types/address';
import { mobileSchema } from './physical-cards/types/mobile';
import { shippingPreferenceSchema } from './physical-cards/types/recard-order-shipping-preference';
import { ForbiddenError, NotFoundError, ServerError } from '../common/errors';

export const cardsRouterContract = initContract().router({
  getPhysicalCards: {
    summary:
      'Get a list of physical cards with their user and recard order information',
    method: 'GET',
    path: '/physical-cards',
    responses: {
      200: z.object({
        data: z.array(getPhysicalCardSchema),
      }),
      ...ForbiddenError,
      ...ServerError,
    },
  },
  getEmployeeSfsCard: {
    summary: "Get an employee's SFS card",
    method: 'GET',
    path: '/sfs-employee-card',
    responses: {
      200: z.object({
        data: getEmployeeSfsCardSchema,
      }),
      ...ServerError,
    },
  },
  putRecardOrders: {
    summary: 'Update recard orders',
    method: 'PUT',
    path: '/physical-cards/recard-orders',
    body: putRecardOrdersSchema,
    responses: {
      200: z.object({
        data: putRecardOrdersSchemaResponse,
      }),
      400: invalidShippingSchema,
      ...ForbiddenError,
      ...ServerError,
    },
  },
  activation: {
    summary: 'Physical card activation',
    method: 'POST',
    path: '/physical-card/:cardId/activation',
    body: z.object({
      visibleNumber: z.string(),
    }),
    responses: {
      200: z.object({}),
      400: z.union([
        activationDigitMismatchSchema,
        userIsPendingScreeningSchema,
      ]),
      ...ServerError,
    },
  },
  finalize: {
    summary: 'Finalize recard orders',
    method: 'POST',
    path: '/physical-cards/recard-orders/finalize',
    body: null,
    responses: {
      200: null,
      409: z.union([
        z.object({ error: z.literal('someCardsMissingRecardOrder') }),
        z.object({
          error: z.literal('aoMissingNumberForCompanyShipping'),
          aoName: z.string(),
        }),
      ]),
      ...ForbiddenError,
      ...ServerError,
    },
  },
  getCompanyShippingAddress: {
    summary: 'Get the company shipping address',
    method: 'GET',
    path: '/company-shipping-address',
    responses: {
      200: z.object({
        data: addressSchema.merge(mobileSchema),
      }),
      404: z.object({
        errorCode: z.literal('ACCOUNT_OWNER_MISSING'),
      }),
      ...ForbiddenError,
      ...ServerError,
    },
  },
  putCompanyShippingAddress: {
    summary: 'Update the company shipping address',
    method: 'PUT',
    path: '/company-shipping-address',
    body: addressSchema,
    responses: {
      200: z.object({
        data: addressSchema,
      }),
      400: invalidShippingSchema,
      ...ForbiddenError,
      ...ServerError,
    },
  },
  getRecardOrderShippingPreference: {
    summary: 'Get the recard order shipping preference',
    method: 'GET',
    path: '/physical-cards/recard-orders/shipping-preference',
    responses: {
      200: z.object({
        preference: shippingPreferenceSchema,
      }),
      ...ForbiddenError,
      ...ServerError,
    },
  },
  putRecardOrderShippingPreference: {
    summary: 'Put the recard order shipping preference',
    method: 'PUT',
    path: '/physical-cards/recard-orders/shipping-preference',
    body: z.object({
      preference: shippingPreferenceSchema,
    }),
    responses: {
      200: null,
      ...ForbiddenError,
      ...ServerError,
    },
  },
  getPhysicalCardsMigration: {
    summary: "Get list of company's physical cards and their migration status",
    method: 'GET',
    path: '/physical-cards/migration',
    responses: {
      200: z.object({
        data: getPhysicalCardsMigrationSchema,
      }),
      ...ForbiddenError,
      ...NotFoundError,
      ...ServerError,
    },
  },
  getPhysicalCardsMigrationProgress: {
    summary:
      'Get the current migration progress for ordered, shipped and activated physical cards.',
    method: 'GET',
    path: '/physical-cards/migration-progress',
    responses: {
      200: z.object({
        progress: getPhysicalCardsMigrationProgressSchema,
      }),
      ...ForbiddenError,
      ...NotFoundError,
      ...ServerError,
    },
  },
  postReorderSfsCard: {
    summary: 'Reorder connected user SFS card',
    method: 'POST',
    path: '/reorder-sfs-card',
    body: postReorderSfsCardSchema,
    responses: {
      200: null,
      400: invalidShippingSchema,
      ...ForbiddenError,
      ...ServerError,
    },
  },
});
