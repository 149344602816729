import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCanConfirmBatch } from 'modules/invoices/transfer/hooks/useCanConfirmBatch';

type Props = {
  canApproveTransfers: boolean;
};

export const WithoutTransfersSelected = ({ canApproveTransfers }: Props) => {
  const canConfirmBatch = useCanConfirmBatch();
  const { t } = useTranslation('global');

  return (
    <section className="flex min-h-[77px] items-center justify-end">
      <div>
        <Button
          text={t('invoices.transfer.actions.cancelTransfer', {
            count: 0,
          })}
          variant="secondary"
          isDisabled
          className="mr-m"
        />
        {canApproveTransfers && canConfirmBatch && (
          <Button
            text={t('invoices.transfer.actions.confirmTransfer', {
              count: 0,
            })}
            variant="primary"
            isDisabled
          />
        )}
      </div>
    </section>
  );
};
