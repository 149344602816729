import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  bankableSubscriptionSchema,
  cancelCardAndRevealCardNumbersResponseSchema,
  getSubscriptionsMigrationProgressSchema,
  getSubscriptionsMigrationSchema,
  sfsSubscriptionSchema,
  subscriptionsBulkActionSchema,
  userIsPendingScreeningSchema,
} from './schemas';
import { ForbiddenError, ServerError } from '../../common/errors';

export const subscriptionsRouterContract = initContract().router({
  getSubscriptions: {
    summary: 'Get the current migration step with its subscriptions.',
    method: 'GET',
    path: '/subscriptions',
    responses: {
      200: z.union([
        z.object({
          // Step 1: Bankable subscriptions that are not already in progress in step 2
          step: z.literal(0),
          subscriptions: z.array(bankableSubscriptionSchema),
        }),
        z.object({
          // Step 2: SFS subscriptions only
          step: z.literal(1),
          subscriptions: z.array(sfsSubscriptionSchema),
        }),
      ]),
      ...ServerError,
    },
  },
  selectSubscriptions: {
    summary:
      'Mark in bulk bankable subscriptions as selected to be migrated to sfs subscriptions',
    method: 'POST',
    path: '/subscriptions/toggle-selected',
    body: z.array(
      z.object({
        id: z.string(),
        selected: z.boolean(),
      }),
    ),
    responses: {
      200: null,
      ...ServerError,
    },
  },
  postSubscriptions: {
    summary:
      'Post selected subscriptions to persist them, unselected ones are cancelled.',
    method: 'POST',
    path: '/subscriptions',
    body: subscriptionsBulkActionSchema,
    responses: {
      200: z.object({
        step: z.literal(1),
        subscriptions: z.array(sfsSubscriptionSchema),
      }),
      ...ServerError,
    },
  },
  cancelCardAndRevealCardNumbers: {
    summary: `Same output as GET /cards/:id/getCardSecret.
      Cancel former bankable card, generate new SFS card
      and get new card PAN link.
      If the card has already been migrated to SFS, only return card details.`,
    method: 'POST',
    path: '/subscriptions/:id/cancel-bkbl-and-reveal-card-numbers',
    body: null,
    responses: {
      200: cancelCardAndRevealCardNumbersResponseSchema,
      400: userIsPendingScreeningSchema,
    },
  },
  getSubscriptionsMigrationProgress: {
    summary: 'Get the current migration step with its subscriptions.',
    method: 'GET',
    path: '/subscriptions/migration-progress',
    responses: {
      200: getSubscriptionsMigrationProgressSchema,
      ...ForbiddenError,
      ...ServerError,
    },
  },
  getSubscriptionsMigration: {
    summary: 'Get the list of subscriptions to migrate and already migrated',
    method: 'GET',
    path: '/subscriptions/migration',
    responses: {
      200: getSubscriptionsMigrationSchema,
      ...ForbiddenError,
      ...ServerError,
    },
  },
});
