import { z } from 'zod';

import { addressSchema } from './address';
import { commonCardSchema } from './common-card';

// commonCardSchema is overridding the bankableCardSchema here, should not be an issue but if necessary we can do the merge the other way around
export const bankableCardSchema = z
  .object({
    lastUsedDate: z.string().nullable(),
    previousOrderAddress: z
      .object({
        recipient: z.string().nullable(),
      })
      .and(addressSchema)
      .nullable(),
  })
  .merge(commonCardSchema);
