import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { ErrorBoundary } from 'common/components/withErrorBoundary';

import { PerDiemRequestInterruptedProgressBox } from './PerDiemRequestInterruptedProgressBox';
import { PerDiemRequestOngoingProgressBox } from './PerDiemRequestOngoingProgressBox';
import { ProgressBoxErrorCallout } from '../../../components/ProgressBoxErrorCallout';
import { ProgressBoxLoadingSkeleton } from '../../../components/ProgressBoxLoadingSkeleton';
import { useProgressStepQuery } from '../../../hooks/useProgressStepQuery';
import { type LegacyRequest } from '../../../types';

type Props = {
  className?: string;
  request: LegacyRequest;
};

export const PerDiemRequestProgressBox = ({ request, ...rest }: Props) => {
  if (request.deletedAt) {
    return null;
  }
  return <PerDiemProgressBoxContent request={request} {...rest} />;
};

const PerDiemProgressBoxContent = ({ request, ...rest }: Props) => {
  const progressStepState = useProgressStepQuery(request.id);

  return (
    <QuerySuspense
      queryState={progressStepState}
      loading={<ProgressBoxLoadingSkeleton />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          className="mb-xxs mt-s"
          logContext={{
            team: 'capture',
          }}
        />
      )}
    >
      {({ progressStep }) =>
        progressStep === 'interrupted' ? (
          <PerDiemRequestInterruptedProgressBox request={request} {...rest} />
        ) : (
          <ErrorBoundary
            context={{ team: 'capture', scope: 'requests::panel::progress' }}
            fallbackComponent={<ProgressBoxErrorCallout />}
          >
            <PerDiemRequestOngoingProgressBox
              key={request.id}
              request={request}
              progressStep={progressStep}
              {...rest}
            />
          </ErrorBoundary>
        )
      }
    </QuerySuspense>
  );
};
