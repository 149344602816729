import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

export type Role = {
  id: string;
  companyId: string;
  name: 'Account owner' | 'Requester' | 'Administrator' | 'Controller';
  order: number;
  fullPermissions: boolean;
  organisationId: string;
  parentsId: string[];
  parentId: string;
  scopeId: string;
  scope: {
    entityType: 'company' | string;
    entityId: string;
  };
  /** @todo what's the shape of it? */
  userPermissions: RawPermissions;
};

export type RawRole = {
  id: string;
  organisation_id: string;
  company_id: string;
  parent_id: string;
  scope_id: string;
  name: 'Account owner' | 'Requester' | 'Administrator' | 'Controller';
  order: number;
  full_permissions: boolean;
  scope: {
    entity_type: string;
    entity_id: string;
  };
  permissions: RawPermissions;
  user_permissions: RawPermissions;
  parents_id: string[];
};

export type RawPermissions = {
  id: string;
  name: string | null;
  code: string;
  allow: boolean;
  is_default: boolean;
  is_user_custom: boolean;
  nb_users?: number;
  users: never[];
  scope_id: string;
  role_id: string;
  company_id: string | null;
  organisation_id: string | null;
  params: {
    approval_needed: 'always' | 'sometimes' | 'never';
    spending_limit: number;
    spending_types: string;
    transaction_max: number;
  };
}[];

export const translateRoleName = (
  roleName: 'Administrator' | 'Controller' | 'Requester' | 'Account owner',
  translator: TGlobalFunctionTyped,
): string => {
  switch (roleName) {
    case 'Administrator':
      return translator('misc.administrator');
    case 'Controller':
      return translator('misc.controller');
    case 'Requester':
      return translator('misc.requester');
    case 'Account owner':
      return translator('misc.accountOwner');
    default:
      return roleName;
  }
};
