import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import type { AppDispatch } from 'modules/app/redux/store';
import type {
  CostCenter,
  CostCenterId,
  CostCenterWithApprovalRules,
} from 'modules/budgets/models/costCenter';
import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import {
  normalizeRules,
  removeAoRule,
  shouldRemoveAoRule,
} from 'modules/company/structure/approval-flows/models';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { staleTime } from './constants';
import { getCostCentersQueryKey } from './query-key-registry';
import { fetchCostCenters } from '../../company/members/redux/actions';
import { useIsCostCenterFeatureEnabled } from '../features';

export type CostCentersResponse = {
  id: CostCenterId;
  name: string;
  isDeleted?: boolean;
  ownerId: string;
  viewerIds: string[];
  approvalRules: ApprovalRuleApi[];
  users?: { userId: string; selectionMode: 'default' | 'suggested' }[];
}[];

const getRequestParams = (params: Params = {}) =>
  ({
    type: 'rest',
    target: 'companyAPI',
    endpoint: '/cost-centers',
    method: 'get',
    params,
  }) as const;

type Params = {
  withDeleted?: boolean;
  includeUsers?: boolean;
};

export function useCostCentersQuery(
  params: Params = {},
): QueryState<CostCenter[], unknown> {
  const companyId = useCompanyId();
  const isCostCentersEnabled = useIsCostCenterFeatureEnabled();

  const query = useQuery<CostCenter[]>({
    key: getCostCentersQueryKey(companyId, params),
    request: getRequestParams(params),
    reshapeData,
    isEnabled: isCostCentersEnabled,
    options: {
      staleTime,
    },
  });

  if (!isCostCentersEnabled) {
    return { status: 'success', data: [] };
  }

  return query;
}

export type CostCenterByMemberId = Record<
  string,
  { id: string; name: string; selectionMode: 'default' | 'suggested' }
>;

export const useCostCenterMembersQuery =
  (): QueryState<CostCenterByMemberId> => {
    const companyId = useCompanyId();
    const params = { includeUsers: true };

    return useQuery<CostCenterByMemberId, CostCentersResponse>({
      key: getCostCentersQueryKey(companyId, params),
      request: getRequestParams(params),
      options: {
        cacheTime: 10 * 60 * 1000, // 10min,
        staleTime: 10 * 60 * 1000, // 10min,
      },
      reshapeData: (costCenters) => {
        const membersCostCenter: CostCenterByMemberId = {};

        for (const costCenter of costCenters) {
          for (const data of costCenter.users ?? []) {
            membersCostCenter[data.userId] = {
              id: costCenter.id,
              name: costCenter.name,
              selectionMode: data.selectionMode,
            };
          }
        }

        return membersCostCenter;
      },
    });
  };

export const useCostCentersQueryPromise = (params: Params = {}) => {
  const companyId = useCompanyId();
  const queryClient = useQueryClient();
  const isCostCentersEnabled = useIsCostCenterFeatureEnabled();

  const costCentersFetcher = () =>
    getFetcher<CostCentersResponse>({
      companyId,
      getRequest: () => getRequestParams(params),
    });

  return async (): Promise<CostCenterWithApprovalRules[]> => {
    if (!isCostCentersEnabled) {
      return [];
    }

    const costCenters = await queryClient.fetchQuery(
      getCostCentersQueryKey(companyId, params),
      costCentersFetcher(),
      {
        staleTime,
      },
    );

    return reshapeData(costCenters);
  };
};

export const useInvalidateCostCentersQuery = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();
  const dispatch = useDispatch<AppDispatch>();

  return () => {
    queryClient.invalidateQueries(getCostCentersQueryKey(companyId));
    dispatch(fetchCostCenters());
  };
};

/**
 * Helpers
 */

const reshapeData = (
  data: CostCentersResponse,
): CostCenterWithApprovalRules[] => {
  return data.map((costCenter) => {
    const apiApprovalRules = costCenter.approvalRules ?? [];
    const approvalRules = shouldRemoveAoRule({ rules: apiApprovalRules })
      ? removeAoRule({ rules: apiApprovalRules }).rules
      : apiApprovalRules;
    const normalizedRules = normalizeRules(approvalRules);
    return {
      id: costCenter.id,
      name: costCenter.name,
      ownerId: costCenter.ownerId,
      viewerIds: costCenter.viewerIds,
      approvalRules: normalizedRules.map((normalizedRule) => ({
        ...normalizedRule,
        id: uuid(),
      })),
      isDeleted: costCenter.isDeleted,
    };
  });
};
