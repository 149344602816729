import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';

import { NavigableProgressBox } from 'common/components/ProgressBox';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import {
  ProgressBoxAppraisalsList,
  ProgressBoxApproversList,
} from 'modules/approval';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import type { ProgressSteps } from '../../../../hooks/useProgressStepQuery';
import { getRequestValidationDate } from '../../../../models';
import { type LegacyRequest } from '../../../../types';

type Props = {
  className?: string;
  request: LegacyRequest;
  progressStep: ProgressSteps;
};

export const PerDiemRequestOngoingProgressBox = ({
  className,
  request,
  progressStep,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const user = useUser();

  return (
    <NavigableProgressBox
      className={className}
      title={getProgressBoxTitleFromRequest(request, t, progressStep)}
      activeStep={progressStep}
      steps={[
        {
          key: 'approval',
          name: t('requests.progressBox.approvalStep.name'),
        },
        {
          key: 'finance-review',
          name: t('requests.progressBox.perDiem.financeReviewStep.name'),
        },
        {
          key: 'payment',
          name: t('requests.progressBox.perDiem.paymentStep.name'),
        },
      ]}
      content={
        // eslint-disable-next-line sonarjs/cognitive-complexity
        (clickedStep, isActiveStep) => {
          switch (clickedStep) {
            case 'approval': {
              if (request.autoApproved) {
                return t(
                  'requests.progressBox.approvalStep.autoApprovedContent',
                );
              }
              if (isActiveStep) {
                return <ProgressBoxApproversList requestId={request.id} />;
              }
              return (
                <>
                  {t('requests.progressBox.approvalStep.contentAppraisal')}
                  <ProgressBoxAppraisalsList requestId={request.id} />
                </>
              );
            }
            case 'finance-review': {
              if (isActiveStep) {
                if (request.autoApproved) {
                  return t(
                    'requests.progressBox.perDiem.financeReviewStep.autoApprovedContent',
                  );
                }
                return user.id === request.userId
                  ? t('requests.progressBox.perDiem.financeReviewStep.content')
                  : t(
                      'requests.progressBox.perDiem.financeReviewStep.contentThis',
                    );
              }
              const validationDate = getRequestValidationDate(request);

              return t(
                'requests.progressBox.perDiem.financeReviewStep.contentComplete',
                {
                  date: validationDate
                    ? localeFormat(validationDate, DATE_FORMAT.LONG_WITH_TIME)
                    : '',
                },
              );
            }
            case 'payment':
              return request.userId === user.id
                ? t('requests.progressBox.perDiem.paymentStep.requesterContent')
                : t('requests.progressBox.perDiem.paymentStep.approverContent');
            case 'interrupted':
            case 'receipt':
              throw new Error(
                `unexpected progress step for per diem request ${clickedStep}`,
              );
            default:
              rejectUnexpectedValue(
                `PerDiemRequestOngoingProgressBox (content props)`,
                clickedStep,
              );
          }
        }
      }
    />
  );
};

const getProgressBoxTitleFromRequest = (
  request: LegacyRequest,
  t: TGlobalFunctionTyped,
  progressStep: ProgressSteps,
): string => {
  switch (progressStep) {
    case 'approval': {
      if (request.directApprover) {
        return t('requests.progressBox.approvalStep.titleApprover');
      }
      return t('requests.progressBox.approvalStep.title');
    }
    case 'finance-review':
      return t('requests.progressBox.perDiem.financeReviewStep.title');
    case 'payment':
      return t('requests.progressBox.perDiem.paymentStep.title');
    case 'interrupted':
    case 'receipt':
      throw new Error(
        `unexpected progress step for per diem request ${progressStep}`,
      );
    default:
      rejectUnexpectedValue(
        `PerDiemRequestOngoingProgressBox (getProgressBoxTitleFromRequest)`,
        progressStep,
      );
  }
};
