import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { RestEndpointsQueryApi } from 'modules/bookkeep/apis';
import { getFetcher } from 'src/core/api/fetcher';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type GraphQlQueryRequest } from 'src/core/api/query';
import { type QueryState } from 'src/core/api/queryState';
import {
  getPayableQueryKey,
  getToPreparePayableListQueryKey,
  type PayableId,
} from 'src/core/modules/payable';

import { reshapePayable, type RawPayable } from './graphql-reshaper';
import { GET_PAYABLE } from './queries';
import {
  reshapePayableFromRestApi,
  type PayableFromRestApi,
} from './rest-reshaper';
import {
  payableVersionWhenPartiallyLoaded,
  type Payable,
} from '../../../prepare-payables/models';

/**
 * Query and cache config
 */

export type PayableGraphQLResponse = {
  payable: RawPayable;
};

export type PayableGraphQlVariables = {
  payableId: PayableId;
};

const getGraphQlQueryConfig = (
  payableId: PayableId | null,
): GraphQlQueryRequest => {
  const variables: PayableGraphQlVariables = { payableId: payableId ?? '' };
  return {
    type: 'graphQL',
    target: 'v2',
    query: GET_PAYABLE,
    variables,
  };
};

export const useInvalidateGetPayableQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    payableId: PayableId | null,
  ): Promise<void> => {
    await Promise.all([
      queryClient.invalidateQueries(getPayableQueryKey(companyId, payableId)),
      // Cached payable details in Prepare
      queryClient.invalidateQueries(getToPreparePayableListQueryKey(companyId)),
    ]);
  };
};

export const useUpdateGetPayableQueryCache = () => {
  const queryClient = useQueryClient();

  return (
    companyId: string,
    payableId: PayableId | null,
    payableUpdate: Partial<RawPayable>,
  ) => {
    const payable: PayableFromRestApi | RawPayable | undefined =
      queryClient.getQueryData<PayableGraphQLResponse>(
        getPayableQueryKey(companyId, payableId),
      )?.payable;

    // Skip "partially loaded" update if we have a "fully loaded" cached payable
    if (
      payableUpdate?.version === payableVersionWhenPartiallyLoaded &&
      (payable?.version ?? -1) > payableVersionWhenPartiallyLoaded
    ) {
      return;
    }

    // Skip other update if we don't have a cached payable or if the update has not a higher version
    if (
      (payable?.version ?? Number.POSITIVE_INFINITY) >
      (payableUpdate?.version ?? 0)
    ) {
      return;
    }

    if (payableId) {
      queryClient.setQueryData<PayableGraphQLResponse>(
        getPayableQueryKey(companyId, payableId),
        {
          payable: {
            id: payableId,
            version: payableVersionWhenPartiallyLoaded,
            ...payable,
            ...payableUpdate,
          } as RawPayable,
        },
      );
    }

    // TODO@financeAccountant@CORE-4887 update cache for REST
  };
};

/**
 * GraphQL query hook
 */

export const useGetPayableQuery = (
  payableId: null | PayableId,
): QueryState<Payable | undefined> => {
  const companyId = useCompanyId();
  const shouldUseRestEndpoint =
    RestEndpointsQueryApi.useRestEndpoints()?.getPayable;

  return useQuery<Payable | undefined, PayableGraphQLResponse>({
    key: getPayableQueryKey(companyId, payableId),
    isEnabled: Boolean(payableId) && !shouldUseRestEndpoint,
    request: getGraphQlQueryConfig(payableId),
    reshapeData: (data) => reshapePayable(data.payable),
  });
};

/**
 * REST query hook
 */

export type PayableRestResponse = PayableFromRestApi;
export type PayableRestParams = { payableId: PayableId };

export function useGetPayableRestQuery(
  payableId: string | null,
): QueryState<Payable | undefined> {
  const companyId = useCompanyId();
  const shouldUseRestEndpoint =
    RestEndpointsQueryApi.useRestEndpoints()?.getPayable;

  return useQuery<Payable | undefined, PayableRestResponse>({
    key: getPayableQueryKey(companyId, payableId, 'rest'),
    isEnabled: Boolean(payableId) && shouldUseRestEndpoint,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payable/${payableId}`,
    },
    reshapeData: (data) => reshapePayableFromRestApi(data),
  });
}

/**
 * Standalone GraphQL fetcher
 */

export const getPayableQueryFetcher = ({
  companyId,
  payableId,
}: {
  companyId: string;
  payableId: string;
}): (() => Promise<PayableGraphQLResponse>) =>
  getFetcher<PayableGraphQLResponse>({
    companyId,
    getRequest: () => getGraphQlQueryConfig(payableId),
  });
