import { createSelector } from '@reduxjs/toolkit';

import { getCustomFieldsActivationPendingKey } from 'src/core/constants/storage';

const getFeatureActivation = (state) => state.categories.featureActivation;

export const getIsFeatureActivationLoading = (state) =>
  getFeatureActivation(state).isLoading;

export const getIsFeatureActivationRequested = createSelector(
  (state) => getFeatureActivation(state).isRequested,
  ({ global: { company } }) => company.id,
  (isFeatureActivationRequested, companyId) => {
    const localStorageKey = getCustomFieldsActivationPendingKey(companyId);

    return (
      isFeatureActivationRequested ||
      Boolean(localStorage.getItem(localStorageKey))
    );
  },
);
