import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type OrganisationReportingEntityBreakdown } from '../../../types';
import {
  BreakdownGauge,
  type BreakdownGaugeBar,
  BreakdownGaugeLegend,
} from '../BreakdownGauge';

export type EntityBreakdownBlockProps = {
  currency: string;
  breakdown: OrganisationReportingEntityBreakdown;
};

export const EntityBreakdownBlock = ({
  currency,
  breakdown,
}: EntityBreakdownBlockProps) => {
  const { t } = useTranslation('global');
  const nilMonetaryValue = {
    amount: 0,
    currency,
    precision: 2,
  }; // PMPTMP: check usefulness

  const formattedBreakdown: BreakdownGaugeBar[] = [
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.label',
      ),
      value: breakdown.scheduledTransfers,
      valueLabel:
        formatMonetaryValue({
          amount: breakdown.scheduledTransfers,
          currency: currency,
          precision: 2,
        }) ?? nilMonetaryValue,
      valueLabelColor:
        (breakdown.scheduledTransfers ?? 0) > 0 ? null : 'neutral', // PMPTMP: not great
      valueLabelTooltip: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.tooltip',
      ),
      type: 'full' as const,
    },
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.label',
      ),
      value: breakdown.cardsLimits,
      valueLabel:
        formatMonetaryValue({
          amount: breakdown.cardsLimits,
          currency: currency,
          precision: 2,
        }) ?? nilMonetaryValue,
      valueLabelColor: (breakdown.cardsLimits ?? 0) > 0 ? null : 'neutral', // PMPTMP: not great
      valueLabelTooltip: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.tooltip',
      ),
      type: 'striped' as const,
    },
    breakdown.trend !== 'warning'
      ? {
          name: t(
            'organisation.reporting.page.entities.entity.breakdown.available',
          ),
          value: breakdown.available,
          valueLabel:
            formatMonetaryValue({
              amount: breakdown.available ?? 0, // PMPTMP: not great
              currency: currency,
              precision: 2,
            }) ?? nilMonetaryValue,
          valueLabelColor:
            (breakdown.available ?? 0) > 0 ? 'positive' : 'neutral', // PMPTMP: not great
          type: 'empty' as const,
        }
      : null,
    breakdown.trend === 'warning' && {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.shortfall',
      ),
      value: breakdown.shortfall,
      valueLabel:
        formatMonetaryValue({
          amount: breakdown.shortfall ?? 0, // PMPTMP: not great
          currency: currency,
          precision: 2,
        }) ?? nilMonetaryValue,
      valueLabelColor: 'warning',
      type: 'empty' as const,
      hideBar: true,
    },
  ].filter((c) => c) as BreakdownGaugeBar[]; // PMPTMP: typings

  return (
    <div>
      <BreakdownGauge bars={formattedBreakdown} trend={breakdown.trend}>
        {(preparedBars) => <BreakdownGaugeLegend preparedBars={preparedBars} />}
      </BreakdownGauge>
    </div>
  );
};
