import { HighlightIcon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { ReactComponent as Orange } from './assets/orange.svg';
import { ReactComponent as Red } from './assets/red.svg';

import './PbrRuleTimeline.css';

type Props = {
  content: {
    mainTitle?: string | React.ReactElement;
    title1?: string | React.ReactElement;
    desc1?: string | React.ReactElement;
    title2?: string | React.ReactElement;
    desc2?: string | React.ReactElement;
    title3?: string | React.ReactElement;
    desc3?: string | React.ReactElement;
    title4?: string | React.ReactElement;
    desc4?: string | React.ReactElement;
    title5?: string | React.ReactElement;
    desc5?: string | React.ReactElement;
  };
  className?: string;
};

export const PbrRuleTimeline = ({ content, className }: Props) => {
  return (
    <div className={cx('PbrRuleTimeline', className)}>
      {content.mainTitle && (
        <div className="mb-m text-complementary title-m">
          {content.mainTitle}
        </div>
      )}
      <div className="PbrRuleTimeline__desc">
        <div className="PbrRuleTimeline__desc__row">
          <div className="PbrRuleTimeline__desc__row__icon">
            <HighlightIcon name="plastic-card" variant="info" size="m" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--lock" />
          </div>
          <div className="PbrRuleTimeline__desc__row__text title-m">
            {content.title1}
            <br />
            <span className="text-neutral-dark body-m">{content.desc1}</span>
          </div>
        </div>
        <div className="PbrRuleTimeline__desc__row">
          <div className="PbrRuleTimeline__desc__row__icon">
            <Orange />

            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--up PbrRuleTimeline__desc__row__icon__line--lock" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--orange" />
          </div>
          <div className="PbrRuleTimeline__desc__row__text title-m">
            {content.title2}
            <br />
            <span className="text-neutral-dark body-m">{content.desc2}</span>
          </div>
        </div>
        <div className="PbrRuleTimeline__desc__row">
          <div className="PbrRuleTimeline__desc__row__icon">
            <Red />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--up PbrRuleTimeline__desc__row__icon__line--orange" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--red" />
          </div>
          <div className="PbrRuleTimeline__desc__row__text title-m">
            {content.title3}
            <br />
            <span className="text-neutral-dark body-m">{content.desc3}</span>
          </div>
        </div>
        <div className="PbrRuleTimeline__desc__row">
          <div className="PbrRuleTimeline__desc__row__icon">
            <HighlightIcon name="lock" variant="alert" size="m" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--up PbrRuleTimeline__desc__row__icon__line--red" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--payment" />
          </div>
          <div className="PbrRuleTimeline__desc__row__text title-m">
            {content.title4}
            <br />
            <span className="text-neutral-dark body-m">{content.desc4}</span>
          </div>
        </div>
        <div className="PbrRuleTimeline__desc__row">
          <div className="PbrRuleTimeline__desc__row__icon">
            <HighlightIcon name="card-unlock" variant="info" size="m" />
            <div className="PbrRuleTimeline__desc__row__icon__line PbrRuleTimeline__desc__row__icon__line--up PbrRuleTimeline__desc__row__icon__line--lock" />
          </div>
          <div className="PbrRuleTimeline__desc__row__text title-m">
            {content.title5}
            <br />
            <span className="text-neutral-dark body-m">{content.desc5}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
