import { Button, colors } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { BASE_MIGRATION_PATH as KYC_BASE_MIGRATION_PATH } from 'src/kyc/common/constants/routes';
import { useTasksSummary } from 'src/sfs-migration/used-in-js-app/hooks/useTasksSummary';

import Illustration from './illustration.svg';
import { type Tasks, useTasksQuery } from '../../hooks/useTasksQuery';

export const KycNeedsAction = withErrorBoundary(
  {
    team: 'sfs-migration',
    scope: 'sfs-migration',
  },
  () => <div />,
)(() => {
  const { t } = useTranslation('sfs-migration');
  const company = useCompany();
  const user = useUser();
  const [isDisplayed, setIsDisplayed] = useState(false);
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const sfsTasksSummaryQueryState = useTasksSummary({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const isUserAnAdmin = user.is_admin || user.is_account_owner;
  const tasksQueryState = useTasksQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const sfsTasksSummary = unwrapQuery(sfsTasksSummaryQueryState);
  const tasks = unwrapQuery(tasksQueryState);
  const kycLink =
    (tasks?.data?.migrationInitiation?.tasks ?? []).find(
      (task) => task.type === 'ReviewKyc',
    )?.dotfile?.onboardingUrl ?? `${KYC_BASE_MIGRATION_PATH}/${company.id}`;

  const companyKycReviewNeedsAction = Boolean(
    sfsTasksSummary?.data.companies.find((c) => c.companyId === company.id)
      ?.kycReviewNeedsAction,
  );
  const companyKycReviewNotStarted = getCompanyKycReviewNotStarted(
    tasks?.data?.migrationInitiation?.tasks ?? [],
  );

  useEffect(() => {
    if (
      (companyKycReviewNeedsAction || companyKycReviewNotStarted) &&
      isUserAnAdmin
    ) {
      setIsDisplayed(true);
    }
  }, [companyKycReviewNeedsAction, companyKycReviewNotStarted]);

  if (sfsTasksSummaryQueryState.status !== 'success') {
    return null;
  }

  if (!isDisplayed) {
    return false;
  }

  return createPortal(
    <div className="fixed inset-0 left-0 top-0 z-[9001] flex h-full w-full overflow-hidden bg-page-background">
      <div
        className="flex w-[610px] flex-col"
        style={{ background: '#FAFAFC' }}
      >
        <div
          className="my-l flex items-center justify-between gap-xs self-start"
          style={{
            marginLeft: '72px',
          }}
        >
          <img height="20" src="/static/img/logo.svg" alt={t('spendeskAlt')} />
          <div className="h-full border border-solid border-primary bg-primary" />
          <p className="text-primary">{t('header.title')}</p>
        </div>
        <div
          className="flex grow flex-col justify-center"
          style={{ margin: '0 72px', maxWidth: '482px' }}
        >
          <div
            className="mb-m text-complementary title-xxl"
            style={{ lineHeight: '40px', letterSpacing: '-1px' }}
          >
            {companyKycReviewNeedsAction
              ? t('kycActionNeeded.title')
              : t('kycNotStartedBanner.title')}
          </div>
          <div className="text-neutral-dark body-l">
            {companyKycReviewNeedsAction
              ? t('kycActionNeeded.description')
              : t('kycNotStartedBanner.description')}
          </div>
          <div className="mt-l flex gap-s">
            <Button
              variant="secondary"
              text={t('kycActionNeeded.actions.close')}
              onClick={() => {
                setIsDisplayed(false);
              }}
            />
            {companyKycReviewNeedsAction ? (
              <Button
                text={t('kycActionNeeded.actions.upload')}
                onClick={() => {
                  window.location.href = kycLink;
                }}
              />
            ) : (
              <Button
                text={t('kycNotStartedBanner.actions.start')}
                variant="alert"
                onClick={() => {
                  window.location.href = `${KYC_BASE_MIGRATION_PATH}/${company.id}`;
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        style={{ background: colors.alertLightest }}
        className="flex grow items-center justify-center"
      >
        <img src={Illustration} alt="Illustration" />
      </div>
    </div>,
    document.body,
  );
});

const getCompanyKycReviewNotStarted = (
  tasks: Tasks['data']['migrationInitiation']['tasks'],
) => {
  const isKycInTodo = tasks.some(
    (task) => task.type === 'ReviewKyc' && task.status === 'ToDo',
  );
  const isValidateCardOrderCompleted = tasks.some(
    (task) => task.type === 'ValidateCardOrder' && task.status === 'Completed',
  );
  const isAcceptTermsCompleted = tasks.some(
    (task) =>
      task.type === 'AcceptTermsAndConditions' && task.status === 'Completed',
  );

  return isKycInTodo && isValidateCardOrderCompleted && isAcceptTermsCompleted;
};
