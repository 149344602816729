import { compareDesc } from 'date-fns';
import queryString from 'query-string';
import { type QueryKey, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import type {
  InvoiceRequest,
  InvoiceRequestData,
} from '@finance-review/models/invoice';
import type { AppDispatch } from 'modules/app/redux/store';
import { type SupplierId } from 'modules/suppliers';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type AppState } from 'src/core/reducers';

import { fetchInvoicesCounts } from '../../../../invoices/redux/thunks';
import type { RawInvoiceData } from '../transformers';
import { transformInvoiceData } from '../transformers';
import { updateInvoiceRequestQueryData } from '../useFetchDraftInvoiceRequest';

export type InvoiceQueryFilters = {
  status?: InvoiceRequest.Status[] | InvoiceRequest.Status;
  supplier?: SupplierId;
  search?: string;
  with_applied_credit_notes?: boolean;
};

/**
 * TODO@financeOps: migrate list to not use rawData
 *
 * @deprecated
 */
export const useInvoiceReviewListQuery = (
  filters: InvoiceQueryFilters,
): QueryState<RawInvoiceData[]> => {
  const qs = queryString.stringify(filters);
  const queryKey = getQueryKey(filters);

  return useQuery<RawInvoiceData[], RawInvoiceData[]>({
    key: queryKey,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_requests?${qs}`,
    },
    options: {
      staleTime: 5000,
    },
    reshapeData: (invoices) =>
      invoices.sort((invoiceA, invoiceB) =>
        invoiceA.invoice_request.emission_date &&
        invoiceB.invoice_request.emission_date
          ? compareDesc(
              new Date(invoiceA.invoice_request.emission_date),
              new Date(invoiceA.invoice_request.emission_date),
            )
          : -1,
      ),
  });
};

export const useInvoiceReviewListQueryV2 = (
  filters: InvoiceQueryFilters,
): QueryState<InvoiceRequestData[]> => {
  const qs = queryString.stringify(filters);
  const queryKey = getQueryKey(filters);

  return useQuery<InvoiceRequestData[], RawInvoiceData[]>({
    key: queryKey,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_requests?${qs}`,
    },
    options: {
      staleTime: 5000,
      onSuccess: ({ rawData, client }): void => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        rawData.map((rawData) =>
          updateInvoiceRequestQueryData(client, rawData),
        );
      },
    },
    reshapeData: invoiceListReshaper,
  });
};

const invoiceListReshaper = (
  rawInvoices: RawInvoiceData[],
): InvoiceRequestData[] => {
  const sortedRawInvoices = rawInvoices.sort((invoiceA, invoiceB) =>
    invoiceA.invoice_request.emission_date &&
    invoiceB.invoice_request.emission_date
      ? compareDesc(
          new Date(invoiceA.invoice_request.emission_date),
          new Date(invoiceA.invoice_request.emission_date),
        )
      : -1,
  );

  return sortedRawInvoices.map(transformInvoiceData);
};

export const useInvalidateInvoiceReviewListQuery =
  (): (() => Promise<void>) => {
    const queryClient = useQueryClient();

    return async (): Promise<void> => {
      await queryClient.invalidateQueries<RawInvoiceData[]>([
        'invoice-requests',
      ]);
    };
  };

export const useUpdateInvoiceCacheEntry = () => {
  const filters = useSelector((state: AppState) => state.invoices.filters);
  const queryClient = useQueryClient();

  return (rawInvoice: RawInvoiceData): void => {
    const queryKey = getQueryKey(filters);

    const previousInvoices =
      queryClient.getQueryData<RawInvoiceData[]>(queryKey);
    const invoiceToUpdateIndex = previousInvoices?.findIndex(
      (previousInvoice) => previousInvoice.id === rawInvoice.id,
    );

    if (previousInvoices && invoiceToUpdateIndex !== undefined) {
      const newInvoices = [
        ...previousInvoices.slice(0, invoiceToUpdateIndex),
        rawInvoice,
        ...previousInvoices.slice(invoiceToUpdateIndex + 1),
      ];
      queryClient.setQueryData(queryKey, newInvoices);
    }
  };
};

export const useRemoveInvoicesCacheEntries = () => {
  const dispatch = useDispatch<AppDispatch>();
  const filters = useSelector((state: AppState) => state.invoices.filters);
  const queryClient = useQueryClient();

  return (invoiceIds: InvoiceRequest.EntityId[]): void => {
    const queryKey = getQueryKey(filters);

    const previousInvoices =
      queryClient.getQueryData<RawInvoiceData[]>(queryKey);
    const filteredInvoices = previousInvoices?.filter(
      (invoice: RawInvoiceData) => !invoiceIds.includes(invoice.id),
    );

    queryClient.setQueryData(queryKey, filteredInvoices);
    // TODO@financeOps: manually update the invoice to schedule count if paid with wire transfer
    // + migrate count to useQuery
    // cf https://spendesk.atlassian.net/browse/PAY-4623
    // sometimes the back-end is not updated yet when we fetch the counts after a request validation
    // setting a timeout to improve chances of getting the last count data
    setTimeout(() => dispatch(fetchInvoicesCounts()), 500);
  };
};

const getQueryKey = (filters: InvoiceQueryFilters): QueryKey => {
  const qs = queryString.stringify(filters, { arrayFormat: 'index' });
  return ['invoice-requests', qs];
};
