import { Button, Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { type Token, TokenInput } from 'common/components/TokenInput';
import { useTranslation } from 'common/hooks/useTranslation';
import { openSupportChat } from 'src/core/utils/supportChat';

import './ModalMFATokenContent.scss';

interface Props {
  hasRegeneratedMfaProcedure: boolean;
  isMfaTokenVerified: boolean;
  isMfaTokenValid: boolean;
  token: Token;
  tokenLength: number;
  handleRegenerateProcedure: () => void;
  handleTokenChange: (token: Token) => void;
}

export const ModalMFATokenContent = ({
  hasRegeneratedMfaProcedure,
  isMfaTokenVerified,
  isMfaTokenValid,
  token,
  tokenLength,
  handleTokenChange,
  handleRegenerateProcedure,
}: Props) => {
  const { t } = useTranslation('global');

  const renderActions = () => {
    if (hasRegeneratedMfaProcedure) {
      // Mfa token has been regenerated but still not received
      return (
        <Button
          variant="ghost"
          text={`${t('mfa.modal.code.noCodeContactUs')} ${t(
            'mfa.modal.code.noCodeContactUsAction',
          )}`}
          onClick={() => openSupportChat()}
          hasNegativeMargins
        />
      );
    }
    if (!isMfaTokenVerified) {
      return (
        <Button
          variant="ghost"
          text={`${t('mfa.modal.code.noCode')} ${t(
            'mfa.modal.code.noCodeAction',
          )}`}
          onClick={handleRegenerateProcedure}
          hasNegativeMargins
        />
      );
    }
    if (isMfaTokenVerified && !isMfaTokenValid) {
      return (
        <Button
          variant="ghost"
          text={t('mfa.modal.code.noCodeWrong')}
          onClick={handleRegenerateProcedure}
          hasNegativeMargins
        />
      );
    }
  };

  return (
    <form id="mfa-form" onSubmit={(e) => e?.preventDefault()}>
      <p className="ModalMFAToken__desc">{t('mfa.modal.code.desc')}</p>
      <div className="ModalMFAToken__code-input">
        <TokenInput
          token={token}
          tokenLength={tokenLength}
          onTokenChange={handleTokenChange}
          hasError={isMfaTokenVerified && !isMfaTokenValid}
        />
      </div>

      {hasRegeneratedMfaProcedure && (
        <Callout
          title={t('mfa.modal.code.newCode')}
          variant="success"
          className="ModalMFAToken__code-feedback"
        />
      )}

      {isMfaTokenVerified &&
        !isMfaTokenValid &&
        !hasRegeneratedMfaProcedure && (
          <Callout
            title={t('mfa.modal.code.wrong')}
            variant="alert"
            className="ModalMFAToken__code-feedback"
          />
        )}

      <div className="ModalMFAToken__messages">{renderActions()}</div>
    </form>
  );
};
