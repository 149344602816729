import { type IconName } from '@dev-spendesk/grapes';
import React, { type ReactNode, useState } from 'react';

import { AnalyticEventName, track } from "src/core/utils/analytics";

import { ProgressBox } from './ProgressBox';
import { type Step } from './ProgressBoxBar';
import { useTranslation } from '../../hooks/useTranslation';

type Props<T extends string> = {
  title: ReactNode;
  content: (stepKey: T, isActiveStep: boolean) => ReactNode;
  className?: string;
  iconName?: IconName;
  steps:
    | [
        {
          key: T;
          name: string;
        },
      ]
    | {
        key: T;
        name: string;
      }[];
  activeStep: T;
  children?: React.ReactNode;
};

export const NavigableProgressBox = function <T extends string>({
  title,
  content,
  children,
  className,
  iconName = 'clock-full',
  steps,
  activeStep: activeStepName,
}: Props<T>) {
  const { t } = useTranslation('global');
  const activeIndex = steps.findIndex((step) => step.key === activeStepName);
  const activeStep = steps[activeIndex];
  const [visu, setVisu] = useState(activeStep.key);
  const clickedIndex = steps.findIndex((step) => step.key === visu);

  return (
    <ProgressBox
      title={title}
      iconName={iconName}
      content={content(visu, visu === activeStep.key)}
      className={className}
      variant="neutral"
      steps={steps?.map((step, index) => {
        // Navigation is possible for 'active' & 'completed' steps
        // Special case: There's no navigation if the first step is the 'active' one (no completed)
        const isNavigable =
          index <= activeIndex && activeIndex !== 0 && index !== clickedIndex;
        return {
          name: step.name,
          info: isNavigable
            ? t('requests.progressBox.clickTooltip')
            : undefined,
          onClick: isNavigable
            ? () => {
                setVisu(step.key);
                track(AnalyticEventName.REQUEST_PROGRESS_BOX_NAVIGATION_USED, {
                  step: step.key,
                });
              }
            : undefined,
          isClicked: index === clickedIndex,
          status: extractStepStatus(index, activeIndex),
        };
      })}
    >
      {children}
    </ProgressBox>
  );
};

const extractStepStatus = (
  index: number,
  activeIndex: number,
): Step['status'] => {
  if (index === activeIndex) {
    return 'active';
  }
  if (index > activeIndex) {
    return 'upcoming';
  }
  return 'completed';
};
