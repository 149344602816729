import cx from 'classnames';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';

import './LegacySubscriptionItem.scss';

const LegacySubscriptionItem = ({ base, extra, isAddon }) => {
  return (
    <li
      className={cx('LegacySubscriptionItem', {
        'LegacySubscriptionItem--addon': isAddon,
      })}
    >
      <div className="LegacySubscriptionItem__content">
        <span className="LegacySubscriptionItem__base">{base}</span>
        <ul className="LegacySubscriptionItem__extra">
          {map(extra, (extraItem) => (
            <li key={extraItem} className="LegacySubscriptionItem__extra-item">
              {extraItem}
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

LegacySubscriptionItem.propTypes = {
  base: PropTypes.string.isRequired,
  extra: PropTypes.arrayOf(PropTypes.string.isRequired),
  isAddon: PropTypes.bool,
};

LegacySubscriptionItem.defaultProps = {
  extra: [],
  isAddon: false,
};

export default LegacySubscriptionItem;
