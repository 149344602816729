import { Icon, Link, Tooltip } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export const SpanishDPRCheckmarkDocumentaryEvidenceViewer = ({
  state,
}: {
  state: 'ongoing' | 'done' | 'not-applicable';
}) => {
  const { t } = useTranslation('global');

  const isDone = state === 'done';
  const isOngoing = state === 'ongoing';

  const iconName = isDone ? 'success' : 'clock-full';
  const iconClassName = isDone ? 'text-success' : 'text-neutral-darker';

  return (
    <Tooltip
      content={
        isOngoing
          ? t('payables.spanishDPR.ongoingTooltip')
          : t('payables.spanishDPR.doneTooltip')
      }
      placement="top"
    >
      <div
        className={cx(
          'flex flex-row items-center rounded-b px-s py-xxs text-neutral-darker body-m',
        )}
      >
        <Icon
          name={iconName}
          size="s"
          className={cx('mr-xxs', iconClassName)}
        />

        <span className="mr-xxs">
          {state === 'ongoing'
            ? t('payables.spanishDPR.ongoingTitle')
            : t('payables.spanishDPR.doneTitle')}
        </span>

        <Link
          className="text-neutral-darker body-m"
          href={t('payables.spanishDPR.readMoreLink')}
          isExternal
        >
          {t('payables.spanishDPR.readMoreCta')}
        </Link>
      </div>
    </Tooltip>
  );
};
