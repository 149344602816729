import { type MonetaryValue } from 'ezmoney';
import React from 'react';
import { useSelector } from 'react-redux';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useWalletTransactionForecastQuery } from 'modules/company';
import { type AuthType } from 'modules/invoices/transfer';
import { getShowWarningWhenNotEnoughFundsForWireTransfer } from 'modules/reimbursements/redux/selectors';

import WireTransferApproverModal from './WireTransferApproverModal';

type Props = {
  amount: MonetaryValue;
  count: number;
  isApproving: boolean;
  isOpen: boolean;
  approvalAuthType: AuthType | null;
  onApprove(): void;
  onClose(): void;
  onComplete(): void;
};

export const WireTransferApproverModalContainer = ({
  amount,
  count,
  isApproving,
  isOpen,
  approvalAuthType,
  onApprove,
  onClose,
  onComplete,
}: Props) => {
  const showWarningWhenNotEnoughFundsForWireTransfer = useSelector(
    getShowWarningWhenNotEnoughFundsForWireTransfer,
  );
  const walletTransactionForecastQuery =
    useWalletTransactionForecastQuery()(amount);

  return (
    <QuerySuspense
      queryState={walletTransactionForecastQuery}
      loading={null}
      fallback={() => (
        // In case of error in the query, we fallback to the 'normal' flow (without not enough funds warning)
        // Since it shouldn't be blocking for the wire transfer flow
        <WireTransferApproverModal
          amount={amount}
          count={count}
          isApproving={isApproving}
          showNotEnoughFundsWarning={false}
          approvalAuthType={approvalAuthType}
          isOpen={isOpen}
          onClose={onClose}
          onApprove={onApprove}
          onComplete={onComplete}
        />
      )}
    >
      {({ hasEnoughFunds }) => {
        const showNotEnoughFundsWarning =
          showWarningWhenNotEnoughFundsForWireTransfer && !hasEnoughFunds;

        return (
          <WireTransferApproverModal
            amount={amount}
            count={count}
            isApproving={isApproving}
            showNotEnoughFundsWarning={showNotEnoughFundsWarning}
            approvalAuthType={approvalAuthType}
            isOpen={isOpen}
            onClose={onClose}
            onApprove={onApprove}
            onComplete={onComplete}
          />
        );
      }}
    </QuerySuspense>
  );
};
