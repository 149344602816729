import {
  Button,
  ListBox,
  Skeleton,
  SkeletonAvatar,
  SkeletonCheckbox,
  SkeletonTable,
} from '@dev-spendesk/grapes';
import { isValid } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { PageNoResult as PageNoResultContainer } from 'common/components/PageNoResult/PageNoResultContainer';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routeFor, routes } from 'src/core/constants/routes';

import { PaymentGroup } from './PaymentGroup';
import { PaymentListRow } from './PaymentListRow';
import { PaymentsListHeader } from './PaymentsListHeader';
import { type Selection } from './types';
import { type MonthStats } from '../../graphql/allPayments/stats';
import { type Payment } from '../all/paymentType';

const BATCH_SIZE = 60;

type Props = {
  isLoading?: boolean;
  payments?: Payment[];
  bulkEditPayments?: Payment[];
  activePayment?: string;
  paymentStats: MonthStats[];
  pageInfo?: {
    hasNextPage: boolean;
    endCursor: string;
  };
  selection: Selection;
  counters?: {
    remindable_for_invoice: number;
    downloadable: number;
    editable: number;
  };
  bulkActions?: {
    download?: { processing: boolean };
    edit?: { processing: boolean };
    remindInvoices?: { processing: boolean };
    markAsMissing?: { processing: boolean };
  };
  isSupervisionActive: boolean;
  hasFilters: boolean;
  fetchPayments: (options: { first?: number; after?: string }) => void;
  bulkEdit: (arguments_: { selection: Selection }) => void;
  download: (arguments_: {
    selection: Selection;
    withReceipts: boolean;
  }) => void;
  remindInvoices: (arguments_: { selection: Selection }) => void;
  bulkMarkAsMissing: (arguments_: {
    selection: Selection;
  }) => Promise<{ nbPaymentsMarked: number }>;
  updateSelection: (ids: {
    all?: boolean;
    include?: string[];
    exclude?: string[];
  }) => void;
};

export const PaymentsList = ({
  isLoading,
  payments,
  bulkEditPayments,
  activePayment,
  paymentStats,
  pageInfo,
  selection,
  counters,
  bulkActions,
  isSupervisionActive,
  hasFilters,
  fetchPayments,
  bulkEdit,
  download,
  remindInvoices,
  bulkMarkAsMissing,
  updateSelection,
}: Props) => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();
  const history = useHistory();

  if (isLoading && (!payments || payments.length === 0)) {
    return (
      <div
        className="flex h-full w-full flex-col bg-page-background"
        data-testid="payment-list-loading"
      >
        <SkeletonTable
          numberOfRows={14}
          columns={[
            {
              cell: (
                <div className="flex w-full items-center justify-between">
                  <div className="flex grow items-center">
                    <SkeletonCheckbox className="mr-s" />
                    <SkeletonAvatar
                      size="m"
                      variant="square"
                      className="mr-s"
                    />
                    <Skeleton height="var(--sizing-l)" width="60%" />
                  </div>
                  <Skeleton height="var(--sizing-l)" width="150px" />
                </div>
              ),
              header: (
                <div className="flex h-[52px] w-full items-center justify-between">
                  <SkeletonCheckbox className="mr-s" />
                </div>
              ),
            },
          ]}
          withHeader
        />
      </div>
    );
  }

  if (!payments || payments.length === 0) {
    return (
      <PageNoResultContainer mode="payment" hasFiltersApplied={hasFilters} />
    );
  }

  return (
    <ListBox
      header={
        <PaymentsListHeader
          selection={selection}
          counters={counters}
          bulkActions={bulkActions}
          bulkEdit={bulkEdit}
          download={download}
          remindInvoices={remindInvoices}
          bulkMarkAsMissing={bulkMarkAsMissing}
          isSupervisionActive={isSupervisionActive}
        />
      }
      getOptionId={(option) => option.databaseId}
      options={payments}
      groupBy={(option) => {
        const paidAt = option.paid_at ? new Date(option.paid_at) : null;
        return paidAt && isValid(paidAt)
          ? `${paidAt.getUTCFullYear()}-${paidAt.getUTCMonth()}`
          : 'none';
      }}
      checkedOptionIds={
        selection.all
          ? payments
              .map(({ databaseId }) => databaseId)
              .filter((databaseId) => !selection.exclude.includes(databaseId))
          : selection.include
      }
      onOptionChange={(_, id, isChecked) => {
        const changes = isChecked ? { include: [id] } : { exclude: [id] };
        return updateSelection(changes);
      }}
      onAllOptionsChange={(_1, _2, isChecked) => {
        updateSelection({ all: isChecked });
      }}
      onOptionClick={(option) => {
        history.push({
          pathname: routeFor(routes.PAYMENTS_ALL.path, {
            company: companyId,
            id: option.databaseId,
          }),
          search: history.location?.search,
        });
      }}
      getIsOptionCheckable={() => !bulkEditPayments}
      getIsOptionActive={(option) => option.databaseId === activePayment}
      renderGroupedOptionsHeader={(value) => {
        const paymentStat = paymentStats.find((stat) => {
          if (value === 'none') {
            return !stat.year;
          }
          return `${stat.year}-${stat.month}` === value;
        });
        if (!paymentStat) {
          return null;
        }
        return <PaymentGroup paymentStat={paymentStat} />;
      }}
      footer={
        pageInfo?.hasNextPage && (
          <div className="mx-auto my-xs">
            <Button
              variant="secondary"
              text={t('misc.loadMore')}
              isLoading={isLoading}
              onClick={async () => {
                await fetchPayments({
                  first: BATCH_SIZE,
                  after: pageInfo.endCursor,
                });
              }}
            />
          </div>
        )
      }
    >
      {(option, titleId) => (
        <PaymentListRow payment={option} titleId={titleId} />
      )}
    </ListBox>
  );
};
