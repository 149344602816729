import { Button, Link, Modal } from '@dev-spendesk/grapes';
import { Trans, useTranslation } from 'react-i18next';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
  trackingLink: string;
};

export const ReorderSFSContinueModal = ({
  isOpen,
  onClose,
  onNext,
  trackingLink,
}: Props) => {
  const { t } = useTranslation('sfs-migration');
  return (
    <Modal
      iconVariant="warning"
      iconName="warning"
      isOpen={isOpen}
      title={t('phase-execution.cardShipment.continueModalTitle')}
      onClose={onClose}
      actions={
        <>
          <Button
            variant="secondary"
            text={t('misc.cancel')}
            onClick={onClose}
          />
          <Button
            variant="primary"
            text={t('misc.continue')}
            onClick={onNext}
          />
        </>
      }
    >
      {trackingLink ? (
        <Trans
          i18nKey="phase-execution.cardShipment.continueModalSubtitle"
          components={{
            a: (
              <Link
                href={trackingLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  track(AnalyticEventName.SFS_MIGRATION_TRACKING_LINK_CLICKED, {
                    origin: 'card_page',
                  });
                }}
              >
                -
              </Link>
            ),
          }}
          ns="sfs-migration"
        />
      ) : (
        t('phase-execution.cardShipment.continueModalSubtitleWithoutTracking')
      )}
    </Modal>
  );
};
