import { type TagVariant } from '@dev-spendesk/grapes';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import * as Money from 'ezmoney';

import {
  type ExportMethod,
  PaymentMethod,
  type PaymentMethodsByIssuer,
} from 'modules/company';

import {
  type ScheduledPaymentUser,
  type ScheduledPayment,
  PaymentInfoStatus,
} from './types';

export const canAccessExpenseClaimsTab = (
  isExpenseClaimsFeatureEnabled: boolean,
  isAccountOwner: boolean,
  isController: boolean,
  isControlOnSpendingMethodEnabled: boolean,
) => {
  if (isControlOnSpendingMethodEnabled || isExpenseClaimsFeatureEnabled) {
    return isAccountOwner || isController;
  }

  return false;
};

export const canAccessExpenseClaimsConfirmTab = (
  isWireTransferForExpenseClaimsFeatureEnabled: boolean,
  isAccountOwner: boolean,
  hasWtConfirmationDelegation: boolean,
) =>
  isWireTransferForExpenseClaimsFeatureEnabled &&
  (isAccountOwner || hasWtConfirmationDelegation);

export const isCsvOnlyPaymentMethod = (
  paymentMethodsByIssuer: PaymentMethodsByIssuer | undefined,
) => {
  if (!paymentMethodsByIssuer) {
    return true; // Csv is by default the only available payment method
  }
  const fromSpendeskMethods = paymentMethodsByIssuer.fromSpendesk || [];
  const fromBankMethods = paymentMethodsByIssuer.fromBank || [];

  return (
    fromSpendeskMethods.length === 0 &&
    fromBankMethods.length === 1 &&
    fromBankMethods[0] === PaymentMethod.Csv
  );
};

export const filterScheduledPaymentsByIds = (
  scheduledPayments: ScheduledPayment[],
  idsToFilter: string[],
) => {
  return scheduledPayments.filter((scheduledPayment) =>
    idsToFilter.includes(scheduledPayment.id),
  );
};

export const getScheduledPaymentsAmount = (
  scheduledPayments: ScheduledPayment[],
): Money.MonetaryValue | null => {
  if (scheduledPayments.length === 0) {
    return null;
  }

  const amounts = scheduledPayments.map(
    (scheduledPayment) => scheduledPayment.amount,
  );
  return amounts.reduce((accumulator, amount) =>
    Money.add(accumulator, amount),
  );
};

export const getScheduledPaymentsUsers = (
  scheduledPayments: ScheduledPayment[],
): ScheduledPaymentUser[] => {
  return scheduledPayments.reduce(
    (
      accumulator: ScheduledPaymentUser[],
      scheduledPayment: ScheduledPayment,
    ) => {
      const { user } = scheduledPayment;
      if (!accumulator.some((u) => u.id === user.id)) {
        return [...accumulator, user];
      }
      return accumulator;
    },
    [],
  );
};

class UnkownPaymentInfoStatusError extends Error {
  constructor(paymentInfoStatus: never) {
    super(`Unknown PaymentInfoStatus: ${paymentInfoStatus}`);
  }
}

export const getTranslationKeyForPaymentInfoStatus = (
  status: PaymentInfoStatus,
) => {
  switch (status) {
    case PaymentInfoStatus.Pending:
      return 'expenseClaims.paymentInfoStatus.pending';
    case PaymentInfoStatus.Approved:
      return 'expenseClaims.paymentInfoStatus.approved';
    case PaymentInfoStatus.Declined:
      return 'expenseClaims.paymentInfoStatus.declined';
    case PaymentInfoStatus.Cancelled:
      return 'expenseClaims.paymentInfoStatus.cancelled';
    case PaymentInfoStatus.PendingBanking:
      return 'expenseClaims.paymentInfoStatus.pending_banking';
    case PaymentInfoStatus.FailedBanking:
      return 'expenseClaims.paymentInfoStatus.failed_banking';
    case PaymentInfoStatus.Paid:
      return 'expenseClaims.paymentInfoStatus.paid';
    case PaymentInfoStatus.Exported:
      return 'expenseClaims.paymentInfoStatus.exported';
    default:
      throw new UnkownPaymentInfoStatusError(status);
  }
};

export const getVariantForPaymentInfoStatus = (
  status: PaymentInfoStatus,
): TagVariant => {
  switch (status) {
    case PaymentInfoStatus.Pending:
      return 'info';
    case PaymentInfoStatus.Approved:
      return 'info';
    case PaymentInfoStatus.Declined:
      return 'alert';
    case PaymentInfoStatus.Cancelled:
      return 'neutral';
    case PaymentInfoStatus.PendingBanking:
      return 'neutral';
    case PaymentInfoStatus.FailedBanking:
      return 'alert';
    case PaymentInfoStatus.Paid:
      return 'success';
    case PaymentInfoStatus.Exported:
      return 'success';
    default:
      throw new UnkownPaymentInfoStatusError(status);
  }
};

export const getDescriptionTranslationKeyForPaymentMethod = (
  paymentMethod: PaymentMethod,
) => {
  switch (paymentMethod) {
    case PaymentMethod.Csv:
      return 'expenseClaims.history.paidFromBankCsv';
    case PaymentMethod.XmlSepa:
      return 'expenseClaims.history.paidFromBankXml';
    case PaymentMethod.WireTransfer:
      return 'expenseClaims.history.paidFromSpendesk';
    default:
      return paymentMethod;
  }
};

export const getRedownloadTranslationKeyForExportMethod = (
  exportMethod: ExportMethod,
) => {
  switch (exportMethod) {
    case PaymentMethod.Csv:
      return 'expenseClaims.history.redownloadTransferFileCsv';
    case PaymentMethod.XmlSepa:
      return 'expenseClaims.history.redownloadTransferFileXml';
    default:
      return exportMethod;
  }
};

export const formatExecutionDate = (date: Date): string => {
  return format(startOfDay(date), 'yyyy-MM-dd');
};
