import { createAction } from '@reduxjs/toolkit';

import * as types from 'src/core/actionTypes/exports';
import { companyAPI } from 'src/core/api/axios';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

// Fetch custom exports
const fetchCustomExportsLoading = createAction(
  types.FETCH_CUSTOM_EXPORTS_LOADING,
);
const fetchCustomExportsFailure = createAction(
  types.FETCH_CUSTOM_EXPORTS_FAILURE,
);
const fetchCustomExportsSuccess = createAction(
  types.FETCH_CUSTOM_EXPORTS_SUCCESS,
);
export const fetchCustomExports = () => async (dispatch, getState) => {
  const companyId = getCompanyId(getState());

  dispatch(fetchCustomExportsLoading());

  let res;

  try {
    res = await companyAPI.get(`/exports/settings`, {
      companyId,
    });
  } catch (error) {
    dispatch(fetchCustomExportsFailure());
    throw error;
  }

  const { data } = res;

  dispatch(
    fetchCustomExportsSuccess({
      exports: data.exports,
      customFieldColumnId: data.customFieldColumnId,
      customContentColumnId: data.customContentColumnId,
    }),
  );
};
