import { Label } from '@dev-spendesk/grapes';
import { toNumber } from 'ezmoney';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type CurrenciesKey } from 'src/core/config/money';
import { type PurchaseOrderRequestItem } from 'src/core/modules/requests/models';
import { displayMoney } from 'src/core/utils/money';

type Props = {
  items: PurchaseOrderRequestItem[];
};

export const PurchaseOrderItemizationList = ({ items }: Props) => {
  const { t } = useTranslation('global');
  return (
    <div className="flex flex-col items-center gap-xs">
      <Label
        label={t('requests.panel.purchaseOrder.itemsTitle')}
        className="self-start"
      />
      {items.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="flex w-full items-center gap-xs" key={index}>
          <div className="min-w-0 flex-1 text-neutral-dark">
            <EllipsisTooltip text={`${item.quantity}x ${item.name}`} />
          </div>
          <div className="ml-auto text-complementary">
            {displayMoney({
              value: item.quantity * toNumber(item.unitPrice),
              currency: item.unitPrice.currency as CurrenciesKey,
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
