import { z } from 'zod';

import { allRecardOrderStateSchema } from './recard-order-state';
import { shippingAddressWithPhoneNumberSchema } from './shipping-address';

export const recardOrderSchema = z.object({
  id: z.string(),
  userId: z.string(),
  bkbCardId: z.string(),
  state: allRecardOrderStateSchema,
  sfsCardId: z.string().nullable(),
  shippingAddress: shippingAddressWithPhoneNumberSchema.nullable(),
  trackingLink: z.string().nullable(),
  trackingNumber: z.string().nullable(),
});
