import { type ClientInferResponses } from '@ts-rest/core';
import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

type Result = ClientInferResponses<
  typeof sfsMigrationContract.cards.getEmployeeSfsCard,
  200
>['body']['data'];

type Response = ClientInferResponses<
  typeof sfsMigrationContract.cards.getEmployeeSfsCard,
  200
>['body'];

const key = ['sfs-migration', 'sfs-employee-card'];

export const useEmployeeCardQuery = ({
  isEnabled = false,
}: {
  isEnabled: boolean;
}): QueryState<Result> => {
  return useQuery<Result, Response>({
    key,
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/sfs-migration/sfs-employee-card',
    },
    reshapeData(card) {
      return card.data;
    },
  });
};

export const useInvalidateEmployeeCardQuery = () => {
  const queryClient = useQueryClient();
  return async () => {
    await queryClient.invalidateQueries(key);
  };
};
