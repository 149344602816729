import {
  Autocomplete,
  Avatar,
  Button,
  FormField,
  Icon,
  Modal,
} from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type EmployeeAccountCode } from 'modules/bookkeep';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { userToString } from 'modules/bookkeep/settings/accounting/utils';
import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useDebouncedState } from 'src/core/common/hooks/useDebounceState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';

import { type ModalState } from './ModalState';
import { useGetEmployeeAccountCodesQuery } from '../../../../../hooks/useGetEmployeeAccountCodesQuery';
import { useGetUsersWithoutEmployeeAccountQuery } from '../../../../../hooks/useGetUsersWithoutEmployeeAccountQuery';
import { useSetAccountPayableToEmployeeMutation } from '../../../../../hooks/useSetAccountPayableToEmployeeMutation';
import styles from '../EmployeeAccountsSection.module.css';
import { getDefaultEmployeeAccountPlaceholderI18nKey } from '../translations';

interface Props {
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
  integrationStatus: IntegrationStatusWithIntegration;
}

export const EmployeeAccountAddModal = ({
  integrationStatus,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useDebouncedState<string>('', 300);
  const getEmployeeAccountsQueryState = useGetEmployeeAccountCodesQuery({
    includeArchived: false,
    search,
  });

  const options =
    getEmployeeAccountsQueryState.status === 'success'
      ? getEmployeeAccountsQueryState.data.map(
          (employeeAccount: EmployeeAccountCode) => ({
            key: employeeAccount.id,
            label: getCodeWithAuxiliaryAccounts(employeeAccount),
          }),
        )
      : [];
  const [setAccountPayableToEmployeeMutation] =
    useSetAccountPayableToEmployeeMutation();

  async function handleSetAccountPayableToEmployee({
    memberId,
    accountPayableId,
  }: {
    memberId: string;
    accountPayableId: string;
  }) {
    // await refreshData();
    return setAccountPayableToEmployeeMutation({
      accountPayableId,
      memberId,
    });
  }
  const { pushNotif } = useNotifications();
  const usersWithoutEmployeeAccountQuery =
    useGetUsersWithoutEmployeeAccountQuery();

  const users =
    usersWithoutEmployeeAccountQuery.status === 'success'
      ? usersWithoutEmployeeAccountQuery.data
      : [];

  const userOptions = users.map((user) => ({
    key: user.id,
    label: userToString(user),
  }));

  const userById = new Map(users.map((user) => [user.id, user]));

  return (
    <Modal
      data-testid="employee-account-add-modal"
      title={t('bookkeep.integrations.settings.employeeAccountsTable.addTitle')}
      isOpen={modalState.kind === 'add'}
      iconVariant="primary"
      iconName="plus"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          data-testid="employee-account-save"
          key="saveChanges"
          onClick={() => {
            if (
              modalState.kind === 'add' &&
              modalState.employeeAccount.user?.id &&
              modalState.employeeAccount.id
            ) {
              // eslint-disable-next-line @spendesk/must-use-result
              handleSetAccountPayableToEmployee({
                memberId: modalState.employeeAccount.user.id,
                accountPayableId: modalState.employeeAccount.id,
              });
              setModalState({ kind: 'closed' });
              pushNotif({
                type: NotificationType.Success,
                message: t(
                  'bookkeep.integrations.settings.employeeAccountsTable.assignEmployeeAccountSuccess',
                ),
              });
            }
          }}
          text={t('misc.saveChanges')}
          variant="primary"
          isDisabled={
            modalState.kind !== 'add' ||
            !modalState.employeeAccount.user ||
            !modalState.employeeAccount.id
          }
        />,
      ]}
    >
      <div>
        {modalState.kind === 'add' && (
          <>
            <FormField
              label={t(
                'bookkeep.integrations.settings.employeeAccountsTable.employeeLabel',
              )}
            >
              <AutocompleteSearch
                data-testid="employee-account-select"
                fit="parent"
                placeholder={t(
                  'bookkeep.integrations.settings.employeeAccountsTable.autocomplatePlaceholder',
                )}
                options={userOptions}
                isLoading={
                  usersWithoutEmployeeAccountQuery.status === 'loading'
                }
                value={
                  modalState.employeeAccount.user
                    ? {
                        key: modalState.employeeAccount.user.id,
                        label: userToString(modalState.employeeAccount.user),
                      }
                    : undefined
                }
                renderPrefix={(option) =>
                  option ? (
                    <Avatar
                      size="s"
                      className={styles.avatar}
                      // @ts-expect-error
                      text={userToString(userById.get(option.key))}
                    />
                  ) : (
                    <Icon size="l" name="team" />
                  )
                }
                onSelect={(option) => {
                  setModalState({
                    ...modalState,
                    employeeAccount: {
                      ...modalState.employeeAccount,
                      user: option ? userById.get(option.key) : undefined,
                    },
                  });
                }}
                renderNoOptions={() => <div>No user found</div>}
              />
            </FormField>

            <div className={styles.defaultForm__select}>
              <FormField
                label={t('bookkeep.integrations.netsuite.accountPayableHeader')}
                className={styles.defaultForm__select}
              >
                <Autocomplete
                  fit="parent"
                  placeholder={t(
                    getDefaultEmployeeAccountPlaceholderI18nKey(
                      integrationStatus.integration,
                    ),
                  )}
                  isLoading={getEmployeeAccountsQueryState.status === 'loading'}
                  value={getSelectValue(modalState)}
                  options={options}
                  onSearch={(keyword) => setSearch(keyword ?? '')}
                  onSelect={(accountPayable) => {
                    if (accountPayable) {
                      setModalState({
                        ...modalState,
                        employeeAccount: {
                          id: accountPayable.key,
                          generalAccountCode: accountPayable.label,
                          user: modalState.employeeAccount.user,
                        },
                      });
                    }
                  }}
                  renderNoOptions={(rawValue) => (
                    <AutocompleteNoOptions value={rawValue} />
                  )}
                />
              </FormField>
              <div className={styles.defaultForm__input} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const getSelectValue = (modalState: ModalState) => {
  if (
    modalState.kind !== 'add' ||
    !modalState.employeeAccount ||
    !modalState.employeeAccount.id ||
    !modalState.employeeAccount.generalAccountCode
  ) {
    return undefined;
  }

  return {
    key: modalState.employeeAccount.id,
    label: modalState.employeeAccount.generalAccountCode,
  };
};
