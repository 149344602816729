import { sanitizeBankInfo } from 'src/core/utils/bankInfoFormats';

import {
  type Supplier,
  type BankInfoOcrResult,
  type UpdatableSupplierDetails,
  type ApiSupplier,
  type ApiSupplierDetails,
  type ApiBankInfoOcrResult,
} from './supplier';

const reshapeBankInfoOcrResult = (
  bankInfoOcrResultAPI: ApiBankInfoOcrResult,
): BankInfoOcrResult => {
  return {
    text: bankInfoOcrResultAPI.text,
    fileUrl: bankInfoOcrResultAPI.file,
    width: bankInfoOcrResultAPI.width,
    height: bankInfoOcrResultAPI.height,
    marginTop: bankInfoOcrResultAPI.marginTop,
    marginBottom: bankInfoOcrResultAPI.marginBottom,
    marginLeft: bankInfoOcrResultAPI.marginLeft,
    marginRight: bankInfoOcrResultAPI.marginRight,
  };
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const reshapeSupplier = (supplierAPI: ApiSupplier): Supplier => {
  return {
    id: supplierAPI.id,
    name: supplierAPI.name,
    supplierAccountCode: supplierAPI.supplierAccountCode || null,
    details: {
      accountHolderName:
        supplierAPI.supplier_details?.account_holder_name ?? null,
      legalName: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.legal_name
        : null,
      registrationNumber: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.registration_number
        : null,
      vatNumber: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.vat_number
        : null,
      address: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.address
        : null,
      city: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.city
        : null,
      zipcode: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.zipcode
        : null,
      country: supplierAPI.supplier_details
        ? supplierAPI.supplier_details.country
        : null,
      bankDetails: {
        country: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.bank_country
          : null,
        iban: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.iban
          : null,
        bic: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.bic
          : null,
        sortCode: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.sort_code
          : null,
        accountNumber: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.account_number
          : null,
        accountCode: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.account_code
          : null,
        routingNumber: supplierAPI.supplier_details
          ? supplierAPI.supplier_details.routing_number
          : null,
      },
      bankDetailsSuggestion: supplierAPI.supplier_details_suggestion
        ? {
            status: supplierAPI.supplier_details_suggestion.status,
            country: supplierAPI.supplier_details_suggestion.country,
            iban: supplierAPI.supplier_details_suggestion.iban
              ? reshapeBankInfoOcrResult(
                  supplierAPI.supplier_details_suggestion.iban,
                )
              : null,
            bic: supplierAPI.supplier_details_suggestion.bic
              ? reshapeBankInfoOcrResult(
                  supplierAPI.supplier_details_suggestion.bic,
                )
              : null,
          }
        : null,
      bankProofFile:
        // TODO: REMOVE THIS ESLINT RULE!!
        // eslint-disable-next-line lodash/prefer-get
        supplierAPI.supplier_details &&
        supplierAPI.supplier_details.bankProofFile &&
        supplierAPI.supplier_details.bankProofFile.filename
          ? {
              mimetype: supplierAPI.supplier_details.bankProofFile.mimetype,
              filename: supplierAPI.supplier_details.bankProofFile.filename,
              viewUrl: supplierAPI.supplier_details.bankProofFile.viewUrl,
              thumbnailUrl:
                supplierAPI.supplier_details.bankProofFile.thumbnailUrl,
              downloadUrl:
                supplierAPI.supplier_details.bankProofFile.downloadUrl,
            }
          : null,
    },
    // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
    validatedAt: supplierAPI.validated_at ? supplierAPI.validated_at : null,
  };
};

export const toApiSupplierDetails = (
  supplierDetails: UpdatableSupplierDetails,
  bankProofFile?:
    | {
        action: 'upload';
        base64: string;
      }
    | { action: 'delete' },
): ApiSupplierDetails => {
  const supplierDetailsAPI: ApiSupplierDetails = {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    account_holder_name: supplierDetails.accountHolderName!,
    legal_name: supplierDetails.legalName,
    registration_number: supplierDetails.registrationNumber,
    vat_number: supplierDetails.vatNumber,
    address: supplierDetails.address,
    city: supplierDetails.city,
    zipcode: supplierDetails.zipcode,
    country: supplierDetails.country,
    bank_country: supplierDetails.bankDetails.country,
    iban: supplierDetails.bankDetails.iban
      ? sanitizeBankInfo(supplierDetails.bankDetails.iban)
      : null,
    sort_code: supplierDetails.bankDetails.sortCode
      ? sanitizeBankInfo(supplierDetails.bankDetails.sortCode)
      : null,
    account_code: supplierDetails.bankDetails.accountCode
      ? sanitizeBankInfo(supplierDetails.bankDetails.accountCode)
      : null,
    account_number: supplierDetails.bankDetails.accountNumber
      ? sanitizeBankInfo(supplierDetails.bankDetails.accountNumber)
      : null,
    routing_number: supplierDetails.bankDetails.routingNumber
      ? sanitizeBankInfo(supplierDetails.bankDetails.routingNumber)
      : null,
    bic: supplierDetails.bankDetails.bic
      ? sanitizeBankInfo(supplierDetails.bankDetails.bic)
      : null,
  };

  if (bankProofFile) {
    if (bankProofFile.action === 'upload') {
      // FIXME: should not upload file via base64
      supplierDetailsAPI.bank_details_file = bankProofFile.base64;
    }
    if (bankProofFile.action === 'delete') {
      supplierDetailsAPI.bank_details_file = null;
    }
  }

  return supplierDetailsAPI;
};
