import filter from 'lodash/filter';

export const getOpenedPayment = (state) =>
  state.payments.allPaymentsById[state.payments.allPaymentsOpenedPayment];

export const getOpenedPaymentInvoices = (state) =>
  filter(state.payments.allPaymentsInvoicesById, {
    paymentId: state.payments.allPaymentsOpenedPayment,
  });

export const getIsPanelInvoicesLoading = (state) =>
  state.payments.isPanelInvoicesLoading;
