import { Icon, Tooltip } from '@dev-spendesk/grapes';

import { type BreakdownGaugePreparedBar } from './BreakdownGauge';

export type Props = {
  preparedBar: BreakdownGaugePreparedBar;
  color?: BreakdownGaugePreparedBar['color'];
};

export const BreakdownGaugeLegendItem = ({ preparedBar, color }: Props) => {
  return (
    <div
      key={`legend-${preparedBar.key}`}
      className="flex shrink grow-0 flex-col"
    >
      <div>
        <div
          className="mr-xxs inline-block h-xs w-xs rounded-[50%]"
          style={{ background: preparedBar.cssBackground }}
        />
        {preparedBar.name}
        {preparedBar.valueLabelTooltip && (
          <Tooltip
            maxWidth={180}
            content={
              <div className="leading-normal body-s">
                {preparedBar.valueLabelTooltip}
              </div>
            }
            triggerAsChild
          >
            <Icon className="ml-xs" size="s" name="info" />
          </Tooltip>
        )}
      </div>
      <div className="font-medium" style={{ color }}>
        {preparedBar.valueLabel}
      </div>
    </div>
  );
};
