import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ApprovalRule } from '../models/approver';

type Approver = {
  id: string;
  avatar: string | undefined;
  fullname: string;
};

export type Appraisal = {
  approver: Approver;
  automatic: boolean;
  created_at: string;
};

type Request = {
  id: string;
  appraisals: Appraisal[];
  approval_state: ApprovalRule;
};

export const useRequestQuery = (requestId: string): QueryState<Request> => {
  return useQuery<Request>({
    key: ['request', requestId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}`,
    },
    reshapeData: (data) => data,
  });
};
