// ------ Colors ------
// Note: It should match the similar SCSS color palette in `_variables.scss`

// Branding
export const brandPrimary = '#5D21D2';
export const darkblueIvy = '#2A264F';

// States
export const statusWarning = '#FFA745';
export const statusError = '#EE625E';
export const statusInfo = '#54BEFF';
export const statusOk = '#4FDC7B';

// Shades of grey
export const grey10Brave = '#3E3D53';
export const grey9Annoying = '#4F4F66';
export const grey8Talkative = '#7E7E8F';
export const grey7Pleased = '#9090A3';
export const grey6Cheerful = '#A3A3B5';
export const grey5Exasperated = '#C0C0CC';
export const grey4Proud = '#CCCCD9';
export const grey0Shy = '#FAFAFC';

// N & B
export const white = '#FFFFFF';
