/* eslint-disable @typescript-eslint/no-non-null-assertion */
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { v4 as uuid } from 'uuid';

import { replaceAt } from 'common/utils/array';

import * as perDiemActions from './actions';
import { type AllowanceLocation } from '../allowanceLocation';
import { fixDepartures, type IdentifiableDayStay, type Meal } from '../perDiem';
import { type SuggestedLocation } from '../perDiemLocation';
import { type PerDiemFormErrors } from '../perDiemValidation';

type CustomField = {
  customFieldId: string;
  customFieldValueId: string;
  value: string;
};

export interface PerDiemState {
  readonly allowanceLocations: ReadonlyArray<AllowanceLocation>;
  readonly suggestedLocationsByDayStayId: {
    [key: string]: SuggestedLocation[];
  };
  readonly customFields: ReadonlyArray<CustomField>;
  readonly error: {
    readonly message: string;
    readonly code: string;
  } | null;
  readonly isFetching: boolean;
  readonly isCreating: boolean;
  readonly ui: {
    readonly description: string;
    readonly selectedTeamId: string | null;
    readonly selectedCostCenterId: string | null;
    readonly selectedExpenseCategoryId: string | undefined;
    readonly mealsIncluded: Meal[];
    readonly stays: IdentifiableDayStay[];
    readonly errors: PerDiemFormErrors;
    readonly showMeals: boolean;
    readonly hasHotelAllowance: boolean;
    fetchingLegalLocationIds: ReadonlyArray<string>;
  };
}

export const initialState: PerDiemState = {
  allowanceLocations: [],
  suggestedLocationsByDayStayId: {},
  customFields: [],
  isFetching: false,
  isCreating: false,
  error: null,
  ui: {
    description: '',
    selectedTeamId: null,
    selectedCostCenterId: null,
    selectedExpenseCategoryId: undefined,
    mealsIncluded: [],
    showMeals: false,
    hasHotelAllowance: true,
    stays: [
      {
        arrivalTime: null,
        departureTime: null,
        location: {
          city: '',
          country: '',
        },
        id: uuid(),
      },
      {
        arrivalTime: null,
        departureTime: null,
        location: {
          city: '',
          country: '',
        },
        id: uuid(),
      },
    ],
    fetchingLegalLocationIds: [],
    errors: {
      description: false,
      locations: [] as number[],
      departures: [] as number[],
      arrivals: [] as number[],
      isTooShortTripDuration: false,
      isTripDurationMoreThan3Months: false,
    },
  },
};

const fetchAllowanceLocationsRequest = (state: PerDiemState): PerDiemState => ({
  ...state,
  isFetching: true,
});

const fetchAllowanceLocationsSuccess = (
  state: PerDiemState,
  { payload }: perDiemActions.FetchAllowanceLocationsSuccess,
): PerDiemState => ({
  ...state,
  isFetching: false,
  allowanceLocations: payload,
});

const fetchAllowanceLocationsFailure = (
  state: PerDiemState,
  { payload }: perDiemActions.FetchAllowanceLocationsFailure,
): PerDiemState => ({
  ...state,
  isFetching: false,
  error: payload,
});

export const fetchLegalLocationRequest = (
  state: PerDiemState,
  action: perDiemActions.FetchLegalLocationRequest,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    fetchingLegalLocationIds: state.ui.fetchingLegalLocationIds.concat(
      action.payload,
    ),
  },
});

export const fetchLegalLocationSuccess = (
  state: PerDiemState,
  action: perDiemActions.FetchLegalLocationSuccess,
): PerDiemState => {
  const nextState = {
    ...state,
    ui: {
      ...state.ui,
      fetchingLegalLocationIds: state.ui.fetchingLegalLocationIds.filter(
        (id) => id !== action.payload.dayStayId,
      ),
    },
  };
  return updateLocation(nextState, action);
};

const fetchLocationsSuccess = (
  state: PerDiemState,
  { payload }: perDiemActions.FetchLocationsSuccess,
): PerDiemState => ({
  ...state,
  suggestedLocationsByDayStayId: {
    ...state.suggestedLocationsByDayStayId,
    [payload.dayStayId]: payload.locations,
  },
});

export const createRequest = (state: PerDiemState): PerDiemState => ({
  ...state,
  isCreating: true,
});

export const createSuccess = (state: PerDiemState): PerDiemState => ({
  ...state,
  isCreating: false,
});

export const createFailure = (
  state: PerDiemState,
  action: perDiemActions.CreateFailure,
): PerDiemState => ({
  ...state,
  isCreating: false,
  error: action.payload,
});

export const buildMeals = (days: Date[]): Meal[] => {
  return days.map((date) => ({
    isBreakfastIncluded: false,
    isLunchIncluded: false,
    isDinnerIncluded: false,
    date,
  }));
};

export const buildDays = (departureDate: Date, returnDate: Date): string[] => {
  const loop = (date: Date, returnAt: Date, days: string[]): string[] => {
    const diff = differenceInDays(startOfDay(returnAt), startOfDay(date));
    if (diff < 0) {
      return days;
    }
    const updatedDays = days.concat(format(date, 'yyyy/MM/dd HH:mm:ss'));
    return loop(addDays(date, 1), returnAt, updatedDays);
  };
  return loop(departureDate, returnDate, []);
};

const updateDescription = (
  state: PerDiemState,
  action: perDiemActions.DescriptionChanged,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    description: action.payload,
  },
});

const updateDeparture = (
  state: PerDiemState,
  action: perDiemActions.DepartureChanged,
): PerDiemState => {
  const { departureTime, dayStayId } = action.payload;
  const stay = state.ui.stays.find((s) => s.id === dayStayId);
  const index = state.ui.stays.findIndex((s) => s.id === dayStayId);
  if (stay) {
    const stays = fixDepartures(
      replaceAt(state.ui.stays, index, { ...stay, departureTime }),
    );
    const firstDeparture = stays[0].departureTime;
    const lastDeparture = stays[state.ui.stays.length - 1].departureTime;
    return {
      ...state,
      ui: {
        ...state.ui,
        mealsIncluded:
          firstDeparture && lastDeparture
            ? buildMeals(
                buildDays(firstDeparture, lastDeparture).map(
                  (d) => new Date(d),
                ),
              )
            : state.ui.mealsIncluded,
        stays,
        errors: {
          ...state.ui.errors,
          departures: state.ui.errors.departures.filter(
            (index_) => index_ !== index,
          ),
        },
      },
    };
  }
  return state;
};

const updateArrival = (
  state: PerDiemState,
  action: perDiemActions.ArrivalChanged,
): PerDiemState => {
  const { arrivalTime, dayStayId } = action.payload;
  const stay = state.ui.stays.find((s) => s.id === dayStayId);
  const index = state.ui.stays.findIndex((s) => s.id === dayStayId);
  if (stay) {
    const stays = replaceAt(state.ui.stays, index, { ...stay, arrivalTime });
    return {
      ...state,
      ui: {
        ...state.ui,
        stays,
        errors: {
          ...state.ui.errors,
          arrivals: state.ui.errors.departures.filter(
            (index_) => index_ !== index,
          ),
        },
      },
    };
  }
  return state;
};

const updateBreakfast = (
  state: PerDiemState,
  action: perDiemActions.BreakfastChanged,
): PerDiemState => {
  const { day, isBreakfastIncluded } = action.payload;
  const meal = state.ui.mealsIncluded.find((_, index) => index === day);
  if (meal) {
    const mealsIncluded = replaceAt(state.ui.mealsIncluded, day, {
      ...meal,
      isBreakfastIncluded,
    });
    return {
      ...state,
      ui: {
        ...state.ui,
        mealsIncluded,
      },
    };
  }
  return state;
};

const updateLunch = (
  state: PerDiemState,
  action: perDiemActions.LunchChanged,
): PerDiemState => {
  const { day, isLunchIncluded } = action.payload;
  const meal = state.ui.mealsIncluded.find((_, index) => index === day);
  if (meal) {
    const mealsIncluded = replaceAt(state.ui.mealsIncluded, day, {
      ...meal,
      isLunchIncluded,
    });
    return {
      ...state,
      ui: {
        ...state.ui,
        mealsIncluded,
      },
    };
  }
  return state;
};

const updateDinner = (
  state: PerDiemState,
  action: perDiemActions.DinnerChanged,
): PerDiemState => {
  const { day, isDinnerIncluded } = action.payload;
  const meal = state.ui.mealsIncluded.find((_, index) => index === day);
  if (meal) {
    const mealsIncluded = replaceAt(state.ui.mealsIncluded, day, {
      ...meal,
      isDinnerIncluded,
    });
    return {
      ...state,
      ui: {
        ...state.ui,
        mealsIncluded,
      },
    };
  }
  return state;
};

const addDestination = (
  state: PerDiemState,
  action: perDiemActions.AddDestination,
): PerDiemState => {
  const lastStay = (state.ui.stays ?? []).at(-1);
  const arrivalTime = lastStay!.departureTime
    ? new Date(lastStay!.departureTime)
    : null;
  if (arrivalTime) {
    arrivalTime.setSeconds(arrivalTime.getSeconds() + 1);
  }
  const stays = state.ui.stays.concat({
    arrivalTime,
    departureTime: null,
    location: action.payload.location,
    id: action.payload.dayStayId,
  });
  return {
    ...state,
    ui: {
      ...state.ui,
      stays,
    },
  };
};

const deleteDestination = (
  state: PerDiemState,
  action: perDiemActions.DeleteDestination,
): PerDiemState => {
  const stays = state.ui.stays.filter((s) => s.id !== action.payload);
  const firstDeparture = stays[0].departureTime;
  const lastDeparture = stays.at(-1)!.departureTime;
  return {
    ...state,
    ui: {
      ...state.ui,
      stays,
      mealsIncluded:
        firstDeparture && lastDeparture
          ? buildMeals(
              buildDays(firstDeparture, lastDeparture).map((d) => new Date(d)),
            )
          : state.ui.mealsIncluded,
    },
  };
};

const updateLocation = (
  state: PerDiemState,
  action:
    | perDiemActions.LocationChanged
    | perDiemActions.FetchLegalLocationSuccess,
): PerDiemState => {
  const { dayStayId, location } = action.payload;
  const stay = state.ui.stays.find((s) => s.id === dayStayId);
  const index = state.ui.stays.findIndex((s) => s.id === dayStayId);
  if (stay) {
    return {
      ...state,
      isFetching: false,
      ui: {
        ...state.ui,
        stays: replaceAt(state.ui.stays, index, { ...stay, location }),
        errors: {
          ...state.ui.errors,
          locations: state.ui.errors.locations.filter(
            (index_) => index_ !== index,
          ),
        },
      },
    };
  }
  return state;
};

const resetUiState = (_state: PerDiemState): PerDiemState => ({
  allowanceLocations: [],
  suggestedLocationsByDayStayId: {},
  customFields: [],
  isFetching: false,
  isCreating: false,
  error: null,
  ui: initialState.ui,
});

const selectTeam = (
  state: PerDiemState,
  action: perDiemActions.SelectTeam,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    selectedTeamId: action.payload,
  },
});

const selectCostCenter = (
  state: PerDiemState,
  action: perDiemActions.SelectCostCenter,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    selectedCostCenterId: action.payload,
  },
});

const selectExpenseCategory = (
  state: PerDiemState,
  action: perDiemActions.SelectExpenseCategory,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    selectedExpenseCategoryId: action.payload,
  },
});

const updateCustomFields = (
  state: PerDiemState,
  action: perDiemActions.CustomFieldsChanged,
): PerDiemState => ({
  ...state,
  customFields: action.payload.customFields,
});

const updateFormErrors = (
  state: PerDiemState,
  action: perDiemActions.FormErrorsChanged,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    errors: action.payload,
  },
});

const computeMeals = (state: PerDiemState): PerDiemState => {
  const { departureTime } = state.ui.stays[0];
  const { arrivalTime } = state.ui.stays.at(-1)!;
  return {
    ...state,
    ui: {
      ...state.ui,
      mealsIncluded:
        departureTime && arrivalTime
          ? buildMeals(
              buildDays(departureTime, arrivalTime).map((d) => new Date(d)),
            )
          : state.ui.mealsIncluded,
    },
  };
};

const displayMeals = (state: PerDiemState): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    showMeals: true,
  },
});

const displayTrip = (state: PerDiemState): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    showMeals: false,
  },
});

const updateNightAccomodation = (
  state: PerDiemState,
  { payload }: perDiemActions.HotelAllowanceChanged,
): PerDiemState => ({
  ...state,
  ui: {
    ...state.ui,
    hasHotelAllowance: payload,
  },
});

const reducer = (
  state: PerDiemState = initialState,
  action: perDiemActions.PerDiemAction,
): PerDiemState => {
  switch (action.type) {
    case perDiemActions.FETCH_ALLOWANCE_LOCATIONS_REQUEST:
      return fetchAllowanceLocationsRequest(state);
    case perDiemActions.FETCH_ALLOWANCE_LOCATIONS_SUCCESS:
      return fetchAllowanceLocationsSuccess(state, action);
    case perDiemActions.FETCH_ALLOWANCE_LOCATIONS_FAILURE:
      return fetchAllowanceLocationsFailure(state, action);
    case perDiemActions.FETCH_LEGAL_LOCATION_REQUEST:
      return fetchLegalLocationRequest(state, action);
    case perDiemActions.FETCH_LEGAL_LOCATION_SUCCESS:
      return fetchLegalLocationSuccess(state, action);
    case perDiemActions.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationsSuccess(state, action);
    case perDiemActions.CREATE_REQUEST:
      return createRequest(state);
    case perDiemActions.CREATE_SUCCESS:
      return createSuccess(state);
    case perDiemActions.CREATE_FAILURE:
      return createFailure(state, action);
    case perDiemActions.DESCRIPTION_CHANGED:
      return updateDescription(state, action);
    case perDiemActions.DEPARTURE_CHANGED:
      return updateDeparture(state, action);
    case perDiemActions.ARRIVAL_CHANGED:
      return updateArrival(state, action);
    case perDiemActions.BREAKFAST_CHANGED:
      return updateBreakfast(state, action);
    case perDiemActions.LUNCH_CHANGED:
      return updateLunch(state, action);
    case perDiemActions.DINNER_CHANGED:
      return updateDinner(state, action);
    case perDiemActions.ADD_DESTINATION:
      return addDestination(state, action);
    case perDiemActions.DELETE_DESTINATION:
      return deleteDestination(state, action);
    case perDiemActions.LOCATION_CHANGED:
      return updateLocation(state, action);
    case perDiemActions.RESET_UI_STATE:
      return resetUiState(state);
    case perDiemActions.SELECT_TEAM:
      return selectTeam(state, action);
    case perDiemActions.SELECT_COST_CENTER:
      return selectCostCenter(state, action);
    case perDiemActions.SELECT_EXPENSE_CATEGORY:
      return selectExpenseCategory(state, action);
    case perDiemActions.CUSTOM_FIELDS_CHANGED:
      return updateCustomFields(state, action);
    case perDiemActions.FORM_ERRORS_CHANGED:
      return updateFormErrors(state, action);
    case perDiemActions.COMPUTE_MEALS:
      return computeMeals(state);
    case perDiemActions.DISPLAY_MEALS:
      return displayMeals(state);
    case perDiemActions.DISPLAY_TRIP:
      return displayTrip(state);
    case perDiemActions.HOTEL_ALLOWANCE_CHANGED:
      return updateNightAccomodation(state, action);
    default:
      return state;
  }
};

export default reducer;
