import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { SubnavigationItem } from 'src/core/modules/requests/utils/navigation';

import { NavLink } from '../NavLink/NavLink';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

/**
 * Request entry
 * Used only by Requester
 */
export const RequestLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  return (
    <NavLink
      href={routeFor(routes.REQUESTS.path, {
        company: company.id,
        type: SubnavigationItem.All,
      })}
      activePaths={[
        // Cannot use REQUESTS.path because we need to exclude `requests/inbox`
        '/app/:company/requests/all',
        '/app/:company/requests/to-approve',
        '/app/:company/requests/mine',
        '/app/:company/requests/drafts',
      ]}
      iconName="cube"
    >
      {t('misc.request_plural')}
    </NavLink>
  );
};
