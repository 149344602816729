import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import i18n from 'src/core/config/i18n';

import { type FormValues as TemplateFormValues } from '../components/TemplateEditorForm/formValues';
import { type CustomField } from '../customField';
import {
  type Template,
  type TemplateItem,
  type ColumnDelimiter,
  type DateFormat,
  type DecimalDelimiter,
  type DateDelimiter,
  type AvailableColumn,
  type CompositePatternPart,
  dateDelimiters,
  parseDateFormatWithEmptyDelimiter,
  getTranslatedDefinedColumnDesc,
  getTranslatedDefinedColumnName,
  getTranslatedCustomFieldColumnDesc,
} from '../template';

interface BaseApiColumn {
  name: string;
}

interface DefinedApiColumn extends BaseApiColumn {
  type: 'defined';
  reference: string;
}

const isDefinedApiColumn = (column: ApiColumn): column is DefinedApiColumn => {
  return column.type === 'defined';
};

const isCompositeApiColumn = (
  column: ApiColumn,
): column is CompositeApiColumn => {
  return column.type === 'composite';
};

const isColumnRequired = (
  column: ApiColumn,
  availableColumns: AvailableColumn[],
): boolean => {
  if (isDefinedApiColumn(column)) {
    return (
      availableColumns.find(
        (availableColumn) => availableColumn.reference === column.reference,
      )?.isRequired || false
    );
  }

  // This then applies to journal code, type & direction columns (and future ones)
  if (!isCompositeApiColumn(column) && !isCustomFieldApiColumn(column)) {
    return (
      availableColumns.find(
        (availableColumn) => availableColumn.type === column.type,
      )?.isRequired || false
    );
  }

  return false;
};

interface CustomFieldApiColumn extends BaseApiColumn {
  type: 'customField';
  customFieldId: string;
}

const isCustomFieldApiColumn = (
  column: ApiColumn,
): column is CustomFieldApiColumn => {
  return column.type === 'customField';
};

interface CompositeApiColumn extends BaseApiColumn {
  type: 'composite';
  compositePattern: CompositePatternPart[];
}

interface DirectionApiColumn extends BaseApiColumn {
  type: 'direction';
  debit: string;
  credit: string;
}

interface TypeApiColumn extends BaseApiColumn {
  type: 'type';
  analytical: string;
  general: string;
}

interface JournalCodeApiColumn extends BaseApiColumn {
  type: 'journalCode';
  cardExpense: string;
  invoice: string;
  expenseClaim: string;
  subscription: string;
}

type ApiColumn =
  | DefinedApiColumn
  | CustomFieldApiColumn
  | CompositeApiColumn
  | JournalCodeApiColumn
  | DirectionApiColumn
  | TypeApiColumn;

export type ApiTemplate = {
  id?: string;
  companyId: string;
  templateType: 'purchase' | 'bank';
  templateName: string;
  columnDelimiter: ColumnDelimiter;
  dateFormat: string;
  decimalDelimiter: DecimalDelimiter;
  format: 'csv';
  includeHeader: boolean;
  columnMap: ApiColumn[];
  languageCode: 'en' | 'fr' | 'de';
  isDefault: boolean;
  accountingSoftware?: string;
  quotes: string;
};

export type ApiAvailableColumns = {
  [key: string]: {
    description: string;
    isRequired: boolean;
    isDeprecated?: boolean;
    type?: 'date';
    name?: string;
  };
};

type DateFormatMatch = {
  dateFormat: DateFormat;
  dateDelimiter: DateDelimiter;
};

/**
 * The API gives us the exact format including delimiter (for ex. `YYYY-MM-DD`)
 * but in the front-end we need to split the delimiter and the format in two
 * separate form controls to avoid having a huge selector containing all the
 * combinations of the date formats and delimiters.
 */
const reshapeApiDateFormat = (format: string): DateFormatMatch => {
  // Try to find which delimiter is used
  for (const delimiter of dateDelimiters) {
    if (delimiter === '') {
      // Skipping because split with empty string splits at each character.
      // This specific case is handled out of the loop later on.
      continue;
    }

    const split = format.split(delimiter);

    // We know we found the correct limiter if using it to split the input
    // yields more than a single chunk.
    if (split.length > 1) {
      const match: DateFormatMatch = {
        dateFormat: split as unknown as DateFormat,
        dateDelimiter: delimiter,
      };
      return match;
    }
  }

  // Handle empty delimiter (`''`)  as a specific case because the parsing
  // differs in that case.
  const match: DateFormatMatch = {
    dateFormat: parseDateFormatWithEmptyDelimiter(format),
    dateDelimiter: '',
  };
  return match;
};

export const reshapeApiTemplate = ({
  template,
  availableTemplateColumns,
  customFields,
  isDefault,
  isTranslated,
}: {
  template: ApiTemplate;
  availableTemplateColumns: AvailableColumn[];
  customFields: CustomField[];
  isDefault: boolean;
  isTranslated: boolean;
}): Template => {
  const { dateFormat, dateDelimiter } = reshapeApiDateFormat(
    template.dateFormat,
  );

  let { accountingSoftware } = template;
  let customSoftwareName: string | undefined;
  if (template.accountingSoftware?.startsWith('others::')) {
    accountingSoftware = 'Others';
    customSoftwareName = template.accountingSoftware.slice('others::'.length);
  }

  return {
    id: template.id,
    accountingSoftware: accountingSoftware ?? '',
    customSoftwareName,
    columnDelimiter: template.columnDelimiter,
    dateFormat,
    dateDelimiter,
    decimalDelimiter: template.decimalDelimiter,
    name: template.templateName,
    outputFormat: template.format,
    shouldOutputHeaders: template.includeHeader,
    columns: template.columnMap.map((column) => {
      let columnName = column.name;
      if (!isTranslated) {
        columnName = isDefault
          ? // @ts-expect-error
            getTranslatedColumnName(column, i18n.t.bind(i18n))
          : column.name;
      }

      return {
        ...column,
        description: getColumnDescription(
          availableTemplateColumns,
          column,
          customFields,
          // @ts-expect-error
          i18n.t.bind(i18n),
          isTranslated,
        ),
        name: columnName,
        isRequired: isColumnRequired(column, availableTemplateColumns),
        isReorderable:
          (isDefinedApiColumn(column) &&
            availableTemplateColumns.find(
              (availableColumn) =>
                availableColumn.reference === column.reference,
            )?.isReorderable) ||
          true,
      };
    }),
    language: template.languageCode == 'en' ? 'en-GB' : template.languageCode,
  };
};

export const reshapeApiTemplateItem = (
  template: ApiTemplate,
): TemplateItem => ({
  id: template.id ?? '',
  name: template.templateName,
  isDefault: template.isDefault,
  type: template.templateType,
});

export const toApiTemplate = (
  values: TemplateFormValues,
  companyId: string,
): ApiTemplate => ({
  companyId,
  isDefault: false,
  templateType: 'purchase',
  templateName: values.name.trim(),
  columnDelimiter: values.columnDelimiter,
  decimalDelimiter: values.decimalDelimiter,
  dateFormat: values.dateFormat.join(values.dateDelimiter),
  format: 'csv',
  includeHeader: values.shouldOutputHeaders,
  columnMap: values.columns.map(
    ({ isRequired, description, isReorderable, ...column }) => column,
  ),
  languageCode: values.language,
  accountingSoftware:
    values.accountingSoftware !== 'Others'
      ? values.accountingSoftware
      : `others::${values.customSoftwareName}`,
  quotes: 'doubleQuoted',
});

export const reshapeAvailableColumns = (
  availableColumns: ApiAvailableColumns,
  isTranslated: boolean,
): AvailableColumn[] =>
  Object.entries(availableColumns).map(([key, value]) => {
    let columnName = value.name ?? 'no value';
    if (!isTranslated) {
      // @ts-expect-error
      columnName = getTranslatedDefinedColumnName(key, i18n.t.bind(i18n));
    }

    return {
      reference: key,
      name: columnName,
      description: value.description,
      isRequired: value.isRequired,
      isReorderable: true,
      isDeprecated: value.isDeprecated,
      type: value.type,
    };
  });

const getTranslatedColumnName = (
  column: ApiColumn,
  t: TGlobalFunctionTyped,
): string => {
  if (isDefinedApiColumn(column)) {
    return getTranslatedDefinedColumnName(column.reference, t);
  }

  return column.name;
};

const getColumnDescription = (
  availableColumns: AvailableColumn[],
  column: ApiColumn,
  customFields: CustomField[],
  t: TGlobalFunctionTyped,
  isTranslated: boolean,
): string => {
  if (isDefinedApiColumn(column)) {
    if (!isTranslated) {
      return getTranslatedDefinedColumnDesc(column.reference, t);
    }

    return (
      availableColumns.find(
        (availableColumn) => availableColumn.reference === column.reference,
      )?.description ?? 'no value'
    );
  }

  if (isCustomFieldApiColumn(column)) {
    return getTranslatedCustomFieldColumnDesc(
      column.customFieldId,
      customFields,
      t,
    );
  }

  return '';
};
