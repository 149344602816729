import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from "src/core/common/hooks/useTranslation";

import './CustomFieldsDisabled.scss';

// FIXME: this component is deprecated and will be removed when the expense
// categories feature will be open to everyone
const CustomFieldsDisabled = () => {
  const { t, i18n } = useTranslation();
  // TODO: i18n German
  const image1xPath =
    i18n.language === 'fr'
      ? '/static/img/custom-fields/disabled_fr@1x.png'
      : '/static/img/custom-fields/disabled_en@1x.png';
  const image2xPath =
    i18n.language === 'fr'
      ? '/static/img/custom-fields/disabled_fr@2x.png'
      : '/static/img/custom-fields/disabled_en@2x.png';

  return (
    <div className="CustomFieldsDisabled">
      <h1 className="CustomFieldsDisabled__title">
        <Trans i18nKey="customFields.disabledEmptyState.title">
          Add more
          <span className="CustomFieldsDisabled__title-highlight">
            analytics
          </span>
          to your spending!
        </Trans>
      </h1>
      <h2 className="CustomFieldsDisabled__subtitle">
        {t('customFields.disabledEmptyState.subtitle')}
      </h2>
      <img
        height={413}
        className="CustomFieldsDisabled__image"
        src={image1xPath}
        srcSet={`${image2xPath} 2x`}
        draggable={false}
        alt=""
      />
    </div>
  );
};

CustomFieldsDisabled.propTypes = {
  isActivationLoading: PropTypes.bool,
};

CustomFieldsDisabled.defaultProps = {
  isActivationLoading: false,
};

export default memo(CustomFieldsDisabled);
