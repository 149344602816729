import { type Role } from 'modules/members/models/roles';

import { type DataSources, type Settings } from '../../models/invite';

export const getDefaultSettings = (dataSources: DataSources): Settings => {
  const { roles, policies } = dataSources;

  // We have at least one role
  let defaultRole: Role = roles[0];

  roles.forEach((role) => {
    if (role.order > defaultRole.order) {
      defaultRole = role;
    }
  });

  return {
    role: defaultRole,
    // In practice, there should be always at least the standard company policy.
    policy: policies.find((policy) => policy.isDefault),
    costCenter: undefined,
  };
};
