import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';

import * as types from './actionTypes';
import { STANDARD, AVAILABLE_DELIVERY_METHODS } from '../constants';

const initialState = {
  cardsToOrder: [],
  deliveryMethod: STANDARD,
  deliveryAddress: {
    recipient: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    zipCode: null,
    country: null,
  },
  availableDeliveryMethods: AVAILABLE_DELIVERY_METHODS,
  standardDeliveryPricing: {},
  expressDeliveryPricing: {},
};

export const addCardToOrder = (state, action) => {
  // If the card already exists just replace it. Else add it.
  const newCardsToOrder = state.cardsToOrder.filter(
    (card) =>
      get(card, 'cardHolder._id') !== get(action, 'payload.cardHolder._id'),
  );

  return {
    ...state,
    cardsToOrder: [...newCardsToOrder, action.payload],
  };
};

const removeCardToOrder = (state, action) => ({
  ...state,
  cardsToOrder: state.cardsToOrder.filter(
    (item) => item.cardHolder._id !== action.payload.cardUserId,
  ),
});

const changeDeliveryAddressForOrder = (state, action) => ({
  ...state,
  deliveryAddress: action.payload,
});

const changeDeliveryMethodForOrder = (state, action) => ({
  ...state,
  deliveryMethod: action.payload,
});

const setExpressDeliveryPricing = (state, action) => ({
  ...state,
  standardDeliveryPricing: action.payload.standardDeliveryPricing,
  expressDeliveryPricing: action.payload.expressDeliveryPricing,
});

const clearCurrentOrder = () => initialState;

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.ADD_CARD_TO_ORDER, addCardToOrder);
  builder.addCase(types.REMOVE_CARD_TO_ORDER, removeCardToOrder);
  builder.addCase(
    types.CHANGE_DELIVERY_ADDRESS_FOR_ORDER,
    changeDeliveryAddressForOrder,
  );
  builder.addCase(
    types.CHANGE_DELIVERY_METHOD_FOR_ORDER,
    changeDeliveryMethodForOrder,
  );
  builder.addCase(types.CLEAR_CURRENT_ORDER, clearCurrentOrder);
  builder.addCase(
    types.FETCH_EXPRESS_DELIVERY_PRICINGS_SUCCESS,
    setExpressDeliveryPricing,
  );
});
