import isNil from 'lodash/isNil';

export interface CustomFieldAssociation {
  customFieldId: string;
  customFieldValueId: string; // this field can be undefined when a new custom field is created within a form, but not yet saved in the back-end
  value: string;
}

export type CustomFieldEligibleType =
  | 'request'
  | 'expense'
  | 'payment'
  | 'subscription';

export const partialUpdateCustomFieldAssociation = (
  customFieldsAssociation: CustomFieldAssociation[],
  updatedCustomFieldAssociations: CustomFieldAssociation[],
): CustomFieldAssociation[] => {
  const updatedCustomFieldIds = updatedCustomFieldAssociations.map(
    (customFieldAssociation) => customFieldAssociation.customFieldId,
  );
  const unchangedCustomFieldAssociations = customFieldsAssociation.filter(
    (customFieldAssociation) =>
      !updatedCustomFieldIds.includes(customFieldAssociation.customFieldId),
  );

  return buildCustomFieldAssociations([
    ...unchangedCustomFieldAssociations,
    ...updatedCustomFieldAssociations.map((customFieldAssociation) => ({
      ...customFieldAssociation,
      value: customFieldAssociation.value ?? undefined,
    })),
  ]);
};

// this function should be called anytime an array of CustomFieldAssociations is created / updated
// it makes sure that the array is sorted by customFieldId
// not sorting it can cause some issues, for example with formik, which takes the order of the fields into account to know if a field has been touched or not
export const buildCustomFieldAssociations = (
  customFieldsAssociation: CustomFieldAssociation[],
): CustomFieldAssociation[] =>
  sortCustomFieldAssociations(customFieldsAssociation);

const sortCustomFieldAssociations = (
  customFieldsAssociation: CustomFieldAssociation[],
) =>
  customFieldsAssociation.sort(
    (customFieldAssociationA, customFieldAssociationB) =>
      customFieldAssociationA.customFieldId.localeCompare(
        customFieldAssociationB.customFieldId,
      ),
  );

// case where the custom field does not exist yet in the back-end, and will be created
export const isNewCustomFieldAssociation = (
  customFieldsAssociation: CustomFieldAssociation,
): boolean =>
  isNil(customFieldsAssociation.customFieldValueId) &&
  !isNil(customFieldsAssociation.value);

export const isExistingCustomFieldAssociation = (
  customFieldsAssociation: CustomFieldAssociation,
): boolean =>
  !isNil(customFieldsAssociation.customFieldValueId) &&
  !isNil(customFieldsAssociation.value);

export const isDefinedCustomFieldAssociation = (
  customFieldsAssociation: CustomFieldAssociation,
): boolean =>
  isNewCustomFieldAssociation(customFieldsAssociation) ||
  isExistingCustomFieldAssociation(customFieldsAssociation);
