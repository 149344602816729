import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { routeFor, routes } from 'src/core/constants/routes';

export const RedirectToHome = () => {
  const history = useHistory();

  const company = useCompany();

  // For some unexplained and mysterious reasons,
  // there were cases when react-router's Redirect did not work in PaidLayoutContainer.
  // Replacing it with an useEffect here
  useEffect(() => {
    history.replace({
      pathname: routeFor(routes.HOMEPAGE.path, {
        company: company.id,
      }),
      search: history.location.search,
    });
  }, []);

  return null;
};
