import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCanAccessInvoicesPage } from 'src/core/modules/invoices/hooks/useCanAccessInvoicesPage';
import { useCanAccessExpenseClaimsPage } from 'src/core/modules/reimbursements/hooks/useCanAccessExpenseClaimsPage';
import { SubnavigationItem } from 'src/core/modules/requests/utils/navigation';

import { DisclosureItem } from '../DisclosureItem/DisclosureItem';
import { SubNavLink } from '../SubNavItem/SubNavItem';

type Props = Readonly<{
  company: {
    id: string;
  };
}>;

export const ExpendituresLink = ({ company }: Props) => {
  const { t } = useTranslation('global');

  const canAccessInvoicesPage = useCanAccessInvoicesPage();
  const canAccessExpenseClaimsPage = useCanAccessExpenseClaimsPage();

  const isPurchaseOrderActivated = useFeature(
    FEATURES.PURCHASE_ORDERS_ACTIVATED,
  );

  return (
    <DisclosureItem summary={t('navigation.expenditures')} iconName="money-bag">
      <li>
        <SubNavLink
          href={routeFor(routes.INBOX_INVOICES.path, {
            company: company.id,
          })}
        >
          {t('requests.inboxInvoicesSection')}
        </SubNavLink>
      </li>
      <li>
        <SubNavLink
          href={routeFor(routes.REQUESTS.path, {
            company: company.id,
            type: SubnavigationItem.All,
          })}
          activePaths={[
            // Cannot use REQUESTS.path because we need to exclude `requests/inbox`
            '/app/:company/requests/all',
            '/app/:company/requests/to-approve',
            '/app/:company/requests/mine',
            '/app/:company/requests/drafts',
          ]}
        >
          {t('misc.request_plural')}
        </SubNavLink>
      </li>
      {canAccessExpenseClaimsPage && (
        <li>
          <SubNavLink
            href={routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
              company: company.id,
            })}
            activePaths={[routes.EXPENSE_CLAIMS.path]}
          >
            {t('misc.expenseClaimsTitle')}
          </SubNavLink>
        </li>
      )}
      {canAccessInvoicesPage && (
        <li>
          <SubNavLink
            href={routeFor(routes.INVOICES_REVIEW.path, {
              company: company.id,
            })}
            activePaths={[
              routes.INVOICES.path,
              routes.CREDIT_NOTES_REVIEW.path,
            ]}
          >
            {t('misc.invoicesTitle')}
          </SubNavLink>
        </li>
      )}
      <li>
        <SubNavLink
          href={routeFor(routes.PAYMENTS_ALL.path, {
            company: company.id,
          })}
          activePaths={[routes.PAYABLES_ALL.path, routes.PAYMENTS_ALL.path]}
        >
          {t('navigation.allExpenses')}
        </SubNavLink>
      </li>
      {isPurchaseOrderActivated && (
        <li>
          <SubNavLink
            href={routeFor(routes.PURCHASE_ORDERS.path, {
              company: company.id,
            })}
          >
            {t('misc.purchaseOrders')}
          </SubNavLink>
        </li>
      )}
    </DisclosureItem>
  );
};
