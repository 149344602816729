import { Callout } from '@dev-spendesk/grapes';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { type Member } from '../../models/member';

type Props = {
  activeMembers: Member[];
};

export const MembersTableManagersInformation = ({ activeMembers }: Props) => {
  const { t } = useTranslation('global');
  const isReportingManagersFeatureToggleEnabled = useFeature(
    FEATURES.TMP_CONMAN_REPORTING_MANAGERS,
  );

  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();
  const isCompanyManagedByIntegrationResult = unwrapQuery(
    isCompanyManagedByIntegrationQueryState,
  );
  const isCompanyManagedByIntegration =
    isCompanyManagedByIntegrationResult?.isManagedByKombo ?? false;
  const featureSet = isCompanyManagedByIntegrationResult?.featureSet ?? [];
  const includesReportingManagers = featureSet.includes(
    'includesReportingManagers',
  );

  if (!isReportingManagersFeatureToggleEnabled) {
    return null;
  }

  const numberOfMembersWithMissingManagers = activeMembers.filter(
    (member) => !member.manager,
  ).length;

  if (numberOfMembersWithMissingManagers === 0) {
    return null;
  }

  const getStringKey = () => {
    if (!isCompanyManagedByIntegration) {
      return 'members.reportingManagers.missingManagersCallout.description.default';
    }

    return includesReportingManagers
      ? 'members.reportingManagers.missingManagersCallout.description.managerSyncEnabled'
      : 'members.reportingManagers.missingManagersCallout.description.managerSyncDisabled';
  };

  return (
    <Callout
      className="mb-s"
      variant="warning"
      title={t(`members.reportingManagers.missingManagersCallout.title`, {
        count: numberOfMembersWithMissingManagers,
      })}
    >
      {t(getStringKey())}
    </Callout>
  );
};
