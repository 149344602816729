import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { useShouldDisplayOnboardingModal } from './hooks/useShouldDisplayOnboardingModal';
import onboardingIllustration from './illustration.svg';
import styles from './styles.module.css';

export const MembersBulkOnboardingModal = () => {
  const { t } = useTranslation('global');
  const [shouldDisplayOnboardingModal, closeOnboardingModal] =
    useShouldDisplayOnboardingModal();

  if (shouldDisplayOnboardingModal) {
    return (
      <Modal
        isOpen
        onClose={closeOnboardingModal}
        illustration={
          <div className={styles.illustration}>
            <img src={onboardingIllustration} alt="" />
          </div>
        }
        title={t('members.bulk.onboardingModalTitle')}
        subtitle={t('members.bulk.onboardingModalDesc')}
      >
        <Button
          text={t('members.bulk.onboardingModalBackButton')}
          variant="primary"
          onClick={closeOnboardingModal}
        />
      </Modal>
    );
  }

  return null;
};
