import { colors, Icon, IconButton, Input } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useDebounce } from 'react-use';

import { useTranslation } from '../../hooks/useTranslation';

type Props = {
  /**
   * Handler that is called when the value changes and
   * when the value is longer or equals to minLength
   */
  setSearch(search: string): void;
  /**
   * The current value.
   */
  search: string;
  /**
   * Whether the Input should fit its parent or content.
   * @default content
   */
  fit?: 'content' | 'parent';
  /**
   * Temporary text that occupies the Input when it is empty.
   * @default misc.searchPlaceholder
   */
  placeholder?: string;
  /**
   * Minimum length needed to call setSearch.
   * @default 3
   */
  minLength?: number;
  /**
   * className for the element.
   */
  className?: string;
};

export const SearchFilter = ({
  search,
  setSearch,
  fit = 'content',
  placeholder,
  minLength = 3,
  className = '',
}: Props) => {
  const { t } = useTranslation('global');
  const [localSearch, setLocalSearch] = useState(search ?? '');

  useDebounce(
    () => {
      if (search === undefined && localSearch === '') {
        return;
      }
      if (
        search !== localSearch &&
        (localSearch.length >= minLength || localSearch.length === 0)
      ) {
        setSearch(localSearch);
      }
    },
    500,
    [localSearch],
  );

  return (
    <Input
      type="search"
      fit={fit}
      placeholder={placeholder ?? t('misc.searchPlaceholder')}
      className={`w-[300px] ${className}`}
      textAlign="left"
      onChange={(e) => setLocalSearch(e.target.value)}
      value={localSearch}
      leftAddon={
        <Icon
          name="search"
          color={colors.neutral}
          className="ml-xs"
          size="m"
          data-testid="SearchFilterLeftIcon"
        />
      }
      rightAddon={
        localSearch ? (
          <IconButton
            iconName="cross"
            iconColor={colors.neutralDark}
            variant="borderless"
            onClick={() => {
              // Bypass the debounce and empty the search right away
              // (debounce is blocked from calling setSearch by comparing localSearch & prop search values)
              setSearch('');
              setLocalSearch('');
            }}
            data-testid="SearchFilterResetIcon"
            aria-label={t('misc.clear')}
          />
        ) : undefined
      }
      data-testid="SearchFilterInput"
    />
  );
};
