import {
  Button,
  FormField,
  Label,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import InputTagsSelect from 'src/core/common/components/legacy/InputTagsSelect/InputTagsSelect';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type FormValues } from './form';

import './ColumnEditModal.css';

type CustomContentOption = {
  id: string;
  name: string;
};

type ParentCustomContentOption = CustomContentOption & {
  subItems: CustomContentOption[];
};

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  customContentOptions: (ParentCustomContentOption | CustomContentOption)[];
  isDeletable: boolean;
  isCustomContent: boolean;
  getCustomContentValueLabel: (value: string) => string;
  isStaticCustomContentValue: (value: string) => boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
} & FormikProps<FormValues>;

export const ColumnEditModal = ({
  isOpen,
  title,
  description,
  customContentOptions,
  isDeletable,
  isCustomContent,
  getCustomContentValueLabel,
  isStaticCustomContentValue,
  onRemoveColumn,
  onClose,
  // Formik
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('exports.columnEditModalTitle', { title })}
      subtitle={description}
      onClose={onClose}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(event: any) => {
            handleSubmit(event);
          }}
        />,
      ]}
    >
      <div className="text-left">
        <FormField
          label={t('exports.renameInputLabel')}
          alertMessage={
            touched.title && Boolean(errors.title) ? errors.title : undefined
          }
        >
          <TextInput
            name="title"
            id="title"
            value={values.title}
            onChange={handleChange}
            isInvalid={touched.title && Boolean(errors.title)}
          />
        </FormField>
        {isCustomContent && (
          <div className="mt-m flex flex-col">
            <Label
              className="mb-xs"
              label={t('exports.changeCustomColumnContent')}
            />

            <InputTagsSelect
              className="ColumnEditModal__tag-selector"
              selectedOptions={values.customContent}
              options={customContentOptions}
              getTagDisplayValue={getCustomContentValueLabel}
              getTagDisplayTheme={(value: string) =>
                isStaticCustomContentValue(value) ? 'success' : 'info'
              }
              onChange={(parts: unknown) => {
                setFieldValue('customContent', parts);
              }}
              allowCreateNewItem
              createNewItemText={t('exports.entryId.createStaticValue')}
            />
          </div>
        )}
        {isDeletable && (
          <Button
            hasNegativeMargins
            className="mt-m"
            variant="ghost"
            iconName="minus-circle"
            iconPosition="left"
            text={t('exports.removeColumn')}
            onClick={onRemoveColumn}
          />
        )}
      </div>
    </Modal>
  );
};
