import { Button, SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useState } from 'react';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';

import { TeamsTab, type TeamsTabProps, type TeamModal } from './TeamsTab';

export type TeamsTabContainerProps = {
  isLoadingUsers: boolean;
  fetchApprovalSchemes: () => void;
} & TeamsTabProps;

export const TeamsTabContainer = ({
  users,
  groups,
  approvalSchemes,
  isLoadingUsers,
  // actions
  fetchApprovalSchemes,
  ...rest
}: TeamsTabContainerProps) => {
  const { t } = useTranslation('global');

  const [openModal, setOpenModal] = useState<TeamModal | null>(null);
  const [searchPattern, setSearchPattern] = useState('');

  const filteredGroups = useMemo(() => {
    if (!searchPattern) {
      return groups;
    }
    return groups.filter((group) =>
      group.name?.match(new RegExp(searchPattern, 'i')),
    );
  }, [groups, searchPattern]);

  useEffect(() => {
    fetchApprovalSchemes();
  }, []);

  return (
    <div>
      <div className="mb-s flex items-center">
        <div className="flex flex-1 flex-col">
          <div className="text-complementary title-xl">
            {t('members.teamsTitle')}
          </div>
          <span className="-mt-xs text-neutral-dark body-m">
            <span>{t('members.teamsCount', { count: groups?.length })}</span>
          </span>
        </div>
        <SearchFilter
          className="mr-s"
          search={searchPattern}
          setSearch={(search) => setSearchPattern(search)}
          minLength={0}
          placeholder={t('members.searchInTeams')}
        />
        <Button
          isDisabled={isLoadingUsers || !groups || !users?.length}
          onClick={() => setOpenModal('createTeam')}
          variant="primary"
          text={t('members.createANewTeam')}
        />
      </div>

      {!isLoadingUsers && groups && users && users?.length > 0 ? (
        <TeamsTab
          groups={filteredGroups}
          users={users}
          approvalSchemes={approvalSchemes}
          openModal={openModal}
          setOpenModal={setOpenModal}
          {...rest}
        />
      ) : (
        <SkeletonTable
          numberOfRows={3}
          withHeader
          columns={[
            {
              header: <SkeletonText width="50%" />,
              cell: <SkeletonText width="70%" />,
              width: '25%',
            },
            {
              header: <SkeletonText width="20%" />,
              cell: <SkeletonText width="30%" />,
              width: '75%',
            },
          ]}
        />
      )}
    </div>
  );
};
