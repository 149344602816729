import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const ProgressBoxErrorCallout = () => {
  const { t } = useTranslation('global');

  return (
    <Callout
      title={t('misc.errors.unknownError')}
      variant="alert"
      className="my-s"
    />
  );
};
