import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { useEditCostCenterQuery } from './hooks/useEditCostCenterQuery';
import { CostCenterFormModal } from '../../components/CostCenterFormModal';
import type { CostCenter } from '../../costCenter';
import type { Member } from '../../member';

type Props = {
  costCenter: CostCenter;
  members: Member[];
  isOpen: boolean;
  onComplete(): void;
  onCancel(): void;
};

export const CostCenterEditionFormModalContainer = ({
  costCenter,
  members,
  isOpen,
  onComplete,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();
  const [handleOnSubmit] = useEditCostCenterQuery(costCenter.id);

  const costCentersQueryState = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQueryState) ?? [];

  return (
    <CostCenterFormModal
      isOpen={isOpen}
      costCenter={costCenter}
      members={members}
      costCenters={costCenters}
      onSubmit={async (updatedCostCenter) => {
        track(AnalyticEventName.APPROVALS_WORKFLOW_SETUP_SUBMIT);
        try {
          await handleOnSubmit({
            ...updatedCostCenter,
            approvalRules: toApiApprovalRules(updatedCostCenter.approvalRules),
          });
          pushNotif({
            type: NotificationType.Success,
            message: t('costCenters.edition.success', {
              name: updatedCostCenter.name,
            }),
          });
          onComplete();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('costCenters.edition.error'),
          });
        }
      }}
      onCancel={onCancel}
    />
  );
};
