import { FormField, TextArea } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type PurchaseOrderFormData } from '../../../../../hooks';

export const EditableDescriptionSection = ({
  formikProps,
}: PurchaseOrderFormData) => {
  const { t } = useTranslation();
  const { errors, values, handleChange, handleBlur } = formikProps;

  return (
    <FormField
      className="PurchaseOrderRequestForm__field"
      label={t('requests.panel.purchaseOrder.description')}
      visuallyHideLabel
      alertMessage={errors.description ? t(errors.description) : undefined}
    >
      <TextArea
        name="description"
        placeholder={t('forms.description.placeholder')}
        value={values.description}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={255}
      />
    </FormField>
  );
};
