import { Tag } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { getIsController, getUserId } from 'src/core/selectors/globalSelectors';

import {
  type Request,
  type ExtendedRequestState,
  isLegacyRequest,
} from '../../../models';

type RequestStateTagProps = {
  request: Request;
};

export const RequestStateTag = ({ request }: RequestStateTagProps) => {
  const { t } = useTranslation('global');
  const isUserController = useSelector(getIsController);
  const userId = useSelector(getUserId);

  const requester = isLegacyRequest(request) ? request.user : request.requester;
  // needed to add a security as for drafts this can be null (was not used for draft before start of the revamp)
  const isUserOwnRequest = userId === requester?.id;

  return (
    <Tag
      className="ml-xs"
      {...getRequestStateToTagProps(
        request.state,
        isUserOwnRequest,
        isUserController,
        t,
      )}
    />
  );
};

const getRequestStateToTagProps = (
  requestState: ExtendedRequestState,
  isUserOwnRequest: boolean,
  isUserController: boolean,
  t: TGlobalFunctionTyped,
): {
  children: string;
  variant: 'success' | 'alert' | 'warning' | 'info';
} => {
  switch (requestState) {
    case 'approved':
      return {
        children: t('requests.filters.approved'),
        variant: 'success',
      };
    case 'refused':
      return {
        children: t('requests.filters.refused'),
        variant: 'alert',
      };
    case 'expired':
      return {
        children: t('requests.filters.expired'),
        variant: 'warning',
      };
    case 'rejected_by_controller':
      return {
        children: t('requests.filters.rejected'),
        variant: 'alert',
      };
    default: {
      let children = t('requests.filters.pending');
      if (isUserController) {
        children = t('requests.filters.toApprove');
      }
      if (isUserOwnRequest) {
        children = t('requests.filters.submitted');
      }
      return {
        children,
        variant: 'info',
      };
    }
  }
};
