import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import logout from "src/core/utils/logout";

class LogoutButton extends Component {
  logout = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    await logout();
    window.location.href = '/auth/login';
  };

  render() {
    const { t, className } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      (<a
        className={cx('LogoutButton', className)}
        href="#"
        onClick={this.logout}
      >
        {t('dropdownLinks.logout')}
      </a>)
    );
  }
}

LogoutButton.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

LogoutButton.defaultProps = {
  className: '',
};

// Explicitly setting namespace because it's also used in onboarding app
export default withTranslation('global')(LogoutButton);
