import AjaxInterceptor from 'ajax-interceptor';
import fetchIntercept from 'fetch-intercept';
import get from 'lodash/get';
import includes from 'lodash/includes';

import { isOnboardingPathname, getOnboardingUrl } from './onboarding';

const isJSON = (contentType) => includes(contentType, 'application/json');

// eslint-disable-next-line sonarjs/cognitive-complexity
const handleResponse = async (response) => {
  const { status } = response;
  let url;
  let body;

  // Extract information from XMLHttpRequest's responses
  if (response instanceof XMLHttpRequest) {
    url = response.responseURL;

    if (isJSON(response.getResponseHeader('Content-Type'))) {
      try {
        body = JSON.parse(response.response);
      } catch {
        // It's not the interceptor responsibility to handle invalid JSON
        return response;
      }
    }
  }

  // Extract information from Fetch's Response
  if (response instanceof Response) {
    ({ url } = response);
    if (isJSON(response.headers.get('Content-Type'))) {
      try {
        // .clone() to avoid locking the body
        body = await response.clone().json();
      } catch {
        // It's not the interceptor responsibility to handle invalid JSON
        return response;
      }
    }
  }

  // FIXME: it may be a bit dangerous to just check for 'spendesk' in the URL :o
  if (/(spendesk)/.test(url) === false) {
    // We only want the interceptor to match requests on our API
    return response;
  }

  // Handle Unauthorized responses
  if (status === 401) {
    // Checks if the request targets an onboarding API
    // FIXME: this is a bad check because it doesn't ensure that the call comes
    // from the onboarding UI
    const isOnOnboarding = isOnboardingPathname();
    const hasRegistrationToken = includes(
      window.location.search,
      'registrationToken=',
    );

    // Don't handle "TooManyCredentials" errors
    if (isOnOnboarding && get(body, 'error.name') === 'TooManyCredentials') {
      return response;
    }

    // Redirect to session expired or login page
    window.location.href =
      isOnOnboarding && !hasRegistrationToken
        ? getOnboardingUrl({ pathname: 'session-expired' })
        : `/auth/login?session-expired=1&targetUrl=${encodeURIComponent(
            window.location.href,
          )}`;
  }

  return response;
};

fetchIntercept.register({
  response: handleResponse,
});
AjaxInterceptor.addResponseCallback(handleResponse);
AjaxInterceptor.wire();
