import pick from 'lodash/pick';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { updateRequestLocally } from 'src/core/modules/requests/redux/legacy/actions';
import { getExpenseCategoryCustomFieldId } from 'src/core/modules/requests/redux/legacy/selectors';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

import { useUpdatePurchaseOrderRequest } from './useUpdatePurchaseOrderRequest';
import {
  type PurchaseOrderRequestFormValues,
  usePurchaseOrderRequestForm,
} from '../../hooks';
import { type DraftPurchaseOrderRequest } from '../../models';
import {
  customFieldToCustomFieldAssociation,
  getExpenseCategory,
  type PurchaseOrderRequestDetails,
} from '../models';

export const useUpdatePurchaseOrderRequestForm = (
  request: PurchaseOrderRequestDetails,
  fieldsToUpdate?: Array<keyof DraftPurchaseOrderRequest>,
) => {
  const dispatch = useDispatch();
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');

  const [updatePurchaseOrderRequest] = useUpdatePurchaseOrderRequest(
    request.id,
  );

  const expenseCategoryCustomFieldId = useSelector(
    getExpenseCategoryCustomFieldId,
  );
  const expenseCategory = getExpenseCategory(
    request,
    expenseCategoryCustomFieldId,
  );

  const initialValues: PurchaseOrderRequestFormValues = {
    description: request.description,
    startDate: new Date(request.startDate),
    endDate: new Date(request.endDate),
    team: request.team ?? null,
    costCenter: request.costCenter ?? null,
    expenseCategory: expenseCategory ?? null,
    amount: request.amount.amount,
    netAmount: request.netAmount
      ? request.netAmount.amount
      : request.amount.amount,
    currency: getCurrencyOptionByKey(request.amount.currency),
    supplier: request.supplier,
    customFieldAssociations: request.customFields
      .filter(
        // Reject the expenseCategory from customFields to avoid duplicates when the final customFieldAssociations array is sent back to the server
        (customField) => customField.field.id !== expenseCategoryCustomFieldId,
      )
      .map(customFieldToCustomFieldAssociation),
    quotes: [],
  };

  const onSubmit = async (
    draftPurchaseOrderRequest: DraftPurchaseOrderRequest,
  ) => {
    const partialDraftPurchaseOrderRequest = fieldsToUpdate
      ? pick(draftPurchaseOrderRequest, fieldsToUpdate)
      : draftPurchaseOrderRequest;

    const data = await updatePurchaseOrderRequest(
      partialDraftPurchaseOrderRequest,
    );
    // @ts-expect-error Action is defined in a JS file
    dispatch(updateRequestLocally(data));
    pushNotif({
      type: NotificationType.Success,
      message: t('requests.panel.purchaseOrder.updateMessage'),
    });
  };

  return usePurchaseOrderRequestForm({
    initialValues,
    onSubmit,
  });
};
