import { type ClientInferResponses } from '@ts-rest/core';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

type Response = ClientInferResponses<
  typeof sfsMigrationContract.subscriptions.getSubscriptions,
  200
>['body'];

const key = ['sfs-migration', 'sfs-subscriptions'];

export const useSubscriptionsQuery = ({
  isEnabled = false,
}: {
  isEnabled: boolean;
}): QueryState<Response> => {
  return useQuery<Response>({
    key,
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/sfs-migration/subscriptions',
    },
    reshapeData(data) {
      return data;
    },
  });
};
