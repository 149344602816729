import { SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUser } from 'modules/app/hooks/useUser';
import { useRawCustomFieldsQuery } from 'modules/custom-fields/hooks/useRawCustomFieldsQuery';
import { QueryError } from 'src/core/common/components/QueryError';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';

import { CustomFieldFilter } from './CustomFieldFilter';
import { type CustomFieldFiltersType } from './types';

type Props = {
  eligibleTypes: string[];
  filters: CustomFieldFiltersType;
  setFilters: (filters: CustomFieldFiltersType) => void;
};

export const CustomFieldFilters = ({
  eligibleTypes,
  filters,
  setFilters,
}: Props) => {
  const companyId = useCompanyId();
  const user = useUser();

  const customFieldsQueryState = useRawCustomFieldsQuery();

  const teamIds = user.data_by_company[companyId].groups_ids ?? [];

  return (
    <QuerySuspense
      queryState={customFieldsQueryState}
      loading={
        <div className="mb-s">
          <SkeletonText width="40%" />
          <SkeletonText size="xl" />
        </div>
      }
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'finance-controller',
          }}
          translations={{
            serverError: 'filters.customFields.errors.fetchCustomFields',
          }}
        />
      )}
    >
      {(customFields) => {
        const eligibleCustomFields = getEligibleCustomFields(customFields, {
          types: eligibleTypes,
          all: true,
          teamIds,
          user,
        });
        return (
          <>
            {eligibleCustomFields.map((customField) => (
              <CustomFieldFilter
                key={customField.id}
                filters={filters}
                customField={customField}
                setFilters={setFilters}
              />
            ))}
          </>
        );
      }}
    </QuerySuspense>
  );
};
