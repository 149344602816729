import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const WireTransfersDeclinedModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      iconName="failure"
      iconVariant="alert"
      title={t('wiretransfer.modal.errors.transfersDeclined.title')}
      subtitle={t('wiretransfer.modal.errors.transfersDeclined.desc')}
      actions={[
        <Button
          key="continue"
          text={t('wiretransfer.modal.errors.transfersDeclined.continue')}
          variant="primary"
          onClick={onClose}
        />,
      ]}
    >
      {/* FIXME: for now children props is mandatory, remove when it's not the case anymore */}
      {null}
    </Modal>
  );
};
