import { type ClientInferResponseBody } from '@ts-rest/core';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

export type Tasks = ClientInferResponseBody<
  typeof sfsMigrationContract.tasks.getTasks,
  200
>;

export type StepName = keyof Tasks['data'];

export const useTasksQuery = ({
  isEnabled,
}: {
  isEnabled: boolean;
}): QueryState<Tasks> => {
  return useQuery<Tasks, Tasks>({
    key: ['tasks'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/sfs-migration/tasks',
    },
    reshapeData(tasks) {
      return tasks;
    },
  });
};
