import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import './CustomFieldsPendingActivation.scss';

// FIXME: this component is deprecated and will be removed when the expense
// categories feature will be open to everyone
const CustomFieldsPendingActivation = () => {
  const { t } = useTranslation();
  return (
    <div className="CustomFieldsPendingActivation">
      <h1 className="CustomFieldsPendingActivation__title">
        {t('customFields.pendingActivationEmptyState.title')}
      </h1>
      <h2 className="CustomFieldsPendingActivation__subtitle">
        {t('customFields.pendingActivationEmptyState.subtitle')}
      </h2>
      <p className="CustomFieldsPendingActivation__text">
        <Trans i18nKey="customFields.pendingActivationEmptyState.text">
          You can
          <a // eslint-disable-line jsx-a11y/anchor-is-valid
            href="#"
            id="intercom-message"
            className="CustomFieldsPendingActivation__text-link"
          >
            chat with us
          </a>
          for more information about custom fields.
        </Trans>
      </p>
      <img
        height={413}
        className="CustomFieldsPendingActivation__image"
        src="/static/img/custom-fields/activate@1x.png"
        srcSet="/static/img/custom-fields/activate@2x.png 2x"
        draggable={false}
        alt=""
      />
    </div>
  );
};

export default memo(CustomFieldsPendingActivation);
