import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExtendedRequestState } from '../../../requests/models';
import {
  type ApprovalRule,
  type ApprovalRuleState,
} from '../../models/approver';

export type RequestApproval = {
  state: ExtendedRequestState;
  approval: {
    state: ApprovalRuleState;
    rules: ApprovalRule[];
  };
  auto_approved: boolean;
  direct_approver: boolean;
  user_id: string;
};

export const useRequestApprovalQuery = (
  requestId: string,
): QueryState<RequestApproval> => {
  return useQuery<RequestApproval>({
    key: ['requests', requestId],
    options: {
      cacheTime: 1 * 1000, // 5sec,
      staleTime: 1 * 1000, // 5sec,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}`,
    },
    reshapeData: (request) => {
      if (request.type === 'purchase_order') {
        return {
          state: request.state,
          approval: {
            state: request.approval?.state?.state,
            rules: request.approval?.state?.rules,
          },
          auto_approved: request.approval.autoApproved,
          direct_approver: request.approval.directApprover ?? false,
          user_id: request.requester.id,
        };
      }
      return {
        state: request?.state,
        approval: {
          state: request.approval_state?.state,
          rules: request.approval_state?.rules,
        },
        auto_approved: request.auto_approved,
        direct_approver: request.direct_approver,
        user_id: request.user_id,
      };
    },
  });
};
