import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';

export const getIsFeatureEnabled = ({ global }, feature) => {
  const { config } = global;
  const features = get(config, 'features', {});

  return (
    Object.prototype.hasOwnProperty.call(features, feature) &&
    Boolean(features[feature])
  );
};

export const getIsFeaturePartiallyEnabled = ({ global }, feature) => {
  const { config } = global;
  const features = get(config, 'features', {});

  return (
    Object.prototype.hasOwnProperty.call(features, feature) &&
    features[feature] === 'partial'
  );
};

export const getConfig = (state) => state.global.config;

export const getBankInfoIsLoading = ({ global }) =>
  global.bankInfoBehavior.isLoading;

export const getBankInfoError = ({ global }) => global.bankInfoBehavior.error;

export const getBankInfo = ({ global }) => global.user.bankInfo || {};

export const getSupervisor = ({ global }) => global.supervisor;

export const getIsSupervisionActive = (state) => Boolean(getSupervisor(state));

export const getIsSupervisedUserSwitchedModalVisible = ({
  switchedSupervisedUserModal,
}) => Boolean(switchedSupervisedUserModal.isVisible);

export const getCompanyCurrency = ({ global }) => global.company.currency;

export const getCompanyCountry = ({ global }) => global.company.country;

export const getRequiredInfoTypesForEmployeeBankAddress = ({ global }) =>
  global.user.data_by_company[global.company.id]
    .requiredInfoTypesForEmployeeBankAddress ?? [];

export const getCompany = (state) => state.global.company;

export const getUserDataByCompany = (state) =>
  state.global.user.data_by_company[state.global.company.id];

export const getIsLimitedAccessActive = (state) =>
  Boolean(
    // company is null by default and this selector is used in LayoutContainer
    state.global.company &&
      // there is no limited access with bankable
      state.global.company.banking_provider === 'marqeta' &&
      // if the company has been validated in forest, it's not limited access anymore
      !state.global.company.validated_at,
  );

export const getIsLoadingPaymentMethods = (state) =>
  state.global.paymentMethodsBehavior.isLoading;

export const getCompanyPaymentMethods = (state) =>
  state.global.company.paymentMethods;

export const getHasRequesterHomepage = (state) =>
  state.global.config.ui?.showRequesterHomepage;

export const getHasControllerHomepage = (state) =>
  state.global.config.ui?.showControllerHomepage;

export const getIsWelcomeDashboardDismissed = (state) =>
  state.global.config.ui?.isOperationalTasksDismissed;

export const getShowRequesterOnboardingPopup = (state) =>
  state.global.config.ui?.showRequesterOnboardingPopup;

export const getIsReimburseViaSepaEnabled = (state) =>
  state.global.config.ui?.isReimburseViaSepaEnabled;

export const getHasHomepage = (state) =>
  getHasRequesterHomepage(state) || getHasControllerHomepage(state);

export const getShowKybProcedureValidatedPopup = (state) =>
  state.global.config.ui?.showKybProcedureValidatedPopup;

export const getShowKybProcedureLimitedAccessOpenedPopup = (state) =>
  state.global.config.ui?.showKybProcedureLimitedAccessOpenedPopup;

export const getTargetApp = (state) => state.global.config.ui?.targetApp;

/**
 * @deprecated use capabilities from accounting integration instead
 */
export const getAreVatAccountsEnabled = (state) =>
  state.global.company.accounting_setting?.are_vat_accounts_enabled;

export const getUser = (state) => state.global.user;
export const getUserId = (state) => state.global.user?.id;

export const getIsController = (state) => state.global.user.is_controller;
export const getIsAccountOwner = (state) => state.global.user.is_account_owner;

export const getUserRoles = createSelector(getUser, (user) => {
  return {
    isRequester: user.is_requester,
    isAdmin: user.is_admin,
    isController: user.is_controller,
    isAccountOwner: user.is_account_owner,
  };
});

export const getUserCostCenter = (state) => state.global.user.costCenter;

export const getHasImpersonationTargets = (state) =>
  state.global.hasImpersonationTargets;

export const getImpersonator = (state) => state.global.impersonator;
