import { useMemo } from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useKycStatusCode } from 'src/core/modules/kyc/hooks/useKycStatusCode';
import { useSFSMigration } from 'src/sfs-migration/used-in-js-app/hooks/useSFSMigration';

import { HeaderLoadNotif } from './HeaderLoadNotif';

export const HeaderLoadNotifContainer = () => {
  const company = useCompany();
  const user = useUser();

  const kycStatusCode = useKycStatusCode();
  const isKycSubmitted = useMemo(
    () =>
      kycStatusCode && !['not-started', 'initiated'].includes(kycStatusCode),
    [kycStatusCode],
  );

  const isAoOrAdmin = user.is_account_owner || user.is_admin;

  const shouldDisplayLoadNotif =
    isKycSubmitted === true && isAoOrAdmin && !company.is_kyb_validated;

  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const sfsMigrationQuery = useSFSMigration({
    isEnabled: hasTMPSFSMigrationPage && shouldDisplayLoadNotif,
  });

  if (!shouldDisplayLoadNotif) {
    return null;
  }

  const isMigrationQueryLoading =
    hasTMPSFSMigrationPage && sfsMigrationQuery.status === 'loading';
  const shouldDisplayBanner =
    sfsMigrationQuery.status === 'success' &&
    sfsMigrationQuery.data.displayBanner;

  // early return when loading to prevent flashing
  if (isMigrationQueryLoading || shouldDisplayBanner) {
    return null;
  }

  return <HeaderLoadNotif company={company} />;
};
