import { Icon, IconButton, colors } from '@dev-spendesk/grapes';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { Option } from './Option';
import { filterRoutes } from './filterRoutes';
import { useCreateRoutes } from './useCreateRoutes';
import { useHighlight } from './useHighlight';

import './search.css';

/**
 * TODO:
 * - [V2] Add real logout (cf src/core/utils/logout.js)
 * - [V2] Filter on user roles / supervision / F.Ts
 */

function extractToken(pattern: string): string[] {
  return pattern.split(' ').filter((token) => token.length > 0);
}

const SearchForPageContent = () => {
  const { t } = useTranslation('global');
  const modalReference = useRef<HTMLDialogElement | null>(null);
  const listboxReference = useRef<HTMLUListElement | null>(null);
  const [searchPattern, setSearchPattern] = useState('');
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined,
  );
  const highlight = useHighlight(listboxReference);
  const allRoutes = useCreateRoutes();
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // eslint-disable-next-line default-case
      switch (event.key) {
        case 'k':
        case 'K':
          if (event.metaKey) {
            event.preventDefault(); // Prevent Firefox default behavior
            modalReference.current?.showModal();
          }
          break;
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    highlight(extractToken(searchPattern));
  }, [searchPattern, highlight]);

  const closeModal = () => {
    modalReference.current?.close();
  };

  const handleClose = () => {
    setSelectedIndex(undefined);
    setSearchPattern('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    // eslint-disable-next-line default-case
    switch (event.key) {
      case 'ArrowDown':
        return setSelectedIndex(
          (index) => ((index ?? -1) + 1) % searchResults.length,
        );
      case 'ArrowUp':
        return setSelectedIndex((index) => {
          const newIndex = (index ?? searchResults.length) - 1;
          if (newIndex < 0) {
            return searchResults.length - 1;
          }
          return newIndex;
        });
    }
  };

  const handleSubmit = () => {
    if (selectedIndex === undefined) {
      return;
    }
    const route = searchResults.at(selectedIndex);

    if (route?.url) {
      if (route?.isExternal) {
        window.location.replace(route?.url);
        return;
      }
      return history.push(route?.url);
    }
  };

  const searchResults = filterRoutes(allRoutes, searchPattern);
  return (
    <dialog ref={modalReference} className="search" onClose={handleClose}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        method="dialog"
        className="search-body"
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit}
      >
        <div className="seach-input-wrapper">
          <Icon name="search" color={colors.neutralDark} size="m" />
          <input
            placeholder={t('searchPage.placeHolder')}
            type="text"
            pattern="[\w\s]+"
            value={searchPattern}
            onChange={(event) => {
              if (event.target.checkValidity()) {
                setSearchPattern(event.target.value);
                setSelectedIndex(0);
              }
            }}
          />
          <IconButton
            iconName="cross"
            iconColor={colors.neutralDark}
            aria-label="Close"
            onClick={closeModal}
          />
        </div>

        <ul ref={listboxReference} className="search-list" role="listbox">
          {searchResults.length === 0 && (
            <span className="text-complementary title-m">
              {t('misc.noResultFor', { search: searchPattern })}
            </span>
          )}
          {searchResults.map((option, index) => (
            <Option
              key={option.url + option.label}
              label={option.label}
              url={option.url}
              category={option.category}
              isExternal={option.isExternal}
              isSelected={selectedIndex === index}
              onOptionClick={closeModal}
            />
          ))}
        </ul>
      </form>
    </dialog>
  );
};

export const SearchForPage = () => {
  return (
    <ErrorBoundary
      context={{
        team: 'none',
        scope: 'search-for-page',
      }}
      fallbackComponent={null}
    >
      <SearchForPageContent />
    </ErrorBoundary>
  );
};
