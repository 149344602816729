import { DATE_FORMAT, SkeletonText } from '@dev-spendesk/grapes';
import { isValid } from 'date-fns';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { type LegacyRequest } from 'modules/requests';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useRequestDenierQuery } from './useRequestDenierQuery';
import { displayFullname } from '../../utils/displayFullname';
import { MemberTag } from '../MemberTag/MemberTag';

type Props = {
  requestId: LegacyRequest['id'];
};

export const RequestDenier = ({ requestId }: Props) => {
  const user = useUser();
  const { t, localeFormat } = useTranslation('global');
  const denierQuery = useRequestDenierQuery(requestId);

  return (
    <QuerySuspense
      queryState={denierQuery}
      loading={<SkeletonText width="125px" />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'capture',
          }}
        />
      )}
    >
      {(denier) => (
        <MemberTag
          avatar={denier.avatar}
          name={displayFullname({
            fullname: denier.fullname,
            isSelf: user.id === denier.id,
            t,
          })}
          tooltip={
            isValid(denier.denialDate) ? (
              <div>
                {t('requests.progressBox.approvalFailedStep.refused')}
                <br />
                {localeFormat(denier.denialDate, DATE_FORMAT.LONG_WITH_TIME)}
              </div>
            ) : undefined
          }
        />
      )}
    </QuerySuspense>
  );
};
