import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type CreationPayload = {
  name: string;
  ownerId: string;
  approvalRules: ApprovalRuleApi[];
};

export const useCreateCostCenterQuery = (): MutationState<CreationPayload> => {
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  return useMutation<CreationPayload>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: '/cost-centers',
    },
    options: {
      throwOnError: true,
      onSuccess(): void {
        invalidateCostCentersQuery();
      },
    },
  });
};
