import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Budget = {
  id: string;
  budgetaryExerciseId: string;
};

export const useBudgetFromCostCenterQuery = (
  costCenterId: string,
): QueryState<Budget | undefined> => {
  return useQuery<Budget | undefined>({
    key: ['budgets', 'costCenter', costCenterId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgets/cost-center/${costCenterId}/active`,
    },
    reshapeData: (data) => {
      return {
        id: data.id,
        budgetaryExerciseId: data.budgetaryExerciseId,
      };
    },
  });
};
