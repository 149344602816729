import { v4 as uuid } from 'uuid';

import { insertAt, replaceAt } from 'common/utils/array';
import { type CurrenciesKey } from 'src/core/config/money';

import {
  type ApprovalRight,
  type ApprovalRule,
  type ApprovalRuleApi,
  type ApprovalSchemeApi,
  type SelectedApprover,
} from './types';

const has = (key: number, map: { [key: number]: ApprovalRule }): boolean => {
  return Boolean(map[key]);
};

export const isMultiStep = (steps: object[]): boolean => {
  return steps.length > 1;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeRules = (rules: any[]): ApprovalRule[] => {
  const normalizedRules = rules.reduce(
    (accumulator, rule) => {
      const from = rule.isAoRule ? Number.NEGATIVE_INFINITY : rule.from.value;
      const previousSteps = has(from, accumulator)
        ? accumulator[from].steps
        : [];
      const steps = isMultiStep(rule.steps)
        ? previousSteps.concat(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rule.steps.map((step: any) => ({ rights: [step] })),
          )
        : [
            {
              rights: previousSteps.length
                ? previousSteps[0].rights.concat(rule.steps)
                : rule.steps,
            },
          ];
      return {
        ...accumulator,
        [from]: {
          ...rule,
          steps,
        },
      };
    },
    {} as { [key: number]: ApprovalRule },
  );
  return Object.values(normalizedRules);
};

const updateFromValue =
  (value: number | null) =>
  (rule: ApprovalRule): ApprovalRule => ({
    ...rule,
    from: {
      ...rule.from,
      value,
    },
  });

const updateUpToValue =
  (value: number | null) =>
  (rule: ApprovalRule): ApprovalRule => ({
    ...rule,
    upTo: {
      ...rule.upTo,
      value,
    },
  });

export const fixRules = (approvalRules: ApprovalRule[]): ApprovalRule[] => {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const loop = (start: number, rules: ApprovalRule[]): ApprovalRule[] => {
    const rule = rules[start];
    const previousRule = rules[start - 1];

    if (start === rules.length) {
      return rules;
    }
    if (start === rules.length - 1) {
      const fromValue = start === 0 ? 0 : previousRule.upTo.value;
      const updateRule = (updatedRule: ApprovalRule) => {
        const updatedRuleWithFromValue =
          updateFromValue(fromValue)(updatedRule);
        return updateUpToValue(null)(updatedRuleWithFromValue);
      };
      return replaceAt(rules, start, updateRule(rule));
    }
    if (start === 0) {
      const updateRule = updateFromValue(0);
      return loop(start + 1, replaceAt(rules, start, updateRule(rule)));
    }
    if (previousRule.upTo.value && rule.from.value) {
      if (
        previousRule.upTo.value >= rule.from.value &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        previousRule.upTo.value >= rule.upTo.value!
      ) {
        const updateRule = (updatedRule: ApprovalRule) => {
          const upToValue = previousRule.upTo.value ?? 0;
          const updatedRuleWithFromValue =
            updateFromValue(upToValue)(updatedRule);
          return updateUpToValue(upToValue + 1000)(updatedRuleWithFromValue);
        };
        return loop(start + 1, replaceAt(rules, start, updateRule(rule)));
      }
      if (
        (previousRule.upTo.value <= rule.from.value &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          rule.from.value <= rule.upTo.value!) ||
        (previousRule.upTo.value >= rule.from.value &&
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          previousRule.upTo.value <= rule.upTo.value!)
      ) {
        const updateRule = updateFromValue(previousRule.upTo.value);
        return loop(start + 1, replaceAt(rules, start, updateRule(rule)));
      }
    }
    return loop(start + 1, rules);
  };
  return loop(0, approvalRules);
};

export const collectApproverIds = (rule: ApprovalRule): string[] => {
  return rule.steps.flatMap((s) => s.rights.map((r) => r.approverId));
};

export const createRight = (approver: SelectedApprover): ApprovalRight => ({
  approverId: approver.type === 'costCenterOwner' ? '' : approver.id,
  approverType: approver.type,
});

const toApiMultiStep = (rule: ApprovalRule): ApprovalRuleApi => {
  const steps = rule.steps.flatMap((step) => step.rights);
  return {
    steps,
    from: rule.from,
    isAoRule: rule.isAoRule,
    upTo: rule.upTo,
  };
};

const toApiSingleStep = (rule: ApprovalRule): ApprovalRuleApi[] => {
  return rule.steps.flatMap((step) =>
    step.rights.map((right) => ({
      steps: [right],
      from: rule.from,
      isAoRule: rule.isAoRule,
      upTo: rule.upTo,
    })),
  );
};

export const toApiFormat = (rules: ApprovalRule[]): ApprovalRuleApi[] => {
  return rules.flatMap((rule) =>
    isMultiStep(rule.steps) ? toApiMultiStep(rule) : toApiSingleStep(rule),
  );
};

export const isTheLastRule = (
  rules: ApprovalRule[],
  ruleIndex: number,
): boolean => {
  return rules.length - 1 === ruleIndex;
};

export const shouldRemoveAoRule = (approvalScheme: {
  rules: ApprovalRuleApi[];
}): boolean => {
  return (
    approvalScheme.rules.length > 1 &&
    approvalScheme.rules
      .filter((r) => r.isAoRule)
      .some((rule) => rule.from.value === null && rule.upTo.value === null)
  );
};

export const removeAoRule = <T extends { rules: ApprovalRuleApi[] }>(
  approvalScheme: T,
): T => ({
  ...approvalScheme,
  rules: approvalScheme.rules.filter((rule) => !rule.isAoRule),
});

export const hasManyAoRule = (scheme: ApprovalSchemeApi): boolean => {
  const aoRuleCount = scheme.rules.reduce(
    (accumulator, rule) => (rule.isAoRule ? accumulator + 1 : accumulator),
    0,
  );
  return aoRuleCount > 1;
};

export const validate = (rules: ApprovalRule[]): string[] => {
  return rules.reduce<string[]>((ids, rule) => {
    if (!rule.steps.length) {
      return ids.concat(rule.id);
    }
    return ids;
  }, []);
};

export const toMultipleStep = (rule: ApprovalRule): ApprovalRule => {
  const steps = rule.steps.flatMap((step) =>
    step.rights.map((right) => ({ rights: [right] })),
  );
  return {
    ...rule,
    steps,
  };
};

export const toSingleStep = (rule: ApprovalRule): ApprovalRule => {
  const rights = rule.steps.flatMap((step) =>
    step.rights.map((right) => right),
  );
  return {
    ...rule,
    steps: [{ rights }],
  };
};

export const createRule = (rules: ApprovalRule[]): ApprovalRule[] => {
  const lastIndex = rules.length - 1;
  const previousRule = rules[lastIndex - 1];
  const lastRule = rules[lastIndex];
  // we should have at least 1 rule to add a new rule
  if (!lastRule) {
    return rules;
  }
  const { currency } = lastRule.upTo;
  // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
  const fromValue = previousRule?.upTo.value ? previousRule.upTo.value : 0;

  const newRule = {
    id: uuid(),
    isAoRule: false,
    from: {
      value: fromValue,
      currency,
    },
    upTo: {
      currency,
      value: fromValue + 1000,
    },
    steps: [...lastRule.steps],
  };
  lastRule.steps = [];
  return fixRules(insertAt(rules, lastIndex, newRule));
};

export const updateRule = (
  rules: ApprovalRule[],
  ruleIndex: number,
  rule: ApprovalRule,
): ApprovalRule[] => {
  return fixRules(replaceAt(rules, ruleIndex, rule));
};

export const deleteRule = (
  rules: ApprovalRule[],
  ruleId: string,
): ApprovalRule[] => {
  return fixRules(rules.filter((rule) => rule.id !== ruleId));
};

export const createFirstRule = (
  costCenterOwnerId: string,
  currency: CurrenciesKey,
): ApprovalRule => {
  return {
    id: uuid(),
    isAoRule: false,
    steps: [
      {
        rights: [
          {
            approverId: costCenterOwnerId,
            approverType: 'user',
          },
        ],
      },
    ],
    from: { value: 0, currency },
    upTo: { value: null, currency },
  };
};
