import { Route } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { type NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import {
  CardsContainer,
  MyCardContainer,
} from 'src/core/modules/physical-cards';

import { SubscriptionsPage } from '../subscriptions';

export const useAccessibleCardsLinks = (): NavigationLinkProps[] => {
  const company = useCompany();
  const user = useUser();
  const { t } = useTranslation('global');

  const hasPlasticCardsFeature = useFeature(FEATURES.PLASTIC_CARDS);

  if (company.churning_at) {
    return [];
  }

  const isMyCardAccessible = hasPlasticCardsFeature && user.has_plastic_card;
  const isSubscriptionAccessible = company.type !== 'branch_expense_entity';
  const isCardsAccessible =
    (user.is_account_owner || user.is_admin) && hasPlasticCardsFeature;

  const links = [];

  if (isCardsAccessible) {
    links.push({
      key: 'physical-cards',
      text: t('cards.physicalCardsTitle'),
      path: routeFor(routes.CARDS.path, {
        company: company.id,
      }),
    });
  }

  if (isSubscriptionAccessible) {
    links.push({
      key: 'subscriptions',
      text: t('misc.subscription_plural'),
      path: routeFor(routes.SUBSCRIPTIONS.path, {
        company: company.id,
      }),
    });
  }

  if (isMyCardAccessible) {
    links.push({
      key: 'mycard',
      text: t('cards.myCardTitle'),
      path: routeFor(routes.CARD.path, {
        company: company.id,
      }),
    });
  }

  return links;
};

export const CardsPage = () => {
  const links = useAccessibleCardsLinks();
  const { t } = useTranslation('global');

  const hasAccessToSubscription = links.some(
    (link) => link.key === 'subscriptions',
  );
  const hasAccessToCards = links.some((link) => link.key === 'physical-cards');

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('misc.card_plural')}
      </HeaderWithNavigation>
      <Route path={routes.CARD.path} component={MyCardContainer} />
      <ProtectedRoute
        path={routes.CARDS.path}
        isAccessAllowed={hasAccessToCards}
      >
        <CardsContainer />
      </ProtectedRoute>
      <ProtectedRoute
        path={routes.SUBSCRIPTIONS.path}
        isAccessAllowed={hasAccessToSubscription}
      >
        <SubscriptionsPage />
      </ProtectedRoute>
    </div>
  );
};
