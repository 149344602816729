import React from 'react';

import { FileViewerModal } from '../FileViewerModal/FileViewerModal';
import { Modes } from '../FileViewerToolbar/FileViewerToolbar';
import {
  type Props as PDFViewerProps,
  PDFViewer,
} from '../PDFViewer/PDFViewer';

type Props = {
  fileViewerModalClassName?: string;
  onClose?: () => void | undefined;
} & PDFViewerProps;

export const PDFViewerModal = ({
  onClose,
  fileViewerModalClassName = '',
  ...pdfViewerProps
}: Props) => (
  <FileViewerModal
    onClose={() => (onClose ? onClose() : undefined)}
    className={fileViewerModalClassName}
    fullWidth
  >
    <PDFViewer
      {...pdfViewerProps}
      onClose={onClose}
      toolbarMode={Modes.Compact}
    />
  </FileViewerModal>
);
