import React from 'react';

import { PanelTextSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';

import { EditableDescriptionSection } from './EditableDescriptionSection';
import { useUpdatePurchaseOrderRequestSection } from '../../../../hooks';
import { type PurchaseOrderRequestDetails } from '../../../../models';
import { UpdateError } from '../UpdateError';

type DescriptionSectionProps = {
  request: PurchaseOrderRequestDetails;
  setIsEditingPurchaseOrder: (isEditing: boolean) => void;
  fetchRequest: () => Promise<void>;
};

export const DescriptionSection = ({
  request,
  setIsEditingPurchaseOrder,
  fetchRequest,
}: DescriptionSectionProps) => {
  const { t } = useTranslation('global');
  const { onSave, onCancel, canEdit, formData } =
    useUpdatePurchaseOrderRequestSection(request, ['description']);

  return (
    <PanelTextSection
      title={t('requests.panel.purchaseOrder.description')}
      {...(canEdit
        ? {
            isEditable: true,
            cancelTranslation: t('misc.cancel'),
            saveTranslation: t('misc.saveChanges'),
            editSection: <EditableDescriptionSection {...formData} />,
            renderError: (error) => <UpdateError error={error} />,
            onEditClick: () => setIsEditingPurchaseOrder(true),
            onCancel: async () => {
              await onCancel();
              setIsEditingPurchaseOrder(false);
            },
            onSave: async () => {
              await onSave();
              await fetchRequest();
              setIsEditingPurchaseOrder(false);
            },
          }
        : {
            isEditable: false,
          })}
    >
      {request.description}
    </PanelTextSection>
  );
};
