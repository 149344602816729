import { Table } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import {
  filterIntegrationVatAccounts,
  type IntegrationVatAccount,
  type TaxAccount,
} from 'modules/bookkeep/settings/accounting';
import { type QueryState } from 'src/core/api/queryState';

import { TableFooter } from './CommonFooter';
import { TaxAccountErrorWarning } from '../../TaxAccountErrorWarning';
import { TaxAccountSkeletonTable } from '../../TaxAccountSkeletonTable';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
}

export const DefaultTaxAccountPullTable = ({ taxAccountQueryState }: Props) => {
  const { t } = useTranslation('global');
  return (
    <QuerySuspense
      queryState={taxAccountQueryState}
      loading={<TaxAccountSkeletonTable />}
      fallback={() => <TaxAccountErrorWarning />}
    >
      {(taxAccounts) => {
        const vatAccounts = filterIntegrationVatAccounts(taxAccounts);
        return (
          <Table<IntegrationVatAccount>
            rowHeight="compact"
            hasColumnSeparator
            columns={[
              {
                id: 'vat-account-name',
                header: t('bookkeep.integrations.settings.vatTable.name'),
                width: '33.333%',
                renderCell(row) {
                  return row.name;
                },
              },
              {
                id: 'vat-account-rate',
                header: t('bookkeep.integrations.settings.vatTable.rate'),
                width: '33.333%',
                renderCell(row) {
                  return `${row.rate} %`;
                },
              },
              {
                id: 'vat-account-code',
                header: t('bookkeep.integrations.settings.vatTable.code'),
                width: '33.333%',
                renderCell(row) {
                  return row.code;
                },
              },
            ]}
            data={vatAccounts}
            footer={
              taxAccounts.length === 0 ? (
                <TableFooter taxAccountQueryState={taxAccountQueryState} />
              ) : undefined
            }
            getRowId={(row) => row.id}
          />
        );
      }}
    </QuerySuspense>
  );
};
