import { type WalletAccount } from 'src/sfs-migration/wallet/models';

import { useQuery } from '../../../core/api/hooks/useQuery';

export const useSfsLoadsQuery = ({ isEnabled }: { isEnabled: boolean }) => {
  return useQuery<
    WalletAccount.SfsLoad[],
    { error: 'Unable to fetch SFS account loads' }
  >({
    key: ['sfs-migration', 'account-sfs-loads'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounts/sfs-loads`,
    },
  });
};
