import {
  type DateFormatter,
  useDateFormatter,
  type DateFormat,
} from '@dev-spendesk/grapes';
import { isValid } from 'date-fns';
import {
  type i18n as nativeI18n,
  type TFunction,
  type TOptions,
} from 'i18next';
import { useTranslation as nativeUseTranslation } from 'react-i18next';

import type authTranslations from 'src/auth/locales/en/auth.json';
import type cardTranslations from 'src/core/locales/en/card.json';
import type errorsTranslations from 'src/core/locales/en/errors.json';
import type globalTranslations from 'src/core/locales/en/global.json';
import type kycTranslations from 'src/kyc/locales/en/kyc.json';
import type sfsMigrationTranslations from 'src/sfs-migration/locales/en/sfs-migration.json';

import {
  getActiveLanguage,
  type Language,
  type Namespace,
} from '../../config/i18n';
import { type Path } from '../utils/forms/types';

export type I18nKey = Path<typeof globalTranslations>;
export type AuthI18nKey = Path<typeof authTranslations>;
export type KycI18nKey = Path<typeof kycTranslations>;
export type SFSMigrationI18nKey = Path<typeof sfsMigrationTranslations>;

type TFunctionTyped<TTranslationFile> = <TInterpolationMap extends object>(
  key: Path<TTranslationFile>,
  params?: TOptions<TInterpolationMap> | string,
) => string;

export type TGlobalFunctionTyped = TFunctionTyped<typeof globalTranslations>;
type TErrorsFunctionTyped = TFunctionTyped<typeof errorsTranslations>;
export type TCardFunctionTyped = TFunctionTyped<typeof cardTranslations>;

export type TAuthFunctionTyped = TFunctionTyped<typeof authTranslations>;
export type TKYCFunctionTyped = TFunctionTyped<typeof kycTranslations>;
export type TSFSMigrationFunctionTyped = TFunctionTyped<
  typeof sfsMigrationTranslations
>;

export type LocaleFormat = DateFormatter;

/**
 * @deprecated give a defined namespace to typecheck your keys.
 * @example
 * useTranslation("global")
 */
export function useTranslation(namespace?: Namespace): {
  t: TFunction;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

// @ts-expect-error This overload signature is not compatible with its implementation signature.
export function useTranslation(namespace: 'errors'): {
  t: TErrorsFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

export function useTranslation(namespace: 'card'): {
  t: TCardFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

export function useTranslation(namespace: 'kyc'): {
  t: TKYCFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

export function useTranslation(namespace: 'auth'): {
  t: TAuthFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

export function useTranslation(namespace: 'sfs-migration'): {
  t: TSFSMigrationFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

/**
 * With typed keys.
 * @example
 * const key: I18nKey = "members.table.title"
 * const message = t(key)
 * // or
 * const message = t("members.table.title")
 */
export function useTranslation(namespace: 'global'): {
  t: TGlobalFunctionTyped;
  i18n: nativeI18n;
  activeLanguage: Language;
  /**
   * recommended format usage is one of:
   * - DATE_FORMAT.SHORT
   * - DATE_FORMAT.MEDIUM
   * - DATE_FORMAT.LONG_WITH_TIME
   */
  localeFormat: LocaleFormat;
};

export function useTranslation(namespace = 'global') {
  const { t, i18n } = nativeUseTranslation(namespace);
  const formatter = useDateFormatter();
  return {
    t,
    i18n,
    activeLanguage: getActiveLanguage(i18n),
    localeFormat: (
      date: Date,
      format?: DateFormat,
      options?: Intl.DateTimeFormatOptions,
    ) => {
      if (!date || !isValid(date)) {
        return '';
      }
      return formatter(date, format, options);
    },
  };
}
