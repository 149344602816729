import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { DirectionColumnEditModal } from './DirectionColumnEditModal';
import { type DirectionFormValues } from './form';

type Props = {
  isOpen: boolean;
  title: string;
  debit: string;
  credit: string;
  description: string;
  isDeletable: boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
  onSaveColumn: (values: DirectionFormValues) => void;
};

export const DirectionColumnEditModalContainer = ({
  title,
  onSaveColumn,
  debit,
  credit,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<DirectionFormValues>({
    initialValues: {
      title,
      debit,
      credit,
    },
    validate: (values) => {
      const errors: FormikErrors<DirectionFormValues> = {};

      if (!values.title.trim()) {
        errors.title = t('exports.emptyColumnTitleError');
      }

      if (!values.credit?.trim()) {
        errors.credit = t(
          'bookkeep.settings.export.directionOptions.emptyError',
        );
      }

      if (!values.debit?.trim()) {
        errors.debit = t(
          'bookkeep.settings.export.directionOptions.emptyError',
        );
      }

      return errors;
    },
    onSubmit: (values) => {
      onSaveColumn({
        title: values.title,
        debit: values.debit,
        credit: values.credit,
      });
    },
  });

  return <DirectionColumnEditModal title={title} {...rest} {...formikProps} />;
};
