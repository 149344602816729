/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18next';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import memoize from 'lodash/memoize';
import replace from 'lodash/replace';
import trim from 'lodash/trim';
import uniq from 'lodash/uniq';
import values from 'lodash/values';

import { getReverseVatRate } from 'src/core/config/country';
import {
  CURRENCIES as Money,
  type CurrenciesKey,
  type CurrencyOption,
  currencyOptions,
  type CurrencyRates,
} from 'src/core/config/money';

export const add = (a: number, b: number): number =>
  Number.parseFloat((a + b).toFixed(2));

export const roundToTwo = (a: number): number =>
  +`${Math.round(Number(`${a}e+2`))}e-2`;

export const currencySymbol = (currency: CurrenciesKey): string =>
  Money[currency] ? Money[currency].symbol : currency;

export const convertToCompanyCurrency = (
  inputAmount: number,
  sourceCurrency: string,
  company: {
    rates: Record<string, number>;
    currency: string;
  },
  options: { currencyRates?: CurrencyRates } = {},
): number => {
  const companyRates = options.currencyRates ?? company.rates;
  const currencyAccount = company.currency;
  const sourceCurrencyRate = companyRates[sourceCurrency];

  if (currencyAccount === sourceCurrency) {
    return inputAmount;
  }

  return roundToTwo(inputAmount / sourceCurrencyRate);
};

const localeMap = new Map([
  ['en', 'en'],
  ['gb', 'en'],
  ['uk', 'en'],
  ['de', 'de'],
  ['fr', 'fr'],
]);

// Use Ezmoney instead
export const formatMoney = (
  inputValue: number | string,
  currency?: string,
  showPlus: boolean = false,
  inputLocale?: string,
  decimals: number = 2,
): string => {
  const locale = inputLocale || i18n.language || 'en';
  let value = inputValue;

  if (Number.isNaN(Number(inputValue))) {
    value = 0;
  }

  const normalizedValue = Number(value);
  const normalizedCurrency = currency || 'EUR';

  const intlLocale = localeMap.get(locale.toLowerCase()) || 'en';

  const result = normalizedValue
    .toLocaleString(intlLocale, {
      style: 'currency',
      currency: normalizedCurrency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replaceAll(/\s/g, ' '); // normalize space (some env use nbsp, some don't)

  return showPlus ? `+${result}` : result;
};

/**
 * displayMoney: Display money with the correct currency symbol and floating point
 * Does not make assumptions and add defaults
 */
export const displayMoney = ({
  value,
  currency,
}: {
  value: number;
  currency: CurrenciesKey;
}) => {
  return new Intl.NumberFormat(i18n.language || 'en', {
    style: 'currency',
    currency: currency as CurrenciesKey,
  }).format(value);
};

/**
 * Takes all currency symbols + display name from the config,
 * removes escape the $ symbol (for regex-compatibility) and
 * flatten / deduplicate them.
 *
 * This is memoize-ed because it's kind of a heavy process,
 * and it won't change over a user's navigation time frame.
 */
const getMoneySymbols = memoize(() =>
  uniq(
    flatten(
      values(Money).map((current) =>
        // Eventually escape the `$` sign for Regex usage
        [current.symbol.replace('$', '\\$'), current.value.toLowerCase()],
      ),
    ),
  ),
);

export const getDefaultCurrencyForCountry = (rawCountryCode = 'FR'): string => {
  const countryCode = rawCountryCode.toUpperCase();

  const defaultCurrency: any = Object.values(Money).find(
    (details: any) =>
      details.defaultForCountries &&
      includes(details.defaultForCountries, countryCode),
  );

  return defaultCurrency ? defaultCurrency.value : Money.EUR.value;
};

export const toAmount = (amount: number | null | undefined): number | null => {
  if (isNil(amount)) {
    return null;
  }

  // Create a money regex that allow us to cleanup almost every case of input
  // with a currency given
  const moneySymbols = getMoneySymbols();
  const moneySymbolsRegex = new RegExp(
    `(${moneySymbols.join(')|(')})|(euro)`,
    'g',
  );
  const clearedAmount = amount
    .toString()
    .toLowerCase()
    .replace(moneySymbolsRegex, '')
    .replaceAll(/[\s-_]/g, '')
    .replaceAll(',', '.')
    .trim();

  return Number(clearedAmount);
};

export const getCurrencySymbol = (currency: string): CurrenciesKey => {
  const currencyConfig = get(Money, currency);

  if (!currencyConfig) {
    throw new Error(`Currency ${currency} is not configured`);
  }

  return get(currencyConfig, 'symbol');
};

export const isAmountValid = (
  amount: number | string | null | undefined,
): boolean => {
  let floatAmount = amount;
  if (!isNumber(amount)) {
    const stringAmount = trim(replace((amount ?? '').toString(), ',', '.'));
    if (!/^\s*-?\d*\.?\d*\s*$/.test(stringAmount)) {
      return false;
    }
    floatAmount = Number.parseFloat(stringAmount);
  }
  return isFinite(floatAmount);
};

export function vatFromAmountAndRate(
  amount: number,
  rate: number,
  country: string,
  payableDate: string,
): number {
  if (!rate) {
    return 0;
  }

  // Base amount used HT for reverse charges, applying 20% rate
  if (rate === -1) {
    const reverseVatRate = getReverseVatRate(country, payableDate);

    // FIXME: we should handle this case
    if (typeof reverseVatRate === 'undefined') {
      return 0;
    }

    return amount * (reverseVatRate / 100);
  }

  // Base amount used TTC for regular VAT-applied rates
  const coeff = 1 + rate / 100;
  return amount * (1 - 1 / coeff);
}

export const getCurrencyOptionByKey = (currencyKey: string): CurrencyOption => {
  const currency = currencyOptions.find(
    (currencyOption) => currencyOption.key === currencyKey,
  );
  return currency ?? { key: currencyKey, label: currencyKey };
};
