import { FormField, Select } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ExpenseCategoryValue } from '../../../../models/expenseCategory';

type Props = {
  expenseCategoryValues: ExpenseCategoryValue[];
  expenseCategoryValue: ExpenseCategoryValue | undefined;
  error: string | undefined;
  onChange: (costCenter: ExpenseCategoryValue) => void;
};

export const PayableAccountingSectionExpenseCategory = ({
  expenseCategoryValues,
  expenseCategoryValue: currentExpenseCategoryValue,
  error,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormField
      className="PayableAccountingSectionExpenseCategory"
      label={t('misc.expenseCategory')}
      alertMessage={error}
    >
      <Select
        fit="parent"
        options={expenseCategoryValues.map((expenseCategoryValue) => ({
          key: expenseCategoryValue.id,
          label: expenseCategoryValue.name,
        }))}
        value={
          currentExpenseCategoryValue && {
            key: currentExpenseCategoryValue.id,
            label: currentExpenseCategoryValue.name,
          }
        }
        onSelect={(expenseCategoryValueOption) => {
          onChange(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            expenseCategoryValues.find(
              (expenseCategoryValue) =>
                expenseCategoryValue.id === expenseCategoryValueOption.key,
            )!,
          );
        }}
      />
    </FormField>
  );
};
