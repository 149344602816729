import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Denier = {
  id: string;
  avatar?: string;
  fullname: string;
  denialDate: Date;
};

export const useRequestDenierQuery = (
  requestId: string,
): QueryState<Denier> => {
  return useQuery<Denier>({
    key: ['requests', requestId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}`,
    },
    reshapeData: (data) => {
      if (data.type === 'purchase_order') {
        const denier = data.approval?.appraisals?.find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (appraisal: any) => appraisal.appraisalType === 'denial',
        );
        return {
          id: denier?.approver.id,
          fullname: denier?.approver.fullName,
          avatar: denier?.approver.avatar,
          denialDate: new Date(data.createdAt),
        };
      }
      const denier = data.appraisals.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (appraisal: any) => appraisal.appraisal_type === 'denial',
      );
      return {
        id: denier?.approver.id,
        fullname: denier?.approver.fullname,
        avatar: denier?.approver.avatar,
        denialDate: new Date(data.created_at),
      };
    },
  });
};
