import assignIn from 'lodash/assignIn';

import * as types from './actionTypes';

const initialState = {
  vatAccounts: null,
};

const vatSelectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENTS_VAT_ACCOUNTS_SUCCESS:
      return assignIn({}, state, {
        vatAccounts: action.payload.vatRatesAccounts,
      });
    default:
      return state;
  }
};

export default vatSelectorReducer;
