import { PanelSection } from '@dev-spendesk/grapes';
import { startOfMonth, endOfMonth } from 'date-fns';
import { type MonetaryValue, fromNumber, toNumber } from 'ezmoney';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { type Company, useCompany } from 'modules/app/hooks/useCompany';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { convertToCompanyCurrency } from 'src/core/utils/money';

import { useBudgetFromCostCenterQuery } from './hooks/useBudgetFromCostCenterQuery';
import { useExpenseCategoryBreakdownQuery } from './hooks/useExpenseCategoryBreakdownQuery';
import {
  BudgetOverviewLoader,
  BudgetOverviewReview,
} from '../../components/BudgetOverview';
import { getBudgetLimit } from '../../models/breakdown';
import {
  getPeriodIndex,
  getPeriodType,
  type Period,
} from '../../models/period';
import { BudgetOverviewReviewDisclaimerContainer } from '../BudgetOverviewReviewDisclaimerContainer';
import { useBreakdownQuery } from '../hooks/useBreakdownQuery';
import { useBudgetaryExerciseQuery } from '../hooks/useBudgetaryExerciseQuery';

type Props = {
  costCenter: { id: string; name: string };
  expenseCategory: { id: string; name: string } | undefined;
  requestType?: 'po' | 'invoice';
  amount?: MonetaryValue;
  purchaseOrderId?: string;
  date?: Date;
};

const getPeriod = (
  queryState: QueryState<
    | {
        id: string;
        periodicity: 'monthly' | 'quarterly' | 'yearly';
      }
    | undefined
  >,
): Period | undefined => {
  if (queryState.status !== 'success' || !queryState.data) {
    return;
  }
  const { periodicity } = queryState.data;
  return {
    type: getPeriodType(periodicity),
    index: getPeriodIndex(new Date(), getPeriodType(periodicity)),
  };
};

export const BudgetOverviewReviewContainer = ({
  costCenter,
  expenseCategory,
  requestType,
  amount,
  purchaseOrderId,
  date = new Date(),
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  // budget and budgetary exercise fetching
  const budgetQueryState = useBudgetFromCostCenterQuery(costCenter.id);
  const budget =
    budgetQueryState.status === 'success' ? budgetQueryState.data : undefined;
  const budgetId = budget?.id;
  const budgetaryExerciseId = budget?.budgetaryExerciseId;
  useBudgetaryExerciseQuery(budgetaryExerciseId);
  const period = getPeriod(useBudgetaryExerciseQuery(budgetaryExerciseId));
  const isYearlyBudgetaryExercise = period?.type === 'annual';
  const periodRange = {
    from: isYearlyBudgetaryExercise ? undefined : startOfMonth(date),
    to: isYearlyBudgetaryExercise ? undefined : endOfMonth(date),
  };

  const expenseCategoryBreakdownQueryState = useExpenseCategoryBreakdownQuery(
    budgetId,
    expenseCategory?.id,
    period,
  );

  const companyCurrency = useCompanyCurrency();

  const queryStates = useQueryStates({
    states: {
      breakdown: useBreakdownQuery(budgetId, periodRange),
      budgetaryExercise: useBudgetaryExerciseQuery(budgetaryExerciseId),
    },
  });

  if (!budget) {
    return null;
  }

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<BudgetOverviewLoader />}
      fallback={(error) => (
        <PanelSection title={t('budget.title')}>
          <QueryError queryError={error} componentType="Callout" />
        </PanelSection>
      )}
    >
      {({ breakdown, budgetaryExercise }) => {
        const budgetOverview = {
          ...budget,
          name: costCenter.name,
          amount: getBudgetLimit(breakdown),
          breakdown,
        };
        const expenseCategoryOverview =
          expenseCategoryBreakdownQueryState.status === 'success'
            ? {
                name: expenseCategory?.name || '',
                spent: expenseCategoryBreakdownQueryState.data.used,
                total: getBudgetLimit(expenseCategoryBreakdownQueryState.data),
              }
            : undefined;

        return (
          <BudgetOverviewReview
            budget={budgetOverview}
            periodicity={budgetaryExercise?.periodicity}
            date={date}
            addon={
              budgetaryExercise &&
              amount &&
              period &&
              requestType && (
                <BudgetOverviewReviewDisclaimerContainer
                  amount={getAmount(
                    budgetaryExercise?.periodicity,
                    amount,
                    companyCurrency,
                    company,
                  )}
                  requestType={requestType}
                  purchaseOrderId={purchaseOrderId}
                />
              )
            }
            expenseCategory={expenseCategoryOverview}
            requestType={requestType}
          />
        );
      }}
    </QuerySuspense>
  );
};

const getAmount = (
  _periodicity: 'monthly' | 'quarterly' | 'yearly',
  amount: MonetaryValue,
  companyCurrency: string,
  company: Company,
): MonetaryValue => {
  return fromNumber(
    convertToCompanyCurrency(toNumber(amount), amount.currency, company),
    companyCurrency,
    2,
  );
};
