/* eslint-disable @typescript-eslint/naming-convention */

import { Button, type DateRange } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import format from 'date-fns/format';
import React from 'react';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { CostCenterFilter } from './CostCenterFilter';
import { PayableStateFilter } from './PayableStateFilter';
import { PayableTypeFilter } from './PayableTypeFilter';
import { ReceiptFilter } from './ReceiptFilter';
import { SettlementStateFilter } from './SettlementStateFilter';
import { PeriodFilter } from '../../../components/PeriodFilter';
import {
  type FiltersState,
  type FilterActions,
} from '../../containers/PayablesFiltersContainer/hooks/useFiltersContext';

import './PayablesFilters.css';

type Props = {
  state: FiltersState;
  actions: FilterActions;
};

export const PayablesFilters = ({ state, actions }: Props) => {
  const { t } = useTranslation('global');
  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);

  return (
    <div className="PayablesFilters">
      <div className="PayablesFilters__left-actions">
        <div className="PayablesFilters__button-filter">
          <ReceiptFilter
            receiptStatus={state.documentaryEvidenceStatus}
            setReceiptStatus={actions.setReceiptStatus}
          />
        </div>
        <div className="PayablesFilters__button-filter">
          <PeriodFilter
            period={[
              state.creationDate?.from
                ? new Date(state.creationDate.from)
                : undefined,
              state.creationDate?.to
                ? new Date(state.creationDate?.to)
                : undefined,
            ]}
            setPeriod={(newPeriod: DateRange) => {
              const range = {
                from: format(newPeriod[0], 'yyyy-MM-dd'),
                to: format(newPeriod[1], 'yyyy-MM-dd'),
              };
              actions.setCreationDate(range);
            }}
            onReset={() => actions.setCreationDate(undefined)}
            renderTrigger={(triggerProps, isOpen) => (
              <Button
                {...triggerProps}
                className={classNames('text-neutral-darker', {
                  'bg-neutral-light': isOpen,
                  'font-medium text-primary': state.creationDate,
                  'font-normal': !state.creationDate,
                })}
                text={t('payables.filters.period')}
                variant="ghost"
              />
            )}
          />
        </div>
        <div className="PayablesFilters__button-filter">
          <PayableTypeFilter
            selectedPayableTypes={state.payableTypes}
            setPayableTypes={actions.setPayableTypes}
          />
        </div>
        <div className="PayablesFilters__button-filter">
          <SettlementStateFilter
            settlementState={state.settlementState}
            setSettlementState={actions.setSettlementState}
          />
        </div>
        <div className="PayablesFilters__button-filter">
          <PayableStateFilter
            selectedPayableStates={state.payableStates}
            setPayableStates={actions.setPayableStates}
          />
        </div>
        {hasCostCentersFeature && (
          <div className="PayablesFilters__button-filter">
            <CostCenterFilter
              costCenterId={state.costCenter}
              setCostcenter={actions.setCostCenter}
            />
          </div>
        )}
      </div>
      <div className="PayablesFilters__right-actions">
        <SearchFilter
          search={state.search ?? ''}
          setSearch={actions.setSearch}
          placeholder={t('payables.filters.searchPlaceholder')}
        />
      </div>
    </div>
  );
};
