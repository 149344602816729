import { computeStackTrace, type StackTrace } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import React, { type ComponentType } from 'react';

import { UnexpectedErrorContainer } from 'src/core/common/components/legacy/UnexpectedError/UnexpectedErrorContainer';

export type LogContext = {
  scope: string;
  team:
    | 'capture'
    | 'budget-owner'
    | 'finance-operations'
    | 'finance-accountant'
    | 'finance-controller'
    | 'monetisation'
    | 'api-integration'
    | 'accounting-integration'
    | 'ecosystem-integration'
    | 'kyc'
    | 'banking-gateway'
    | 'none'
    | 'growth'
    | 'e-invoicing'
    | 'sfs-migration'; // tmp team for the sfs migration
};

// comes from non exposed method of @datadog/browser-core
export function formatErrorMessage(stack: StackTrace) {
  return `${stack.name || 'Error'}: ${stack.message}`;
}
// comes from non exposed method of @datadog/browser-core
export function toStackTraceString(stack: StackTrace) {
  let result = formatErrorMessage(stack);
  stack.stack.forEach((frame) => {
    const functionName = frame.func === '?' ? '<anonymous>' : frame.func;
    const functionArguments =
      frame.args && frame.args.length > 0 ? `(${frame.args.join(', ')})` : '';
    const line = frame.line ? `:${frame.line}` : '';
    const column = frame.line && frame.column ? `:${frame.column}` : '';
    result += `\n  at ${functionName}${functionArguments} @ ${frame.url}${line}${column}`;
  });
  return result;
}

type FallbackRender = (errorData: {
  resetError: () => void;
}) => React.ReactElement;

type ErrorBoundaryProps = {
  fallbackComponent?: React.ReactNode | FallbackRender;
  context: LogContext;
  children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    // Formating error to allow source mapping
    const stackTrace = computeStackTrace(error);
    const formattedStack = toStackTraceString(stackTrace);

    // Sending error to Datadog
    datadogLogs.logger.error(formattedStack, {
      message: `${error.name ? `${error.name} ` : ''}${error.message}`,
      error: {
        stack: formattedStack,
      },
      ...this.props.context,
    });
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      if (typeof this.props.fallbackComponent === 'function') {
        return this.props.fallbackComponent({ resetError: this.resetError });
      }
      return this.props.fallbackComponent ?? <UnexpectedErrorContainer />;
    }

    return this.props.children;
  }
}

const withErrorBoundary =
  (
    context: LogContext,
    fallbackComponent: ErrorBoundaryProps['fallbackComponent'] = (
      <UnexpectedErrorContainer />
    ),
  ) =>
  <P extends object>(Component: ComponentType<P>) =>
  // eslint-disable-next-line react/display-name
  (props: P) => {
    return (
      <ErrorBoundary fallbackComponent={fallbackComponent} context={context}>
        <Component {...props} />
      </ErrorBoundary>
    );
  };

export default withErrorBoundary;
