import { type TGlobalFunctionTyped } from "src/core/common/hooks/useTranslation";

export const displayFullname = ({
  fullname = '',
  isSelf,
  t,
}: {
  fullname?: string;
  isSelf: boolean;
  t: TGlobalFunctionTyped;
}) => {
  return isSelf
    ? `${fullname} ${t('approval.progressBox.youIndicator')}`
    : fullname;
};
