import { isValid } from 'date-fns';
import { useSelector } from 'react-redux';

import { PaymentMethod } from 'modules/company';
import {
  track,
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from "src/core/utils/analytics";

import {
  getSelectedPaymentsToSchedule,
  getSelectedPaymentMethod,
} from '../../redux/selectors';

type Params = { scheduleDate: string | 'due-date' };

export const useSchedulePaymentsTrackingEvents = () => {
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod);
  const selectedPaymentsToSchedule = useSelector(getSelectedPaymentsToSchedule);

  const getTrackingPayload = ({ scheduleDate }: Params) => {
    const isIndividualDueDatesOn = !isValid(new Date(scheduleDate));
    const requestIds: string[] = selectedPaymentsToSchedule.map(
      (paymentToSchedule) => paymentToSchedule.bill.requestId,
    );
    const ids = selectedPaymentsToSchedule.map(
      (paymentToSchedule) => paymentToSchedule.id,
    );
    return {
      ids,
      paymentMethod: selectedPaymentMethod,
      requestIds,
      numberOfPayments: selectedPaymentsToSchedule.length,
      individualDueDates: isIndividualDueDatesOn,
      ...(!isIndividualDueDatesOn ? { dueDate: scheduleDate } : {}),
    };
  };

  const onScheduleConfirmationModalOpened = (params: Params): void => {
    track(
      AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_OPENED,
      getTrackingPayload(
        params,
      ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_OPENED],
    );
  };

  const onScheduleConfirmationModalConfirmed = (params: Params): void => {
    track(
      AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_CONFIRMED,
      getTrackingPayload(
        params,
      ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_CONFIRMED],
    );
  };

  const onScheduleConfirmationModalClosed = (params: Params): void => {
    track(
      AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_CLOSED,
      getTrackingPayload(
        params,
      ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_SCHEDULE_CONFIRMATION_MODAL_CLOSED],
    );
  };

  const onComplianceModalOpened = (params: Params): void => {
    track(
      AnalyticEventName.INVOICES_SCHEDULE_COMPLIANCE_MODAL_OPENED,
      getTrackingPayload(
        params,
      ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_SCHEDULE_COMPLIANCE_MODAL_OPENED],
    );
  };

  const onDownloadAndMarkAsPaidButtonClicked = (): void => {
    track(AnalyticEventName.INVOICES_DL_AND_MARK_AS_PAID_CLICKED, {
      numberOfPayments: selectedPaymentsToSchedule.length,
    });
  };

  const onConfirmationButtonClicked = (): void => {
    if (selectedPaymentMethod !== PaymentMethod.WireTransfer) {
      onDownloadAndMarkAsPaidButtonClicked();
    }
  };

  return {
    onScheduleConfirmationModalOpened,
    onScheduleConfirmationModalConfirmed,
    onScheduleConfirmationModalClosed,
    onComplianceModalOpened,
    onDownloadAndMarkAsPaidButtonClicked,
    onConfirmationButtonClicked,
  };
};
