import { Button, Panel, PanelSection, Popover } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SearchFilter } from 'common/components/SearchFilter';
import { useTranslation } from 'common/hooks/useTranslation';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import type { Team } from 'modules/budgets/models/team';
import { CustomFieldFilters } from 'modules/custom-fields';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { RequestFiltersForm } from './RequestFiltersForm';
import { type Filters, convertFiltersToPayload } from './types';
import { trackRequestsSearchText } from '../../../utils/trackFilters';

type Props = {
  costCenters: CostCenter[];
  teams: Team[];
  closeRequestsPanel: () => void;
  setTextFilter: (query: string) => void;
  setRequestFilters: (query: {
    requester: string | undefined;
    supplier: string | undefined;
    status: string[] | undefined;
    type: string[] | undefined;
    costCenter: string[] | undefined;
    team: string[] | undefined;
    period?: string;
    amount?: string;
    custom_fields?: { [key in string]: string | null };
  }) => void;
};

export const RequestFilters = ({
  costCenters,
  teams,
  setTextFilter,
  setRequestFilters,
  closeRequestsPanel,
}: Props) => {
  const { t } = useTranslation('global');

  const { type } = useParams();

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<Filters>({});
  const [countFilters, setCountFilters] = useState(0);

  const applyFilters = (filtersToApply: Filters) => {
    const { customFields, ...filtersWithoutCustomFields } = filtersToApply;
    setCountFilters(
      Object.keys(customFields ?? {}).length +
        Object.keys(filtersWithoutCustomFields).length,
    );
    const payload = convertFiltersToPayload(filtersToApply);

    if (Object.keys(filtersToApply).length === 0) {
      track(AnalyticEventName.REQUESTS_LIST_FILTERS_RESET);
    } else {
      track(AnalyticEventName.REQUEST_LIST_FILTERING_CHANGED_NEW, {
        filters: Object.keys(filtersToApply),
      });
    }
    setRequestFilters(payload);
    closeRequestsPanel();
  };

  useEffect(() => {
    setFilters({});
  }, [type]);

  return (
    <div className="flex items-center justify-between border-0 border-b border-solid border-neutral-lighter bg-page-background px-m py-xxs [&_nav]:mb-0">
      <Popover
        renderTrigger={({ isDropdown, ...triggerProps }) => (
          <Button
            variant="secondary"
            iconName="filters"
            text={
              t('misc.filters') + (countFilters > 0 ? ` (${countFilters})` : '')
            }
            {...triggerProps}
          />
        )}
      >
        {(close) => (
          <Panel
            className="max-h-[500px] w-[336px] border-none"
            title={t('misc.selectAndApplyFilters')}
            onClose={() => {
              close();
            }}
            footer={
              <div className="grid w-full grid-cols-2 gap-xs">
                <Button
                  variant="secondary"
                  text={t('misc.resetFilters')}
                  onClick={() => {
                    setFilters({});
                    applyFilters({});
                    close();
                  }}
                />
                <Button
                  text={t('misc.apply')}
                  onClick={() => {
                    applyFilters(filters);
                    close();
                  }}
                />
              </div>
            }
          >
            <PanelSection>
              <RequestFiltersForm
                costCenters={costCenters}
                teams={teams}
                filters={filters}
                setFilters={setFilters}
              />
              <CustomFieldFilters
                eligibleTypes={['request', 'expense']}
                filters={filters.customFields ?? {}}
                setFilters={(customFields) => {
                  const nonNullCustomFields = Object.fromEntries(
                    Object.entries(customFields).filter(
                      ([_, value]) => value !== null,
                    ),
                  );
                  if (Object.keys(nonNullCustomFields).length === 0) {
                    const { customFields: customFieldsUnused, ...rest } =
                      filters;
                    setFilters(rest);
                  } else {
                    setFilters({
                      ...filters,
                      customFields: nonNullCustomFields,
                    });
                  }
                }}
              />
            </PanelSection>
          </Panel>
        )}
      </Popover>

      <SearchFilter
        search={search}
        setSearch={(newSearch) => {
          trackRequestsSearchText(newSearch);
          setSearch(newSearch);
          setTextFilter(newSearch);
        }}
      />
    </div>
  );
};
