import React from 'react';

import {
  type NewTaxAccount,
  type TaxAccount,
} from 'modules/bookkeep/settings/accounting';
import { useDisplayReverseChargeQuery } from 'modules/bookkeep/settings/integrations/hooks/useDisplayReverseChargeQuery';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './TaxAccountPullAndSetSection.module.css';
import { Sage100ReverseChargeSetSection } from './sage100/Sage100ReverseChargeSetSection';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import { type TaxAccountAddSuccess } from '../../../../../hooks/useSetTaxAccountMutation';
import { DefaultTaxAccountPullTable } from '../TaxAccountPullSection/common/CommonTaxAccountPullTable';
import { NetsuiteTaxAccountPullTable } from '../TaxAccountPullSection/netsuite/NetsuiteTaxAccountPullTable';
import { getVatAccountsDescriptionI18nKey } from '../translations';
import { integrationSupportsReverseCharge } from '../utils';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
  setTaxAccountQueryState: MutationQueryState<TaxAccountAddSuccess, unknown>;
  integration: AccountingSoftware;
  onAdd: (taxAccount: NewTaxAccount) => Promise<TaxAccountAddSuccess>;
  onDelete: (taxAccount: TaxAccount) => Promise<void>;
  clearErrors: () => void;
}

export const TaxAccountPullAndSetSection = ({
  integration,
  taxAccountQueryState,
  setTaxAccountQueryState,
  onAdd,
  onDelete,
  clearErrors,
}: Props) => {
  const { t } = useTranslation();
  const displayReverseChargeQuery = useDisplayReverseChargeQuery();
  return (
    <>
      <div id="vat-accounts">
        <div className={styles.header}>
          <div className={styles.headerText}>
            <h3 className="IntegrationAccountingPage__section-title title-xl">
              {t('bookkeep.integrations.settings.sectionTaxAccounts')}
            </h3>
            <p className="IntegrationAccountingPage__section-description body-m">
              {t(getVatAccountsDescriptionI18nKey(integration))}
            </p>
            {renderTaxAccountTable(integration, taxAccountQueryState)}
          </div>
        </div>
      </div>

      {displayReverseChargeQuery &&
        integrationSupportsReverseCharge(integration) &&
        renderReverseChargeSetSection(
          integration,
          taxAccountQueryState,
          setTaxAccountQueryState,
          onAdd,
          onDelete,
          clearErrors,
        )}
    </>
  );
};

function renderTaxAccountTable(
  integration: AccountingSoftware,
  taxAccountQueryState: QueryState<TaxAccount[]>,
) {
  switch (integration) {
    case 'Xero':
    case 'Datev':
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
    case 'SpendeskAccountingSingleEntry':
    case 'Quickbooks':
    case 'Sage100':
      return (
        <DefaultTaxAccountPullTable
          taxAccountQueryState={taxAccountQueryState}
        />
      );
    case 'Netsuite':
      return (
        <NetsuiteTaxAccountPullTable
          taxAccountQueryState={taxAccountQueryState}
        />
      );
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}

function renderReverseChargeSetSection(
  integration: AccountingSoftware,
  taxAccountQueryState: QueryState<TaxAccount[]>,
  setTaxAccountQueryState: MutationQueryState<TaxAccountAddSuccess, unknown>,
  onAdd: (taxAccount: NewTaxAccount) => Promise<TaxAccountAddSuccess>,
  onDelete: (taxAccount: TaxAccount) => Promise<void>,
  clearErrors: () => void,
) {
  switch (integration) {
    case 'Xero':
    case 'Datev':
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
    case 'SpendeskAccountingSingleEntry':
    case 'Quickbooks':
    case 'Netsuite':
      return <></>;
    case 'Sage100':
      return (
        <Sage100ReverseChargeSetSection
          taxAccountQueryState={taxAccountQueryState}
          setTaxAccountQueryState={setTaxAccountQueryState}
          onAdd={onAdd}
          onDelete={onDelete}
          clearErrors={clearErrors}
        />
      );
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}
