import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import some from 'lodash/some';

import * as groupsTypes from "src/core/actionTypes/groups";
import * as policiesTypes from "src/core/actionTypes/policies";
import { actions as approvalActions } from "src/core/modules/company/structure/approval-flows";
import { swap } from "src/core/utils/toolbelt";

import * as types from './actionTypes';

const initialState = {
  groups: null,
  policies: null,
  approvers: null,
  approvalSchemes: [], // will be populated by individual fetches
  costCenters: [],
  behavior: {
    errors: null,
    isLoading: false,
  },
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER_POLICY_SUCCESS: {
      const updatedPolicy = action.payload;
      const matchingPolicy = find(state.policies, { id: updatedPolicy.id });
      const newPolicy = {
        ...matchingPolicy,
        nb_users: updatedPolicy.nb_users,
      };

      return {
        ...state,
        policies: swap(
          state.policies,
          (item) => item.id === updatedPolicy.id,
          newPolicy,
        ),
      };
    }

    // POLICIES
    case policiesTypes.FETCH_POLICIES_LOADING: {
      return {
        ...state,
        behavior: {
          ...get(state, 'behavior'),
          errors: null,
          isLoading: true,
        },
      };
    }
    case policiesTypes.FETCH_POLICIES_FAILURE: {
      return {
        ...state,
        behavior: {
          ...get(state, 'behavior'),
          errors: true,
          isLoading: false,
        },
      };
    }
    case policiesTypes.FETCH_POLICIES_SUCCESS: {
      return {
        ...state,
        policies: action.payload,
        behavior: {
          ...get(state, 'behavior'),
          errors: null,
          isLoading: false,
        },
      };
    }

    // GROUPS
    case groupsTypes.FETCH_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload.groups,
      };
    }

    case types.FETCH_APPROVAL_SCHEMES_SUCCESS: {
      return {
        ...state,
        approvalSchemes: action.payload,
      };
    }

    // APPROVAL SCHEMES
    case approvalActions.FETCH_SCHEMA_SUCCESS: {
      // indexed by teamId
      const { teamId } = action.payload;
      const schemes = get(state, 'approvalSchemes');

      const shouldUpdate = some(schemes, { teamId });
      let updatedSchemes = [];
      if (shouldUpdate) {
        updatedSchemes = map(schemes, (scheme) => {
          if (get(scheme, 'teamId') === teamId) {
            return action.payload;
          }
          return scheme;
        });
      } else {
        updatedSchemes = [...schemes, action.payload];
      }

      return {
        ...state,
        approvalSchemes: updatedSchemes,
      };
    }
    case approvalActions.FETCH_SCHEMA_FAILURE: {
      return {
        ...state,
        behavior: {
          errors: true,
        },
      };
    }

    // RESPONSIBILITY CENTERS
    case types.FETCH_COST_CENTERS_SUCCESS: {
      return {
        ...state,
        costCenters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default membersReducer;
