import * as Money from 'ezmoney';

import { type PaymentToSchedule } from '../models';

export type CountAndAmountForCurrency = {
  count: number;
  amount: Money.MonetaryValue;
};

export type CountsAndAmountsPerCurrency = Map<
  string,
  CountAndAmountForCurrency
>;

export const buildCountsAndAmountsPerCurrency = (
  paymentsToSchedule: PaymentToSchedule[],
): CountsAndAmountsPerCurrency => {
  const countsAndAmountsPerCurrency: CountsAndAmountsPerCurrency = new Map();

  paymentsToSchedule.forEach((paymentToSchedule) => {
    if (!paymentToSchedule) {
      return;
    }

    const { currency } = paymentToSchedule.amount;

    const countsAndAmountsForCurrency = countsAndAmountsPerCurrency.get(
      currency,
    ) || { count: 0, amount: Money.fromNumber(0, currency) };

    const count = countsAndAmountsForCurrency.count + 1;
    const totalAmount = Money.add(
      countsAndAmountsForCurrency.amount,
      paymentToSchedule.amount,
    );

    countsAndAmountsPerCurrency.set(currency, { count, amount: totalAmount });
  });

  return countsAndAmountsPerCurrency;
};

export const accumulateCounts = (
  countsAndAmountsPerCurrency: CountsAndAmountsPerCurrency,
): number => {
  let totalCount = 0;

  countsAndAmountsPerCurrency.forEach((countAndAmountForCurrency) => {
    totalCount += countAndAmountForCurrency.count;
  });

  return totalCount;
};

export const getFirst = (
  countsAndAmountsPerCurrency: CountsAndAmountsPerCurrency,
): CountAndAmountForCurrency =>
  // @ts-ignore next() can be undefined
  countsAndAmountsPerCurrency.values().next().value;

export const toArray = (
  countsAndAmountsPerCurrency: CountsAndAmountsPerCurrency,
): CountAndAmountForCurrency[] =>
  Array.from(countsAndAmountsPerCurrency, ([_, value]) => value);
