import { type MonetaryValue } from 'ezmoney';

import { type ExportMethodsStatus, type PaymentMethod } from 'modules/company';
import { type WireTransferError } from 'modules/invoices/models';

export const USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY = 'employeesToReimburse';

export enum ScheduledPaymentStatus {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Done = 'done',
}

export enum PaymentInfoStatus {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  Cancelled = 'cancelled',
  PendingBanking = 'pending_banking',
  FailedBanking = 'failed_banking',
  Paid = 'paid',
  Exported = 'exported',
}

// NOTE: for now the status of the batch is computed from the paymentInfo.status
// of it's inner scheduled payments so this enum is very similar to
// the PaymentInfoStatus one.
export enum ScheduledPaymentsBatchStatus {
  Pending = 'pending',
  Approved = 'approved',
  Declined = 'declined',
  Cancelled = 'cancelled',
  PendingBanking = 'pending_banking',
  FailedBanking = 'failed_banking',
  Paid = 'paid',
  Exported = 'exported',
  Failed = 'failed',
}

export enum ScheduledPaymentRequestType {
  Expense = 'expense',
  MileageAllowance = 'mileage_allowance',
  PerDiemAllowance = 'per_diem_allowance',
}

export type ScheduledPaymentUser = {
  id: string;
  firstName: string;
  lastName: string;
  hasBankInfo?: boolean; // not in batches
  avatar: string;
};

export type ScheduledPaymentRequest = {
  id: string;
  supplier: string;
  description: string;
  amountToReimburse: MonetaryValue;
  date: string;
  type: ScheduledPaymentRequestType;
};

type ScheduledPaymentPayment = {
  date: string;
  method: PaymentMethod;
  status: PaymentInfoStatus;
  failureReason?: WireTransferError.WireTransferError;
};

export type ScheduledPayment = {
  id: string;
  date: string;
  status: ScheduledPaymentStatus;
  requests: ScheduledPaymentRequest[];
  user: ScheduledPaymentUser;
  amount: MonetaryValue;
  paymentInfo?: ScheduledPaymentPayment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history?: any[]; // any because we don't use that type and it's too complex
  availablePaymentMethods: PaymentMethod[];
  exportMethods?: ExportMethodsStatus;
};

export type UserScheduledPayments = {
  user: ScheduledPaymentUser;
  amount: MonetaryValue;
  status: ScheduledPaymentStatus;
  scheduledPayments: ScheduledPayment[];
};

export type UserRequests = {
  user: ScheduledPaymentUser;
  amount: MonetaryValue;
  requests: ScheduledPaymentRequest[];
};

export type ScheduledPaymentsBatch = {
  id: string;
  amount: MonetaryValue;
  paymentMethod: PaymentMethod;
  batchedAt: string;
  batchedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  scheduledPaymentsCount: number;
  status: ScheduledPaymentsBatchStatus;
  exportMethods?: ExportMethodsStatus;
};

export type ScheduledPaymentsBatchWithScheduledPayments = {
  id: string;
  amount: MonetaryValue;
  paymentMethod: PaymentMethod;
  batchedAt: string;
  batchedBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  scheduledPayments: ScheduledPayment[];
  status: ScheduledPaymentsBatchStatus;
  exportMethods?: ExportMethodsStatus;
};

export type ScheduledPaymentsBatchesByMonth = Map<
  string,
  ScheduledPaymentsBatch[]
>;

export type ExpenseClaimsCounts = Readonly<{
  expensesToValidate: number | undefined;
  paymentsToConfirm: number | undefined;
  usersToPay: number | undefined;
}>;

export type AuthType = 'mfa' | 'sca';
