import { Button, Modal } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useLocalStorage } from 'react-use';

import { type MigrationStatus } from '@sfs-migration/common/models/migration-status';
import { WalletAccount } from '@sfs-migration/wallet/models';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  sfsLoads: WalletAccount.SfsLoad[] | undefined;
  bankableToSfsTransfers: { isAutomatic: boolean }[] | undefined;
  sfsMigration: { status: MigrationStatus; companyId: string } | undefined;
  context: 'app' | 'sfs-migration';
  isAccountOwner: boolean;
};

export const AutomaticFundingWarning = ({
  sfsLoads,
  bankableToSfsTransfers,
  sfsMigration,
  context,
  isAccountOwner,
}: Props) => {
  const { t } = useTranslation('sfs-migration');

  const [isOpen, setIsOpen] = useState(true);

  const { hasModalBeenDisplayed, onClick } = useModal();

  const displayModal = shouldDisplayModal({
    sfsLoads,
    bankableToSfsTransfers,
    sfsMigration,
    hasModalBeenDisplayed,
    isAccountOwner,
  });

  const link = sfsMigration
    ? `/free-upgrade/${sfsMigration.companyId}/overview/follow-your-progress?openFunding=true`
    : '';

  return (
    <Modal
      isOpen={isOpen && displayModal}
      onClose={() => setIsOpen(false)}
      title={t(
        'phase-execution.walletsOverview.automaticFunding.warningModal.title',
      )}
      iconName="warning"
      iconVariant="alert"
      actions={[
        <Button
          key="continue"
          variant="alert"
          text={t(
            'phase-execution.walletsOverview.automaticFunding.warningModal.button',
          )}
          href={link}
          component="a"
          onClick={() => {
            setIsOpen(false);
            onClick();
          }}
          target={context === 'app' ? '_blank' : undefined}
        />,
      ]}
    >
      {t(
        'phase-execution.walletsOverview.automaticFunding.warningModal.content',
      )}
    </Modal>
  );
};

const shouldDisplayModal = ({
  sfsLoads,
  bankableToSfsTransfers,
  sfsMigration,
  hasModalBeenDisplayed,
  isAccountOwner,
}: Omit<Props, 'context'> & { hasModalBeenDisplayed: boolean }) => {
  if (hasModalBeenDisplayed || !isAccountOwner) {
    return false;
  }

  if (
    sfsLoads === undefined ||
    bankableToSfsTransfers === undefined ||
    sfsMigration === undefined
  ) {
    return false;
  }

  // SFS wallet is not active
  if (
    WalletAccount.getMainAccountBankingProvider(sfsMigration.status) !== 'sfs'
  ) {
    return false;
  }

  // Not automatically activated
  if (sfsLoads.length) {
    return false;
  }

  // Automatically funded so enough funds
  return !hasAutomaticFundings(bankableToSfsTransfers);
};

const hasAutomaticFundings = (
  bankableToSfsTransfers: { isAutomatic: boolean }[],
) => {
  return bankableToSfsTransfers.some((transfer) => transfer.isAutomatic);
};

const useModal = () => {
  const [hasModalBeenDisplayed, setDisplayed] = useLocalStorage(
    'sfs-migration/automatic-wallet-funding/warning',
    false,
  );

  return {
    hasModalBeenDisplayed: Boolean(hasModalBeenDisplayed),
    onClick() {
      setDisplayed(true);
    },
  };
};
