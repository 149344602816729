import { type QueryKey } from 'react-query';

export const getAnalyticalSplitStatusQueryKey = (
  companyId: string,
): QueryKey => {
  return ['GET /bookkeeping/analytical-split-status', companyId];
};

export const getFieldValuesQueryKey = (companyId: string): QueryKey => {
  return ['useFieldValuesQuery', companyId];
};
