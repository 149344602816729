import { Callout, type IconName } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';

import { ProgressBoxBar, type Step } from './ProgressBoxBar';

type Props = {
  title: ReactNode;
  content: ReactNode;
  iconName: IconName;
  className?: string;
  variant?: 'neutral' | 'alert' | 'warning' | 'success';
  steps?: Step[];
  children?: React.ReactNode;
};

export const ProgressBox = ({
  title,
  content,
  className,
  iconName,
  variant = 'neutral',
  steps,
  children,
}: Props) => {
  return (
    <Callout
      className={className}
      title={title}
      iconName={iconName}
      variant={variant}
    >
      <div className="mt-s">{content}</div>
      {steps && <ProgressBoxBar steps={steps} />}
      {children}
    </Callout>
  );
};
