import cx from 'classnames';
import React from 'react';

import { grey9Annoying, white } from 'src/core/utils/color-palette';

import './Icon.scss';

type Props = {
  icon: string;
  width?: number;
  height?: number;
  className?: string;
  round?: boolean;
  spin?: boolean;
  fill?: string;
  fillOpacity?: string | number;
  viewBox?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.SyntheticEvent) => void;
};

// TODO: Get rid of viewBox prop
// We use forwardRef() mostly to attach tooltips on instances of this component
// eslint-disable-next-line react/display-name
const Icon = React.forwardRef<SVGSVGElement, Props>(
  (
    // FIXME: false positive on ESLint for prop-types because the generic
    // declaration above works fine.
    /* eslint-disable react/prop-types */
    {
      icon,
      width = 24,
      height,
      viewBox = '0 0 24 24',
      round = false,
      className,
      fill = grey9Annoying,
      fillOpacity = 1,
      onClick,
      style,
      ...rest
    },
    /* eslint-enable react/prop-types */
    ref,
  ) => (
    <svg
      ref={ref}
      role="img"
      width={width}
      height={height || width}
      viewBox={viewBox}
      className={cx('Icon', className, {
        'Icon--round': round,
        'Icon--clickable': onClick !== undefined,
      })}
      onClick={onClick}
      style={style}
      {...rest}
    >
      <path d={icon} fill={round ? white : fill} fillOpacity={fillOpacity} />
    </svg>
  ),
);

export default Icon;

export { default as ICONS } from './icons.json';
