import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type User } from 'src/core/modules/app/hooks/useUser';

import {
  canCancelRequest,
  isPurchaseOrderRequestApprovable,
} from '../../../../models';
import { type PurchaseOrderRequestDetails } from '../../../models';

import './PurchaseOrderRequestPanelFooter.css';

type PurchaseOrderRequestPanelProps = {
  request: PurchaseOrderRequestDetails;
  user: User;
  isDisabled: boolean;
  onApprove: () => void;
  onCancel: () => void;
  onDeny: () => void;
};

export const PurchaseOrderRequestPanelFooter = ({
  user,
  request,
  isDisabled,
  onApprove,
  onCancel,
  onDeny,
}: PurchaseOrderRequestPanelProps) => {
  const { t } = useTranslation('global');

  if (isPurchaseOrderRequestApprovable(request)) {
    return (
      <div className="PurchaseOrderRequestPanelFooter">
        <Button
          fit="parent"
          onClick={() => onDeny()}
          text={t('requests.actions.deny')}
          variant="secondary"
          isDisabled={isDisabled}
        />
        <Button
          fit="parent"
          onClick={() => onApprove()}
          text={t('requests.actions.approve')}
          variant="primary"
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  if (canCancelRequest(request, user)) {
    return (
      <div className="PurchaseOrderRequestPanelFooter">
        <Button
          fit="parent"
          onClick={() => onCancel()}
          text={t('requests.actions.cancel')}
          variant="secondary"
          isDisabled={isDisabled}
        />
      </div>
    );
  }

  return null;
};
