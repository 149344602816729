import PropTypes from 'prop-types';
import React from 'react';

import './LegacySubscriptionItems.css';

const LegacySubscriptionItems = ({ children }) => (
  <ul className="LegacySubscriptionItems">{children}</ul>
);

LegacySubscriptionItems.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LegacySubscriptionItems;
