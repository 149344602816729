import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React from 'react';

import { PanelItemsSection } from 'src/core/common/components/Panel';
import { type SimpleItem } from 'src/core/common/components/Panel/PanelItemsSection/SimplePanelItem';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { type ExpenseCategoryValue } from '../../models/expenseCategory';
import {
  formatMemberName,
  formatSpendingAllocationPeriod,
} from '../../models/member';
import { isSpanishDPRPayable } from '../../models/payable';
import type { Payable } from '../PayablePanelContainer';

type Props = {
  payable: Payable;
};

type CostCenter = {
  id: string;
  name: string;
};

export type DetailsFormValues = {
  costCenter: CostCenter | undefined;
  expenseCategoryValue: ExpenseCategoryValue | undefined;
};

export const PayableDetailsSectionContainer = ({ payable }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasAllocationPeriodFeature = useFeature(FEATURES.ALLOCATION_PERIOD);
  const hasSpanishDPRFeature = useFeature(FEATURES.SPANISH_DPR);
  const shouldDisplayDPRInformations =
    isSpanishDPRPayable(payable) && hasSpanishDPRFeature;

  const formatDate = (date: Date) => localeFormat(date, DATE_FORMAT.SHORT);
  const items: SimpleItem[] = [
    ...(payable.type === 'claimedBill' && payable.supplier?.name
      ? [
          {
            label: t('misc.supplier'),
            value: payable.supplier?.name,
          },
        ]
      : []),
    ...getRenderItemsWhenInvoiceNumberForAllPayables(payable, t),
    ...(payable.creationDate
      ? [
          {
            label: computeInvoiceDateLabel(payable, t),
            value: formatDate(payable.creationDate),
          },
        ]
      : []),
    ...(payable.accountingDate && shouldDisplayDPRInformations
      ? [
          {
            label: t('payables.panel.accountingDate'),
            value: formatDate(payable.accountingDate),
          },
        ]
      : []),
    {
      label: t('payables.panel.requester'),
      value: payable.member ? formatMemberName(payable.member, t) : '-',
    },
    ...(hasTeamsFeature
      ? [
          {
            label: t('payables.panel.team'),
            value: payable.team?.name ?? '-',
          },
        ]
      : []),
    ...(hasAllocationPeriodFeature && payable.subtype !== 'creditNote'
      ? [
          {
            label: t('payables.panel.allocationPeriod'),
            value: formatSpendingAllocationPeriod(
              payable.spendingAllocationPeriod,
              formatDate,
            ),
          },
        ]
      : []),
  ];

  return (
    <PanelItemsSection
      title={t('payables.panel.details')}
      items={items}
      cancelTranslation={t('misc.cancel')}
      saveTranslation={t('misc.saveChanges')}
    />
  );
};

function getRenderItemsWhenInvoiceNumberForAllPayables(
  payable: Payable,
  t: TGlobalFunctionTyped,
) {
  return [
    ...(payable.documentaryEvidence?.type === 'invoice' &&
    payable.documentaryEvidence?.invoiceNumber
      ? [
          {
            label: computeInvoiceNumberLabel(payable, t),
            value: payable.documentaryEvidence?.invoiceNumber,
          },
        ]
      : []),
    ...(payable.documentaryEvidence?.type === 'creditNote' &&
    payable.documentaryEvidence?.creditNoteNumber
      ? [
          {
            label: computeInvoiceNumberLabel(payable, t),
            value: payable.documentaryEvidence?.creditNoteNumber,
          },
        ]
      : []),
  ];
}

/**
 * we intend to display a label for the field based on the payable
 * type and the type of the documentary evidence, as follows:
 *
 * For invoices -> Invoice number
 * For credit notes -> Credit note number
 * For expense claims, cards (all types), card refunds -> Receipt number
 *
 * Since we always need to display something for the field, we default to
 * 'Invoice number' for any other case.
 */
function computeInvoiceNumberLabel(payable: Payable, t: TGlobalFunctionTyped) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteNumber');
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return t('payables.panel.receiptNumberLabel');
    default:
      return t('payables.panel.invoiceNumber');
  }
}

function computeInvoiceDateLabel(payable: Payable, t: TGlobalFunctionTyped) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteDate');
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return t('payables.panel.receiptDate');
    default:
      return t('payables.panel.invoiceDate');
  }
}
