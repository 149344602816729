import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { getTaskSummaryForOrganisationSchema } from './get-summary-for-organisation';
import { getSfsMigrationTasksResponseSchema } from './schema';
import { updateTaskCountSchema } from './update-task-count';
import { NotFoundError, ServerError } from '../../common/errors';

export const tasksRouterContract = initContract().router({
  getTasks: {
    summary: 'Get a list of tasks',
    method: 'GET',
    path: '/tasks',
    responses: {
      200: z.object({
        data: getSfsMigrationTasksResponseSchema,
      }),
      ...NotFoundError,
      ...ServerError,
    },
  },
  updateTaskCount: {
    summary: 'Update a task',
    method: 'POST',
    path: '/tasks/update-count',
    body: updateTaskCountSchema,
    responses: {
      204: null,
      ...ServerError,
    },
  },
  // Used to populate the side bar in the migration dashboard.
  getSummaryForOrganisation: {
    summary:
      'Get a summary of tasks for all companies in the current organisation',
    method: 'GET',
    path: '/tasks-summary',
    responses: {
      200: z.object({
        data: getTaskSummaryForOrganisationSchema,
      }),
      ...NotFoundError,
      ...ServerError,
    },
  },
});
