import React from 'react';

import { PurchaseOrderRequestInterruptedProgressBox } from './PurchaseOrderRequestInterruptedProgressBox';
import { PurchaseOrderRequestOngoingProgressBox } from './PurchaseOrderRequestOngoingProgressBox';
import { type PurchaseOrderRequestDetails } from '../../..';
import { isRequestInterrupted } from '../../../../models';

type Props = {
  className?: string;
  request: PurchaseOrderRequestDetails;
};

export const PurchaseOrderRequestProgressBox = ({
  request,
  ...rest
}: Props) => {
  return isRequestInterrupted(request) ? (
    <PurchaseOrderRequestInterruptedProgressBox request={request} {...rest} />
  ) : (
    <PurchaseOrderRequestOngoingProgressBox request={request} {...rest} />
  );
};
