import { z } from 'zod';

import { adyenPublicKeySchema } from './types/adyen-public-key-schema';

const subscriptionSchema = z.object({
  id: z.string(),
  cardId: z.string(),
  status: z.enum(['active', 'inactive']),
  description: z.string(),
  currency: z.string(),
  budget: z.number(),

  supplier: z.object({
    id: z.string(),
    name: z.string(),
    url: z.string().nullable(),
  }),
});

export const cancelCardAndRevealCardNumbersResponseSchema =
  z.discriminatedUnion('provider', [
    z.object({
      provider: z.literal('marqeta'),
      data: z.object({
        token: z.string(),
        panWidgetScriptUrl: z.string(),
      }),
    }),
    z.object({
      provider: z.literal('adyen'),
      data: z.object({
        cardId: z.string(),

        // todo: decide on whether to pass back in this response or let FE/mobile handle the full getPAN flow
        publicKey: adyenPublicKeySchema,
      }),
    }),
  ]);

export const userIsPendingScreeningSchema = z.object({
  error: z.literal('userIsPendingScreening'),
  message: z.literal('User is pending screening, will need to try again later'),
});

export const bankableSubscriptionSchema = z
  .object({ selected: z.boolean(), bankingProvider: z.literal('bankable') })
  .merge(subscriptionSchema);

export const sfsSubscriptionSchema = z
  .object({
    /**
     * @deprecated
     */
    isDone: z.boolean(),
    bankingProvider: z.enum(['bankable', 'sfs']),
    /**
     * @example "5185 * 8825"
     */
    cardVisibleNumber: z.string().nullable(),
  })
  .merge(subscriptionSchema);

export const subscriptionsBulkActionSchema = z.array(z.string());

export const getSubscriptionsMigrationProgressSchema = z.object({
  progress: z.object({
    renewed: z.number().nonnegative(),
    total: z.number().nonnegative(),
  }),
});

export const getSubscriptionsMigrationSchema = z.object({
  subscriptions: z.array(
    z.object({
      id: z.string(),
      description: z.string(),
      budget: z.object({
        currency: z.string(),
        amount: z.number(),
        precision: z.number(),
      }),
      // is renewed or still to do
      isDone: z.boolean(),
      bankingProvider: z.enum(['bankable', 'sfs']),
      user: z.object({
        id: z.string(),
        fullName: z.string(),
      }),
      card: z.object({
        id: z.string(),
        budget: z.object({
          currency: z.string(),
          amount: z.number(),
          precision: z.number(),
        }),
      }),
      supplier: z.object({
        id: z.string(),
        name: z.string(),
        url: z.string().nullable(),
      }),
    }),
  ),
});
