import { Button, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useCompany } from 'modules/app/hooks/useCompany';
import { markOnboardingPopupAsSeen } from 'src/core/actions/global';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { getShowKybProcedureValidatedPopup } from 'src/core/selectors/globalSelectors';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import fullAccessImageUrl from './assets/fullAccess.svg';
import limitedAccessImageUrl from './assets/limitedAccess.svg';
import { getTrialLimit } from '../../../app/layout/redux/selectors';
import { type AppDispatch } from '../../../app/redux/store';

export const KybProcedureUpdatedModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { t } = useTranslation('global');

  const company = useCompany();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  const trialLimit = useSelector(getTrialLimit);
  const showKybProcedureValidatedPopup = useSelector(
    getShowKybProcedureValidatedPopup,
  );

  const content = showKybProcedureValidatedPopup
    ? {
        imageSrc: fullAccessImageUrl,
        title: t('homepage.fullWalletAccessModal.title'),
        subtitle: t('homepage.fullWalletAccessModal.message'),
        skip: t('homepage.fullWalletAccessModal.skip'),
        cta: t('homepage.fullWalletAccessModal.cta'),
      }
    : {
        imageSrc: limitedAccessImageUrl,
        title: t('homepage.limitedWalletAccessModal.title', {
          limit: trialLimit?.limit
            ? formatMonetaryValue(
                {
                  amount: trialLimit.limit,
                  currency: company.currency,
                  precision: 0,
                },
                { precision: 0 },
              )
            : '',
        }),
        subtitle: t('homepage.limitedWalletAccessModal.message'),
        skip: t('homepage.limitedWalletAccessModal.skip'),
        cta: t('homepage.limitedWalletAccessModal.cta'),
      };

  const modalCode = showKybProcedureValidatedPopup
    ? 'KYB_PROCEDURE_VALIDATED'
    : 'KYB_PROCEDURE_LIMITED_ACCESS_OPENED';

  const markPopupAsSeen = () => {
    dispatch(markOnboardingPopupAsSeen(modalCode));
  };

  const onSkip = () => {
    markPopupAsSeen();
    track(AnalyticEventName.WELCOME_MODAL_LOAD_FUNDS_SKIPPED, {
      type: modalCode,
    });
    setIsOpen(false);
  };

  const onLoadFunds = () => {
    markPopupAsSeen();
    track(AnalyticEventName.WELCOME_MODAL_LOAD_FUNDS_CLICKED, {
      type: modalCode,
    });
    history.push(
      routeFor(routes.COMPANY_BANK_FUNDING_SOURCE.path, {
        company: company.id,
      }),
    );
  };

  return (
    <Modal
      actions={
        <div className="flex flex-col gap-xs">
          <Button
            key="loadFunds"
            onClick={onLoadFunds}
            text={content.cta}
            variant="primary"
          />
          <Button
            key="skip"
            onClick={onSkip}
            text={content.skip}
            variant="ghost"
            iconName="arrow-right"
            iconPosition="right"
          />
        </div>
      }
      illustration={<img src={content.imageSrc} alt="Limited access" />}
      illustrationHeight="241px"
      title={content.title}
      subtitle={content.subtitle}
      isOpen={isOpen}
    />
  );
};
