import { type MonetaryValue } from 'ezmoney';

import { useQuery } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { type CompanyWalletSummary } from '../../company/companyWalletSummary';
import {
  hasDetailedWalletSummary,
  type DetailedWalletSummary,
} from '../../company/wallet/walletSummary';
import {
  type OrganisationReportingWalletDetails,
  type OrganisationReportingEntityBreakdown,
} from '../types';

const getPositiveOf = (value: number) => (value >= 0 ? value : 0);
const getNegativeOf = (value: number) =>
  Math.abs((value ?? 0) - getPositiveOf(value));
const getAmountOfMonetaryValue = (
  monetaryValue: MonetaryValue | null | undefined,
) => (monetaryValue ? monetaryValue.amount : 0);

export const useOrganisationEntityWalletSummaryQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = { isEnabled: true },
): QueryState<OrganisationReportingWalletDetails> => {
  return useQuery<OrganisationReportingWalletDetails, CompanyWalletSummary>({
    key: `companyWalletSummary_${companyId}`,
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/companies/${companyId}/wallet-summary`,
    },
    isEnabled: options.isEnabled,
    reshapeData: (
      walletSummary: CompanyWalletSummary,
    ): OrganisationReportingWalletDetails => {
      const hasDetails =
        walletSummary && hasDetailedWalletSummary(walletSummary);

      return {
        isWalletActive: walletSummary?.status === 'active',
        walletBalance: getAmountOfMonetaryValue(
          hasDetails ? walletSummary.totalAmount : undefined,
        ),
        walletBalanceByCurrency: undefined,
        breakdown: getBreakdownData(
          hasDetails ? walletSummary.walletDetails : undefined,
        ),
      };
    },
  });
};

const getBreakdownData = (
  walletDetails: DetailedWalletSummary['walletDetails'] | undefined,
): OrganisationReportingEntityBreakdown => ({
  scheduledTransfers: getAmountOfMonetaryValue(
    walletDetails?.scheduledTransfers?.totalAmount,
  ),
  cardsLimits: getAmountOfMonetaryValue(
    walletDetails?.allocatedOnCards?.totalAmount,
  ),
  available: getPositiveOf(
    getAmountOfMonetaryValue(walletDetails?.available?.totalAmount),
  ),
  shortfall: getNegativeOf(
    getAmountOfMonetaryValue(walletDetails?.available?.totalAmount),
  ),
  trend:
    (walletDetails?.available?.totalAmount.amount ?? 0) >= 0
      ? ('positive' as const)
      : ('warning' as const),
});
