import { type IconName, Tag } from '@dev-spendesk/grapes';
import React from 'react';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';

import type { Payable } from '../../containers/PayablePanelContainer';

type Props = {
  payable: Payable;
};

export const PayableTypeTag = ({ payable }: Props) => {
  const { t } = useTranslation('global');

  const typeIcon = getReadablePayableTypeIcon(payable.type, payable.subtype);

  return (
    <Tag variant="neutral" {...(typeIcon && { iconName: typeIcon })}>
      {getReadablePayableType(payable.type, payable.subtype, t)}
    </Tag>
  );
};

const getReadablePayableType = (
  payableType: Payable['type'],
  payableSubType: Payable['subtype'],
  translator: TGlobalFunctionTyped,
): string => {
  switch (payableType) {
    case 'cardPurchase':
      return getReadableCardPayableType(payableSubType, translator);
    case 'invoicePurchase':
      return translator('payables.panel.types.invoice');
    case 'claimedBill':
      return translator('payables.panel.types.expenseClaim');
    case 'mileageAllowance':
      return translator('payables.panel.types.mileage');
    case 'perDiemAllowance':
      return translator('payables.panel.types.perDiem');
    case 'reversal':
      return payableSubType === 'creditNote'
        ? translator('payables.panel.types.creditNote')
        : translator('payables.panel.types.reversal');
    default:
      return '';
  }
};

const getReadableCardPayableType = (
  payableSubType: Payable['subtype'],
  translator: TGlobalFunctionTyped,
) => {
  switch (payableSubType) {
    case 'singleUseCard':
      return translator('payables.panel.types.singleUseCardPurchase');
    case 'subscriptionCard':
      return translator('payables.panel.types.subscriptionCardPurchase');
    case 'plasticCard':
      return translator('payables.panel.types.platicCardPurchase');
    default:
      return translator('payables.panel.types.cardPurchase');
  }
};

const getReadablePayableTypeIcon = (
  payableType: Payable['type'],
  payableSubType: Payable['subtype'],
): IconName | undefined => {
  switch (payableType) {
    case 'cardPurchase':
      return getCardPayableIcon(payableSubType);
    case 'reversal':
      return payableSubType === 'creditNote'
        ? 'credit'
        : getCardPayableIcon(payableSubType);
    case 'invoicePurchase':
      return 'invoice';
    case 'claimedBill':
      return 'expense-claim';
    case 'mileageAllowance':
      return 'car';
    case 'perDiemAllowance':
      return 'airplane';
    default:
      return undefined;
  }
};

const getCardPayableIcon = (payableSubType: Payable['subtype']) => {
  switch (payableSubType) {
    case 'singleUseCard':
      return 'single-purchase-card';
    case 'subscriptionCard':
      return 'recurring-card';
    case 'plasticCard':
      return 'plastic-card';
    default:
      return undefined;
  }
};
