import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { PurchaseOrderRequestQuotes } from './PurchaseOrderRequestQuotes';
import {
  useUploadQuotesToPurchaseOrderRequestMutation,
  toFormData,
} from '../../../hooks/useUploadQuotesToPurchaseOrderRequestMutation';
import { type Quote } from '../../../models';

type Props = {
  canAddQuotes: boolean;
  quotes: Quote[];
  requestId: string;
  fetchRequest: () => Promise<void>;
};

export const PurchaseOrderRequestQuotesContainer = ({
  canAddQuotes,
  quotes,
  requestId,
  fetchRequest,
}: Props) => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation();
  const [uploadQuotesToPurchaseOrderRequest] =
    useUploadQuotesToPurchaseOrderRequestMutation(requestId);

  return (
    <PurchaseOrderRequestQuotes
      canAddQuotes={canAddQuotes}
      quotes={quotes}
      onUpload={async (files) => {
        try {
          await uploadQuotesToPurchaseOrderRequest(toFormData(files));
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('requests.panel.purchaseOrder.addQuoteError'),
          });
        }
        await fetchRequest();
      }}
    />
  );
};
