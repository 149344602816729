import { type MonetaryValue } from 'ezmoney';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type PurchaseOrder = {
  id: string;
  amount: MonetaryValue;
  billedAmount: MonetaryValue;
};

export const usePurchaseOrderQuery = (
  purchaseOrderId?: string,
): QueryState<PurchaseOrder | undefined> => {
  return useQuery<PurchaseOrder | undefined>({
    key: ['purchaseOrders', purchaseOrderId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/purchase-orders/${purchaseOrderId}`,
    },
    isEnabled: Boolean(purchaseOrderId),
    reshapeData: (data) => {
      return {
        id: data.id,
        amount: data.amount,
        billedAmount: data.billedAmount,
      };
    },
  });
};
