import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type InviteLink } from '../models/invite';

type RawInviteLink = {
  key: string;
  restrict_domain: string;
} | null;

export const useGetInviteLinkQuery = (): QueryState<InviteLink> => {
  const companyId = useCompanyId();

  return useQuery<InviteLink, RawInviteLink>({
    key: ['inviteLink', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/invite-links',
    },
    // @ts-expect-error
    reshapeData: (rawInviteLink: RawInviteLink) => {
      return rawInviteLink?.key
        ? {
            key: rawInviteLink.key,
            restrictDomain: rawInviteLink.restrict_domain,
          }
        : undefined;
    },
  });
};
