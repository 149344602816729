import { Tooltip } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import isNil from 'lodash/isNil';
import React, { Fragment, type ReactChild } from 'react';

import { ProgressBoxBarStep } from './ProgressBoxBarStep';

import './ProgressBoxBar.css';

export type Step = {
  status: 'active' | 'upcoming' | 'completed';
  name: string;
  info?: ReactChild;
  isClicked?: boolean;
  onClick?: () => void;
};

type Props = {
  steps: Step[];
};

export const ProgressBoxBar = ({ steps }: Props) => {
  const { activeStepCssPosition, upcomingStepCssPosition } =
    getActiveAndUpcomingStepCSSPosition(steps);
  return (
    <div className="ProgressBoxBar">
      <div className="ProgressBoxBar__steps-wrapper shadow-10 ">
        <div
          className="ProgressBoxBar__steps relative"
          style={
            {
              '--ProgressBoxBar__active-step-position': activeStepCssPosition,
              '--ProgressBoxBar__upcoming-step-position':
                upcomingStepCssPosition,
            } as React.CSSProperties
          }
        >
          {steps.map((step, index) => {
            const stepButton = (
              <span
                className={classnames('ProgressBoxBar__step', {
                  'cursor-pointer': !isNil(step.onClick),
                })}
                role="button"
                aria-labelledby={`navigable-step-${index}`}
                onClick={step.onClick}
              >
                <ProgressBoxBarStep
                  status={step.status}
                  isClicked={step.isClicked}
                />
                <span
                  id={`navigable-step-${index}`}
                  className={classnames('ProgressBoxBar__step-description', {
                    'ProgressBoxBar__step-description--hoverable':
                      step.status === 'active' || step.status === 'completed',
                    'ProgressBoxBar__step-description--active': !isNil(
                      step.isClicked,
                    )
                      ? step.isClicked
                      : step.status === 'active',
                    'right-0': index !== 0,
                    'left-0': index !== steps.length - 1,
                  })}
                >
                  {step.name}
                </span>
              </span>
            );

            return (
              <Fragment key={step.name}>
                {step.info ? (
                  <Tooltip content={step.info}>{stepButton}</Tooltip>
                ) : (
                  stepButton
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getActiveAndUpcomingStepCSSPosition = (
  steps: Step[],
): {
  activeStepCssPosition: string;
  upcomingStepCssPosition: string;
} => {
  const indexOfActiveStep = steps.findIndex((step) => step.status === 'active');
  const spaceBetweenEachStep = 100 / (steps.length - 1);

  if (indexOfActiveStep === -1) {
    return {
      activeStepCssPosition: '100%',
      upcomingStepCssPosition: '100%',
    };
  }

  return {
    activeStepCssPosition: `${spaceBetweenEachStep * indexOfActiveStep}%`,
    upcomingStepCssPosition: `${
      spaceBetweenEachStep * (indexOfActiveStep + 1)
    }%`,
  };
};
