import { AccordionItem, Button, colors, Icon } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import { getContentByTaskType } from './utils';
import { type ReadyToSpendTaskType, type ReadyToSpendTask } from '../../types';

type Props = {
  setModalType: (type: ReadyToSpendTaskType | null) => void;
  task: ReadyToSpendTask;
};

export const ReadyToSpendTaskItem = ({ setModalType, task }: Props) => {
  const history = useHistory();
  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const onClick = () => {
    switch (task.type) {
      case 'download_app':
      case 'learn_to_approve':
      case 'upload_receipt':
        setModalType(task.type);
        break;
      case 'expense_claim': {
        const path = routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: 'all',
          id: 'new',
          newRequestType: 'reimbursement',
        });
        history.push(path, { productTourToDisplay: 'reimbursement' });
        break;
      }
      case 'single_use': {
        const path = routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: 'all',
          id: 'new',
          newRequestType: 'virtual',
        });
        history.push(path, { productTourToDisplay: 'single_purchase' });
        break;
      }
      case 'subscription': {
        const path = routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: 'all',
          id: 'new',
          newRequestType: 'virtual',
        });
        history.push(`${path}?recurring=true`, {
          productTourToDisplay: 'subscription',
        });
        break;
      }
      default:
        break;
    }
  };

  const content = getContentByTaskType(task.type);
  if (!content) {
    return null;
  }
  const { title, decription, cta } = content;

  return (
    <div className="border-0 border-t border-solid border-neutral-light py-s">
      <AccordionItem
        renderHeader={() => (
          <div className="-ml-m flex items-center gap-xs">
            {task.status === 'completed' ? (
              <Icon name="success" size="l" color={colors.success} />
            ) : (
              <div className="h-m w-m rounded-[24px] border border-solid border-neutral" />
            )}
            <p className="title-m">{t(title)}</p>
          </div>
        )}
      >
        <div className="flex flex-col gap-s">
          <div className="text-neutral-dark">{t(decription)}</div>
          <Button
            className="mb-xs self-end"
            onClick={onClick}
            variant="primary"
            text={t(cta)}
          />
        </div>
      </AccordionItem>
    </div>
  );
};
