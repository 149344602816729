import {
  Button,
  IconButton,
  Modal,
  Table,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { ExpenseAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import {
  hasIntegrationFileBasedExport,
  type IntegrationStatusWithIntegration,
} from 'modules/bookkeep/integration/status';
import {
  type MutationQueryState,
  type QueryState,
} from 'src/core/api/queryState';
import { CreateExpenseAccountModal } from 'src/core/modules/bookkeep/components/CreateExpenseAccountModal';
import { UpdateExpenseAccountModal } from 'src/core/modules/bookkeep/components/UpdateExpenseAccountModal';
import { apiUrl } from 'src/core/utils/api';

import { BulkImportExpenseAccount } from './BulkImportExpenseAccount';
import { DefaultExpenseAccountForm } from './DefaultExpenseAccountForm';
import { ExpenseAccountEmptyState } from './ExpenseAccountEmptyState';
import { ExpenseAccountErrorModal } from './ExpenseAccountErrorModal';
import styles from './ExpenseAccountLocalOnlySection.module.css';
import {
  getExpenseAccountsDescriptionI18nKey,
  getExpenseAccountsHelpCenterLink,
} from './translations';
import {
  reshapeToFormData,
  useExpenseAccountsMutation,
} from './useImportExpenseAccountsMutation';
import {
  type ExpenseAccount,
  type ExpenseAccountUpdate,
} from '../../../../../accounting';
import { type Result as GetDefaultExpenseAccountQueryResult } from '../../../../hooks/useGetDefaultExpenseAccountQuery';
import { type Result as SetDefaultExpenseAccountMutationResult } from '../../../../hooks/useSetDefaultExpenseAccountMutation';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

type Props = {
  expenseAccounts: ExpenseAccount[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
  clearErrors: () => void;
  onDelete: (expenseAccount: ExpenseAccount) => Promise<void>;
  integrationStatus: IntegrationStatusWithIntegration;
  isLoading: boolean;
};

type PropsWithDefaultAccounts = Props & {
  setDefaultExpenseAccountQueryState: MutationQueryState<SetDefaultExpenseAccountMutationResult>;
  getDefaultExpenseAccountQueryState: QueryState<GetDefaultExpenseAccountQueryResult>;
  onDefaultChange: (expenseAccount: ExpenseAccountUpdate) => Promise<void>;
};

export const ExpenseAccountLocalOnlyWithDefaultAccountsSection = (
  props: PropsWithDefaultAccounts,
) => {
  return <ExpenseAccountSection {...props} withDefaultAccount />;
};

export const ExpenseAccountLocalOnlySection = (props: Props) => {
  return (
    <ExpenseAccountSection
      {...props}
      withDefaultAccount={false}
      onDefaultChange={async () => {}}
      setDefaultExpenseAccountQueryState={{ status: 'idle' }}
      getDefaultExpenseAccountQueryState={{ status: 'loading' }}
    />
  );
};

enum MODAL_KIND {
  IMPORT = 'import',
  ADD = 'add',
  CONFIRM_DELETE = 'confirmDelete',
  UPDATE = 'update',
  CLOSED = 'closed',
  ERROR = 'error',
}

type ModalState =
  | { kind: MODAL_KIND.CLOSED }
  | { kind: MODAL_KIND.ADD }
  | {
      kind: MODAL_KIND.UPDATE;
      account: ExpenseAccount;
    }
  | {
      kind: MODAL_KIND.CONFIRM_DELETE;
      account: ExpenseAccount;
    }
  | { kind: MODAL_KIND.IMPORT }
  | { kind: MODAL_KIND.ERROR };

const ExpenseAccountSection = ({
  withDefaultAccount,
  setDefaultExpenseAccountQueryState,
  getDefaultExpenseAccountQueryState,
  onDefaultChange,
  expenseAccounts,
  hasNextPage,
  fetchNextPage,
  clearErrors,
  onDelete,
  integrationStatus,
  isLoading,
}: PropsWithDefaultAccounts & { withDefaultAccount: boolean }) => {
  const openImportModal = () => setModalStateFromKind(MODAL_KIND.IMPORT);
  const openManualModal = () => setModalStateFromKind(MODAL_KIND.ADD);
  const closeModals = () => setModalStateFromKind(MODAL_KIND.CLOSED);
  const openErrorModal = () => setModalStateFromKind(MODAL_KIND.ERROR);
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const [modalState, setModalState] = useState<ModalState>({
    kind: MODAL_KIND.CLOSED,
  });
  const [importExpenseAccounts, expenseAccountMutationState] =
    useExpenseAccountsMutation();

  useEffect(() => {
    if (expenseAccountMutationState.status === 'error') {
      openErrorModal();
    }
  }, [expenseAccountMutationState.status]);

  const expenseAccountsErrorsList =
    integrationStatus.settingsValidation.expenseAccounts.flatMap(
      ({ id }) => id,
    );
  const data = expenseAccounts.map((expenseAccount) => ({
    hasErrors: expenseAccountsErrorsList.includes(expenseAccount.id),
    ...expenseAccount,
  }));

  const defaultExpenseAccount =
    getDefaultExpenseAccountQueryState.status === 'success'
      ? getDefaultExpenseAccountQueryState.data
      : undefined;

  const isDefaultExpenseAccountToggleChecked = Boolean(
    defaultExpenseAccount?.id && !defaultExpenseAccount.isArchived,
  );

  async function handleUpload(file: File, prefixWithCode: boolean) {
    const payload = reshapeToFormData(file, prefixWithCode);
    try {
      await importExpenseAccounts(payload);
      closeModals();
    } catch {
      // caught below as mutationState.error
    }
  }

  function setModalStateFromKind(kind: MODAL_KIND, account?: ExpenseAccount) {
    switch (kind) {
      case MODAL_KIND.IMPORT:
      case MODAL_KIND.ERROR:
      case MODAL_KIND.CLOSED:
      case MODAL_KIND.ADD:
        setModalState({
          kind,
        });
        break;

      case MODAL_KIND.UPDATE:
      case MODAL_KIND.CONFIRM_DELETE:
        if (account) {
          setModalState({ kind, account });
        }

        break;

      default:
        break;
    }
  }

  return (
    <div id="expense-accounts">
      {(() => {
        if (isLoading) {
          return null;
        }

        if (!expenseAccounts.length) {
          return (
            <ExpenseAccountEmptyState
              importAction={openImportModal}
              manualAction={openManualModal}
            />
          );
        }

        return (
          <>
            <div className={styles.header}>
              <div className={styles.headerText}>
                <h3 className="IntegrationAccountingPage__section-title title-xl">
                  {t('bookkeep.integrations.settings.sectionExpenseAccounts')}
                </h3>
                <p
                  className={classNames(styles.headerTextDescription, 'body-m')}
                >
                  {t(
                    getExpenseAccountsDescriptionI18nKey(
                      integrationStatus.integration,
                    ),
                  )}
                  &nbsp;|&nbsp;
                  <a
                    href={getExpenseAccountsHelpCenterLink(
                      integrationStatus.integration,
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('misc.helpCenterArticle')}
                  </a>
                </p>
              </div>
              <DropdownMenu
                placement="bottom-end"
                className={styles.headerBtn}
                options={[
                  {
                    key: MODAL_KIND.ADD,
                    label: t(
                      'bookkeep.integrations.settings.expenseAccountsTable.create',
                    ),
                  },
                  {
                    key: MODAL_KIND.IMPORT,
                    label: t(
                      'bookkeep.integrations.settings.expenseAccountsTable.bulkImport',
                    ),
                  },
                ]}
                renderButton={(getToggleButtonProps) => (
                  <Button
                    {...getToggleButtonProps()}
                    text={t(
                      'bookkeep.integrations.settings.expenseAccountsTable.addExpenseAccount',
                    )}
                    variant="primary"
                  />
                )}
                renderOption={(option) => <DropdownItem label={option.label} />}
                onSelect={(option) => setModalStateFromKind(option.key)}
              />

              <a
                rel="noopener noreferrer"
                target="_blank"
                href={apiUrl(
                  `/accounting-integration/chart-of-accounts/expense-accounts/export`,
                  companyId,
                )}
                aria-label={t(
                  'bookkeep.integrations.settings.expenseAccountsTable.downloadList',
                )}
              >
                <Tooltip
                  content={t(
                    'bookkeep.integrations.settings.expenseAccountsTable.downloadList',
                  )}
                  triggerAsChild
                >
                  <IconButton
                    iconName="download"
                    variant="border"
                    className="ml-xs"
                    aria-hidden="true"
                  />
                </Tooltip>
              </a>
            </div>
            {!!expenseAccountsErrorsList.length && (
              <ExpenseAccountAdvice
                showError
                integrationStatus={integrationStatus}
                title={t(
                  'bookkeep.integrations.datev.accountCodeAdvice.expenseAccounts.title',
                  {
                    count: expenseAccountsErrorsList.length,
                  },
                )}
                className={styles.errorCallout}
              />
            )}
            <Table
              rowHeight="compact"
              data={data}
              hasColumnSeparator
              columns={[
                {
                  id: 'expense-account-name',
                  header: t(
                    'bookkeep.integrations.settings.expenseAccountsTable.accountName',
                  ),
                  renderCell(row) {
                    return row.name;
                  },
                  width: '50%',
                },
                {
                  getCellVariant: (row) =>
                    row.hasErrors ? 'alert' : undefined,
                  id: 'expense-account-code',
                  header: t(
                    'bookkeep.integrations.settings.expenseAccountsTable.accountCode',
                  ),
                  width: '50%',
                  renderCell(row, { isRowHovered }) {
                    return (
                      <CellWithButton
                        isButtonVisible={isRowHovered}
                        button={
                          <span className="CellWithButton__button flex gap-xxs">
                            <>
                              <IconButton
                                iconName="pen"
                                variant="borderless"
                                onClick={() =>
                                  setModalStateFromKind(MODAL_KIND.UPDATE, row)
                                }
                                aria-label={t('misc.edit')}
                              />
                              <IconButton
                                iconName="trash"
                                variant="borderless"
                                onClick={() =>
                                  setModalStateFromKind(
                                    MODAL_KIND.CONFIRM_DELETE,
                                    row,
                                  )
                                }
                                aria-label={t('misc.delete')}
                              />
                            </>
                          </span>
                        }
                      >
                        {row.code}
                      </CellWithButton>
                    );
                  },
                },
              ]}
              footer={
                hasFooter({ expenseAccounts, isLoading, hasNextPage }) ? (
                  <TableFooter
                    fetchNextPage={fetchNextPage}
                    expenseAccounts={expenseAccounts}
                    isLoading={isLoading}
                  />
                ) : undefined
              }
              getRowId={(row) => row.id}
            />
          </>
        );
      })()}

      {withDefaultAccount ? (
        <DefaultExpenseAccountForm
          integrationStatus={integrationStatus}
          getDefaultExpenseAccountQueryState={
            getDefaultExpenseAccountQueryState
          }
          setDefaultExpenseAccountQueryState={
            setDefaultExpenseAccountQueryState
          }
          onDefaultChange={onDefaultChange}
          defaultExpenseAccount={defaultExpenseAccount}
          isInitialToggleChecked={isDefaultExpenseAccountToggleChecked}
        />
      ) : null}

      <CreateExpenseAccountModal
        integrationStatus={integrationStatus}
        isOpen={modalState.kind === MODAL_KIND.ADD}
        onClose={() => setModalStateFromKind(MODAL_KIND.CLOSED)}
      />
      <UpdateExpenseAccountModal
        integrationStatus={integrationStatus}
        isOpen={modalState.kind === MODAL_KIND.UPDATE}
        onClose={() => setModalStateFromKind(MODAL_KIND.CLOSED)}
        account={
          modalState.kind === MODAL_KIND.UPDATE ? modalState.account : undefined
        }
      />
      <BulkImportExpenseAccount
        isOpen={modalState.kind === MODAL_KIND.IMPORT}
        onUpload={handleUpload}
        onCancel={() => {
          setModalStateFromKind(MODAL_KIND.CLOSED);
          clearErrors();
        }}
        downloadLink={apiUrl(
          `/accounting-integration/chart-of-accounts/expense-accounts/export-template`,
          companyId,
        )}
      />
      {modalState.kind === MODAL_KIND.ERROR &&
      expenseAccountMutationState.status === 'error' &&
      expenseAccountMutationState.error.type === 'RequestError' ? (
        <ExpenseAccountErrorModal
          action={openImportModal}
          close={closeModals}
          errors={expenseAccountMutationState.error.data.errors}
          isOpen={expenseAccountMutationState.error.data.errors.length !== 0}
        />
      ) : null}
      <Modal
        title={
          modalState.kind === 'confirmDelete'
            ? t(
                'bookkeep.integrations.settings.expenseAccountsTable.confirmDeleteExpenseAccountTitle',
                { accountName: modalState.account.name },
              )
            : ''
        }
        subtitle={
          hasIntegrationFileBasedExport(integrationStatus.integration)
            ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
            : t(
                'bookkeep.integrations.settings.expenseAccountsTable.confirmSendBackToPrepareSubtitle',
              )
        }
        isOpen={modalState.kind === 'confirmDelete'}
        iconVariant="alert"
        iconName="trash"
        actions={[
          <Button
            key="cancel"
            onClick={() => setModalStateFromKind(MODAL_KIND.CLOSED)}
            text={t('misc.cancel')}
            variant="secondary"
          />,
          <Button
            key="yes"
            onClick={() => {
              if (modalState.kind === 'confirmDelete') {
                onDelete({
                  id: modalState.account.id,
                  name: modalState.account.name,
                  code: modalState.account.code,
                  isArchived: false,
                  isDefault: false,
                });
              }

              setModalStateFromKind(MODAL_KIND.CLOSED);
            }}
            text={t(
              'bookkeep.integrations.settings.expenseAccountsTable.confirmDeletion',
            )}
            variant="alert"
          />,
        ]}
      />
    </div>
  );
};

function hasFooter({
  expenseAccounts,
  isLoading,
  hasNextPage,
}: {
  expenseAccounts: ExpenseAccount[];
  isLoading: boolean;
  hasNextPage: boolean;
}) {
  return expenseAccounts.length === 0 || isLoading || hasNextPage;
}

const TableFooter = ({
  expenseAccounts,
  isLoading,
  fetchNextPage,
}: {
  expenseAccounts: ExpenseAccount[];
  isLoading: boolean;
  fetchNextPage: () => Promise<void>;
}) => {
  const { t } = useTranslation('global');

  if (isLoading) {
    return <em>{t('misc.loading')}</em>;
  }

  if (expenseAccounts.length === 0) {
    return (
      <div>
        <h3 className="title-m">
          {t('bookkeep.integrations.settings.noExpenseAccountTitle')}
        </h3>
      </div>
    );
  }

  return (
    <Button
      onClick={() => fetchNextPage()}
      text={t('misc.loadMore')}
      variant="secondary"
    />
  );
};
