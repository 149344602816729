import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export const useUploadQuotesToPurchaseOrderRequestMutation = (
  requestId: string,
): MutationState<FormData> => {
  return useMutation<FormData, object>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `requests/purchase-orders/${requestId}/quotes`,
      method: 'post',
    },
    options: {
      throwOnError: true,
    },
    reshapeData: () => {},
  });
};

export const toFormData = (quotes: FileList): FormData => {
  const formData = new FormData();

  Array.from(quotes).forEach((quote) => {
    formData.append('quotes', quote);
  });

  return formData;
};
