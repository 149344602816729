import { type StepName } from './useTasksQuery';
import { stepNameToUrlpart } from '../../routes/[companyId]/overview/hooks/useMigrationStep';
import {
  type MigrationProcess,
  type TaskType,
} from '../../routes/[companyId]/overview/hooks/useTasksQuery';

export const MIGRATION_STEPS_ORDER: Readonly<{
  [x: string]: {
    previous: StepName | undefined;
    // next: StepName | undefined;
  };
}> = {
  migrationInitiation: {
    previous: undefined,
    // next: 'migrationActivation',
  },
  migrationActivation: {
    previous: 'migrationInitiation',
    // next: 'migrationExecution',
  },
  migrationExecution: {
    previous: 'migrationActivation',
    // next: undefined,
  },
};

export const MIGRATION_TASKS_ORDER: Readonly<{
  [x: string]: TaskType[];
}> = {
  migrationInitiation: [
    'AcceptTermsAndConditions',
    'ReviewKyc',
    'ValidateCardOrder',
  ],
  migrationActivation: ['FundWallet'],
  migrationExecution: [
    'SubscriptionRecard',
    'PhysicalCardsActivation',
    'AddBankAccountCode',
  ],
};

/**
 * The active phase/step of the SFS migration is considered as:
 * - Either the first one with "InProgress" status
 * - Or the last one with "Completed" status if no "InProgress" phase.
 *
 * @param phases Migration phases
 * @returns The last ongoing step that is considered active.
 * @remark The computation only relies on migration phase/step (i.e migration status is not involved)
 */
export const getMigrationActivePhase = (phases: MigrationProcess): StepName => {
  // Ordering phases in `phases` Object
  const orderedPhaseNames = Object.keys(MIGRATION_STEPS_ORDER) as StepName[];
  const orderedPhases = orderedPhaseNames.map((name) => ({
    name,
    data: phases[name],
  }));

  let activePhase;

  const firstInProgress = orderedPhases.find(
    ({ data: { status } }) => status === 'InProgress',
  );
  if (firstInProgress) {
    activePhase = firstInProgress;
  } else {
    activePhase = orderedPhases
      .reverse()
      .find(({ data: { status } }) => status === 'Completed');
  }

  return activePhase?.name ?? 'migrationInitiation';
};

export const getSfsMigrationPhaseUrlPath = (stepName: StepName): string =>
  stepNameToUrlpart[stepName as keyof typeof stepNameToUrlpart];
