import { enrichUser } from 'src/core/utils/entities/user';

import { type CardsAccess } from '../models/cardsAccess';
import { type ControlRule } from '../models/controlRule';
import { type MembersCostCenter } from '../models/costCenter';
import { type Invite } from '../models/invite';
import {
  type Member,
  type MemberDetails,
  type MemberRoles,
  type RawDataByCompany,
  type RawMember,
} from '../models/member';
import {
  type Policy,
  reshapeToSpendingTypes,
  type RolePolicy,
} from '../models/policy';
import { type Team } from '../models/teams';

/**
 * Return both camelCase and snake_case, deeply
 * snake_case is used for legacy reasons and should not be called in new code
 */
export const reshapeMember = (
  data: RawMember,
  {
    company,
    teams,
    controlRules,
    policies,
    subscriptionCount,
    invites,
    membersCostCenter,
    cardsAccess,
  }: {
    company: { id: string };
    teams: Team[];
    controlRules: ControlRule[];
    policies: Policy[];
    subscriptionCount?: { count: number };
    invites: Invite[];
    membersCostCenter: MembersCostCenter;
    cardsAccess?: CardsAccess;
  },
): Member => {
  const computedProperties = enrichUser(data, company);

  const controlRule = controlRules.find((rule) =>
    rule.userIds.includes(data.id),
  );
  const userPolicy = getUserPolicy(computedProperties, company.id, policies);

  const reshapedRoles = reshapeRoles(data.data_by_company[company.id].roles);
  return {
    id: data.id,
    email: data.email,
    firstName: data.first_name,
    lastName: data.last_name,
    fullname: data.fullname,
    isOrganisationOwner: data.is_organisation_owner,
    mobileExt: data.mobile_ext,
    mobileNo: data.mobile_no,
    pending: data.pending,
    isPending: data.pending,
    inviteId: invites.find((invite) => invite.invitedUserId === data.id)?.id,
    costCenter: membersCostCenter[data.id],
    lang: data.lang,
    gender: data.gender,
    createdAt: data.created_at,
    avatar: data.avatar,
    bankInfo: data.data_by_company[company.id].bank_info,
    hasPlasticCard: Boolean(data.data_by_company[company.id].plastic_card),
    displayName: getMemberName({
      email: data.email,
      firstName: data.first_name,
      fullname: data.fullname,
      pending: data.pending,
    }),
    hasSlackEnabled: computedProperties.has_slack_enabled,
    hasSlackLinked: computedProperties.has_slack_linked,
    isAccountOwner: computedProperties.is_account_owner,
    isAdmin: computedProperties.is_admin,
    isController: computedProperties.is_controller,
    isDeleted: computedProperties.is_deleted,
    isGroupAdmin: computedProperties.is_group_admin,
    isRequester: reshapedRoles.some((role) => role.name === 'Requester'),
    spendingPolicy: computedProperties.spending_policy,
    requiredInfoTypesForEmployeeBankAddress:
      data.data_by_company[company.id]
        .requiredInfoTypesForEmployeeBankAddress ?? [],
    teams: teams.filter(({ id }) =>
      data.data_by_company[company.id].groups_ids?.includes(id),
    ),
    roles: reshapedRoles,
    plasticCard: data.data_by_company[company.id].plastic_card,
    subscriptionsCount: subscriptionCount?.count ?? 0,
    controlRule,
    policy: userPolicy,
    cardsAccess,
    manager: data.manager,
  };
};

export const reshapeMemberDetails = (
  data: RawMember,
  params: {
    company: { id: string };
    teams: Team[];
    controlRules: ControlRule[];
    policies: Policy[];
    subscriptionCount?: { count: number };
    invites: Invite[];
    membersCostCenter: MembersCostCenter;
    cardsAccess?: CardsAccess;
    canConfirmPayments: boolean;
  },
): MemberDetails => {
  const member = reshapeMember(data, params);

  return {
    ...member,
    canConfirmPayments: params.canConfirmPayments,
  };
};

function reshapeRoles(roles: RawDataByCompany['roles']): MemberRoles {
  return roles
    .filter((role) => role.scope.entity_type !== 'team')
    .map((role) => ({
      id: role.id,
      name: role.name,
      order: role.order,
      companyId: role.company_id,
      organisationId: role.organisation_id,
      fullPermissions: role.full_permissions,
      parentsId: role.parents_id,
      parentId: role.parent_id,
      scopeId: role.scope_id,
      scope: {
        entityType: role.scope.entity_type,
        entityId: role.scope.entity_id,
      },
      permissions: role.permissions.map(reshapeRolePolicy),
      userPermissions: role.user_permissions,
    }));
}

type RawPermission = RawDataByCompany['roles'][number]['permissions'][number];

// Policies from role.permission don't always have `params`, hence the difference with reshapePolicy
function reshapeRolePolicy(permission: RawPermission): RolePolicy {
  return {
    id: permission.id,
    name: permission.name,
    isDefault: permission.is_default,
    isCustom: permission.is_user_custom,
    params: permission.params
      ? {
          approvalNeeded: permission.params.approval_needed,
          amountPerTransaction: permission.params.transaction_max,
          amountPerMonth: permission.params.spending_limit,
          spendingTypes: reshapeToSpendingTypes(
            permission.params.spending_types,
          ),
        }
      : undefined,
  };
}

const reshapePolicy = (permission: RawPermission): Policy => {
  if (!permission.params) {
    throw new Error('Policy needs to have params');
  }
  return {
    id: permission.id,
    name: permission.name,
    isDefault: permission.is_default,
    isCustom: permission.is_user_custom,
    params: {
      approvalNeeded: permission.params.approval_needed,
      amountPerTransaction: permission.params.transaction_max,
      amountPerMonth: permission.params.spending_limit,
      spendingTypes: reshapeToSpendingTypes(permission.params.spending_types),
    },
  };
};

const getUserPolicy = (
  user: RawMember,
  companyId: string,
  policies: Policy[],
): Policy | null => {
  const isRequester = user?.data_by_company?.[companyId]?.is_requester;
  const isAccountOwner = user?.data_by_company?.[companyId]?.is_account_owner;
  const canUserHavePolicy = isRequester && !isAccountOwner;

  if (!canUserHavePolicy) {
    return null;
  }

  const userPolicy =
    user?.data_by_company?.[companyId].legacy_spending_policy || null;

  if (userPolicy === null) {
    return null;
  }

  return userPolicy.is_user_custom
    ? reshapePolicy(userPolicy)
    : policies.find((policy) => policy.id === userPolicy?.id) || null;
};

const getMemberName = (
  member: Pick<Member, 'pending' | 'email' | 'fullname' | 'firstName'>,
): string => {
  return member.pending
    ? member.email
    : (member.fullname ?? member.firstName ?? member.email);
};
