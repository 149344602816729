import { useQueryClient } from 'react-query';

import { useAccountingIntegrationStatusQuery } from 'modules/accounting-integration/apis';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type Payable as RawPayable } from 'modules/bookkeep/export/pages/IntegrationExportPage/payable';
import { isIntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { type PayableId } from 'modules/bookkeep/prepare-payables/models';
import { type Payable } from 'modules/payable/models';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { getFailedExportPayablesQueryKey } from './query-key-registry';

/**
 * Query and cache config
 */

export type FailedExportPayablesRawResponse = {
  payable: RawPayable;
  reason: string;
  errorData?: {
    errorMessage: string;
    unmappedFieldDetails: {
      name: string;
      value: string;
    };
  };
  faultyPayableId?: PayableId;
  suggestion:
    | 'retry'
    | 'markAsAccountedManually'
    | 'sendBackToPrepare'
    | 'contactSupport';
}[];

export type FailedExportPayablesResponse = {
  payable: Payable;
  exportError: {
    reason: string;
    errorData?: {
      value?: string;
      unmappedFieldDetails: {
        value: string;
        name: string;
      };
      errorMessage: string;
    };
    faultyPayableId?: PayableId;
    suggestion:
      | 'retry'
      | 'markAsAccountedManually'
      | 'sendBackToPrepare'
      | 'contactSupport';
  };
}[];

export const useUpdateFailedExportPayablesQueryCache = () => {
  const companyId = useCompanyId();

  const queryClient = useQueryClient();

  return async ({
    payableIdsToRemove,
  }: {
    payableIdsToRemove: PayableId[];
  }): Promise<void> => {
    const queryKey = getFailedExportPayablesQueryKey(companyId);

    const previousData =
      queryClient.getQueryData<FailedExportPayablesRawResponse>(queryKey);

    if (previousData) {
      const nextData = previousData.filter(
        (payable) => !payableIdsToRemove.includes(payable.payable.id),
      );

      queryClient.setQueryData(queryKey, nextData);
    }
  };
};

export const useInvalidateFailedExportPayablesQueryCache = () => {
  const queryClient = useQueryClient();

  return async (companyId: string): Promise<void> => {
    await queryClient.invalidateQueries(
      getFailedExportPayablesQueryKey(companyId),
    );
  };
};

/**
 * REST query hook
 */

export const useFailedExportPayables =
  (): QueryState<FailedExportPayablesResponse> => {
    const companyId = useCompanyId();

    const integrationStatusQuery = useAccountingIntegrationStatusQuery();

    const isEnabled =
      integrationStatusQuery.status === 'success'
        ? isIntegrationStatusWithIntegration(integrationStatusQuery.data)
        : false;

    return useQuery<
      FailedExportPayablesResponse,
      FailedExportPayablesRawResponse
    >({
      isEnabled,
      key: getFailedExportPayablesQueryKey(companyId),
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/accounting-integration/export/failed-payables',
      },
      reshapeData,
    });
  };

/**
 * Helpers
 */

const reshapeData = (
  rawFailedPayables: FailedExportPayablesRawResponse,
): FailedExportPayablesResponse =>
  rawFailedPayables.map((rawFailedPayable) => ({
    exportError: {
      reason: rawFailedPayable.reason,
      errorData: rawFailedPayable?.errorData,
      faultyPayableId: rawFailedPayable?.faultyPayableId,
      suggestion: rawFailedPayable.suggestion,
    },
    payable: reshapePayable(rawFailedPayable.payable),
  }));

// TODO@financeAccountant@CORE-4887 improve payable shape returned by the backend

const reshapePayable = (rawPayable: RawPayable): Payable => {
  let subtype: Payable['subtype'] | undefined;
  if (rawPayable.subType === 'subscription') {
    subtype = 'subscriptionCard';
  }
  if (rawPayable.subType === 'singlePurchase') {
    subtype = 'singleUseCard';
  }
  if (rawPayable.subType === 'physical') {
    subtype = 'plasticCard';
  }
  if (rawPayable.subType === 'creditNote') {
    subtype = 'creditNote';
  }

  return {
    id: rawPayable.id,
    version: rawPayable.version,
    kind: rawPayable.type === 'reverseBill' ? 'reversal' : 'expense',
    subtype,
    description: rawPayable.description,
    counterparty:
      rawPayable.counterparty.type === 'supplier'
        ? {
            thumbnailUrl: rawPayable.counterparty.thumbnailUrl,
            name: rawPayable.counterparty.name,
            type: 'supplier',
          }
        : {
            avatar: '',
            email: '',
            givenName: '',
            familyName: rawPayable.counterparty.name,
            type: 'user',
          },
    member: rawPayable.member
      ? {
          avatar: '',
          email: '',
          givenName: '',
          familyName: rawPayable.member.name,
          type: 'user',
        }
      : undefined,
    supplier: rawPayable.supplier
      ? {
          name: rawPayable.supplier.name,
          thumbnailUrl: rawPayable.supplier.thumbnailUrl,
        }
      : undefined,
    receiptNumber: undefined,
    grossAmount: rawPayable.amount,
    functionalAmount: rawPayable.functionalAmount,
    mileageDetails: undefined,
    perDiem: undefined,
    creditNoteDetails: undefined,
    creationDate: new Date(rawPayable.date),
  };
};
