import React, { useEffect, useMemo, useState } from 'react';

import { WireTransferApproverModalContainer } from 'common/components/WireTransferModal';
import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type QueryError } from 'src/core/api/queryError';

import {
  type AuthType,
  useConfirmTransfersTrackingEvents,
  useConfirmTransferErrorMessage,
  useConfirmTransfers,
  useInvalidateTransferQueries,
} from '../../hooks';
import {
  GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON,
  GBP_BATCH_CONFIRMATION_MAX_RETRIES,
  type ConfirmationError,
  type ConfirmTransferErrors,
} from '../../hooks/api/useConfirmTransfers';
import { sumTransfersAmount, type Transfer } from '../../models';

export type TransferConfirmationModalProps = {
  transfers: Transfer[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onConfirmationPaymentsErrors?: (payments: ConfirmationError[]) => void;
};

export const TransferConfirmationModal = ({
  isOpen,
  transfers,
  onClose,
  onConfirm,
  onConfirmationPaymentsErrors,
}: TransferConfirmationModalProps) => {
  const confirmTransfersTrackingEvents = useConfirmTransfersTrackingEvents();
  const { pushNotif } = useNotifications();
  const {
    getMessage: getConfirmTransferErrorMessage,
    shouldDisplayErrorNotification,
  } = useConfirmTransferErrorMessage();
  const [
    confirmTransfers,
    confirmTransfersQueryState,
    resetConfirmTransfersQueryState,
  ] = useConfirmTransfers();

  const invalidateTransferQueries = useInvalidateTransferQueries();
  const companyCurrency = useCompanyCurrency();

  const transfersAmount = sumTransfersAmount(transfers, companyCurrency);
  const transferIds = useMemo(
    () => transfers.map((transfer) => transfer.id),
    [transfers],
  );

  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [approvalAuthType, setApprovalAuthType] = useState<AuthType | null>(
    null,
  );

  const onApproveTransfers = async () => {
    /**
     * In case of UK, we retry the confirmation a couple of times in case of "Transfer not found" error. This is because Adyen sometimes takes a bit of time to process the transfers.
     */
    let confirmationRetryCount = 0;
    setIsApproving(true);
    while (confirmationRetryCount < GBP_BATCH_CONFIRMATION_MAX_RETRIES) {
      try {
        confirmationRetryCount += 1;
        const data = await confirmTransfers({
          transferIds,
        });

        setApprovalAuthType(data.authType);
        return;
      } catch (error) {
        const isGbpConfirmationError =
          error.data.reason &&
          error.data.reason === GBP_BATCH_CONFIRMATION_NOT_ALLOWED_REASON &&
          error.data.errors &&
          onConfirmationPaymentsErrors;

        if (!isGbpConfirmationError) {
          displayErrorToast(error);
          setIsApproving(false);
          throw error;
        }

        const retriableError = error.data.errors?.find(
          (paymentError: ConfirmationError) =>
            paymentError?.error === 'transferNotFound',
        );

        if (
          retriableError &&
          confirmationRetryCount < GBP_BATCH_CONFIRMATION_MAX_RETRIES
        ) {
          await new Promise((resolve) => setTimeout(resolve, 5000));
          continue;
        }

        if (confirmationRetryCount >= GBP_BATCH_CONFIRMATION_MAX_RETRIES) {
          displayErrorToast(error);
          setIsApproving(false);
          throw error;
        }

        onConfirmationPaymentsErrors(error.data.errors);
        setIsApproving(false);
        return;
      }
    }
  };

  function displayErrorToast(error: QueryError<ConfirmTransferErrors>) {
    const translationParams = {
      count: transferIds.length,
    };
    if (shouldDisplayErrorNotification(error, translationParams)) {
      pushNotif({
        type: NotificationType.Danger,
        message: getConfirmTransferErrorMessage(error, translationParams),
      });
    }
  }

  useEffect(() => {
    if (isOpen) {
      resetConfirmTransfersQueryState();
    }
  }, [isOpen]);

  return (
    <WireTransferApproverModalContainer
      isOpen={isOpen}
      amount={transfersAmount}
      count={transfers.length}
      isApproving={
        confirmTransfersQueryState.status === 'loading' || isApproving
      }
      approvalAuthType={approvalAuthType}
      onClose={() => {
        setIsApproving(false);
        onClose();
      }}
      onApprove={async () => {
        confirmTransfersTrackingEvents.onConfirmTransfersConfirmationModalConfirmed(
          { transfers },
        );
        await onApproveTransfers();
      }}
      onComplete={() => {
        invalidateTransferQueries();
        if (onConfirm) {
          onConfirm();
        }
      }}
    />
  );
};
