import { EmptyState } from '@dev-spendesk/grapes';
import React, { useContext, type ReactNode } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { PageViewerContext } from 'src/core/common/components/layout/SiderPageLayout/viewerContext';

import { getDocumentViewer } from './documentViewer';
import { usePayableQuery } from './hooks/usePayableQuery';
import {
  PayablePanel,
  PayablePanelLoader,
} from '../../components/PayablePanel';

type Props = {
  payableId: string;
  exportError?: ReactNode;
  panelActions?: ReactNode;
  onClose(): void;
  className?: string;
};

export const PayablePanelContainer = ({
  payableId,
  exportError,
  panelActions,
  onClose,
  className,
}: Props) => {
  const { t } = useTranslation('global');
  const setPageViewerContent = useContext(PageViewerContext);
  const payableQueryState = usePayableQuery(payableId);

  if (payableQueryState.status !== 'success') {
    return <PayablePanelLoader className={className} />;
  }

  if (!payableQueryState.data) {
    return (
      <EmptyState
        title={t('misc.errors.requestErrors.notFound')}
        subtitle=""
        iconName="warning"
        iconVariant="warning"
        actions={[]}
      />
    );
  }

  return (
    <PayablePanel
      className={className}
      payable={payableQueryState.data}
      exportError={exportError}
      panelActions={panelActions}
      onDocumentClick={(document) => {
        if (setPageViewerContent) {
          setPageViewerContent(
            getDocumentViewer(document, () => setPageViewerContent(undefined)),
          );
        }
      }}
      onClose={onClose}
    />
  );
};
