// Fetch payment's elligible VAT accounts
export const FETCH_PAYMENTS_VAT_ACCOUNTS_SUCCESS =
  'FETCH_PAYMENTS_VAT_ACCOUNTS_SUCCESS';

// Save VAT rates for a payment
export const SAVE_PAYMENT_VAT_LOADING = 'SAVE_PAYMENT_VAT_LOADING';
export const SAVE_PAYMENT_VAT_FAILURE = 'SAVE_PAYMENT_VAT_FAILURE';
export const SAVE_PAYMENT_VAT_SUCCESS = 'SAVE_PAYMENT_VAT_SUCCESS';

// Save VAT type for a payment
export const SAVE_PAYMENT_VAT_TYPE_LOADING = 'SAVE_PAYMENT_VAT_TYPE_LOADING';
export const SAVE_PAYMENT_VAT_TYPE_FAILURE = 'SAVE_PAYMENT_VAT_TYPE_FAILURE';
export const SAVE_PAYMENT_VAT_TYPE_SUCCESS = 'SAVE_PAYMENT_VAT_TYPE_SUCCESS';
