import { colors, Icon, type TriggerProps } from '@dev-spendesk/grapes';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import React from 'react';

import type { Company } from 'modules/app/hooks/useCompany';
import { formatMoney } from 'src/core/utils/money';
import {
  NO_RELOAD,
  HARD_RELOAD,
  type getReloadType,
} from 'src/core/utils/wallet';

import warningSoftSVG from './warning-icon-alt.svg';
import warningHardSVG from './warning-icon.svg';

import './CompanySwitcherButton.css';

type ReloadType = ReturnType<typeof getReloadType>;
type Props = {
  company: Company;
  reloadType: ReloadType;
  showBalance: boolean;
  triggerProps: Omit<TriggerProps, 'isDropdown'>;
};

const getWarningIcon = (reloadType: ReloadType) => {
  if (reloadType === NO_RELOAD) {
    return;
  }
  return reloadType === HARD_RELOAD ? warningHardSVG : warningSoftSVG;
};

export const CompanySwitcherButton = ({
  company,
  reloadType,
  showBalance,
  triggerProps,
}: Props) => {
  return (
    <button
      type="button"
      className={cx('CompanySwitcherButton', {
        'CompanySwitcherButton--no-wallet': !showBalance,
      })}
      // FIXME: this testid is shared with the CompanyDropdownToggleButton
      // because we either display that one or this one depending on the loads
      // to limit feature, which is difficult to deal with in E2E tests.
      data-testid="CompanySwitcherButton"
      {...triggerProps}
    >
      <div className="CompanySwitcherButton__content">
        <span
          className="CompanySwitcherButton__content-name"
          title={company.name}
        >
          {company.name}
        </span>
        {showBalance && !isNil(company.balance_available_all_accounts) && (
          <span
            className={cx('CompanySwitcherButton__content-balance', {
              'CompanySwitcherButton__content-balance-warning':
                reloadType === HARD_RELOAD,
            })}
          >
            {reloadType !== NO_RELOAD && (
              <img
                alt="warning"
                className="WarningIcon"
                src={getWarningIcon(reloadType)}
              />
            )}
            {formatMoney(
              company.balance_available_all_accounts,
              company.currency,
            )}
          </span>
        )}
      </div>
      <Icon name="caret-down" color={colors.neutral} />
    </button>
  );
};
