import { useQueryClient } from '@tanstack/react-query';

import { type QueryState } from 'src/core/api/queryState';
import { useCompanyId } from 'src/sfs-migration/common/hooks/useCompany';
import { sfsMigrationQueryClient } from 'src/sfs-migration/common/utils/sfsMigrationQueryClient';

import { type GetSubscriptionsResponse } from './types';

const subscriptionsQueryKey = (companyId: string) => [
  'subscriptions',
  { companyId },
];

export const useSubscriptionsQuery =
  (): QueryState<GetSubscriptionsResponse> => {
    const companyId = useCompanyId();
    const { data, isLoading, error } =
      sfsMigrationQueryClient.subscriptions.getSubscriptions.useQuery(
        subscriptionsQueryKey(companyId),
        {
          params: { companyId },
        },
        {
          // TODO: to challenge, but if we want to retry we need to accomodate the user xp for it
          retry: 0,
        },
      );

    if (isLoading) {
      return { status: 'loading' };
    }
    if (error) {
      return { status: 'error', error: error.body };
    }

    return { status: 'success', data: data.body };
  };

export const useInvalidateSubscriptionsQuery = () => {
  const queryClient = useQueryClient();
  return (companyId: string) => {
    return queryClient.invalidateQueries(subscriptionsQueryKey(companyId));
  };
};

export const getSubscriptionsMigrationStatus = (
  response?: GetSubscriptionsResponse,
): {
  status: 'noSub' | 'todo' | 'selectionDone' | 'inProgress' | 'completed';
} => {
  if (
    !response ||
    (response.subscriptions.length === 0 && response.step === 0)
  ) {
    return { status: 'noSub' };
  }
  if (response.step === 0) {
    return { status: 'todo' };
  }

  if (
    response.subscriptions.some((sub) => sub.bankingProvider === 'bankable')
  ) {
    return { status: 'inProgress' };
  }

  return { status: 'completed' };
};
