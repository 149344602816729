import {
  CalendarRange,
  Popover,
  DatePickerInput,
  FormField,
  type DateRange,
} from '@dev-spendesk/grapes';
import { useId } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { DateRangeActions } from './DateRangeActions';

export type Props = {
  calendarId: string;
  initialValue?: DateRange;
  value: DateRange;
  onChange: (value: DateRange) => void;
  fit?: 'content' | 'parent';
  className?: string;
};

export const DateRangeFormField = ({
  className = '',
  value,
  initialValue = [undefined, undefined],
  onChange,
  fit = 'content',
  calendarId,
  ...props
}: Props) => {
  const { t } = useTranslation('global');

  const openCalendar = (onClick: () => void, isOpen: boolean) => {
    if (!isOpen) {
      onClick();
    }
  };

  const closeCalendar = (onClick: () => void, isOpen: boolean) => {
    if (isOpen) {
      onClick();
    }
  };

  const id = useId();

  const [from, to] = value;

  return (
    <Popover
      className={fit === 'parent' ? 'w-full' : 'w-fit'}
      renderTrigger={({ onClick, ...triggerProps }, isOpen) => (
        <div className={`flex flex-row gap-xs ${className}`}>
          <FormField
            label={t('misc.from')}
            className="w-full"
            htmlFor={`from-${id}`}
          >
            <DatePickerInput
              calendarId={calendarId}
              {...triggerProps}
              {...props}
              {...{ id: `from-${id}` }}
              openCalendar={() => openCalendar(onClick, isOpen)}
              closeCalendar={() => closeCalendar(onClick, isOpen)}
              isCalendarOpen={isOpen}
              value={from}
              onChange={(date) => onChange([date, to])}
            />
          </FormField>

          <FormField
            label={t('misc.to')}
            className="w-full"
            htmlFor={`to-${id}`}
          >
            <DatePickerInput
              calendarId={calendarId}
              {...triggerProps}
              {...props}
              {...{ id: `to-${id}` }}
              openCalendar={() => openCalendar(onClick, isOpen)}
              closeCalendar={() => closeCalendar(onClick, isOpen)}
              isCalendarOpen={isOpen}
              value={to}
              onChange={(date) => onChange([from, date])}
            />
          </FormField>
        </div>
      )}
    >
      {(closeDropdown) => (
        <>
          <DateRangeActions
            className="border-0 border-b border-solid border-neutral-light"
            onChange={(newValue) => {
              if (newValue.every(Boolean)) {
                onChange(newValue);
              } else {
                onChange(initialValue);
              }
              closeDropdown();
            }}
          />
          <div className="px-m py-s" id={calendarId}>
            <CalendarRange
              value={value}
              onClick={(newValue) => {
                onChange(newValue);
                if (newValue.every(Boolean)) {
                  closeDropdown();
                }
              }}
            />
          </div>
        </>
      )}
    </Popover>
  );
};
