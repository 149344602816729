import { Button, FormField, Modal, TextArea } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import { type FormValues } from './formValues';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDeny(reason: string): Promise<void>;
};

export const DenyPurchaseOrderRequestModal = ({
  isOpen,
  onClose,
  onDeny,
}: Props) => {
  const { t } = useTranslation();

  const { values, errors, handleChange, submitForm } = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: { reason: '' },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (valuesToValidate) => {
      const formikErrors: FormikErrors<typeof valuesToValidate> = {};

      if (!isDenyReasonValid(valuesToValidate.reason)) {
        formikErrors.reason = 'misc.requiredField';
      }

      return formikErrors;
    },
    onSubmit: async (submittedValues) => {
      await onDeny(submittedValues.reason);
      onClose();
    },
  });

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="cancel-purchase-order"
          text={t('requests.actions.denyThis')}
          variant="primary"
          type="submit"
          onClick={submitForm}
        />,
      ]}
      iconName="cross"
      iconVariant="primary"
      title={t('requests.actions.denyThis')}
      isOpen={isOpen}
    >
      <form>
        <FormField
          label={t('requests.denyLabel')}
          alertMessage={errors.reason ? t(errors.reason) : undefined}
        >
          <TextArea
            placeholder={t('requests.denyPlaceholder')}
            name="reason"
            rows={3}
            value={values.reason}
            onChange={handleChange}
          />
        </FormField>
      </form>
    </Modal>
  );
};

const isDenyReasonValid = (reason: string): boolean => {
  return reason.trim().length > 0;
};
