import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type User } from '../../../../models';

type RawUser = {
  id: string;
  fullname: string;
  data_by_company: {
    [companyId: string]: {
      roles: {
        full_permissions: boolean;
        order: number;
      }[];
    };
  };
};

export const useGetUsersQuery = (): QueryState<User[]> => {
  const companyId = useCompanyId();

  return useQuery<User[], RawUser[]>({
    key: ['users', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/users',
    },
    reshapeData: (rawUsers) => {
      return rawUsers
        .filter((user) =>
          user.data_by_company[companyId].roles.some(
            (role) => role.full_permissions || role.order === 3,
          ),
        )
        .map((rawUser) => ({
          id: rawUser.id,
          fullName: rawUser.fullname,
        }));
    },
  });
};
