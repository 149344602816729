import { Icon, type IconName } from '@dev-spendesk/grapes';
import React, { type ReactNode, useEffect, useRef, useId } from 'react';

import styles from './DisclosureItem.module.css';
import { useNavigationContext } from '../NavigationContext';

type Props = {
  children: ReactNode;
  iconName: IconName;
  summary: string;
};

export const DisclosureItem = ({ iconName, children, summary }: Props) => {
  const detailsRef = useRef<HTMLDetailsElement>(null);
  const isExpanded = useNavigationContext();
  const summaryId = useId();

  useEffect(() => {
    if (!isExpanded && detailsRef.current) {
      detailsRef.current.open = false;
    }
  }, [isExpanded, detailsRef]);

  return (
    <details className={styles.details} name="navigation" ref={detailsRef}>
      <summary
        className={`navigation-item h-xxl list-none ${styles.safariFix}`}
        aria-labelledby={summaryId}
      >
        <Icon
          name={iconName}
          size="m"
          aria-hidden="true"
          className="shrink-0"
        />
        <span id={summaryId} className="navigation-toggle-display grow">
          {summary}
        </span>
        <Icon
          name="caret-right"
          aria-hidden="true"
          size="m"
          className={`${styles.caret} navigation-toggle-display`}
        />
      </summary>
      <ul className={styles.list}>{children}</ul>
    </details>
  );
};
