import { type IntegrationValidateSettingsResult } from 'modules/accounting-integration/models/settings-validation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export type IntegrationStatus =
  | {
      integration: 'noIntegration';
      availableAccountingIntegrations: AccountingSoftware[];
    }
  | {
      integration: 'switchInProgress';
      availableAccountingIntegrations: AccountingSoftware[];
    }
  | IntegrationStatusWithIntegration;

export type IntegrationStatusWithIntegration = {
  integration: AccountingSoftware;
  accountingCountry: string;
  availableAccountingIntegrations: AccountingSoftware[];
  authorization: IntegrationStatusAuthorization;
  settingsRefresh: AccountingIntegrationStatusSettingsRefresh;
  descriptor: AccountingIntegrationStatusDescriptor;
  capabilities: {
    spendeskBankAccount?: BankAccountCapability;
    fundingBankAccount?: BankAccountCapability;
    creditorBankAccount?: BankAccountCapability;
    bankFeesAccount?: BankAccountCapability;
    continuousAccounting?: true;
    customExports?: true;
    analyticalFields?: AnalyticalFieldsCapability;
    employeeAccounts?: EmployeeAccountsCapability;
    expenseAccounts?: ExpenseAccountsCapability;
    payables?: true;
    settlements?: true;
    payablesSettlementsReExport?: true;
    supplierAccounts?: SupplierAccountsCapability;
    taxAccounts?: TaxAccountsCapability;
    invoiceNumber?: true;
    auxiliaryAccountsEnabled?: true;
    bankJournalExport?: true;
    supplierSync?: true;
    exportValidation?: true;
    memberSync?: true;
    mfaCheckEnabled?: true;
    journals?: true;
    amortisation?: AmortisationCapability;
  };
  settingsValidation: IntegrationValidateSettingsResult;
};
interface IntegrationStatusWithIntegrationSupportingSupplierSync
  extends IntegrationStatusWithIntegration {
  integration: AccountingSoftware;
  capabilities: IntegrationStatusWithIntegration['capabilities'] & {
    supplierSync: true;
  };
}

export type BankAccountCapability = 'pullAndSelect' | 'localOnly';
export type EmployeeAccountsCapability =
  | 'localOnly'
  | 'localOnlyWithDefaultAccounts'
  | 'pullWithDefaultAccounts';
export type ExpenseAccountsCapability =
  | 'pull'
  | 'localOnly'
  | 'localOnlyWithDefaultAccounts'
  | 'push';
export type AnalyticalFieldsCapability =
  | 'pullAndMap'
  | 'pullAndMapWithValues'
  | 'pullAndMapWithStaticFields'
  | 'pullAndMapWithStaticFieldsAndValues'
  | 'staticMap';
export type SupplierAccountsCapability =
  | 'localOnly'
  | 'localOnlyWithDefaultAccounts'
  | 'pullWithDefaultAccounts';
export type TaxAccountsCapability =
  | 'pull'
  | 'pullAndSet'
  | 'localOnly'
  | 'localOnlyWithDefaultAccounts'
  | 'push';
export type AmortisationCapability = 'localOnly' | 'pullAndMap';

export type FileBasedAccountingIntegration =
  | 'SpendeskAccounting'
  | 'SpendeskAccountingSingleEntry'
  | 'Sage'
  | 'Cegid';

export type NativeAccountingIntegration =
  | 'Xero'
  | 'Datev'
  | 'Quickbooks'
  | 'Netsuite'
  | 'Sage100';

export type AccountingSoftware =
  | FileBasedAccountingIntegration
  | NativeAccountingIntegration;

export type AccountingSoftwareGraphQl =
  | 'spendesk_accounting'
  | 'spendesk_accounting_single_entry'
  | 'sage'
  | 'cegid'
  | 'xero'
  | 'datev'
  | 'quickbooks'
  | 'netsuite'
  | 'sage100'
  | 'not_set';

export const toAccountingSoftware = (
  accountingIntegration: AccountingSoftwareGraphQl,
): AccountingSoftware | 'noIntegration' => {
  switch (accountingIntegration) {
    case 'spendesk_accounting':
      return 'SpendeskAccounting';
    case 'spendesk_accounting_single_entry':
      return 'SpendeskAccountingSingleEntry';
    case 'sage':
      return 'Sage';
    case 'cegid':
      return 'Cegid';
    case 'xero':
      return 'Xero';
    case 'datev':
      return 'Datev';
    case 'quickbooks':
      return 'Quickbooks';
    case 'netsuite':
      return 'Netsuite';
    case 'sage100':
      return 'Sage100';
    case 'not_set':
      return 'noIntegration';
    default:
      throw new Error(
        `Unknown accounting integration "${accountingIntegration}"`,
      );
  }
};

export const toAccountingSoftwareGraphQl = (
  accountingIntegration: AccountingSoftware | 'noIntegration',
): AccountingSoftwareGraphQl => {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
      return 'spendesk_accounting';
    case 'SpendeskAccountingSingleEntry':
      return 'spendesk_accounting_single_entry';
    case 'Sage':
      return 'sage';
    case 'Cegid':
      return 'cegid';
    case 'Xero':
      return 'xero';
    case 'Datev':
      return 'datev';
    case 'Quickbooks':
      return 'quickbooks';
    case 'Netsuite':
      return 'netsuite';
    case 'Sage100':
      return 'sage100';
    case 'noIntegration':
      return 'not_set';
    default:
      throw new Error(
        `Unknown accounting integration "${accountingIntegration}"`,
      );
  }
};

export type IntegrationStatusAuthorization =
  | {
      kind: 'oauth2OpenId' | 'oauth2ScopedOpenId';
      status: OAuth2OpenIdAuthorizationStatus;
    }
  | {
      kind: 'oauth1';
      status: OAuth1AuthorizationStatus | NetsuiteAuthorizationStatus;
    }
  | {
      kind: 'external';
      status: ExternalAuthorizationStatus;
    }
  | {
      kind: 'token';
      status: TokenAuthorizationStatus;
    }
  | {
      kind: 'noAuthorization';
    };

export type ExternalAuthorizationStatus = {
  status: 'connected' | 'notConnected' | 'connectionError' | 'needServerUp';
  error?: unknown;
};

export type OAuth2OpenIdAuthorizationStatus =
  | {
      status: 'connected';
      tenant: OpenIdTenant;
    }
  | {
      status: 'needTokenRefresh';
      tenant: OpenIdTenant;
    }
  | {
      status: 'fetchingTenants';
    }
  | {
      status: 'selectingTenant';
      tenants: OpenIdTenant[];
    }
  | {
      status: 'notConnected';
    }
  | {
      status: 'connectionError';
      error: string;
    };

// As far as the frontend is concerned, if tokens need refreshing, it's equivalent to being connected
// On the next request to Xero, the tokens will be refreshed, resulting in either
// the connected status or the disconnected status
export const getOAuth2OpenIdAuthorizationStatus = ({
  status,
}: OAuth2OpenIdAuthorizationStatus): Exclude<
  OAuth2OpenIdAuthorizationStatus['status'],
  'needTokenRefresh'
> => {
  return status === 'needTokenRefresh' ? 'connected' : status;
};

export type OAuth1AuthorizationStatus =
  | {
      status: 'connected';
    }
  | {
      status: 'notConnected';
    }
  | {
      status: 'connectionError';
      error: unknown;
    };

export type NetsuiteAuthorizationStatus =
  | OAuth1AuthorizationStatus
  | {
      status: 'fetchingSubsidiaries';
    }
  | {
      status: 'subsidiariesError';
      error: unknown;
    }
  | {
      status: 'selectingSubsidiaries';
      subsidiaries: Subsidiary[];
    };

export type TokenAuthorizationStatus =
  | {
      status: 'connected';
      tenant: OpenIdTenant;
    }
  | {
      status: 'notConnected';
    }
  | {
      status: 'connectionError';
      error: string;
    };

export type AccountingIntegrationStatusSettingsRefresh =
  | {
      kind: 'noRefresh';
    }
  | {
      kind: 'canRefresh';
      lastRefreshedAt?: string;
    };

export type AccountingIntegrationStatusDescriptor = {
  title: string;

  settingsAccountingHeaderFields?: {
    fieldName: string;
    fieldValue: unknown;
  }[];

  settingsAccountingHeaderLinks?: {
    linkName: string;
    linkUrl: string;
  }[];
};

export type OpenIdTenant = {
  type: 'organisation' | 'client';
  name: string;
  externalId: string;
};

export type Subsidiary = {
  id: string;
  companyId: string;
  externalId: string;
  externalParentId: string | null;
  subsidiaryName: string;
  active: boolean;
};

export type ConnectionStatus =
  | 'noIntegration'
  | 'switchInProgress'
  | 'noAuthorization'
  | 'connectionError'
  | 'fetchingTenants'
  | 'selectingTenant'
  | 'fetchingSubsidiaries'
  | 'selectingSubsidiaries'
  | 'subsidiariesError'
  | 'needServerUp'
  | 'connected'
  | 'notConnected';

export function getConnectionStatus(
  integration: IntegrationStatus,
): ConnectionStatus {
  if (
    integration.integration === 'noIntegration' ||
    integration.integration === 'switchInProgress'
  ) {
    return integration.integration;
  }

  switch (integration.authorization.kind) {
    case 'noAuthorization':
      return 'noAuthorization';
    case 'oauth2OpenId':
    case 'oauth2ScopedOpenId':
      return getOAuth2OpenIdAuthorizationStatus(
        integration.authorization.status,
      );
    case 'token':
    case 'oauth1':
    case 'external':
      return integration.authorization.status.status;
    default:
      rejectUnexpectedValue(
        'integration.authorization',
        integration.authorization,
      );
  }
}

export function isIntegrationStatusWithIntegration(
  integration: IntegrationStatus,
): integration is IntegrationStatusWithIntegration {
  return (
    integration.integration !== 'noIntegration' &&
    integration.integration !== 'switchInProgress'
  );
}

export function hasBankAccounts(integration: IntegrationStatus): boolean {
  return Boolean(
    isIntegrationStatusWithIntegration(integration) &&
      (integration.capabilities.bankFeesAccount ||
        integration.capabilities.fundingBankAccount ||
        integration.capabilities.creditorBankAccount ||
        integration.capabilities.spendeskBankAccount),
  );
}

export type TenantChooserState = { kind: 'closed' } | TenantChooserOpenState;

export type TenantChooserOpenState =
  | { kind: 'loading' }
  | { kind: 'chooseTenant'; tenants: OpenIdTenant[] };

export function getTenantChooserState(
  integration: IntegrationStatus,
): TenantChooserState {
  if (
    integration.integration === 'noIntegration' ||
    integration.integration === 'switchInProgress' ||
    (integration.authorization.kind !== 'oauth2OpenId' &&
      integration.authorization.kind !== 'oauth2ScopedOpenId')
  ) {
    return { kind: 'closed' };
  }

  switch (integration.authorization.status.status) {
    case 'selectingTenant':
      return {
        kind: 'chooseTenant',
        tenants: integration.authorization.status.tenants,
      };
    case 'fetchingTenants':
      return { kind: 'loading' };
    default:
      return { kind: 'closed' };
  }
}

export type SubsidiaryChooserState =
  | { kind: 'closed' }
  | SubsidiaryChooserOpenState;

export type SubsidiaryChooserOpenState =
  | { kind: 'loading' }
  | { kind: 'chooseSubsidiary'; subsidiaries: Subsidiary[] };

export function getSubsidiaryChooserState(
  integration: IntegrationStatus,
): SubsidiaryChooserState {
  if (
    integration.integration === 'noIntegration' ||
    integration.integration === 'switchInProgress' ||
    integration.authorization.kind !== 'oauth1'
  ) {
    return { kind: 'closed' };
  }

  switch (integration.authorization.status.status) {
    case 'selectingSubsidiaries':
      return {
        kind: 'chooseSubsidiary',
        subsidiaries: integration.authorization.status.subsidiaries,
      };
    case 'fetchingSubsidiaries':
      return { kind: 'loading' };
    default:
      return { kind: 'closed' };
  }
}

export function isUserAuthorized(integration: IntegrationStatus): boolean {
  if (
    isIntegrationStatusWithIntegration(integration) &&
    (integration.authorization.kind === 'oauth2OpenId' ||
      integration.authorization.kind === 'oauth2ScopedOpenId' ||
      integration.authorization.kind === 'oauth1' ||
      integration.authorization.kind === 'external' ||
      integration.authorization.kind === 'token') &&
    integration.authorization.status.status === 'connected'
  ) {
    return true;
  }

  return (
    isIntegrationStatusWithIntegration(integration) &&
    integration.authorization.kind === 'noAuthorization'
  );
}

// This is not strictly equal to !isUserAuthorized, as isUserAuthorized will
// return true by default (no integration, etc.)
export function isUserUnauthorized(integration: IntegrationStatus): boolean {
  if (
    integration.integration === 'noIntegration' ||
    integration.integration === 'switchInProgress'
  ) {
    return false;
  }

  return (
    (integration.authorization.kind === 'oauth2OpenId' ||
      integration.authorization.kind === 'oauth2ScopedOpenId' ||
      integration.authorization.kind === 'oauth1' ||
      integration.authorization.kind === 'external') &&
    integration.authorization.status.status !== 'connected'
  );
}

// TODO improve
export function hasIntegrationFileBasedExport(
  accountingSoftware: AccountingSoftware | 'noIntegration' | 'switchInProgress',
): boolean {
  return [
    'SpendeskAccountingSingleEntry',
    'SpendeskAccounting',
    'Sage',
    'Cegid',
  ].includes(accountingSoftware);
}

export const isIntegrationsPlanEnabled = ({
  integrationName,
  featureFlags,
}: {
  integrationName: AccountingSoftware;
  featureFlags: {
    netsuite: boolean;
  };
}): boolean => {
  return integrationName === 'Netsuite' ? featureFlags.netsuite : true;
};

// TODO load this list in the integration status instead
export function hasExternalConnection(
  accountingSoftware: AccountingSoftware | 'noIntegration' | 'switchInProgress',
): boolean {
  return ['Xero', 'Datev', 'Quickbooks', 'Netsuite', 'Sage100'].includes(
    accountingSoftware,
  );
}

export function shouldHideAccountsPayable(status: IntegrationStatus): boolean {
  if (
    status.integration === 'noIntegration' ||
    status.integration === 'switchInProgress'
  ) {
    return false;
  }

  return (
    !status.capabilities.employeeAccounts &&
    !status.capabilities.supplierAccounts
  );
}

export function isIntegrationStatusWithIntegrationSupportingSupplierSync(
  status: IntegrationStatusWithIntegration,
): status is IntegrationStatusWithIntegrationSupportingSupplierSync {
  return Boolean(status.capabilities.supplierSync);
}
