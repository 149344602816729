import { useEffect, useState } from 'react';

import { EmployeeCardOrderBanner } from 'src/sfs-migration/used-in-js-app/components/EmployeeCardOrderBanner';

import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { Step3 } from './Step3/Step3';
import { Step4 } from './Step4/Step4';
import { type ApiCard } from '../../../card';

import './CardActivation.css';

const steps = [Step1, Step2, Step3, Step4];

type Props = {
  withSFSBanner?: boolean;
  card: ApiCard;
  onFinish: () => void;
  isRecard?: boolean;
};

export const CardActivation = ({
  withSFSBanner = false,
  card,
  onFinish,
  isRecard = false,
}: Props) => {
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    let newStepIndex = 0;
    if (card.date_first_activation && card.last_pin_action) {
      newStepIndex = 3;
    } else if (!card.last_pin_action && card.date_first_activation) {
      newStepIndex = 2;
    }
    setStepIndex(newStepIndex);
  }, [card]);

  const goToNextStep = () => {
    const nextIndex = stepIndex + 1;

    if (nextIndex < steps.length) {
      setStepIndex(nextIndex);
    } else {
      onFinish();
    }
  };

  let Step: JSX.Element | null;

  switch (stepIndex) {
    case 0:
      Step = (
        <Step1 card={card} goToNextStep={goToNextStep} isRecard={isRecard} />
      );
      break;
    case 1:
      Step = (
        <Step2 card={card} goToNextStep={goToNextStep} onFinish={onFinish} />
      );
      break;
    case 2:
      Step = (
        <Step3 card={card} goToNextStep={goToNextStep} onFinish={onFinish} />
      );
      break;
    case 3:
      Step = <Step4 goToNextStep={goToNextStep} />;
      break;
    default:
      Step = null;
  }

  return (
    <div className="CardActivation">
      {withSFSBanner && <EmployeeCardOrderBanner className="mb-m" />}
      <div>{Step}</div>
    </div>
  );
};
