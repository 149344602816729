import { initContract } from '@ts-rest/core';
import { initQueryClient } from '@ts-rest/react-query';

import { sfsMigrationContract } from 'src/ts-rest-contract/src';

const contract = initContract().router(sfsMigrationContract, {
  pathPrefix: '/:companyId/sfs-migration',
});

export const sfsMigrationQueryClient = initQueryClient(contract, {
  baseUrl: appConfig.apiUrls.api,
  baseHeaders: {},
  credentials: 'include',
});
