import { type QueryKey } from 'react-query';

import { type FiltersState } from 'modules/bookkeep/payables/containers/PayablesFiltersContainer/hooks/useFiltersContext';
import { type PayableFilters } from 'modules/bookkeep/prepare-payables/models';

import { type PayableId } from '../../models';

export const getToPreparePayableListQueryKey = (
  companyId: string,
  filters?: PayableFilters,
): QueryKey => {
  return ['usePayablesQuery', companyId, filters].filter(Boolean);
};

export const getToPreparePayableGroupsQueryKey = (
  companyId: string,
  groupBy?: string | undefined,
  filters?: PayableFilters,
): QueryKey => {
  return ['usePayableGroupsQuery', companyId, groupBy, filters].filter(Boolean);
};

export const getPayableQueryKey = (
  companyId: string,
  payableId: PayableId | null,
  fetcherType?: 'rest' | 'graphQL',
  subResource?: 'proofs',
): QueryKey => {
  return [
    'useGetPayableQuery',
    companyId,
    payableId,
    fetcherType,
    subResource,
  ].filter(Boolean);
};

export const getPayableProofsQueryKey = (
  companyId: string,
  payableId: PayableId | null,
): QueryKey => {
  return getPayableQueryKey(companyId, payableId, undefined, 'proofs');
};

export const getPayableCountQueryKey = (
  companyId: string,
  filters?: FiltersState,
): QueryKey => {
  return ['useGetPayableCount', companyId, filters].filter(Boolean);
};

export const getPayableListQueryKey = (
  companyId: string,
  filters?: FiltersState,
): QueryKey => {
  return ['useGetPayableList', companyId, filters].filter(Boolean);
};
