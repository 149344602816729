import assignIn from 'lodash/assignIn';
import map from 'lodash/map';
import reject from 'lodash/reject';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';

import * as types from './actionTypes';
import {
  FETCH_CUSTOM_EXPORTS_SUCCESS,
  FETCH_CUSTOM_EXPORTS_LOADING,
  FETCH_CUSTOM_EXPORTS_FAILURE,
} from '../../../../../actionTypes/exports';

const initialState = {
  hasLoadedBases: false,
  hasLoadedCustomExports: false,
  customFields: null,
  customExports: null,
  bases: null,
  availableColumns: null,
};

const exportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case customFieldsTypes.FETCH_CF_SUCCESS: {
      return assignIn({}, state, { customFields: action.payload.customFields });
    }
    case types.FETCH_BASES_LOADING:
      return assignIn({}, state, { hasLoadedBases: false });
    case types.FETCH_BASES_SUCCESS: {
      const { bases } = action.payload;
      // Find uniq available columns based on the bases'
      const availableColumns = sortBy(
        uniqBy(
          (bases ?? []).flatMap((b) =>
            map(b.export_bases_columns, (bc) => ({
              id: bc.export_column.id,
              name: bc.name,
              original_name: bc.original_name,
              description: bc.description,
              pattern: bc.export_column.pattern,
            })),
          ),
          'id',
        ),
        'pattern',
      );
      return assignIn({}, state, {
        hasLoadedBases: true,
        bases,
        availableColumns,
      });
    }
    case types.FETCH_BASES_FAILURE:
      return assignIn({}, state, { hasLoadedBases: false });
    case types.DELETE_CUSTOM_EXPORT_SUCCESS: {
      const { id } = action.payload;
      const updatedExports = reject(state.customExports, { id });
      return assignIn({}, state, { customExports: updatedExports });
    }
    case types.TOGGLE_ACTIVE_EXPORT_SUCCESS: {
      const { id } = action.payload;
      const updatedExports = map(state.customExports, (ce) => ({
        ...ce,
        active: id === ce.id,
      }));
      return assignIn({}, state, { customExports: updatedExports });
    }
    case FETCH_CUSTOM_EXPORTS_LOADING:
      return assignIn({}, state, { hasLoadedCustomExports: false });
    case FETCH_CUSTOM_EXPORTS_SUCCESS: {
      return assignIn({}, state, {
        hasLoadedCustomExports: true,
        customExports: action.payload.exports,
        customFieldColumnId: action.payload.customFieldColumnId,
        customContentColumnId: action.payload.customContentColumnId,
      });
    }
    case FETCH_CUSTOM_EXPORTS_FAILURE:
      return assignIn({}, state, { hasLoadedCustomExports: false });
    default:
      return state;
  }
};

export default exportsReducer;
