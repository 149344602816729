import { type MonetaryValue, add } from 'ezmoney';
import compact from 'lodash/compact';

import type { CostCenter } from 'modules/budgets/models/costCenter';
import { type PanelItemsSection } from 'src/core/common/components/Panel';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type CustomFieldListItem } from '../../models/customFieldListItem';
import {
  type AnalyticalFieldAssociation,
  type Payable,
} from '../PayablePanelContainer';

type Params = {
  costCenters: CostCenter[];
  customFields: CustomFieldListItem[];
  t: TGlobalFunctionTyped;
  hasCostCentersFeature: boolean;
  customFieldIdForExpenseCategory: string;
  showVATAccount: boolean;
};

type GroupedResults = Record<
  string,
  {
    label: string;
    deleted: boolean;
    values: Record<
      string,
      { label: string; amount: MonetaryValue; deleted: boolean }
    >;
  }
>;

const getCustomFieldValueInfo = (
  customField: CustomFieldListItem | undefined,
  fieldAssociation: AnalyticalFieldAssociation,
  t: TGlobalFunctionTyped,
) => {
  const customFieldValue = customField?.custom_fields_values.find(
    ({ id }) => id === fieldAssociation.fieldEntityValueId,
  );

  let label: string = '';

  if (customField?.type === 'boolean') {
    if (customFieldValue !== undefined) {
      label =
        customFieldValue.value === '1' || customFieldValue.value === 'true'
          ? t('misc.yes')
          : t('misc.no');
    }
  } else {
    label = customFieldValue ? customFieldValue.value : '';
  }

  return {
    label,
    deleted: !!customFieldValue?.deleted_at,
  };
};

const getItemValueInfo = (
  fieldAssociation: Payable['itemLines'][number]['analyticalFieldAssociations'][number],
  {
    costCenterById,
    customFieldById,
    t,
  }: {
    t: TGlobalFunctionTyped;
    costCenterById: Map<string, CostCenter>;
    customFieldById: Map<string, CustomFieldListItem>;
  },
) => {
  switch (fieldAssociation.fieldKind) {
    case 'costCenter': {
      const costCenter = costCenterById.get(
        fieldAssociation.fieldEntityValueId,
      );
      return {
        label: costCenter?.name || '',
        deleted: !!costCenter?.isDeleted,
      };
    }
    case 'customField': {
      const customField = customFieldById.get(fieldAssociation.fieldEntityId);

      if (customField) {
        return getCustomFieldValueInfo(customField, fieldAssociation, t);
      }
      return { label: '', deleted: false };
    }
    default:
      return { label: '', deleted: false };
  }
};

const addExpenseAccount = ({
  itemLine,
  accumulator,
}: {
  itemLine: Payable['itemLines'][number];
  accumulator: GroupedResults;
}): GroupedResults => {
  const expenseAccountName = itemLine.expenseAccount?.name;

  if (!expenseAccountName) {
    return { expenseAccount: accumulator.expenseAccount };
  }
  const existingExpenseAccountData =
    accumulator.expenseAccount?.values[expenseAccountName];

  return {
    expenseAccount: {
      ...accumulator.expenseAccount,
      values: {
        ...accumulator.expenseAccount?.values,
        [expenseAccountName]: {
          label: expenseAccountName,
          amount: existingExpenseAccountData
            ? add(existingExpenseAccountData.amount, itemLine.netAmount)
            : itemLine.netAmount,
          deleted: !!itemLine.expenseAccount?.isArchived,
        },
      },
    },
  };
};

const addVATAccount = ({
  itemLine,
  accumulator,
}: {
  itemLine: Payable['itemLines'][number];
  accumulator: GroupedResults;
}): GroupedResults => {
  const name = itemLine.taxAccount?.name;
  if (!name) {
    return { taxAccount: accumulator.taxAccount };
  }
  const existingData = accumulator.taxAccount?.values[name];

  return {
    taxAccount: {
      ...accumulator.taxAccount,
      values: {
        ...accumulator.taxAccount?.values,
        [name]: {
          label: name,
          amount: existingData
            ? add(existingData.amount, itemLine.taxAmount)
            : itemLine.taxAmount,
          deleted: !!itemLine.taxAccount?.isArchived,
        },
      },
    },
  };
};

const getGroupingKey = (
  analyticalFieldAssociation: AnalyticalFieldAssociation,
) => {
  switch (analyticalFieldAssociation.fieldKind) {
    case 'costCenter':
      return analyticalFieldAssociation.fieldKind;
    default:
      return analyticalFieldAssociation.fieldEntityId;
  }
};

const groupSplittableFields = (
  { payable, ...params }: Params & { payable: Payable },
  initialData: GroupedResults,
) => {
  const customFieldById = new Map(
    params.customFields.map((customField) => [customField.id, customField]),
  );

  const costCenterById = new Map(
    params.costCenters.map((costCenter) => [costCenter.id, costCenter]),
  );

  return payable.itemLines.reduce<GroupedResults>(
    (accumulator: GroupedResults, itemLine: Payable['itemLines'][number]) => {
      return {
        ...itemLine.analyticalFieldAssociations.reduce<GroupedResults>(
          (result: GroupedResults, analyticalFieldAssociation) => {
            const groupingKey = getGroupingKey(analyticalFieldAssociation);
            const existingResultForFieldEntity = result[groupingKey];
            const existingValueForFieldEntityValue =
              existingResultForFieldEntity?.values?.[
                analyticalFieldAssociation.fieldEntityValueId
              ];

            if (
              analyticalFieldAssociation.fieldKind === 'costCenter' &&
              costCenterById.size === 0
            ) {
              return {
                ...result,
                [groupingKey]: {
                  ...existingResultForFieldEntity,
                  values: {},
                },
              };
            }

            return {
              ...result,
              [groupingKey]: {
                ...existingResultForFieldEntity,
                values: {
                  ...existingResultForFieldEntity?.values,
                  [analyticalFieldAssociation.fieldEntityValueId]:
                    existingValueForFieldEntityValue
                      ? {
                          ...existingValueForFieldEntityValue,
                          amount: add(
                            existingValueForFieldEntityValue.amount,
                            itemLine.netAmount,
                          ),
                        }
                      : {
                          ...getItemValueInfo(analyticalFieldAssociation, {
                            ...params,
                            customFieldById,
                            costCenterById,
                          }),
                          amount: itemLine.netAmount,
                        },
                },
              },
            };
          },
          accumulator,
        ),
        ...addExpenseAccount({
          itemLine,
          accumulator,
        }),
        ...addVATAccount({
          itemLine,
          accumulator,
        }),
      };
    },
    initialData,
  );
};

const getInitialObjectWithAllFieldsForDisplay = ({
  t,
  hasCostCentersFeature,
  showVATAccount,
  customFieldIdForExpenseCategory,
  customFields,
}: Params) => {
  return {
    ...customFields.reduce((r: GroupedResults, customField) => {
      return {
        ...r,
        [customField.id]: {
          deleted: !!customField.deleted_at,
          label:
            customField.id === customFieldIdForExpenseCategory
              ? t('payables.panel.expenseCategory')
              : customField.name,
          values: {},
        },
      };
    }, {}),
    expenseAccount: {
      label: t('payables.panel.expenseAccount'),
      deleted: false,
      values: {},
    },
    taxAccount: {
      label: t('payables.panel.vatAccount'),
      values: {},
      deleted: !showVATAccount,
    },
    costCenter: {
      label: t('payables.panel.costCenter'),
      values: {},
      deleted: !hasCostCentersFeature,
    },
  };
};

const orderPanelKeys = (
  currentKey: string,
  nextKey: string,
  itemOrderByLabel: string[],
) => {
  return (
    itemOrderByLabel.indexOf(currentKey) - itemOrderByLabel.indexOf(nextKey)
  );
};

const convertGroupedResultsToPanelItems = (
  groupedData: GroupedResults,
  t: TGlobalFunctionTyped,
): React.ComponentProps<typeof PanelItemsSection>['items'] => {
  return compact(
    Object.keys(groupedData).map((key) => {
      const { label, values, deleted } = groupedData[key];

      const valueKeys = Object.keys(values);
      const hasNoValue = valueKeys.length === 0;

      // `type` is used by <PayableAccountingEditSection/>
      const getType = (itemLabel: string) => {
        if (itemLabel === t('payables.panel.costCenter')) {
          return 'costCenter';
        }
        if (itemLabel === t('payables.panel.expenseCategory')) {
          return 'expenseCategory';
        }
      };

      if (deleted && hasNoValue) {
        return;
      }

      const hasMoreThanOneValue = valueKeys.length > 1;
      if (hasMoreThanOneValue) {
        return {
          label: label,
          deleted,
          values: valueKeys.map((valueKey) => ({
            label: values[valueKey].label,
            deleted: !!values[valueKey].deleted,
            value: formatMonetaryValue(values[valueKey].amount),
          })),
          type: getType(label),
        };
      }

      return {
        label: groupedData[key].label,
        value: values[valueKeys[0]]?.label || '-',
        deleted: !!deleted,
        valueDeleted: !!values[valueKeys[0]]?.deleted,
        type: getType(groupedData[key].label),
      };
    }),
  );
};

const buildSplittableFieldPanelItems = (
  params: Params & {
    payable: Payable;
  },
): {
  label: string;
  value?: string | React.ReactElement;
  type?: string;
  expenseCategoryValueId?: string;
  deleted?: boolean;
}[] => {
  const { t } = params;
  const initialData = getInitialObjectWithAllFieldsForDisplay(params);
  const itemOrderByLabel = [
    t('payables.panel.expenseAccount'),
    t('payables.panel.vatAccount'),
    t('payables.panel.costCenter'),
    t('payables.panel.expenseCategory'),
    ...params.customFields
      .filter(({ id }) => id !== params.customFieldIdForExpenseCategory)
      .map(({ name }) => name)
      .sort(),
  ];

  const groupedData = groupSplittableFields(params, initialData);

  return convertGroupedResultsToPanelItems(groupedData, t).sort((a, b) =>
    orderPanelKeys(a.label, b.label, itemOrderByLabel),
  );
};

export default buildSplittableFieldPanelItems;
