import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Company } from 'modules/app/hooks/useCompany';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMoney } from 'src/core/utils/money';

import { getHasWalletWarning } from '../../../utils';
import { type AccountLoad, type WalletInfos } from '../../wallet';
import {
  HomepageWalletDetailNoFunds,
  HomepageWalletDetailPrimaryAmount,
  HomepageWalletDetailSecondaryAmount,
} from '../HomepageWalletDetail';

import './LegacyHomepageWallet.css';

type Props = {
  accountLoads: AccountLoad[];
  company: Company;
  walletInfos: WalletInfos;
};

export const LegacyHomepageWallet = ({
  accountLoads,
  company,
  walletInfos,
}: Props) => {
  const { t } = useTranslation('global');
  const {
    balance_available_all_accounts,
    balance_loaded_cards,
    balance_pending_cards,
    currency,
    id: companyId,
  } = company;
  const hasWarning = getHasWalletWarning(company, walletInfos);
  const isAccountLoaded = accountLoads.length > 0;
  const balancePendingOnCards = formatMoney(
    balance_pending_cards ?? 0,
    currency,
  );
  const walletBalance = formatMoney(balance_available_all_accounts, currency);
  const balanceLoadedOnCard = formatMoney(balance_loaded_cards ?? 0, currency);
  return (
    <div className="LegacyHomepageWallet shadow-10">
      {!isAccountLoaded ? (
        <HomepageWalletDetailNoFunds />
      ) : (
        <HomepageWalletDetailPrimaryAmount
          ctaText={t('homepage.wallet.link')}
          ctaLink={routeFor(routes.COMPANY_BANK.path, { company: companyId })}
          formatedAmount={walletBalance}
          formatedAmountDescription={t('homepage.wallet.balance')}
          hasWarning={hasWarning}
          hideTooltip
        />
      )}
      <div className="LegacyHomepageWallet__amount-secondary">
        <HomepageWalletDetailSecondaryAmount
          formatedAmount={balanceLoadedOnCard}
          formatedAmountDescription={t('homepage.wallet.loaded')}
          isAccountLoaded={isAccountLoaded}
          iconName="recurring-card"
        />
        <HomepageWalletDetailSecondaryAmount
          formatedAmount={balancePendingOnCards}
          formatedAmountDescription={t('homepage.wallet.pending')}
          isAccountLoaded={isAccountLoaded}
          iconName="plastic-card"
        />
      </div>
    </div>
  );
};
