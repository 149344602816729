import { Icon, colors } from '@dev-spendesk/grapes';
import { type MonetaryValue, greaterThan, subtract } from 'ezmoney';
import React from 'react';

import { QueryError } from "src/core/common/components/QueryError";
import { QuerySuspense } from "src/core/common/components/QuerySuspense";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { usePurchaseOrderQuery } from './hooks/usePurchaseOrderQuery';

type Props = {
  amount: MonetaryValue;
  requestType: 'po' | 'invoice';
  purchaseOrderId?: string;
};

export const BudgetOverviewReviewDisclaimerContainer = ({
  amount,
  requestType,
  purchaseOrderId,
}: Props) => {
  const { t } = useTranslation('global');
  const purchaseOrderQueryState = usePurchaseOrderQuery(purchaseOrderId);

  if (requestType === 'po') {
    return (
      <div className="mt-s flex gap-xs">
        <Icon
          className="mt-xxs shrink-0"
          name="info"
          size="s"
          color={colors.primary}
        />
        <div className="text-complementary body-s">
          {t('budget.reviewPanel.netAmountDisclaimer', {
            amount: formatMonetaryValue(amount),
          })}
        </div>
      </div>
    );
  }

  if (requestType === 'invoice') {
    return (
      <QuerySuspense
        queryState={purchaseOrderQueryState}
        fallback={(error) => (
          <QueryError queryError={error} componentType="Callout" />
        )}
        loading={<div />}
      >
        {(purchaseOrder) => {
          const isPoOverbilled =
            purchaseOrder &&
            greaterThan(purchaseOrder.billedAmount, purchaseOrder.amount);
          let disclaimer = '';
          if (purchaseOrder && isPoOverbilled) {
            disclaimer = t('requests.panel.netDisclaimerInvoiceBudgetWidget', {
              amount: formatMonetaryValue(
                subtract(purchaseOrder.billedAmount, purchaseOrder.amount),
              ),
            });
          } else {
            disclaimer = t('requests.panel.netDisclaimerBudgetWidget');
          }

          return (
            <div className="mt-s flex gap-xs">
              <Icon
                className="mt-xxs shrink-0"
                name={isPoOverbilled ? 'warning' : 'info'}
                size="s"
                color={isPoOverbilled ? colors.warning : colors.primary}
              />
              <div className="text-complementary body-s">{disclaimer}</div>
            </div>
          );
        }}
      </QuerySuspense>
    );
  }

  return null;
};
