import { Callout, Icon, Label } from '@dev-spendesk/grapes';
import { type FormikErrors } from 'formik';
import React from 'react';

import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import { SectionList } from 'src/core/common/components/legacy/SectionList';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ApprovalPolicyFormValues } from '../../formValues';
import { getMemberLabel, type Member } from '../../member';

type Props = {
  members: Member[];
  values: ApprovalPolicyFormValues;
  errors: FormikErrors<ApprovalPolicyFormValues>;
  isPolicyDefault?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue(field: string, value: any): void;
};

export const ApprovalPolicyMembersContent = ({
  members,
  values,
  errors,
  isPolicyDefault,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');
  const membersById = new Map(members.map((member) => [member.id, member]));
  const selectedMembers = values.membersIds
    .map((memberId) => membersById.get(memberId))
    .filter(
      (member: Member | undefined): member is Member => member !== undefined,
    );

  return (
    <div>
      <p>{t('approvalPolicy.members.title')}</p>
      <div className="mt-m text-left">
        <Label
          className="mb-xs block"
          label={t('approvalPolicy.members.label')}
        />

        <AutocompleteMultipleSearch
          fit="parent"
          placeholder={t('approvalPolicy.members.autocompleteLabel')}
          values={selectedMembers.map((member) => ({
            key: member.id,
            label: getMemberLabel(member),
          }))}
          options={members
            .filter((member) => !member.isAccountOwner)
            .map((member) => ({
              key: member.id,
              label: getMemberLabel(member),
            }))}
          translations={{
            selectAll: t('approvalPolicy.members.selectAll'),
            selected: t('approvalPolicy.members.selected', {
              count: selectedMembers.length,
            }),
          }}
          onSelect={(selectedOptions) => {
            setFieldValue(
              'membersIds',
              selectedOptions.map((option) => option.key),
            );
          }}
        />
      </div>
      {selectedMembers.length > 0 && (
        <SectionList
          title={t('approvalPolicy.members.assigned', {
            count: selectedMembers.length,
          })}
          items={selectedMembers.map((member) => ({
            id: member.id,
            value: getMemberLabel(member),
            userAvatar: {
              avatar: member.avatar,
              gender: member.gender,
            },
            action: <Icon name="trash" />,
            onActionClicked: isPolicyDefault
              ? undefined
              : (item) => {
                  setFieldValue(
                    'membersIds',
                    values.membersIds.filter(
                      (memberId) => memberId !== item.id,
                    ),
                  );
                },
          }))}
          emptyMessage={t('approvalPolicy.members.noMembers')}
          className="mt-m text-left"
        />
      )}
      {errors.membersIds && (
        <Callout
          variant="alert"
          title={errors.membersIds as string}
          className="mt-xs"
        />
      )}
    </div>
  );
};
