import { type FormikErrors } from 'formik';

import { useFormikWithValidationStrategy } from 'src/core/common/hooks/useFormikWithValidationStrategy';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { type AmortisationCapability } from 'src/core/modules/bookkeep/integration/status';
import {
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'src/core/modules/payable';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import {
  type ExpenseCategoryValue,
  getExpenseCategoryValueFromCustomField,
} from '../../../models/expenseCategory';
import { type Payable } from '../../PayablePanelContainer';
import { useUpdatePayableQueryCache } from '../../PayablePanelContainer/hooks';

type CostCenter = {
  id: string;
  name: string;
};

export type AccountingFormValues = {
  costCenter: CostCenter | undefined;
  expenseCategoryValue: ExpenseCategoryValue | undefined;
  amortisation:
    | {
        date?: {
          from: Date;
          to: Date;
        };
        schemeId?: string;
        schemeName?: string;
      }
    | undefined
    | null;
};

export const usePayableAccountingEditForm = ({
  payable,
  customFieldIdForExpenseCategory,
  amortisationCapability,
  hasCostCentersFeature,
}: {
  payable: Payable;
  customFieldIdForExpenseCategory?: string;
  amortisationCapability: AmortisationCapability | undefined;
  hasCostCentersFeature: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('global');
  const [updatePayable] = useUpdatePayable(payable.id);
  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();
  const updatePayableQueryCache = useUpdatePayableQueryCache();
  const { dangerNotif } = useNotifications();

  const handleOnSectionCancelEdit = () => formik.resetForm();
  const handleOnSectionSaveEdit = async () => {
    // this will trigger 2 form validations
    // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
    // https://github.com/formium/formik/issues/1580
    const { submitForm, validateForm } = formik;
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      throw new Error('Validation error');
    }
    return submitForm();
  };

  const formik = useFormikWithValidationStrategy<AccountingFormValues>({
    initialValues: {
      costCenter: payable.costCenter,
      expenseCategoryValue: customFieldIdForExpenseCategory
        ? getExpenseCategoryValueFromCustomField(
            payable.customFields,
            customFieldIdForExpenseCategory,
          )
        : undefined,
      amortisation: payable.amortisation,
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors: FormikErrors<AccountingFormValues> = {};

      if (amortisationCapability && values.amortisation) {
        if (!values.amortisation?.date) {
          errors.amortisation = t('payables.panel.missingAmortisationDate');
        } else if (!values.amortisation?.date?.from) {
          errors.amortisation = t(
            'payables.panel.missingAmortisationStartDate',
          );
        } else if (!values.amortisation?.date?.to) {
          errors.amortisation = t('payables.panel.missingAmortisationEndDate');
        }
      }

      if (
        hasCostCentersFeature &&
        payable.itemLines.length === 1 && // Edition disabled for now for payables with split values
        !values.costCenter
      ) {
        errors.costCenter = t(
          'payables.panel.detailsSection.validation.costCenter',
        );
      }
      return errors;
    },

    onSubmit: async (values) => {
      try {
        // make sure the value is set (even no change is made)
        if (
          hasCostCentersFeature &&
          payable.itemLines.length === 1 && // Edition disabled for now for payables with split values
          !values.costCenter
        ) {
          throw new Error('Missing cost center');
        }

        const customFieldAssociations = payable.customFields.map(
          (customField) => ({
            customFieldId: customField.id,
            customFieldListValueId:
              customField.type === 'list'
                ? customField.values[0]?.id
                : customField.value.toString(),
          }),
        );

        track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_EDITED, {
          payableId: payable.id,
          whatChanged: 'costCenter',
        });

        const { newPayableVersion } = await updatePayable({
          payableVersion: payable.version,
          update: {
            costCenterId: values.costCenter?.id,
            amortisationDate:
              values.amortisation === null ? null : values.amortisation?.date,
            amortisationSchemeId:
              values.amortisation === null
                ? null
                : values.amortisation?.schemeId,
            ...(values.expenseCategoryValue
              ? {
                  customFieldAssociations: [
                    {
                      customFieldId: customFieldIdForExpenseCategory ?? '',
                      customFieldListValueId: values.expenseCategoryValue?.id,
                    },
                    ...customFieldAssociations.filter(
                      (customFieldAssociation) =>
                        customFieldIdForExpenseCategory !== undefined &&
                        customFieldAssociation.customFieldId !==
                          customFieldIdForExpenseCategory,
                    ),
                  ],
                }
              : {
                  customFieldAssociations,
                }),
          },
        });

        updatePayableQueryCache(payable.id, {
          version: newPayableVersion,
          costCenter: values.costCenter,
          amortisation: values.amortisation?.date
            ? {
                date: {
                  from: values.amortisation.date.from.toString(),
                  to: values.amortisation.date.to.toString(),
                },
                schemeId: values.amortisation.schemeId,
              }
            : undefined,
        });
      } catch (error) {
        const errorMessage = getUpdatePayableErrorMessage(error);
        dangerNotif(errorMessage);
        throw error;
      }
    },
  });

  return {
    formik,
    handleOnSectionCancelEdit,
    handleOnSectionSaveEdit,
  };
};
