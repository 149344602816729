import { useInvalidateCostCentersQuery } from 'modules/budgets/apis';
import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

type EditionPayload = {
  name: string;
  ownerId: string;
  approvalRules: ApprovalRuleApi[];
};

export const useEditCostCenterQuery = (
  id: string,
): MutationState<EditionPayload> => {
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  return useMutation<EditionPayload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/cost-centers/${id}`,
    },
    options: {
      throwOnError: true,
      onSuccess(): void {
        invalidateCostCentersQuery();
      },
    },
  });
};
