import { useQueryStates } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

// TODO 2024-09-17: reenable
// import { useOrganisationEntityLateReceiptsQuery } from './useOrganisationEntityLateReceiptsQuery';
import { useOrganisationEntityPayablesInfoQuery } from './useOrganisationEntityPayablesInfoQuery';
import { useOrganisationEntityRequestStatsQuery } from './useOrganisationEntityRequestStatsQuery';
import { useOrganisationEntityTransferSchedulingQuery } from './useOrganisationEntityTransferSchedulingQuery';
import { useOrganisationEntityWalletSummaryQuery } from './useOrganisationEntityWalletSummaryQuery';
import { type OrganisationFeatures } from './useOrganisationFeatures';
import {
  type OrganisationReportingEntityDetails,
  type OrganisationReportingEntity,
} from '../types';

export const useOrganisationReportingEntityDetails = (
  entityBasicData: OrganisationReportingEntity,
  features: OrganisationFeatures,
): QueryState<OrganisationReportingEntityDetails> => {
  const { id: companyId } = entityBasicData;

  return useQueryStates({
    states: {
      walletSummary: useOrganisationEntityWalletSummaryQuery(companyId, {
        isEnabled: features.wallet,
      }),
      requestStats: useOrganisationEntityRequestStatsQuery(companyId, {
        isEnabled: features.requestsToApprove,
      }),
      transferScheduling: useOrganisationEntityTransferSchedulingQuery(
        companyId,
        {
          isEnabled: features.invoicesToPay,
        },
      ),
      // TODO 2024-09-17: reenable
      // receipts: useOrganisationEntityLateReceiptsQuery(companyId, {
      //   isEnabled: features.lateReceipts,
      // }),
      payablesInfo: useOrganisationEntityPayablesInfoQuery(companyId, {
        isEnabled: features.payablesToReview,
      }),
    },
    reshapeData: ({
      walletSummary,
      requestStats,
      transferScheduling,
      // TODO 2024-09-17: reenable
      // receipts,
      payablesInfo,
    }) =>
      ({
        ...entityBasicData,
        ...walletSummary,
        requestsToApprove: requestStats.requestsToApprove,
        invoicesToPay: transferScheduling.invoicesToPay,
        // TODO 2024-09-17: reenable
        // lateReceipts: receipts.lateReceipts,
        payablesToReview: payablesInfo.payablesToReview,
      }) satisfies OrganisationReportingEntityDetails,
  });
};
