import { Accordion, Icon, NavigationItem, Popover } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useState } from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { ReadyToSpendModalSlideshow } from './components/ReadyToSpendModalSlideshow';
import { ReadyToSpendTaskItem } from './components/ReadyToSpendTaskItem';
import { useGetReadyToSpendTasksQuery } from './hooks/useGetReadyToSpendTasksQuery';
import { type ReadyToSpendTaskType } from './types';

export const ReadyToSpendSetupGuide = () => {
  const { t } = useTranslation('global');

  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const [modalSlideshowType, setModalSlideshowType] =
    useState<ReadyToSpendTaskType | null>(null);

  const tasksQueryState = useGetReadyToSpendTasksQuery();

  if (tasksQueryState.status !== 'success') {
    return null;
  }

  const tasks = tasksQueryState.data;
  if (tasks.length === 0) {
    return null;
  }

  const completedTasks = tasks.filter((task) => task.status === 'completed');
  if (completedTasks.length === tasks.length) {
    return null;
  }

  const progress = Math.floor((completedTasks.length / tasks.length) * 100);

  return (
    <>
      <Popover
        className={cx({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'popover-hack-align w-full': hasNewNavigation,
        })}
        placement={hasNewNavigation ? 'end-top' : 'bottom-start'}
        renderTrigger={(triggerProps) =>
          hasNewNavigation ? (
            <button
              {...triggerProps}
              className="navigation-item h-xl"
              type="button"
            >
              <Icon
                name="expense-claim"
                aria-hidden="true"
                className="shrink-0"
              />
              <span className="navigation-toggle-display grow">
                {t('readyToSpend.triggerText', { progress })}
              </span>
            </button>
          ) : (
            <NavigationItem
              {...triggerProps}
              text={t('readyToSpend.triggerText', { progress })}
              variant="mainNavigation"
              leftAddon={<Icon name="expense-claim" />}
              rightAddon={<Icon name="caret-down" />}
            />
          )
        }
      >
        {() => (
          <div className="max-h-[80vh] w-[500px] overflow-y-auto p-m">
            <div className="mb-s">
              <p className="mb-xs text-complementary title-xl">
                {t('readyToSpend.title')}
              </p>
              <p className="text-neutral-dark body-m">
                {t('readyToSpend.subtitle')}
              </p>
            </div>
            <div className="mb-m flex items-center gap-xs">
              <label
                className="text-neutral-darker title-m"
                htmlFor="ready-to-spend-progress"
              >
                {`${progress}%`}
              </label>
              <progress
                className="h-[10px] flex-1 accent-primary"
                id="ready-to-spend-progress"
                max="100"
                value={progress}
              />
            </div>
            <Accordion>
              {tasks.map((task) => (
                <ReadyToSpendTaskItem
                  key={task.id}
                  setModalType={setModalSlideshowType}
                  task={task}
                />
              ))}
            </Accordion>
          </div>
        )}
      </Popover>
      <ReadyToSpendModalSlideshow
        setModalType={setModalSlideshowType}
        type={modalSlideshowType}
      />
    </>
  );
};
