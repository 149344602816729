import { cardsRouterContract } from './sfs-migration/cards';
import { dotfileRouterContract } from './sfs-migration/dotfile/router';
import { migrationRouterContract } from './sfs-migration/migration';
import { subscriptionsRouterContract } from './sfs-migration/subscriptions/router';
import { tasksRouterContract } from './sfs-migration/tasks/router';
import { wtRequestsRouterContract } from './sfs-migration/wire-transfers';

export const sfsMigrationContract = {
  cards: cardsRouterContract,
  dotfile: dotfileRouterContract,
  migration: migrationRouterContract,
  subscriptions: subscriptionsRouterContract,
  tasks: tasksRouterContract,
  wireTransfers: wtRequestsRouterContract,
};

export {
  allowedShippingCountriesForSfsEeaCardOrders,
  allowedShippingCountriesForSfsUkCardOrders,
  allowedShippingCountriesForAllSfsCardOrders,
} from './common/countries';
