import * as companySelectors from 'modules/company/redux/selectors';
import FEATURES from 'src/core/constants/features';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';

import { type PerDiemState } from './reducer';
import { type CustomField } from '../customField';
import { isTripSpanningMultipleDates, toTripDays } from '../perDiem';
import { type Location, type SuggestedLocation } from '../perDiemLocation';

export type AppState = {
  perDiem: PerDiemState;
  global: object;
  requests: {
    expenseCategoryCustomFieldId: string | undefined;
    expenseCategory: {
      eligible_types: { type: string }[];
      is_required: boolean;
    };
    customFields: CustomField[];
  };
};

export const getState = (state: AppState): PerDiemState => state.perDiem;

export const getUi = (state: AppState) => state.perDiem.ui;

export const getAllowanceLocations = (state: AppState) =>
  state.perDiem.allowanceLocations;

export const getIsFetching = (state: AppState) => state.perDiem.isFetching;

export const getError = (state: AppState) => state.perDiem.error;

export const getDescription = (state: AppState) => state.perDiem.ui.description;

export const getMealsIncluded = (state: AppState) =>
  state.perDiem.ui.mealsIncluded;

export const getStays = (state: AppState) => state.perDiem.ui.stays;

export const getSelectedTeamId = (state: AppState) =>
  state.perDiem.ui.selectedTeamId;

export const getSelectedCostCenterId = (state: AppState) =>
  state.perDiem.ui.selectedCostCenterId;

export const getSelectedExpenseCategoryId = (state: AppState) =>
  state.perDiem.ui.selectedExpenseCategoryId;

export const getFormErrors = (state: AppState) => state.perDiem.ui.errors;

export const getSelectedCustomFields = (state: AppState) =>
  state.perDiem.customFields;

export const getSuggestedLocations = (
  state: AppState,
  dayStayId: string,
): SuggestedLocation[] => {
  const locations = getState(state).suggestedLocationsByDayStayId[dayStayId];
  if (!locations) {
    return [];
  }
  return locations;
};

export const getShowMeals = (state: AppState) => state.perDiem.ui.showMeals;

export const getHasHotelAllowance = (state: AppState) =>
  state.perDiem.ui.hasHotelAllowance;

export const getTripDays = (state: AppState) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const country = companySelectors.getCountry(state as any);
  return toTripDays(
    state.perDiem.ui.mealsIncluded,
    state.perDiem.ui.stays,
    state.perDiem.ui.hasHotelAllowance,
    country,
  );
};

export const getLocationByDayStayId = (
  state: AppState,
  dayStayId: string,
): Location | null => {
  const dayStay = getStays(state).find((stay) => stay.id === dayStayId);
  if (!dayStay) {
    return null;
  }
  return dayStay.location;
};

export const getFetchingLegalLocationIds = (state: AppState) =>
  state.perDiem.ui.fetchingLegalLocationIds;

export const getIsFetchingLegalLocation = (
  state: AppState,
  dayStayId: string,
): boolean => {
  return getFetchingLegalLocationIds(state).includes(dayStayId);
};

export const getIsTripSpanningMultipleDates = (state: AppState) =>
  isTripSpanningMultipleDates(state.perDiem.ui.stays);

export const getIsCreating = (state: AppState) => state.perDiem.isCreating;

export const getExpenseCategoryCustomFieldId = (state: AppState) => {
  return state.requests.expenseCategoryCustomFieldId;
};

export const getCustomFields = (state: AppState) => {
  const expenseCategoryCustomFieldId = getExpenseCategoryCustomFieldId(state);
  const isExpenseCategoryFeatureEnabled = getIsFeatureEnabled(
    state,
    FEATURES.EXPENSE_CATEGORIES,
  );

  let { customFields } = state.requests;

  if (state.requests.customFields && isExpenseCategoryFeatureEnabled) {
    customFields = state.requests.customFields.filter(
      (customField) => customField.id !== expenseCategoryCustomFieldId,
    );
  }

  return customFields ?? [];
};
