import { Icon, type TriggerProps } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';

import { type CompanyWalletSummary } from '../../../companyWalletSummary';
import { hasCompanyNegativeAvailableAmount } from '../hasCompanyNegativeAvailableAmount';

import './CompanyDropdownToggleButton.scss';

type Props = {
  company: CompanyWalletSummary;
  isDropdownOpen: boolean;
  getToggleButtonProps: TriggerProps;
};

export const CompanyDropdownToggleButton = ({
  company,
  isDropdownOpen,
  getToggleButtonProps,
}: Props) => {
  const hasNegativeAvailableAmount = hasCompanyNegativeAvailableAmount(company);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isDropdown,
    ...buttonProps
  } = getToggleButtonProps;

  return (
    <button
      {...buttonProps}
      type="button"
      className={cx('CompanyDropdownToggleButton title-m', {
        'CompanyDropdownToggleButton--opened': isDropdownOpen,
        'CompanyDropdownToggleButton--missing-funds':
          hasNegativeAvailableAmount,
      })}
      // FIXME: this testid is shared with the CompanySwitcherButton because
      // we either display that one or this one depending on the loads to limit
      // feature, which is difficult to deal with in E2E tests.
      data-testid="CompanySwitcherButton"
    >
      {hasNegativeAvailableAmount && (
        <Icon
          className="CompanyDropdownToggleButton__warning-icon"
          name="warning"
          aria-hidden="true"
        />
      )}
      <span className="CompanyDropdownToggleButton__company-name">
        {company.name}
      </span>
      <Icon
        className="CompanyDropdownToggleButton__caret-icon"
        name="caret-down"
        aria-hidden="true"
      />
    </button>
  );
};
