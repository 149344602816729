import { type ParsedQuery } from 'query-string';

type Filters = {
  period?: { value?: string };
  amount?: { value?: string };
  type?: { value?: { key: string; name: string }[] };
  status?: { value?: { key: string; name: string }[] };
  team?: { value?: { key: string; name: string } };
  requester?: { value?: { key: string; name: string } };
  supplier?: { value?: { key: string; name: string } };
  costCenter?: { value?: { key: string; name: string }[] };
} & {
  // this is for custom fields
  [key: string]: {
    value?:
      | { key: string; name: string }[]
      | { key: string; name: string }
      | string;
  };
};

const getOneOrManyKeys = (
  arrayOrObject: { key: string }[] | { key: string } | undefined,
) => {
  if (!arrayOrObject) {
    return null;
  }
  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.map((item) => item.key);
  }
  return arrayOrObject.key;
};

const isValueNotEmpty = (
  value:
    | string
    | string[]
    | {
        [key: string]: string | string[];
      }
    | null
    | undefined,
) => {
  if (!value) {
    return false;
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length > 0;
  }

  return true;
};

/**
 * Formats raw filters from the app's state to API-understandable filters
 *
 * @param  {Object} filters={}        The raw filters to clean / format
 * @param  {Array}  customFields=[]   The company's custom fields
 * @return {Object}                   API-ready request search params
 */
export const convertFiltersFromState = (
  filters: Filters = {},
  customFields: { id: string }[] = [],
) => {
  const type = filters?.type?.value;
  const status = filters?.status?.value;
  const team = filters?.team?.value;
  const requester = filters?.requester?.value;
  const supplier = filters?.supplier?.value;
  const period = filters?.period?.value;
  const amount = filters?.amount?.value;
  const costCenter = filters?.costCenter?.value;

  const sanitizedFilters = {
    type: getOneOrManyKeys(type),
    status: getOneOrManyKeys(status),
    team: getOneOrManyKeys(team),
    requester: getOneOrManyKeys(requester),
    supplier: getOneOrManyKeys(supplier),
    costCenter: getOneOrManyKeys(costCenter),
    custom_fields: {} as { [key: string]: string | string[] },
    amount,
    period,
  };
  // Eventually append custom fields filters
  if (customFields) {
    customFields.forEach((cf) => {
      const value = filters?.[cf.id]?.value;
      // this is sadly needed because I'm not good enough in TS
      // to create the Filters type properly
      if (value && typeof value !== 'string') {
        sanitizedFilters.custom_fields[cf.id] = Array.isArray(value)
          ? value.map((item) => item.key)
          : value.key;
      }
    });
  }

  return Object.fromEntries(
    Object.entries(sanitizedFilters).filter(([_, value]) =>
      isValueNotEmpty(value),
    ),
  );
};

/**
 * Formats raw filters from the URL to API-understandable filters
 *
 * @param  {Object} filters={}  The raw filters to clean / format
 * @return {Object}             API-ready request search params
 */
export const convertFiltersFromUrl = (filters: ParsedQuery<string> = {}) => {
  const formattedfilters = Object.fromEntries(
    Object.entries({ ...filters, group: filters.team || null }).filter(
      ([key]) => !['team'].includes(key),
    ),
  );
  return Object.fromEntries(
    Object.entries(formattedfilters).filter(([_, value]) =>
      isValueNotEmpty(value),
    ),
  );
};
