import { unwrapQuery } from 'src/core/api/unwrapQuery';
import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { AutomaticFundingWarning } from 'src/sfs-migration/routes/[companyId]/overview/migrationExecution/AutomaticFundingWarning';

import { useSFSMigration } from '../hooks/useSFSMigration';
import { useSfsLoadsQuery } from '../hooks/useSfsLoadsQuery';
import { useTransfersFromLegacyWalletQuery } from '../hooks/useTransfersFromLegacyWalletQuery';

export const AutomaticFundingWarningContainer = withErrorBoundary({
  team: 'sfs-migration',
  scope: 'sfs-migration::banner',
  // eslint-disable-next-line sonarjs/cognitive-complexity
})(() => {
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const user = useUser();

  const isAccountOwner = user.is_account_owner;
  const isEnabled = hasTMPSFSMigrationPage && isAccountOwner;

  const transfersFromLegacyWallet = unwrapQuery(
    useTransfersFromLegacyWalletQuery({ isEnabled }),
  )?.data.transfers;

  const sfsLoads = unwrapQuery(useSfsLoadsQuery({ isEnabled }));
  const sfsMigration = unwrapQuery(useSFSMigration({ isEnabled }));

  return (
    <AutomaticFundingWarning
      bankableToSfsTransfers={transfersFromLegacyWallet}
      sfsLoads={sfsLoads}
      sfsMigration={sfsMigration}
      context="app"
      isAccountOwner={isAccountOwner}
    />
  );
});
