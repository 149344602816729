import { createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import map from 'lodash/map';
import noop from 'lodash/noop';
import set from 'lodash/set';

import { fetchUser } from 'src/core/actions/users';
import { companyAPI } from 'src/core/api/axios';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';

import * as types from './actionTypes';

export const updateUserPolicyRequest = createAction(
  types.UPDATE_USER_POLICY_REQUEST,
);
export const updateUserPolicyFailure = createAction(
  types.UPDATE_USER_POLICY_FAILURE,
);
export const updateUserPolicySuccess = createAction(
  types.UPDATE_USER_POLICY_SUCCESS,
);
export const updateUserPolicy =
  (policy, user_id, callback = noop) =>
  async (dispatch, getState) => {
    const { id: companyId } = getState().global.company;
    const payload = {
      user_id,
    };
    const isCustom = get(policy, 'is_user_custom');
    let callAPI;

    if (!policy) {
      return;
    }

    dispatch(updateUserPolicyRequest());

    if (!isCustom) {
      callAPI = () =>
        companyAPI.put(`/userpolicies/${get(policy, 'id')}`, payload, {
          companyId,
        });
    } else {
      set(payload, 'code', get(policy, 'code'));
      set(payload, 'params', get(policy, 'params'));
      callAPI = () => companyAPI.post('/userpolicies', payload, { companyId });
    }

    let updatedPolicy;
    try {
      const res = await callAPI();
      updatedPolicy = res.data;
    } catch (error) {
      dispatch(updateUserPolicyFailure(error));
      throw error;
    }

    dispatch(updateUserPolicySuccess(updatedPolicy));
    await dispatch(fetchUser(user_id)); // update the local user
    return callback(updatedPolicy);
  };

export const updateUsersPolicy = (policy, usersIds) => (dispatch) => {
  return Promise.all(
    map(usersIds, (id) => dispatch(updateUserPolicy(policy, id))),
  );
};

export const fetchApprovalSchemesRequest = createAction(
  types.FETCH_APPROVAL_SCHEMES_REQUEST,
);
export const fetchApprovalSchemesFailure = createAction(
  types.FETCH_APPROVAL_SCHEMES_FAILURE,
);
export const fetchApprovalSchemesSuccess = createAction(
  types.FETCH_APPROVAL_SCHEMES_SUCCESS,
);
export const fetchApprovalSchemes = () => async (dispatch, getState) => {
  const { id: companyId } = getState().global.company;
  dispatch(fetchApprovalSchemesRequest());

  let schemes;
  try {
    const res = await companyAPI.get('/approval-schemes/', { companyId });
    schemes = res.data;
  } catch (error) {
    dispatch(fetchApprovalSchemesFailure(error));
    throw error;
  }

  dispatch(fetchApprovalSchemesSuccess(schemes));
};

export const fetchCostCentersSuccess = createAction(
  types.FETCH_COST_CENTERS_SUCCESS,
);
export const fetchCostCenters = () => async (dispatch, getState) => {
  const companyId = getCompanyId(getState());

  // TODO: handle error state in UI
  const { data } = await companyAPI.get('/cost-centers', {
    companyId,
  });
  dispatch(fetchCostCentersSuccess(data));
};
