import { useParams } from 'react-router-dom';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type JournalMappings, type Journal } from '../../accounting';

export const useJournalsQuery = (): QueryState<JournalMappings> => {
  const { company: companyId } = useParams();

  return useQuery<JournalMappings>({
    key: ['getJournals', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/journals',
    },
    reshapeData(data) {
      const { journals } = data;

      return {
        availableJournals: {
          forPurchase: journals.filter(
            (journal: Journal) =>
              journal.externalType !== 'financial_operation',
          ),
          forBank: journals.filter((journal: Journal) =>
            ['financial_operation', 'miscellaneous_operation'].includes(
              journal.externalType,
            ),
          ),
        },
        mappings: [
          {
            mappingType: 'invoice',
            mappedJournal: journals.find((journal: Journal) =>
              journal.mappingTypes?.includes('invoice'),
            ),
          },
          {
            mappingType: 'expense_claim',
            mappedJournal: journals.find((journal: Journal) =>
              journal.mappingTypes?.includes('expense_claim'),
            ),
          },
          {
            mappingType: 'card_payment',
            mappedJournal: journals.find((journal: Journal) =>
              journal.mappingTypes?.includes('card_payment'),
            ),
          },
          {
            mappingType: 'bank_journal',
            mappedJournal: journals.find((journal: Journal) =>
              journal.mappingTypes?.includes('bank_journal'),
            ),
          },
        ],
      };
    },
  });
};
