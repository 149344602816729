import { useQuery } from '../../../core/api/hooks/useQuery';
import type {
  GetSfsMigrationResponse,
  GetSfsMigrationResponseError,
} from '../../common/models/migration-status';

export const useSFSMigration = ({ isEnabled }: { isEnabled: boolean }) => {
  return useQuery<GetSfsMigrationResponse, GetSfsMigrationResponseError>({
    key: ['sfs-migration'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/sfs-migration/`,
    },
  });
};
