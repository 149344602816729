import { Callout } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { PurchaseOrderOverbillForecastWarning } from 'modules/purchase-orders/components';
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

type Props = {
  purchaseOrderId: string;
  newInvoiceAmount: MonetaryValue;
};

export const InvoiceRequestFormAmountWarning = ({
  purchaseOrderId,
  newInvoiceAmount,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <PurchaseOrderOverbillForecastWarning
      purchaseOrderId={purchaseOrderId}
      newInvoiceAmount={newInvoiceAmount}
    >
      {(purchaseOrderOverbillForecast) =>
        purchaseOrderOverbillForecast.status === 'overbilled' ? (
          <Callout
            title={t(
              'requests.panel.invoice.requesterOverbilledPurchaseOrder.title',
            )}
            variant="warning"
            className="mt-s"
          >
            {t(
              'requests.panel.invoice.requesterOverbilledPurchaseOrder.message',
              {
                monetaryAmount: formatMonetaryValue(
                  purchaseOrderOverbillForecast.newBilledAmount,
                ),
              },
            )}
          </Callout>
        ) : null
      }
    </PurchaseOrderOverbillForecastWarning>
  );
};
