import { useContext, useState } from 'react';
import { createPortal } from 'react-dom';

import { PreviewFileItem } from 'modules/requests/components/common/PreviewFileItem';

import {
  FileViewerModalWrapper,
  type MediaType,
} from './FileViewerModalWrapper';
import { ImageViewer, PDFViewer } from '../FileViewer';
import { PageViewerContext } from '../layout/SiderPageLayout/viewerContext';

type FileReceiptProps = {
  previewName: string;
  fileUrl: string;
  fileMediaType: MediaType;
  onDelete?: () => void;
};

export const FileReceipt = ({
  previewName,
  fileUrl,
  fileMediaType,
  onDelete,
}: FileReceiptProps) => {
  const [isFileViewerModalOpen, setIsFileViewerModalOpen] = useState(false);
  const [isPreviewHighlighted, setIsPreviewHighlighted] = useState(false);

  const pageViewerContext = useContext(PageViewerContext);
  const showFileViewer = () => {
    if (pageViewerContext) {
      const fileViewer =
        fileMediaType === 'application/pdf' ? (
          <PDFViewer
            url={fileUrl}
            onDownload={() => window.open(fileUrl, '_blank')}
            onClose={hideFileViewer}
            className="w-[900px] min-w-[500px] max-w-[80%]"
          />
        ) : (
          <ImageViewer
            url={fileUrl}
            withZoom
            withRotate
            onDownload={() => window.open(fileUrl, '_blank')}
            onClose={hideFileViewer}
            withoutCrossOrigin
          />
        );
      setIsPreviewHighlighted(true);
      pageViewerContext(fileViewer);
    } else {
      setIsFileViewerModalOpen(true);
    }
  };

  const hideFileViewer = () => {
    if (pageViewerContext) {
      setIsPreviewHighlighted(false);
      pageViewerContext(undefined);
    } else {
      setIsFileViewerModalOpen(false);
    }
  };

  const overlay = document.getElementById('request-floating');

  return (
    <div>
      <PreviewFileItem
        isHighlighted={isFileViewerModalOpen || isPreviewHighlighted}
        fileName={previewName}
        onPreview={showFileViewer}
        mimeType={fileMediaType}
        onDelete={onDelete}
      />
      {overlay &&
        isFileViewerModalOpen &&
        createPortal(
          <div className="absolute flex h-full w-[calc(100%_-_390px)] items-center justify-center p-s">
            {fileMediaType === 'application/pdf' ? (
              <PDFViewer
                url={fileUrl}
                onDownload={() => window.open(fileUrl, '_blank')}
                onClose={hideFileViewer}
                className="max-h-[90%] w-[900px] min-w-[500px] max-w-[90%]"
              />
            ) : (
              <ImageViewer
                url={fileUrl}
                withZoom
                withRotate
                onDownload={() => window.open(fileUrl, '_blank')}
                onClose={hideFileViewer}
                withoutCrossOrigin
                className="max-h-[90%] w-[900px] min-w-[500px] max-w-[90%]"
              />
            )}
          </div>,
          document.getElementById('request-floating') ?? document.body,
        )}
      {!overlay && isFileViewerModalOpen && (
        <FileViewerModalWrapper
          fileUrl={fileUrl}
          fileMediaType={fileMediaType}
          onModalClose={hideFileViewer}
        />
      )}
    </div>
  );
};
