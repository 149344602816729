import { type DateRange } from '@dev-spendesk/grapes';

import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { type AnalyticEventPayloadDefinitionType } from 'src/core/utils/analytics/eventPayload';

import { type ExportOptions, type PayableListType } from './types';

export const eventDescriptor = {
  [AnalyticEventName.BOOKKEEP_EXPORT_PERIOD_CHANGED]: {
    description: 'Export period changed',
    payload: { period: 'The new period of the export' },
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_REQUESTED]: {
    description: 'Export requested',
    payload: { exportOptions: 'The selected options of the export' },
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_REVIEW_PAGE_BUTTON_CLICKED]: {
    description: 'Export review page button clicked',
    payload: { payableListType: 'The type of list to review' },
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_NEW_RECEIPTS_BANNER_HIDE_CLICKED]: {
    description: 'Export new receipts banner, `Hide` button clicked',
    payload: {},
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_UNPREPARED_EXPENSES_WARNING_CLICKED]: {
    description: 'Export unprepared expenses warning banner clicked',
    payload: {},
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_EXPENSE_SENT_BACK_TO_PREPARE]: {
    description: 'Export expense sent back to prepare from review page',
    payload: { payableId: 'The id of the expense sent back to prepare' },
  },
  [AnalyticEventName.BOOKKEEP_PAYABLE_ALL_EXPORT_REQUESTED]: {
    description: 'Export requested from `Payable > All` page',
    payload: {},
  },
  [AnalyticEventName.BOOKKEEP_EXPORT_EXPENSE_MARKED_AS_MANUALLY_EXPORTED]: {
    description: 'Export marked as manually exported from review page',
    payload: { payableId: 'The id of the expense sent back to prepare' },
  },
};

export interface PayloadDefinition extends AnalyticEventPayloadDefinitionType {
  [AnalyticEventName.BOOKKEEP_EXPORT_PERIOD_CHANGED]: {
    period: DateRange;
  };
  [AnalyticEventName.BOOKKEEP_EXPORT_REQUESTED]: {
    exportOptions: ExportOptions;
  };
  [AnalyticEventName.BOOKKEEP_EXPORT_REVIEW_PAGE_BUTTON_CLICKED]: {
    payableListType: PayableListType;
  };
  [AnalyticEventName.BOOKKEEP_EXPORT_NEW_RECEIPTS_BANNER_HIDE_CLICKED]: object;
  [AnalyticEventName.BOOKKEEP_EXPORT_UNPREPARED_EXPENSES_WARNING_CLICKED]: object;
  [AnalyticEventName.BOOKKEEP_EXPORT_EXPENSE_SENT_BACK_TO_PREPARE]: {
    payableId: string;
  };
  [AnalyticEventName.BOOKKEEP_PAYABLE_ALL_EXPORT_REQUESTED]: object;
}

export function trackPeriodChange(period: DateRange): void {
  track(AnalyticEventName.BOOKKEEP_EXPORT_PERIOD_CHANGED, {
    period,
  });
}

export function trackExportRequested(exportOptions: ExportOptions): void {
  track(AnalyticEventName.BOOKKEEP_EXPORT_REQUESTED, {
    exportOptions,
  });
}

export function trackExportReview(payableListType: PayableListType): void {
  track(AnalyticEventName.BOOKKEEP_EXPORT_REVIEW_PAGE_BUTTON_CLICKED, {
    payableListType,
  });
}

export function trackUnpreparedPayablesWarningClicked(): void {
  track(AnalyticEventName.BOOKKEEP_EXPORT_UNPREPARED_EXPENSES_WARNING_CLICKED);
}

export function trackPayablesSentBackToPrepare(payableIds: string[]): void {
  payableIds.forEach((payableId) => {
    track(AnalyticEventName.BOOKKEEP_EXPORT_EXPENSE_SENT_BACK_TO_PREPARE, {
      payableId,
    });
  });
}

export function trackPayablesMarkAsManuallyExported(
  payableIds: string[],
): void {
  payableIds.forEach((payableId) => {
    track(
      AnalyticEventName.BOOKKEEP_EXPORT_EXPENSE_MARKED_AS_MANUALLY_EXPORTED,
      {
        payableId,
      },
    );
  });
}

export function trackPayableAllRequested(): void {
  track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_EXPORT_REQUESTED);
}
