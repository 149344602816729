import { Label, Avatar } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React from 'react';
import { v1 as uuid } from 'uuid';

import { SectionRowContent } from './SectionRowContent';

import './SectionList.scss';

type Item = {
  id?: string;
  value: string;
  secondaryValue?: string;
  secondaryValuePosition?: 'left' | 'right';
  isLight?: boolean;
  userAvatar?: {
    avatar: string | undefined;
    gender: string;
  };
  action?: React.ReactElement;
  onActionClicked?: (item: Item) => void;
  secondaryAction?: React.ReactElement;
  onSecondaryActionClicked?: (item: Item) => void;
};

type Props = {
  className?: string;
  title: string;
  infoText?: string;
  items: Item[];
  emptyMessage?: string;
  actionMessage?: string;
  onActionClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export const SectionList = ({
  className,
  title,
  items,
  actionMessage,
  onActionClicked,
  emptyMessage,
  infoText,
}: Props) => {
  const renderRow = (item: Item) => {
    return (
      <div
        key={item.id ?? uuid()}
        className={cx('SectionList__row', {
          'SectionList__row--with-hover': Boolean(item.onActionClicked),
        })}
      >
        {item.userAvatar && (
          <Avatar
            src={item.userAvatar.avatar}
            size="m"
            text={item.value}
            className="SectionList__row__avatar"
          />
        )}
        <SectionRowContent item={item} />
        {item.onSecondaryActionClicked && (
          <button
            type="button"
            className="SectionList__row-action body-s "
            onClick={() => {
              item.onSecondaryActionClicked?.(item);
            }}
          >
            {item.secondaryAction}
          </button>
        )}
        {item.onActionClicked && (
          <button
            type="button"
            className="SectionList__row-action body-s "
            onClick={() => {
              if (item.onActionClicked) {
                item.onActionClicked(item);
              }
            }}
          >
            {item.action}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={cx('SectionList', className)}>
      <div className="SectionList__title">
        <Label label={title} infoTipContent={infoText} />
        {onActionClicked && (
          <button
            type="button"
            className="SectionList__title-action title-m "
            onClick={onActionClicked}
          >
            {actionMessage}
          </button>
        )}
      </div>
      <div className="SectionList__list">
        {items.length === 0 ? (
          <span className="SectionList__list-empty">{emptyMessage}</span>
        ) : (
          items.map(renderRow)
        )}
      </div>
    </div>
  );
};
