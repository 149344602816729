import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { TypeColumnEditModal } from './TypeColumnEditModal';
import { type TypeFormValues } from './form';

type Props = {
  isOpen: boolean;
  title: string;
  general: string;
  analytical: string;
  description: string;
  isDeletable: boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
  onSaveColumn: (values: TypeFormValues) => void;
};

export const TypeColumnEditModalContainer = ({
  title,
  onSaveColumn,
  general,
  analytical,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<TypeFormValues>({
    initialValues: {
      title,
      general,
      analytical,
    },
    validate: (values) => {
      const errors: FormikErrors<TypeFormValues> = {};

      if (!values.title.trim()) {
        errors.title = t('exports.emptyColumnTitleError');
      }

      if (!values.general?.trim()) {
        errors.general = t('bookkeep.settings.export.typeOptions.emptyError');
      }

      if (!values.analytical?.trim()) {
        errors.analytical = t(
          'bookkeep.settings.export.typeOptions.emptyError',
        );
      }

      return errors;
    },
    onSubmit: (values) => {
      onSaveColumn({
        title: values.title,
        general: values.general,
        analytical: values.analytical,
      });
    },
  });

  return <TypeColumnEditModal title={title} {...rest} {...formikProps} />;
};
