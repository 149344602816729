import {
  Panel,
  PanelSection,
  Skeleton,
  SkeletonButton,
  SkeletonTag,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

export const RequestDetailsPanelLoading = () => (
  <Panel
    footer={
      <div className="flex">
        <SkeletonButton width="100%" />
      </div>
    }
  >
    <PanelSection>
      <SkeletonText size="l" width="50%" />
      <SkeletonText size="xl" width="100%" className="my-xl" />
      <Skeleton height="144px" width="100%" className="mt-xl" />
      <SkeletonTag className="mt-m" />
    </PanelSection>
  </Panel>
);
