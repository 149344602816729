import React from 'react';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications } from 'modules/app/notifications';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

import { CustomExportsContainer } from './components/CustomExports';
import { type CustomField } from './customField';
import { useIntegrationStatusQuery } from '../../hooks';
import { hasIntegrationFileBasedExport } from '../../integration/status';
import InvoicesPattern from '../export-legacy/components/InvoicesPattern';

type Props = {
  customFields: CustomField[] | null;
  updateInvoicePattern: () => void;
};

export const Exports = ({ customFields, updateInvoicePattern }: Props) => {
  const company = useCompany();
  const { pushNotif } = useNotifications();
  const integrationStatus = useIntegrationStatusQuery();
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const hasCustomExportsFeature = useFeature(FEATURES.CUSTOM_EXPORTS);
  const hasCustomExports =
    hasCustomExportsFeature &&
    integrationStatus.status === 'success' &&
    hasIntegrationFileBasedExport(integrationStatus.data.integration);

  return (
    <div className={`${hasNewNavigation ? '' : 'max-w-[760px] mx-auto'}`}>
      <InvoicesPattern
        company={company}
        customFields={hasCustomFieldsFeature ? customFields : []}
        pushNotif={pushNotif}
        updateInvoicePattern={updateInvoicePattern}
      />
      {hasCustomExports && (
        <CustomExportsContainer customFields={customFields} />
      )}
    </div>
  );
};
