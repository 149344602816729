import { ModalContent, ModalOverlay, Button } from '@dev-spendesk/grapes';
import { useState } from 'react';

import withErrorBoundary from 'common/components/withErrorBoundary';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { LazySFSUKRevealPinContent } from './LazySFSUKRevealPinContent';
import styles from './SFSRevealPinButton.module.css';
import { SFSRevealPinContent } from './SFSRevealPinContent';
import { useSFSRevealPinAccess } from './useSFSRevealPinAccess';
import { usePlasticCard } from '../../../hooks/usePlasticCard';

type Props = {
  isBlocked: boolean;
};

const SFSRevealPinButton = ({ isBlocked }: Props) => {
  const hasSFSPinRevealAccess = useSFSRevealPinAccess();
  const card = usePlasticCard();
  const company = useCompany();
  const { t } = useTranslation('global');
  const [showSFSPinRevealModal, setShowSFSPinRevealModal] = useState(false);

  if (!hasSFSPinRevealAccess || isBlocked) {
    return null;
  }

  return (
    <>
      <Button
        fit="parent"
        variant="secondary"
        iconName="eye"
        text={t('cards.actions.seePinModal')}
        onClick={() => {
          track(AnalyticEventName.CARD_SEE_PIN_REVEAL);
          setShowSFSPinRevealModal(true);
        }}
        isDisabled={false}
        isLoading={false}
      />

      {/* Grapes Modal without title/image is easier to do with the Modal** components  */}
      <ModalOverlay isOpen={showSFSPinRevealModal}>
        <ModalContent
          onClose={() => {
            track(AnalyticEventName.CARD_CLOSE_PIN_REVEAL, {
              trigger: 'user',
            });
            setShowSFSPinRevealModal(false);
          }}
          className={`${styles.buttonOnTop} ${
            card.currency === 'GBP' ? '' : 'h-[297px] w-[600px]'
          }`}
        >
          {card.currency === 'GBP' ? (
            <LazySFSUKRevealPinContent
              onClose={() => {
                track(AnalyticEventName.CARD_CLOSE_PIN_REVEAL, {
                  trigger: 'timer-modal',
                });
                setShowSFSPinRevealModal(false);
              }}
              cardId={card.id}
              companyId={company.id}
            />
          ) : (
            <SFSRevealPinContent
              onClose={() => {
                track(AnalyticEventName.CARD_CLOSE_PIN_REVEAL, {
                  trigger: 'timer-modal',
                });
                setShowSFSPinRevealModal(false);
              }}
              cardId={card.id}
              companyId={company.id}
            />
          )}
        </ModalContent>
      </ModalOverlay>
    </>
  );
};

const SFSRevealPinButtonWithErrorBoundary = withErrorBoundary({
  team: 'capture',
  scope: 'card::marqeta-pin-reveal::modal',
})(SFSRevealPinButton);

export { SFSRevealPinButtonWithErrorBoundary as SFSRevealPinButton };
