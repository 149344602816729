import { getFetcher } from 'src/core/api/fetcher';

import { GET_CUSTOM_FIELD_VALUES } from './query';
import { type RawCustomField } from '../../../../../apis/prepare-payable/useFieldValuesQuery/reshaper';

type RawData = {
  company: {
    customFields: RawCustomField[];
  };
};

export const getCustomFieldsQueryFetcher = ({
  companyId,
  filter,
}: {
  companyId: string;
  filter: {
    customFieldId: string;
    customFieldValueIds?: string[];
    search?: string;
    includeArchivedValues: boolean;
  };
}) =>
  getFetcher<RawData>({
    companyId,
    getRequest: () => ({
      type: 'graphQL',
      target: 'v2',
      query: GET_CUSTOM_FIELD_VALUES,
      variables: {
        ...filter,
      },
      method: 'get',
    }),
  });
