import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { ForbiddenError, ServerError } from '../../common/errors';
import { dotfileCaseSchema } from '../tasks/schema';

export const dotfileRouterContract = initContract().router({
  updateDotfileBusinessType: {
    summary: 'Update the business type of the dotfile case',
    method: 'PATCH',
    path: '/kyc/dotfile/business-type',
    body: z.object({
      businessType: z.enum(['association', 'company']),
      copyIdFrom: z.string().optional(),
    }),
    responses: {
      200: dotfileCaseSchema,
      400: z.object({
        errorCode: z.literal('invalidBusinessType'),
        message: z.literal('Invalid business type'),
      }),
      ...ForbiddenError,
      404: z.object({
        errorCode: z.literal('dotfileCaseNotFound'),
        message: z.literal('No dotfile case found for this company'),
      }),
      ...ServerError,
    },
  },
});
