import { Button, Callout, Link } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import * as WalletSummary from '../models/summary';

export const MissingFundsWarningCallout = ({
  companyId,
  legacyWalletSummary,
}: {
  companyId: string;
  legacyWalletSummary: WalletSummary.Summary | WalletSummary.DetailedSummary;
}) => {
  const { t } = useTranslation('sfs-migration');

  if (!legacyWalletSummary) {
    return null;
  }

  const displayMissingFundsWarning =
    WalletSummary.isDetailedSummary(legacyWalletSummary) &&
    WalletSummary.hasMissingFunds(legacyWalletSummary, false);

  return (
    <>
      {displayMissingFundsWarning && (
        <Callout
          className="mb-xs mt-xs"
          variant="warning"
          title={t('legacyWalletCallout.missingFunds')}
        >
          <div className="flex justify-center">
            <Link
              href={`/free-upgrade/${companyId}/overview/follow-your-progress`}
              className="w-full"
            >
              <Button
                text={t('legacyWalletCallout.overviewButton')}
                variant="warning"
                className="w-full"
              />
            </Link>
          </div>
        </Callout>
      )}
    </>
  );
};
