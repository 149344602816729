import { z } from 'zod';

export const activationDigitMismatchSchema = z.union([
  z.object({
    error: z.literal('lastDigitsDontMatch'),
  }),
  z.object({
    error: z.literal('lastDigitsDontMatchForCompany'),
    matchingCompanyId: z.string(),
    matchingCompanyName: z.string(),
  }),
]);

export const userIsPendingScreeningSchema = z.object({
  error: z.literal('userIsPendingScreening'),
  message: z.literal('User is pending screening, will need to try again later'),
});
