import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from 'modules/app/hooks/useUser';
import { type AppDispatch } from 'modules/app/redux/store';
import { hasApproverSubNav } from 'modules/requests';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { fetchRequestsStats } from 'src/core/modules/requests/redux/legacy/actions';
import { getRequestsStats } from 'src/core/modules/requests/redux/legacy/selectors';

import { ApproverRequestsSubNav } from './ApproverRequestsSubNav';
import { RequestsSubNav } from './RequestsSubNav';

export const RequestsSubNavContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useUser();
  const stats = useSelector(getRequestsStats);

  useEffect(() => {
    dispatch(fetchRequestsStats());
  }, []);

  return (
    <ErrorBoundary
      context={{ scope: 'requests::subnav', team: 'capture' }}
      fallbackComponent={<></>}
    >
      {hasApproverSubNav(user) ? (
        <ApproverRequestsSubNav stats={stats} />
      ) : (
        <RequestsSubNav stats={stats} />
      )}
    </ErrorBoundary>
  );
};
