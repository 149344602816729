import {
  DatePicker,
  InfoTip,
  Button,
  AmountInput,
  FormField,
} from '@dev-spendesk/grapes';
import isAfter from 'date-fns/isAfter';
import subDays from 'date-fns/subDays';
import subYears from 'date-fns/subYears';
import * as Money from 'ezmoney';
import { useFormik, type FormikErrors } from 'formik';
import React, { useMemo, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type CurrenciesKey, SORTED_CURRENCIES } from 'src/core/config/money';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type FormValues } from './form';
import { useGetFxTransparencyQuery } from './useGetFxTransparencyQuery';

import './FXCalculator.css';

const getFormattedAmount = (
  amount: number | null,
  currency: string | null,
): string => {
  if (!amount || !currency) {
    return '';
  }
  return formatMonetaryValue(Money.fromNumber(amount, currency, 2));
};

// The next currencies are the only ones supported by Bankable for this feature
// (07-21-2023):
// https://bankable.atlassian.net/servicedesk/customer/portal/1/PRD-31011
const supportedCurrenciesCodes = [
  'BGN',
  'CHF',
  'CZK',
  'DKK',
  'EUR',
  'GBP',
  'HUF',
  'ISK',
  'NOK',
  'PLN',
  'RON',
  'SEK',
];

const allCurrencies = (Object.keys(SORTED_CURRENCIES) as CurrenciesKey[]).map(
  (k) => ({
    key: k,
    label: SORTED_CURRENCIES[k]?.label,
  }),
);

const supportedCurrencies = allCurrencies.filter((c) =>
  supportedCurrenciesCodes.includes(c.key),
);

const beginningOfRegulation = new Date('2020-02-14');
const oneYearAgo = subYears(new Date(), 1);
const minimumDate = isAfter(beginningOfRegulation, oneYearAgo)
  ? beginningOfRegulation
  : oneYearAgo;

export const FXCalculator = () => {
  const { t } = useTranslation();
  const { pushNotif } = useNotifications();
  const company = useCompany();

  const [displayedFxRate, setDisplayedFxRate] = useState<
    | {
        schemeConversionRate: string;
        ecbConversionRate: string;
        issuerBillingAmount: number;
        ecbReferenceAmount: number;
        issuerVsEcbDiff: string;
        issuerConversionRate: string;
      }
    | undefined
  >();

  // We want to exclude the company's currency.
  const availableCurrencies = useMemo(
    () => supportedCurrencies.filter((c) => c.key !== company.currency),
    [company],
  );

  const formikProps = useFormik<FormValues>({
    initialValues: {
      transactionAmount: null,
      transactionDate: subDays(new Date(), 1),
      transactionCurrency: availableCurrencies[0],
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.transactionAmount) {
        errors.transactionAmount = t('FXCalculator.errors.noAmount');
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: async () => {
      try {
        const fetchedDisplayedFxRate = await getFxTransparencyQuery();
        setDisplayedFxRate(fetchedDisplayedFxRate);
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('errors:somethingWrong_short'),
        });
      }
    },
  });

  const [getFxTransparencyQuery] = useGetFxTransparencyQuery(
    formikProps.values,
  );

  return (
    <div className="page__container bg-page-background">
      <div className="mx-auto my-xxl max-w-[800px]">
        <div className="box">
          <h1 className="title-xl">{t('FXCalculator.title')}</h1>
          <p className="mb-s body-m">{t('FXCalculator.subtitle')}</p>
          <form onSubmit={formikProps.handleSubmit}>
            <div className="FXCalculator__form">
              <FormField label={t('FXCalculator.transactionDate')}>
                <DatePicker
                  value={formikProps.values.transactionDate}
                  onChange={(date) => {
                    formikProps.setFieldValue('transactionDate', date);
                  }}
                  maxDate={subDays(new Date(), 1)}
                  minDate={minimumDate}
                  fit="parent"
                />
              </FormField>
              <FormField label={t('FXCalculator.transactionAmount')}>
                <AmountInput
                  placeholder={t('wallet.stripe.amountPlaceholder')}
                  isInvalid={Boolean(formikProps.errors.transactionAmount)}
                  currency={formikProps.values.transactionCurrency}
                  currencies={availableCurrencies}
                  value={formikProps.values.transactionAmount}
                  name="amount"
                  fit="parent"
                  onChange={(_, value) => {
                    formikProps.setFieldValue('transactionAmount', value);
                  }}
                  onSelectCurrency={(currency: { key: string }) => {
                    formikProps.setFieldValue('transactionCurrency', currency);
                  }}
                />
              </FormField>
            </div>
            <Button
              fit="parent"
              text={t('FXCalculator.getRate')}
              variant="primary"
              isLoading={formikProps.isSubmitting}
              type="submit"
              className="mt-m"
            />
          </form>
          {displayedFxRate && !formikProps.isSubmitting && (
            <>
              <div className="separator my-m w-full" />
              <div className="FXCalculator__rates">
                <table>
                  <thead>
                    <tr>
                      <td aria-label="headers" />
                      <td>{t('FXCalculator.rateTable.ECB')}</td>
                      <td>{t('FXCalculator.rateTable.TP')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t('FXCalculator.rateTable.transactionAmount')}</td>
                      <td>
                        {getFormattedAmount(
                          formikProps.values.transactionAmount,
                          formikProps.values.transactionCurrency.key,
                        )}
                      </td>
                      <td>
                        {getFormattedAmount(
                          formikProps.values.transactionAmount,
                          formikProps.values.transactionCurrency.key,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>{t('FXCalculator.rateTable.appliedRate')}</td>
                      <td>&#215; {displayedFxRate.ecbConversionRate}</td>
                      <td>&#215; {displayedFxRate.issuerConversionRate}</td>
                    </tr>
                    <tr className="FXCalculator__ConvertedAmount">
                      <td>{t('FXCalculator.rateTable.convertedAmount')}</td>
                      <td>
                        {getFormattedAmount(
                          displayedFxRate.ecbReferenceAmount,
                          company.currency,
                        )}
                      </td>
                      <td>
                        {getFormattedAmount(
                          displayedFxRate.issuerBillingAmount,
                          company.currency,
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} className="FXCalculator__RateDifference">
                        <span className="flex items-center gap-xs">
                          {t('FXCalculator.rateTable.difference', {
                            percentage: displayedFxRate.issuerVsEcbDiff,
                          })}
                          <InfoTip
                            content={`(${displayedFxRate.issuerBillingAmount} - ${displayedFxRate.ecbReferenceAmount}) / ${displayedFxRate.ecbReferenceAmount} * 100`}
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <p className="mt-s text-neutral-dark body-m">
          {t('FXCalculator.disclaimer')}
        </p>
      </div>
    </div>
  );
};
