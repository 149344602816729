import { useLocalStorage } from 'react-use';

import { LocalStorageKey } from 'src/core/constants/storage';

import { OrganisationReportingPage } from './OrganisationReportingPage';
import { useOrganisationCompanyList } from '../../hooks/useOrganisationCompanyList';
import { useOrganisationFeatures } from '../../hooks/useOrganisationFeatures';
import { OrganisationReportingIntroModal } from '../OrganisationReportingIntroModal/OrganisationReportingIntroModal';

export const OrganisationReportingPageContainer = () => {
  const features = useOrganisationFeatures();
  const organisationCompanyList = useOrganisationCompanyList();

  const [introModalAlreadyDisplayed, toggleIntroModalEncountered] =
    useLocalStorage<boolean>(
      LocalStorageKey.OrganisationReportingIntroModalEncountered,
      false,
    );

  return (
    <>
      {!introModalAlreadyDisplayed && (
        <OrganisationReportingIntroModal
          onModalHidden={() => toggleIntroModalEncountered(true)}
        />
      )}
      <OrganisationReportingPage
        features={features}
        companies={organisationCompanyList}
      />
    </>
  );
};
