import { type LegacyRequest, type RequestAPI } from '../types';

const invoiceToCamelCase = (
  invoice: RequestAPI['invoices'][0],
): LegacyRequest['invoices'][0] => ({
  ...invoice,
  organisationId: invoice.organisation_id,
  companyId: invoice.company_id,
  paymentId: invoice.payment_id,
  userId: invoice.user_id,
  requestId: invoice.request_id,
  usesPngThumbnailExtension: invoice.uses_png_thumbnail_extension,
  autoMatched: invoice.auto_matched,
  archivedAt: invoice.archived_at,
  uploadStatus: invoice.upload_status,
  createdAt: invoice.created_at,
  updatedAt: invoice.updated_at,
  deletedAt: invoice.deleted_at,
});

const invoiceToSnakeCase = (
  invoice: LegacyRequest['invoices'][0],
): RequestAPI['invoices'][0] => ({
  ...invoice,
  organisation_id: invoice.organisationId,
  company_id: invoice.companyId,
  payment_id: invoice.paymentId,
  user_id: invoice.userId,
  request_id: invoice.requestId,
  uses_png_thumbnail_extension: invoice.usesPngThumbnailExtension,
  auto_matched: invoice.autoMatched,
  archived_at: invoice.archivedAt,
  upload_status: invoice.uploadStatus,
  created_at: invoice.createdAt,
  updated_at: invoice.updatedAt,
  deleted_at: invoice.deletedAt,
  downloadXmlUrl: invoice.downloadXmlUrl,
});

const userToCamelCase = (user: RequestAPI['user']): LegacyRequest['user'] => ({
  ...user,
  nbCompanies: user.nb_companies,
  companyIds: user.company_ids,
  isOrganisationOwner: user.is_organisation_owner,
  intercomHash: user.intercom_hash,
  firstName: user.first_name,
  lastName: user.last_name,
  notificationProviderUsers: user.notification_provider_users,
});

const userToSnakeCase = (user: LegacyRequest['user']): RequestAPI['user'] => ({
  ...user,
  nb_companies: user.nbCompanies,
  company_ids: user.companyIds,
  is_organisation_owner: user.isOrganisationOwner,
  intercom_hash: user.intercomHash,
  first_name: user.firstName,
  last_name: user.lastName,
  notification_provider_users: user.notificationProviderUsers,
});

export const toCamelCase = (request: RequestAPI): LegacyRequest => ({
  ...request,
  organisationId: request.organisation_id,
  companyId: request.company_id,
  subscriptionId: request.subscription_id,
  supplierId: request.supplier_id,
  groupId: request.group_id,
  costCenter: request.cost_center,
  userId: request.user_id,
  currencyDeclared: request.currency_declared,
  amountDeclared: request.amount_declared,
  userMessage: request.user_message,
  denyMotif: request.deny_motif,
  autoApproved: request.auto_approved,
  amountToRefund: request.amount_to_refund,
  originalConvertedAmount: request.original_converted_amount,
  expensePaidAt: request.expense_paid_at,
  expenseAccountId: request.expense_account_id,
  paymentMethod: request.payment_method,
  createdAt: request.created_at,
  updatedAt: request.updated_at,
  deletedAt: request.deleted_at,
  user: userToCamelCase(request.user),
  invoices: request.invoices.map(invoiceToCamelCase),
  mileageAllowanceRequest: request.mileage_allowance_request,
  customFields: request.custom_fields,
  approvalState: request.approval_state,
  canApprove: request.can_approve,
  timeline: request.timeline,
  directApprover: request.direct_approver,
});

export const toSnakeCase = (request: LegacyRequest): RequestAPI => ({
  ...request,
  organisation_id: request.organisationId,
  company_id: request.companyId,
  subscription_id: request.subscriptionId,
  supplier_id: request.supplierId,
  group_id: request.groupId,
  cost_center: request.costCenter,
  user_id: request.userId,
  currency_declared: request.currencyDeclared,
  amount_declared: request.amountDeclared,
  user_message: request.userMessage,
  deny_motif: request.denyMotif,
  auto_approved: request.autoApproved,
  amount_to_refund: request.amountToRefund,
  original_converted_amount: request.originalConvertedAmount,
  expense_paid_at: request.expensePaidAt,
  expense_account_id: request.expenseAccountId,
  payment_method: request.paymentMethod,
  created_at: request.createdAt,
  updated_at: request.updatedAt,
  deleted_at: request.deletedAt,
  user: userToSnakeCase(request.user),
  invoices: request.invoices.map(invoiceToSnakeCase),
  mileage_allowance_request: request.mileageAllowanceRequest,
  perDiem: request.perDiem,
  custom_fields_associations: request.customFields,
  // @ts-expect-error
  custom_fields: request.customFields,
  approval_state: request.approvalState,
  can_approve: request.canApprove,
  direct_approver: request.directApprover,
});
