import { apiV2Url } from 'src/core/utils/api';

export type Response =
  | {
      procedureStatus: 'pending' | 'timeout' | 'rejected';
    }
  | {
      procedureStatus: 'approved';
      paymentPassword: string;
    };

export const getPaymentPasswordProcedure = async ({
  procedureId,
}: {
  procedureId: string;
}): Promise<Response> => {
  const response = await fetch(
    apiV2Url(`/cards/payment-password/${procedureId}`),
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('Payment password reset failed');
};
