import cx from 'classnames';
import head from 'lodash/head';
import omit from 'lodash/omit';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';

import { useTranslation } from "src/core/common/hooks/useTranslation";
import {
  monetaryValuePropTypes,
  formatMonetaryValue,
} from "src/core/utils/monetaryValue";

import {
  DISCOUNT_TYPES,
  formatDiscount,
  computePriceWithDiscount,
} from '../helpers';

import './Subscription.scss';

const Subscription = ({ name, price, discounts }) => {
  const { t } = useTranslation();
  // For now, we manage only one discount on the subscription
  const discount = head(discounts);
  const discountedPrice = computePriceWithDiscount(price, discount);

  return (
    <div className="Subscription">
      <span className="Subscription__name">{name}</span>
      <div className="Subscription__pricing">
        <div className="Subscription__pricing-amounts">
          <span
            className={cx('Subscription__pricing-amount', {
              'Subscription__pricing-amount--crossed': !!discount,
            })}
          >
            {formatMonetaryValue(price)}
          </span>
          {discountedPrice && (
            <span className="Subscription__plan-amount">
              {formatMonetaryValue(discountedPrice)}
            </span>
          )}{' '}
          / {t(`billing.month`)}
        </div>
        {discount && (
          <div className="Subscription__pricing-discounts">
            {t('billing.discount', {
              discount: formatDiscount(discount),
            })}
          </div>
        )}
      </div>
    </div>
  );
};

Subscription.propTypes = {
  name: PropTypes.string.isRequired,
  price: monetaryValuePropTypes.isRequired,
  discounts: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.oneOf(
          values(omit(DISCOUNT_TYPES, DISCOUNT_TYPES.PERCENTAGE)),
        ).isRequired,
        value: monetaryValuePropTypes.isRequired,
      }),
      PropTypes.shape({
        type: PropTypes.oneOf([DISCOUNT_TYPES.PERCENTAGE]).isRequired,
        ratio: PropTypes.number.isRequired,
      }),
    ]),
  ).isRequired,
};

export default Subscription;
