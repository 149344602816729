import { fromNumber, type MonetaryValue } from 'ezmoney';
import { useSelector } from 'react-redux';

import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { getUserDataByCompany } from 'src/core/selectors/globalSelectors';

export const useAmountAlreadySpent = (): MonetaryValue => {
  const userDataForCompany = useSelector(getUserDataByCompany);
  const companyCurrency = useCompanyCurrency();

  return fromNumber(userDataForCompany.monthly_outgoings ?? 0, companyCurrency);
};
