import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { type AccountingSoftware } from 'src/core/modules/bookkeep/integration/status';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export function getAccountPayableSuppliersTitle(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.title',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.suppliers.title',
      );
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return t(
        'bookkeep.integrations.settings.syncStatus.default.suppliers.title',
      );
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSuppliersNotSyncedTitle(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.notSyncedTitle',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.suppliers.notSyncedTitle',
      );
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return t(
        'bookkeep.integrations.settings.syncStatus.default.suppliers.notSyncedTitle',
      );
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSuppliersCalloutContent(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.calloutTitle',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.calloutText',
        ),
      };
    case 'Sage100':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.calloutTitle',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.calloutText',
        ),
      };
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.calloutTitle',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.calloutText',
        ),
      };
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSupplierMapVendorMessage(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return {
        success: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.mapVendorSuccessMessage',
        ),
        failure: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.mapVendorFailureMessage',
        ),
      };
    case 'Sage100':
      return {
        success: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.mapVendorSuccessMessage',
        ),
        failure: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.mapVendorFailureMessage',
        ),
      };
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return {
        success: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.mapVendorSuccessMessage',
        ),
        failure: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.mapVendorFailureMessage',
        ),
      };
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSupplierManualMappingError(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.manualMappingErrorHeader',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.notAllowedManualMapping',
        ),
      };
    case 'Sage100':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.manualMappingErrorHeader',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.sage100.suppliers.notAllowedManualMapping',
        ),
      };
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return {
        title: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.manualMappingErrorHeader',
        ),
        text: t(
          'bookkeep.integrations.settings.syncStatus.default.suppliers.notAllowedManualMapping',
        ),
      };
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSuppliersMapVendorFormLabel(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.mapVendorFormLabel',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.suppliers.mapVendorFormLabel',
      );
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return 'bookkeep.integrations.settings.syncStatus.default.suppliers.mapVendorFormLabel';
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}

export function getAccountPayableSuppliersLeftLabel(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
) {
  switch (integration) {
    case 'Netsuite':
      return t(
        'bookkeep.integrations.settings.syncStatus.netsuite.suppliers.leftLabel',
      );
    case 'Sage100':
      return t(
        'bookkeep.integrations.settings.syncStatus.sage100.suppliers.leftLabel',
      );
    case 'Quickbooks':
    case 'Cegid':
    case 'Datev':
    case 'Xero':
    case 'Sage':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
      return t(
        'bookkeep.integrations.settings.syncStatus.default.suppliers.leftLabel',
      );
    default:
      return rejectUnexpectedValue('Unsupported integration:', integration);
  }
}
