import { useEffect, useState } from 'react';

import { CustomFieldFormField } from 'common/components/CustomFieldFormField';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser, type User } from 'modules/app/hooks/useUser';
import { type CustomFieldDefinition } from 'modules/budgets/models/customFieldDefinition';
import {
  getCfLabel,
  getEligibleCustomFields as baseGetEligibleCustomFields,
  harmonizeCustomFieldsAssociations,
} from 'src/core/utils/custom-fields';

import './CustomFieldsSelector.css';

export type EligibleType = 'request' | 'expense' | 'payment' | 'subscription';
type CustomFieldValue = {
  field: { id: string };
  value: { id: string; value: string };
};
type CustomFieldAssociation = {
  customFieldId: string;
  customFieldValueId: string;
  value: string;
  hasMultipleMatchingValues?: boolean;
  updated?: boolean;
};

type Props = {
  customFields?: CustomFieldDefinition[];
  customFieldsValues?: CustomFieldValue[];
  types?: EligibleType[];
  team?: string;
  teamIds?: string[];
  isDisabled?: boolean;
  errors?: { [key: string]: string | boolean };
  onChange?: (
    customFieldAssociations: CustomFieldAssociation[],
    customField: CustomFieldDefinition,
  ) => void;
  fit?: 'parent' | 'content';
  placement?: 'bottom-start' | 'top-end' | 'top-start';
  withAllChanges?: boolean;
};

const getEligibleCustomFields = ({
  customFields,
  user,
  team,
  types,
  teamIds,
}: {
  customFields: CustomFieldDefinition[];
  user: User;
  team?: string;
  types: EligibleType[];
  teamIds: string[];
}) => {
  const eligibleTeamIds = teamIds.length ? teamIds : [];

  if (team && !eligibleTeamIds.length) {
    eligibleTeamIds.push(team);
  }

  const options = { user, types, teamIds: eligibleTeamIds };

  return baseGetEligibleCustomFields(customFields, options);
};

export const CustomFieldsSelector = ({
  customFields = [],
  customFieldsValues = [],
  types = [],
  team,
  teamIds = [],
  isDisabled = false,
  errors = {},
  onChange = () => {},
  fit,
  placement,
  withAllChanges,
}: Props) => {
  const { t } = useTranslation('global');

  const user = useUser();

  const [eligibleCustomFields, setEligibleCustomFields] = useState<
    CustomFieldDefinition[]
  >(getEligibleCustomFields({ customFields, user, team, types, teamIds }));
  const [customFieldsAssociations, setCustomFieldsAssociations] = useState<
    CustomFieldAssociation[] | null
  >(harmonizeCustomFieldsAssociations(customFields, customFieldsValues));

  useEffect(() => {
    setEligibleCustomFields(
      getEligibleCustomFields({ customFields, user, team, types, teamIds }),
    );
  }, [user, team, customFields]);

  // Update chosen value locally & propagate changes
  const handleSelectValue = (
    customField: CustomFieldDefinition,
    value: { key: string; name: string } | string,
  ) => {
    const matchingCfAssocIndex = (customFieldsAssociations ?? []).findIndex(
      (item) => item.customFieldId === customField.id,
    );

    const updatedAssociation: CustomFieldAssociation = {
      customFieldId: customField.id,
      // @ts-expect-error types are incompatible
      customFieldValueId: typeof value === 'object' ? value.key : null,
      value: typeof value === 'object' ? value.name : value,
      updated: true,
    };

    const updatedCustomFieldsAssociations = customFieldsAssociations
      ? [...customFieldsAssociations]
      : [];

    if (matchingCfAssocIndex !== -1) {
      // Existing CF association mapping
      updatedCustomFieldsAssociations.splice(
        matchingCfAssocIndex,
        1,
        updatedAssociation,
      );
    } else {
      // New CF association mapping
      updatedCustomFieldsAssociations.push(updatedAssociation);
    }

    setCustomFieldsAssociations(updatedCustomFieldsAssociations);

    if (withAllChanges) {
      const sanitizedAssociations = updatedCustomFieldsAssociations.map((a) => {
        const {
          hasMultipleMatchingValues,
          updated,
          ...updatedCustomFieldAssociation
        } = a;
        return updatedCustomFieldAssociation;
      });
      return onChange(sanitizedAssociations, customField);
    }
    // Clear utils variables before propagating changes
    const updatedAssociations = updatedCustomFieldsAssociations.filter(
      (customFieldAssociation) => customFieldAssociation.updated,
    );
    const sanitizedAssociations = updatedAssociations.map((a) => {
      const {
        hasMultipleMatchingValues,
        updated,
        ...updatedCustomFieldAssociation
      } = a;
      return updatedCustomFieldAssociation;
    });
    return onChange(sanitizedAssociations, customField);
  };

  const getPlaceholder = (
    customFieldValue: CustomFieldAssociation | undefined,
  ) => {
    const fieldHasManySelectedValues =
      customFieldValue?.hasMultipleMatchingValues;
    if (fieldHasManySelectedValues) {
      return t('misc.multipleValues');
    }
    if (isDisabled) {
      return t('misc.noValueSelected');
    }
    return t('misc.chooseValue');
  };

  if (!eligibleCustomFields.length) {
    return null;
  }

  return (
    <div>
      {eligibleCustomFields.map((customField) => {
        const customFieldValue = customFieldsAssociations?.find(
          ({ customFieldId }) => customFieldId === customField.id,
        );

        // The field has been deleted and no value was assigned before
        if (customField.deleted_at && !customFieldValue) {
          return null;
        }

        return (
          <CustomFieldFormField
            className="CustomFieldsSelector__item"
            key={`${customField.id}_${customFieldValue?.customFieldValueId}`}
            customField={customField}
            customFieldAssociation={customFieldValue}
            label={getCfLabel(customField)}
            placeholder={getPlaceholder(customFieldValue)}
            isDisabled={isDisabled || Boolean(customField.deleted_at)}
            isInvalid={Boolean(errors[customField.id])}
            onSelectCustomFieldValue={(newCustomFieldValue) => {
              if (newCustomFieldValue) {
                handleSelectValue(customField, newCustomFieldValue);
              }
            }}
            onSelectNewCustomFieldValue={(rawValue) =>
              handleSelectValue(customField, rawValue)
            }
            fit={fit}
            placement={placement}
          />
        );
      })}
    </div>
  );
};
