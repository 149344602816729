import filter from 'lodash/filter';

import * as customFieldsTypes from "src/core/actionTypes/customFields";
import * as groupsTypes from "src/core/actionTypes/groups";

const initialState = {
  groups: null,
  customFields: null,
  featureActivation: {
    isLoading: false,
    isRequested: false, // if true the user requests to activate the custom fields feature
  },
  behavior: {
    errors: null,
    isLoading: false,
  },
};

const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case groupsTypes.FETCH_GROUPS_LOADING:
    case customFieldsTypes.FETCH_CF_LOADING: {
      return {
        ...state,
        behavior: {
          errors: null,
          isLoading: true,
        },
      };
    }
    case groupsTypes.FETCH_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: action.payload.groups,
        behavior: {
          errors: null,
          isLoading: false,
        },
      };
    }
    case customFieldsTypes.FETCH_CF_SUCCESS: {
      return {
        ...state,
        customFields: filter(action.payload.customFields, { deleted_at: null }),
        behavior: {
          errors: null,
          isLoading: false,
        },
      };
    }
    case customFieldsTypes.UPDATE_CUSTOM_FIELDS_FROM_SETTINGS: {
      return {
        ...state,
        customFields: action.payload,
      };
    }
    case groupsTypes.FETCH_GROUPS_FAILURE:
    case customFieldsTypes.FETCH_CF_FAILURE: {
      return {
        ...state,
        behavior: {
          errors: true,
          isLoading: false,
        },
      };
    }
    case customFieldsTypes.GRANT_CUSTOM_FIELDS_FEATURE_LOADING: {
      return {
        ...state,
        featureActivation: {
          isLoading: true,
          isRequested: false,
        },
      };
    }
    case customFieldsTypes.GRANT_CUSTOM_FIELDS_FEATURE_SUCCESS: {
      return {
        ...state,
        featureActivation: {
          isLoading: false,
          isRequested: true,
        },
      };
    }
    case customFieldsTypes.GRANT_CUSTOM_FIELDS_FEATURE_FAILURE: {
      return {
        ...state,
        featureActivation: {
          isLoading: false,
          isRequested: false,
        },
      };
    }
    default:
      return state;
  }
};

export default accountingReducer;
