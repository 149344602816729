import { Popover, type TriggerProps } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { group, AnalyticEventName } from 'src/core/utils/analytics';

import { CompanyDropdownContent } from './CompanyDropdownContent';
import { NewBranch } from '../../../app/layout/components/NewBranch';
import OnboardingModal from '../../../app/layout/components/NewBranch/modals/OnboardingModal';
import {
  type CompanyWalletSummary,
  isCreatingCompany,
} from '../../companyWalletSummary';
import './CompanyDropdown.css';

type Props = {
  companies: CompanyWalletSummary[];
  className?: string;
  children: (triggerProps: TriggerProps, isOpen: boolean) => ReactNode;
  placement?: 'end-bottom' | 'bottom-start';
};

export const CompanyDropdown = ({
  companies,
  children,
  className,
  placement,
}: Props) => {
  const currentCompanyId = useCompanyId();
  const user = useUser();
  const impersonator = useSelector(getImpersonator);

  const hasOrganisationBranchesFeature = useFeature(
    FEATURES.ORGANISATION_BRANCHES,
  );

  const isUserOrganisationOwner = user.is_organisation_owner;
  const canCreateCompany =
    hasOrganisationBranchesFeature && isUserOrganisationOwner && !impersonator;

  const [isNewCompanyModalVisible, setIsNewCompanyModalVisible] =
    useState<boolean>(false);
  const [isOnboardingModalVisible, setIsOnboardingModalVisible] =
    useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    bankingProvider: 'bankable' | 'marqeta' | 'sfs';
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentCompany = companies.find(
    (company) => company.id === currentCompanyId,
  )!;
  const otherCompanies = companies.filter(
    (company) => company.id !== currentCompanyId,
  );

  const handleOpenOnboardingModal = (company: CompanyWalletSummary): void => {
    if (isCreatingCompany(company)) {
      setSelectedCompany({
        id: company.id,
        bankingProvider: company.bankingProvider,
      });
      setIsOnboardingModalVisible(true);
    }
  };

  const handleCloseOnboardingModal = (): void => {
    setIsOnboardingModalVisible(false);
    setSelectedCompany(null);
  };

  return (
    <>
      <Popover
        className={classNames('CompanyDropdown', className)}
        renderTrigger={children}
        placement={placement}
      >
        {(closeDropdown) => {
          return (
            <CompanyDropdownContent
              currentCompany={currentCompany}
              companies={otherCompanies}
              onCompanyClick={(company) => {
                if (isCreatingCompany(company)) {
                  handleOpenOnboardingModal(company);
                } else {
                  window.location.href = `/app/${company.id}`;
                  closeDropdown();
                }
                group(
                  AnalyticEventName.COMPANIES_DROPDOWN_COMPANY_CLICK,
                  company.id,
                  {},
                );
              }}
              onNewCompanyClick={
                canCreateCompany
                  ? () => {
                      setIsNewCompanyModalVisible(true);
                      closeDropdown();
                    }
                  : undefined
              }
              closeDropdown={closeDropdown}
            />
          );
        }}
      </Popover>
      {isNewCompanyModalVisible && (
        <NewBranch onClose={() => setIsNewCompanyModalVisible(false)} />
      )}
      {isOnboardingModalVisible && selectedCompany && (
        <OnboardingModal
          companyId={selectedCompany.id}
          bankingProvider={selectedCompany.bankingProvider}
          onClose={handleCloseOnboardingModal}
        />
      )}
    </>
  );
};
