import { z } from 'zod';

export const nonSkippedOrderStateSchema = z.enum([
  'pending',
  'ready_to_order',
  'ordered',
  'shipped',
]);

export const skippedRecardOrderStateSchema = z.literal('skipped');

export const allRecardOrderStateSchema = z.union([
  nonSkippedOrderStateSchema,
  skippedRecardOrderStateSchema,
]);
