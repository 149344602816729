import React from 'react';
import { Redirect } from 'react-router';

import UnexpectedError from 'common/components/legacy/UnexpectedError/UnexpectedError';
import withErrorBoundary from 'common/components/withErrorBoundary';
import { useParams } from 'common/hooks/useParams';
import { useCompany } from 'modules/app/hooks/useCompany';
import { routeFor, routes } from 'src/core/constants/routes';

import { useGetIntegrations } from '../../../hooks';
import { type IntegrationId } from '../../../types';
import { isKnownIntegration } from '../../../utils';
import {
  IntegrationDetails,
  IntegrationDetailsSkeleton,
} from '../../components';

const IntegrationDetailsContainer = () => {
  const integrationsQuery = useGetIntegrations();
  const params = useParams(routes.COMPANY_INTEGRATION.path);
  const company = useCompany();

  if (integrationsQuery.status === 'loading') {
    return (
      <div className="page__container">
        <IntegrationDetailsSkeleton />;
      </div>
    );
  }
  if (integrationsQuery.status === 'error') {
    return (
      <div className="page__container">
        <UnexpectedError />
      </div>
    );
  }
  if (
    params.integration &&
    isKnownIntegration(params.integration as IntegrationId)
  ) {
    const { active } = integrationsQuery.data;
    const activeIntegration = active.find(
      (integration) => integration.id === params.integration,
    );
    if (activeIntegration) {
      return <IntegrationDetails integration={activeIntegration} />;
    }
  }
  return (
    <Redirect
      to={{
        pathname: routeFor(routes.COMPANY_INTEGRATIONS_ALL.path, {
          company: company.id,
        }),
      }}
    />
  );
};

export default withErrorBoundary({
  scope: 'integration-details',
  team: 'api-integration',
})(IntegrationDetailsContainer);
