import { type ClientInferRequest } from '@ts-rest/core';

import { useMutation } from 'src/core/api/hooks/useMutation';
import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

type Payload = ClientInferRequest<
  typeof sfsMigrationContract.cards.postReorderSfsCard
>['body'];

export const useReorderSfsCardMutation = () => {
  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint: '/sfs-migration/reorder-sfs-card',
    },
    reshapeData: (data: Response) => data,
  });
};
