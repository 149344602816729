import { type QueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUpdatePayablesQueryCache } from 'modules/bookkeep/prepare-payables/components/PreparePayablesBuckets/hooks/usePayablesQuery/usePayablesQuery';
import { useMutation } from 'src/core/api/hooks/useMutation';
import { type QueryError } from 'src/core/api/queryError';
import {
  type Translations,
  useQueryError,
} from 'src/core/common/components/QueryError';
import { type RawPayable } from 'src/core/modules/bookkeep/apis/prepare-payable/useGetPayableQuery/graphql-reshaper';
import {
  useInvalidateGetPayableQueryCache,
  useUpdateGetPayableQueryCache,
} from 'src/core/modules/bookkeep/apis/prepare-payable/useGetPayableQuery/useGetPayableQuery';

import { type PayableId, type PayableUpdate } from '../../models';

export type UpdatePayableError =
  | {
      reason: 'invalidBodyParams';
      error: Error;
    }
  | {
      reason:
        | 'unauthorized'
        | 'serviceNotFound'
        | 'notUpdated'
        | 'wrongVersion'
        | 'updateInProgress'
        | 'invalidState'
        | 'notFound'
        | 'invalidExpenseAccounts'
        | 'invalidTaxAccounts'
        | 'unmatchingVatRate'
        | 'indexInvalid'
        | 'indexOutOfBounds'
        | 'noInput'
        | 'spendingCommitmentNotFound'
        | 'reversalNotFound'
        | 'expenseNotFound'
        | 'spendingCommitmentRevoked'
        | 'inconsistentLines'
        | 'inconsistentSummary'
        | 'reversalDiscarded'
        | 'invalidCounterparty'
        | 'invalidAccountPayable'
        | 'invalidDocumentaryEvidence'
        | 'noIntegration'
        | 'switchInProgress';
    }
  | {
      reason: 'invalidItemLines';
      result: Record<string, unknown>;
    };

export type UpdatePayableParams = {
  payableId: PayableId;
};

export type UpdatePayableResponse = {
  newPayableVersion: number;
};

export type UpdatePayablePayload = {
  payableVersion: number;
  update: PayableUpdate;
};

/**
 * Mutation
 **/

export const useUpdatePayable = (payableId: PayableId | undefined) => {
  const companyId = useCompanyId();
  const invalidateGetPayableQueryCache = useInvalidateGetPayableQueryCache();
  const updateGetPayableQueryCache = useUpdateGetPayableQueryCache();
  const updatePayablesQueryCache = useUpdatePayablesQueryCache();

  // Refresh the payable in the background, not blocking the mutation
  // TODO@financeAccountant@CORE-4887: the endpoint should return the new payable so we can skip this

  const invalidateGetPayableQueryCacheForPayableId = async (
    client: QueryClient,
  ) => {
    if (!payableId) {
      return;
    }

    // Payable details in All Payables
    client.invalidateQueries([`payablePanel::${payableId}`]);
    // Payable details in Prepare
    invalidateGetPayableQueryCache(companyId, payableId);
  };

  return useMutation<
    UpdatePayablePayload,
    UpdatePayableResponse,
    UpdatePayableResponse,
    UpdatePayableError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/bookkeeping/payable/${payableId}`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: async ({ client, payload, data: { newPayableVersion } }) => {
        if (!payableId) {
          return;
        }

        const {
          counterparty,
          itemLines,
          accountPayableId,
          teamId,
          costCenterId,
          customFieldAssociations,
          ...payloadUpdate
        } = payload.update;

        const update: Partial<RawPayable> = {
          ...payloadUpdate,
          version: newPayableVersion,
        };

        if (accountPayableId !== undefined) {
          update.accountPayable = accountPayableId
            ? {
                id: accountPayableId,
                generalAccountCode: '',
                auxiliaryAccountCode: undefined,
              }
            : null;
        }

        if (itemLines || teamId || costCenterId || customFieldAssociations) {
          // TODO@financeAccountant@CORE-4887: handle updates with reshaper
        }

        if (counterparty) {
          update.counterparty = {
            id: counterparty.id,
            name: counterparty.name ?? '',
            thumbnailUrl: counterparty.thumbnailUrl ?? '',
          };
        }

        updateGetPayableQueryCache(companyId, payableId, update);
        updatePayablesQueryCache(companyId, payableId, {
          ...update,
          // RawPayable update can't change documentaryEvidence validity, which
          // is part of the PayableSummary type (but not of RawPayable), so we
          // make sure it is not part of the query cache update
          documentaryEvidence: undefined,
        });

        invalidateGetPayableQueryCacheForPayableId(client);
      },
      onError: ({ client, error }) => {
        // Refresh payable if error is `wrongVersion`
        if (
          payableId &&
          error.type === 'RequestError' &&
          error.data.reason === 'wrongVersion'
        ) {
          invalidateGetPayableQueryCacheForPayableId(client);
        }
      },
    },
    reshapeData(data) {
      return data;
    },
  });
};

/**
 * Error messages
 **/

export const updatePayableErrorTranslations: Translations<UpdatePayableError> =
  {
    requestError: ({ reason }) => {
      switch (reason) {
        case 'invalidItemLines':
          return 'bookkeep.prepare.bulkValidationErrors.invalidItemLines';
        case 'unmatchingVatRate':
          return 'bookkeep.prepare.bulkValidationErrors.unmatchingVatRate';
        case 'invalidExpenseAccounts':
          return 'bookkeep.prepare.bulkValidationErrors.invalidExpenseAccounts';
        case 'invalidTaxAccounts':
          return 'bookkeep.prepare.bulkValidationErrors.invalidTaxAccounts';
        case 'invalidAccountPayable':
          return 'bookkeep.prepare.bulkValidationErrors.invalidAccountPayable';
        case 'updateInProgress':
          return 'bookkeep.prepare.bulkValidationErrors.updateInProgress';
        case 'wrongVersion':
          return 'expenseInbox.errors.hasBeenModifiedError';
        case 'invalidState':
          return 'expenseInbox.errors.alreadyPreparedError';
        case 'notFound':
          return 'expenseInbox.errors.notFoundError';
        default:
          return 'payable.api.updatePayable.error';
      }
    },
    serverError: 'payable.api.updatePayable.error',
  };

export const useUpdatePayableErrorMessage = () => {
  const getErrorMessage = useQueryError<UpdatePayableError>(
    updatePayableErrorTranslations,
  );

  return (
    queryError: QueryError<UpdatePayableError>,
    translationParams?: Record<string, unknown>,
  ): string => getErrorMessage(queryError, translationParams);
};
