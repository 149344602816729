import {
  Button,
  DATE_FORMAT,
  Icon,
  IconButton,
  type DateFormatter,
  useDateFormatter,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { differenceInDays, parseISO } from 'date-fns';
import { useLocalStorage } from 'react-use';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import withErrorBoundary from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TSFSMigrationFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { type User, useUser } from 'src/core/modules/app/hooks/useUser';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getPhysicalCardActivationStatus } from 'src/sfs-migration/routes/[companyId]/overview/hooks/useEmployeeSfsCardQuery';
import { stepNameToUrlpart } from 'src/sfs-migration/routes/[companyId]/overview/hooks/useMigrationStep';
import { getSubscriptionsMigrationStatus } from 'src/sfs-migration/routes/[companyId]/renew-subscriptions/api/useSubscriptionsQuery';

import styles from './SFSMigrationBanner.module.css';
import { SFSMigrationBannerModal } from './SFSMigrationBannerModal';
import { type GetSfsMigrationResponse } from '../../../common/models/migration-status';
import { useEmployeeCardQuery } from '../../hooks/useEmployeeCardQuery';
import { useSFSMigration } from '../../hooks/useSFSMigration';
import { useSfsLoadsQuery } from '../../hooks/useSfsLoadsQuery';
import { useSubscriptionsQuery } from '../../hooks/useSubscriptionsQuery';
import { useTasksQuery } from '../../hooks/useTasksQuery';
import { useTasksSummary } from '../../hooks/useTasksSummary';

export const SFSMigrationBanner = withErrorBoundary({
  team: 'sfs-migration',
  scope: 'sfs-migration::banner',
  // eslint-disable-next-line sonarjs/cognitive-complexity
})(() => {
  const [isBannerVisible, setIsBannerVisible] = useLocalStorage(
    'isSFSMigrationBannerClosed',
    true,
  );

  const { t } = useTranslation('sfs-migration');
  const dateFormatter = useDateFormatter();
  const companyId = useCompanyId();
  const user = useUser();
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const sfsMigrationQuery = useSFSMigration({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const sfsTasksSummaryQueryState = useTasksSummary({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const sfsTasksQueryState = useTasksQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const useSfsLoadsQueryState = useSfsLoadsQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const isWebviewActive = urlParams.has('webview');
  const sfsMigration = unwrapQuery(sfsMigrationQuery);
  const sfsTasksSummary = unwrapQuery(sfsTasksSummaryQueryState);
  const sfsTasks = unwrapQuery(sfsTasksQueryState);
  const sfsLoads = unwrapQuery(useSfsLoadsQueryState);

  const customerGroup = sfsMigration?.customerGroup ?? 'EEA';
  const isUserAnAdmin = user.is_admin || user.is_account_owner;

  const employeeSFSCardQuery = useEmployeeCardQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const subscriptionsQuery = useSubscriptionsQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const subscriptions = unwrapQuery(subscriptionsQuery);
  const employeeSFSCard = unwrapQuery(employeeSFSCardQuery);

  const physicalCardActivationStatus =
    getPhysicalCardActivationStatus(employeeSFSCard);

  const subscriptionMigrationStatus =
    getSubscriptionsMigrationStatus(subscriptions);

  const hasOpenTask =
    physicalCardActivationStatus.status === 'todo' ||
    subscriptionMigrationStatus.status === 'todo' ||
    subscriptionMigrationStatus.status === 'inProgress';

  if (!hasTMPSFSMigrationPage) {
    return null;
  }

  if (isWebviewActive) {
    return null;
  }

  const shouldCheckSfsLoads =
    sfsMigrationQuery.status === 'success' &&
    sfsMigration?.status !== 'InProgress' &&
    isUserAnAdmin;
  if (
    sfsTasksSummaryQueryState.status !== 'success' ||
    sfsMigrationQuery.status !== 'success' ||
    sfsTasksQueryState.status !== 'success' ||
    (shouldCheckSfsLoads && useSfsLoadsQueryState.status !== 'success')
  ) {
    return null;
  }

  if (sfsMigration?.status === 'Closing' && !isUserAnAdmin && !hasOpenTask) {
    return null;
  }

  if (sfsMigration?.status === 'Closing' && !isUserAnAdmin && hasOpenTask) {
    return (
      <div
        className={classNames(
          styles.banner,
          'relative overflow-hidden title-l',
        )}
      >
        {t('appDesktopBanner.titles.requester')}
        <Button
          className={classNames('ml-m')}
          variant="contrasted"
          text={t('appDesktopBanner.ctas.requester')}
          component="a"
          href={`/free-upgrade/${companyId}/overview/`}
          target="_blank"
          onClick={() => {
            track(AnalyticEventName.SFS_MIGRATION_APP_BANNER_BUTTON_CLICKED);
          }}
          rel="noopener noreferrer"
        />
      </div>
    );
  }

  if (sfsMigration?.status === 'Closing' && (isBannerVisible || hasOpenTask)) {
    const redirectUrl =
      isUserAnAdmin === true
        ? `/free-upgrade/${companyId}/migration-closing`
        : `/free-upgrade/${companyId}/overview`;

    return (
      <div className={classNames('title-l', styles.banner, styles.isClosing)}>
        <div>
          {customerGroup === 'UK'
            ? t('appDesktopBanner.titles.closingUkCustomerGroup')
            : t('appDesktopBanner.titles.closing')}
        </div>
        <Button
          className="ml-m"
          variant="contrasted"
          text={t('appDesktopBanner.ctas.nextSteps')}
          href={redirectUrl}
          target="_blank"
          rel="noopener noreferrer"
          component="a"
        />
        <IconButton
          key="close"
          className="absolute right-0 mr-s"
          variant="borderless"
          iconColor="white"
          onClick={() => setIsBannerVisible(false)}
          iconName="cross"
          aria-label={t('kycActionNeeded.actions.close')}
        />
      </div>
    );
  }

  // If the UK client migration is in the 'WaitingForFunds' state and there are no loads, means we don't expect any user action, hence we don't display the banner
  if (
    sfsMigrationQuery.data.customerGroup === 'UK' &&
    shouldCheckSfsLoads &&
    sfsLoads?.length !== 0
  ) {
    return null;
  }
  const shouldDisplayAttentionModal = !(
    sfsMigrationQuery.data.customerGroup === 'UK' &&
    shouldCheckSfsLoads &&
    sfsLoads?.length !== 0
  );

  const companiesWithUncompletedTasks = sfsTasksSummary?.data.companies.filter(
    (company) => company.uncompletedTaskCount > 0,
  );
  const companyIdsWithUncompletedTasks =
    companiesWithUncompletedTasks?.map((company) => company.companyId) || [];

  if (!sfsMigration?.banner) {
    return null;
  }

  const bannerContent = getBannerContent(
    sfsMigration.banner,
    user,
    t,
    dateFormatter,
    customerGroup,
  );
  if (!bannerContent) {
    return null;
  }

  const ongoingStepUrlSegment =
    stepNameToUrlpart[sfsMigration.banner.activePhase];

  const kycTaskCount = (sfsTasks?.data?.migrationInitiation?.tasks ?? []).some(
    ({ type, status }) => type === 'ReviewKyc' && status === 'InProgress',
  )
    ? 1
    : 0;

  const isLate = bannerContent.deadlineStatus !== 'OK';
  const hasUncompleteTasks =
    sfsMigration.banner.uncompletedTaskCount - kycTaskCount > 0;

  return (
    <>
      {shouldDisplayAttentionModal && (
        <SFSMigrationBannerModal
          isLate={isLate}
          hasUncompleteTasks={hasUncompleteTasks}
          taskCount={sfsMigration.banner.uncompletedTaskCount}
          ctaLink={getCtalink({
            hasTasksInOtherEntities:
              sfsMigration.banner.hasTasksInOtherEntities,
            companyId,
            ongoingStepUrlSegment,
            companyIdsWithUncompletedTasks,
          })}
          customerGroup={customerGroup}
        />
      )}
      <div
        className={classNames(
          styles.banner,
          'relative overflow-hidden title-l',
          {
            [styles.isLate]: isLate,
          },
        )}
      >
        {isLate && (
          <SVGEffectCircle
            className={classNames(styles.circle, {
              [styles.isChachaSliding]: hasUncompleteTasks,
            })}
          />
        )}
        {bannerContent.title}
        {bannerContent.dateLabel && (
          <>
            <div className={styles.separator} />
            <div
              className={classNames(styles.deadLine, 'text-page-background')}
            >
              <Icon name="calendar" />
              <div className="mr-xxs">{bannerContent.dateLabel}</div>
            </div>
          </>
        )}
        <Button
          className={classNames('ml-m', {
            [styles.isWiggling]: isLate && hasUncompleteTasks,
          })}
          variant="contrasted"
          text={bannerContent.ctaLabel}
          component="a"
          href={getCtalink({
            hasTasksInOtherEntities:
              sfsMigration.banner.hasTasksInOtherEntities,
            companyId,
            ongoingStepUrlSegment,
            companyIdsWithUncompletedTasks,
          })}
          target="_blank"
          onClick={() => {
            track(AnalyticEventName.SFS_MIGRATION_APP_BANNER_BUTTON_CLICKED);
          }}
          rel="noopener noreferrer"
        />
      </div>
    </>
  );
});

const getCtalink = ({
  hasTasksInOtherEntities,
  companyId,
  ongoingStepUrlSegment,
  companyIdsWithUncompletedTasks,
}: {
  hasTasksInOtherEntities: boolean;
  companyId: string;
  companyIdsWithUncompletedTasks: string[];
  ongoingStepUrlSegment: string;
}) => {
  const isCurrentCompanyHasTasks =
    companyIdsWithUncompletedTasks.includes(companyId);
  if (
    hasTasksInOtherEntities &&
    !isCurrentCompanyHasTasks &&
    companyIdsWithUncompletedTasks.length > 0
  ) {
    const firstCompanyIdWithUncompletedTasks =
      companyIdsWithUncompletedTasks[0];
    return `/free-upgrade/${firstCompanyIdWithUncompletedTasks}/overview/${ongoingStepUrlSegment}`;
  }
  return `/free-upgrade/${companyId}/overview/${ongoingStepUrlSegment}`;
};

type DeadlineStatus = 'OK' | 'SOON_TO_BE_LATE' | 'LATE';

const getDeadlineStatus = (startDate: Date, deadline: Date): DeadlineStatus => {
  const diffDays = differenceInDays(deadline, new Date());
  const halfPeriod = differenceInDays(deadline, startDate) / 2;

  if (diffDays < 0) {
    return 'LATE';
  }
  if (diffDays < halfPeriod) {
    return 'SOON_TO_BE_LATE';
  }
  return 'OK';
};

const getBannerContent = (
  banner: NonNullable<GetSfsMigrationResponse['banner']>,
  user: User,
  t: TSFSMigrationFunctionTyped,
  dateFormatter: DateFormatter,
  customerGroup: GetSfsMigrationResponse['customerGroup'],
):
  | {
      title: string;
      dateLabel?: string;
      deadlineStatus?: DeadlineStatus;
      ctaLabel: string;
    }
  | undefined => {
  const {
    activePhase,
    uncompletedTaskCount,
    completedTaskCount,
    hasTasksInOtherEntities,
    deadline,
    phaseStartDate,
  } = banner;
  const tParams = { count: uncompletedTaskCount };

  // No more remaining tasks to complete
  if (uncompletedTaskCount === 0 && !hasTasksInOtherEntities) {
    return undefined;
  }
  if (!user.is_account_owner && !user.is_admin) {
    // Users just with roles requesters or controllers
    if (activePhase === 'migrationExecution') {
      // These users have card actions to do only during the execution phase
      return {
        title: t('appDesktopBanner.titles.requester'),
        ctaLabel: t('appDesktopBanner.ctas.requester'),
        deadlineStatus: 'OK',
      };
    }
    return undefined;
  }

  if (uncompletedTaskCount > 0 && deadline && phaseStartDate) {
    const parsedDeadline = parseISO(deadline);
    const parsedPhaseStartDate = parseISO(phaseStartDate);
    const deadlineStatus = getDeadlineStatus(
      parsedPhaseStartDate,
      parsedDeadline,
    );
    const hasNotStarted =
      activePhase === 'migrationInitiation' && completedTaskCount === 0;
    const { title, ctaLabel } = getBannerWordings(
      deadlineStatus,
      hasNotStarted,
      t,
      tParams,
      customerGroup,
    );
    return {
      title,
      deadlineStatus,
      dateLabel: getDateLabel(parsedDeadline, deadlineStatus, dateFormatter, t),
      ctaLabel,
    };
  }

  if (hasTasksInOtherEntities && deadline) {
    const parsedDeadline = parseISO(deadline);
    return {
      title:
        customerGroup === 'UK'
          ? t(
              'appDesktopBanner.titles.otherEntitiesToCompleteUkCustomerGroup',
              tParams,
            )
          : t('appDesktopBanner.titles.otherEntitiesToComplete', tParams),
      dateLabel: getDateLabel(parsedDeadline, 'OK', dateFormatter, t),
      ctaLabel: t('appDesktopBanner.ctas.otherEntitiesToComplete', tParams),
    };
  }

  return undefined;
};

const getBannerWordings = (
  deadlineStatus: DeadlineStatus,
  hasNotStarted: boolean,
  t: TSFSMigrationFunctionTyped,
  tParams: Record<string, unknown>,
  customerGroup: GetSfsMigrationResponse['customerGroup'],
  // eslint-disable-next-line sonarjs/cognitive-complexity
): { title: string; ctaLabel: string } => {
  const appDesktopBannerTitlesMain =
    customerGroup === 'UK'
      ? t('appDesktopBanner.titles.mainUkCustomerGroup', tParams)
      : t('appDesktopBanner.titles.main', tParams);
  const appDesktopBannerTitlesStarted =
    customerGroup === 'UK'
      ? t('appDesktopBanner.titles.startedUkCustomerGroup', tParams)
      : t('appDesktopBanner.titles.started', tParams);
  const appDesktopBannerTitlesSoonToBeLate =
    customerGroup === 'UK'
      ? t('appDesktopBanner.titles.soonToBeLateUkCustomerGroup', tParams)
      : t('appDesktopBanner.titles.soonToBeLate', tParams);
  const appDesktopBannerTitlesSoonToBeLateTasksLeft =
    customerGroup === 'UK'
      ? t(
          'appDesktopBanner.titles.soonToBeLateTasksLeftUkCustomerGroup',
          tParams,
        )
      : t('appDesktopBanner.titles.soonToBeLateTasksLeft', tParams);
  const appDesktopBannerTitlesLate =
    customerGroup === 'UK'
      ? t('appDesktopBanner.titles.lateUkCustomerGroup', tParams)
      : t('appDesktopBanner.titles.late', tParams);
  const appDesktopBannerTitlesLateTasksLeft =
    customerGroup === 'UK'
      ? t('appDesktopBanner.titles.lateTasksLeftUkCustomerGroup', tParams)
      : t('appDesktopBanner.titles.lateTasksLeft', tParams);
  const appDesktopBannerCtasMain =
    customerGroup === 'UK'
      ? t('appDesktopBanner.ctas.mainUkCustomerGroup', tParams)
      : t('appDesktopBanner.ctas.main', tParams);

  let title = hasNotStarted
    ? appDesktopBannerTitlesMain
    : appDesktopBannerTitlesStarted;
  let ctaLabel = hasNotStarted
    ? appDesktopBannerCtasMain
    : t('appDesktopBanner.ctas.complete', tParams);

  if (deadlineStatus === 'SOON_TO_BE_LATE') {
    title = hasNotStarted
      ? appDesktopBannerTitlesSoonToBeLate
      : appDesktopBannerTitlesSoonToBeLateTasksLeft;
    ctaLabel = hasNotStarted
      ? appDesktopBannerCtasMain
      : t('appDesktopBanner.ctas.complete', tParams);
  } else if (deadlineStatus === 'LATE') {
    title = hasNotStarted
      ? appDesktopBannerTitlesLate
      : appDesktopBannerTitlesLateTasksLeft;
    ctaLabel = hasNotStarted
      ? appDesktopBannerCtasMain
      : t('appDesktopBanner.ctas.complete', tParams);
  }

  return { title, ctaLabel };
};

const getDateLabel = (
  deadline: Date,
  deadlineStatus: DeadlineStatus,
  dateFormatter: DateFormatter,
  t: TSFSMigrationFunctionTyped,
) => {
  const count = Math.abs(differenceInDays(deadline, new Date()));

  switch (deadlineStatus) {
    case 'LATE':
      return t('appDesktopBanner.dates.late', { count });
    case 'SOON_TO_BE_LATE':
      return t('appDesktopBanner.dates.soonToBeLate', { count });
    default:
      return t('appDesktopBanner.dates.main', {
        date: dateFormatter(deadline, DATE_FORMAT.CUSTOM, {
          day: 'numeric',
          month: 'short',
        }),
      });
  }
};

const SVGEffectCircle = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="725"
    height="729"
    viewBox="0 0 725 729"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_639_854)">
      <g opacity="0.5" filter="url(#filter0_f_639_854)">
        <ellipse cx="362.5" cy="364.5" rx="174.5" ry="176.5" fill="#F1C0B3" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_639_854"
        x="0"
        y="0"
        width="725"
        height="729"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="94"
          result="effect1_foregroundBlur_639_854"
        />
      </filter>
      <clipPath id="clip0_639_854">
        <rect width="725" height="729" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
