import { type ApproverType } from '../../company/structure/approval-flows';
import { type UserDetail } from '../components/ProgressBoxApproversList/useUsersByIdQuery';

export type ApprovalStep = {
  approverId: string;
  actingApproverId: string;
  state: ApprovalStepState;
  approverType: ApproverType;
  event?: {
    appraisedAt: string;
    reason: null | string;
    type: 'approval' | 'denial';
  };
  as: 'approver' | 'account_owner';
};

export type ApprovalStepState =
  | 'in_review'
  | 'upcoming'
  | 'approved'
  | 'denied'
  | 'pending';

export type ApprovalRuleState =
  | 'in_review'
  | 'pending'
  | 'approved'
  | 'denied'
  | 'obsolete';

export type ApprovalRule = {
  state: ApprovalRuleState;
  steps: ApprovalStep[];
  from: {
    value: number | null;
  };
  upTo: {
    value: number | null;
  };
  isAoRule: boolean;
};

export type Approver = {
  id: string;
  avatar: string | undefined;
  name: string;
  email: string;
};

export type ApproversListItem<T> = {
  approver: T;
  state: ApprovalRuleState;
};

export type ListType = 'queue' | 'any';

export const getListType = <T>(rules: { steps: T[] }[]): ListType => {
  // we assume that if the first rule has more than one step, the list approval flow is set as 'approve one after another'
  if (rules[0].steps.length > 1) {
    return 'queue';
  }
  return 'any';
};

export const extractNonLegacyRequestApprovers = (approvalState?: {
  rules: {
    state: ApprovalRuleState;
    steps: { actingApproverId: string; approverType: ApproverType }[];
  }[];
  state: ApprovalRuleState;
}) => {
  if (!approvalState || !approvalState.rules) {
    return [];
  }

  return approvalState.rules
    .filter((rule) => rule.state === approvalState.state)
    .flatMap((rule) => {
      return rule.steps.map((step) => ({
        approverType: step.approverType,
        id: step.actingApproverId,
      }));
    });
};

export const getApproversWithApproverType = (
  requestApprovers: { id: string; approverType: ApproverType }[],
  users: UserDetail[],
): (UserDetail & { approverType: ApproverType })[] => {
  return requestApprovers.map((approver) => {
    const user = users.find((u) => u.id === approver.id);
    if (!user) {
      return {
        id: approver.id,
        approverType: approver.approverType,
        avatar: '',
        email: '',
        fullname: '',
      };
    }
    return {
      ...user,
      approverType: approver.approverType,
    };
  });
};
