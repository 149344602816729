import { Redirect, Route, Switch } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import type { NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep';
import ExportsContainer from 'src/core/modules/bookkeep/settings/export/ExportsContainer';
import { IntegrationsAccountingSetupPage } from 'src/core/modules/bookkeep/settings/integrations/pages/IntegrationsAccountingSetupPage/IntegrationsAccountingSetupPage';
import { BookkeepingStartDateSectionContainer } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/BookkeepingStartDateSection';
import { CustomFieldsContainer } from 'src/core/modules/custom-fields';

export const SettingsAccountingPage = () => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const company = useCompany();
  const user = useUser();
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);
  const isAccountingExportsSettingsEnabled = useFeature(
    FEATURES.SETTINGS_ACCOUNTING_EXPORTS,
  );
  const isAccountingSettingsEnabled = useFeature(FEATURES.SETTINGS_ACCOUNTING);

  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();
  const hasIntegration =
    accountingIntegrationStatusQueryResult.status === 'success' &&
    accountingIntegrationStatusQueryResult.data.integration !== 'noIntegration';

  const links: NavigationLinkProps[] = [];

  const isAoOrController = user.is_account_owner || user.is_controller;

  if (isAccountingSettingsEnabled && isAoOrController) {
    links.push({
      key: 'accounting-integration',
      path: routeFor(routes.COMPANY_ACCOUNTING_INTEGRATION.path, {
        company: companyId,
      }),
      text: t('bookkeep.integrations.settings.sectionAccountingSoftware'),
    });

    links.push({
      key: 'bookkeeping-date',
      path: routeFor(routes.COMPANY_ACCOUNTING_BOOKKEEPING_DATE.path, {
        company: companyId,
      }),
      text: t('bookkeep.integrations.settings.sectionBookkeepingDate'),
    });
  }

  if (
    isAccountingExportsSettingsEnabled &&
    hasIntegration &&
    !company.churning_at &&
    isAoOrController
  ) {
    links.push({
      key: 'exports',
      path: routeFor(routes.COMPANY_EXPORTS.path, {
        company: companyId,
      }),
      text: t('settings.links.exports'),
    });
  }

  if (hasCustomFieldsFeature && (isAoOrController || user.is_admin)) {
    links.push({
      key: 'custom-fields',
      path: routeFor(routes.COMPANY_CATEGORIES.path, {
        company: companyId,
      }),
      text: t('settings.links.customFields'),
    });
  }

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.accounting')}
      </HeaderWithNavigation>
      <div className="grow bg-page-background p-3xl">
        <div className="flex max-w-[760px] flex-col gap-xxl">
          <Switch>
            <Route exact path={routes.COMPANY_ACCOUNTING_INTEGRATION.path}>
              <IntegrationsAccountingSetupPage />
            </Route>
            <Route path={routes.COMPANY_ACCOUNTING_BOOKKEEPING_DATE.path}>
              <BookkeepingStartDateSectionContainer />
            </Route>
            <Route path={routes.COMPANY_CATEGORIES.path}>
              <CustomFieldsContainer />
            </Route>
            <Route path={routes.COMPANY_EXPORTS.path}>
              <ExportsContainer />
            </Route>
            <Redirect
              from={routes.COMPANY_ACCOUNTING.path}
              to={routes.COMPANY_ACCOUNTING_INTEGRATION.path}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};
