import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { type QueryState } from 'src/core/api/queryState';
import { getSelf as getUser } from 'src/core/selectors/users';
import { getEligibleCustomFields } from 'src/core/utils/custom-fields';

import { useCustomFieldsQuery } from '../../hooks/useCustomFieldsQuery';
import { type CustomFieldDefinition, type User } from '../../models';
import { type CustomField } from '../models';

type CustomFieldsRequestError = {
  errorCode: string;
};

export const useEligibleCustomFields = (
  teamId?: string,
): QueryState<CustomField[], CustomFieldsRequestError> => {
  const user: User = useSelector(getUser);
  const customFieldsQueryState = useCustomFieldsQuery();

  return useMemo(
    () =>
      customFieldsQueryState.status === 'success'
        ? {
            ...customFieldsQueryState,
            data: reshapeToEligibleCustomFields(
              customFieldsQueryState.data,
              user,
              teamId,
            ),
          }
        : customFieldsQueryState,
    [customFieldsQueryState, teamId, user],
  );
};

const reshapeToEligibleCustomFields = (
  customFieldDefinitions: CustomFieldDefinition[],
  user: User,
  teamId?: string,
): CustomField[] =>
  getEligibleCustomFields(customFieldDefinitions, {
    types: ['request'],
    teamIds: teamId ? [teamId] : [],
    user,
  });
