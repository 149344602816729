import { z } from 'zod';

export const getScheduledWireTransfersAmountSchema = z.object({
  scheduledAmount: z.number(),
  currency: z.string(),
});

export const getScheduledWireTransfersAmountQueryParameters = z.object({
  fromDate: z.string().optional(),
  toDate: z.string().optional(),
});
