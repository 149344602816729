import { z } from 'zod';

export const BadRequestError = {
  400: z.any(),
};
export const ForbiddenError = {
  403: z.object({
    errorCode: z.enum([
      'noCompanyIdProvided',
      'noAccessToMigration',
      'userNotAllowed',
    ]),
  }),
};
export const NotFoundError = {
  404: z.any(),
};
export const ServerError = {
  500: z.any(),
};
