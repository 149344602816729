import Fuse from 'fuse.js';

import { type OptionRoute } from './useCreateRoutes';

const LIMIT = 8;

export const filterRoutes = (
  routesToSearch: OptionRoute[],
  searchTerm: string | undefined,
): OptionRoute[] => {
  if (!searchTerm) {
    return routesToSearch.slice(0, LIMIT);
  }

  const fuse = new Fuse(routesToSearch, {
    keys: ['label', 'category', 'url', 'hiddenTags'],
    threshold: 0.1,
    location: 0,
    distance: 2900,
    useExtendedSearch: true,
    ignoreLocation: true,
    minMatchCharLength: 1,
  });
  return fuse.search(searchTerm, { limit: LIMIT }).map((result) => {
    return result.item;
  });
};
