import { fromNumber } from 'ezmoney';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type TimelineEvent } from '../../../../models';

type RequestTimelineEventProps = {
  timelineEvent: TimelineEvent;
};

export const RequestTimelineEvent = ({
  timelineEvent,
}: RequestTimelineEventProps) => {
  const { t } = useTranslation('global');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, actionType, user } = timelineEvent;
  const userFullName = user.fullName ?? '';

  switch (timelineEvent.actionType) {
    case 'request.create': {
      const { amountDeclared, currencyDeclared } = timelineEvent.data;
      const amount = fromNumber(amountDeclared, currencyDeclared, 2);

      return (
        <>
          <div>
            {t('requests.timeline.events.submittedBy', { user: userFullName })}
          </div>
          <div>
            {t('requests.timeline.events.amountRequested', {
              amount: formatMonetaryValue(amount),
            })}
          </div>
        </>
      );
    }

    case 'request.approve': {
      const { amountApproved, currency } = timelineEvent.data;
      const amount = fromNumber(amountApproved, currency, 2);

      return (
        <>
          <div>
            {t('requests.timeline.events.approvedBy', { user: userFullName })}
          </div>
          <div>
            {t('requests.timeline.events.amountApproved', {
              amount: formatMonetaryValue(amount),
            })}
          </div>
        </>
      );
    }

    case 'request.deny': {
      return (
        <div>
          {t('requests.timeline.events.deniedBy', { user: userFullName })}
        </div>
      );
    }

    case 'request.reject_by_controller': {
      return (
        <div>
          {t('requests.timeline.events.deniedByController', {
            user: userFullName,
          })}
        </div>
      );
    }

    case 'request.validate_by_controller': {
      return (
        <div>
          {t('requests.timeline.events.validatedByController', {
            user: userFullName,
          })}
        </div>
      );
    }

    default: {
      return null;
    }
  }
};
