import { type AnyAction, createReducer } from '@reduxjs/toolkit';

import * as types from './actionTypes';

type ProfileLegacyState = {
  userApprovalData: {
    isApprover?: boolean;
    isDelegate?: boolean;
    isDelegatingTo?: string;
  };
  slackProviderSettings?: {
    clientId: string;
    authorize: string;
    url: string;
  };
  behavior: {
    isUserApprovalDataLoading: boolean;
    errors: string | null;
  };
};

const initialState: ProfileLegacyState = {
  userApprovalData: {},
  behavior: {
    isUserApprovalDataLoading: false,
    errors: null,
  },
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.FETCH_SLACK_PROVIDER_SETTINGS_LOADING, (state) => {
    state.slackProviderSettings = undefined;
  });
  builder.addCase(
    types.FETCH_SLACK_PROVIDER_SETTINGS_SUCCESS,
    (state, action: AnyAction) => {
      state.slackProviderSettings = action.payload.slackProviderSettings;
    },
  );
  builder.addCase(types.FETCH_SLACK_PROVIDER_SETTINGS_FAILURE, (state) => {
    state.slackProviderSettings = undefined;
  });
  builder.addCase(types.FETCH_USER_APPROVAL_DATA_LOADING, (state) => {
    state.behavior.isUserApprovalDataLoading = true;
  });
  builder.addCase(
    types.FETCH_USER_APPROVAL_DATA_FAILURE,
    (state, action: AnyAction) => {
      state.userApprovalData = {};
      state.behavior.isUserApprovalDataLoading = false;
      state.behavior.errors = action.error;
    },
  );
  builder.addCase(
    types.FETCH_USER_APPROVAL_DATA_SUCCESS,
    (state, action: AnyAction) => {
      state.userApprovalData = action.payload;
      state.behavior.isUserApprovalDataLoading = false;
    },
  );
});
