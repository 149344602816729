import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';

export type ProgressSteps =
  | 'interrupted'
  | 'approval'
  | 'payment'
  | 'receipt'
  | 'finance-review';

type ProgressStep = {
  progressStep: ProgressSteps;
};

const progressStepQueryKey = (requestId: string) => [
  'requests',
  { requestId },
  'progress-step',
];

export const useProgressStepQuery = (requestId: string) => {
  return useQuery<ProgressStep>({
    key: progressStepQueryKey(requestId),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}/progress-step`,
    },
  });
};

export const useInvalidateProgressStepQuery = () => {
  const queryClient = useQueryClient();
  return (requestId: string) =>
    queryClient.invalidateQueries(progressStepQueryKey(requestId));
};
