import { type CustomField } from '../containers/PayablePanelContainer';

export type ExpenseCategoryValue = {
  id: string;
  name: string;
};

export const getExpenseCategoryValueFromCustomField = (
  customFields: CustomField[],
  designatedCustomFieldId: string,
): ExpenseCategoryValue | undefined => {
  const expenseCategoryAsCustomField = customFields.find(
    (customField) => customField.id === designatedCustomFieldId,
  );
  if (
    expenseCategoryAsCustomField?.type === 'list' &&
    expenseCategoryAsCustomField.values.length > 0
  ) {
    return {
      id: expenseCategoryAsCustomField.values[0].id,
      name: expenseCategoryAsCustomField.values[0].name,
    };
  }
};
