import cx from 'classnames';
import React, { type ReactNode } from 'react';

import './Card.css';

type Props = {
  header: ReactNode;
  children: ReactNode;
  className?: string;
};

const Card = ({ header, children, className }: Props) => (
  <div className={cx('Card', className)}>
    <div className="Card__header body-m ">{header}</div>
    <div className="Card__body">{children}</div>
  </div>
);

export default Card;
