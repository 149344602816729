import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { COUNTRIES } from 'src/core/config/country';

import { type SupplierResponse } from '../detail/hooks/useSupplierQuery';

export type SupplierId = string;
export interface Supplier {
  id: string;
  name: string;
  thumbnailUrl: string;
}

export const formatSupplierAddress = ({
  t,
  supplier,
}: {
  t: TGlobalFunctionTyped;
  supplier?: SupplierResponse;
}) => {
  if (!supplier) {
    return [''];
  }
  const address = supplier.supplier_details?.address ?? supplier.address ?? '';
  const city = supplier.supplier_details?.city;

  const country = supplier.supplier_details?.country
    ? t(
        // @ts-expect-error countries is not in global translation type
        COUNTRIES[supplier.supplier_details.country]?.translationKey,
      )
    : '';
  const zipcode = supplier.supplier_details?.zipcode;
  return [
    supplier.name,
    address,
    `${zipcode ? `${zipcode} ` : ''}${city ?? ''}${zipcode || city ? ', ' : ''}${country}`,
  ].filter(Boolean);
};
