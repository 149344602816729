import { Modal, Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useGoToCompanyWalletFunding } from 'modules/company';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  company: {
    id: string;
    is_kyb_validated: boolean;
  };
  onContinue: () => void;
};

export const AchSuccessModal = ({ onContinue, company }: Props) => {
  const { t } = useTranslation();
  const goToCompanyWalletFunding = useGoToCompanyWalletFunding();
  const clickHandler = () => {
    onContinue();
    goToCompanyWalletFunding();
  };
  return (
    <Modal
      actions={[
        // eslint-disable-next-line react/jsx-key
        <Button
          type="button"
          variant="primary"
          text={t('ach.achSuccessCTA')}
          onClick={clickHandler}
        />,
      ]}
      iconName="bank"
      title={t('ach.achSuccessTitle')}
      subtitle={t(
        // eslint-disable-next-line sonarjs/no-all-duplicated-branches
        company.is_kyb_validated ? 'ach.achSuccessDesc' : 'ach.achSuccessDesc',
      )}
      iconVariant="success"
      isOpen
      onClose={onContinue}
    />
  );
};
