import { z } from 'zod';

export const userSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  defaultCostCenter: z.string().nullable(),
  avatar: z.string().nullable(),
});
