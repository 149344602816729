import { type MonetaryValue } from 'ezmoney';

type AmountDiscount = {
  type: 'amount';
  value: MonetaryValue;
};

type PerUnitDiscount = {
  type: 'per_unit';
  value: MonetaryValue;
};

type PercentageDiscount = {
  type: 'percentage';
  ratio: number;
};

type Discount = AmountDiscount | PerUnitDiscount | PercentageDiscount;

type Tier = {
  base: MonetaryValue;
  factor: MonetaryValue;
  floor: number;
};

type Usage = { [unit: string]: Tier[] };

type Pricing = {
  constant: MonetaryValue;
  discounts: Discount[];
  usage: Usage;
  totalAmount: MonetaryValue;
};

enum FusebillAddon {
  Ereceipts = 'ereceipts',
  SupplementaryWallets = 'supplementary_wallets',
  SamlSso = 'saml_sso',
  CardInsurance = 'card_insurance',
  EreceiptUsers = 'ereceipt_users',
  IncludedExpenses = 'included_expenses',
}

export type FusebillSubscription = {
  name: string;
  status: string;
  schedule: string;
  responsibleCompany: { id: string; name: string };
  plan: { code: string; name: string };
  price: Pricing;
  addons: { [addon in FusebillAddon]?: Pricing };
  version: 'v1' | 'v2';
};

type AddonSlug =
  | 'samlSso'
  | 'cardInsurance'
  | 'supplementaryWallets'
  | 'includedExpenses'
  | 'extraExpenses';

export type Addon = {
  amount: MonetaryValue;
  quantity: number;
  slug: AddonSlug;
  pricing: {
    price: MonetaryValue;
  };
};

export const getAddonBySlug = (
  addons: Addon[],
  slug: AddonSlug,
): Addon | undefined => {
  return addons.find((addon) => addon.slug === slug);
};

export const getAddonsBySlug = (
  addons: Addon[],
): {
  supplementaryWalletsAddon: Addon | undefined;
  samlSsoAddon: Addon | undefined;
  cardInsuranceAddon: Addon | undefined;
  includedExpenses: Addon | undefined;
  extraExpenses: Addon | undefined;
} => {
  const supplementaryWalletsAddon = getAddonBySlug(
    addons,
    'supplementaryWallets',
  );
  const samlSsoAddon = getAddonBySlug(addons, 'samlSso');
  const cardInsuranceAddon = getAddonBySlug(addons, 'cardInsurance');
  const includedExpenses = getAddonBySlug(addons, 'includedExpenses');
  const extraExpenses = getAddonBySlug(addons, 'extraExpenses');

  return {
    supplementaryWalletsAddon,
    samlSsoAddon,
    cardInsuranceAddon,
    includedExpenses,
    extraExpenses,
  };
};

type PlanSlug =
  | 'free'
  | 'starter'
  | 'essentials'
  | 'growth'
  | 'scale'
  | 'scale-2023'
  | 'subsidiary'
  | 'enterprise'
  | 'premium'
  | 'freemium';

export type ChargebeeSubscription = {
  chargebeeSubscriptionId: string;
  addons: Addon[];
  amount: MonetaryValue;
  plan: {
    slug: PlanSlug;
  };
  parentCompanyName?: string;
  coupons?: string[];
  entitiesCount?: {
    activeEntities: number;
    availableEntities: number;
    isAllowedToOpenEntity: boolean;
  };
};

export type CompanySubscription = FusebillSubscription | ChargebeeSubscription;

export const isChargebeeSubscription = (
  subscription: CompanySubscription | undefined,
): subscription is ChargebeeSubscription => {
  if (!subscription) {
    return false;
  }

  return (
    'chargebeeSubscriptionId' in subscription &&
    typeof subscription.chargebeeSubscriptionId === 'string'
  );
};

export const getCompanyPlanTranslationKey = (plan: PlanSlug): string => {
  switch (plan) {
    case 'starter':
      return 'billing.companyPlan.plans.starter';
    case 'essentials':
      return 'billing.companyPlan.plans.essentials';
    case 'growth':
      return 'billing.companyPlan.plans.growth';
    case 'scale':
      return 'billing.companyPlan.plans.scale';
    case 'scale-2023':
      return 'billing.companyPlan.plans.scale';
    case 'subsidiary':
      return 'billing.companyPlan.plans.subsidiary';
    case 'free':
      return 'billing.companyPlan.plans.free';
    case 'enterprise':
      return 'billing.companyPlan.plans.enterprise';
    case 'premium':
      return 'billing.companyPlan.plans.premium';
    case 'freemium':
      return 'billing.companyPlan.plans.freemium';
    default:
      return 'misc.unknown';
  }
};
