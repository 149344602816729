/* eslint-disable unicorn/no-await-expression-member */
import React from 'react';

import { EmptyStateError, lazyLoad } from "src/core/common/components/LazyLoad";
import Loader from "src/core/common/components/legacy/Loader/Loader";

export const LazyLoadedSignature = lazyLoad({
  loader: async () => ({
    default: (await import(/* webpackChunkName: "Signature" */ './Signature'))
      .Signature,
  }),
  loading: <Loader />,
  fallback: (props) => <EmptyStateError {...props} />,
});
