import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useUpdateKomboSettings } from 'src/core/modules/integrations/hooks';
import {
  type SettingsPreviewParams,
  useGetKomboSettingsPreviewQuery,
  useInvalidateGetKomboSettingsPreviewQuery,
} from 'src/core/modules/integrations/hooks/useGetKomboSettingsPreviewQuery';
import type {
  SupportedKomboFilterDimension,
  FiltersForDimension,
  KomboAvailableFilterByDimension,
} from 'src/core/modules/integrations/types';

import { Preview } from './Preview';
import { PreviewSkeleton } from './PreviewSkeleton';

type Props = {
  currentFilters: FiltersForDimension[] | undefined;
  automaticArchiving: boolean;
  syncReportingManagers: boolean;
  closeModal: () => void;
  isOpen: boolean;
  availableFiltersByDimension: KomboAvailableFilterByDimension;
};

const formattedDimensionName = (
  dimension: SupportedKomboFilterDimension,
): I18nKey => {
  switch (dimension) {
    case 'work_location':
      return 'integration.kombo.settings.filters.dimensions.workLocation.label';
    case 'legal_entity':
      return 'integration.kombo.settings.filters.dimensions.legalEntity.label';
    case 'cost_center':
      return 'integration.kombo.settings.filters.dimensions.costCenter.label';
    case 'department':
      return 'integration.kombo.settings.filters.dimensions.department.label';
    case 'team':
      return 'integration.kombo.settings.filters.dimensions.team.label';
    default:
      return dimension as I18nKey;
  }
};

const buildPreviewParams = (
  currentFilters: FiltersForDimension[] | undefined,
  automaticArchiving: boolean,
  syncReportingManagers: boolean,
): SettingsPreviewParams => {
  let params: SettingsPreviewParams = {
    // eslint-disable-next-line camelcase
    automatic_archiving: automaticArchiving,
    // eslint-disable-next-line camelcase
    sync_reporting_manager: syncReportingManagers,
  };
  Array.from(currentFilters || []).forEach(({ dimension, values }) => {
    params = { ...params, [dimension]: values };
  });

  return params;
};

export const SettingsModal = ({
  currentFilters,
  automaticArchiving,
  syncReportingManagers,
  closeModal,
  isOpen,
  availableFiltersByDimension,
}: Props) => {
  const { t } = useTranslation('global');

  const params = buildPreviewParams(
    currentFilters,
    automaticArchiving,
    syncReportingManagers,
  );
  const invalidateKomboSettingsPreviewQuery =
    useInvalidateGetKomboSettingsPreviewQuery();
  const [hasUnmatchedFilters, setHasUnmatchedFilters] =
    useState<boolean>(false);
  const [hasZeroTotalEmployees, setHasZeroTotalEmployees] =
    useState<boolean>(false);

  const settingsPreviewQuery = useGetKomboSettingsPreviewQuery(
    params,
    (data) => {
      setHasUnmatchedFilters(data.hasUnmatchedFilters);
      setHasZeroTotalEmployees(data.total === 0);
    },
  );

  const hasReportingManagerFeature = useFeature(
    FEATURES.TMP_CONMAN_REPORTING_MANAGERS,
  );

  const [updateKomboSettings] = useUpdateKomboSettings();

  const renderActionButtons = () => {
    if (settingsPreviewQuery.status === 'error') {
      return [
        <Button
          key="cancel"
          className="w-full"
          text={t('integration.kombo.settings.settingsModal.cancel')}
          variant="secondary"
          onClick={closeModal}
        />,
        <Button
          key="retry"
          className="w-full"
          text={t('integration.kombo.settings.settingsModal.retry')}
          variant="primary"
          onClick={() => {
            invalidateKomboSettingsPreviewQuery(params);
          }}
        />,
      ];
    }
    if (hasUnmatchedFilters) {
      return [
        <Button
          key="cancel"
          className="w-full"
          text={t('integration.kombo.settings.settingsModal.return')}
          variant="primary"
          onClick={() => {
            setHasUnmatchedFilters(false);
            closeModal();
          }}
        />,
      ];
    }
    if (hasZeroTotalEmployees) {
      return [
        <Button
          key="cancel"
          className="w-full"
          text={t('integration.kombo.settings.settingsModal.adjust')}
          variant="primary"
          onClick={() => {
            setHasZeroTotalEmployees(false);
            closeModal();
          }}
        />,
      ];
    }

    return [
      <Button
        key="cancel"
        className="w-full"
        text={t('integration.kombo.settings.settingsModal.cancel')}
        variant="secondary"
        onClick={closeModal}
      />,
      <Button
        key="sync"
        className="w-full"
        text={t('integration.kombo.settings.settingsModal.sync')}
        variant="primary"
        isDisabled={settingsPreviewQuery.status === 'loading'}
        onClick={async () => {
          await updateKomboSettings({
            companySettings: {
              automaticArchiving,
              syncReportingManagers: currentFilters
                ? syncReportingManagers
                : hasReportingManagerFeature,
              conditions: Array.from(currentFilters || []).map(
                ({ dimension, values }) => ({
                  fieldName: dimension,
                  fieldValues: values,
                }),
              ),
            },
          });
          closeModal();
        }}
      />,
    ];
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="eye"
      iconVariant="primary"
      title={t('integration.kombo.settings.settingsModal.title')}
      subtitle={t('integration.kombo.settings.settingsModal.description')}
      onClose={closeModal}
      actions={renderActionButtons()}
    >
      <div className="flex flex-col gap-m">
        <div>
          <div className="h-m text-left font-medium text-neutral-darker">
            {t('integration.kombo.settings.settingsModal.conditions.title')}
          </div>

          <div className="flex flex-col gap-s rounded-xxs bg-neutral-lightest p-s text-left text-neutral-darker">
            <div>
              {currentFilters ? (
                currentFilters.map(({ dimension, values }) => (
                  <div className="body-m" key={dimension}>
                    <Trans
                      i18nKey="integration.kombo.settings.settingsModal.conditions.filtered"
                      values={{
                        dimensionName: t(formattedDimensionName(dimension)),
                      }}
                      components={{
                        strong: <strong className="font-medium">-</strong>,
                      }}
                    />
                    {values.map((value, index) => {
                      const availableFilters =
                        availableFiltersByDimension.get(dimension) || [];
                      const displayName =
                        availableFilters.find(
                          (filter) => filter.komboId === value,
                        )?.komboName || value;
                      if (index > 0) {
                        return (
                          <React.Fragment key={displayName}>
                            {` ${t('misc.or')} `}
                            <strong className="font-medium">
                              {displayName}
                            </strong>
                          </React.Fragment>
                        );
                      }
                      return (
                        <strong key={displayName} className="font-medium">
                          {displayName}
                        </strong>
                      );
                    })}
                    .
                  </div>
                ))
              ) : (
                <>
                  {t('integration.kombo.settings.settingsModal.conditions.all')}
                </>
              )}
              {syncReportingManagers && (
                <div>
                  <Trans
                    i18nKey="integration.kombo.settings.settingsModal.conditions.syncReportingManagers"
                    components={{
                      strong: <strong className="font-medium">-</strong>,
                    }}
                  />
                </div>
              )}
            </div>
            {automaticArchiving && (
              <div>
                {t(
                  'integration.kombo.settings.settingsModal.conditions.automaticArchiving',
                )}
              </div>
            )}
          </div>
        </div>
        <QuerySuspense
          queryState={settingsPreviewQuery}
          loading={<PreviewSkeleton showArchivedTab={automaticArchiving} />}
          fallback={() => (
            <Callout
              title={t('integration.kombo.settings.settingsModal.error.title')}
              variant="alert"
            >
              {t('integration.kombo.settings.settingsModal.error.description')}
            </Callout>
          )}
        >
          {(settingsPreviewResult) =>
            hasUnmatchedFilters ? (
              <Callout
                title={t(
                  'integration.kombo.settings.settingsModal.filterError.title',
                )}
                variant="alert"
              >
                {t(
                  'integration.kombo.settings.settingsModal.filterError.description',
                )}
              </Callout>
            ) : (
              <Preview settingsPreviewResult={settingsPreviewResult} />
            )
          }
        </QuerySuspense>
      </div>
    </Modal>
  );
};
