import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { ColumnEditModal } from './ColumnEditModal';
import { type FormValues } from './form';

type CustomContentOption = {
  id: string;
  name: string;
};

type ParentCustomContentOption = CustomContentOption & {
  subItems: CustomContentOption[];
};

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  customContent?: string[] | null;
  customContentOptions: (ParentCustomContentOption | CustomContentOption)[];
  isDeletable: boolean;
  getCustomContentValueLabel: (value: string) => string;
  isStaticCustomContentValue: (value: string) => boolean;
  getCustomContentPreview?: (customContent: string[]) => string;
  onRemoveColumn: () => void;
  onClose: () => void;
  onSaveColumn: (values: FormValues) => void;
};

export const ColumnEditModalContainer = ({
  title,
  customContent = null,
  onSaveColumn,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<FormValues>({
    initialValues: {
      customContent: customContent || null,
      title,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.title.trim()) {
        errors.title = t('exports.emptyColumnTitleError');
      }

      return errors;
    },
    onSubmit: (values) => {
      onSaveColumn({
        title: values.title,
        customContent: values.customContent,
      });
    },
  });
  const isCustomContent = customContent !== null;

  return (
    <ColumnEditModal
      title={title}
      isCustomContent={isCustomContent}
      {...rest}
      {...formikProps}
    />
  );
};
