import { type FormikProps } from 'formik';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';

import { type MemberEditFormikValues } from '../../components/MemberEditModal/validate';
import { MemberEditTabRoles } from '../../components/MemberEditTabRoles/MemberEditTabRoles';
import { type MemberDetails } from '../../models/member';
import { type Role } from '../../models/roles';

type Props = {
  member: MemberDetails;
  roles: Role[];
  formikProps: FormikProps<MemberEditFormikValues>;
};

export const MemberEditTabRolesContainer = ({
  member,
  roles,
  formikProps,
}: Props) => {
  // retrieve global data from Redux store
  const currentUser = useUser();

  const isRoleEditable = (role: Role) => {
    if (member.isAccountOwner) {
      return false;
    }

    // Super admins can edit all roles
    if (currentUser.is_account_owner) {
      return true;
    }

    // Admins can't assign or retract the "Admin" role to other users
    return !(currentUser.is_admin && role.name === 'Administrator');
  };

  return (
    <MemberEditTabRoles
      member={member}
      formikProps={formikProps}
      roles={roles
        .filter((role) => !role.fullPermissions)
        .map((role) => ({
          ...role,
          isEditable: isRoleEditable(role),
          checked:
            member.isAccountOwner ||
            formikProps.values.roleIds.includes(role.id),
        }))}
    />
  );
};
