import { Button } from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';

import { ProgressBox } from 'common/components/ProgressBox';
import { QueryError } from 'common/components/QueryError';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { RequestDenier } from 'modules/approval';

import { useArchiveRequest, useGotoRequests } from '../../../../hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ProgressSteps } from '../../../../hooks/useProgressStepQuery';
import {
  getFinanceReviewRejectionReason,
  getManagerRejectionReason,
} from '../../../../models';
import { type LegacyRequest } from '../../../../types';

type Props = {
  className?: string;
  request: LegacyRequest;
};

export const PerDiemRequestInterruptedProgressBox = ({
  className,
  request,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const gotoRequests = useGotoRequests();
  const [archiveRequest, archiveRequestQueryState] = useArchiveRequest(
    request.id,
    {
      onSuccess: () => {
        pushNotif({
          type: NotificationType.Success,
          message: t('requests.progressBox.actions.archive.success'),
        });
        gotoRequests();
      },
    },
  );
  const { title, content } = getProgressBoxContentFromRequest(request, { t });

  return (
    <>
      <ProgressBox
        title={title}
        iconName="failure"
        content={
          <>
            {content}
            {request.state !== 'cancelled' && (
              <div className="mt-s">
                <Button
                  fit="parent"
                  variant="contrasted"
                  text={t('requests.progressBox.actions.archive.cta')}
                  onClick={() => archiveRequest()}
                />
              </div>
            )}
          </>
        }
        className={className}
        variant="alert"
      />
      {archiveRequestQueryState.status === 'error' && (
        <QueryError
          queryError={archiveRequestQueryState.error}
          componentType="Notification"
          translations={{
            serverError: 'requests.progressBox.actions.archive.error',
          }}
          logContext={{
            team: 'capture',
          }}
        />
      )}
    </>
  );
};

const getProgressBoxContentFromRequest = (
  request: LegacyRequest,
  { t }: { t: TGlobalFunctionTyped },
): {
  title: ReactNode;
  content: ReactNode;
} => {
  if (request.state === 'cancelled') {
    return {
      title: t('requests.progressBox.perDiem.cancelled.title'),
      content: <div>{t('requests.progressBox.perDiem.cancelled.content')}</div>,
    };
  }

  const financeReviewRejectionReason = getFinanceReviewRejectionReason(request);
  const managerRejectionReason = getManagerRejectionReason(request);

  return {
    title: managerRejectionReason
      ? t('requests.progressBox.perDiem.approvalFailedStep.title')
      : t('requests.progressBox.perDiem.financeReviewFailedStep.title'),
    content: (
      <div>
        <div>
          {managerRejectionReason
            ? t('requests.progressBox.perDiem.approvalFailedStep.content')
            : t('requests.progressBox.perDiem.financeReviewFailedStep.content')}
        </div>
        {managerRejectionReason && (
          <>
            <div className="my-s">
              <RequestDenier requestId={request.id} />
            </div>
            <div className="mt-xs text-alert body-m">
              "{managerRejectionReason}"
            </div>
          </>
        )}
        {financeReviewRejectionReason && (
          <div className="mt-xs text-alert body-m">
            "{financeReviewRejectionReason}"
          </div>
        )}
      </div>
    ),
  };
};
