import {
  Table,
  Button,
  SwitchInput,
  SkeletonTable,
  SkeletonText,
  Callout,
  InfoTip,
} from '@dev-spendesk/grapes';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { type ExpenseAccount } from 'modules/bookkeep/settings/accounting';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { SearchFilter } from 'src/core/common/components/SearchFilter';
import FEATURES from 'src/core/constants/features';

import styles from './ExpenseAccountPullSection.module.css';
import { getExpenseAccountsDescriptionI18nKey } from './translations';

interface Props {
  search: string;
  expenseAccountQueryState: InfiniteQueryState<ExpenseAccount[]>;
  integration: AccountingSoftware;
  setSearch: (value: string) => void;
  handleToggleAvailabilityExpenseAccount: (
    expenseAccount: ExpenseAccount,
  ) => Promise<void>;
}

export const ExpenseAccountPullSection = ({
  expenseAccountQueryState,
  integration,
  search,
  setSearch,
  handleToggleAvailabilityExpenseAccount,
}: Props) => {
  const { t } = useTranslation('global');
  const hasHideAccountsFeature = useFeature(FEATURES.TMP_HIDE_EXPENSE_ACCOUNTS);

  const hasHideAccountsFeatureOrAvailableIntegration =
    hasHideAccountsFeature ||
    ['Xero', 'Netsuite', 'Sage100'].includes(integration);

  return (
    <div id="expense-accounts">
      <div className="flex items-center gap-xs">
        <div className="flex-grow">
          <h3 className="IntegrationAccountingPage__section-title title-xl">
            {t('bookkeep.integrations.settings.sectionExpenseAccounts')}
          </h3>
          <p className="IntegrationAccountingPage__section-description body-m">
            {t(getExpenseAccountsDescriptionI18nKey(integration))}
            {hasHideAccountsFeatureOrAvailableIntegration && (
              <InfoTip
                className="ml-xxs"
                content={t(
                  'bookkeep.integrations.settings.expenseAccountsDescriptionTooltip',
                )}
              />
            )}
          </p>
        </div>
        <div className={styles.headerSearchContainer}>
          <SearchFilter
            search={search}
            setSearch={(localSearch) => setSearch(localSearch)}
            placeholder={t(
              'bookkeep.integrations.settings.expenseAccountsTable.searchPlaceholder',
            )}
          />
        </div>
      </div>
      <QuerySuspense
        queryState={expenseAccountQueryState}
        loading={<ExpenseAccountsSkeletonTable />}
        fallback={() => <ExpenseAccountsErrorWarning />}
      >
        {(expenseAccounts) => (
          <Table
            rowHeight="compact"
            hasColumnSeparator
            data={expenseAccounts}
            getIsRowDisabled={(row) => {
              if (hasHideAccountsFeatureOrAvailableIntegration) {
                return !row.isAvailable;
              }
              return false;
            }}
            columns={[
              {
                id: 'expense-account-name',
                header: t(
                  'bookkeep.integrations.settings.expenseAccountsTable.accountName',
                ),
                renderCell(row) {
                  if (hasHideAccountsFeatureOrAvailableIntegration) {
                    return (
                      <div className={styles.CellWithSwitch}>
                        <SwitchInput
                          className={styles.CellWithSwitch__switch}
                          isChecked={!!row.isAvailable}
                          onChange={() => {
                            handleToggleAvailabilityExpenseAccount(row);
                          }}
                        />
                        <EllipsisTooltip text={row.name} />
                      </div>
                    );
                  }

                  return row.name;
                },
                width: '50%',
              },
              {
                id: 'expense-account-code',
                header: t(
                  'bookkeep.integrations.settings.expenseAccountsTable.accountCode',
                ),
                width: '50%',
                renderCell(row) {
                  return row.code;
                },
              },
            ]}
            footer={
              hasFooter(expenseAccountQueryState) ? (
                <TableFooter
                  expenseAccountQueryState={expenseAccountQueryState}
                />
              ) : undefined
            }
          />
        )}
      </QuerySuspense>
    </div>
  );
};

function hasFooter(
  expenseAccountQueryState: InfiniteQueryState<ExpenseAccount[]>,
) {
  return (
    expenseAccountQueryState.hasNextPage ||
    (expenseAccountQueryState.status === 'loading' &&
      !expenseAccountQueryState.isFetchingNextPage)
  );
}

const TableFooter = ({
  expenseAccountQueryState,
}: {
  expenseAccountQueryState: InfiniteQueryState<ExpenseAccount[]>;
}) => {
  const { t } = useTranslation('global');

  if (expenseAccountQueryState.fetchNextPage) {
    return (
      <Button
        text={t('misc.loadMore')}
        variant="secondary"
        isDisabled={Boolean(
          expenseAccountQueryState.status === 'loading' ||
            expenseAccountQueryState.isFetchingNextPage,
        )}
        onClick={expenseAccountQueryState.fetchNextPage}
      />
    );
  }

  if (
    expenseAccountQueryState.status === 'loading' &&
    !expenseAccountQueryState.isFetchingNextPage
  ) {
    return <>{t('misc.loading')}</>;
  }

  if (
    expenseAccountQueryState.status === 'success' &&
    expenseAccountQueryState.data.length === 0
  ) {
    return (
      <div>
        <h3 className="title-m">
          {t('bookkeep.integrations.settings.noExpenseAccountTitle')}
        </h3>
      </div>
    );
  }

  return null;
};

const ExpenseAccountsSkeletonTable = () => (
  <SkeletonTable
    numberOfRows={2}
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
    ]}
    withColumnSeparator
    withHeader
  />
);

const ExpenseAccountsErrorWarning = () => {
  const { t } = useTranslation('global');

  return (
    <Callout
      title={t('bookkeep.integrations.settings.errorLoading')}
      variant="alert"
    />
  );
};
