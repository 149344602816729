import { type PurchaseOrderRequestDetails } from './purchaseOrderRequestDetails';

interface ExpenseCategory {
  id: string;
  name: string;
}

export const getExpenseCategory = (
  request: PurchaseOrderRequestDetails,
  expenseCategoryCustomFieldId: string,
): ExpenseCategory | undefined => {
  const requestCustomFields = request.customFields ?? [];
  const expenseCategoryCustomFieldValue = requestCustomFields.find(
    ({ field }) => field.id === expenseCategoryCustomFieldId,
  )?.value;
  return expenseCategoryCustomFieldValue
    ? {
        id: expenseCategoryCustomFieldValue.id,
        name: expenseCategoryCustomFieldValue.value,
      }
    : undefined;
};
