import * as Money from 'ezmoney';

import { type CurrenciesKey } from 'src/core/config/money';

import { type TransfersFromLegacyWalletResponse } from '../hooks/use-transfers-from-legacy-wallet-query';

export type TransfersFromLegacyWallet =
  TransfersFromLegacyWalletResponse['data']['transfers'];

export type FormattedTransfersFromLegacyWallet = {
  pendingTransfers: TransfersFromLegacyWallet;
  pendingTransfersTotal: Money.MonetaryValue;
  failedTransfers: TransfersFromLegacyWallet;
  reconciledTransfers: TransfersFromLegacyWallet;
};

export const TRANSFER_LIMIT = Money.fromNumber(500_000, 'EUR');
export const UK_TRANSFER_LIMIT = Money.fromNumber(300_000, 'GBP');

export function getTransferLimit(currency: CurrenciesKey) {
  return currency === 'GBP' ? UK_TRANSFER_LIMIT : TRANSFER_LIMIT;
}
