/* eslint-disable promise/prefer-await-to-then */
import { createAction } from '@reduxjs/toolkit';
import includes from 'lodash/includes';
import noop from 'lodash/noop';

import { companyAPI } from 'src/core/api/axios';
import { apiUrl } from 'src/core/utils/api';

import * as types from './actionTypes';

// Save a payment's VAT settings
const savePaymentVatLoading = createAction(types.SAVE_PAYMENT_VAT_LOADING);
const savePaymentVatFailure = createAction(types.SAVE_PAYMENT_VAT_FAILURE);
const savePaymentVatSuccess = createAction(types.SAVE_PAYMENT_VAT_SUCCESS);
export const savePaymentVat =
  (payment, rates = [], callback = noop) =>
  (dispatch, getState) => {
    const companyId = getState().global.company.id;

    dispatch(savePaymentVatLoading());

    return companyAPI
      .post(`/payments/${payment.id}/vat`, rates, { companyId })
      .then((response) => response.data)
      .then((paymentVat) => {
        if (paymentVat) {
          callback(paymentVat);
          return dispatch(savePaymentVatSuccess(paymentVat));
        }
        callback();
        return dispatch(savePaymentVatFailure());
      });
  };

// Save a payment's VAT settings
const savePaymentVatTypeLoading = createAction(
  types.SAVE_PAYMENT_VAT_TYPE_LOADING,
);
const savePaymentVatTypeFailure = createAction(
  types.SAVE_PAYMENT_VAT_TYPE_FAILURE,
);
const savePaymentVatTypeSuccess = createAction(
  types.SAVE_PAYMENT_VAT_TYPE_SUCCESS,
);
export const savePaymentVatType =
  (payment, vat_type) => (dispatch, getState) => {
    const companyId = getState().global.company.id;
    if (!includes(['liable', 'not_liable', 'reverse_charge'], vat_type)) {
      return null;
    }

    dispatch(savePaymentVatTypeLoading());

    return fetch(apiUrl(`/payments/${payment.id}/vat`, companyId), {
      method: 'PATCH',
      body: JSON.stringify({ vat_type }),
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      credentials: 'include',
    })
      .then((res) => {
        if (res.status !== 204) {
          throw new Error('Failed to save VAT type');
        }
        return res.json();
      })
      .then(() => dispatch(savePaymentVatTypeSuccess()))
      .catch(() => dispatch(savePaymentVatTypeFailure()));
  };
