import startOfDay from 'date-fns/startOfDay';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import replace from 'lodash/replace';
import queryString from 'query-string';

import { type Filters, type SortFilter, type GroupFilter } from '../../models';

const isBooleanUrlCustomFieldValues = (values: string[]): boolean =>
  values.every((value) => value === 'true' || value === 'false');

const FIELD_FILTER_PREFIX = 'f_';
const CUSTOM_FIELD_FILTER_PREFIX = 'cf_';

const parseSortFilter = (string_: string | string[]): SortFilter => {
  const sortFilter = Array.isArray(string_) ? string_.at(-1) : string_;
  switch (sortFilter) {
    case 'smart':
    case 'natural':
      return sortFilter as SortFilter;
    default:
      throw new Error('Unhandled sort filter');
  }
};

const parseGroupFilter = (string_: string | string[]): GroupFilter => {
  const groupFilter = Array.isArray(string_) ? string_.at(-1) : string_;
  switch (string_) {
    case 'expenseType':
    case 'supplier':
      return groupFilter as GroupFilter;
    default:
      throw new Error('Unhandled group option');
  }
};

const parseSearch = (string_: string | string[]): string => {
  return Array.isArray(string_) ? string_[0] || '' : string_;
};

export const urlQueryStringToFilters = (
  initialFilters: Filters,
  urlQueryString: string,
  // eslint-disable-next-line sonarjs/cognitive-complexity
): Filters => {
  const filters = { ...initialFilters };

  const queryParams = queryString.parse(urlQueryString, {
    arrayFormat: 'index',
  });

  if (queryParams.sort) {
    filters.sort = parseSortFilter(queryParams.sort);
  }

  if (queryParams.group) {
    filters.group = parseGroupFilter(queryParams.group);
  }

  if (queryParams.search) {
    filters.search = parseSearch(queryParams.search);
  }

  if (queryParams.startDate && queryParams.endDate) {
    filters.period = {
      startDate: startOfDay(
        new Date(queryParams.startDate as string),
      ).toISOString(),
      endDate: startOfDay(
        new Date(queryParams.endDate as string),
      ).toISOString(),
    };
  }

  // Retrieve non-custom fields filters
  const fieldFilters = pickBy(queryParams, (_, key) =>
    key.startsWith(FIELD_FILTER_PREFIX),
  );
  if (fieldFilters) {
    forEach(fieldFilters, (values, key) => {
      if (!values) {
        return;
      }
      if (!Array.isArray(values)) {
        values = [values];
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const id: any /* TODO: fix TS */ = replace(key, FIELD_FILTER_PREFIX, '');

      if (
        [
          'supplier',
          'expenseAccount',
          'expenseType',
          'team',
          'payer',
          'costCenter',
          'vatAccount',
          'supplierAccount',
          'documentaryEvidenceStatus',
        ].includes(id)
      ) {
        filters.additional.push({
          id,
          type: 'List',
          values: values.reduce<string[]>(
            (accumulator, value) =>
              value ? [...accumulator, value] : accumulator,
            [],
          ),
        });
      }
    });
  }

  // Retrieve custom fields filters
  const customFieldFilters = pickBy(queryParams, (_, key) =>
    (key ?? '').startsWith(CUSTOM_FIELD_FILTER_PREFIX),
  );
  if (customFieldFilters) {
    forEach(customFieldFilters, (values, key) => {
      if (!values) {
        return;
      }
      if (!Array.isArray(values)) {
        values = [values];
      }

      const id = replace(key, CUSTOM_FIELD_FILTER_PREFIX, '');
      const isBooleanCustomField = isBooleanUrlCustomFieldValues(values);

      if (isBooleanCustomField) {
        filters.additional.push({
          id,
          type: 'CustomBoolean',
          values: values.reduce<string[]>(
            (accumulator, value) =>
              value ? [...accumulator, value] : accumulator,
            [],
          ),
        });
      } else {
        filters.additional.push({
          id,
          type: 'CustomList',
          values: values.reduce<string[]>(
            (accumulator, value) =>
              value ? [...accumulator, value] : accumulator,
            [],
          ),
        });
      }
    });
  }

  return filters;
};
