import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import merge from 'lodash/merge';

import { capitalize } from 'src/core/common/utils/string';

import { isAccountOwner, isGroupAdmin } from './userTyped';

const displayName = (user) => `${user.first_name} ${user.last_name}`;

const isOrganisationOwner = (user) => !!user.is_organisation_owner;

export const isAdmin = (user = {}, company = {}) => {
  return user?.data_by_company?.[company.id]?.is_admin;
};

export const isController = (user = {}, company = {}) => {
  return user?.data_by_company?.[company.id]?.is_controller;
};

export const isRequester = (user = {}, company = {}) => {
  return user?.data_by_company?.[company.id]?.is_requester;
};

const getSpendingPolicy = (user) => {
  const permission = user.legacy_spending_policy;
  return merge(
    {
      transaction_max: 0,
      spending_limit: 0,
    },
    permission ? permission.params : null,
  );
};

const getCompanyRoles = (user) =>
  map(
    // Can not work without spxEnrichUser adding the role property
    filter(user.roles, (role) =>
      includes(['company', 'organisation'], role.scope.entity_type),
    ),
  );

const getCompanyRolesNames = (user) => map(getCompanyRoles(user), 'name');

const isDeleted = (user) => !!user.deleted_at;

const hasSlackLinked = (user, company) => {
  const companyId = get(company, 'id');
  const companyData = user.data_by_company[companyId];
  return get(companyData, 'provider_integration_statuses.has_slack_linked');
};

const hasSlackEnabled = (user, company) => {
  const companyId = get(company, 'id');
  const companyData = user.data_by_company[companyId];
  return get(companyData, 'provider_integration_statuses.has_slack_enabled');
};

export const enrichUser = (user, company) =>
  merge(
    {},
    {
      display_name: displayName(user),
      is_organisation_owner: isOrganisationOwner(user),
      is_account_owner: isAccountOwner(user, company),
      is_admin: isAdmin(user, company),
      is_controller: isController(user, company),
      is_requester: isRequester(user, company),
      is_group_admin: isGroupAdmin(user, company),
      spending_policy: getSpendingPolicy(user),
      company_roles: getCompanyRolesNames(user),
      is_deleted: isDeleted(user),
      has_slack_linked: hasSlackLinked(user, company),
      has_slack_enabled: hasSlackEnabled(user, company),
    },
    user,
  );

// Temporary helpers to reproduce the old behaviour of the backbone user model
const getUserAttribute = (user, companyId, attribute) => {
  if (attribute in user) {
    return user[attribute];
  }
  if (attribute === 'data_by_company') {
    return {};
  }
  return user.data_by_company[companyId]?.[attribute];
};

const capitalizeAll = (name) => {
  return (name ?? '').split(' ').map(capitalize).join(' ');
};

// Temporary replacement for the backbone model user toJSON method
export const spxEnrichUser = (user, company) => {
  const policy = getUserAttribute(
    user,
    company.id,
    'legacy_spending_policy',
  )?.params;
  const roles = getUserAttribute(user, company.id, 'roles');
  const groups_admins = getUserAttribute(user, company.id, 'groups_admins');
  const is_group_admin =
    !!user.data_by_company?.[company.id]?.is_account_owner ||
    (groups_admins && groups_admins.length > 0);

  const fromBackbone = {
    display_name: `${capitalizeAll(user.first_name)} ${capitalizeAll(
      user.last_name,
    )}`,
    is_organisation_owner: getUserAttribute(
      user,
      company.id,
      'is_organisation_owner',
    ),
    is_account_owner: getUserAttribute(user, company.id, 'is_account_owner'),
    is_admin: getUserAttribute(user, company.id, 'is_admin'),
    is_controller: getUserAttribute(user, company.id, 'is_controller'),
    is_requester: getUserAttribute(user, company.id, 'is_requester'),
    is_group_admin: is_group_admin,
    spending_policy: {
      transaction_max: 0,
      spending_limit: 0,
      ...policy,
    },
    transaction_max: policy?.transaction_max ?? 0,
    spending_limit: policy?.spending_limit ?? 0,
    company_roles: roles
      ?.filter((role) =>
        ['company', 'organisation'].includes(role.scope.entity_type),
      )
      ?.map((role) => role.name),
    is_deleted: !!getUserAttribute(user, company.id, 'is_deleted'),
    is_delegating: getUserAttribute(user, company.id, 'is_delegating'),
    user_delegate: getUserAttribute(user, company.id, 'user_delegate'),
    monthly_outgoings: getUserAttribute(user, company.id, 'monthly_outgoings'),
    groups: getUserAttribute(user, company.id, 'groups'),
    groups_admins: groups_admins,
    nbPaymentsFromBeginning: getUserAttribute(
      user,
      company.id,
      'nbPaymentsFromBeginning',
    ),
    requests: getUserAttribute(user, company.id, 'requests'),
    is_mobile_needed: getUserAttribute(user, company.id, 'is_mobile_needed'),
  };

  return {
    ...user,
    ...fromBackbone,
  };
};
