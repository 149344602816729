import {
  Button,
  SkeletonAvatar,
  SkeletonButton,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import padlock from './padlock.svg';
import { useUserControlRuleQuery } from '../../hooks';

type Props = {
  onLearnMoreClick: () => void;
};

export const YourRulesInfo = ({ onLearnMoreClick }: Props) => {
  const controlRuleQueryState = useUserControlRuleQuery();
  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={controlRuleQueryState}
      loading={
        <div className="box relative bg-white p-m">
          <div className="flex items-start justify-between">
            <div>
              <div className="mb-xs">
                <SkeletonText />
              </div>
              <div className="mb-s">
                <SkeletonText />
                <SkeletonText />
              </div>
              <SkeletonButton />
            </div>
            <SkeletonAvatar size="l" />
          </div>
        </div>
      }
      fallback={(error) => (
        <QueryError componentType="Callout" queryError={error} />
      )}
    >
      {(controlRule) => (
        <div className="box relative bg-white p-m">
          <div className="flex items-start justify-between">
            <div>
              <div className="mb-xs title-l">
                {t('homepage.rulesFieldsAndReceipts.panel.title')}
              </div>
              <div className="mb-s text-neutral-darker body-l">
                {t('homepage.rulesFieldsAndReceipts.panel.description', {
                  count: controlRule.completionDeadline,
                })}
              </div>
              <Button
                text={t('homepage.rulesFieldsAndReceipts.panel.button')}
                variant="secondary"
                onClick={onLearnMoreClick}
              />
            </div>
            <img src={padlock} alt="" />
          </div>
        </div>
      )}
    </QuerySuspense>
  );
};
