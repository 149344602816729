import React, { useEffect, useState } from 'react';

import {
  type ConfirmationError as ErrorModalConfirmationError,
  WireTransferConfirmationErrorModal,
} from 'common/components/WireTransferModal/WireTransferConfirmationErrorModal';

import {
  TransferConfirmationModal,
  type TransferConfirmationModalProps,
} from './TransferConfirmationModal';
import { ActiveModalStates, useConfirmModal } from './useConfirmModal';
import { useMapErrorsToPayments } from './useMapErrorsToPayments';
import { type ConfirmationError } from '../../hooks/api/useConfirmTransfers';
import { TransferBatchLimitModal } from '../TransferBatchLimitModal';
import { MAX_PAYMENTS_PER_BATCH } from '../TransferBatchLimitModal/TransferBatchLimitModal';

type Props = Omit<
  TransferConfirmationModalProps,
  'onConfirmationPaymentsErrors'
>;

export const TransferConfirmationModalContainer = ({
  isOpen,
  transfers,
  onClose,
  onConfirm,
}: Props) => {
  const [paymentsInError, setPaymentsInError] = useState<
    ErrorModalConfirmationError[]
  >([]);

  const { activeModal, askConfirmation, showConfirmationErrors } =
    useConfirmModal();

  const [transfersToConfirm, setTransfersToConfirm] = useState(transfers);

  const mapErrorsToPayments = useMapErrorsToPayments();

  const onConfirmationPaymentsErrors = (payments: ConfirmationError[]) => {
    setPaymentsInError(mapErrorsToPayments(payments, transfers));
    showConfirmationErrors();
  };

  const onBatchLimitConfirmation = () => {
    setTransfersToConfirm(transfers.slice(0, MAX_PAYMENTS_PER_BATCH));
    askConfirmation(transfersToConfirm);
  };

  useEffect(() => {
    setTransfersToConfirm(transfers);
  }, [transfers]);

  useEffect(() => {
    if (isOpen) {
      askConfirmation(transfers);
    }
  }, [isOpen]);

  return (
    <>
      <TransferConfirmationModal
        transfers={transfersToConfirm}
        isOpen={activeModal === ActiveModalStates.confirmation && isOpen}
        onClose={onClose}
        onConfirm={onConfirm}
        onConfirmationPaymentsErrors={onConfirmationPaymentsErrors}
      />

      <WireTransferConfirmationErrorModal
        onCancel={() => {
          onClose();
        }}
        onRetry={() => {
          askConfirmation(transfersToConfirm);
        }}
        isOpen={activeModal === ActiveModalStates.confirmationErrors && isOpen}
        payments={paymentsInError}
      />

      <TransferBatchLimitModal
        isOpen={activeModal === ActiveModalStates.batchLimit && isOpen}
        onClose={onClose}
        onConfirm={onBatchLimitConfirmation}
      />
    </>
  );
};
