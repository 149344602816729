import { ModalSlideshow } from '@dev-spendesk/grapes';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';

import downloadApp1Source from './assets/downloadApp_1.svg';
import downloadApp2Source from './assets/downloadApp_2.webp';
import uploadReceipt2Source from './assets/upload_receipt_2.webp';
import { UploadReceiptSlideThree } from './slides/UploadReceiptSlideThree';
import { UploadReceiptSlideTwo } from './slides/UploadReceiptSlideTwo';
import {
  getLearnToApproveAssets,
  getUploadReceiptFirstAsset,
  getUploadReceiptThirdAsset,
} from './utils';
import { type ReadyToSpendTaskType } from '../../types';

type Props = {
  setModalType: (type: ReadyToSpendTaskType | null) => void;
  type: ReadyToSpendTaskType | null;
};

export const ReadyToSpendModalSlideshow = ({ setModalType, type }: Props) => {
  const history = useHistory();

  const { activeLanguage, t } = useTranslation('global');
  const companyId = useCompanyId();

  const slides = useMemo(() => {
    switch (type) {
      case 'download_app':
        return [
          {
            title: t('readyToSpend.slideshows.downloadApp.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.downloadApp.one.content" />
            ),
            illustration: <img src={downloadApp1Source} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.downloadApp.two.title'),
            content: (
              <div>{t('readyToSpend.slideshows.downloadApp.two.content')}</div>
            ),
            illustration: <img src={downloadApp2Source} alt="" />,
          },
        ];
      case 'learn_to_approve': {
        const assets = getLearnToApproveAssets(activeLanguage);
        return [
          {
            title: t('readyToSpend.slideshows.learnToApprove.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.learnToApprove.one.content" />
            ),
            illustration: <img src={assets[0]} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.learnToApprove.two.title'),
            content: (
              <div>
                {t('readyToSpend.slideshows.learnToApprove.two.content')}
              </div>
            ),
            illustration: <img src={assets[1]} alt="" />,
          },
        ];
      }
      case 'upload_receipt':
        return [
          {
            title: t('readyToSpend.slideshows.uploadReceipt.one.title'),
            content: (
              <Trans i18nKey="readyToSpend.slideshows.uploadReceipt.one.content" />
            ),
            illustration: (
              <img src={getUploadReceiptFirstAsset(activeLanguage)} alt="" />
            ),
          },
          {
            title: t('readyToSpend.slideshows.uploadReceipt.two.title'),
            content: <UploadReceiptSlideTwo />,
            illustration: <img src={uploadReceipt2Source} alt="" />,
          },
          {
            title: t('readyToSpend.slideshows.uploadReceipt.three.title'),
            content: <UploadReceiptSlideThree />,
            illustration: (
              <video loop muted autoPlay>
                <source
                  src={getUploadReceiptThirdAsset(activeLanguage)}
                  type="video/mp4"
                />
              </video>
            ),
          },
        ];
      default:
        return [];
    }
  }, [type]);

  const onClose = () => {
    setModalType(null);
  };

  const onDone = () => {
    // TODO(Growth): Once we have the endpoint,
    // get the task from useGetReadyToSpendTasksQuery whose type matches
    // and use its id to mark it as completed
    onClose();

    switch (type) {
      case 'learn_to_approve': {
        const allRequestsPath = routeFor(routes.REQUESTS.path, {
          company: companyId,
          type: 'all',
        });
        history.push(allRequestsPath);
        break;
      }
      default:
        break;
    }
  };

  if (slides.length === 0) {
    return null;
  }

  return (
    <ModalSlideshow
      isOpen={!!type}
      slides={slides}
      translations={{
        cancel: t('misc.cancel'),
        previous: t('misc.back'),
        next: t('misc.next'),
        done: t('misc.gotIt'),
      }}
      onDone={onDone}
      onClose={onClose}
      onCancel={onClose}
    />
  );
};
