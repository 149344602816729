import { useMatches } from 'react-router-dom-6';

import { type StepName } from './useTasksQuery';

export const isStepName = (value: string): value is StepName =>
  ['migrationInitiation', 'migrationActivation', 'migrationExecution'].includes(
    value,
  );

const getCurrentStep = (matches?: { id: string }[]) => {
  if (!matches || !Array.isArray(matches)) {
    return '';
  }
  return matches.at(-1)?.id ?? '';
};

export const useMigrationStep = (): StepName => {
  const matches = useMatches();
  const currentStep = getCurrentStep(matches);
  const stepName = urlPartToStepName[currentStep];
  return isStepName(stepName) ? stepName : 'migrationInitiation';
};

const urlPartToStepName: Record<
  string,
  | 'migrationInitiation'
  | 'migrationActivation'
  | 'migrationExecution'
  | 'migrationClosing'
> = {
  'setup-for-success': 'migrationInitiation',
  'fuel-your-new-wallet': 'migrationActivation',
  'follow-your-progress': 'migrationExecution',
  'migration-closing': 'migrationClosing',
};

export const stepNameToUrlpart: Record<
  | 'migrationInitiation'
  | 'migrationActivation'
  | 'migrationExecution'
  | 'migrationClosing',
  string
> = {
  migrationInitiation: 'setup-for-success',
  migrationActivation: 'fuel-your-new-wallet',
  migrationExecution: 'follow-your-progress',
  migrationClosing: 'migration-closing',
};
