import { Banner, Tag } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import illustration from './AnalyticalSplitDiscoveryBanner.svg';
import { useIsAnalyticalSplitAvailable } from '../../apis/analytical-split/useAnalyticalSplitAvailability';

export const AnalyticalSplitDiscoveryBanner = () => {
  const { t } = useTranslation('global');

  const isAnalyticalSplitAvailable = useIsAnalyticalSplitAvailable();

  return isAnalyticalSplitAvailable ? (
    <Banner
      title={
        <>
          {t('bookkeep.analyticalSplitDiscovery.title')}
          <Tag variant="primary" className="ml-xs">
            {t('misc.new')}
          </Tag>
        </>
      }
      variant="primary"
      className="mt-l"
      illustration={<img alt="" src={illustration} />}
      actionText={t('misc.learnMore')}
      onClick={() => {
        window.open(
          t(
            'bookkeep.integrations.settings.analyticalSplit.readHelpCenterArticleUrl',
          ),
          '_blank',
        );
      }}
    >
      {t('bookkeep.analyticalSplitDiscovery.content')}
    </Banner>
  ) : null;
};
