import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { type PayableAmortisationScheme } from 'modules/accounting-integration/models';
import { PayableAmortisationField } from 'modules/payable/components';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ExpenseCategoryValue } from '../../../../models/expenseCategory';
import { type Payable, type CostCenter } from '../../../PayablePanelContainer';
import { type AccountingFormValues } from '../../hooks/usePayableAccountingEditForm';
import { PayableAccountingSectionCostCenter } from '../PayableAccountingSectionCostCenter';
import { PayableAccountingSectionExpenseCategory } from '../PayableAccountingSectionExpenseCategory';

type Props = {
  payable: Payable;
  costCenters: CostCenter[];
  expenseCategoryValues: ExpenseCategoryValue[];
  amortisationSchemes: PayableAmortisationScheme[];
  items: {
    label: string;
    value: string;
    type?: string;
    expenseCategoryValueId?: string;
    deleted?: boolean;
  }[];
};

export const PayableAccountingEditSection = ({
  payable,
  costCenters,
  expenseCategoryValues,
  amortisationSchemes,
  items,
  // formik props
  initialValues,
  values,
  errors,
  setFieldValue,
}: Props & FormikProps<AccountingFormValues>) => {
  const { t } = useTranslation('global');

  const withAmortisationScheme = items.some(
    (item) => item.type === 'amortisationScheme',
  );

  return (
    <div className="PayableDetailsEditSection">
      {items.map((item) => {
        const defaultField = (
          <div className="mb-s flex flex-col" key={item.label}>
            <FormField
              label={item.label}
              alertMessage={
                item.deleted ? t('payables.panel.deletedTooltip') : undefined
              }
            >
              <TextInput
                fit="parent"
                isInvalid={false}
                value={item.value}
                isDisabled
              />
            </FormField>
          </div>
        );

        switch (item.type) {
          case 'costCenter': {
            // Edition disabled for now for payables with split values
            if (payable.itemLines.length === 1) {
              return (
                <div className="mb-s flex flex-col" key={item.label}>
                  <PayableAccountingSectionCostCenter
                    costCenters={costCenters}
                    costCenter={values.costCenter}
                    onChange={(costCenter) => {
                      setFieldValue('costCenter', costCenter);
                    }}
                    error={errors.costCenter}
                  />
                </div>
              );
            }
            return defaultField;
          }

          case 'expenseCategory': {
            // Edition disabled for now for payables with split values
            if (payable.itemLines.length === 1) {
              return (
                <div className="mb-s flex flex-col" key={item.label}>
                  <PayableAccountingSectionExpenseCategory
                    expenseCategoryValues={expenseCategoryValues}
                    expenseCategoryValue={values.expenseCategoryValue}
                    onChange={(expenseCategory) => {
                      setFieldValue('expenseCategoryValue', expenseCategory);
                    }}
                    error={errors.expenseCategoryValue}
                  />
                </div>
              );
            }
            return defaultField;
          }

          case 'amortisationDate':
            return (
              <div className="mb-s flex flex-col" key={item.label}>
                <PayableAmortisationField
                  initialValue={initialValues.amortisation}
                  value={values.amortisation}
                  onChange={(value) => {
                    setFieldValue('amortisation', value);
                  }}
                  alertMessage={errors.amortisation}
                  withAmortisationScheme={withAmortisationScheme}
                  amortisationSchemes={amortisationSchemes}
                />
              </div>
            );

          case 'amortisationScheme':
            // Handled in the amortisation date field
            return;

          default:
            return defaultField;
        }
      })}
    </div>
  );
};
