import { getMonth, getQuarter } from 'date-fns';

export type PeriodType = 'month' | 'quarter' | 'annual';

export type PeriodRangePayload = {
  from: Date | undefined;
  to: Date | undefined;
};

export type PeriodRange =
  | {
      key: PeriodRangeKey;
    }
  | {
      key: 'custom';
      from: Date;
      to: Date;
    };

export type PeriodRangeKey =
  | 'currentMonth'
  | 'previousMonth'
  | 'currentQuarter'
  | 'previousQuarter'
  | 'overall';

export type Period = {
  type: PeriodType;
  index: number;
};

export const getPeriodType = (
  periodicity: 'monthly' | 'quarterly' | 'yearly',
): PeriodType => {
  switch (periodicity) {
    case 'monthly':
      return 'month';
    case 'quarterly':
      return 'quarter';
    default:
      return 'annual';
  }
};

export const getPeriodIndex = (
  date: Date,
  periodType: 'annual' | 'month' | 'quarter',
): number => {
  if (periodType === 'month') {
    return getMonth(date);
  }
  if (periodType === 'quarter') {
    return getQuarter(date) - 1;
  }
  return 0;
};
