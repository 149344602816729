import {
  DropdownMenu,
  DropdownItem,
  IconButton,
  SkeletonButton,
  Avatar,
  Button,
} from '@dev-spendesk/grapes';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useTranslation } from 'common/hooks/useTranslation';
import type { WalletTransactionForecast } from 'modules/company';
import { useCanConfirmBatch } from 'modules/invoices/transfer/hooks/useCanConfirmBatch';
import type { QueryState } from 'src/core/api/queryState';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { TransferListRowDueDate } from './TransferListRowDueDate';
import { type Transfer } from '../../../models';

import './TransferListRow.css';

type Props = {
  transfer: Transfer;
  canApproveTransfers: boolean;
  onConfirmTransfer: (transfer: Transfer) => void;
  onCancelTransfer: (transfer: Transfer) => void;
  getWalletTransactionForecastQueryForTransfer: () => QueryState<
    WalletTransactionForecast,
    unknown
  >;
};

export const TransferListRow = ({
  transfer,
  canApproveTransfers,
  getWalletTransactionForecastQueryForTransfer,
  onConfirmTransfer,
  onCancelTransfer,
}: Props) => {
  const { t } = useTranslation('global');
  const { counterparty: counterParty } = transfer.bill;
  const { invoiceNumber } = transfer.bill.documentaryEvidence;
  const canConfirmBatch = useCanConfirmBatch();

  const walletTransactionForecastQueryState =
    getWalletTransactionForecastQueryForTransfer();

  const handleConfirmTransfer = (): void => {
    if (
      walletTransactionForecastQueryState.status === 'success' &&
      walletTransactionForecastQueryState.data.hasEnoughFunds
    ) {
      return onConfirmTransfer(transfer);
    }

    // in case we could not assert if the wallet has enough funds or not, we don't block the confirm button and let the back end handle it
    if (walletTransactionForecastQueryState.status === 'error') {
      return onConfirmTransfer(transfer);
    }
  };

  const displayDropdown =
    canConfirmBatch || (!canConfirmBatch && !canApproveTransfers);

  return (
    <div className="TransferListRow">
      <div className="TransferListRow__counter-party">
        <Avatar
          variant="square"
          src={counterParty.logo}
          fallbackSrc={fallbackSupplierLogoSrc}
          text={counterParty.name}
        />
        <div className="TransferListRow__counter-party__details">
          <div className="TransferListRow__counter-party__name body-m">
            {counterParty.name}
          </div>
          {invoiceNumber && (
            <div className="TransferListRow__counter-party__invoice-number body-s">
              #{' '}
              <span className="TransferListRow__counter-party__invoice-number--highlight">
                {invoiceNumber}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="TransferListRow__details">
        <div className="TransferListRow__amount title-m">
          {formatMonetaryValue(transfer.amount)}
        </div>
        <TransferListRowDueDate transfer={transfer} />
      </div>

      <div
        className="TransferListRow__actions"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {displayDropdown ? (
          <DropdownMenu
            placement="bottom-start"
            className="PayableTypeFilter__dropdown"
            renderButton={(getToggleButtonProps) => (
              <IconButton
                {...getToggleButtonProps()}
                iconName="dots"
                variant="borderless"
                aria-label={t('misc.openMenu')}
              />
            )}
            options={[
              ...(canApproveTransfers
                ? [
                    {
                      key: 'confirm',
                      label: t('invoices.transfer.actions.confirmTransfer'),
                      isSelected: true,
                      onSelect: handleConfirmTransfer,
                    },
                  ]
                : []),
              {
                key: 'cancel',
                label: t('invoices.transfer.actions.cancelTransfer'),
                isSelected: false,
                onSelect: onCancelTransfer,
              },
            ]}
            onSelect={(option) => {
              option.onSelect(transfer);
            }}
            renderOption={(option) =>
              option.key === 'confirm' ? (
                <QuerySuspense
                  queryState={walletTransactionForecastQueryState}
                  loading={<SkeletonButton width="100%" />}
                  fallback={() => (
                    <DropdownItem
                      key={option.key}
                      label={option.label}
                      isSelected={option.isSelected}
                    />
                  )}
                >
                  {({ hasEnoughFunds }) => (
                    <DropdownItem
                      key={option.key}
                      label={option.label}
                      isSelected={option.isSelected && hasEnoughFunds}
                      isDisabled={!hasEnoughFunds}
                    />
                  )}
                </QuerySuspense>
              ) : (
                <DropdownItem
                  key={option.key}
                  label={option.label}
                  isSelected={option.isSelected}
                />
              )
            }
          />
        ) : (
          <Button
            variant="contrasted"
            text={t('invoices.transfer.actions.confirmLineTransfer')}
            onClick={handleConfirmTransfer}
          />
        )}
      </div>
    </div>
  );
};
