import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import {
  useCanConfirmBatch,
  useCanTransfersBeExecutedInTheFuture,
} from 'modules/invoices/transfer/hooks';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useIsCompanyOnSfsUk } from 'src/core/modules/company/hooks';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

type WireTransfersSummaryModalProps = {
  amount: MonetaryValue;
  count: number;
  isApproving: boolean;
  canApprove: boolean;
  showNotEnoughFundsWarning: boolean;
  isOpen: boolean;
  onApprove: () => void;
  onClose: () => void;
};

export const WireTransfersSummaryModal = ({
  amount,
  count,
  isApproving,
  canApprove,
  showNotEnoughFundsWarning,
  isOpen,
  onApprove,
  onClose,
}: WireTransfersSummaryModalProps) => {
  const { t } = useTranslation('global');
  const canTransfersBeExecutedInTheFuture =
    useCanTransfersBeExecutedInTheFuture();

  const isUkCompany = useIsCompanyOnSfsUk();
  const canConfirmBatch = useCanConfirmBatch();

  const approverDescription =
    isUkCompany && canConfirmBatch
      ? t(
          'submitMyInvoice.scheduled.wireTransfer.approverModalDescription_sfsuk',
          {
            count,
          },
        )
      : t('submitMyInvoice.scheduled.wireTransfer.approverModalDescription', {
          count,
        });
  const titleLabel = canApprove
    ? t('submitMyInvoice.scheduled.wireTransfer.approverModalTitle', {
        amount: formatMonetaryValue(amount),
      })
    : t('submitMyInvoice.scheduled.wireTransfer.controllerModalTitle', {
        amount: formatMonetaryValue(amount),
        count,
      });

  const descriptionLabel = canApprove
    ? approverDescription
    : t('submitMyInvoice.scheduled.wireTransfer.controllerModalDescription', {
        count,
      });

  const confirmActionLabel = canApprove
    ? t('submitMyInvoice.scheduled.wireTransfer.confirmTransfer', { count })
    : t('submitMyInvoice.scheduled.wireTransfer.sendToPayment');

  const cancelActionLabel = t('misc.cancel');

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={cancelActionLabel}
          variant="secondary"
          isDisabled={isApproving}
        />,
        <Button
          key="confirm"
          text={confirmActionLabel}
          variant="primary"
          onClick={onApprove}
          isDisabled={isApproving}
        />,
      ]}
      iconName="bell"
      iconVariant="primary"
      title={titleLabel}
      isOpen={isOpen}
    >
      {!canTransfersBeExecutedInTheFuture && canApprove && (
        <Callout
          title={t(
            'submitMyInvoice.scheduled.wireTransfer.immediateExecutionWarning',
          )}
          variant="warning"
          className="my-m"
        />
      )}
      <div>{descriptionLabel}</div>
      {showNotEnoughFundsWarning && !canApprove && (
        <Callout
          title={t(
            'submitMyInvoice.scheduled.wireTransfer.notEnoughFundsControllerWarning',
          )}
          variant="warning"
          className="my-m"
        />
      )}
    </Modal>
  );
};
