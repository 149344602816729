import { add, fromNumber, type MonetaryValue } from 'ezmoney';

import type { RequestAPI } from 'modules/requests';

import { type Bill, type SchedulingProcess } from '../../models';

export type Type = 'expense' | 'invoice';

export type TransferId = string;

export interface Transfer {
  id: TransferId;
  amount: MonetaryValue;
  scheduledForDate: string;
  bill: Bill;
}

export interface TransferDetails extends Transfer {
  schedulingProcess: SchedulingProcess;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeline: any;
  request: RequestAPI;
}

// Note: this function does not handle transfers with different currencies, as it's not a use case
export const sumTransfersAmount = (
  transfers: Transfer[],
  currency: string,
): MonetaryValue => {
  if (transfers.length === 0) {
    return fromNumber(0, currency, 2);
  }

  return transfers.reduce(
    (sum: MonetaryValue, transfer: Transfer): MonetaryValue =>
      add(sum, transfer.amount),
    fromNumber(0, transfers[0].amount.currency, 2),
  );
};
