import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { Breakdown } from '../../../models/breakdown';
import type { Period } from '../../../models/period';

export const useExpenseCategoryBreakdownQuery = (
  budgetId: string | undefined,
  expenseCategoryId: string | undefined,
  period: Period | undefined,
): QueryState<Breakdown> => {
  const params = {
    periodType: period?.type,
    periodIndex: period?.index,
  };

  return useQuery<Breakdown>({
    key: [
      'budget',
      budgetId,
      'expenseCategories',
      expenseCategoryId,
      'breakdown',
      params,
    ],
    isEnabled:
      Boolean(budgetId) && Boolean(expenseCategoryId) && Boolean(period),
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/budgets/budgets/${budgetId}/expense-categories/${expenseCategoryId}/breakdown`,
      params,
    },
    reshapeData: (data) => {
      return {
        available: data.available,
        used: data.used,
        committed: data.committed,
        usedExceeded: data.usedExceeded,
        committedExceeded: data.committedExceeded,
        breakdownComputedAt: new Date(data.breakdownComputedAt),
      };
    },
  });
};
