// import { useQuery } from 'src/core/api/hooks';
import { type QueryState } from 'src/core/api/queryState';

import { type ReadyToSpendTask } from '../types';

export const useGetReadyToSpendTasksQuery = (): QueryState<
  ReadyToSpendTask[]
> => {
  // Uncomment for local testing purposes
  // return {
  //   status: 'success',
  //   data: [
  //     { id: '1', type: 'subscription', status: 'completed' },
  //     { id: '2', type: 'single_use', status: 'completed' },
  //     { id: '3', type: 'expense_claim', status: 'completed' },
  //     { id: '4', type: 'upload_receipt', status: 'ongoing' },
  //     { id: '5', type: 'download_app', status: 'ongoing' },
  //   ],
  // };

  return {
    status: 'success',
    data: [],
  };

  // TODO(growth): Uncomment once the endpoint is implemented
  // return useQuery<ReadyToSpendTask[]>({
  // key: ['readyToSpendTasks'],
  // request: {
  //     type: 'rest',
  //     target: 'companyAPI',
  //     endpoint: `/ready-to-spend/tasks`,
  // },
  // reshapeData: (data) => data,
  // });
};
