import Config from "src/core/config";

interface Logger extends Console {}

type Options = {
  logger?: Logger;
  currency?: string;
};

export function logHiringMessage({
  logger = console,
  currency,
}: Options = {}): void {
  let brand = `
                                                $$\\                     $$\\
                                                $$ |                    $$ |
   $$$$$$$\\  $$$$$$\\   $$$$$$\\  $$$$$$$\\   $$$$$$$ | $$$$$$\\   $$$$$$$\\ $$ |  $$\\
  $$  _____|$$  __$$\\ $$  __$$\\ $$  __$$\\ $$  __$$ |$$  __$$\\ $$  _____|$$ | $$  |
  \\$$$$$$\\  $$ /  $$ |$$$$$$$$ |$$ |  $$ |$$ /  $$ |$$$$$$$$ |\\$$$$$$\\  $$$$$$  /
  \\____$$\\ $$ |   $$ |$$   ____|$$ |  $$ |$$ |  $$ |$$   ____| \\____$$\\ $$  _$$<
  $$$$$$$  |$$$$$$$  |\\$$$$$$$\\ $$ |  $$ |\\$$$$$$$ |\\$$$$$$$\\ $$$$$$$  |$$ | \\$$\\
  \\_______/ $$  ____/  \\_______|\\__|  \\__| \\_______| \\_______|\\_______/ \\__|  \\__|
            $$ |
            $$ |
            \\__|
  `;
  const brandStyle = 'color: #5D21D2;';

  switch (currency) {
    case 'EUR':
      brand = brand.replaceAll('$', '€');
      break;
    case 'GBP':
      brand = brand.replaceAll('$', '£');
      break;
    default:
      break;
  }

  const url = new URL('careers', Config.wwwBaseUrl);
  url.searchParams.append('utm_source', 'app');
  url.searchParams.append('utm_medium', 'devtools_console');

  const hiringTextStyle = 'font-family: sans-serif; font-size: 14px';
  const hiringText = `
    Looking at our code? 👀

    Come take a closer look, we're hiring!
    Check out our careers page and job openings at ${url.toString()}
  `;

  logger.log(`%c${brand}`, brandStyle);
  logger.log(`%c${hiringText}`, hiringTextStyle);
}
