import { Table } from '@dev-spendesk/grapes';
import React from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';
import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import { type ExpenseAccount } from 'src/core/modules/bookkeep/accounts-payable/types';

import { type ExpenseCategory } from '../../expenseCategory';
import { type ExpenseCategoryExpenseAccountRule } from '../../hooks/useGetExpenseCategoryExpenseAccountRulesLazyQuery';

import './EcContainer.css';

const getCostCentersString = (
  costCenterIds: string[],
  costCenters: CostCenter[],
  t: TGlobalFunctionTyped,
) => {
  const costCentersList = costCenterIds
    .map((costCenterId) => {
      return (
        // Case where the cost center list does not have one of the cost center defined in the expense category associated list
        costCenters.find((costCenter) => costCenter.id === costCenterId)
          ?.name ?? undefined
      );
    })
    .filter((name): name is string => name !== undefined);
  if (costCentersList.length > 0) {
    return <EllipsisTooltip text={costCentersList.join(', ')} />;
  }

  return t('expenseCategories.appliedOnAllCostCenters');
};

const getExpenseAccountsString = (
  expenseAccountIds: string[],
  expenseAccounts: ExpenseAccount[],
  t: TGlobalFunctionTyped,
) => {
  const names = expenseAccountIds
    .map((id) => {
      return (
        expenseAccounts.find((expenseAccount) => expenseAccount.id === id)
          ?.name ?? undefined
      );
    })
    .filter((name): name is string => name !== undefined);
  if (names.length > 0) {
    return <EllipsisTooltip text={names.join(', ')} />;
  }

  return t('expenseCategories.noLinkedExpenseAccount');
};

const getLinkedExpenseAccountsText = (
  rules: ExpenseCategoryExpenseAccountRule[],
  expenseCategoryId: string,
  expenseAccounts: ExpenseAccount[],
  t: TGlobalFunctionTyped,
) => {
  const rule = rules.find((r) => r.expenseCategoryId === expenseCategoryId);

  return getExpenseAccountsString(
    rule?.expenseAccountIds || [],
    expenseAccounts,
    t,
  );
};

type TableProps = {
  t: TGlobalFunctionTyped;
  rowActions: (expenseCategory: ExpenseCategory) => React.ReactNode;
  isCostCentersFeatureEnabled: boolean;
  isExpenseCategoryExpenseAccountRuleActivated: boolean;
  costCenters: CostCenter[];
  expenseAccounts: ExpenseAccount[];
  expenseCategoryExpenseAccountRules: ExpenseCategoryExpenseAccountRule[];
};

export const getExpenseCategoryColumns = ({
  t,
  rowActions,
  isCostCentersFeatureEnabled,
  isExpenseCategoryExpenseAccountRuleActivated,
  costCenters,
  expenseAccounts,
  expenseCategoryExpenseAccountRules,
}: TableProps) => {
  const columns = [
    {
      id: 'expenseCategory',
      header: t('expenseCategories.expenseCategory'),
      renderCell: (expenseCategory: ExpenseCategory) => (
        <div className="EcContainer__row">
          <div className="truncate">{expenseCategory.name}</div>
          {!isCostCentersFeatureEnabled &&
            !isExpenseCategoryExpenseAccountRuleActivated &&
            rowActions(expenseCategory)}
        </div>
      ),
    },
  ];

  if (isCostCentersFeatureEnabled) {
    columns.push({
      id: 'associatedCostCenter',
      header: t('expenseCategories.associatedCostCenter'),
      renderCell: (expenseCategory: ExpenseCategory) => (
        <div className="EcContainer__row">
          <div className="truncate">
            {getCostCentersString(
              expenseCategory.costCenterIds,
              costCenters,
              t,
            )}
          </div>
          {!isExpenseCategoryExpenseAccountRuleActivated &&
            rowActions(expenseCategory)}
        </div>
      ),
    });
  }

  if (isExpenseCategoryExpenseAccountRuleActivated) {
    columns.push({
      id: 'expenseCategoryExpenseAccountRules',
      header: t('expenseCategories.linkedExpenseAccount'),
      renderCell: (expenseCategory: ExpenseCategory) => (
        <div className="EcContainer__row">
          <div className="truncate">
            {getLinkedExpenseAccountsText(
              expenseCategoryExpenseAccountRules,
              expenseCategory.id,
              expenseAccounts,
              t,
            )}
          </div>
          {rowActions(expenseCategory)}
        </div>
      ),
    });
  }

  return columns;
};

export const ExpenseCategoryTable = ({
  expenseCategories,
  ...tableProps
}: {
  expenseCategories: ExpenseCategory[];
} & TableProps) => {
  const { t } = tableProps;

  return (
    <Table
      className="EcContainer__expenseCategoryTable"
      columns={getExpenseCategoryColumns(tableProps)}
      data={expenseCategories}
      rowHeight="compact"
      maxHeight={330}
      emptyState={{
        title: t('expenseCategories.emptyState.title'),
        subtitle: t('expenseCategories.emptyState.subtitle'),
      }}
      getRowId={(row) => row.id}
    />
  );
};
