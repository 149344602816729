/* eslint-disable react/display-name */
import React, { useEffect, useState, type ComponentType } from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUserId } from 'modules/app/hooks/useUserId';
import UnexpectedError from 'src/core/common/components/legacy/UnexpectedError/UnexpectedError';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { routeFor, routes } from 'src/core/constants/routes';
import { PendingKycEmptyState } from 'src/core/modules/kyc/components/PendingKycEmptyState';
import { useShouldCompleteKyc } from 'src/core/modules/kyc/hooks/useShouldCompleteKyc';

import { IntegrationAccountingPageSkeleton } from './LegacyIntegrationsAccountingPage/components/IntegrationAccountingPageSkeleton';
import { GraphQLProvider } from '../../../components/GraphQLProvider';
import { useIntegrationStatusQuery } from '../../../hooks';
import { NoIntegrationAccountingPage } from '../../accounting/pages/NoIntegrationAccountingPage';
import { useGetBookkeepingStartDateQuery } from '../hooks/useGetBookkeepingStartDateQuery';

const useHasSeenOnboarding = () => {
  const userId = useUserId();
  const key = `bk2.accounting.onboarding.${userId}`;

  const get = () => {
    try {
      return window.localStorage.getItem(key) === 'true';
    } catch {
      return true;
    }
  };

  const set = () => {
    try {
      window.localStorage.setItem(key, 'true');
    } catch {
      // Do nothing
    }
  };

  return { hasSeenOnboarding: get(), setHasSeenOnboarding: set };
};

const withIntegrationAccountingState = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => {
    const history = useHistory();
    const companyId = useCompanyId();
    const bookkeepingStartDateQueryResult = useGetBookkeepingStartDateQuery();
    const shouldCompleteKyc = useShouldCompleteKyc();
    const [isPollingIntegrationStatus, setIsPollingIntegrationStatus] =
      useState(false);
    const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery(
      isPollingIntegrationStatus,
      { staleTime: 0 },
    );

    const { hasSeenOnboarding, setHasSeenOnboarding } = useHasSeenOnboarding();

    // we only want to pool integration status during a accounting integration switch
    useEffect(() => {
      if (accountingIntegrationStatusQueryResult.status === 'success') {
        const shouldIPollIntegrationStatus =
          accountingIntegrationStatusQueryResult.data.integration ===
          'switchInProgress';
        setIsPollingIntegrationStatus(shouldIPollIntegrationStatus);
      }
    }, [accountingIntegrationStatusQueryResult]);

    if (shouldCompleteKyc) {
      return <PendingKycEmptyState />;
    }

    if (
      accountingIntegrationStatusQueryResult.status === 'loading' ||
      bookkeepingStartDateQueryResult.status === 'loading'
    ) {
      return <IntegrationAccountingPageSkeleton />;
    }

    if (
      accountingIntegrationStatusQueryResult.status === 'error' ||
      bookkeepingStartDateQueryResult.status === 'error'
    ) {
      return <UnexpectedError />;
    }

    const { integration: accountingIntegration } =
      accountingIntegrationStatusQueryResult.data;

    if (accountingIntegration === 'noIntegration' && !hasSeenOnboarding) {
      return (
        <NoIntegrationAccountingPage
          onDone={() => {
            setHasSeenOnboarding();
            history.push(
              routeFor(routes.COMPANY_ACCOUNTING.path, { company: companyId }),
              { isAccountingBaseSelected: true },
            );
          }}
        />
      );
    }

    return <WrappedComponent {...props} />;
  };
};

const withGraphQLProviderAndErrorBoundary = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  return (props: P) => {
    return (
      <ErrorBoundary
        context={{
          scope: 'integration-accounting-page',
          team: 'accounting-integration',
        }}
      >
        <GraphQLProvider>
          <WrappedComponent {...props} />
        </GraphQLProvider>
      </ErrorBoundary>
    );
  };
};

const composeHOCs =
  <P extends object>(
    ...hocs: Array<
      (component: React.ComponentType<P>) => React.ComponentType<P>
    >
  ) =>
  (Component: React.ComponentType<P>): React.ComponentType<P> => {
    return hocs.reduceRight(
      (WrappedComponent, hoc) => hoc(WrappedComponent),
      Component,
    );
  };

export const withIntegrationAccounting = composeHOCs(
  withIntegrationAccountingState,
  withGraphQLProviderAndErrorBoundary,
);
