import {
  Callout,
  type IconName,
  Panel,
  DATE_FORMAT,
  type DateFormatter,
} from '@dev-spendesk/grapes';
import React, { type ReactNode } from 'react';
import { Trans } from 'react-i18next';

import {
  PanelHeader,
  PanelTextSection,
  PanelIconItemsSection,
} from 'common/components/Panel';
import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { CounterpartySection } from './CounterpartySection';
import { MileageSection } from './MileageSection';
import styles from './PayablePanel.module.css';
import { PayableTypeTag } from './PayableTypeTag';
import { ReceiptSection } from './ReceiptSection';
import { PayableAccountingSectionContainer } from '../../containers/PayableAccountingSectionContainer';
import { PayableDetailsSectionContainer } from '../../containers/PayableDetailsSectionContainer';
import type {
  Payable,
  DocumentViewable,
} from '../../containers/PayablePanelContainer';
import {
  getIsPayableFullyPaid,
  getIsPayablePrepared,
  getIsPayableExported,
  getIsPayableNotBookkept,
  type PayableType,
} from '../../models/payable';

type Props = {
  payable: Payable;
  exportError?: ReactNode;
  panelActions?: ReactNode;
  onDocumentClick(document: DocumentViewable): void;
  onClose(): void;
  className?: string;
};

export const PayablePanel = ({
  payable,
  exportError,
  panelActions,
  onDocumentClick,
  onClose,
  className,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const transactionDescription = getTransactionDescription(payable.allocations);
  const isPayableFullyPaid = getIsPayableFullyPaid(payable);
  const isMileagePayable = getIsMileagePayable(payable.type);
  const isPayablePrepared = getIsPayablePrepared(payable);
  const isPayableExported = getIsPayableExported(payable);
  const isPayableNotBookkept = getIsPayableNotBookkept(payable);

  return (
    <Panel
      className={className}
      title={t('payables.panel.title')}
      header={
        <PanelHeader
          top={<CounterpartySection payable={payable} />}
          textPrimary={formatMonetaryValue(payable.functionalAmount)}
          textSecondary={getHeaderSecondaryText(payable, t)}
        >
          {exportError && (
            <Callout
              variant="alert"
              title={t('bookkeep.integrations.exportErrorsReview.exportFailed')}
              className={styles.exportErrorCallout}
            >
              {exportError}
            </Callout>
          )}
          <PayableTypeTag payable={payable} />
        </PanelHeader>
      }
      footer={
        <PanelIconItemsSection
          items={
            panelActions
              ? []
              : [
                  payable.subtype === 'creditNote'
                    ? getCreditStatus(payable, t)
                    : {
                        label: getReadablePayablePaidStatus(payable, t),
                        value:
                          getReadablePayablePaidDate(
                            payable,
                            t,
                            localeFormat,
                          ) ?? '',
                        variant: isPayableFullyPaid ? 'success' : 'info',
                        icon: isPayableFullyPaid ? 'success' : 'schedule',
                      },
                  {
                    label: getReadablePayableExportStatus(
                      isPayablePrepared,
                      isPayableExported,
                      isPayableNotBookkept,
                      t,
                    ),
                    value: '',
                    variant: isPayableExported ? 'success' : 'info',
                    icon: getReadablePayableExportIcon(
                      isPayableExported,
                      isPayableNotBookkept,
                    ),
                  },
                ].filter(
                  (
                    value,
                  ): value is {
                    label: string;
                    value: string;
                    icon: IconName;
                    variant: 'info' | 'success';
                  } => Boolean(value),
                )
          }
          panelActions={panelActions}
        />
      }
      onClose={onClose}
    >
      <MileageSection payable={payable} />
      {!isMileagePayable && (
        <ReceiptSection
          payable={payable}
          onDocumentClick={(document) => {
            track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_RECEIPT_VIEWED, {
              payableId: payable.id,
            });
            onDocumentClick(document);
          }}
        />
      )}
      {transactionDescription && (
        <PanelTextSection
          isEmphasized
          title={t('payables.panel.transactionDescription')}
        >
          {transactionDescription}
        </PanelTextSection>
      )}
      <PanelTextSection title={t('payables.panel.description')}>
        {payable.description}
      </PanelTextSection>
      <PayableDetailsSectionContainer payable={payable} />
      <PayableAccountingSectionContainer payable={payable} />
    </Panel>
  );
};

const getTransactionDescription = (allocations: Payable['allocations']) => {
  const cardPaymentAllocation = allocations.find(
    (allocation) =>
      allocation.settlement?.kind === 'payment' &&
      allocation.settlement?.transaction.medium === 'card',
  );
  // only display description of card payment transaction
  if (cardPaymentAllocation) {
    return cardPaymentAllocation.settlement?.transaction.description;
  }
};

const getReadablePayablePaidStatus = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
): string => {
  const settlementKind = payable.allocations[0]?.settlement?.kind;
  const isPayableFullyPaid = getIsPayableFullyPaid(payable);

  if (isPayableFullyPaid) {
    switch (settlementKind) {
      case 'refund':
        return translator('payables.panel.status.refunded');
      case 'reimbursement':
        return translator('payables.panel.status.reimbursed');
      default:
        return translator('payables.panel.status.paid');
    }
  }

  switch (payable.type) {
    case 'cardPurchase':
    case 'invoicePurchase':
      return translator('payables.panel.status.toPay');
    case 'claimedBill':
    case 'mileageAllowance':
    case 'perDiemAllowance':
      return translator('payables.panel.status.toReimburse');
    default:
      return '';
  }
};

const getReadablePayablePaidDate = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
  localeFormat: DateFormatter,
): string | undefined => {
  // TODO@financeAccountants@creditNotes use invoice validation date as paid date
  if (payable.allocations.some((allocation) => allocation.reversal)) {
    return;
  }

  const sortedAllocations = payable.allocations.sort(
    (allocation1, allocation2) =>
      (allocation2.settlement?.transaction.clearingDate.getTime() || 0) -
      (allocation1.settlement?.transaction.clearingDate.getTime() || 0),
  );
  const latestAllocation = sortedAllocations[0];

  if (latestAllocation && latestAllocation.settlement) {
    const formattedDate = localeFormat(
      latestAllocation.settlement.transaction.clearingDate,
      DATE_FORMAT.MEDIUM,
    );
    return translator('payables.panel.status.settledOn', {
      date: formattedDate,
    });
  }
};

const getReadablePayableExportStatus = (
  isPayablePrepared: boolean,
  isExported: boolean,
  isPayableNotBookkept: boolean,
  translator: TGlobalFunctionTyped,
): string => {
  if (isPayableNotBookkept) {
    return translator('payables.panel.status.notBookkept');
  }
  if (isExported) {
    return translator('payables.panel.status.exported');
  }
  if (isPayablePrepared) {
    return translator('payables.panel.status.toExport');
  }
  return translator('payables.panel.status.toPrepare');
};

const getReadablePayableExportIcon = (
  isPayableExported: boolean,
  isPayableNotBookkept: boolean,
): IconName => {
  if (isPayableExported) {
    return 'success';
  }
  if (isPayableNotBookkept) {
    return 'closed-eye';
  }
  return 'clock';
};

const getHeaderSecondaryText = (
  payable: Payable,
  translator: TGlobalFunctionTyped,
): ReactNode | string | undefined => {
  if (payable.type === 'mileageAllowance') {
    return (
      <Trans
        i18nKey="payables.panel.mileage.distance"
        values={{ distance: payable.mileageDetails?.distance }}
        components={[<strong key={payable.id} />]}
      />
    );
  }

  if (payable.type === 'perDiemAllowance') {
    return translator('payables.panel.perDiemDuration', {
      count: payable.perDiem?.duration,
    });
  }

  if (payable.grossAmount.currency !== payable.functionalAmount.currency) {
    return translator('payables.panel.convertsFrom', {
      amount: formatMonetaryValue(payable.grossAmount),
    });
  }

  return undefined;
};

const getIsMileagePayable = (type: PayableType) => {
  return type === 'mileageAllowance';
};

type Status = {
  label: string;
  value: string;
  variant: string;
  icon: string;
};

const getCreditStatus = (
  payable: Payable,
  t: TGlobalFunctionTyped,
): Status | undefined => {
  if (payable.subtype === 'creditNote') {
    if (
      payable.creditNoteDetails?.validationAction === 'recordForFutureInvoices'
    ) {
      return {
        label: t('payables.panel.creditStatus.available'),
        variant: 'info',
        icon: 'money-bag',
        value: '',
      };
    }

    if (payable.creditNoteDetails?.state === 'reimbursed') {
      return {
        label: t('payables.panel.creditStatus.refunded'),
        variant: 'success',
        icon: 'bank',
        value: '',
      };
    }

    if (
      payable.creditNoteDetails?.validationAction ===
        'deductFromInvoiceRequests' &&
      payable.creditNoteDetails?.state === 'allocated'
    ) {
      return {
        label: t('payables.panel.creditStatus.deducted'),
        variant: 'success',
        icon: 'success',
        value: '',
      };
    }

    if (
      payable.creditNoteDetails?.validationAction ===
        'deductFromInvoiceRequests' &&
      payable.creditNoteDetails?.state === 'partiallyAllocated'
    ) {
      return {
        label: t('payables.panel.creditStatus.partiallyDeducted'),
        variant: 'success',
        icon: 'success',
        value: '',
      };
    }
  }
};
