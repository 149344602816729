import cx from 'classnames';
import React, { Component, type ReactNode } from 'react';
import ReactDOM from 'react-dom';

import './FileViewerModal.css';

const KEYCODE_ESC = 27;

type Props = {
  children: ReactNode;
  className?: string;
  onClose: () => void;
  fullWidth?: boolean;
};

export class FileViewerModal extends Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modalRootRef: any = null;

  static defaultProps = {
    fullWidth: false,
    className: '',
  };

  componentDidMount() {
    window.addEventListener('keydown', this.listenToKeyboardEvents);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.listenToKeyboardEvents);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickOutside = (e: any) => {
    if (this.modalRootRef === e.target) {
      this.props.onClose();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenToKeyboardEvents = (e: any) => {
    if (e.keyCode === KEYCODE_ESC) {
      this.props.onClose();
    }
  };

  render() {
    const { fullWidth, className, children } = this.props;

    return ReactDOM.createPortal(
      <div
        className={cx('FileViewerModal dialog dialog-dark active', className, {
          'FileViewerModal--fullwidth': fullWidth,
        })}
        onClick={this.onClickOutside}
        ref={(element) => {
          this.modalRootRef = element;
        }}
      >
        <div className="dialog__content">{children}</div>
      </div>,
      document.getElementById('react__file-viewer-modal') ?? document.body,
    );
  }
}
