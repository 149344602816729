import cx from 'classnames';
import noop from 'lodash/noop';
import React from 'react';

import Icon, { ICONS } from 'common/components/legacy/Icon/Icon';
import { grey6Cheerful } from "src/core/utils/color-palette";
import './Tag.scss';

type TagTheme = '' | 'info' | 'success';

type Props = {
  text: string | JSX.Element;
  theme?: TagTheme;
  isDisabled?: boolean;
  hasCloseIcon?: boolean;
  hasBorder?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void; // TODO: fixme
  className?: string;
};

const Tag = ({
  text,
  theme = '',
  isDisabled = false,
  hasCloseIcon = false,
  hasBorder = true,
  style = undefined,
  onClick = noop,
  className = '',
}: Props) => (
  <div
    className={cx('Tag', theme, className, {
      'is-disabled': isDisabled,
      'has-border': hasBorder,
    })}
    onClick={() => !isDisabled && onClick()}
    style={style}
  >
    <div
      className={cx('Tag-content', { 'has-icon': hasCloseIcon })}
      role="status"
    >
      {text}
      {!!hasCloseIcon && (
        <Icon icon={ICONS.CROSS} fill={grey6Cheerful} width={20} height={20} />
      )}
    </div>
  </div>
);

export default Tag;
