import { z } from 'zod';

import { migrationPhase } from '../migration-phase';

export const getTaskSummaryForOrganisationSchema = z.object({
  // List of companies the user is member of and there is an active migration
  companies: z.array(
    z.object({
      companyId: z.string(),
      companyName: z.string(),
      activePhase: migrationPhase,

      // Filtered by the user roles
      uncompletedTaskCount: z.number(),

      // True when the review KYC task has started and the user has to take action
      // Based on the user roles
      kycReviewNeedsAction: z.boolean(),

      // True when the review KYC task has started and the user has no action to take
      // Based on the user roles
      kycReviewNoActionNeeded: z.boolean(),
    }),
  ),
});
