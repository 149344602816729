import React from 'react';

import UnexpectedError from 'common/components/legacy/UnexpectedError/UnexpectedError';
import withErrorBoundary from 'common/components/withErrorBoundary';

import { useGetIntegrations } from '../../../hooks';
import { IntegrationsList, IntegrationsListSkeleton } from '../../components';

const IntegrationsListContainer = () => {
  const integrationsQuery = useGetIntegrations();
  if (integrationsQuery.status === 'loading') {
    return <IntegrationsListSkeleton />;
  }
  if (integrationsQuery.status === 'error') {
    return <UnexpectedError />;
  }
  return <IntegrationsList integrationStatus={integrationsQuery.data} />;
};

export default withErrorBoundary({
  scope: 'integrations-list-container',
  team: 'api-integration',
})(IntegrationsListContainer);
