import { createReducer } from '@reduxjs/toolkit';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';

const initialState = {
  customFields: null,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(customFieldsTypes.FETCH_CF_SUCCESS, (state, action) => {
    state.customFields = action.payload.customFields;
  });
});
