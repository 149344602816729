import { Button, Callout, Tag } from '@dev-spendesk/grapes';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { TypeGuardError } from 'src/core/utils/switchGuard';

import { type Integration } from '../../../types';
import { isKomboIntegration } from '../../../utils';

type Props = {
  integration: Integration;
  sizeTitle?: string;
  colorTitle?: string;
  colorList?: string;
};

export const IntegrationBenefits = (props: Props) => {
  const { integration, colorTitle, colorList, sizeTitle } = props;
  const { t } = useTranslation('global');

  if (integration.id === 'travelperk') {
    return (
      <div className="flex w-full flex-col gap-s text-left">
        <span
          className={`flex ${sizeTitle ?? 'title-l'} ${
            colorTitle ?? 'text-complementary'
          }`}
        >
          {t(`integration.${integration.id}.details.benefits.title`)}
        </span>
        <div className="flex gap-xs text-m">
          <ul
            className={`flex list-inside flex-col gap-xs pl-s ${
              colorList ?? 'text-neutral-dark'
            }`}
          >
            {[
              t(`integration.${integration.id}.details.benefits.list.one`),
              t(`integration.${integration.id}.details.benefits.list.two`),
              t(`integration.${integration.id}.details.benefits.list.three`),
            ].map((line) => (
              <li
                key={line}
                className={`flex-inline list-disc ${
                  colorList ?? 'text-neutral-dark'
                }`}
              >
                <span className={colorList ?? 'text-neutral-dark'}>{line}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
  if (isKomboIntegration(integration.id)) {
    return (
      <div className="flex w-full flex-col gap-s text-left">
        <Callout title={t('integration.kombo.settings.callout.label')}>
          <ul className="list-disc pb-xs pl-s text-neutral-dark">
            <li>
              <Trans
                i18nKey={t(
                  `integration.kombo.${integration.id}.calloutList.add`,
                )}
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey={t(
                  `integration.kombo.${integration.id}.calloutList.update`,
                )}
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
              />
            </li>
            <li>
              <Trans
                i18nKey={t(
                  `integration.kombo.${integration.id}.calloutList.remove`,
                )}
                components={{
                  strong: <strong className="font-bold">-</strong>,
                }}
              />
            </li>
          </ul>
          <Button
            name="control"
            text={t('integration.kombo.settings.callout.button.label')}
            variant="contrasted"
            component="a"
            target="_blank"
            href={t('integration.kombo.settings.callout.button.helpCenterUrl')}
          />
        </Callout>
        <span className="text-m">
          <Trans
            i18nKey={t(
              `integration.kombo.${integration.id}.details.description`,
            )}
            components={{
              strong: <strong className="font-bold">-</strong>,
            }}
          />
        </span>
        <div className="flex gap-xs">
          <Tag iconName="success" variant="neutral">
            {t('misc.active')}
          </Tag>
          <Tag iconName="failure" variant="neutral">
            {t('misc.leave')}
          </Tag>
        </div>
      </div>
    );
  }

  if (integration.id === 'spendesk-oauth2') {
    return null;
  }

  throw new TypeGuardError(
    integration.id,
    'IntegrationBenefits: Unknown integrationId',
  );
};
