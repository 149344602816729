import { Label, Tag, Avatar } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import React from 'react';

import { EllipsisTooltip } from "src/core/common/components/EllipsisTooltip";
import { useTranslation } from "src/core/common/hooks/useTranslation";

import { type Appraisal } from '../../hooks/useRequestQuery';

import './AppraisalsList.css';

type Props = {
  appraisals: Appraisal[];
  className?: string;
};

export const AppraisalsList = ({ appraisals, className }: Props) => {
  const { t } = useTranslation('global');
  const appraisalItems = getAppraisals(appraisals);

  if (appraisalItems.length === 0 || isEmpty(appraisals)) {
    return null;
  }

  return (
    <div className={classnames('AppraisalsList', className)}>
      <Label className="mb-s" label={t('requests.approvedBy')} />
      <div className="AppraisalsList__items flex flex-col gap-s">
        {appraisalItems.map((appraisalItem) => (
          <AppraisalRow
            key={appraisalItem.approver.id}
            appraisalItem={appraisalItem}
          />
        ))}
      </div>
    </div>
  );
};

const AppraisalRow = ({ appraisalItem }: { appraisalItem: Appraisal }) => {
  const { t } = useTranslation('global');
  const { approver } = appraisalItem;

  return (
    <div className="flex items-center justify-between gap-x-xs">
      <Avatar src={approver.avatar} text={approver.fullname} />
      <div className="flex-auto">
        {/*  for the 'flex' reasons */}
        <EllipsisTooltip
          className="AppraisalsListRow__name body-m"
          text={approver.fullname}
        />
      </div>
      <Tag variant="success">{t('approval.approversList.tags.approved')}</Tag>
      {/* show date here after design ready */}
    </div>
  );
};

const getAppraisals = (appraisals: Appraisal[]) => {
  const filteredAppraisals = appraisals.filter(
    (appraisal) => !appraisal.automatic,
  );

  return sortBy(
    filteredAppraisals,
    (filteredAppraisal) => filteredAppraisal.created_at, // sort by approval date and display date when design will be updated
  );
};
