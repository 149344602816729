import cx from 'classnames';
import React from 'react';

import './SectionRowContent.scss';

type Item = {
  value: string;
  isLight?: boolean;
  secondaryValue?: string;
  secondaryValuePosition?: 'left' | 'right';
};

type Props = {
  item: Item;
};

export const SectionRowContent = ({ item }: Props) => {
  return (
    <div
      className={cx('SectionRowContent', {
        'SectionRowContent--light': item.isLight,
      })}
    >
      <span className="SectionRowContent__value body-m ">{item.value}</span>
      {item.secondaryValue && (
        <span
          className={cx('SectionRowContent__additional', {
            'SectionRowContent__additional--right':
              item.secondaryValuePosition === 'right',
          })}
        >
          {item.secondaryValue}
        </span>
      )}
    </div>
  );
};
