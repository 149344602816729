import { colors, Icon, type IconName, Link, Modal } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { useState } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import ReceiptAlert from './assets/receipt_alert.svg';
import ReceiptOkay from './assets/receipt_okay.svg';
import { SpenderRulesModal } from '../SpenderRulesModal';
import { YourRulesInfo } from '../YourRulesInfo';

import './SpenderRulesPanel.css';

type Props = {
  incompletePaymentsLimit: number;
  completionDeadline: number;
  mode?: 'normal' | 'danger';
};

const getComponentPropsFromMode = (
  mode: 'normal' | 'danger',
  translator: TGlobalFunctionTyped,
): {
  className: string;
  titleIcon: IconName;
  titleIconColor: string;
  icon: React.ReactNode;
  getSubtitle(count: number): string;
  getDescription(count: number): string;
} => {
  if (mode === 'danger') {
    return {
      className: 'SpenderRulesPanel--danger text-alert',
      titleIcon: 'warning',
      titleIconColor: colors.alert,
      icon: <img width="93" height="88" alt="" src={ReceiptAlert} />,
      getSubtitle: (count: number) =>
        translator('homepage.rules.panel.warning.subtitle', { count }),
      getDescription: (count: number) =>
        translator('homepage.rules.panel.warning.text', { count }),
    };
  }

  return {
    className: 'SpenderRulesPanel--normal text-info',
    titleIcon: 'info',
    titleIconColor: colors.info,
    icon: <img width="87" height="85" alt="" src={ReceiptOkay} />,
    getSubtitle: (count: number) =>
      translator('homepage.rules.panel.normal.subtitle', { count }),
    getDescription: (count: number) =>
      translator('homepage.rules.panel.normal.text', { count }),
  };
};

export const SpenderRulesPanel = ({
  incompletePaymentsLimit,
  completionDeadline,
  mode = 'normal',
}: Props) => {
  const { t } = useTranslation('global');
  const [isModalOpened, setIsModalOpened] = useState(false);
  const hasPlayByTheRulesReceiptAndFieldsFeature = useFeature(
    FEATURES.PLAY_BY_THE_RULES_RECEIPT_AND_FIELDS,
  );

  const {
    className,
    titleIcon,
    titleIconColor,
    icon,
    getSubtitle,
    getDescription,
  } = getComponentPropsFromMode(mode, t);

  return (
    <>
      <Modal
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        iconName="info"
        iconVariant="info"
        title={t('homepage.rules.modal.title')}
        subtitle={t('homepage.rules.modal.desc')}
      >
        <div className="text-left">
          <SpenderRulesModal
            incompletePaymentsLimit={incompletePaymentsLimit}
            completionDeadline={completionDeadline}
          />
        </div>
      </Modal>
      {hasPlayByTheRulesReceiptAndFieldsFeature ? (
        <YourRulesInfo
          onLearnMoreClick={() => {
            setIsModalOpened(true);
            track(AnalyticEventName.HOMEPAGE_REQUESTER_HOW_LINK_CLICKED);
          }}
        />
      ) : (
        <div className={cx('box p-m text-center', className)}>
          <div className="flex items-center justify-center title-l">
            <Icon name={titleIcon} color={titleIconColor} className="mr-xs" />
            <span>{t('homepage.rules.panel.title')}</span>
          </div>
          <div className="m-m">{icon}</div>
          <div className="text-complementary title-l">
            {getSubtitle(incompletePaymentsLimit)}
          </div>
          <div className="mb-m mt-xxs text-neutral-dark body-m">
            {getDescription(completionDeadline)}
          </div>
          <Link
            href="#"
            className="text-complementary body-m"
            onClick={() => {
              setIsModalOpened(true);
              track(AnalyticEventName.HOMEPAGE_REQUESTER_HOW_LINK_CLICKED);
            }}
          >
            {t('homepage.rules.panel.how')}
          </Link>
        </div>
      )}
    </>
  );
};
