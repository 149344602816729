import { z } from 'zod';

export const updateTaskCountSchema = z.object({
  type: z.enum([
    'ReviewKyc',
    'ValidateCardOrder',
    'SubscriptionRecard',
    'PhysicalCardsActivation',
  ]),
  completedCount: z.number(),
  totalCount: z.number(),
});
