import {
  type IntegrationStatusWithIntegration,
  hasBankAccounts,
  isIntegrationStatusWithIntegration,
  isUserAuthorized,
  shouldHideAccountsPayable,
  type IntegrationStatus,
} from '../../../integration/status';

export function canAccessBankAccountsSettings(
  status: IntegrationStatus,
  companyType:
    | 'initial'
    | 'branch_entity'
    | 'branch_currency'
    | 'branch_expense_entity',
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status)
  ) {
    return false;
  }

  return companyType !== 'branch_expense_entity' && hasBankAccounts(status);
}

export function canAccessExpenseAccountsSettings(
  status: IntegrationStatus,
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status)
  ) {
    return false;
  }

  return Boolean(status.capabilities.expenseAccounts);
}

export function canAccessTaxAccountsSettings(
  status: IntegrationStatus,
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status)
  ) {
    return false;
  }

  return Boolean(status.capabilities.taxAccounts);
}

export function canAccessSupplierAccountsSettings(
  status: IntegrationStatus,
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status) ||
    shouldHideAccountsPayable(status)
  ) {
    return false;
  }

  return Boolean(status.capabilities.supplierAccounts);
}

export function canAccessEmployeeAccountsSettings(
  status: IntegrationStatus,
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status) ||
    shouldHideAccountsPayable(status)
  ) {
    return false;
  }

  return Boolean(status.capabilities.employeeAccounts);
}

export function canAccessAnalyticalFieldsSettings(
  status: IntegrationStatus,
): status is IntegrationStatusWithIntegration {
  if (
    !isIntegrationStatusWithIntegration(status) ||
    !isUserAuthorized(status)
  ) {
    return false;
  }

  return Boolean(status.capabilities.analyticalFields);
}
