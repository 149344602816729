import { Button, Link } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useState } from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { useEmployeeCardQuery } from 'src/sfs-migration/used-in-js-app/hooks/useEmployeeCardQuery';

import { ReorderSFSContinueModal } from './ReorderSFSContinueModal';
import { ReorderSFSFormModal } from './ReorderSFSFormModal';
import bg from './assets/bg.png';

type Props = {
  className?: string;
};

export const EmployeeCardOrderBanner = ({ className }: Props) => {
  const { t } = useTranslation('sfs-migration');
  const companyId = useCompanyId();
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const hasTMPSFSMigrationEnableReorderSFSCard = useFeature(
    FEATURES.TMP_SFS_MIGRATION_ENABLE_REORDER_SFS_CARD,
  );

  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false);
  const [isFormOrderModalOpen, setIsFormOrderModalOpen] = useState(false);

  const employeeCardQueryState = useEmployeeCardQuery({
    isEnabled: hasTMPSFSMigrationPage,
  });

  if (employeeCardQueryState.status !== 'success') {
    return null;
  }

  if (!employeeCardQueryState.data) {
    return null;
  }

  const recardOrder = employeeCardQueryState.data.recardOrder;

  // Only show the card once it's shipped and not activated
  if (
    recardOrder?.state !== 'shipped' ||
    !recardOrder?.shippingAddress ||
    employeeCardQueryState.data.card?.cardStatus !== 'PRE'
  ) {
    return null;
  }
  const address = `${recardOrder.shippingAddress?.addressLine1} ${recardOrder.shippingAddress?.addressLine2 ? `${recardOrder.shippingAddress?.addressLine2} ` : ''}${recardOrder.shippingAddress?.city} ${recardOrder.shippingAddress?.zipcode}`;

  return (
    <div>
      <div
        className={classNames(
          'relative flex items-center justify-between overflow-hidden rounded-xxs p-s',
          className,
        )}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="text-white">
          <div className="mb-xxs title-xl">
            {t('phase-execution.cardShipment.title')} {iconDelivery}
          </div>
          <div>
            <div className="flex items-center gap-xxs">
              {t('phase-execution.cardShipment.address', { address })}
              {hasTMPSFSMigrationEnableReorderSFSCard &&
                Boolean(recardOrder.trackingLink) && (
                  <>
                    <span>•</span>
                    <Link
                      href={recardOrder.trackingLink ?? ''}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        track(
                          AnalyticEventName.SFS_MIGRATION_TRACKING_LINK_CLICKED,
                          {
                            origin: 'card_page',
                          },
                        );
                      }}
                    >
                      {t('phase-execution.cardShipment.buttonTrack')}
                    </Link>
                  </>
                )}
            </div>
            {t('phase-execution.cardShipment.deliveryEstimation')}
          </div>
        </div>
        <div className="flex gap-s">
          {hasTMPSFSMigrationEnableReorderSFSCard ? (
            <Button
              className="text-white"
              variant="ghost"
              text={t('phase-execution.cardShipment.cardNotReceived')}
              onClick={() => setIsContinueModalOpen(true)}
            />
          ) : (
            <Button
              className="text-white"
              variant="ghost"
              text={t('phase-execution.cardShipment.buttonTrack')}
              component="a"
              target="_blank"
              href={recardOrder.trackingLink ?? ''}
              onClick={() => {
                track(AnalyticEventName.SFS_MIGRATION_TRACKING_LINK_CLICKED, {
                  origin: 'card_page',
                });
              }}
            />
          )}

          <Button
            component="a"
            href={`/free-upgrade/${companyId}/overview/follow-your-progress?openCardActivation=1`}
            target="_blank"
            variant="contrasted"
            text={t('phase-execution.cardShipment.buttonReceived')}
          />
        </div>
      </div>
      <ReorderSFSContinueModal
        isOpen={isContinueModalOpen}
        onClose={() => setIsContinueModalOpen(false)}
        onNext={() => {
          setIsFormOrderModalOpen(true);
          setIsContinueModalOpen(false);
        }}
        trackingLink={recardOrder.trackingLink ?? ''}
      />
      <ReorderSFSFormModal
        isOpen={isFormOrderModalOpen}
        onClose={() => setIsFormOrderModalOpen(false)}
        shippingAddress={recardOrder.shippingAddress}
      />
    </div>
  );
};

const iconDelivery = (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.375 0.5C1.339 0.5 0.5 1.34 0.5 2.375V9.5H12.5V2.375C12.5 1.339 11.66 0.5 10.625 0.5H2.375ZM12.5 11H0.5V13.625C0.5 14.66 1.34 15.5 2.375 15.5H2.75C2.75 14.7044 3.06607 13.9413 3.62868 13.3787C4.19129 12.8161 4.95435 12.5 5.75 12.5C6.54565 12.5 7.30871 12.8161 7.87132 13.3787C8.43393 13.9413 8.75 14.7044 8.75 15.5H11.75C11.9489 15.5 12.1397 15.421 12.2803 15.2803C12.421 15.1397 12.5 14.9489 12.5 14.75V11ZM7.25 15.5C7.25 15.1022 7.09196 14.7207 6.81066 14.4394C6.52936 14.1581 6.14782 14 5.75 14C5.35217 14 4.97064 14.1581 4.68934 14.4394C4.40803 14.7207 4.25 15.1022 4.25 15.5C4.25 15.8978 4.40803 16.2794 4.68934 16.5607C4.97064 16.842 5.35217 17 5.75 17C6.14782 17 6.52936 16.842 6.81066 16.5607C7.09196 16.2794 7.25 15.8978 7.25 15.5ZM14.75 2.75002C14.5511 2.75002 14.3603 2.82903 14.2197 2.96969C14.079 3.11034 14 3.3011 14 3.50002V14.75C14 14.837 14.015 14.92 14.042 14.998C14.1666 14.2592 14.5632 13.5937 15.1538 13.1326C15.7444 12.6714 16.4861 12.4479 17.2331 12.5061C17.9802 12.5643 18.6784 12.8999 19.1904 13.4469C19.7025 13.9939 19.9913 14.7128 20 15.462C20.853 15.287 21.522 14.527 21.464 13.579C21.2403 9.91738 19.9422 6.40292 17.732 3.47502C17.5598 3.24862 17.3374 3.06529 17.0823 2.93948C16.8273 2.81367 16.5464 2.74881 16.262 2.75002H14.75ZM18.0607 14.4393C18.342 14.7206 18.5 15.1022 18.5 15.5C18.5 15.8978 18.342 16.2794 18.0607 16.5607C17.7794 16.842 17.3978 17 17 17C16.6022 17 16.2206 16.842 15.9393 16.5607C15.658 16.2794 15.5 15.8978 15.5 15.5C15.5 15.1022 15.658 14.7206 15.9393 14.4393C16.2206 14.158 16.6022 14 17 14C17.3978 14 17.7794 14.158 18.0607 14.4393Z"
      fill="white"
    />
  </svg>
);
