import { gql } from 'graphql-tag';

const detailedPayableFragment = gql`
  fragment detailedPayable on Payable {
    id
    version
    allocations {
      amount
    }
    state
    creationDate
    accountingDate
    netAmount
    grossAmount
    functionalAmount
    functionalExchangeRate
    kind
    type
    subtype
    invoiceNumber
    accountPayable {
      id
      generalAccountCode
      auxiliaryAccountCode
    }
    documentaryEvidence {
      type
      invoiceNumber
      creditNoteNumber
      compliances {
        spainEreceipt {
          receipt {
            uniqueId
            path
          }
        }
      }
    }
    counterparty {
      ... on User {
        id
        givenName
        familyName
        email
      }
      ... on SuspendedUser {
        id
        givenName
        familyName
      }
      ... on Invitee {
        id
        email
      }
      ... on Supplier {
        id
        name
        thumbnailUrl
        isArchived
      }
    }
    team {
      id
      name
    }
    costCenter {
      id
      name
    }
    member {
      ... on User {
        id
        givenName
        familyName
        email
      }
      ... on SuspendedUser {
        id
        givenName
        familyName
      }
      ... on Invitee {
        id
        email
      }
    }
    description
    itemLines {
      netAmount
      grossAmount
      natureId
      taxAmount
      taxAccount {
        id
        name
        ... on VatAccount {
          rate
        }
      }
      expenseAccount {
        id
        code
        name
        description
      }
      analyticalFieldAssociations {
        fieldKind
        fieldEntityId
        fieldEntityValueId
      }
    }
    automation {
      expenseAccount {
        kind
        supplierRule {
          supplier {
            id
            name
          }
          expenseAccount {
            id
            name
          }
        }
        expenseAccount {
          id
          name
        }
        confidence
        isAppliedOnPayable
      }
      expenseAmount {
        kind
        confidence
        isAppliedOnPayable
      }
      accountPayable {
        accountPayableMatch {
          id
          generalAccountCode
          isDefault
          isArchived
        }
        isAppliedOnPayable
      }
      tax {
        kind
        confidence
        taxAccount {
          id
          name
        }
        taxAmount
        isAppliedOnPayable
      }
      analyticalFieldAssociations {
        kind
        isAppliedOnPayable
        fieldEntityId
        fieldEntityValueId
        fieldKind
      }
    }
    amortisation {
      date {
        from
        to
      }
      schemeId
      schemeName
    }
    customFields {
      customField {
        id
        name
        optional
        __typename
      }
      ... on CustomFieldListAssociation {
        values {
          id
          value
        }
      }
      ... on CustomFieldBooleanAssociation {
        value
      }
    }
    ... on SupplierExpensePayable {
      vatConfidence
      receiptNumber
      invoiceDate
      supplier {
        id
        name
        thumbnailUrl
      }
    }
    ... on MileageAllowanceExpensePayable {
      mileageDetails {
        distance
        departure {
          coordinates
          address
        }
        vehicle {
          type
          taxHorsepower
          totalDistanceOverYear
          electric
          ownership
          engineSize
          fuelType
        }
        arrival {
          coordinates
          address
        }
        travelDate
        journey
        scheme {
          type
          rate
        }
      }
    }
    ... on PerDiemAllowanceExpensePayable {
      perDiem {
        allowance {
          totalAmount
          dailyAllowances {
            dayType
            finalAmount
            mealLegalAmount
          }
        }
        tripDays {
          stays {
            departureTime
            arrivalTime
            location {
              country
              city
              description
            }
          }
          mealsIncluded {
            isLunchIncluded
            isDinnerIncluded
            isBreakfastIncluded
          }
        }
      }
    }
  }
`;

export const GET_PAYABLE = gql`
  query GetPayable($payableId: ID!) {
    payable(id: $payableId) {
      ...detailedPayable
    }
  }
  ${detailedPayableFragment}
`;
