/* eslint-disable unicorn/filename-case */
import {
  Button,
  PanelSection,
  type PanelSectionProps,
} from '@dev-spendesk/grapes';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import ExpandablePanelItem, {
  type ExpandableItem,
} from './ExpandablePanelItem';
import SimplePanelItem, { type SimpleItem } from './SimplePanelItem';

type Props = DistributiveOmit<PanelSectionProps, 'children'> & {
  items: (SimpleItem | ExpandableItem)[];
  expandThreshold?: number;
};

export function PanelItemsSection({
  items,
  expandThreshold: expandableAfterItemsNumber,
  ...panelSectionProps
}: Props) {
  const { t } = useTranslation('global');
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  if (items.length === 0 || expandableAfterItemsNumber === 0) {
    return null;
  }

  const visibleItems = showAll
    ? items
    : items.slice(0, expandableAfterItemsNumber);

  return (
    <>
      <PanelSection {...panelSectionProps}>
        <ul>
          {visibleItems.map((item) => {
            if (item.value !== undefined && item.value !== null) {
              return (
                <SimplePanelItem
                  key={item.label}
                  item={item}
                  className={item.className}
                />
              );
            }

            return <ExpandablePanelItem key={item.label} item={item} />;
          })}
        </ul>
      </PanelSection>
      {expandableAfterItemsNumber &&
        items.length > expandableAfterItemsNumber && (
          <div className="mt-s flex justify-center">
            <Button
              variant="ghost"
              onClick={toggleShowAll}
              text={
                showAll
                  ? t('common.panelItemsSection.viewLess')
                  : t('common.panelItemsSection.viewMore')
              }
            />
          </div>
        )}
    </>
  );
}
