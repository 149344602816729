import { useUpdatePurchaseOrderRequestForm } from './useUpdatePurchaseOrderRequestForm';
import {
  type PurchaseOrderFormData,
  type PurchaseOrderRequestFormFields,
} from '../../hooks';
import { canEditRequest, type DraftPurchaseOrderRequest } from '../../models';
import { type PurchaseOrderRequestDetails } from '../models';

export type UseUpdatePurchaseOrderRequestSectionData = {
  onSave: () => void;
  onCancel: () => void;
  canEdit: boolean;
  formData: PurchaseOrderFormData;
  request: PurchaseOrderRequestDetails;
};

export const useUpdatePurchaseOrderRequestSection = (
  request: PurchaseOrderRequestDetails,
  fieldsToUpdate?: Array<keyof DraftPurchaseOrderRequest>,
): UseUpdatePurchaseOrderRequestSectionData => {
  const formData = useUpdatePurchaseOrderRequestForm(request, fieldsToUpdate);

  const canEdit = canEditRequest(request);
  const { formikProps } = formData;
  const onCancel = () => formikProps.resetForm();
  const onSave = async () => {
    // this will trigger 2 form validations
    // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
    // https://github.com/formium/formik/issues/1580
    const { submitForm, validateForm } = formikProps;
    const errors = await validateForm();

    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      throw new FormValidationError(
        JSON.stringify(errors),
        Object.keys(errors) as PurchaseOrderRequestFormFields[],
      );
    }
    return submitForm();
  };

  return { onSave, onCancel, canEdit, formData, request };
};

export class FormValidationError extends Error {
  fields: PurchaseOrderRequestFormFields[];

  constructor(message: string, fields: PurchaseOrderRequestFormFields[]) {
    super(message);
    this.fields = fields;
  }
}

export const isFormValidationError = (
  error: Error,
): error is FormValidationError => error instanceof FormValidationError;
