import React from 'react';

import { QuerySuspense } from "src/core/common/components/QuerySuspense/";

import { AppraisalsList } from '../../components/AppraisalsList';
import { useRequestQuery } from '../../hooks/useRequestQuery';

type Props = {
  requestId: string;
  className?: string;
};

export const AppraisalsListContainer = ({ requestId, className }: Props) => {
  const requestQueryState = useRequestQuery(requestId);
  return (
    <QuerySuspense
      queryState={requestQueryState}
      loading={null}
      fallback={(error) => <pre>{JSON.stringify(error)}</pre>}
    >
      {(request) => (
        <AppraisalsList appraisals={request.appraisals} className={className} />
      )}
    </QuerySuspense>
  );
};
