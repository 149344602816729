import { z } from 'zod';

import { allowedShippingCountriesForAllSfsCardOrders } from '../../../common';

export const addressSchema = z.object({
  addressLine1: z.string().max(255),
  city: z.string().max(255),
  zipcode: z.string().max(10),
  country: z.enum(allowedShippingCountriesForAllSfsCardOrders),
  addressLine2: z.string().max(255).nullable(),
});
