import { connect } from 'react-redux';

import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { useShouldCompleteKyc } from 'modules/kyc/hooks/useShouldCompleteKyc';
import { type AppState } from 'src/core/reducers';
import { getImpersonator } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import { getSelf } from 'src/core/selectors/users';

import { PageNoResult } from './PageNoResult';
import { type Mode } from './mode';

type Props = {
  mode: Mode;
  companyId: string;
  user: {
    is_requester: boolean;
    is_group_admin: boolean;
    is_admin: boolean;
    is_controller: boolean;
    is_account_owner: boolean;
    is_organisation_owner: boolean;
  };
  impersonator: { id: string } | null;
  hasFiltersApplied?: boolean;
};

const PageNoResultContainer = ({
  mode,
  companyId,
  user,
  impersonator,
  hasFiltersApplied,
}: Props) => {
  const { t } = useTranslation('global');
  const shouldCompleteKyc = useShouldCompleteKyc();
  const isUserOnlyRequester =
    user.is_requester &&
    !user.is_group_admin &&
    !user.is_admin &&
    !user.is_controller &&
    !user.is_account_owner &&
    !user.is_organisation_owner;

  return (
    <PageNoResult
      mode={mode}
      isUserRequester={user.is_requester}
      isUserOnlyRequester={isUserOnlyRequester}
      companyId={companyId}
      impersonator={impersonator}
      hasFiltersApplied={hasFiltersApplied}
      hasFeature={useFeature}
      t={t}
      shouldCompleteKyc={shouldCompleteKyc}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  companyId: getCompanyId(state),
  user: getSelf(state),
  impersonator: getImpersonator(state),
});

const ConnectedPageNoResult = connect(mapStateToProps)(PageNoResultContainer);

export { ConnectedPageNoResult as PageNoResult };
