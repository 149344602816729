import { type MonetaryValue } from 'ezmoney';

import { type PerDiem } from 'modules/per-diem';

import {
  type UkVehicleFuelType,
  type UkVehicleEngineSize,
} from '../requests/mileage/creation/model';

export enum SelectionStatus {
  All = 'ALL',
  Partial = 'PARTIAL',
  None = 'NONE',
}

export enum ExpenseProofStatus {
  Provided = 'PROVIDED',
  NotProvided = 'NOT_PROVIDED',
  Missing = 'MISSING',
  Invalid = 'INVALID',
}

export enum ExpenseType {
  cardPurchase = 'card_purchase',
  invoicePurchase = 'invoice_purchase',
  expenseClaim = 'expense_claim',
  mileageAllowance = 'mileage_allowance',
  perDiemAllowance = 'per_diem_allowance',
  reversal = 'reversal',
}

export enum PayableType {
  cardPurchase = 'cardPurchase',
  invoicePurchase = 'invoicePurchase',
  cardReversal = 'cardReversal',
  creditNote = 'creditNote',
  claimedBill = 'claimedBill',
  mileageExpense = 'mileageExpense',
  reverseBill = 'reverseBill',
  perDiemExpense = 'perDiemExpense',
}

export enum PayableSubType {
  plasticCard = 'plasticCard',
  subscriptionCard = 'subscriptionCard',
  singleUseCard = 'singleUseCard',
  creditNote = 'creditNote',
}

export enum LegacyExpenseType {
  subscription = 'subscription',
  singlePurchase = 'single_purchase',
  payment = 'payment',
  perDiemAllowance = 'per_diem_allowance',
  invoice = 'invoice',
  mileageAllowance = 'mileage_allowance',
  expense = 'expense',
}

export type ExpenseHistoryEvent = {
  id: string;
  type: string;
  occuredAt: string;
  user: {
    givenName: string;
    familyName: string;
    email: string;
  };
  data?: {
    scheduledAmount: MonetaryValue;
    executionDate: string;
    paymentMethod?: 'directDebit' | 'wireTransfer';
  };
};

// FIXME: we should have different types for the users, like in the schema
export type User = {
  id: string;
  givenName?: string;
  familyName?: string;
  email?: string;
};

export type Payment = {
  payer: User;
};

export type ExpenseProof = {
  id: string;
  mediaType: string;
  url: string;
  downloadUrl: string;
  thumbnailUrl: string;
  certifiedAt: string | null;
  createdAt: string;
};

interface BaseRawCustomField {
  customField: {
    id: string;
    name: string;
    optional: boolean;
  };
}

export interface RawCustomFieldSingleValue extends BaseRawCustomField {
  value: boolean | null;
}

export interface RawCustomFieldMultipleValue extends BaseRawCustomField {
  values: {
    id: string;
    value: string;
    default: boolean;
  }[];
}

export type RawCustomField =
  | RawCustomFieldSingleValue
  | RawCustomFieldMultipleValue;

export type ExpenseCustomField = {
  name: string;
  value: string;
  id: string;
};

type EmployeeAccount = {
  id: string;
  generalAccountCode: string;
  auxiliaryAccountCode?: string | undefined;
};

/*
 * Represents a Raw Expense preview as returned by the graphQL API
 */
export type RawExpensePreview = {
  id: string;
  prepared: boolean;
  exportedAt: string | null;
  type: ExpenseType;
  description: string | null;
  settlementDate: string | null;
  amountTotal: MonetaryValue;
  amountDeclared: MonetaryValue | null;
  payments: Payment[];
  supplier: {
    id: string;
    name: string;
    thumbnailUrl: string;
  };
  proofs: { id: string; type: 'receipt' | 'affidavit' }[];
  missingProof: { id: string } | null;
  hasInvalidProof: boolean;
};

/*
 * Represents a Raw Expense detail as returned by the graphQL API
 */
export interface RawExpenseDetail {
  id: string;
  prepared: boolean;
  description: string;
  amountTotal: MonetaryValue;
  exportedAt: string | null;
  accountedBy: User | null;
  createdAt: string;
  settlementDate: string;
  type: ExpenseType;
  subType: PayableSubType | null;
  payments: Payment[];
  team: {
    id: string;
    name: string;
  } | null;
  costCenter: {
    id: string;
    name: string;
  } | null;
  costCentersFromItemLines: string[];
  proofs: ExpenseProof[];
  missingProof: {
    id: string;
    type: string;
    message: string;
    reporter: {
      id: string;
      givenName: string;
    };
  } | null;
  hasInvalidProof: boolean;
  chargeItemGroup?: {
    charge?: {
      expenseAccount: {
        name: string;
      };
    };
  }[];
  vatItemGroup: {
    vat?: {
      amount: MonetaryValue;
      vatAccount: {
        code: string;
        name: string;
        rate: string;
      };
    };
  }[];
  customFields: RawCustomField[];
  customFieldsFromItemLines: Map<string, string[]>;
  history: ExpenseHistoryEvent[];
}

export interface RawSupplierExpense extends RawExpenseDetail {
  receiptNumber?: string;
  invoiceDate?: string;
  lastReminder: string | null;
  amountDeclared: MonetaryValue;
  amountConverted?: MonetaryValue;
  fxFees?: MonetaryValue;
  supplier: {
    name: string;
    thumbnailUrl: string;
  };
  categories: string[];
  supplierAccount: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode: string | undefined;
    supplier?: { id: string };
  } | null;
}

export interface RawExpenseClaim extends RawExpenseDetail {
  receiptNumber?: string;
  invoiceDate?: string;
  lastReminder: string | null;
  amountDeclared: MonetaryValue;
  amountConverted?: MonetaryValue;
  fxFees?: MonetaryValue;
  supplier: {
    name: string;
    thumbnailUrl: string;
  };
  categories: string[];
  employeeAccount: EmployeeAccount | null;
}

// Mileage types

type Coordinates = {
  lat: number;
  lng: number;
};

type Position = {
  coordinates: Coordinates;
  address: string;
};

export type Distance = {
  value: number;
  unit: string;
};

export type Vehicle = {
  type: 'car' | 'motorcycle' | 'bike';
  taxHorsepower: number | null;
  totalDistanceOverYear: Distance;
  electric: boolean;
  engineSize?: UkVehicleEngineSize;
  fuelType?: UkVehicleFuelType;
  ownership?: 'company' | 'personal' | null;
};

export type MileageScheme = {
  type: 'french' | 'german' | 'uk' | 'custom';
  rate: string | null;
};

export interface RawMileageAllowanceExpense extends RawExpenseDetail {
  distance: Distance;
  departure: Position;
  arrival: Position;
  vehicle: Vehicle;
  travelDate: string;
  journey: Coordinates[];
  distanceUnit: string;
  scheme: MileageScheme;
  employeeAccount: EmployeeAccount | null;
}

interface RawPerDiemAllowanceExpense extends RawExpenseDetail {
  perDiem: PerDiem;
  employeeAccount: EmployeeAccount | null;
}

// TODO: Handle different kind of expenses
export type RawExpense =
  | RawSupplierExpense
  | RawExpenseClaim
  | RawMileageAllowanceExpense
  | RawPerDiemAllowanceExpense;

/*
 * Represents an Expense preview to be used by the module's components
 */
export type ExpensePreview = {
  id: string;
  label: string;
  sublabel: string;
  amount: MonetaryValue;
  originalAmount?: MonetaryValue;
  date: string;
  proofStatus: ExpenseProofStatus;
  supplierImageSrc?: string;
  type: ExpenseType;
  payer: User | null;
};

interface Expense {
  id: string;
  isExported: boolean;
  type: ExpenseType;
  subType: PayableSubType | undefined;
  description: string;
  amount: MonetaryValue;
  originalAmount?: MonetaryValue;
  expenseAccount: string[];
  vat: string[];
  team: string | null;
  costCenter: string | null;
  costCentersFromItemLines: string[];
  history: ExpenseHistoryEvent[];
  proofs: ExpenseProof[];
  hasInvalidProof: boolean;
  customFields: ExpenseCustomField[];
  customFieldsFromItemLines: Map<string, string[]>;
  version?: number; // Use by the new Foundations API
  payer: User | null;
  exportedAt: string | null;
  accountedBy: User | null;
}

export interface SupplierExpense extends Expense {
  receiptNumber?: string;
  invoiceDate?: string;
  supplierName?: string;
  supplierId?: string;
  supplierImageSrc?: string;
  categories: string[];
  supplierAccount: {
    id: string;
    generalAccountCode: string;
    auxiliaryAccountCode: string | undefined;
    supplier?: { id: string };
  } | null;
}

export interface ExpenseClaim extends Expense {
  receiptNumber?: string;
  invoiceDate?: string;
  supplierName?: string;
  supplierImageSrc?: string;
  categories: string[];
  employeeAccount: EmployeeAccount | null;
}

export type MileageAllowanceDetails = {
  departure: Position;
  arrival: Position;
  distance: Distance;
  scheme: MileageScheme;
  travelDate: string;
  vehicle: Vehicle;
  journey?: { lat: number; lng: number }[];
};

export type MileageAllowanceExpense = Expense &
  MileageAllowanceDetails & { employeeAccount: EmployeeAccount | null };

export type CreditNoteDetails = {
  creditNoteNumber: string;
  issueDate: string;
  referenceInvoiceRequestNumber: string | undefined;
  referenceInvoiceRequestId: string | undefined;
};

export type CreditNoteReversal = Expense & {
  categories: string[];
  supplierName?: string;
  supplierImageSrc?: string;
  creditNoteDetails: CreditNoteDetails;
};

export interface PerDiemAllowanceExpense extends Expense {
  perDiem: PerDiem;
  employeeAccount: EmployeeAccount | null;
}

/*
 * Represents an Expense detail to be used by the module's components
 */
export type AnyExpense =
  | SupplierExpense
  | ExpenseClaim
  | MileageAllowanceExpense
  | PerDiemAllowanceExpense
  | CreditNoteReversal;

export type Payable = {
  id: string;
  label: string;
  sublabel: string;
  amount: MonetaryValue;
  originalAmount: MonetaryValue;
  date: string;
  proofStatus: ExpenseProofStatus;
};

export type PayableWithContext = Payable & {
  isActive: boolean;
  selectionStatus: SelectionStatus;
};

export type Group = {
  id: string;
  label: React.ReactChild | string;
  thumbnailUrl: string;
  totalCount: number;
  totalAmount: MonetaryValue;
  connectionId: string;
};

export type GroupWithContext = Group & {
  selectionStatus: SelectionStatus;
};

export type Supplier = {
  id: string;
  name: string | null;
  thumbnailUrl: string;
  isArchived: boolean;
};

export type CostCenter = {
  id: string;
  name: string;
};
