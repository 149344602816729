import compact from 'lodash/compact';
import map from 'lodash/map';

import { type SupplierAccountDefaultFor } from 'modules/bookkeep/settings/accounting';
import { type QueryClient } from 'src/core/api/client';
import { type Mutate } from 'src/core/api/hooks/useMutation';
import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { normalizeString } from 'src/core/common/utils/normalizeString';
import {
  type CreateOrUpdateAccountPayablePayload,
  type CreateOrUpdateAccountPayableResponse,
} from 'src/core/modules/accounting-integration/apis/useCreateOrUpdateAccountPayable';
import { removeNullValues } from 'src/core/modules/custom-fields/utils/removeNullValues';

import {
  type FieldValue,
  type FieldValues,
  reshapeTeamsResultData,
  reshapeSupplierAccountsResultData,
  reshapeCustomFieldsValuesResultData,
} from './reshaper';
import { getCustomFieldsQueryFetcher } from '../../../prepare-payables/components/PreparePayablesInbox/hooks/getCustomFieldsQueryFetcher';
import { getSupplierAccountsQueryFetcher } from '../../../prepare-payables/components/PreparePayablesInbox/hooks/getSupplierAccountsQueryFetcher';
import { getTeamsQueryFetcher } from '../../../prepare-payables/components/PreparePayablesInbox/hooks/getTeamsQueryFetcher';
import {
  type Payload as CreateCustomFieldPayload,
  type Response as CreateCustomFieldResponse,
} from '../../../prepare-payables/components/PreparePayablesInbox/hooks/useCreateCustomFieldMutation/useCreateCustomFieldMutation';
import {
  type PreparePayablesInputConfig,
  type InputValueEntry,
} from '../../../prepare-payables/components/PreparePayablesInbox/panelInputConfig';
import {
  type CustomField,
  type CustomFieldType,
  filterCustomFieldsForPayable,
  filterActiveCustomFields,
  type PayableType,
} from '../../../prepare-payables/models';

const buildInputConfig = ({
  id,
  name,
  fieldValues,
  isReadOnly,
  isSplittable,
  onCreateNewValue,
  onCreateNewValueMutation,
  onLoadValues,
  onSearchValues,
  onSearchLocalValues,
}: {
  id: string;
  name: string;
  fieldValues: {
    values: FieldValue[];
    valuesTotalCount: number;
  };
  isReadOnly: boolean;
  isSplittable?: boolean;
  onCreateNewValue?: (newValue: string) => Promise<string>;
  onCreateNewValueMutation?: (
    newValue: string,
  ) => Promise<CreateOrUpdateAccountPayableResponse>;
  onLoadValues: (idsToLoad: string[]) => Promise<InputValueEntry[]>;
  onSearchValues: (query: string) => Promise<InputValueEntry[]>;
  onSearchLocalValues?: (query: string) => InputValueEntry[];
}): PreparePayablesInputConfig => {
  const hasMoreValues =
    fieldValues.values.length < fieldValues.valuesTotalCount;

  // If input has more values than the ones loaded, use a search input
  if (hasMoreValues) {
    return {
      id,
      name,
      type: 'search',
      onCreateNewValue,
      onCreateNewValueMutation,
      onLoadValues,
      onSearchValues,
      isReadOnly,
      isSplittable,
      totalCount: fieldValues.valuesTotalCount,
    };
  }

  // Otherwise use a default select input
  return {
    id,
    name,
    type: 'select',
    values: fieldValues.values,
    totalCount: fieldValues.valuesTotalCount,
    onCreateNewValueMutation,
    onCreateNewValue,
    onSearchValues: onSearchLocalValues,
    isReadOnly,
    isSplittable,
  };
};

export const buildBaseInputsConfig = ({
  fieldsValues,
  companyId,
  queryClient,
  translator,
  options = {
    fields: {},
  },
  createSupplierAccount,
  isAnalyticalSplitActivated,
}: {
  fieldsValues: FieldValues;
  companyId: string;
  queryClient: QueryClient;
  translator: TGlobalFunctionTyped;
  options: {
    fields?: {
      supplierAccount?: {
        visible: boolean;
        isReadOnly: boolean;
        defaultFor: SupplierAccountDefaultFor;
      };
      team?: {
        visible: boolean;
      };
      costCenter?: {
        visible: boolean;
      };
    };
  };
  createSupplierAccount: Mutate<
    CreateOrUpdateAccountPayablePayload,
    CreateOrUpdateAccountPayableResponse
  >;
  isAnalyticalSplitActivated: boolean;
}): PreparePayablesInputConfig[] => {
  const buildSupplierAccountInputConfig = () =>
    fieldsValues.supplierAccounts
      ? buildInputConfig({
          id: 'supplierAccount',
          name: translator('expenseInbox.filters.supplierAccount'),
          fieldValues: fieldsValues.supplierAccounts,
          isReadOnly: options.fields?.supplierAccount?.isReadOnly ?? false,
          onLoadValues: async (idsToLoad) => {
            const data = await queryClient.fetchQuery(
              ['useGetSupplierAccountsQuery', companyId, idsToLoad],
              getSupplierAccountsQueryFetcher({
                companyId,
                filter: {
                  ids: idsToLoad,
                },
              }),
              {
                cacheTime: 10 * 60 * 1000, // 10min,
                staleTime: 10 * 60 * 1000, // 10min,
              },
            );

            return reshapeSupplierAccountsResultData(
              data.company.chartOfAccounts.supplierAccounts,
            ).values;
          },
          onSearchValues: async (search) => {
            const data = await queryClient.fetchQuery(
              ['useGetSupplierAccountsQuery', companyId, search],
              getSupplierAccountsQueryFetcher({
                companyId,
                filter: {
                  search,
                },
              }),
              {
                cacheTime: 10 * 60 * 1000, // 10min,
                staleTime: 10 * 60 * 1000, // 10min,
              },
            );
            return reshapeSupplierAccountsResultData(
              data.company.chartOfAccounts.supplierAccounts,
            ).values;
          },
          onCreateNewValueMutation: async (newValue) => {
            return createSupplierAccount({
              generalAccountCode: newValue,
              isArchived: false,
            });
          },
        })
      : null;

  const buildTeamInputConfig = () =>
    buildInputConfig({
      id: 'team',
      name: translator('expenseInbox.filters.team'),
      fieldValues: fieldsValues.teams,
      isReadOnly: false,
      onLoadValues: async (idsToLoad) => {
        const data = await queryClient.fetchQuery(
          ['useGetTeamsQuery', companyId, idsToLoad],
          getTeamsQueryFetcher({
            companyId,
            filter: {
              ids: idsToLoad,
            },
          }),
          {
            cacheTime: 10 * 60 * 1000, // 10min,
            staleTime: 10 * 60 * 1000, // 10min,
          },
        );
        return reshapeTeamsResultData(data.company.teams).values;
      },
      onSearchValues: async (search) => {
        const data = await queryClient.fetchQuery(
          ['useGetTeamsQuery', companyId, search],
          getTeamsQueryFetcher({
            companyId,
            filter: {
              search,
            },
          }),
          {
            cacheTime: 10 * 60 * 1000, // 10min,
            staleTime: 10 * 60 * 1000, // 10min,
          },
        );
        return reshapeTeamsResultData(data.company.teams).values;
      },
    });

  const buildCostCenterInputConfig = () => {
    const { costCenters } = fieldsValues;
    return costCenters
      ? buildInputConfig({
          id: 'costCenter',
          name: translator('expenseInbox.filters.costCenter'),
          fieldValues: costCenters,
          isReadOnly: false,
          isSplittable: isAnalyticalSplitActivated, // Cost centers are always splittable when the analytical split feature is activated
          onLoadValues: async (idsToLoad) => {
            return costCenters?.values.filter((costCenter) =>
              idsToLoad.includes(costCenter.key),
            );
          },
          onSearchValues: async (query) => {
            return costCenters?.values.filter((costCenter) =>
              costCenter.label.toLowerCase().includes(query.toLowerCase()),
            );
          },
        })
      : null;
  };

  return compact([
    options?.fields?.supplierAccount?.visible
      ? buildSupplierAccountInputConfig()
      : null,
    options?.fields?.team?.visible ? buildTeamInputConfig() : null,
    options?.fields?.costCenter?.visible ? buildCostCenterInputConfig() : null,
  ]);
};

const buildCustomFieldInputConfig = ({
  id,
  name,
  type,
  isOptional,
  isSplittable,
  allowNewValue,
  archiveDate,
  values,
  valuesTotalCount,
  onLoadValues,
  onSearchValues,
  onCreateNewValue,
  onSearchLocalValues,
  isAnalyticalSplitActivated,
}: {
  id: string;
  name: string;
  type: CustomFieldType;
  isOptional: boolean;
  isSplittable: boolean;
  allowNewValue: boolean;
  archiveDate: string | null;
  values: FieldValue[];
  valuesTotalCount: number;
  onLoadValues: (idsToLoad: string[]) => Promise<InputValueEntry[]>;
  onSearchValues: (query: string) => Promise<InputValueEntry[]>;
  onCreateNewValue?: (newValue: string) => Promise<string>;
  onSearchLocalValues?: (query: string) => InputValueEntry[];
  isAnalyticalSplitActivated: boolean;
}): PreparePayablesInputConfig => {
  // Set basic input attributes
  const inputCommonAttributes = {
    id,
    name,
    isOptional,
    isCustom: true,
    isReadOnly: archiveDate !== null,
    allowNewValue,
    isSplittable: isSplittable && isAnalyticalSplitActivated,
  };
  const hasMoreValues = values.length < valuesTotalCount;

  if (type === 'Boolean') {
    return {
      ...inputCommonAttributes,
      type: 'boolean',
      values: values as unknown as {
        key: string;
        label: boolean;
      }[], // Fixme with type { id: InputValueId; name: boolean }[];
    };
  }

  if (type === 'List' && hasMoreValues) {
    return {
      ...inputCommonAttributes,
      type: 'search',
      onLoadValues,
      onSearchValues,
      onCreateNewValue,
    };
  }

  return {
    ...inputCommonAttributes,
    type: 'select',
    values,
    onSearchValues: onSearchLocalValues,
    onCreateNewValue,
  };
};

export const buildCustomFieldsInputsConfig = ({
  customFields,
  companyId,
  queryClient,
  createCustomField,
  // Next values are used to filter customFields on an expense
  customFieldsValues,
  teamId,
  expenseTypes,
  expenseCategoryCustomFieldId,
  translator,
  isAnalyticalSplitActivated,
}: {
  customFields: CustomField[];
  companyId: string;
  queryClient: QueryClient;
  customFieldsValues: Record<string, boolean | string>;
  teamId?: string;
  expenseTypes: PayableType[];
  createCustomField: Mutate<
    CreateCustomFieldPayload,
    CreateCustomFieldResponse
  >;
  expenseCategoryCustomFieldId: string | undefined;
  translator: TGlobalFunctionTyped;
  isAnalyticalSplitActivated: boolean;
}): PreparePayablesInputConfig[] => {
  const onLoadValues =
    (customFieldId: string) => async (idsToLoad: string[]) => {
      const data = await queryClient.fetchQuery(
        ['useGetCustomFieldsQuery', companyId, customFieldId, idsToLoad],
        getCustomFieldsQueryFetcher({
          companyId,
          filter: {
            customFieldId,
            customFieldValueIds: idsToLoad,
            includeArchivedValues: true,
          },
        }),
        {
          cacheTime: 10 * 60 * 1000, // 10min,
          staleTime: 10 * 60 * 1000, // 10min,
        },
      );
      return reshapeCustomFieldsValuesResultData(data.company.customFields);
    };

  const onSearchValues = (customFieldId: string) => async (search: string) => {
    const data = await queryClient.fetchQuery(
      ['useGetCustomFieldsQuery', companyId, customFieldId, search],
      getCustomFieldsQueryFetcher({
        companyId,
        filter: {
          customFieldId,
          search,
          includeArchivedValues: false,
        },
      }),
      {
        cacheTime: 10 * 60 * 1000, // 10min,
        staleTime: 10 * 60 * 1000, // 10min,
      },
    );
    return reshapeCustomFieldsValuesResultData(data.company.customFields);
  };

  const onCreateNewValue =
    (customFieldId: string) => async (newValue: string) => {
      const newValueWithoutNulls = removeNullValues(newValue);
      const { createCustomFieldValue } = await createCustomField({
        customFieldId,
        value: newValueWithoutNulls,
      });

      if (createCustomFieldValue.outcome !== 'created') {
        throw new Error('Failed to create custom field value');
      }

      return createCustomFieldValue.customFieldValue.id;
    };

  const onSearchLocalValues = (values: FieldValue[]) => (query: string) => {
    return values.filter((item) => {
      const deburredItemName = normalizeString(item.label);
      const deburredQuery = normalizeString(query);

      return deburredItemName.includes(deburredQuery);
    });
  };

  const nonArchivedCustomFields = customFields.filter(
    (customField) => !customField.archiveDate,
  );

  const eligibleCustomFields = customFieldsValues
    ? // TODO: the back-end should return the ids of the custom fields
      filterCustomFieldsForPayable(
        nonArchivedCustomFields,
        customFieldsValues,
        teamId,
        expenseTypes,
      )
    : filterActiveCustomFields(nonArchivedCustomFields);

  return map(eligibleCustomFields, (customField) => {
    const customFieldName =
      customField.id === expenseCategoryCustomFieldId
        ? translator('expenseInbox.filters.expenseCategory')
        : customField.name;

    return buildCustomFieldInputConfig({
      ...customField,
      name: customFieldName,
      values:
        customField.type === 'List'
          ? customField.values
              ?.filter(
                (value) =>
                  value.archiveDate === null ||
                  (customFieldsValues &&
                    value.id === customFieldsValues[customField.id]),
              )
              .map((customFieldValue) => ({
                key: customFieldValue.id,
                label: `${customFieldValue.name}`,
                archiveDate: customFieldValue.archiveDate,
              }))
          : customField.values.map((customFieldValue) => ({
              key: customFieldValue.id,
              label: `${customFieldValue.name}`,
              archiveDate: customFieldValue.archiveDate,
            })),
      onLoadValues: onLoadValues(customField.id),
      onSearchValues: onSearchValues(customField.id),
      onCreateNewValue: onCreateNewValue(customField.id),
      onSearchLocalValues: onSearchLocalValues(
        customField.values.map((customFieldValues) => ({
          key: customFieldValues.id,
          label: `${customFieldValues.name}`,
          archiveDate: customFieldValues.archiveDate,
        })),
      ),
      isAnalyticalSplitActivated,
    });
  });
};
