import isEmpty from 'lodash/isEmpty';

import { type CustomFieldPayload } from '../containers/customField';

export const convertValues = (
  values?: string,
): NonNullable<CustomFieldPayload['values']> => {
  if (!values) {
    return [];
  }
  return (values ?? '')
    .split('\n')
    .filter((value) => !isEmpty(value))
    .map((value) => ({
      value: value.trim(),
      isDefault: false,
      costCenterIds: [],
    }));
};
