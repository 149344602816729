import { useQueryClient } from 'react-query';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

const requestQueryKey = (requestId: string) => ['requests', requestId];

export const useRequestQuery = <T extends { id: string }>(
  requestId: string,
): QueryState<T> => {
  return useQuery<T>({
    key: requestQueryKey(requestId),
    options: {
      cacheTime: 1 * 1000, // 5sec,
      staleTime: 1 * 1000, // 5sec,
    },
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}`,
    },
    isEnabled: requestId !== '',
  });
};

export const useInvalidateRequestQuery = () => {
  const queryClient = useQueryClient();
  return (requestId: string) =>
    queryClient.invalidateQueries(requestQueryKey(requestId));
};
