import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Appraisal = {
  id: string;
  avatar?: string;
  fullname: string;
  approvedAt: string;
};

export const useRequestAppraisalsQuery = (
  requestId: string,
): QueryState<Appraisal[]> => {
  return useQuery<Appraisal[]>({
    key: ['requests', requestId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/${requestId}`,
    },
    reshapeData: (data) => {
      if (data.type === 'purchase_order') {
        return (
          data.approval?.appraisals
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ?.filter(({ automatic }: any) => !automatic)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((appraisal: any) => ({
              id: appraisal?.approver.id,
              fullname: appraisal?.approver.fullName,
              avatar: appraisal?.approver.avatar,
              approvedAt: appraisal?.updatedAt,
            }))
        );
      }
      return (
        data.appraisals
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.filter(({ automatic }: any) => !automatic)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((appraisal: any) => ({
            id: appraisal?.approver.id,
            fullname: appraisal?.approver.fullname,
            avatar: appraisal?.approver.avatar,
            approvedAt: appraisal?.updated_at,
          }))
      );
    },
  });
};
