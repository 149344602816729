import { Button, DATE_FORMAT, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const BookkeepingStartDateConfirmationModal = ({
  hiddenPayablesCount = 0,
  bookkeepingStartDate,
  isOpen,
  onCancel,
  onConfirm,
}: {
  hiddenPayablesCount: number | undefined;
  bookkeepingStartDate: Date | undefined;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
}) => {
  const { t, localeFormat } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      iconName="warning"
      iconVariant="warning"
      title={
        hiddenPayablesCount > 0
          ? t('accounting.bookkeepingStartDate.confirmationModal.title', {
              count: hiddenPayablesCount,
              date:
                bookkeepingStartDate &&
                localeFormat(bookkeepingStartDate, DATE_FORMAT.SHORT),
            })
          : t(
              'accounting.bookkeepingStartDate.confirmationModal.titleNoHiddenPayables',
            )
      }
      actions={[
        <Button
          key="no"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={() => onCancel()}
        />,
        <Button
          key="yes"
          variant="warning"
          text={t('accounting.bookkeepingStartDate.confirmationModal.confirm')}
          onClick={async () => {
            await onConfirm();
          }}
        />,
      ]}
    >
      <div>
        {t('accounting.bookkeepingStartDate.confirmationModal.content', {
          count: hiddenPayablesCount,
        })}
      </div>
    </Modal>
  );
};
