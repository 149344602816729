import forEach from 'lodash/forEach';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import SubscriptionItem from './LegacySubscriptionItem';
import {
  formatBaseItem,
  formatMiddleTierItem,
  formatExtraItem,
  USAGE_UNITS,
} from '../helpers';

const LegacySubscriptionUsageItem = ({ unit, usage, t }) => {
  // For now, we don't handle empty usage for constant price
  if (isEmpty(usage)) {
    return null;
  }

  const firstUsage = head(usage);
  const { currency } = firstUsage.base;
  let base = '';
  const extra = [];

  // Format base
  if (firstUsage.base.amount === 0) {
    const value = usage[1] ? usage[1].floor : Number.POSITIVE_INFINITY;
    const monetaryValue = firstUsage.factor;
    base = formatBaseItem(value, { monetaryValue, unit, currency, t });
  }

  // Format middle tiers item
  if (usage.length > 2) {
    forEach(usage.slice(1, -1), (currentUsage, index) => {
      const from = currentUsage.floor + 1;
      const to = usage[index + 2].floor; // +2 because we want the next item (+1) and we iterate in a `usage` subarray starting at 1
      const value = currentUsage.factor;
      extra.push(
        formatMiddleTierItem(from, to, value, {
          unit,
          currency,
          t,
        }),
      );
    });
  }

  // Format extra tier item
  if (usage.length > 1) {
    const value = usage.at(-1).factor;
    extra.push(formatExtraItem(value, { unit, currency, t }));
  }

  return <SubscriptionItem base={base} extra={extra} />;
};

LegacySubscriptionUsageItem.propTypes = {
  unit: PropTypes.oneOfType([
    PropTypes.oneOf(values(USAGE_UNITS)),
    PropTypes.string, // we handle unknown unit as a generic one
  ]).isRequired,
  usage: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LegacySubscriptionUsageItem);
