import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  getScheduledWireTransfersAmountQueryParameters,
  getScheduledWireTransfersAmountSchema,
} from './get-scheduled-wire-transfers-amount';
import { getTransfersFromBankableToSfsSchema } from './get-transfers-from-bankable-to-sfs';
import { NotFoundError, ServerError } from '../../common/errors';

export const wtRequestsRouterContract = initContract().router({
  getScheduledWireTransfersAmount: {
    summary:
      'Get the total amount for the scheduled wire transfers for a company',
    method: 'GET',
    path: '/scheduledWireTransfersAmount',
    responses: {
      200: z.object({
        data: getScheduledWireTransfersAmountSchema,
      }),
      ...ServerError,
      ...NotFoundError,
    },
    query: getScheduledWireTransfersAmountQueryParameters,
  },
  getTransfersFromBankableToSfs: {
    summary: 'Get all the transfers from Bankable to SFS account',
    method: 'GET',
    path: '/transfers-from-bankable-to-sfs-account',
    responses: {
      200: z.object({
        data: getTransfersFromBankableToSfsSchema,
      }),
      ...ServerError,
      ...NotFoundError,
    },
  },
});
