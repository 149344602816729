import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { type Company } from 'modules/app/hooks/useCompany';
import { actions as walletActions } from 'modules/company/wallet';
import { type AppState } from 'src/core/reducers';

import { LegacyHomepageWallet } from './LegacyHomepageWallet';
import {
  getAccountLoads,
  getIsLoadingWallet,
  getWalletInfos,
} from '../../redux/selectors';
import { type AccountLoad, type WalletInfos } from '../../wallet';
import { HomepageWalletPlaceholder } from '../HomepageWalletPlaceholder';

type Props = {
  accountLoads: AccountLoad[];
  company: Company;
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchAccountLoads: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchWalletInfos: Function;
  isLoadingWallet: boolean;
  walletInfos: WalletInfos;
};

const LegacyHomepageWalletContainer = ({
  accountLoads,
  company,
  fetchAccountLoads,
  fetchWalletInfos,
  isLoadingWallet,
  walletInfos,
}: Props) => {
  useEffect(() => {
    fetchAccountLoads();
    fetchWalletInfos();
  }, []);

  if (isLoadingWallet) {
    return <HomepageWalletPlaceholder />;
  }

  return (
    <LegacyHomepageWallet
      accountLoads={accountLoads}
      company={company}
      walletInfos={walletInfos}
    />
  );
};

const mapStateToProps = (state: AppState) => {
  const accountLoads = getAccountLoads(state);
  const isLoadingWallet = getIsLoadingWallet(state);
  const walletInfos = getWalletInfos(state);
  return {
    accountLoads,
    isLoadingWallet,
    walletInfos,
  };
};

const mapDispatchToProps = {
  fetchAccountLoads: walletActions.fetchAccountLoads,
  fetchWalletInfos: walletActions.fetchWalletInfos,
};

const ConnectedLegacyLegacyHomepageWalletContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LegacyHomepageWalletContainer);

export { ConnectedLegacyLegacyHomepageWalletContainer as LegacyHomepageWalletContainer };
