import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type SpendingAllocationPeriod } from '../containers/PayablePanelContainer';

type Member =
  | {
      type: 'user';
      givenName: string;
      familyName: string;
      email: string;
    }
  | {
      type: 'suspendedUser';
      givenName: string;
      familyName: string;
    }
  | { type: 'invitee'; email: string };

export const formatMemberName = (
  member: Member | undefined,
  translator: TGlobalFunctionTyped,
): string => {
  if (!member) {
    return translator('misc.unknownMember');
  }
  if (member.type === 'invitee') {
    return member.email;
  }
  if (member.givenName || member.familyName) {
    return [member.givenName, member.familyName].filter(Boolean).join(' ');
  }
  if (member.type === 'suspendedUser') {
    return translator('misc.unknownMember');
  }
  return member.email;
};

export const formatSpendingAllocationPeriod = (
  spendingAllocationPeriod: SpendingAllocationPeriod | undefined,
  formatDate: (date: Date) => string,
): string => {
  if (!spendingAllocationPeriod) {
    return '-';
  }

  if (spendingAllocationPeriod.date) {
    return formatDate(spendingAllocationPeriod.date);
  }
  const { period } = spendingAllocationPeriod;
  return `${formatDate(period.from)} > ${formatDate(period.to)}`;
};
