import { type ThunkDispatch } from '@reduxjs/toolkit';

import * as actionsTypes from '../actionTypes/global';
import { companyAPI } from '../api/axios';
import { type AppState } from '../reducers';
import { getCompanyId } from '../selectors/globalSelectorsTyped';

const markOnboardingPopupAsSeenAction = (payload: {
  key:
    | 'showKybProcedureValidatedPopup'
    | 'showKybProcedureLimitedAccessOpenedPopup'
    | 'showRequesterOnboardingPopup';
}): actionsTypes.MarkOnboardingPopupAsSeen => ({
  type: actionsTypes.MARK_ONBOARDING_POPUP_AS_SEEN,
  payload,
});

export const dismissWelcomeDashboard =
  (): actionsTypes.DismissWelcomeDashboard => ({
    type: actionsTypes.DISMISS_WELCOME_DASHBOARD,
  });

class TypeGuardError extends Error {
  constructor(_switchCase: never, message: string) {
    super(message);
  }
}

type MarkOnboardingPopupAsSeenCode =
  | 'KYB_PROCEDURE_VALIDATED'
  | 'KYB_PROCEDURE_LIMITED_ACCESS_OPENED'
  | 'REQUESTER_SLIDESHOW';

const getMarkPopupAsSeenKeyFromCode = (code: MarkOnboardingPopupAsSeenCode) => {
  switch (code) {
    case 'KYB_PROCEDURE_VALIDATED':
      return 'showKybProcedureValidatedPopup';
    case 'KYB_PROCEDURE_LIMITED_ACCESS_OPENED':
      return 'showKybProcedureLimitedAccessOpenedPopup';
    case 'REQUESTER_SLIDESHOW':
      return 'showRequesterOnboardingPopup';
    default:
      throw new TypeGuardError(code, `Unknown code when marking popup as seen`);
  }
};

export const markOnboardingPopupAsSeen =
  (code: MarkOnboardingPopupAsSeenCode) =>
  async (
    dispatch: ThunkDispatch<AppState, null, actionsTypes.GlobalActions>,
    getState: () => AppState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getCompanyId(state);

    try {
      await companyAPI.post(
        '/user/popup-seen',
        { code },
        {
          companyId,
        },
      );
    } catch {
      // We don't care about the error
    }

    const key = getMarkPopupAsSeenKeyFromCode(code);
    dispatch(markOnboardingPopupAsSeenAction({ key }));
  };
