import React from 'react';

import { FileViewerModal } from '../FileViewerModal/FileViewerModal';
import { Modes } from '../FileViewerToolbar/FileViewerToolbar';
import { ImageViewer, type ImageViewerProps } from '../ImageViewer/ImageViewer';

type Props = {
  fileViewerModalClassName?: string;
  onClose?: () => void | undefined;
} & Omit<ImageViewerProps, 't' | 'tReady' | 'i18n'>;

export const ImageViewerModal = ({
  onClose,
  fileViewerModalClassName = '',
  ...imageViewerProps
}: Props) => (
  <FileViewerModal
    onClose={() => (onClose ? onClose() : undefined)}
    className={fileViewerModalClassName}
  >
    <ImageViewer
      {...imageViewerProps}
      onClose={onClose}
      toolbarMode={Modes.Compact}
    />
  </FileViewerModal>
);
