import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';

import { type Company } from 'modules/app/hooks/useCompany';
import { type User } from 'modules/app/hooks/useUser';
import type { CostCenter } from 'modules/budgets/models/costCenter';
import type { Team } from 'modules/budgets/models/team';
import { SiderPageLayout } from 'src/core/common/components/layout';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';
import { type PushNotif } from 'src/core/modules/app/notifications';
import {
  isDraftsTab,
  hasApproverSubNav,
  SubnavigationItem,
  canUserHandleSharedDraftInvoice,
  isSharedDraftInvoice,
  type LegacyRequest,
  type RequestAPI,
} from 'src/core/modules/requests';
import { FloatingRequestPanel } from 'src/core/modules/requests/panel/FloatingPanelRequest';

import { RequestFilters } from './RequestFilters/RequestFilters';
import { RequestsListBox } from './RequestsListBox/RequestsListBox';
import { type RequestStats, type Sections } from './requestsSectionsProps';
import { NewRequestModalSlideshow } from '../../components/NewRequestModalSlideshow';
import { RequestsSubNavContainer } from '../../components/RequestsSubNav';
import { LazyRequestsPanel } from '../../creation/components/LazyRequestsPanel/LazyRequestsPanel';
import { type CustomFieldDefinition } from '../../models';
import { type MileageScheme } from '../../models/mileageScheme';

import './Requests.css';

/* eslint-disable react/no-this-in-sfc */
// Inheritance confuses ESLint
type Props = {
  customFields?: CustomFieldDefinition[];
  groups?: Team[];
  costCenters: CostCenter[];
  company: Company;
  user: User;
  itemId?: string;
  newRequestType?: string;
  history: { push: (route: string) => void };
  type: SubnavigationItem | 'request';
  updateRequest: () => void;
  uploadInvoiceFile: () => void;
  addRequestLocally: () => void;
  approveRequest: () => void;
  denyRequest: (
    requestId: string,
    reason: string,
    updateLocally: boolean,
    callback: (response: Response) => void,
  ) => void;
  updateRequestLocally: () => void;
  removeRequestLocally: () => void;
  loadCardRequest: () => void;
  uploadFile: () => void;
  pushNotif: PushNotif;
  sectionsStats: Sections<RequestStats>;
  requestsSections: Sections<
    (LegacyRequest & RequestAPI & { mileage_scheme: MileageScheme })[]
  >;
  isLoading?: boolean;
  hasFilters?: boolean;
  setRequestFilters: (query: object) => void;
  setTextFilter: (query: string) => void;
};

const Requests = ({
  addRequestLocally,
  approveRequest,
  company,
  costCenters,
  customFields,
  denyRequest,
  groups,
  hasFilters,
  history,
  isLoading,
  itemId,
  loadCardRequest,
  newRequestType,
  pushNotif,
  removeRequestLocally,
  requestsSections,
  sectionsStats,
  setRequestFilters,
  setTextFilter,
  type,
  user,
  uploadFile,
  updateRequest,
  updateRequestLocally,
  uploadInvoiceFile,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const isDraftExpenseClaimFeatureEnabled = useFeature(
    FEATURES.DRAFTS_EXPENSE_CLAIMS,
  );
  const hasNewNavigation = useFeature(FEATURES.TMP_VERTICAL_NAVIGATION);

  const isPanelOpen = !!itemId && itemId !== 'new';
  const isFloatingPanelOpen = itemId === 'new';
  const [checkedRequests, setCheckedRequests] = useState<string[]>([]);
  const [checkedDraftRequests, setCheckedDraftRequests] = useState<string[]>(
    [],
  );

  const hasResults = () => {
    return getAllRequests().length > 0;
  };

  // Only select sections we want to display
  const getActiveRequestsSections = () => {
    if (type === SubnavigationItem.All && hasApproverSubNav(user)) {
      const sections = {
        purchaseRequests: requestsSections.purchaseRequests,
        expenseClaimRequests: requestsSections.expenseClaimRequests,
        invoiceRequests: requestsSections.invoiceRequests,
        purchaseOrderRequests: requestsSections.purchaseOrderRequests,
        mileageAllowanceRequests: requestsSections.mileageAllowanceRequests,
        perDiemAllowanceRequests: requestsSections.perDiemAllowanceRequests,
        creditNoteRequests: requestsSections.creditNoteRequests,
      };
      return omitBy(sections, isEmpty);
    }

    if (isDraftsTab(type)) {
      const sections = {
        myDraftInvoiceRequests: requestsSections.myDraftInvoiceRequests,
        myDraftCreditNoteRequests: requestsSections.myDraftCreditNoteRequests,
        otherDraftInvoiceRequests: requestsSections.otherDraftInvoiceRequests,
        ...(isDraftExpenseClaimFeatureEnabled
          ? {
              myDraftExpenseClaimRequests:
                requestsSections.myDraftExpenseClaimRequests,
            }
          : {}),
      };
      return omitBy(sections, isEmpty);
    }

    const sections = {
      myPurchaseRequests: requestsSections.myPurchaseRequests,
      myExpenseClaimRequests: requestsSections.myExpenseClaimRequests,
      myPurchaseOrderRequests: requestsSections.myPurchaseOrderRequests,
      myMileageAllowanceRequests: requestsSections.myMileageAllowanceRequests,
      myPerDiemAllowanceRequests: requestsSections.myPerDiemAllowanceRequests,
      myInvoiceRequests: requestsSections.myInvoiceRequests,
      myCreditNoteRequests: requestsSections.myCreditNoteRequests,
      teamPurchaseRequests: requestsSections.teamPurchaseRequests,
      teamExpenseClaimRequests: requestsSections.teamExpenseClaimRequests,
      teamPurchaseOrderRequests: requestsSections.teamPurchaseOrderRequests,
      teamInvoiceRequests: requestsSections.teamInvoiceRequests,
      teamCreditNoteRequests: requestsSections.teamCreditNoteRequests,
      teamMileageAllowanceRequests:
        requestsSections.teamMileageAllowanceRequests,
      teamPerDiemAllowanceRequests:
        requestsSections.teamPerDiemAllowanceRequests,
    };
    return omitBy(sections, isEmpty);
  };

  const getAllRequests = () => {
    const activeRequestsSections = getActiveRequestsSections();

    const activeRequests = [];
    for (const property in activeRequestsSections) {
      activeRequests.push(...activeRequestsSections[property]);
    }
    return activeRequests;
  };

  const getSelectedRequests = () => {
    return isDraftsTab(type) ? checkedDraftRequests : checkedRequests;
  };

  const setCheckedItemsToState = (
    checkedItems: string[],
    typeSelected: string,
  ) => {
    if (isDraftsTab(typeSelected)) {
      setCheckedDraftRequests(checkedItems);
    } else {
      setCheckedRequests(checkedItems);
    }
  };

  // Select / un-select all items in the list
  const toggleAllItems = (isChecked: boolean) => {
    const requests = getAllRequests();

    if (requests) {
      const userCanHandle = canUserHandleSharedDraftInvoice(user);

      const itemsIds = requests.flatMap((request) =>
        userCanHandle || !isSharedDraftInvoice(request) ? [request.id] : [],
      );
      const updatedCheckedItems = isChecked ? itemsIds : [];

      setCheckedItemsToState(updatedCheckedItems, type);
    }
  };

  const closePanel = () => {
    history.push(routeFor(routes.REQUESTS.path, { company: company.id, type }));
  };

  const renderFilters = () => {
    if (isDraftsTab(type)) {
      return null;
    }

    return (
      <ErrorBoundary
        context={{ scope: 'requests::filter', team: 'capture' }}
        fallbackComponent={<></>}
      >
        <RequestFilters
          costCenters={costCenters ?? []}
          teams={groups ?? []}
          setRequestFilters={setRequestFilters}
          setTextFilter={setTextFilter}
          closeRequestsPanel={closePanel}
        />
      </ErrorBoundary>
    );
  };

  const handleRequestToggle = (selectedItemId: string, checked: boolean) => {
    const checkedItems = getSelectedRequests();

    const updatedCheckedItems = checked
      ? checkedItems.concat(selectedItemId)
      : checkedItems.filter((id) => id !== selectedItemId);

    setCheckedItemsToState(updatedCheckedItems, type);
  };

  const renderPanel = () => (
    <ErrorBoundary context={{ scope: 'requests::panel', team: 'capture' }}>
      <LazyRequestsPanel
        type={type}
        itemId={itemId}
        newRequestType={newRequestType}
        customFields={customFields}
        groups={groups}
        costCenters={costCenters}
        user={user}
        closePanel={closePanel}
        updateRequest={updateRequest}
        uploadInvoiceFile={uploadInvoiceFile}
        addRequestLocally={addRequestLocally}
        approveRequest={approveRequest}
        denyRequest={denyRequest}
        updateRequestLocally={updateRequestLocally}
        removeRequestLocally={removeRequestLocally}
        loadCardRequest={loadCardRequest}
        uploadFile={uploadFile}
        pushNotif={pushNotif}
      />
    </ErrorBoundary>
  );

  const shouldHideHeader = !isLoading && !hasResults() && !hasFilters;

  return (
    <div className="page__container">
      <div className="RequestsPage">
        {isFloatingPanelOpen && (
          <FloatingRequestPanel isOpen>{renderPanel()}</FloatingRequestPanel>
        )}
        {!hasNewNavigation && <RequestsSubNavContainer />}
        {!shouldHideHeader && renderFilters()}
        <SiderPageLayout
          className="RequestsPage__content"
          panel={isPanelOpen && renderPanel()}
        >
          <RequestsListBox
            sectionsStats={sectionsStats}
            requests={getAllRequests()}
            activeRequest={itemId}
            type={type}
            isLoading={isLoading}
            checkedRequests={getSelectedRequests()}
            onOptionClick={(request) => {
              // Open the request panel
              history.push(
                routeFor(routes.REQUESTS.path, {
                  company: company.id,
                  id: request.id,
                  type,
                }),
              );
            }}
            onRequestToggle={handleRequestToggle}
            onRequestToggleAll={toggleAllItems}
            hasFiltersApplied={hasFilters}
          />
          <NewRequestModalSlideshow />
        </SiderPageLayout>
      </div>
    </div>
  );
};

export default Requests;
