import { ExpenseType } from '../types';

const supplierCounterpartyExpenseTypes = [
  ExpenseType.cardPurchase,
  ExpenseType.invoicePurchase,
  // FIXME: not technically correct but we only handle refunds on cards for now
  ExpenseType.reversal,
];

export const isSupplierCounterpartyExpense = (expense) => {
  return supplierCounterpartyExpenseTypes.includes(expense.type);
};
