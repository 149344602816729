// Bootstraping
export const FETCH_ME_LOADING = 'FETCH_ME_LOADING';
export const FETCH_ME_FAILURE = 'FETCH_ME_FAILURE';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const UPDATE_FEATURE_SET = 'layout/UPDATE_FEATURE_SET';

// Plastic cards
export const FETCH_MONTHLY_BUDGET_SUCCESS = 'FETCH_MONTHLY_BUDGET_SUCCESS';
export const SET_ME_COMPANY_PLASTIC_CARD = 'SET_ME_COMPANY_PLASTIC_CARD';

// Trial account load limit
export const FETCH_TRIAL_LOAD_LIMIT_LOADING = 'FETCH_TRIAL_LOAD_LIMIT_LOADING';
export const FETCH_TRIAL_LOAD_LIMIT_SUCCESS = 'FETCH_TRIAL_LOAD_LIMIT_SUCCESS';
export const FETCH_TRIAL_LOAD_LIMIT_FAILURE = 'FETCH_TRIAL_LOAD_LIMIT_FAILURE';
