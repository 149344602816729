import { Callout } from '@dev-spendesk/grapes';
import React, { useMemo, useState } from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useModal } from 'common/hooks/useModal';
import { useTranslation } from 'common/hooks/useTranslation';
import { CreateApprovalPolicyModalContainer } from 'modules/company/members/components/ApprovalPolicies';
import { type ApiMember } from 'modules/company/members/components/ApprovalPolicies/reshapers';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import { MembersApprovalPolicies } from '../../components/MembersApprovalPolicies';
import { MembersApprovalPoliciesHeader } from '../../components/MembersApprovalPoliciesHeader/MembersApprovalPoliciesHeader';
import { MembersTableLoader } from '../../components/MembersTableLoader';
import { useMembersQuery, usePoliciesQuery } from '../../hooks';
import { type Member } from '../../models/member';
import { type Policy } from '../../models/policy';
import { EditApprovalPolicyModalContainer } from '../EditApprovalPolicyModalContainer';

export function MembersApprovalPoliciesContainer() {
  const membersPoliciesQueryState = useQueryStates({
    states: {
      members: useMembersQuery(),
      policies: usePoliciesQuery(),
    },
  });
  const { t } = useTranslation();
  return (
    <QuerySuspense
      queryState={membersPoliciesQueryState}
      loading={<MembersTableLoader />}
      fallback={() => (
        <Callout variant="alert" title={t('members.table.error')} />
      )}
    >
      {({ policies, members }) => (
        <MembersApprovalPoliciesContainerWithPolicies
          policies={policies}
          members={members}
        />
      )}
    </QuerySuspense>
  );
}

const MembersApprovalPoliciesContainerWithPolicies = ({
  policies,
  members,
}: {
  policies: Policy[];
  members: Member[];
}) => {
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedPolicyId, setSelectedPolicyId] = useState<string>();

  const filteredPolicies = useMemo(
    () =>
      policies.filter((policy) =>
        policy.name?.match(new RegExp(searchPattern, 'i')),
      ),
    [policies, searchPattern],
  );

  const onCreateClick = () => {
    showCreatePolicyModal();
  };
  const onPolicyClick = ({ id }: { id: string }) => {
    setSelectedPolicyId(id);
    showEditPolicyModal();
  };

  const [createPolicyModal, showCreatePolicyModal, hideCreatePolicyModal] =
    useModal(() => {
      return (
        <CreateApprovalPolicyModalContainer
          members={members.map(reshapeMemberToLegacyApprovalPolicyMember)}
          onSuccess={() => {
            hideCreatePolicyModal();
          }}
          onCancel={() => {
            hideCreatePolicyModal();
          }}
        />
      );
    });
  const [editPolicyModal, showEditPolicyModal, hideEditPolicyModal] = useModal(
    () => {
      if (!selectedPolicyId) {
        return null;
      }
      const policy = policies.find(({ id }) => id === selectedPolicyId);
      if (!policy) {
        return null;
      }
      return (
        <EditApprovalPolicyModalContainer
          policyId={policy.id}
          onSuccess={() => hideEditPolicyModal()}
          onCancel={() => hideEditPolicyModal()}
        />
      );
    },
  );

  return (
    <ErrorBoundary
      context={{
        scope: 'members-approval-policies-tab',
        team: 'finance-controller',
      }}
    >
      <MembersApprovalPoliciesHeader
        policiesCount={policies.length}
        searchPattern={searchPattern}
        setSearchPattern={setSearchPattern}
        onCreateClick={onCreateClick}
      />

      <MembersApprovalPolicies
        members={members}
        policies={filteredPolicies}
        onPolicyClick={onPolicyClick}
      />

      {createPolicyModal}
      {editPolicyModal}
    </ErrorBoundary>
  );
};

const reshapeMemberToLegacyApprovalPolicyMember = (
  member: Member,
): ApiMember => ({
  avatar: member.avatar || '',
  email: member.email,
  fullname: member.fullname,
  gender: member.gender,
  id: member.id,
  is_account_owner: member.isAccountOwner,
  is_pending: member.pending,
});
