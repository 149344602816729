import { connect } from 'react-redux';

import { type AppState } from "src/core/reducers";
import { getCompany } from "src/core/selectors/globalSelectors";

import { AchSuccessModal } from '../../components/AchSuccessModal';

const mapStateToProps = (state: AppState) => ({
  company: getCompany(state),
});

const ConnectedAchSuccessModalContainer =
  connect(mapStateToProps)(AchSuccessModal);

export { ConnectedAchSuccessModalContainer as AchSuccessModalContainer };
