/* eslint-disable promise/prefer-await-to-then */
import { createAction } from '@reduxjs/toolkit';

import * as types from 'src/core/actionTypes/groups';
import { companyAPI } from 'src/core/api/axios';
import { apiUrl } from 'src/core/utils/api';

export const fetchGroupsLoading = createAction(types.FETCH_GROUPS_LOADING);
export const fetchGroupsFailure = createAction(types.FETCH_GROUPS_FAILURE);
export const fetchGroupsSuccess = createAction(types.FETCH_GROUPS_SUCCESS);
export const fetchGroups =
  (params = {}) =>
  async (dispatch, getState) => {
    dispatch(fetchGroupsLoading());
    const { piGroups } = params;
    const endpoint = piGroups ? '/all-company-groups' : '/groups';
    const companyId = getState().global.company.id;

    let groups;
    try {
      const res = await companyAPI.get(endpoint, { companyId });

      groups = res.data;
    } catch (error) {
      dispatch(fetchGroupsFailure());
      throw error;
    }

    if (!Array.isArray(groups)) {
      return dispatch(fetchGroupsFailure());
    }
    dispatch(fetchGroupsSuccess({ groups }));
  };

export const groupAddUsersLoading = createAction(types.GROUP_ADD_USERS_LOADING);
export const groupAddUsersFailure = createAction(types.GROUP_ADD_USERS_FAILURE);
export const groupAddUsersSuccess = createAction(types.GROUP_ADD_USERS_SUCCESS);
export const addUsersToGroup =
  ({ user_ids, group_id }, callback = () => {}) =>
  (dispatch, getState) => {
    // FIXME API : can only add/remove users one by one
    const companyId = getState().global.company.id;

    dispatch(groupAddUsersLoading());

    const posts = (user_ids ?? []).map((user_id) =>
      fetch(apiUrl(`/groups/${group_id}/users`, companyId), {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id }),
      }).then((res) =>
        res.status === 200 ? res.json() : Promise.resolve(null),
      ),
    );

    return Promise.all(posts)
      .then(() => fetchGroups()(dispatch, getState)) // refresh groups globally
      .then(() => dispatch(groupAddUsersSuccess()))
      .then(callback)
      .catch(() => dispatch(groupAddUsersFailure()));
  };

export const groupRemoveUsersLoading = createAction(
  types.GROUP_REMOVE_USERS_LOADING,
);
export const groupRemoveUsersFailure = createAction(
  types.GROUP_REMOVE_USERS_FAILURE,
);
export const groupRemoveUsersSuccess = createAction(
  types.GROUP_REMOVE_USERS_SUCCESS,
);
export const removeUsersFromGroup =
  ({ user_ids, group_id }, callback = () => {}) =>
  (dispatch, getState) => {
    const companyId = getState().global.company.id;

    dispatch(groupRemoveUsersLoading());

    const deletes = (user_ids ?? []).map((user_id) =>
      fetch(apiUrl(`/groups/${group_id}/users/${user_id}`, companyId), {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id }),
      }).then((res) =>
        res.status === 200 ? res.json() : Promise.resolve(null),
      ),
    );

    return Promise.all(deletes)
      .then(() => fetchGroups()(dispatch, getState)) // refresh groups globally
      .then(() => dispatch(groupRemoveUsersSuccess()))
      .then(callback)
      .catch(() => dispatch(groupRemoveUsersFailure()));
  };
