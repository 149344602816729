import { DATE_FORMAT } from '@dev-spendesk/grapes';
import { type PanelEditableSectionProps } from '@dev-spendesk/grapes/dist/components/Panel/PanelEditableSection';
import { add, fromNumber } from 'ezmoney';
import React, { useMemo } from 'react';

import {
  useAccountingIntegrationStatusQuery,
  useAmortisationSchemesQuery,
  useHasAccountingIntegrationCapability,
} from 'modules/accounting-integration/apis';
import {
  type AmortisationCapability,
  isIntegrationStatusWithIntegration,
} from 'modules/bookkeep/integration/status';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { type SimpleItem } from 'src/core/common/components/Panel/PanelItemsSection/SimplePanelItem';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { PayableAccountingEditSection } from './PayableAccountingEditSection/PayableAccountingEditSection';
import buildSplittableFieldPanelItems from './buildSplittableFieldPanelItems';
import { useCustomFieldsQuery } from './hooks/useCustomFieldsQuery';
import { useDesignatedCustomFieldQuery } from './hooks/useDesignatedCustomFieldQuery';
import { useExpenseCategoriesQuery } from './hooks/useExpenseCategoriesQuery';
import { usePayableAccountingEditForm } from './hooks/usePayableAccountingEditForm';
import { useMustDisplayAccountsPayable } from '../../../hooks';
import {
  getIsPayableExported,
  getIsPayablePrepared,
} from '../../models/payable';
import type { Payable } from '../PayablePanelContainer';

type Props = {
  payable: Payable;
};

const hasNoSplitValues = (
  items: React.ComponentProps<typeof PanelItemsSection>['items'],
): items is SimpleItem[] => {
  return items.every((item) => item.values === undefined);
};

export const PayableAccountingSectionContainer = ({ payable }: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const formatDate = (date: Date) => localeFormat(date, DATE_FORMAT.SHORT);

  const hasTaxAccountsCapability =
    useHasAccountingIntegrationCapability('taxAccounts');

  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const costCentersQueryState = useCostCentersQuery({ withDeleted: true });
  const expenseCategoriesQueryState = useExpenseCategoriesQuery();
  const mustDisplayAccountCode = useMustDisplayAccountsPayable();
  const customFieldsQueryState = useCustomFieldsQuery(payable);
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();
  const customFieldIdForExpenseCategory =
    designatedCustomFieldQueryState.status === 'success'
      ? designatedCustomFieldQueryState.data.customFieldId
      : undefined;

  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  let amortisationCapability: AmortisationCapability | undefined;

  if (
    useFeature(FEATURES.BOOKKEEP_AMORTISATION) &&
    integrationStatusQuery.status === 'success' &&
    isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    amortisationCapability =
      integrationStatusQuery.data.capabilities.amortisation;
  }

  const amortisationSchemesQueryState = useAmortisationSchemesQuery({
    isEnabled: !!amortisationCapability,
  });

  const amortisationSchemes = unwrapQuery(amortisationSchemesQueryState) ?? [];

  const { formik, handleOnSectionCancelEdit, handleOnSectionSaveEdit } =
    usePayableAccountingEditForm({
      payable,
      customFieldIdForExpenseCategory,
      amortisationCapability,
      hasCostCentersFeature,
    });

  const payableCurrency = payable.grossAmount.currency;

  const items = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => [
      {
        label: t('payables.panel.totalGross'),
        value: formatMonetaryValue(
          payable.itemLines.reduce(
            (total, itemLine) => add(total, itemLine.grossAmount),
            fromNumber(0, payableCurrency),
          ),
        ),
      },
      ...(hasTaxAccountsCapability
        ? [
            {
              label: t('payables.panel.totalNet'),
              value: formatMonetaryValue(
                payable.itemLines.reduce(
                  (total, itemLine) => add(total, itemLine.netAmount),
                  fromNumber(0, payableCurrency),
                ),
              ),
            },
            {
              label: t('payables.panel.totalVat'),
              value: formatMonetaryValue(
                payable.itemLines.reduce(
                  (total, itemLine) => add(total, itemLine.taxAmount),
                  fromNumber(0, payableCurrency),
                ),
              ),
            },
          ]
        : []),
      ...(mustDisplayAccountCode
        ? [
            {
              label: t('payables.panel.payableAccount'),
              value: payable.accountPayable
                ? getCodeWithAuxiliaryAccounts(payable.accountPayable)
                : '-',
            },
          ]
        : []),
      ...buildSplittableFieldPanelItems({
        payable,
        showVATAccount: hasTaxAccountsCapability,
        hasCostCentersFeature,
        customFieldIdForExpenseCategory: customFieldIdForExpenseCategory || '',
        customFields:
          customFieldsQueryState.status === 'success'
            ? customFieldsQueryState.data
            : [],
        costCenters:
          costCentersQueryState.status === 'success'
            ? costCentersQueryState.data
            : [],
        t,
      }),
      ...(amortisationCapability
        ? [
            {
              label: t('payables.panel.amortisationPeriod'),
              value: payable.amortisation
                ? `${formatDate(payable.amortisation.date.from)} > ${formatDate(payable.amortisation.date.to)}`
                : '-',
              type: 'amortisationDate',
            },
          ]
        : []),
      ...(amortisationCapability === 'pullAndMap'
        ? [
            {
              label: t('payables.panel.amortisationTemplate'),
              value: payable.amortisation?.schemeId
                ? `${payable.amortisation.schemeName || t('payables.panel.unknownAmortisationTemplate')}`
                : '-',
              type: 'amortisationScheme',
              deleted: payable.amortisation?.schemeId
                ? !amortisationSchemes?.find(
                    ({ id }) => id === payable.amortisation?.schemeId,
                  )
                : false,
            },
          ]
        : []),
    ],
    [
      payable,
      hasTaxAccountsCapability,
      costCentersQueryState,
      customFieldsQueryState,
      expenseCategoriesQueryState,
      hasCostCentersFeature,
      customFieldIdForExpenseCategory,
    ],
  ) as {
    label: string;
    value: string;
    type?: string;
    expenseCategoryValueId?: string;
    deleted?: boolean;
  }[];

  let editionProps:
    | { isEditable: false }
    | ({ isEditable: true } & Pick<
        PanelEditableSectionProps,
        | 'editSection'
        | 'cancelTranslation'
        | 'saveTranslation'
        | 'onSave'
        | 'onCancel'
      >)
    | object = {};
  if (
    costCentersQueryState.status === 'success' &&
    expenseCategoriesQueryState.status === 'success'
  ) {
    const isPayableExported = getIsPayableExported(payable);
    const isPayablePrepared = getIsPayablePrepared(payable);

    // TODO - currently disabling edit for payables with split values @finance-accountant analytical split
    if (!isPayableExported && !isPayablePrepared && hasNoSplitValues(items)) {
      editionProps = {
        editSection: (
          <PayableAccountingEditSection
            payable={payable}
            {...formik}
            items={items}
            expenseCategoryValues={expenseCategoriesQueryState.data}
            costCenters={costCentersQueryState.data}
            amortisationSchemes={amortisationSchemes}
          />
        ),
        isEditable: true,
        cancelTranslation: t('misc.cancel'),
        saveTranslation: t('misc.saveChanges'),
        onSave: handleOnSectionSaveEdit,
        onCancel: handleOnSectionCancelEdit,
      };
    } else {
      editionProps = { isEditable: false };
    }
  }

  return (
    <PanelItemsSection
      title={t('payables.panel.accounting')}
      items={items}
      {...editionProps}
    />
  );
};
