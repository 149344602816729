import { useCompany } from 'modules/app/hooks/useCompany';
import { useProcedure } from 'modules/procedures';

export const useHasAccessToKycStateRelatedFeatures = (): boolean => {
  const company = useCompany();

  const isCompanyInitial =
    // @ts-expect-error
    company.created_from === null && company.type === 'initial';
  const isKycAlreadyValidated = company.is_kyb_validated;
  const arePendingKycBlocking =
    company.banking_provider === 'sfs' || !company.banking_provider
      ? !isKycAlreadyValidated
      : isCompanyInitial && !isKycAlreadyValidated;

  const procedure = useProcedure({ isEnabled: arePendingKycBlocking });
  if (company.type === 'branch_expense_entity') {
    return false;
  }
  if (!arePendingKycBlocking) {
    return true;
  }
  return !!procedure?.state?.limitedAccessOpenedAt;
};
