import { Button, Modal } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import type { AppDispatch } from 'src/core/modules/app/redux/store';

import {
  cancelRequest as cancelRequestAction,
  removeRequestLocally,
} from '../../../redux/legacy/actions';

type Props = {
  requestId: string;
  isOpen: boolean;
  onCancel(): void;
  onConfirm?(): void;
  onConfirmSuccess?(): void;
  onConfirmFailure?(): void;
};

export const CancelRequestModal = ({
  requestId,
  isOpen,
  onCancel,
  ...useCancelRequestProps
}: Props) => {
  const { t } = useTranslation('global');
  const cancelRequest = useCancelRequest(useCancelRequestProps);
  const handleCancelRequest = async () => {
    setIsLoading(true);
    try {
      await cancelRequest(requestId);
    } catch {
      setIsLoading(false);
    }
    // We don't call setIsLoading to keep the button in an unclickable state
    // (prevents double call to the cancel endpoint as the modal closes later due to the Grapes timeout)
  };
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondary"
          isDisabled={isLoading}
        />,
        <Button
          key="confirm"
          text={t('misc.yesDelete')}
          variant="warning"
          onClick={handleCancelRequest}
          isDisabled={isLoading}
          isLoading={isLoading}
        />,
      ]}
      iconName="warning"
      title={t('requests.actions.cancelTitle')}
      iconVariant="warning"
      isOpen={isOpen}
    />
  );
};

type UseCancelRequestProps = {
  onConfirm?(): void;
  onConfirmSuccess?(): void;
  onConfirmFailure?(): void;
};

const useCancelRequest = ({
  onConfirm,
  onConfirmSuccess,
  onConfirmFailure,
}: UseCancelRequestProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const confirmSuccessCallback = (requestId: string) => {
    pushNotif({ message: t('requests.actions.successCancel') });
    if (onConfirmSuccess) {
      onConfirmSuccess();
    }
    dispatch(removeRequestLocally(requestId));
  };

  const confirmFailureCallback = () => {
    pushNotif({
      type: NotificationType.Danger,
      message: t('requests.actions.errorCancel'),
    });
    if (onConfirmFailure) {
      onConfirmFailure();
    }
  };

  return (requestId: string) => {
    if (onConfirm) {
      onConfirm();
    }

    return dispatch(
      cancelRequestAction(requestId, (response: Response) =>
        response.ok
          ? confirmSuccessCallback(requestId)
          : confirmFailureCallback(),
      ),
    );
  };
};
