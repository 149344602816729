import differenceInDays from 'date-fns/differenceInDays';
import isAfter from 'date-fns/isAfter';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import isNil from 'lodash/isNil';
import partition from 'lodash/partition';
import queryString from 'query-string';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useUser } from 'modules/app/hooks/useUser';
import { LAST_REMINDER_LOCAL_STORAGE_KEY } from 'src/core/constants/storage';
import { type AppState } from 'src/core/reducers';

import { ModalReminder } from './ModalReminder';
import * as actions from './actions';

type PaymentsToRemind = {
  userRemindable?: {
    total: number;
    edges: {
      node: {
        paidAt: Date;
        request: {
          id: string;
        };
      };
    }[];
  };
  total: {
    total: number;
  };
  invalid: {
    total: number;
  };
};

type ModalReminderContainerProps = {
  paymentsToRemind: PaymentsToRemind;
  fetchUserPaymentsToRemind: () => void;
};

const ModalReminderContainer = ({
  paymentsToRemind,
  fetchUserPaymentsToRemind,
}: ModalReminderContainerProps) => {
  const [visible, setVisibility] = useState(false);
  const user = useUser();
  const location = useLocation();

  useEffect(() => {
    fetchUserPaymentsToRemind();
  }, []);

  useEffect(() => {
    setVisibility(isVisible(paymentsToRemind || {}));
  }, [paymentsToRemind]);

  const isVisible = (payments: PaymentsToRemind) => {
    const conditions = [];

    if (!payments.userRemindable) {
      return false;
    }

    const oneMonthAgo = subMonths(new Date(), 1);
    const twoDaysAgo = subDays(new Date(), 2);

    // show only if at least one payment is incomplete
    conditions.push(
      payments.userRemindable.edges.some(
        (edge) =>
          isAfter(edge.node.paidAt, oneMonthAgo) &&
          isAfter(twoDaysAgo, edge.node.paidAt),
      ),
    );

    // come from reminder mail -> do not push modal
    conditions.push(
      queryString.parse(location.search).referrer !== 'reminder_mail',
    );

    // majority of the payments are physical ? -> he'll get the mobile app notification
    const userRemindable = payments.userRemindable.edges || [];

    const userPhysicalPaymentsCount = partition(
      userRemindable,
      ({ node: { request } }) => isNil(request),
    )[0].length;

    const userPaymentsCount = userRemindable.length;
    conditions.push(userPhysicalPaymentsCount <= userPaymentsCount / 2);

    // the pop up shouldn't appear on every login but at most every 3 days
    const lastPopup = localStorage.getItem(LAST_REMINDER_LOCAL_STORAGE_KEY);
    if (lastPopup) {
      conditions.push(differenceInDays(new Date(), new Date(lastPopup)) > 2);
    }

    // the pop up shouldn't appear for users from companies where overall over 80% receipts are missing
    conditions.push(payments.invalid.total / payments.total.total <= 0.8);

    // the pop up shouldn't appear for users who are Controllers (only or Controllers-Requesters, Controllers-Admins, AO etc.)
    conditions.push(!user.is_controller);

    return conditions.reduce((a, b) => a && b, true);
  };

  const onClose = () => {
    setVisibility(false);

    // set localstorage key on close as we don't want it to be set in render
    // there are multiple renders on page load, and it would set the flag on first render and hide the modal on the following renders
    localStorage.setItem(
      LAST_REMINDER_LOCAL_STORAGE_KEY,
      new Date().toISOString(),
    );
  };

  return visible ? (
    <ModalReminder
      user={user}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      count={paymentsToRemind.userRemindable!.total}
      onClose={onClose}
    />
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  const {
    payments: { paymentsToRemind },
  } = state;
  return {
    paymentsToRemind,
  };
};

const mapDispatchToProps = {
  fetchUserPaymentsToRemind: actions.fetchUserPaymentsToRemind,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalReminderContainer);
