import { DatePicker, FormField } from '@dev-spendesk/grapes';
import React from 'react';

import { AutocompleteNoOptions } from 'common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'common/components/AutocompleteSearch';
import { useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { CustomFieldsFormField } from 'modules/budgets/components/CustomFieldsFormField';
import { ExpenseCategoryContainer } from 'modules/company/expense-categories';
import { canEditAnalyticalFields } from 'modules/requests';

import { type PurchaseOrderFormData } from '../../../../../hooks';
import { type PurchaseOrderRequestDetails } from '../../../../models';

export const EditableDetailsSection = ({
  costCenters,
  shouldDisplayCostCenterField,
  shouldDisplayTeamField,
  shouldDisplayExpenseCategoryField,
  isExpenseCategoryFieldRequired,
  userTeams,
  formikProps,
  request,
}: PurchaseOrderFormData & {
  request: PurchaseOrderRequestDetails;
}) => {
  const { t } = useTranslation();
  const { errors, values, setFieldValue } = formikProps;
  const user = useUser();
  const areAnalyticalFieldsDisabled = !canEditAnalyticalFields(request, user);

  return (
    <>
      <FormField
        className="PurchaseOrderRequestForm__field"
        label={t('requests.panel.purchaseOrder.startDateLabel')}
        alertMessage={errors.startDate ? t(errors.startDate) : undefined}
      >
        <DatePicker
          fit="parent"
          isInvalid={!!errors.startDate}
          maxDate={values.endDate}
          placeholder={t('requests.panel.purchaseOrder.startDatePlaceholder')}
          value={values.startDate}
          onChange={(newStartDate) => setFieldValue('startDate', newStartDate)}
        />
      </FormField>
      <FormField
        className="PurchaseOrderRequestForm__field"
        label={t('requests.panel.purchaseOrder.endDateLabel')}
        alertMessage={errors.endDate ? t(errors.endDate) : undefined}
      >
        <DatePicker
          fit="parent"
          isInvalid={!!errors.endDate}
          minDate={values.startDate}
          placeholder={t('requests.panel.purchaseOrder.endDatePlaceholder')}
          value={values.endDate}
          onChange={(newEndDate) => setFieldValue('endDate', newEndDate)}
        />
      </FormField>
      {shouldDisplayTeamField && (
        <FormField
          className="PurchaseOrderRequestForm__field"
          label={t('forms.team.label')}
          alertMessage={errors.team ? t(errors.team) : undefined}
        >
          <AutocompleteSearch
            fit="parent"
            name="team"
            placeholder={t('forms.team.label')}
            value={
              values.team
                ? { key: values.team.id, label: values.team.name }
                : undefined
            }
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            options={userTeams.map((team) => ({
              key: team.id,
              label: team.name,
            }))}
            onSelect={(newTeam) =>
              setFieldValue(
                'team',
                newTeam ? { id: newTeam.key, name: newTeam.label } : null,
              )
            }
          />
        </FormField>
      )}
      {shouldDisplayCostCenterField && (
        <FormField
          className="PurchaseOrderRequestForm__field"
          label={t('forms.costCenter.label')}
          alertMessage={errors.costCenter ? t(errors.costCenter) : undefined}
        >
          <AutocompleteSearch
            fit="parent"
            name="costCenter"
            placeholder={t('forms.costCenter.label')}
            value={
              values.costCenter
                ? { key: values.costCenter.id, label: values.costCenter.name }
                : undefined
            }
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            options={costCenters.map((costCenter) => ({
              key: costCenter.id,
              label: costCenter.name,
            }))}
            onSelect={(newCostCenter) =>
              setFieldValue(
                'costCenter',
                newCostCenter
                  ? { id: newCostCenter.key, name: newCostCenter.label }
                  : null,
              )
            }
          />
        </FormField>
      )}
      {shouldDisplayExpenseCategoryField && (
        <ExpenseCategoryContainer
          costCenterId={values.costCenter?.id}
          expenseCategoryId={values.expenseCategory?.id}
          isRequired={isExpenseCategoryFieldRequired}
          onSelectExpenseCategory={(newExpenseCategory) => {
            setFieldValue('expenseCategory', newExpenseCategory);
          }}
          isExpenseCategoryValid
          isDisabled={areAnalyticalFieldsDisabled}
        />
      )}
      <div className="my-m">
        <CustomFieldsFormField
          error={
            errors.customFieldAssociations as
              | {
                  [customFieldId: string]: 'required';
                }
              | undefined
          }
          className="ExpenseReviewForm__field"
          fit="parent"
          value={values.customFieldAssociations.filter(
            (customFieldAssociation) =>
              customFieldAssociation.customFieldId !==
              values.expenseCategory?.id,
          )}
          teamId={values.team?.id ?? ''}
          onSelect={(newCustomFieldsAssociations) => {
            setFieldValue(
              'customFieldAssociations',
              newCustomFieldsAssociations,
            );
          }}
          types={['expense']}
          isDisabled={areAnalyticalFieldsDisabled}
        />
      </div>
    </>
  );
};
