import { CompanyHeader } from './CompanyHeader/CompanyHeader';
import { FeedbackButton } from './FeedbackButton/FeedbackButton';
import { HelpButton } from './HelpButton/HelpButton';
import { ImpersonationOrganisationSwitcher } from './ImpersonationOrganisationSwitcher/ImpersonationOrganisationSwitcher';
import { JiraIssueReporter } from './JiraIssueReporter/JiraIssueReporter';
import { Logo } from './Logo/Logo';
import styles from './Navigation.module.css';
import { NavigationProvider } from './NavigationContext';
import { NewRequestButton } from './NewRequestButton/NewRequestButton';
import { ProfileEntry } from './ProfileEntry/ProfileEntry';
import {
  usePrimaryNavigationLinks,
  shouldDisplayNewRequestButton,
} from './helpers';
import { useCompany } from '../../../hooks/useCompany';
import { useUser } from '../../../hooks/useUser';
import { ReadyToSpendSetupGuide } from '../ReadyToSpendSetupGuide';

import './Navigation.css';

export const Navigation = () => {
  const user = useUser();
  const company = useCompany();
  const primaryNavigationLinks = usePrimaryNavigationLinks();

  const renderRequestButton = shouldDisplayNewRequestButton(company, user);

  return (
    <NavigationProvider>
      <header className={styles.top}>
        <Logo />
        <ImpersonationOrganisationSwitcher />
        <CompanyHeader />
      </header>
      <div className="overflow-separator relative grow overflow-y-auto px-xs">
        {renderRequestButton && <NewRequestButton />}
        <ul className="flex flex-col gap-xxs">
          {primaryNavigationLinks.map(({ name, component: Component }) => (
            <li key={name}>
              <Component user={user} company={company} />
            </li>
          ))}
        </ul>
      </div>
      <footer className="shrink-0 p-xs">
        <ul className="flex flex-col gap-xxs">
          <JiraIssueReporter />
          <li>
            <ReadyToSpendSetupGuide />
          </li>
          <HelpButton />
          <FeedbackButton />
          <li>
            <ProfileEntry />
          </li>
        </ul>
      </footer>
    </NavigationProvider>
  );
};
