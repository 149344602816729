import { DATE_FORMAT, type DateFormatter } from '@dev-spendesk/grapes';
import React from 'react';

import {
  useTranslation,
  type TGlobalFunctionTyped,
} from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './AuthFieldsAndLinks.module.css';
import { StatusActions } from './StatusActions';
import {
  type AccountingSoftware,
  type IntegrationStatus,
} from '../../../../../../integration/status';

interface Props {
  status: IntegrationStatus;
}

export const AuthFieldsAndLinks = ({ status }: Props) => {
  const { t, localeFormat } = useTranslation('global');

  if (
    status.integration === 'noIntegration' ||
    status.integration === 'switchInProgress'
  ) {
    return null;
  }

  return (
    <div className="body-m">
      {status.descriptor.settingsAccountingHeaderFields && (
        <div className={styles.fields}>
          {status.descriptor.settingsAccountingHeaderFields.map((field) => {
            return (
              <div className={styles.field} key={field.fieldName}>
                <div className={styles.fieldName}>
                  {renderFieldName(field.fieldName, status.integration, t)}
                </div>
                <div className={styles.fieldValue}>
                  {renderFieldValue(
                    field.fieldName,
                    field.fieldValue,
                    status.integration,
                    t,
                    localeFormat,
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <StatusActions status={status} />
    </div>
  );
};

const renderFieldName = (
  fieldName: string,
  integrationName: AccountingSoftware,
  t: TGlobalFunctionTyped,
): React.ReactNode => {
  switch (integrationName) {
    case 'Datev':
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (fieldName) {
        case 'tenantId':
          return t('bookkeep.integrations.datev.authFieldsNames.tenantId');
        case 'tenantName':
          return t('bookkeep.integrations.datev.authFieldsNames.tenantName');
        case 'fiscalYear':
          return t('bookkeep.integrations.datev.authFieldsNames.fiscalYear');
        case 'accountLength':
          return t('bookkeep.integrations.datev.authFieldsNames.accountLength');
        default: {
          return fieldName;
        }
      }
    case 'Netsuite':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Xero':
    case 'Sage':
    case 'Cegid':
    case 'Quickbooks':
    case 'Sage100':
      return null;
    default:
      rejectUnexpectedValue('integrationName', integrationName);
  }
};

const renderFieldValue = (
  fieldName: string,
  fieldValue: unknown,
  integrationName: AccountingSoftware,
  t: TGlobalFunctionTyped,
  localeFormat: DateFormatter,
): React.ReactNode => {
  switch (integrationName) {
    case 'Datev':
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (fieldName) {
        case 'tenantId':
          return typeof fieldValue === 'string' ? fieldValue : null;
        case 'tenantName':
          return typeof fieldValue === 'string' ? fieldValue : null;
        case 'fiscalYear': {
          if (!isFiscalYear(fieldValue)) {
            return null;
          }

          const start = localeFormat(
            new Date(fieldValue.fiscalYearStart),
            DATE_FORMAT.SHORT,
          );
          const end = localeFormat(
            new Date(fieldValue.fiscalYearEnd),
            DATE_FORMAT.SHORT,
          );

          return `${start} - ${end}`;
        }
        case 'accountLength': {
          if (!isAccountLengths(fieldValue)) {
            return null;
          }
          return t(
            'bookkeep.integrations.datev.authFieldsValues.accountLengths',
            fieldValue,
          );
        }
        default: {
          return fieldName;
        }
      }
    case 'Netsuite':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Xero':
    case 'Sage':
    case 'Cegid':
    case 'Quickbooks':
    case 'Sage100':
      return null;
    default:
      rejectUnexpectedValue('integrationName', integrationName);
  }
};

const isFiscalYear = (
  input: unknown,
): input is { fiscalYearStart: string; fiscalYearEnd: string } => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (input as any)?.fiscalYearStart && (input as any)?.fiscalYearEnd;
};

const isAccountLengths = (
  input: unknown,
): input is { expenseAccountLength: number; accountsPayableLength: number } => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Number.isInteger((input as any)?.expenseAccountLength) && // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Number.isInteger((input as any)?.accountsPayableLength)
  );
};
