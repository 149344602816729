import * as Money from 'ezmoney';

// amount availableForNewRequests === total - allocatedOnCards;
export type AmountAllocation =
  | 'total'
  | 'availableForNewRequests'
  | 'allocatedOnCards';

export type Summary = {
  id: string;
  name: string;
  totalAmount?: Money.MonetaryValue;
};

export type DetailedSummary = Summary & {
  totalAmount: Money.MonetaryValue;
  totalAllocated: Money.MonetaryValue;
  walletDetails: {
    allocatedOnCards: {
      totalAmount: Money.MonetaryValue;
      subscriptionCardsAmount: Money.MonetaryValue;
      physicalCardsAmount: Money.MonetaryValue;
      singlePurchaseCardsAmount: Money.MonetaryValue;
    };
    scheduledTransfers?: {
      totalAmount: Money.MonetaryValue;
      expenseClaimsAmount: Money.MonetaryValue;
      invoicesAmount: Money.MonetaryValue;
    };
    available: {
      totalAmount: Money.MonetaryValue;
    };
  };
};

export const isDetailedSummary = (
  walletSummary: Summary | DetailedSummary,
): walletSummary is DetailedSummary => {
  return 'walletDetails' in walletSummary;
};

// TODO@financeOps: totalAllocated will always be present on detailed wallet when fully rolled out on the backend side
export const getTotalAllocated = (
  walletSummary: DetailedSummary,
): Money.MonetaryValue => {
  const currency = walletSummary.totalAmount.currency;

  // TODO@financeOps: hotfix walletDetails was supposed to always be present...
  if (
    !('totalAllocated' in walletSummary) &&
    !('walletDetails' in walletSummary)
  ) {
    return Money.fromNumber(0, currency);
  }

  return (
    walletSummary.totalAllocated ??
    walletSummary.walletDetails.allocatedOnCards.totalAmount
  );
};

export const hasMissingFunds = (
  walletSummary: DetailedSummary,
  includeWireTransfers = true,
): boolean => {
  const currency = walletSummary.totalAmount.currency;

  const allocatedFunds =
    includeWireTransfers && walletSummary.totalAllocated
      ? walletSummary.totalAllocated
      : walletSummary.walletDetails?.allocatedOnCards?.totalAmount ??
        Money.fromNumber(0, currency);

  return (
    Boolean(walletSummary.walletDetails) &&
    Money.greaterThan(allocatedFunds, walletSummary.totalAmount)
  );
};

export const hasAllocatedOnCards = (walletSummary: DetailedSummary) => {
  const allocatedOnCards = isDetailedSummary(walletSummary)
    ? walletSummary.walletDetails.allocatedOnCards.totalAmount.amount
    : 0;

  return allocatedOnCards > 0;
};

export const getTotalAllocatedOnCards = (walletSummary: DetailedSummary) => {
  return walletSummary.walletDetails.allocatedOnCards.totalAmount;
};

export const getAmountAllocatedOnSubscriptionCards = (
  walletSummary: DetailedSummary,
) => {
  return walletSummary.walletDetails.allocatedOnCards.subscriptionCardsAmount;
};

export const getAmountAllocatedOnPhysicalCards = (
  walletSummary: DetailedSummary,
) => {
  return walletSummary.walletDetails.allocatedOnCards.physicalCardsAmount;
};
export const getAmountAllocatedOnSinglePurchaseCards = (
  walletSummary: DetailedSummary,
) => {
  return walletSummary.walletDetails.allocatedOnCards.singlePurchaseCardsAmount;
};

export const getAmountAllocatedForScheduledWireTransfers = (
  walletSummary: DetailedSummary,
) => {
  return walletSummary.walletDetails.scheduledTransfers?.totalAmount;
};

export const getAmountAvailable = (
  walletDetails: DetailedSummary,
): Money.MonetaryValue => {
  return walletDetails.walletDetails.available.totalAmount;
};

export function getWalletDetailsBreakdown(walletSummary: DetailedSummary) {
  const totalAmount = walletSummary.totalAmount;
  const totalAmountAllocatedOnCards = getTotalAllocatedOnCards(walletSummary);
  const amountAvailable = getAmountAvailable(walletSummary);
  const amountAllocatedOnSubscriptionCards =
    getAmountAllocatedOnSubscriptionCards(walletSummary);
  const amountAllocatedOnPhysicalCards =
    getAmountAllocatedOnPhysicalCards(walletSummary);
  const amountAllocatedForWireTransfers =
    getAmountAllocatedForScheduledWireTransfers(walletSummary);
  const amountAllocatedForSinglePurchaseCards =
    getAmountAllocatedOnSinglePurchaseCards(walletSummary);
  return {
    totalAmount,
    amountAvailable,
    amountAllocatedOnSubscriptionCards,
    amountAllocatedOnPhysicalCards,
    totalAmountAllocatedOnCards,
    amountAllocatedForWireTransfers,
    amountAllocatedForSinglePurchaseCards,
  };
}
