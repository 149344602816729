import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import { migrationPhase } from './migration-phase';
import { ServerError } from '../common/errors';

export const migrationRouterContract = initContract().router({
  getStatus: {
    summary: 'Get the migration status',
    method: 'GET',
    path: '/',
    responses: {
      200: z.object({
        id: z.string(),
        companyId: z.string(),
        status: z.enum([
          'ToDo',
          'PendingAccountCreation',
          'WaitingForFunds',
          'InProgress',
          'Closing',
          'Done',
          'Cancelled',
        ]),
        /**
         * Deadline for the entire migration.
         */
        deadline: z.string(),

        /**
         * According to the active phase and the user roles, true if the user
         * has some remaining tasks to complete for the user's companies.
         *
         * @deprecated use `banner` property instead
         */
        displayBanner: z.boolean(),

        /**
         * Provides information to display the SFS banner based on active phase
         * and user roles. The banner is displayed if current user has tasks to
         * complete. Null meaning no banner should be displayed.
         */
        banner: z
          .object({
            activePhase: migrationPhase,
            uncompletedTaskCount: z.number(),
            completedTaskCount: z.number(),
            deadline: z.string().nullable(),
            phaseStartDate: z.string().nullable(),
            hasTasksInOtherEntities: z.boolean(),
          })
          .nullable(),

        /**
         * Provides information to display the SFS widget on the home page
         * according to user roles and the active phase. If the user
         * is AO or admin or has some remaining tasks to complete for the
         * user's companies. Null meaning no widget should be displayed.
         */
        widget: z
          .object({
            activePhase: migrationPhase,
          })
          .nullable(),
        customerGroup: z.enum(['EEA', 'UK']),
      }),
      404: z.object({
        error: z.literal('No migration found'),
      }),
      ...ServerError,
    },
  },
});
