import { Button, Callout } from '@dev-spendesk/grapes';
import cx from 'classnames';
import type * as Money from 'ezmoney';
import React from 'react';
import { Link } from 'react-router-dom';

import { LegacyWalletCallout } from '@sfs-migration/wallet/components/LegacyWalletCallout';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { buildShortfallCalloutTitle } from '../../../wallet/buildShortfallCalloutTitle';
import {
  type DetailedWalletSummary,
  type WalletSummary,
  hasDetailedWalletSummary,
} from '../../../wallet/walletSummary';
import { hasCompanyNegativeAvailableAmount } from '../hasCompanyNegativeAvailableAmount';

import './CompanyDropdownWalletDetails.css';

type Props = {
  className?: string;
  company: DetailedWalletSummary | WalletSummary;
  onRedirectToWalletSettings: () => void;
};

export const CompanyDropdownWalletDetails = ({
  className,
  company,
  onRedirectToWalletSettings,
}: Props) => {
  const { t } = useTranslation('global');

  if (!hasDetailedWalletSummary(company)) {
    return null;
  }

  const hasNegativeAvailableAmount = hasCompanyNegativeAvailableAmount(company);

  return (
    <div className={cx('CompanyDropdownWalletDetails', className)}>
      <div className="CompanyDropdownWalletDetails__amount-line mb-xs title-m">
        <span>{t('wallet.walletDetails.allocatedOnCards')}</span>
        <span>
          {formatMonetaryValue(
            company.walletDetails.allocatedOnCards.totalAmount,
          )}
        </span>
      </div>
      <div className="CompanyDropdownWalletDetails__allocated-details body-s">
        <div className="CompanyDropdownWalletDetails__allocated-details__line">
          <span>{t('wallet.walletDetails.subscriptions')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.subscriptionCardsAmount,
            )}
          </span>
        </div>
        <div className="CompanyDropdownWalletDetails__allocated-details__line">
          <span>{t('wallet.walletDetails.plasticCards')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.physicalCardsAmount,
            )}
          </span>
        </div>
        <div className="CompanyDropdownWalletDetails__allocated-details__line">
          <span>{t('wallet.walletDetails.virtualCards')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.allocatedOnCards.singlePurchaseCardsAmount,
            )}
          </span>
        </div>
      </div>
      {company.walletDetails.scheduledTransfers && (
        <ScheduledTransfersDetails company={company} />
      )}
      <div className="CompanyDropdownWalletDetails__amount-line mb-s title-m">
        <span>
          {hasNegativeAvailableAmount
            ? t('wallet.walletDetails.expectedShortfall')
            : t('wallet.walletDetails.available')}
        </span>
        <span
          className={cx('text-success', {
            'text-warning': hasNegativeAvailableAmount,
          })}
        >
          {formatMonetaryValue(company.walletDetails.available.totalAmount, {
            // FIXME: can't access const enum SignDisplay with isolatedModules
            signDisplay: 'never' as Money.SignDisplay,
          })}
        </span>
      </div>
      {hasNegativeAvailableAmount && company.totalAmount && (
        <Callout
          className="mb-s"
          variant="warning"
          title={buildShortfallCalloutTitle({ walletSummary: company, t })}
        />
      )}
      <Link
        to={routeFor(routes.COMPANY_BANK.path, { company: company.id })}
        onClick={onRedirectToWalletSettings}
      >
        <Button
          fit="parent"
          className="mb-s"
          variant={hasNegativeAvailableAmount ? 'primary' : 'secondary'}
          text={
            hasNegativeAvailableAmount
              ? t('wallet.walletDetails.topUpWallet')
              : t('wallet.walletDetails.viewDetails')
          }
        />
      </Link>
      <LegacyWalletCallout companyId={company.id} />
    </div>
  );
};

const ScheduledTransfersDetails = ({
  company,
}: {
  company: DetailedWalletSummary;
}) => {
  const { t } = useTranslation('global');

  if (!company.walletDetails?.scheduledTransfers) {
    return null;
  }

  return (
    <div>
      <div className="CompanyDropdownWalletDetails__amount-line mb-xs title-m">
        <span>{t('wallet.walletDetails.scheduledTransfers')}</span>
        <span>
          {formatMonetaryValue(
            company.walletDetails.scheduledTransfers.totalAmount,
          )}
        </span>
      </div>
      <div className="CompanyDropdownWalletDetails__allocated-details body-s">
        <div className="CompanyDropdownWalletDetails__allocated-details__line">
          <span>{t('wallet.walletDetails.expenseClaims')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.scheduledTransfers.expenseClaimsAmount,
            )}
          </span>
        </div>
        <div className="CompanyDropdownWalletDetails__allocated-details__line">
          <span>{t('wallet.walletDetails.invoices')}</span>
          <span>
            {formatMonetaryValue(
              company.walletDetails.scheduledTransfers.invoicesAmount,
            )}
          </span>
        </div>
      </div>
    </div>
  );
};
