import { z } from 'zod';

export const mobileSchema = z.object({
  mobileExt: z.string().max(5),
  mobileNo: z.string().max(20),
});

export const mobileSchemaNullable = z.object({
  mobileExt: z.string().max(5).nullable(),
  mobileNo: z.string().max(20).nullable(),
});
