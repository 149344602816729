import { add } from 'ezmoney';
import head from 'lodash/head';
import values from 'lodash/values';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import { monetaryValuePropTypes } from "src/core/utils/monetaryValue";

import SubscriptionItem from './LegacySubscriptionItem';
import SubscriptionUsageItem from './LegacySubscriptionUsageItem';
import { formatAddonName, formatAddonPrice, ADDON_NAMES } from '../helpers';

import './LegacySubscriptionAddonItem.css';

// Map addon product name to usage unit name
const addonUsageUnitMapper = new Map([
  ['supplementary_wallets', 'branches'],
  ['ereceipts', 'ereceipts'],
  ['ereceipt_users', 'active_users'],
  ['card_insurance', 'active_users'],
]);

const getPriceFromUsagePriceItem = (usagePriceItem) => {
  return add(usagePriceItem.base, usagePriceItem.factor);
};

const LegacySubscriptionAddonItem = ({ name, price, t }) => {
  const addonName = formatAddonName({ name, t });
  const usageUnit = addonUsageUnitMapper.get(name);
  const firstUsagePrice = head(price.usage[usageUnit]);
  // If constant price is free, use the first usage price item as addon price
  const addonPrice =
    price.constant.amount === 0 && firstUsagePrice
      ? getPriceFromUsagePriceItem(firstUsagePrice)
      : price.constant;
  // If constant price is free, remove the first usage price item from usage pricing (already as for addon price)
  const addonUsagePrice =
    price.constant.amount === 0 && firstUsagePrice
      ? (price.usage[usageUnit] ?? []).slice(
          1,
          (price.usage[usageUnit] ?? []).length,
        )
      : price.usage[usageUnit];
  const formattedAddonPrice = formatAddonPrice({
    unit: name,
    price: addonPrice,
    t,
  });

  return (
    <div className="LegacySubscriptionAddonItem">
      <SubscriptionItem
        base={addonName}
        extra={[formattedAddonPrice]}
        isAddon
      />
      {addonUsagePrice && (
        <SubscriptionUsageItem key={name} unit={name} usage={addonUsagePrice} />
      )}
    </div>
  );
};

LegacySubscriptionAddonItem.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.oneOf(values(ADDON_NAMES)),
    PropTypes.string, // we handle unknown addon as a generic one
  ]).isRequired,
  price: PropTypes.shape({
    constant: monetaryValuePropTypes.isRequired,
    usage: PropTypes.objectOf(PropTypes.array).isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LegacySubscriptionAddonItem);
