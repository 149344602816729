import { colors, Icon } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React from 'react';

import './ProgressBoxBarStep.css';

type Props = {
  status: 'active' | 'upcoming' | 'completed';
  isClicked?: boolean;
};

export const ProgressBoxBarStep = ({ status, isClicked }: Props) => {
  switch (status) {
    case 'active':
      return <ProgressBoxBarActiveStep isClicked={isClicked} />;
    case 'completed':
      return <ProgressBoxBarCompletedStep isClicked={isClicked} />;
    default:
      return <ProgressBoxBarUpcomingStep />;
  }
};

const ProgressBoxBarUpcomingStep = () => (
  <div className="ProgressBoxBarStep ProgressBoxBarStep--upcoming">
    <div className="ProgressBoxBarStep--upcoming__inner" />
  </div>
);

const ProgressBoxBarActiveStep = ({ isClicked }: { isClicked?: boolean }) => (
  <div
    className={classNames('ProgressBoxBarStep ProgressBoxBarStep--active', {
      'ProgressBoxBarStep--active--clicked':
        isClicked !== undefined ? isClicked : false,
      'ProgressBoxBarStep--active--notClicked':
        isClicked !== undefined ? !isClicked : false,
    })}
  >
    <div className="ProgressBoxBarStep--active__inner" />
  </div>
);

const ProgressBoxBarCompletedStep = ({
  isClicked,
}: {
  isClicked?: boolean;
}) => (
  <div
    className={classNames('ProgressBoxBarStep ProgressBoxBarStep--completed', {
      'ProgressBoxBarStep--completed--clicked':
        isClicked !== undefined ? isClicked : false,
      'ProgressBoxBarStep--completed--notClicked':
        isClicked !== undefined ? !isClicked : false,
    })}
  >
    <Icon name="checked" color={colors.white} />
  </div>
);
