import { DATE_FORMAT, PanelSection } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';

import { PanelSectionListItem } from 'common/components/Panel';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type CustomFieldDefinition } from 'modules/requests/models';
import FEATURES from 'src/core/constants/features';
import { getExpenseCategoryCustomFieldId } from 'src/core/modules/requests/redux/legacy/selectors';
import { getDisplayCustomFieldsValues } from 'src/core/utils/custom-fields';

import { EditableDetailsSection } from './EditableDetailsSection';
import { useUpdatePurchaseOrderRequestSection } from '../../../../hooks';
import {
  getExpenseCategory,
  type PurchaseOrderRequestDetails,
} from '../../../../models';
import { UpdateError } from '../UpdateError';

type DetailsSectionProps = {
  request: PurchaseOrderRequestDetails;
  customFields: CustomFieldDefinition[];
  setIsEditingPurchaseOrder: (isEditing: boolean) => void;
  fetchRequest: () => Promise<void>;
};

export const DetailsSection = ({
  request,
  customFields,
  setIsEditingPurchaseOrder,
  fetchRequest,
}: DetailsSectionProps) => {
  const { t, localeFormat } = useTranslation('global');
  const { onSave, onCancel, canEdit, formData } =
    useUpdatePurchaseOrderRequestSection(request, [
      'startDate',
      'endDate',
      'costCenterId',
      'expenseCategoryId',
      'customFieldAssociations',
      'teamId',
    ]);
  const expenseCategoryCustomFieldId = useSelector(
    getExpenseCategoryCustomFieldId,
  );
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);

  const expenseCategory = getExpenseCategory(
    request,
    expenseCategoryCustomFieldId,
  );
  return (
    <PanelSection
      title={t('requests.panel.purchaseOrder.details')}
      {...(canEdit
        ? {
            isEditable: true,
            cancelTranslation: t('misc.cancel'),
            saveTranslation: t('misc.saveChanges'),
            editSection: (
              <EditableDetailsSection {...formData} request={request} />
            ),
            renderError: (error) => <UpdateError error={error} />,
            onEditClick: () => setIsEditingPurchaseOrder(true),
            onCancel: async () => {
              await onCancel();
              setIsEditingPurchaseOrder(false);
            },
            onSave: async () => {
              await onSave();
              await fetchRequest();
              setIsEditingPurchaseOrder(false);
            },
            editButtonLabel: t(
              'requests.panel.purchaseOrder.editDetailsSection',
            ),
          }
        : {
            isEditable: false,
          })}
    >
      <div className="flex flex-col gap-s">
        {[
          {
            label: t('requests.panel.purchaseOrder.startDateLabel'),
            value: localeFormat(new Date(request.startDate), DATE_FORMAT.SHORT),
          },
          {
            label: t('requests.panel.purchaseOrder.endDateLabel'),
            value: localeFormat(new Date(request.endDate), DATE_FORMAT.SHORT),
          },
          ...(request.team && hasTeamsFeature
            ? [
                {
                  label: t('requests.panel.purchaseOrder.team'),
                  value: request.team.name,
                },
              ]
            : []),
          ...(request.costCenter
            ? [
                {
                  label: t('requests.panel.purchaseOrder.costCenter'),
                  value: request.costCenter.name,
                },
              ]
            : []),
          ...(expenseCategory
            ? [
                {
                  label: t('requests.panel.purchaseOrder.expenseCategory'),
                  value: expenseCategory.name,
                },
              ]
            : []),
          ...getDisplayCustomFieldsValues(
            customFields,
            request.customFields,
          ).map((customFieldValue) => ({
            label: customFieldValue.key,
            value: customFieldValue.value,
          })),
        ].map((item) => (
          <PanelSectionListItem
            key={item.label}
            label={item.label}
            value={item.value}
          />
        ))}
      </div>
    </PanelSection>
  );
};
