import type { Config } from '@dev-spendesk/app-cloudflare-worker';

type LocalConfig = {
  [k: string]: unknown;
  // Add your custom settings type here
  // Values can be added/updated in `settings.json5` file
  // ex:
  //   customVar: string;
  storyblokAccessToken: string;
  procurementBaseUrl: string;
  isApprovalFlowSelectionByCCOwnerEnabled: boolean;
};

// Injected by app-cloudflare-worker
declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var appConfig: Config & LocalConfig;
}

const appConfig = globalThis.appConfig ?? window.appConfig ?? {};
// Note: we use both named exports and default export

export const {
  stage,
  wwwBaseUrl,
  apiUrls,
  companySetUp,
  helloSign,
  phoneNumbers,
  google,
  microsoft,
  mapboxgl,
  sessionMaxActivity,
  storyblok,
  maxBulkImportMembers,
  selfServedEnabled,
} = appConfig;

export default appConfig;
