import React, { useMemo } from 'react';

import { Timeline } from 'common/components/Timeline';

import { RequestTimelineEvent } from './RequestTimelineEvent';
import { type PurchaseOrderRequestDetails } from '../../../models';

export type RequestTimelineProps = {
  request: PurchaseOrderRequestDetails;
};

export const RequestTimeline = ({ request }: RequestTimelineProps) => {
  const { timeline } = request;

  const timelineEvents = useMemo(
    () =>
      timeline.map((timelineEvent) => ({
        key: timelineEvent.id,
        date: new Date(timelineEvent.occuredAt),
        content: <RequestTimelineEvent timelineEvent={timelineEvent} />,
      })),
    [timeline],
  );

  return <Timeline className="mt-m" events={timelineEvents} />;
};
