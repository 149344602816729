import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

import { type AccountingSoftware } from './status';
import { rejectUnexpectedValue } from '../../../utils/switchGuard';

export function getBrandName(
  t: TGlobalFunctionTyped,
  integration: AccountingSoftware | 'noIntegration' | 'switchInProgress',
): string {
  switch (integration) {
    case 'Xero':
      return t('bookkeep.integrations.xero.brandName');
    case 'Datev':
      return t('bookkeep.integrations.datev.brandName');
    case 'Netsuite':
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'noIntegration':
    case 'switchInProgress':
    case 'Sage':
    case 'Cegid':
    case 'Quickbooks':
    case 'Sage100':
      return integration;
    default:
      rejectUnexpectedValue('integration', integration);
  }
}

export function getIntegrationName(
  t: TGlobalFunctionTyped,
  integration: AccountingSoftware | 'noIntegration' | 'switchInProgress',
): string {
  switch (integration) {
    case 'Xero':
      return t('bookkeep.integrations.xero.integrationName');
    case 'Datev':
      return t('bookkeep.integrations.datev.integrationName');
    case 'Quickbooks':
      return t('bookkeep.integrations.quickbooks.integrationName');
    case 'Netsuite':
      return t('bookkeep.integrations.netsuite.integrationName');
    case 'SpendeskAccounting':
      return t('bookkeep.integrations.doubleEntry.integrationName');
    case 'SpendeskAccountingSingleEntry':
      return t('bookkeep.integrations.singleEntry.integrationName');
    case 'Sage':
      return t('bookkeep.integrations.sage.integrationName');
    case 'Cegid':
      return t('bookkeep.integrations.cegid.integrationName');
    case 'Sage100':
      return t('bookkeep.integrations.sage100.integrationName');
    case 'noIntegration':
      return t('bookkeep.integrations.noIntegration.integrationName');
    case 'switchInProgress':
      return t('bookkeep.integrations.noIntegration.integrationName');
    default:
      rejectUnexpectedValue('integration', integration);
  }
}
