import { createAction } from '@reduxjs/toolkit';

import * as types from 'src/core/modules/payments/redux/actionTypes';
import { graphql } from 'src/core/utils/api';

const lastRequests = {
  fetchUserPaymentsToRemind: null,
};

// Fetch user payments that are incomplete
const fetchUserPaymentsToRemindFailure = createAction(
  types.FETCH_USER_PAYMENTS_TO_REMIND_FAILURE,
);
const fetchUserPaymentsToRemindSuccess = createAction(
  types.FETCH_USER_PAYMENTS_TO_REMIND_SUCCESS,
);
export const fetchUserPaymentsToRemind = () => async (dispatch, getState) => {
  const variables = {
    companyId: getState().global.company.id,
    userRemindable: [
      { type: 'payer', value: getState().global.user.id },
      { type: 'invoice', value: 'missing' },
    ],
    companyRemindable: [{ type: 'remindable_for_invoice', value: true }],
  };

  const request = `
      query FetchPaymentsAndStats($companyId: String!, $userRemindable: [JSON], $companyRemindable: [JSON]) {
        paymentsToRemind: company(id: $companyId) {
          userRemindable: payments(filters_v2: $userRemindable) {
            total,
            edges {
              node {
                paidAt: paid_at
                request { id }
              }
            }
          }

          total: payments {
            total
          }

          invalid: payments(filters_v2: $companyRemindable) {
            total
          }
        }
      }
    `;

  let request_;
  let result;

  try {
    request_ = graphql(request, variables);
    lastRequests.fetchUserPaymentsToRemind = request_;

    result = await request_;
  } catch (error) {
    if (lastRequests.fetchUserPaymentsToRemind === request_) {
      dispatch(fetchUserPaymentsToRemindFailure(error));

      throw error;
    }
  }

  if (lastRequests.fetchUserPaymentsToRemind === request_) {
    dispatch(fetchUserPaymentsToRemindSuccess(result));
  }

  return request_;
};
