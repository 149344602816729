import { isBefore } from 'date-fns';
import i18next from 'i18next';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { intersection } from './array';

const TOO_MANY_VALUES_THRESHOLD = 200;

export const userCanCreateCfValue = (user, cf) => {
  if (!isObject(user) || !isObject(cf)) {
    return false;
  }
  const { perms_add_values } = cf;
  const { is_requester, is_controller, is_admin } = user;
  return (
    (is_requester && includes(perms_add_values, '05_002_0')) || // requesters can create values
    (is_controller && includes(perms_add_values, '07_004_0')) || // controllers can create values
    (is_admin && includes(perms_add_values, '03_006_2'))
  ); // admins can create values
};

export const userCanSeeCf = (user, cf) => {
  if (!isObject(user) || !isObject(cf)) {
    return false;
  }
  const { perms_visibility } = cf;
  const { is_requester, is_controller, is_admin } = user;
  return (
    (is_requester && includes(perms_visibility, '05_002_0')) || // requesters can create values
    (is_controller && includes(perms_visibility, '07_004_0')) || // controllers can create values
    (is_admin && includes(perms_visibility, '03_006_2'))
  ); // admins can create values
};

// Get eligible custom fields depending on their type, teams and the current user
// Also skip deleted custom fields if the payment or request occurs after this deletion
export const getEligibleCustomFields = (customFields, options = {}) => {
  const { types, date = new Date(), teamIds = [], all, user } = options;

  const cfs = compact(
    filter(customFields, (cf) => {
      // Custom field is deleted before
      if (cf.deleted_at && isBefore(new Date(cf.deleted_at), date)) {
        return false;
      }
      // Custom field is not eligible for type
      if (intersection(map(cf.eligible_types, 'type'), types).length === 0) {
        return false;
      }
      // Not eligible if no values
      if (cf.total_values === 0) {
        return false;
      }
      // User can't see the custom field
      if (!userCanSeeCf(user, cf)) {
        return false;
      }
      // Wanna see all custom fields eligible for entity type
      if (all) {
        return true;
      }
      // Custom field applies to all team or team id not provided
      if (cf.is_all_scopes) {
        return true;
      }
      // Only all scope custom fields
      if (isNil(teamIds)) {
        return false;
      }
      // Custom field applies to the payment team
      const cfTeamsScopes = map(
        filter(cf.scopes, { entity_type: 'team' }),
        'entity_id',
      );

      return !isEmpty(intersection(cfTeamsScopes, compact(teamIds)));
    }),
  );

  return sortBy(cfs, (cf) => !cf.deleted_at && cf.name);
};

export const getCfLabel = (customField) =>
  `${customField.name}${
    customField.deleted_at
      ? ` (${i18next.t('global:customFields.noLongerUsed')})`
      : ''
  }`;

export const isCfBoolean = (customField) => customField.type === 'boolean';

export const prettyCfValue = (customField, cfv) => {
  if (!cfv) {
    return;
  }
  return isCfBoolean(customField)
    ? prettyBooleanCustomFieldValue(cfv.value)
    : cfv.value;
};

const formatCustomFieldValue = (customFieldValue, customField) => ({
  key: customFieldValue.id,
  name: prettyCfValue(customField, customFieldValue),
});

export const getValuesFromCustomField = (customField) =>
  map(customField.custom_fields_values, (cfv) =>
    formatCustomFieldValue(cfv, customField),
  );

export const getCurrentCustomFieldValue = (customField, entityCf) => {
  if (!get(entityCf, 'value.value')) {
    return null;
  }
  return {
    id: entityCf.value.id,
    name: prettyCfValue(customField, entityCf.value),
  };
};

const hasTooManyValues = (customField) => {
  return (
    get(customField, 'totalValues', 0) > TOO_MANY_VALUES_THRESHOLD ||
    get(customField, 'total_values', 0) > TOO_MANY_VALUES_THRESHOLD
  );
};

/**
 * @param entity Can be a request or a payment
 */
export const attachValuesToCustomFields = (
  customFields,
  customFieldsInEntity,
) => {
  const eligibleCustomFieldsWithValue = map(customFields, (field) => ({
    field,
    value: {},
  }));
  forEach(customFieldsInEntity, ({ field, value }) => {
    const matchingFieldWithValue = find(
      eligibleCustomFieldsWithValue,
      (object) => object.field.id === field.id,
    );
    if (matchingFieldWithValue) {
      matchingFieldWithValue.value = value;
    } else {
      eligibleCustomFieldsWithValue.push({
        field: {
          ...field,
          // for custom with too many values we remove the custom field inside this customFields collection when we try to retrieve the field of this subscription inside this collection we don't find it and we do a fallback to the field of the subscription the issue is that the field of the subscription doesn't have the same shape and miss one attribute (custom_fields_values), we manually add it if it doesn't exist and fallback to an empty array to prevent exception (this field is required in other places)
          // FIXME: backend should return the right shape with custom_fields_values as empty array
          custom_fields_values: field.custom_fields_values ?? [],
        },
        value,
      });
    }
  });

  return filter(
    eligibleCustomFieldsWithValue,
    // Remove delete custom fields except those that are set for the entity
    ({ field, value }) => !field.deleted_at || !isEmpty(value),
  );
};

export const prettyBooleanCustomFieldValue = (cfValue) => {
  const value = Number(cfValue);

  if (isNaN(value)) {
    if (includes(['yes', 'no'], cfValue?.toLowerCase())) {
      return cfValue?.toLowerCase() === 'yes'
        ? i18next.t('global:misc.yes')
        : i18next.t('global:misc.no');
    }
    return cfValue;
  }

  return value === 1
    ? i18next.t('global:misc.yes')
    : i18next.t('global:misc.no');
};

/**
 * Returns a list of entity CFs to display,
 * for instance in a `ReadOnlyFieldsValues` component
 * The return values is a simple list of CF name / CF value objects
 *
 * @param  {Array} eligibleCustomFields The entity's eligible CFs
 * @param  {Array} entityCustomFields   The entity's CFs mappings
 * @return {Array}                      List of CFs associations to show
 */
export const getDisplayCustomFieldsValues = (
  eligibleCustomFields,
  entityCustomFields,
) => {
  return compact(
    map(eligibleCustomFields, (cf) => {
      const matchingCf = find(entityCustomFields, { field: { id: cf.id } });
      if (!cf.deleted_at || !isNil(get(matchingCf, 'value.value'))) {
        return {
          key: cf.name,
          value: matchingCf
            ? prettyCfValue(cf, {
                value: String(get(matchingCf, 'value.value')),
              })
            : '-',
        };
      }
      return null;
    }),
  );
};

/*
 * Transform nested entity CF data structure to flat, server-understandable format:
 *   input:  [{ field: { id: 12 }, value: { id: 45, value: 'foo' } }]
 *   output: [{ customFieldId: 12, customFieldValueId: 45, value: 'foo' }]
 */
export const harmonizeCustomFieldsAssociations = (
  customFields,
  customFieldsAssociations,
) => {
  if (!customFields || !customFieldsAssociations) {
    return null;
  }
  return map(customFieldsAssociations, (association) => ({
    customFieldId: get(association, 'field.id'),
    customFieldValueId: get(association, 'value.id'),
    value: get(association, 'value.value'),
    hasMultipleMatchingValues: get(association, 'isMultipleValue', false),
  }));
};

export const shouldUseAsyncCustomFieldAutocomplete = (customField) => {
  return !isCfBoolean(customField) && hasTooManyValues(customField);
};
