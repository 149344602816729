import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import './UnexpectedError.css';

const UnexpectedError = () => {
  const { t } = useTranslation('errors');

  return (
    <div className="UnexpectedError">
      <span
        role="img"
        aria-label="blushing face with hand over mouth"
        className="UnexpectedError__emoji"
      >
        😕
      </span>
      <p className="UnexpectedError__title">{t('unexpectedError.title')}</p>
      <p className="UnexpectedError__details">
        <Trans i18nKey="errors:unexpectedError.weHaveBeenNotified">
          <a
            href="#"
            id="intercom-message"
            onClick={() => window.Intercom('show')}
          >
            -
          </a>
        </Trans>
      </p>
    </div>
  );
};

export default UnexpectedError;
