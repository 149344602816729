import { createReducer } from '@reduxjs/toolkit';
import find from 'lodash/find';
import merge from 'lodash/merge';

import * as types from 'src/core/actionTypes/users';
import { swap } from 'src/core/utils/toolbelt';

const initialState = {
  isLoading: false,
  isLoadingUsers: false,
  error: null,
  users: null,
};

export default createReducer(initialState, (builder) => {
  builder.addCase(types.FETCH_USERS_LOADING, (state) => {
    state.isLoadingUsers = true;
  });
  builder.addCase(types.FETCH_USERS_SUCCESS, (state, action) => {
    state.users = action.payload;
    state.isLoadingUsers = false;
  });
  builder.addCase(types.FETCH_USERS_FAILURE, (state, action) => {
    state.isLoadingUsers = false;
    state.error = action.payload;
  });
  builder.addCase(types.FETCH_USER_LOADING, (state) => {
    state.isLoading = true;
    state.error = null;
  });
  builder.addCase(types.FETCH_USER_FAILURE, (state, action) => {
    state.isLoading = false;
    state.error = action.error;
  });
  builder.addCase(types.FETCH_USER_SUCCESS, (state, action) => {
    const user = find(state.users, { id: action.payload.id });
    const updatedUser = merge(user, action.payload);

    state.isLoading = false;
    state.users = swap(
      state.users,
      (item) => item.id === action.payload.id,
      updatedUser,
    );
  });
});
