import { Icon, type IconName } from '@dev-spendesk/grapes';
import React, { useId, type ReactNode } from 'react';
import { NavLink as RRNavLink, matchPath } from 'react-router-dom';

type Props = {
  href: string;
  iconName: IconName;
  children: ReactNode;
  activePaths?: string[];
};

export const NavLink = ({ href, iconName, children, activePaths }: Props) => {
  const textId = useId();
  return (
    <RRNavLink
      to={href}
      className="navigation-item h-xxl"
      aria-labelledby={textId}
      isActive={(_, location) => {
        const match = matchPath(location.pathname, {
          path: activePaths ?? href,
          exact: activePaths && activePaths.length > 0,
          strict: false,
        });
        return match !== null;
      }}
    >
      <Icon name={iconName} size="m" aria-hidden="true" className="shrink-0" />
      <span id={textId} className="navigation-toggle-display">
        {children}
      </span>
    </RRNavLink>
  );
};
