import { Icon, SkeletonText } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useUser } from 'src/core/modules/app/hooks/useUser';

import { OrganisationReportingEntityList } from './OrganisationReportingEntityList';
import { type OrganisationFeatures } from '../../hooks/useOrganisationFeatures';
import { type OrganisationReportingEntity } from '../../types';

export const OrganisationReportingPage = ({
  features,
  companies,
}: {
  features: OrganisationFeatures;
  companies: OrganisationReportingEntity[];
}) => {
  const user = useUser();
  const { t } = useTranslation('global');
  const [updatedAt, setUpdatedAt] = useState<Date | null>(null);

  return (
    <div className="page__container bg-page-background">
      <div className="grow bg-page-background p-xl">
        <div className="flex flex-col gap-xl">
          <div>
            <h1 className="leading-tight title-xxl">
              {t('organisation.reporting.page.greeting', {
                firstName: user.first_name,
              })}
            </h1>

            <div className="flex items-center gap-s pt-s">
              <div className="text-neutral-dark body-xl">
                <p>{t('organisation.reporting.page.subgreeting')}</p>
              </div>
              <div
                className="h-m w-0"
                style={{ borderLeft: '1px solid var(--color-neutral-dark)' }}
              />
              <div className="text-neutral-dark body-l">
                <div className="flex items-center gap-xs">
                  <Icon name="clock" className="block" />
                  <div className="flex align-middle">
                    {updatedAt ? (
                      t('organisation.reporting.page.updatedAt', {
                        date: updatedAt,
                      })
                    ) : (
                      <SkeletonText className="mt-[1px] w-[240px]" size="l" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <OrganisationReportingEntityList
            features={features}
            entities={companies}
            onDataLoaded={() => setUpdatedAt(new Date())}
          />
        </div>
      </div>
    </div>
  );
};
