/**
 * Types definition
 */
export interface Country {
  name: string;
  alpha2: string;
  translationKey: string;
  companyNumber?: string;
  approved?: boolean;
  vatRates?: VatRate[];
  reverseVatRates?: ReverseVatRate[];
  order?: number;
}

interface Vat {
  defaultValue?: number;
  vatRates: VatRate[];
}

interface VatRate {
  value: number;
  default?: boolean;
  name?: string;
}
interface ReverseVatRate {
  value: number;
  validFrom: string;
}

/**
 * VAT Countries settings
 */

export const COUNTRIES: Record<string, Country> = {
  AF: {
    name: 'Afghanistan',
    alpha2: 'AF',
    translationKey: 'countries:afghanistan',
  },
  AX: {
    name: 'Åland Islands',
    alpha2: 'AX',
    translationKey: 'countries:alandIslands',
  },
  AL: {
    name: 'Albania',
    alpha2: 'AL',
    translationKey: 'countries:albania',
  },
  DZ: {
    name: 'Algeria',
    alpha2: 'DZ',
    translationKey: 'countries:algeria',
  },
  AS: {
    name: 'American Samoa',
    alpha2: 'AS',
    translationKey: 'countries:americanSamoa',
  },
  AD: {
    name: 'Andorra',
    alpha2: 'AD',
    translationKey: 'countries:andorra',
  },
  AO: {
    name: 'Angola',
    alpha2: 'AO',
    translationKey: 'countries:angola',
  },
  AI: {
    name: 'Anguilla',
    alpha2: 'AI',
    translationKey: 'countries:anguilla',
  },
  AG: {
    name: 'Antigua and Barbuda',
    alpha2: 'AG',
    translationKey: 'countries:antiguaAndBarbuda',
  },
  AR: {
    name: 'Argentina',
    alpha2: 'AR',
    translationKey: 'countries:argentina',
  },
  AM: {
    name: 'Armenia',
    alpha2: 'AM',
    translationKey: 'countries:armenia',
  },
  AW: {
    name: 'Aruba',
    alpha2: 'AW',
    translationKey: 'countries:aruba',
  },
  AU: {
    name: 'Australia',
    alpha2: 'AU',
    translationKey: 'countries:australia',
  },
  AT: {
    name: 'Austria',
    alpha2: 'AT',
    translationKey: 'countries:austria',
    companyNumber: 'Company registration number (Firmenbuchnummer)',
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 13,
      },
      {
        value: 10,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  AZ: {
    name: 'Azerbaijan',
    alpha2: 'AZ',
    translationKey: 'countries:azerbaijan',
  },
  BS: {
    name: 'Bahamas',
    alpha2: 'BS',
    translationKey: 'countries:bahamas',
  },
  BH: {
    name: 'Bahrain',
    alpha2: 'BH',
    translationKey: 'countries:bahrain',
  },
  BD: {
    name: 'Bangladesh',
    alpha2: 'BD',
    translationKey: 'countries:bangladesh',
  },
  BB: {
    name: 'Barbados',
    alpha2: 'BB',
    translationKey: 'countries:barbados',
  },
  BY: {
    name: 'Belarus',
    alpha2: 'BY',
    translationKey: 'countries:belarus',
  },
  BE: {
    name: 'Belgium',
    alpha2: 'BE',
    translationKey: 'countries:belgium',
    companyNumber: 'Company registration number (BTW/TVA/NWSt)',
    approved: true,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 12,
      },
      {
        value: 6,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  BZ: {
    name: 'Belize',
    alpha2: 'BZ',
    translationKey: 'countries:belize',
  },
  BJ: {
    name: 'Benin',
    alpha2: 'BJ',
    translationKey: 'countries:benin',
  },
  BM: {
    name: 'Bermuda',
    alpha2: 'BM',
    translationKey: 'countries:bermuda',
  },
  BT: {
    name: 'Bhutan',
    alpha2: 'BT',
    translationKey: 'countries:bhutan',
  },
  BO: {
    name: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    translationKey: 'countries:bolivia',
  },
  BQ: {
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    translationKey: 'countries:bonaire',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    translationKey: 'countries:bosniaAndHerzegovina',
  },
  BW: {
    name: 'Botswana',
    alpha2: 'BW',
    translationKey: 'countries:botswana',
  },
  BR: {
    name: 'Brazil',
    alpha2: 'BR',
    translationKey: 'countries:brazil',
  },
  VG: {
    name: 'British Virgin Islands',
    alpha2: 'VG',
    translationKey: 'countries:britishVirginIslands',
  },
  BN: {
    name: 'Brunei Darussalam',
    alpha2: 'BN',
    translationKey: 'countries:bruneiDarussalam',
  },
  BG: {
    name: 'Bulgaria',
    alpha2: 'BG',
    translationKey: 'countries:bulgaria',
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  BF: {
    name: 'Burkina Faso',
    alpha2: 'BF',
    translationKey: 'countries:burkinaFaso',
  },
  BI: {
    name: 'Burundi',
    alpha2: 'BI',
    translationKey: 'countries:burundi',
  },
  CV: {
    name: 'Cabo Verde',
    alpha2: 'CV',
    translationKey: 'countries:caboVerde',
  },
  KH: {
    name: 'Cambodia',
    alpha2: 'KH',
    translationKey: 'countries:cambodia',
  },
  CM: {
    name: 'Cameroon',
    alpha2: 'CM',
    translationKey: 'countries:cameroon',
  },
  CA: {
    name: 'Canada',
    alpha2: 'CA',
    translationKey: 'countries:canada',
  },
  KY: {
    name: 'Cayman Islands',
    alpha2: 'KY',
    translationKey: 'countries:caymanIslands',
  },
  CF: {
    name: 'Central African Republic',
    alpha2: 'CF',
    translationKey: 'countries:centralAfricanRepublic',
  },
  TD: {
    name: 'Chad',
    alpha2: 'TD',
    translationKey: 'countries:chad',
  },
  TW: {
    name: 'Taiwan',
    alpha2: 'TW',
    translationKey: 'countries:taiwan',
  },
  CL: {
    name: 'Chile',
    alpha2: 'CL',
    translationKey: 'countries:chile',
  },
  CN: {
    name: 'China',
    alpha2: 'CN',
    translationKey: 'countries:china',
  },
  HK: {
    name: 'China, Hong Kong Special Administrative Region',
    alpha2: 'HK',
    translationKey: 'countries:hongKong',
  },
  MO: {
    name: 'China, Macao Special Administrative Region',
    alpha2: 'MO',
    translationKey: 'countries:macao',
  },
  CO: {
    name: 'Colombia',
    alpha2: 'CO',
    translationKey: 'countries:colombia',
  },
  KM: {
    name: 'Comoros',
    alpha2: 'KM',
    translationKey: 'countries:comoros',
  },
  CG: {
    name: 'Congo',
    alpha2: 'CG',
    translationKey: 'countries:congo',
  },
  CK: {
    name: 'Cook Islands',
    alpha2: 'CK',
    translationKey: 'countries:cookIslands',
  },
  CR: {
    name: 'Costa Rica',
    alpha2: 'CR',
    translationKey: 'countries:costaRica',
  },
  CI: {
    name: "Cote d'Ivoire",
    alpha2: 'CI',
    translationKey: 'countries:coteIvoire',
  },
  HR: {
    name: 'Croatia',
    alpha2: 'HR',
    translationKey: 'countries:croatia',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 13,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 25,
        validFrom: '2000-01-01',
      },
    ],
  },
  CU: {
    name: 'Cuba',
    alpha2: 'CU',
    translationKey: 'countries:cuba',
  },
  CW: {
    name: 'Curaçao',
    alpha2: 'CW',
    translationKey: 'countries:curacao',
  },
  CY: {
    name: 'Cyprus',
    alpha2: 'CY',
    translationKey: 'countries:cyprus',
    approved: true,
    vatRates: [
      {
        value: 19,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 19,
        validFrom: '2000-01-01',
      },
    ],
  },
  CZ: {
    name: 'Czech Republic',
    alpha2: 'CZ',
    translationKey: 'countries:czechRepublic',
    approved: true,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 15,
      },
      {
        value: 10,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  KP: {
    name: "Democratic People's Republic of Korea",
    alpha2: 'KP',
    translationKey: 'countries:northKorea',
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    alpha2: 'CD',
    translationKey: 'countries:democraticRepublicOfTheCongo',
  },
  DK: {
    name: 'Denmark',
    alpha2: 'DK',
    translationKey: 'countries:denmark',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 25,
        validFrom: '2000-01-01',
      },
    ],
  },
  DJ: {
    name: 'Djibouti',
    alpha2: 'DJ',
    translationKey: 'countries:djibouti',
  },
  DM: {
    name: 'Dominica',
    alpha2: 'DM',
    translationKey: 'countries:dominica',
  },
  DO: {
    name: 'Dominican Republic',
    alpha2: 'DO',
    translationKey: 'countries:dominicanRepublic',
  },
  EC: {
    name: 'Ecuador',
    alpha2: 'EC',
    translationKey: 'countries:ecuador',
  },
  EG: {
    name: 'Egypt',
    alpha2: 'EG',
    translationKey: 'countries:egypt',
  },
  SV: {
    name: 'El Salvador',
    alpha2: 'SV',
    translationKey: 'countries:elSalvador',
  },
  GQ: {
    name: 'Equatorial Guinea',
    alpha2: 'GQ',
    translationKey: 'countries:equatorialGuinea',
  },
  ER: {
    name: 'Eritrea',
    alpha2: 'ER',
    translationKey: 'countries:eritrea',
  },
  EE: {
    name: 'Estonia',
    alpha2: 'EE',
    translationKey: 'countries:estonia',
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  ET: {
    name: 'Ethiopia',
    alpha2: 'ET',
    translationKey: 'countries:ethiopia',
  },
  FO: {
    name: 'Faeroe Islands',
    alpha2: 'FO',
    translationKey: 'countries:faeroeIslands',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 0,
      },
    ],
  },
  FK: {
    name: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    translationKey: 'countries:falklandIslands',
  },
  FJ: {
    name: 'Fiji',
    alpha2: 'FJ',
    translationKey: 'countries:fiji',
  },
  FI: {
    name: 'Finland',
    alpha2: 'FI',
    translationKey: 'countries:finland',
    companyNumber: 'Company registration number (Y-tunnus)',
    approved: true,
    vatRates: [
      {
        value: 24,
        default: true,
      },
      {
        value: 14,
      },
      {
        value: 10,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 24,
        validFrom: '2000-01-01',
      },
    ],
  },
  FR: {
    name: 'France',
    alpha2: 'FR',
    translationKey: 'countries:france',
    companyNumber: 'Company registration number (SIREN)',
    approved: true,
    order: 1,
    vatRates: [
      {
        value: 20,
        default: true,
        name: 'Standard',
      },
      {
        value: 10,
        name: 'Reduced',
      },
      {
        value: 5.5,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
        name: 'Exempt',
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  GF: {
    name: 'French Guiana',
    alpha2: 'GF',
    translationKey: 'countries:frenchGuiana',
    approved: true,
    vatRates: [
      {
        value: 8.5,
        default: true,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
      },
    ],
  },
  PF: {
    name: 'French Polynesia',
    alpha2: 'PF',
    translationKey: 'countries:frenchPolynesia',
    approved: true,
    vatRates: [
      {
        value: 8.5,
        default: true,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
      },
    ],
  },
  GA: {
    name: 'Gabon',
    alpha2: 'GA',
    translationKey: 'countries:gabon',
  },
  GM: {
    name: 'Gambia',
    alpha2: 'GM',
    translationKey: 'countries:gambia',
  },
  GE: {
    name: 'Georgia',
    alpha2: 'GE',
    translationKey: 'countries:georgia',
    companyNumber: 'Company registration number (Handelsregisternummer)',
  },
  DE: {
    name: 'Germany',
    alpha2: 'DE',
    translationKey: 'countries:germany',
    order: 3,
    approved: true,
    vatRates: [
      {
        value: 19,
      },
      {
        value: 16,
        default: true,
      },
      {
        value: 7,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 19,
        validFrom: '2000-01-01',
      },
      {
        value: 16,
        validFrom: '2020-07-01',
      },
      {
        value: 19,
        validFrom: '2021-01-01',
      },
    ],
  },
  GH: {
    name: 'Ghana',
    alpha2: 'GH',
    translationKey: 'countries:ghana',
  },
  GI: {
    name: 'Gibraltar',
    alpha2: 'GI',
    translationKey: 'countries:gibraltar',
    approved: true,
    vatRates: [
      {
        value: 0,
        default: true,
      },
    ],
  },
  GR: {
    name: 'Greece',
    alpha2: 'GR',
    translationKey: 'countries:greece',
    approved: true,
    vatRates: [
      {
        value: 23,
        default: true,
      },
      {
        value: 13,
      },
      {
        value: 6,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 24,
        validFrom: '2000-01-01',
      },
    ],
  },
  GL: {
    name: 'Greenland',
    alpha2: 'GL',
    translationKey: 'countries:greenland',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 0,
      },
    ],
  },
  GD: {
    name: 'Grenada',
    alpha2: 'GD',
    translationKey: 'countries:grenada',
  },
  GP: {
    name: 'Guadeloupe',
    alpha2: 'GP',
    translationKey: 'countries:guadeloupe',
    approved: true,
    vatRates: [
      {
        value: 8.5,
        default: true,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
      },
    ],
  },
  GU: {
    name: 'Guam',
    alpha2: 'GU',
    translationKey: 'countries:guam',
  },
  GT: {
    name: 'Guatemala',
    alpha2: 'GT',
    translationKey: 'countries:guatemala',
  },
  GG: {
    name: 'Guernsey',
    alpha2: 'GG',
    translationKey: 'countries:guernsey',
  },
  GN: {
    name: 'Guinea',
    alpha2: 'GN',
    translationKey: 'countries:guinea',
  },
  GW: {
    name: 'Guinea-Bissau',
    alpha2: 'GW',
    translationKey: 'countries:guineaBissau',
  },
  GY: {
    name: 'Guyana',
    alpha2: 'GY',
    translationKey: 'countries:guyana',
    approved: true,
    vatRates: [
      {
        value: 14,
        default: true,
      },
      {
        value: 0,
      },
    ],
  },
  HT: {
    name: 'Haiti',
    alpha2: 'HT',
    translationKey: 'countries:haiti',
  },
  VA: {
    name: 'Holy See',
    alpha2: 'VA',
    translationKey: 'countries:holySee',
  },
  HN: {
    name: 'Honduras',
    alpha2: 'HN',
    translationKey: 'countries:honduras',
  },
  HU: {
    name: 'Hungary',
    alpha2: 'HU',
    translationKey: 'countries:hungary',
    approved: true,
    vatRates: [
      {
        value: 27,
        default: true,
      },
      {
        value: 18,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 27,
        validFrom: '2000-01-01',
      },
    ],
  },
  IS: {
    name: 'Iceland',
    alpha2: 'IS',
    translationKey: 'countries:iceland',
    approved: true,
    vatRates: [
      {
        value: 24,
        default: true,
      },
      {
        value: 11,
      },
      {
        value: 0,
      },
    ],
  },
  IN: {
    name: 'India',
    alpha2: 'IN',
    translationKey: 'countries:india',
  },
  ID: {
    name: 'Indonesia',
    alpha2: 'ID',
    translationKey: 'countries:indonesia',
  },
  IR: {
    name: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    translationKey: 'countries:iran',
  },
  IQ: {
    name: 'Iraq',
    alpha2: 'IQ',
    translationKey: 'countries:iraq',
  },
  IE: {
    name: 'Ireland',
    alpha2: 'IE',
    translationKey: 'countries:ireland',
    approved: true,
    vatRates: [
      {
        value: 23,
        default: true,
      },
      {
        value: 13.5,
      },
      {
        value: 9,
      },
      {
        value: 4.8,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 23,
        validFrom: '2000-01-01',
      },
    ],
  },
  IM: {
    name: 'Isle of Man',
    alpha2: 'IM',
    translationKey: 'countries:isleOfMan',
  },
  IL: {
    name: 'Israel',
    alpha2: 'IL',
    translationKey: 'countries:israel',
  },
  IT: {
    name: 'Italy',
    alpha2: 'IT',
    translationKey: 'countries:italy',
    approved: true,
    vatRates: [
      {
        value: 22,
        default: true,
      },
      {
        value: 10,
      },
      {
        value: 5,
      },
      {
        value: 4,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 22,
        validFrom: '2000-01-01',
      },
    ],
  },
  JM: {
    name: 'Jamaica',
    alpha2: 'JM',
    translationKey: 'countries:jamaica',
  },
  JP: {
    name: 'Japan',
    alpha2: 'JP',
    translationKey: 'countries:japan',
  },
  JE: {
    name: 'Jersey',
    alpha2: 'JE',
    translationKey: 'countries:jersey',
    approved: true,
    vatRates: [
      {
        value: 5,
        default: true,
      },
    ],
  },
  JO: {
    name: 'Jordan',
    alpha2: 'JO',
    translationKey: 'countries:jordan',
  },
  KZ: {
    name: 'Kazakhstan',
    alpha2: 'KZ',
    translationKey: 'countries:kazakhstan',
  },
  KE: {
    name: 'Kenya',
    alpha2: 'KE',
    translationKey: 'countries:kenya',
  },
  KI: {
    name: 'Kiribati',
    alpha2: 'KI',
    translationKey: 'countries:kiribati',
  },
  KW: {
    name: 'Kuwait',
    alpha2: 'KW',
    translationKey: 'countries:kuwait',
  },
  KG: {
    name: 'Kyrgyzstan',
    alpha2: 'KG',
    translationKey: 'countries:kyrgyzstan',
  },
  LA: {
    name: "Lao People's Democratic Republic",
    alpha2: 'LA',
    translationKey: 'countries:lao',
  },
  LV: {
    name: 'Latvia',
    alpha2: 'LV',
    translationKey: 'countries:latvia',
    approved: true,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 12,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  LB: {
    name: 'Lebanon',
    alpha2: 'LB',
    translationKey: 'countries:lebanon',
  },
  LS: {
    name: 'Lesotho',
    alpha2: 'LS',
    translationKey: 'countries:lesotho',
  },
  LR: {
    name: 'Liberia',
    alpha2: 'LR',
    translationKey: 'countries:liberia',
  },
  LY: {
    name: 'Libya',
    alpha2: 'LY',
    translationKey: 'countries:libya',
  },
  LI: {
    name: 'Liechtenstein',
    alpha2: 'LI',
    translationKey: 'countries:liechtenstein',
    approved: true,
    vatRates: [
      {
        value: 0,
        default: true,
      },
    ],
  },
  LT: {
    name: 'Lithuania',
    alpha2: 'LT',
    translationKey: 'countries:lithuania',
    approved: true,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  LU: {
    name: 'Luxembourg',
    alpha2: 'LU',
    translationKey: 'countries:luxembourg',
    companyNumber: 'Company registration number (Numéro RCS)',
    approved: true,
    vatRates: [
      {
        value: 17,
        default: true,
      },
      {
        value: 14,
      },
      {
        value: 8,
      },
      {
        value: 3,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 17,
        validFrom: '2000-01-01',
      },
    ],
  },
  MG: {
    name: 'Madagascar',
    alpha2: 'MG',
    translationKey: 'countries:madagascar',
  },
  MW: {
    name: 'Malawi',
    alpha2: 'MW',
    translationKey: 'countries:malawi',
  },
  MY: {
    name: 'Malaysia',
    alpha2: 'MY',
    translationKey: 'countries:malaysia',
  },
  MV: {
    name: 'Maldives',
    alpha2: 'MV',
    translationKey: 'countries:maldives',
  },
  ML: {
    name: 'Mali',
    alpha2: 'ML',
    translationKey: 'countries:mali',
  },
  MT: {
    name: 'Malta',
    alpha2: 'MT',
    translationKey: 'countries:malta',
    approved: true,
    vatRates: [
      {
        value: 18,
        default: true,
      },
      {
        value: 7,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 18,
        validFrom: '2000-01-01',
      },
    ],
  },
  MH: {
    name: 'Marshall Islands',
    alpha2: 'MH',
    translationKey: 'countries:marshallIslands',
  },
  MQ: {
    name: 'Martinique',
    alpha2: 'MQ',
    translationKey: 'countries:martinique',
    approved: true,
    vatRates: [
      {
        value: 8.5,
        default: true,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
      },
    ],
  },
  MR: {
    name: 'Mauritania',
    alpha2: 'MR',
    translationKey: 'countries:mauritania',
  },
  MU: {
    name: 'Mauritius',
    alpha2: 'MU',
    translationKey: 'countries:mauritius',
  },
  YT: {
    name: 'Mayotte',
    alpha2: 'YT',
    translationKey: 'countries:mayotte',
  },
  MX: {
    name: 'Mexico',
    alpha2: 'MX',
    translationKey: 'countries:mexico',
  },
  FM: {
    name: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    translationKey: 'countries:micronesia',
  },
  MC: {
    name: 'Monaco',
    alpha2: 'MC',
    translationKey: 'countries:monaco',
  },
  MN: {
    name: 'Mongolia',
    alpha2: 'MN',
    translationKey: 'countries:mongolia',
  },
  ME: {
    name: 'Montenegro',
    alpha2: 'ME',
    translationKey: 'countries:montenegro',
  },
  MS: {
    name: 'Montserrat',
    alpha2: 'MS',
    translationKey: 'countries:montserrat',
  },
  MA: {
    name: 'Morocco',
    alpha2: 'MA',
    translationKey: 'countries:morocco',
  },
  MZ: {
    name: 'Mozambique',
    alpha2: 'MZ',
    translationKey: 'countries:mozambique',
  },
  MM: {
    name: 'Myanmar',
    alpha2: 'MM',
    translationKey: 'countries:myanmar',
  },
  NA: {
    name: 'Namibia',
    alpha2: 'NA',
    translationKey: 'countries:namibia',
  },
  NR: {
    name: 'Nauru',
    alpha2: 'NR',
    translationKey: 'countries:nauru',
  },
  NP: {
    name: 'Nepal',
    alpha2: 'NP',
    translationKey: 'countries:nepal',
  },
  NL: {
    name: 'Netherlands',
    alpha2: 'NL',
    translationKey: 'countries:netherlands',
    companyNumber: 'Company registration number (KvK-nummer)',
    approved: true,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  NC: {
    name: 'New Caledonia',
    alpha2: 'NC',
    translationKey: 'countries:newCaledonia',
  },
  NZ: {
    name: 'New Zealand',
    alpha2: 'NZ',
    translationKey: 'countries:newZealand',
  },
  NI: {
    name: 'Nicaragua',
    alpha2: 'NI',
    translationKey: 'countries:nicaragua',
  },
  NE: {
    name: 'Niger',
    alpha2: 'NE',
    translationKey: 'countries:niger',
  },
  NG: {
    name: 'Nigeria',
    alpha2: 'NG',
    translationKey: 'countries:nigeria',
  },
  NU: {
    name: 'Niue',
    alpha2: 'NU',
    translationKey: 'countries:niue',
  },
  NF: {
    name: 'Norfolk Island',
    alpha2: 'NF',
    translationKey: 'countries:norfolkIsland',
  },
  MP: {
    name: 'Northern Mariana Islands',
    alpha2: 'MP',
    translationKey: 'countries:northernMarianaIslands',
  },
  NO: {
    name: 'Norway',
    alpha2: 'NO',
    translationKey: 'countries:norway',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 15,
      },
      {
        value: 11.11,
      },
      {
        value: 10,
      },
      {
        value: 8,
      },
      {
        value: 0,
      },
    ],
  },
  OM: {
    name: 'Oman',
    alpha2: 'OM',
    translationKey: 'countries:oman',
  },
  PK: {
    name: 'Pakistan',
    alpha2: 'PK',
    translationKey: 'countries:pakistan',
  },
  PW: {
    name: 'Palau',
    alpha2: 'PW',
    translationKey: 'countries:palau',
  },
  PA: {
    name: 'Panama',
    alpha2: 'PA',
    translationKey: 'countries:panama',
  },
  PG: {
    name: 'Papua New Guinea',
    alpha2: 'PG',
    translationKey: 'countries:papuaNewGuinea',
  },
  PY: {
    name: 'Paraguay',
    alpha2: 'PY',
    translationKey: 'countries:paraguay',
  },
  PE: {
    name: 'Peru',
    alpha2: 'PE',
    translationKey: 'countries:peru',
  },
  PH: {
    name: 'Philippines',
    alpha2: 'PH',
    translationKey: 'countries:philippines',
  },
  PN: {
    name: 'Pitcairn',
    alpha2: 'PN',
    translationKey: 'countries:pitcairn',
  },
  PL: {
    name: 'Poland',
    alpha2: 'PL',
    translationKey: 'countries:poland',
    approved: true,
    vatRates: [
      {
        value: 23,
        default: true,
      },
      {
        value: 8,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 23,
        validFrom: '2000-01-01',
      },
    ],
  },
  PT: {
    name: 'Portugal',
    alpha2: 'PT',
    translationKey: 'countries:portugal',
    approved: true,
    vatRates: [
      {
        value: 23,
        default: true,
      },
      {
        value: 13,
      },
      {
        value: 6,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 23,
        validFrom: '2000-01-01',
      },
    ],
  },
  PR: {
    name: 'Puerto Rico',
    alpha2: 'PR',
    translationKey: 'countries:puertoRico',
  },
  QA: {
    name: 'Qatar',
    alpha2: 'QA',
    translationKey: 'countries:qatar',
  },
  KR: {
    name: 'Republic of Korea',
    alpha2: 'KR',
    translationKey: 'countries:southKorea',
  },
  MD: {
    name: 'Republic of Moldova',
    alpha2: 'MD',
    translationKey: 'countries:moldova',
  },
  RE: {
    name: 'Réunion',
    alpha2: 'RE',
    translationKey: 'countries:reunion',
    approved: true,
    vatRates: [
      {
        value: 8.5,
        default: true,
      },
      {
        value: 2.1,
      },
      {
        value: 0,
      },
    ],
  },
  RO: {
    name: 'Romania',
    alpha2: 'RO',
    translationKey: 'countries:romania',
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 9,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  RU: {
    name: 'Russian Federation',
    alpha2: 'RU',
    translationKey: 'countries:russia',
  },
  RW: {
    name: 'Rwanda',
    alpha2: 'RW',
    translationKey: 'countries:rwanda',
  },
  SH: {
    name: 'Saint Helena',
    alpha2: 'SH',
    translationKey: 'countries:saintHelena',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    translationKey: 'countries:saintKittsAndNevis',
  },
  LC: {
    name: 'Saint Lucia',
    alpha2: 'LC',
    translationKey: 'countries:saintLucia',
  },
  MF: {
    name: 'Saint Martin (French part)',
    alpha2: 'MF',
    translationKey: 'countries:saintMartin',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    translationKey: 'countries:saintPierreAndMiquelon',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    translationKey: 'countries:saintVincentAndTheGrenadines',
  },
  BL: {
    name: 'Saint-Barthélemy',
    alpha2: 'BL',
    translationKey: 'countries:saintBarthelemy',
  },
  WS: {
    name: 'Samoa',
    alpha2: 'WS',
    translationKey: 'countries:samoa',
  },
  SM: {
    name: 'San Marino',
    alpha2: 'SM',
    translationKey: 'countries:sanMarino',
  },
  ST: {
    name: 'Sao Tome and Principe',
    alpha2: 'ST',
    translationKey: 'countries:saoTomeAndPrincipe',
  },
  SA: {
    name: 'Saudi Arabia',
    alpha2: 'SA',
    translationKey: 'countries:saudiArabia',
  },
  SN: {
    name: 'Senegal',
    alpha2: 'SN',
    translationKey: 'countries:senegal',
  },
  RS: {
    name: 'Serbia',
    alpha2: 'RS',
    translationKey: 'countries:serbia',
  },
  SC: {
    name: 'Seychelles',
    alpha2: 'SC',
    translationKey: 'countries:seychelles',
  },
  SL: {
    name: 'Sierra Leone',
    alpha2: 'SL',
    translationKey: 'countries:sierraLeone',
  },
  SG: {
    name: 'Singapore',
    alpha2: 'SG',
    translationKey: 'countries:singapore',
  },
  SX: {
    name: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    translationKey: 'countries:sintMaarten',
  },
  SK: {
    name: 'Slovakia',
    alpha2: 'SK',
    translationKey: 'countries:slovakia',
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 10,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  SI: {
    name: 'Slovenia',
    alpha2: 'SI',
    translationKey: 'countries:slovenia',
    approved: true,
    vatRates: [
      {
        value: 22,
        default: true,
      },
      {
        value: 9.5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 22,
        validFrom: '2000-01-01',
      },
    ],
  },
  SB: {
    name: 'Solomon Islands',
    alpha2: 'SB',
    translationKey: 'countries:solomonIslands',
  },
  SO: {
    name: 'Somalia',
    alpha2: 'SO',
    translationKey: 'countries:somalia',
  },
  ZA: {
    name: 'South Africa',
    alpha2: 'ZA',
    translationKey: 'countries:southAfrica',
  },
  SS: {
    name: 'South Sudan',
    alpha2: 'SS',
    translationKey: 'countries:southSudan',
  },
  ES: {
    name: 'Spain',
    alpha2: 'ES',
    translationKey: 'countries:spain',
    companyNumber: 'Company registration number (NIF/CIF)',
    approved: true,
    order: 4,
    vatRates: [
      {
        value: 21,
        default: true,
      },
      {
        value: 10,
      },
      {
        value: 4,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 21,
        validFrom: '2000-01-01',
      },
    ],
  },
  LK: {
    name: 'Sri Lanka',
    alpha2: 'LK',
    translationKey: 'countries:sriLanka',
  },
  PS: {
    name: 'State of Palestine',
    alpha2: 'PS',
    translationKey: 'countries:palestine',
  },
  SD: {
    name: 'Sudan',
    alpha2: 'SD',
    translationKey: 'countries:sudan',
  },
  SR: {
    name: 'Suriname',
    alpha2: 'SR',
    translationKey: 'countries:suriname',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen Islands',
    alpha2: 'SJ',
    translationKey: 'countries:svalbardAndJanMayenIslands',
  },
  SZ: {
    name: 'Swaziland',
    alpha2: 'SZ',
    translationKey: 'countries:swaziland',
  },
  SE: {
    name: 'Sweden',
    alpha2: 'SE',
    translationKey: 'countries:sweden',
    companyNumber: 'Company registration number (Organisationsnummer)',
    approved: true,
    vatRates: [
      {
        value: 25,
        default: true,
      },
      {
        value: 12,
      },
      {
        value: 6,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 25,
        validFrom: '2000-01-01',
      },
    ],
  },
  CH: {
    name: 'Switzerland',
    alpha2: 'CH',
    translationKey: 'countries:switzerland',
    approved: true,
  },
  SY: {
    name: 'Syrian Arab Republic',
    alpha2: 'SY',
    translationKey: 'countries:syria',
  },
  TJ: {
    name: 'Tajikistan',
    alpha2: 'TJ',
    translationKey: 'countries:tajikistan',
  },
  TH: {
    name: 'Thailand',
    alpha2: 'TH',
    translationKey: 'countries:thailand',
  },
  MK: {
    name: 'The former Yugoslav Republic of Macedonia',
    alpha2: 'MK',
    translationKey: 'countries:northMacedonia',
  },
  TL: {
    name: 'Timor-Leste',
    alpha2: 'TL',
    translationKey: 'countries:timorLeste',
  },
  TG: {
    name: 'Togo',
    alpha2: 'TG',
    translationKey: 'countries:togo',
  },
  TK: {
    name: 'Tokelau',
    alpha2: 'TK',
    translationKey: 'countries:tokelau',
  },
  TO: {
    name: 'Tonga',
    alpha2: 'TO',
    translationKey: 'countries:tonga',
  },
  TT: {
    name: 'Trinidad and Tobago',
    alpha2: 'TT',
    translationKey: 'countries:trinidadAndTobago',
  },
  TN: {
    name: 'Tunisia',
    alpha2: 'TN',
    translationKey: 'countries:tunisia',
  },
  TR: {
    name: 'Turkey',
    alpha2: 'TR',
    translationKey: 'countries:turkey',
  },
  TM: {
    name: 'Turkmenistan',
    alpha2: 'TM',
    translationKey: 'countries:turkmenistan',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    alpha2: 'TC',
    translationKey: 'countries:turksAndCaicosIslands',
  },
  TV: {
    name: 'Tuvalu',
    alpha2: 'TV',
    translationKey: 'countries:tuvalu',
  },
  UG: {
    name: 'Uganda',
    alpha2: 'UG',
    translationKey: 'countries:uganda',
  },
  UA: {
    name: 'Ukraine',
    alpha2: 'UA',
    translationKey: 'countries:ukraine',
  },
  AE: {
    name: 'United Arab Emirates',
    alpha2: 'AE',
    translationKey: 'countries:unitedArabEmirates',
  },
  GB: {
    name: 'United Kingdom',
    alpha2: 'GB',
    translationKey: 'countries:unitedKingdom',
    order: 2,
    approved: true,
    vatRates: [
      {
        value: 20,
        default: true,
      },
      {
        value: 7,
      },
      {
        value: 5,
      },
      {
        value: 0,
      },
    ],
    reverseVatRates: [
      {
        value: 20,
        validFrom: '2000-01-01',
      },
    ],
  },
  TZ: {
    name: 'United Republic of Tanzania',
    alpha2: 'TZ',
    translationKey: 'countries:tanzania',
  },
  US: {
    name: 'United States of America',
    alpha2: 'US',
    translationKey: 'countries:unitedStatesOfAmerica',
    approved: true,
  },
  VI: {
    name: 'United States Virgin Islands',
    alpha2: 'VI',
    translationKey: 'countries:unitedStatesVirginIslands',
  },
  UY: {
    name: 'Uruguay',
    alpha2: 'UY',
    translationKey: 'countries:uruguay',
  },
  UZ: {
    name: 'Uzbekistan',
    alpha2: 'UZ',
    translationKey: 'countries:uzbekistan',
  },
  VU: {
    name: 'Vanuatu',
    alpha2: 'VU',
    translationKey: 'countries:vanuatu',
  },
  VE: {
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    translationKey: 'countries:venezuela',
  },
  VN: {
    name: 'Viet Nam',
    alpha2: 'VN',
    translationKey: 'countries:vietNam',
  },
  WF: {
    name: 'Wallis and Futuna Islands',
    alpha2: 'WF',
    translationKey: 'countries:wallisAndFutunaIslands',
  },
  EH: {
    name: 'Western Sahara',
    alpha2: 'EH',
    translationKey: 'countries:westernSahara',
  },
  XK: {
    name: 'Kosovo',
    alpha2: 'XK',
    translationKey: 'countries:kosovo',
  },
  YE: {
    name: 'Yemen',
    alpha2: 'YE',
    translationKey: 'countries:yemen',
  },
  ZM: {
    name: 'Zambia',
    alpha2: 'ZM',
    translationKey: 'countries:zambia',
  },
  ZW: {
    name: 'Zimbabwe',
    alpha2: 'ZW',
    translationKey: 'countries:zimbabwe',
  },
};

export const SEPACountries = [
  'FR',
  'GB',
  'BE',
  'DE',
  'GR',
  'LU',
  'ES',
  'IT',
  'NL',
  'FI',
  'NO',
  'DK',
  'PL',
  'CH',
  'SE', // Sweden
  'IE', // Ireland
  'PT', // Portugal
  'MT', // Malta
  'AT', // Austria (Autriche)
  'LV', // Latvia (Lettonie)
  'LT', // Lithuania (Lutuanie)
  'SI', // Slovenia (Slovenie)
  'SK', // Slovakia (Slovaquie)
  'EE', // Estonia (Estonie)
  'BG', // Bulgaria (Bulgarie)
  'CY', // Cyprus (Chypre)
  'CZ', // Czech Republic
  'HU', // Hungary
  'HR', // Croatia
  'RO', // Romania
  'IS', // Iceland
  'MC', // Monaco
  'LI', // Liechtenstein
  'SM', // San Marino
  'AD', // Andorra
];

interface IBANCountry {
  prefix: string;
  blocks: number[];
  size: number;
}

export const IBANCountries: { [key: string]: IBANCountry } = {
  AL: {
    prefix: 'AL',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  AD: {
    prefix: 'AD',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  AT: {
    prefix: 'AT',
    blocks: [5, 4, 4, 4, 4],
    size: 20,
  },
  AZ: {
    prefix: 'AZ',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  BH: {
    prefix: 'BH',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  BY: {
    prefix: 'BY',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  BE: {
    prefix: 'BE',
    blocks: [4, 4, 4, 4],
    size: 16,
  },
  BA: {
    prefix: 'BA',
    blocks: [5, 4, 4, 4, 4],
    size: 20,
  },
  BR: {
    prefix: 'BR',
    blocks: [4, 4, 4, 4, 4, 4, 4, 1],
    size: 29,
  },
  BG: {
    prefix: 'BG',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  CR: {
    prefix: 'CR',
    blocks: [5, 4, 4, 4, 4, 2],
    size: 22,
  },
  HR: {
    prefix: 'HR',
    blocks: [5, 4, 4, 4, 4, 1],
    size: 21,
  },
  CY: {
    prefix: 'CY',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  CZ: {
    prefix: 'CZ',
    blocks: [5, 4, 4, 4, 4, 4],
    size: 24,
  },
  DK: {
    prefix: 'DK',
    blocks: [5, 4, 4, 4, 2],
    size: 18,
  },
  DO: {
    prefix: 'DO',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  TL: {
    prefix: 'TL',
    blocks: [5, 4, 4, 4, 4, 3],
    size: 23,
  },
  EE: {
    prefix: 'EE',
    blocks: [5, 4, 4, 4, 4],
    size: 20,
  },
  FO: {
    prefix: 'FO',
    blocks: [5, 4, 4, 4, 2],
    size: 18,
  },
  FI: {
    prefix: 'FI',
    blocks: [5, 4, 2, 2, 4, 2],
    size: 18,
  },
  FR: {
    prefix: 'FR',
    blocks: [4, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  GE: {
    prefix: 'GE',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  DE: {
    prefix: 'DE',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  GI: {
    prefix: 'GI',
    blocks: [4, 4, 4, 4, 4, 3],
    size: 23,
  },
  GR: {
    prefix: 'GR',
    blocks: [4, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  GL: {
    prefix: 'GL',
    blocks: [5, 4, 4, 4, 2],
    size: 18,
  },
  GT: {
    prefix: 'GT',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  HU: {
    prefix: 'HU',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  IS: {
    prefix: 'IS',
    blocks: [5, 4, 4, 4, 4, 4, 2],
    size: 26,
  },
  IE: {
    prefix: 'IE',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  IL: {
    prefix: 'IL',
    blocks: [4, 4, 4, 4, 4, 3],
    size: 23,
  },
  IT: {
    prefix: 'IT',
    blocks: [4, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  JO: {
    prefix: 'JO',
    blocks: [4, 4, 4, 4, 4, 4, 4, 2],
    size: 30,
  },
  KZ: {
    prefix: 'KZ',
    blocks: [4, 4, 4, 4, 4],
    size: 20,
  },
  XK: {
    prefix: 'XK',
    blocks: [4, 4, 4, 4, 4],
    size: 20,
  },
  KW: {
    prefix: 'KW',
    blocks: [4, 4, 4, 4, 4, 4, 4, 2],
    size: 30,
  },
  LV: {
    prefix: 'LV',
    blocks: [4, 4, 4, 4, 4, 1],
    size: 21,
  },
  LB: {
    prefix: 'LB',
    blocks: [4, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  LI: {
    prefix: 'LI',
    blocks: [4, 4, 4, 4, 4, 1],
    size: 21,
  },
  LT: {
    prefix: 'LT',
    blocks: [5, 4, 4, 4, 4],
    size: 20,
  },
  LU: {
    prefix: 'LU',
    blocks: [4, 4, 4, 4, 4],
    size: 20,
  },
  MK: {
    prefix: 'MK',
    blocks: [4, 4, 4, 4, 3],
    size: 19,
  },
  MT: {
    prefix: 'MT',
    blocks: [4, 4, 4, 4, 4, 4, 4, 3],
    size: 31,
  },
  MR: {
    prefix: 'MR',
    blocks: [5, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  MU: {
    prefix: 'MU',
    blocks: [4, 4, 4, 4, 4, 4, 4, 2],
    size: 30,
  },
  MC: {
    prefix: 'MC',
    blocks: [4, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  MD: {
    prefix: 'MD',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  ME: {
    prefix: 'ME',
    blocks: [5, 4, 4, 4, 4, 2],
    size: 22,
  },
  NL: {
    prefix: 'NL',
    blocks: [4, 4, 4, 4, 2],
    size: 18,
  },
  NO: {
    prefix: 'NO',
    blocks: [5, 4, 4, 3],
    size: 15,
  },
  PK: {
    prefix: 'PK',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  PS: {
    prefix: 'PS',
    blocks: [4, 4, 4, 4, 4, 4, 4, 1],
    size: 29,
  },
  PL: {
    prefix: 'PL',
    blocks: [5, 4, 4, 4, 4, 4, 4],
    size: 28,
  },
  PT: {
    prefix: 'PT',
    blocks: [5, 4, 4, 4, 4, 4, 1],
    size: 25,
  },
  QA: {
    prefix: 'QA',
    blocks: [4, 4, 4, 4, 4, 4, 4, 1],
    size: 29,
  },
  RO: {
    prefix: 'RO',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  SM: {
    prefix: 'SM',
    blocks: [4, 4, 4, 4, 4, 4, 3],
    size: 27,
  },
  SA: {
    prefix: 'SA',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  RS: {
    prefix: 'RS',
    blocks: [5, 4, 4, 4, 4, 2],
    size: 22,
  },
  SK: {
    prefix: 'SK',
    blocks: [5, 4, 4, 4, 4, 4],
    size: 24,
  },
  SI: {
    prefix: 'SI',
    blocks: [5, 4, 4, 4, 3],
    size: 19,
  },
  ES: {
    prefix: 'ES',
    blocks: [5, 4, 4, 4, 4, 4],
    size: 24,
  },
  SE: {
    prefix: 'SE',
    blocks: [5, 4, 4, 4, 4, 4],
    size: 24,
  },
  CH: {
    prefix: 'CH',
    blocks: [4, 4, 4, 4, 4, 1],
    size: 21,
  },
  TN: {
    prefix: 'TN',
    blocks: [5, 4, 4, 4, 4, 4],
    size: 24,
  },
  TR: {
    prefix: 'TR',
    blocks: [4, 4, 4, 4, 4, 4, 2],
    size: 26,
  },
  AE: {
    prefix: 'AE',
    blocks: [4, 4, 4, 4, 4, 3],
    size: 23,
  },
  GB: {
    prefix: 'GB',
    blocks: [4, 4, 4, 4, 4, 2],
    size: 22,
  },
  VG: {
    prefix: 'VG',
    blocks: [4, 4, 4, 4, 4, 4],
    size: 24,
  },
  UA: {
    prefix: 'UA',
    blocks: [4, 4, 4, 4, 4, 4, 4, 1],
    size: 29,
  },
};

export const preferredCountries = [
  COUNTRIES.FR.alpha2,
  COUNTRIES.GB.alpha2,
  COUNTRIES.DE.alpha2,
  COUNTRIES.ES.alpha2,
  COUNTRIES.BE.alpha2,
  COUNTRIES.IT.alpha2,
];

export function isApprovedCountry(countryKey: string): boolean {
  const country = COUNTRIES[countryKey];
  return country ? country.approved === true : false;
}

export function getVat(countryKey: string): Vat {
  const country = COUNTRIES[countryKey];

  if (!country || !country.vatRates) {
    return {
      defaultValue: undefined,
      vatRates: [],
    };
  }

  const defaultVatRate = country.vatRates.find((vatRate) => vatRate.default);

  return {
    defaultValue: defaultVatRate ? defaultVatRate.value : undefined,
    vatRates: country.vatRates,
  };
}

/**
 * Get a specific country's settings, and return the proper reverse Vat Rate
 * according to the payment date
 *
 * @param {string} country
 * @param {string} payableDate
 */
export function getReverseVatRate(
  country: string,
  payableDate: string,
): number {
  const countryConfig = COUNTRIES[country];

  // Country was not provided or does not exist or doesn't provide reversedVAT
  if (!country || !countryConfig?.reverseVatRates || !payableDate) {
    throw new Error(
      'Cannot compute reverse VAT rate, please check parameters.',
    );
  }

  const { reverseVatRates: reverseChargeRates } = countryConfig;

  // Filter valid VAT reverse rates (those in the future)
  // then sort from newest to latest
  const filteredRates = reverseChargeRates
    ?.filter((vatItem: ReverseVatRate) => {
      return new Date(vatItem.validFrom) <= new Date(payableDate);
    })
    .sort(
      (a: ReverseVatRate, b: ReverseVatRate) =>
        new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime(),
    );
  if (filteredRates?.length === 0) {
    throw new Error('Cannot find reverse charge rate');
  }

  // Return newest valid reverse VAT value
  return filteredRates?.[0].value;
}

const EU_MEMBERS = [
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Republic of Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'GP', // Guadeloupe
  'MQ', // Martinique
  'GF', // French Guiana
  'RE', // Réunion
  'YT', // Mayotte
];

const EEA_MEMBERS = [
  ...EU_MEMBERS,
  'IS', // Iceland
  'LI', // Liechtenstein
  'NO', // Norway
];

const EEA_MEMBERS_COUNTRY_CODE = new Set(EEA_MEMBERS);

export function isEeaMember(countryCode: string): boolean {
  return EEA_MEMBERS_COUNTRY_CODE.has(countryCode);
}

const GB_COUNTRIES = ['GB', 'GI', 'GG', 'IM', 'JE'];

export function isGbCountry(countryCode: string): boolean {
  return GB_COUNTRIES.includes(countryCode);
}

export function isSupportedCountry(countryCode: string): boolean {
  return (
    isEeaMember(countryCode) ||
    countryCode === 'US' ||
    GB_COUNTRIES.includes(countryCode)
  );
}

export const getCountryCodes = () => {
  return Object.values(COUNTRIES).map(({ alpha2 }) => alpha2);
};

export const getCountryFromCode = (
  code: keyof typeof COUNTRIES,
): (typeof COUNTRIES)[typeof code] | null => COUNTRIES[code] ?? null;
