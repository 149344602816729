import { type FormikErrors } from 'formik';
import isEmpty from 'lodash/isEmpty';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import {
  isAccountHolderNameValid,
  isAccountNumberValid,
  isBicSwiftValid,
  isIbanValid,
  isRoutingNumberValid,
  isSortCodeValid,
} from 'src/core/utils/bankInfoFormats';
import { isRequiredValid } from 'src/core/utils/validators';

import {
  MAX_NUMBER_OF_LATE_RECEIPTS,
  MIN_NUMBER_OF_LATE_RECEIPTS,
} from '../../constants/controlRules';
import { type Member, type DraftMember } from '../../models/member';

export type MemberEditFormikErrors = {
  firstName?: boolean;
  lastName?: boolean;
  bankInfo?: Partial<Record<keyof NonNullable<Member['bankInfo']>, string>> & {
    apiError?: string;
  };
  roleIds?: boolean;
  controlRule?: string;
};

export type MemberEditFormikValues = DraftMember;

export const validate = (
  member: Member,
  t: TGlobalFunctionTyped,
  values: MemberEditFormikValues,
  shouldValidateBankInformation: boolean,
): MemberEditFormikErrors => {
  const errors: MemberEditFormikErrors = {};

  if (!member.pending) {
    if (!values.firstName) {
      errors.firstName = true;
    }
    if (!values.lastName) {
      errors.lastName = true;
    }
  }
  if (values.roleIds?.length === 0 && !member.isAccountOwner) {
    errors.roleIds = true;
  }

  const controlRuleErrors = validateControlRules(t, values.controlRule);
  if (!isEmpty(controlRuleErrors)) {
    errors.controlRule = controlRuleErrors;
  }

  const bankInfoErrors = shouldValidateBankInformation
    ? validateBankInfoForm(
        t,
        member.requiredInfoTypesForEmployeeBankAddress,
        values.bankInfo,
      )
    : {};
  if (!isEmpty(bankInfoErrors)) {
    errors.bankInfo = bankInfoErrors;
  }
  return errors;
};

const validateControlRules = (
  t: TGlobalFunctionTyped,
  controlRule: Member['controlRule'],
) => {
  if (controlRule && controlRule?.isCustom) {
    if (controlRule.incompletePaymentsLimit === undefined) {
      return t('controlRulesModal.errors.invalidParameters');
    }

    if (
      controlRule.incompletePaymentsLimit &&
      controlRule.incompletePaymentsLimit > MAX_NUMBER_OF_LATE_RECEIPTS
    ) {
      return t('controlRulesModal.errors.maxLimit');
    }

    if (controlRule.incompletePaymentsLimit < MIN_NUMBER_OF_LATE_RECEIPTS) {
      return t('controlRulesModal.errors.minLimit');
    }
  }
};

export type BankInfoFormValues = Partial<{
  iban: string;
  bic: string;
  cuc: string;
  sortCode: string;
  accountNumber: string;
  accountHolderName: string;
  routingNumber: string;
}>;

const validateBankInfoForm = (
  t: TGlobalFunctionTyped,
  requiredInfoTypesForEmployeeBankAddress: (keyof BankInfoFormValues)[],
  values: Member['bankInfo'],
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const errors: FormikErrors<BankInfoFormValues> = {};
  if (requiredInfoTypesForEmployeeBankAddress.includes('iban')) {
    if (!values || !values.iban || !isRequiredValid(values.iban)) {
      errors.iban = t('misc.requiredField');
    } else if (!isIbanValid(values.iban)) {
      errors.iban = t('bankInfoForm.ibanInvalid');
    }
  }

  if (requiredInfoTypesForEmployeeBankAddress.includes('bic')) {
    if (!values || !values.bic || !isRequiredValid(values.bic)) {
      errors.bic = t('misc.requiredField');
    } else if (!isBicSwiftValid(values.bic)) {
      errors.bic = t('bankInfoForm.bicInvalid');
    }
  }

  if (requiredInfoTypesForEmployeeBankAddress.includes('sortCode')) {
    if (!values || !values.sortCode || !isRequiredValid(values.sortCode)) {
      errors.sortCode = t('misc.requiredField');
    } else if (!isSortCodeValid(values.sortCode)) {
      errors.sortCode = t('bankInfoForm.sortCodeInvalid');
    }
  }

  if (requiredInfoTypesForEmployeeBankAddress.includes('accountNumber')) {
    if (
      !values ||
      !values.accountNumber ||
      !isRequiredValid(values.accountNumber)
    ) {
      errors.accountNumber = t('misc.requiredField');
    } else if (!isAccountNumberValid(values.accountNumber)) {
      errors.accountNumber = t('bankInfoForm.accountNumberInvalid');
    }
  }

  if (requiredInfoTypesForEmployeeBankAddress.includes('routingNumber')) {
    if (
      !values ||
      !values.routingNumber ||
      !isRequiredValid(values.routingNumber)
    ) {
      errors.routingNumber = t('misc.requiredField');
    } else if (!isRoutingNumberValid(values.routingNumber)) {
      errors.routingNumber = t('bankInfoForm.routingNumberInvalid');
    }
  }

  if (requiredInfoTypesForEmployeeBankAddress.includes('accountHolderName')) {
    if (
      !values ||
      !values.accountHolderName ||
      !isRequiredValid(values.accountHolderName)
    ) {
      errors.accountHolderName = t('misc.requiredField');
    } else if (!isAccountHolderNameValid(values.accountHolderName)) {
      errors.accountHolderName = t('bankInfoForm.accountHolderNameInvalid');
    }
  }

  // If at least one field is filled, we keep the errors
  const hasOneFilledBankInfoField =
    requiredInfoTypesForEmployeeBankAddress.some(
      (field) =>
        values && values[field] && isRequiredValid(values[field] as string),
    );

  return hasOneFilledBankInfoField ? errors : {};
};
