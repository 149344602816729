import { z } from 'zod';

import { bankableCardSchema } from './types/bankable-card';
import { mobileSchemaNullable } from './types/mobile';
import { recardOrderSchema } from './types/recard-order';
import {
  nonSkippedOrderStateSchema,
  skippedRecardOrderStateSchema,
} from './types/recard-order-state';
import { shippingAddressSchema } from './types/shipping-address';
import { userSchema } from './types/user';

export const getShippingAddressSchema = z.discriminatedUnion('state', [
  z.object({
    state: nonSkippedOrderStateSchema,
    shippingAddress: shippingAddressSchema.merge(mobileSchemaNullable),
  }),
  z.object({
    state: skippedRecardOrderStateSchema,
    shippingAddress: z.null(),
  }),
]);

export const getPhysicalCardSchema = z.object({
  companyId: z.string(),
  bankableCard: bankableCardSchema.omit({ cardName: true }),
  recardOrder: z
    .intersection(
      getShippingAddressSchema,
      recardOrderSchema.omit({
        state: true,
        shippingAddress: true,
        userId: true,
        bkbCardId: true,
        trackingLink: true,
        trackingNumber: true,
      }),
    )
    .nullable(),
  user: userSchema.merge(mobileSchemaNullable),
});
