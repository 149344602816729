import { type RawRole, type Role } from '../models/roles';

/**
 * Return both camelCase and snake_case, deeply
 * snake_case is used for legacy reasons and should not be called in new code
 */
export const reshapeRole = (data: RawRole): Role => {
  return {
    ...data,
    id: data.id,
    name: data.name,
    order: data.order,
    scope: {
      entityId: data.scope.entity_id,
      entityType: data.scope.entity_type,
      ...data.scope,
    },
    scopeId: data.scope_id,

    companyId: data.company_id,
    fullPermissions: data.full_permissions,
    organisationId: data.organisation_id,
    parentId: data.parent_id,
    parentsId: data.parents_id,
    /** @todo once we know the type, we should transform to camelCase */
    userPermissions: data.user_permissions,
  };
};
