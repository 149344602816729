import {
  Button,
  Callout,
  IconButton,
  InfoTip,
  Modal,
  Table,
} from '@dev-spendesk/grapes';
import { useMemo, useState } from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
  type I18nKey,
} from 'common/hooks/useTranslation';
import { type IntegrationValidateSettingsResult } from 'modules/accounting-integration/models/settings-validation';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import defaultAccountingCodes from 'src/core/config/defaultAccountingCodes';
import FEATURES from 'src/core/constants/features';
import { useIsOnboardedWithSfs } from 'src/core/modules/bookkeep/hooks/useIsOnboardedWithSfs';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';
import { useSFSMigration } from 'src/sfs-migration/used-in-js-app/hooks/useSFSMigration';

import styles from './BankAccountsSection.module.css';
import { EditBankAccountForm } from './EditBankAccountForm';
import { isLocalOnlyListAccount, type ListAccount } from './listAccount';
import { type ModalState } from './modalState';
import { validateBankAccount } from './validation';
import {
  type AccountingSoftware,
  type BankAccountCapability,
  hasIntegrationFileBasedExport,
  type IntegrationStatusWithIntegration,
} from '../../../../../../integration/status';
import {
  type SelectableBankAccount,
  type BankAccountPurpose,
} from '../../../../../accounting';
import { useBankAccountsQuery } from '../../../../hooks/useBankAccountsQuery';
import { useSetBankAccountsMutation } from '../../../../hooks/useSetBankAccountsMutation';
import { useUpdateBankAccountsMutation } from '../../../../hooks/useUpdateBankAccountsMutation';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

// TODO: fix back-end, which is currently preventing from updating bank
// accounts for file-based integrations. It always returns codeAlreadyExists.

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  spendeskBankAccountCapability?: BankAccountCapability;
  bankFeesAccountCapability?: BankAccountCapability;
  fundingBankAccountCapability?: BankAccountCapability;
  // TODO add other bank account capabilities: funding, creditor, ...
  settingsValidation: IntegrationValidateSettingsResult;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const BankAccountsSection = (props: Props) => {
  const {
    integrationStatus,
    spendeskBankAccountCapability,
    bankFeesAccountCapability,
    fundingBankAccountCapability,
    settingsValidation,
  } = props;

  // TODO: Will be completely removed after sfs migration done
  // Calculate isOnboardedWithSfs
  const wasFirstOnboardedWithSfs = useIsOnboardedWithSfs();

  // TODO: Will be completely removed after sfs migration done
  // Calculate isSfsMigration
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);
  const sfsMigrationQuery = useSFSMigration({
    isEnabled: hasTMPSFSMigrationPage,
  });
  const sfsMigration = unwrapQuery(sfsMigrationQuery);
  let isSfsMigration = [
    'WaitingForFunds',
    'InProgress',
    'Closing',
    'Done',
  ].includes(sfsMigration?.status ?? '');

  // If company onboarded with SFS, ensure there is no any SFS migration
  if (wasFirstOnboardedWithSfs) {
    isSfsMigration = false;
  }

  const bankAccountsQueryState = useBankAccountsQuery();
  const [updateBankAccounts, updateBankAccountsQueryState, clearErrors] =
    useUpdateBankAccountsMutation();
  const [setBankAccounts] = useSetBankAccountsMutation();
  const { t } = useTranslation('global');
  const isFileBasedIntegration = hasIntegrationFileBasedExport(
    integrationStatus.integration,
  );

  const availableBankAccounts =
    bankAccountsQueryState.status !== 'success'
      ? null
      : bankAccountsQueryState.data;

  // TODO: Will be completely removed after sfs migration done
  // Calculate hasSameCodeForBankableAndSfs
  const getSelectedAccountByPurpose = (purpose: BankAccountPurpose) => {
    if (!availableBankAccounts) {
      return null;
    }

    const bankAccountsForPurpose = availableBankAccounts[purpose];
    if (bankAccountsForPurpose) {
      return bankAccountsForPurpose.find((b) => b.accountPurpose === purpose);
    }
    return null;
  };

  // TODO: Will be completely removed after sfs migration done
  const calculateHasSameCodeForBankableAndSfs = (
    editingAccount?: ListAccount,
  ) => {
    if (wasFirstOnboardedWithSfs) {
      return false;
    }

    if (!editingAccount) {
      return false;
    }

    // We do not allow to select same account for bankable and sfs for pullAndSelect mode
    if (editingAccount.capability === 'pullAndSelect') {
      return false;
    }

    const otherAccountPurpose: BankAccountPurpose =
      editingAccount.purpose === 'spendesk' ? 'spendeskFs' : 'spendesk';

    const editingAccountCode = editingAccount.code;
    const otherAccount = getSelectedAccountByPurpose(otherAccountPurpose);
    const otherAccountCode = otherAccount?.code;

    return (
      editingAccountCode &&
      otherAccountCode &&
      editingAccountCode.toLowerCase() === otherAccountCode.toLowerCase()
    );
  };

  // Ensure user acknowledge the default sfs account code or 90 days been passed from deadline
  const selectedSfsAccount = getSelectedAccountByPurpose('spendeskFs');
  const displaySfsDefaultBankAccountWarning =
    // We should wait for the queries to be successful before displaying the warning
    // Otherwise it will show the warning while the data is still loading
    !!selectedSfsAccount &&
    sfsMigrationQuery.status === 'success' &&
    shouldDisplaySfsDefaultBankAccountCodeWarning({
      isSfsMigration,
      isSelected: !!selectedSfsAccount?.isSelected,
      isFileBasedIntegration,
      sfsAccountCode: selectedSfsAccount?.code,
    });

  // TODO: Bankable(purpose=spendesk) part should be removed after sfs migration done
  // Handle spendeskBankAccounts either Bankable(purpose=spendesk) and/or SFS(purpose=spendeskFs)
  const spendeskBankAccounts: ListAccount[] = [];
  if (spendeskBankAccountCapability) {
    if (!wasFirstOnboardedWithSfs) {
      spendeskBankAccounts.push(
        makeAccountListItem({
          availableBankAccounts: availableBankAccounts?.spendesk ?? [],
          purpose: 'spendesk',
          capability: spendeskBankAccountCapability,
          t,
          settingsValidation,
          isSfsMigration,
        }),
      );
    }

    if (wasFirstOnboardedWithSfs || isSfsMigration) {
      spendeskBankAccounts.push(
        makeAccountListItem({
          availableBankAccounts: availableBankAccounts?.spendeskFs ?? [],
          purpose: 'spendeskFs',
          capability: spendeskBankAccountCapability,
          t,
          settingsValidation,
          isSfsMigration,
          warning: displaySfsDefaultBankAccountWarning ? true : undefined,
        }),
      );
    }
  }

  const data = useMemo<ListAccount[]>(() => {
    if (bankAccountsQueryState.status === 'success') {
      return [
        ...spendeskBankAccounts,
        ...(fundingBankAccountCapability
          ? [
              makeAccountListItem({
                availableBankAccounts: availableBankAccounts?.company ?? [],
                purpose: 'company',
                capability: fundingBankAccountCapability,
                t,
                settingsValidation,
                isSfsMigration,
              }),
            ]
          : []),
        ...(bankFeesAccountCapability
          ? [
              makeAccountListItem({
                availableBankAccounts: availableBankAccounts?.bankFees ?? [],
                purpose: 'bankFees',
                capability: bankFeesAccountCapability,
                t,
                settingsValidation,
                isSfsMigration,
              }),
            ]
          : []),
      ];
    }
    return [];
  }, [bankAccountsQueryState]);

  const [editModalState, setEditModalState] = useState<ModalState>({
    isModalOpen: false,
    inputChanged: false,
  });

  const wrongCodeFormatError =
    !!editModalState.editedAccount &&
    updateBankAccountsQueryState.status === 'error' &&
    updateBankAccountsQueryState.error.type === 'RequestError' &&
    updateBankAccountsQueryState.error.data.outcome === 'notSet';

  const hasAtLeastOneError = data.find((row) => row.error);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function handleUpdateBankAccount() {
    const { editedAccount, isModalOpen } = editModalState;
    if (!isModalOpen) {
      // eslint-disable-next-line no-console
      console.warn(
        "This shouldn't happen: modal will always be open when we see modal buttons",
      );
      return;
    }

    if (!editedAccount) {
      return;
    }

    switch (editedAccount?.capability) {
      case 'localOnly': {
        setEditModalState({
          ...editModalState,
          error: 'none',
          inputChanged: false,
        });

        if (isFileBasedIntegration) {
          const accountUpdate = {
            id: editedAccount.accountId,
            code: editedAccount.code,
            currency: editedAccount.currency ?? '',
            accountPurpose: editedAccount.purpose,
          };
          const accountsUpdates = [accountUpdate];

          // TODO: Will be completely removed after sfs migration done
          // Temporarily update "spendesk" purpose bank account along with
          // "spendeskFs" purpose while sfs migrations are ongoing
          if (
            wasFirstOnboardedWithSfs &&
            editedAccount.purpose === 'spendeskFs'
          ) {
            accountsUpdates.push({
              ...accountUpdate,
              accountPurpose: 'spendesk',
            });
          }

          await setBankAccounts({
            accountsUpdates,
          });
          setEditModalState({
            ...editModalState,
            isModalOpen: false,
          });
        } else {
          // TODO: Will be completely removed after sfs migration done
          // Temporarily update "spendesk" purpose bank account along with
          // "spendeskFs" purpose while sfs migrations are ongoing
          if (
            wasFirstOnboardedWithSfs &&
            editedAccount.purpose === 'spendeskFs'
          ) {
            // eslint-disable-next-line @spendesk/must-use-result
            await updateBankAccounts({
              capabilityKind: editedAccount.capability,
              purpose: 'spendesk',
              newCode: editedAccount.code,
            });
          }

          const result = await updateBankAccounts({
            capabilityKind: editedAccount.capability,
            purpose: editedAccount.purpose,
            newCode: editedAccount.code,
          });

          if (result.outcome === 'set') {
            clearErrors();
            setEditModalState({
              ...editModalState,
              isModalOpen: false,
            });
          }
        }

        break;
      }
      case 'pullAndSelect': {
        // eslint-disable-next-line @spendesk/must-use-result
        await updateBankAccounts({
          capabilityKind: editedAccount.capability,
          purpose: editedAccount.purpose,
          newId: editedAccount.accountId ?? undefined,
        });

        setEditModalState({
          ...editModalState,
          isModalOpen: false,
        });
        break;
      }
      default:
        return rejectUnexpectedValue('accountCapability', editedAccount);
    }
  }

  const { editedAccount: editingAccount } = editModalState;

  const editBankAccountFormWarning =
    editingAccount &&
    ['spendesk', 'spendeskFs'].includes(editingAccount.purpose) &&
    calculateHasSameCodeForBankableAndSfs(editingAccount)
      ? t(
          // Ensure key is removed from lokalise when the migration fully completed
          'bookkeep.integrations.settings.hasSameCodeForBankableAndSfsWarning',
        )
      : undefined;

  // Do not allow selecting spendesk, spendeskFs and funding account with same account
  // filter out those accounts from dropdown on edit form
  const accountsToFilter = [];
  if (
    editingAccount &&
    (integrationStatus.capabilities.spendeskBankAccount === 'pullAndSelect' ||
      integrationStatus.capabilities.fundingBankAccount === 'pullAndSelect') &&
    ['spendesk', 'spendeskFs', 'company'].includes(editingAccount.purpose)
  ) {
    const otherAccountPurposes: {
      spendesk: BankAccountPurpose[];
      spendeskFs: BankAccountPurpose[];
      company: BankAccountPurpose[];
      supplierInvoices: BankAccountPurpose[];
      expenseClaims: BankAccountPurpose[];
      bankFees: BankAccountPurpose[];
    } = {
      spendesk: ['spendeskFs', 'company'],
      spendeskFs: ['spendesk', 'company'],
      company: ['spendesk', 'spendeskFs'],
      supplierInvoices: [],
      expenseClaims: [],
      bankFees: [],
    };
    for (const otherAccountPurpose of otherAccountPurposes[
      editingAccount.purpose
    ]) {
      const otherSelectedAccount =
        getSelectedAccountByPurpose(otherAccountPurpose);

      if (otherSelectedAccount) {
        accountsToFilter.push(otherSelectedAccount);
      }
    }
  }

  function accountNameDisplay(row: ListAccount) {
    const name =
      availableBankAccounts &&
      availableBankAccounts[row.purpose].find(
        (account) => account.id === row.accountId,
      )?.name;

    switch (integrationStatus.integration) {
      case 'Sage100':
        return name ? `${row.code} - ${name}` : undefined;
      default:
        return row.capability === 'localOnly' ? row.code : name;
    }
  }

  return (
    <div id="bank-accounts">
      <h3 className="IntegrationAccountingPage__section-title title-xl">
        {t('bookkeep.integrations.settings.sectionBankAccounts')}
      </h3>

      <p className="IntegrationAccountingPage__section-description body-m">
        {t(getBankAccountsDescriptionI18nKey(integrationStatus.integration))}
      </p>

      {hasAtLeastOneError && (
        <Callout
          className={styles.pullErrorCallout}
          title={t('bookkeep.integrations.settings.pullBankAccountsError')}
          variant="alert"
        />
      )}

      {displaySfsDefaultBankAccountWarning && (
        <Callout
          className={styles.hasDefaultSfsBankAccountCodeWarning}
          variant="warning"
          title={t(
            // Ensure key is removed from lokalise when the migration fully completed
            'bookkeep.integrations.settings.hasDefaultSfsBankAccountCodeWarning',
          )}
        />
      )}

      <Table
        hasColumnSeparator
        rowHeight="compact"
        columns={[
          {
            id: 'name',
            header: t('bookkeep.integrations.settings.bankAccountsTable.name'),
            width: '50%',
            renderCell(row) {
              return (
                <div className={styles.nameContainer}>
                  <span className={styles.name}>{row.name}</span>
                  {row.tooltip && <InfoTip content={row.tooltip} />}
                </div>
              );
            },
          },
          {
            id: 'code',
            header: t('bookkeep.integrations.settings.bankAccountsTable.code'),
            width: '50%',
            getCellVariant: (row) => {
              if (row.error) {
                return 'alert';
              }
              if (row.warning) {
                return 'warning';
              }
              return undefined;
            },
            renderCell(row, { isRowHovered }) {
              const nameToDisplay = accountNameDisplay(row);

              return (
                <CellWithButton
                  isButtonVisible={
                    isRowHovered &&
                    (!isFileBasedIntegration ||
                      (isFileBasedIntegration &&
                        bankAccountsQueryState.status === 'success'))
                  }
                  button={
                    <IconButton
                      iconName="pen"
                      variant="borderless"
                      onClick={() =>
                        setEditModalState({
                          isModalOpen: true,
                          error: 'none',
                          editedAccount: row,
                          inputChanged: false,
                        })
                      }
                      className="CellWithButton__button"
                      aria-label={t('misc.edit')}
                    />
                  }
                >
                  {nameToDisplay ?? <em>{t('misc.none')}</em>}
                </CellWithButton>
              );
            },
          },
        ]}
        data={data}
        footer={
          bankAccountsQueryState.status === 'loading' ? (
            <>{t('misc.loading')}</>
          ) : undefined
        }
      />

      <Modal
        actions={[
          <Button
            key="no"
            onClick={() => {
              setEditModalState({
                ...editModalState,
                isModalOpen: false,
              });
              clearErrors();
            }}
            text={t('misc.cancel')}
            variant="secondary"
          />,
          <Button
            key="yes"
            text={
              editBankAccountFormWarning
                ? t(
                    'bookkeep.integrations.settings.editBankAccountSaveAnywayOnWarning',
                  )
                : t('misc.saveChanges')
            }
            variant="primary"
            isDisabled={
              editModalState.editedAccount
                ? !canSaveEditedAccount(
                    editModalState.editedAccount,
                    getCapabilityForEditedAccount(
                      editModalState.editedAccount,
                      props,
                    ),
                    wrongCodeFormatError,
                    editModalState.inputChanged,
                    integrationStatus.integration,
                  )
                : false
            }
            onClick={() => {
              const { editedAccount, isModalOpen } = editModalState;
              if (!isModalOpen) {
                // eslint-disable-next-line no-console
                console.warn(
                  "This shouldn't happen: modal will always be open when we see modal buttons",
                );
                return;
              }

              if (!editedAccount) {
                return;
              }

              const result = validateBankAccount(
                editedAccount,
                integrationStatus.integration,
              );

              if (result.outcome === 'valid') {
                return handleUpdateBankAccount();
              }

              setEditModalState({
                ...editModalState,
                error: result.reason,
                inputChanged: false,
              });
            }}
          />,
        ]}
        iconName="info"
        iconVariant="info"
        title={t('bookkeep.integrations.settings.editBankAccounts')}
        data-testid="edit-bank-account-modal"
        isOpen={editModalState.isModalOpen}
        onClose={() => {
          // This should be called after the fading out transition
          // We clean up the previously edited account
          setEditModalState({
            isModalOpen: false,
            inputChanged: false,
          });
        }}
      >
        {editModalState.editedAccount && (
          <EditBankAccountForm
            integrationStatus={integrationStatus}
            allAccounts={
              availableBankAccounts
                ? availableBankAccounts[editModalState.editedAccount.purpose]
                : []
            }
            editedAccount={editModalState.editedAccount}
            showError={wrongCodeFormatError && !editModalState.inputChanged}
            isError={
              editModalState.isModalOpen &&
              editModalState.error !== 'none' &&
              !editModalState.inputChanged
            }
            setEditedAccount={(account) =>
              setEditModalState({
                ...editModalState,
                editedAccount: account,
                inputChanged: true,
              })
            }
            warning={editBankAccountFormWarning}
            accountsToFilter={accountsToFilter}
          />
        )}
      </Modal>
    </div>
  );
};

function getCapabilityForEditedAccount(
  editedAccount: ListAccount,
  props: Props,
): BankAccountCapability {
  switch (editedAccount.purpose) {
    case 'bankFees': {
      if (!props.bankFeesAccountCapability) {
        throw new Error(
          'Integration does not have the bank fees account capability',
        );
      }
      return props.bankFeesAccountCapability;
    }

    case 'company': {
      if (!props.fundingBankAccountCapability) {
        throw new Error(
          'Integration does not have the funding bank account capability',
        );
      }
      return props.fundingBankAccountCapability;
    }

    case 'spendesk':
    case 'spendeskFs': {
      if (!props.spendeskBankAccountCapability) {
        throw new Error(
          'Integration does not have the spendesk bank account capability',
        );
      }
      return props.spendeskBankAccountCapability;
    }

    case 'supplierInvoices':
    case 'expenseClaims':
      throw new Error('Not implemented');

    default:
      rejectUnexpectedValue('bank account purpose', editedAccount.purpose);
  }
}

function canSaveEditedAccount(
  editedAccount: ListAccount,
  capability: BankAccountCapability,
  wrongCodeFormatError: boolean,
  inputChanged: boolean,
  integration: AccountingSoftware,
): boolean {
  switch (capability) {
    case 'pullAndSelect':
      return Boolean(editedAccount.accountId);
    case 'localOnly': {
      if (
        hasIntegrationFileBasedExport(integration) &&
        isLocalOnlyListAccount(editedAccount)
      ) {
        return editedAccount.code.trim() !== '' || inputChanged;
      }
      return !(wrongCodeFormatError && !inputChanged);
    }
    default:
      rejectUnexpectedValue('capability', capability);
  }
}

function getBankAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.bankAccountsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.bankAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.bankAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.bankAccountsDescription';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.bankAccountsDescription';
    case 'Sage100':
      // TODO: Check content
      return 'bookkeep.integrations.sage100.bankAccountsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

function makeAccountListItem({
  availableBankAccounts,
  purpose,
  capability,
  settingsValidation,
  t,
  isSfsMigration,
  warning,
}: {
  availableBankAccounts: SelectableBankAccount[];
  purpose: BankAccountPurpose;
  capability: BankAccountCapability;
  settingsValidation: IntegrationValidateSettingsResult;
  t: TGlobalFunctionTyped;
  isSfsMigration: boolean;
  warning?: true;
}): ListAccount {
  switch (capability) {
    case 'pullAndSelect': {
      const account = availableBankAccounts.find(
        (a) => a.accountPurpose === purpose && a.isSelected,
      );

      return {
        capability,
        purpose,
        name: t(getBankAccountNameForPurpose(purpose, isSfsMigration)),
        tooltip: getBankAccountTooltipForPurpose(purpose, t),
        accountId: account?.id ?? null,
        code: account?.code ?? '',
        currency: account?.currency ?? null,
        // TODO improve error extraction logic
        error: getValidationErrorFromSettingsPullAndSelect(
          purpose,
          settingsValidation,
        ),
        warning,
      };
    }
    case 'localOnly': {
      const account = availableBankAccounts.find(
        (a) => a.accountPurpose === purpose,
      );

      return {
        capability,
        purpose,
        name: t(getBankAccountNameForPurpose(purpose, isSfsMigration)),
        tooltip: getBankAccountTooltipForPurpose(purpose, t),
        accountId: account?.id ?? '',
        code: account?.code ?? '',
        currency: account?.currency ?? null,
        // TODO improve error extraction logic
        error: getValidationErrorFromSettingsLocalOnly(
          purpose,
          settingsValidation,
        ),
        warning,
      };
    }
    default:
      rejectUnexpectedValue('capability', capability);
  }
}

function getValidationErrorFromSettingsPullAndSelect(
  purpose: BankAccountPurpose,
  settingsValidation: IntegrationValidateSettingsResult,
) {
  const kind = 'notSelected' as const;

  switch (purpose) {
    case 'bankFees':
      return settingsValidation.bankFeesAccount ? { kind } : undefined;
    case 'spendesk':
      return settingsValidation.spendeskBankAccount ? { kind } : undefined;
    case 'spendeskFs':
      return settingsValidation.spendeskFsBankAccount ? { kind } : undefined;
    case 'company':
      return settingsValidation.fundingBankAccount ? { kind } : undefined;
    case 'supplierInvoices':
    case 'expenseClaims':
      return settingsValidation.creditorBankAccount ? { kind } : undefined;
    default:
      rejectUnexpectedValue('purpose', purpose);
  }
}

function getValidationErrorFromSettingsLocalOnly(
  purpose: BankAccountPurpose,
  settingsValidation: IntegrationValidateSettingsResult,
) {
  const kind = 'invalidValue' as const;

  switch (purpose) {
    case 'bankFees':
      return settingsValidation.bankFeesAccount ? { kind } : undefined;
    case 'spendesk':
      return settingsValidation.spendeskBankAccount ? { kind } : undefined;
    case 'spendeskFs':
      return settingsValidation.spendeskFsBankAccount ? { kind } : undefined;
    case 'company':
      return settingsValidation.fundingBankAccount ? { kind } : undefined;
    case 'supplierInvoices':
    case 'expenseClaims':
      return settingsValidation.creditorBankAccount ? { kind } : undefined;
    default:
      rejectUnexpectedValue('purpose', purpose);
  }
}

function getBankAccountNameForPurpose(
  purpose: BankAccountPurpose,
  isSfsMigration = false,
): I18nKey {
  switch (purpose) {
    case 'spendesk':
      return isSfsMigration
        ? // Ensure key is removed from lokalise when the migration fully completed
          'bookkeep.integrations.settings.spendeskBankAccountTitle_SFSMigration'
        : 'bookkeep.integrations.settings.spendeskBankAccountTitle';
    case 'spendeskFs':
      return isSfsMigration
        ? // Ensure key is removed from lokalise when the migration fully completed
          'bookkeep.integrations.settings.spendeskFsBankAccountTitle_SFSMigration'
        : 'bookkeep.integrations.settings.spendeskFsBankAccountTitle';
    case 'bankFees':
      return 'bookkeep.integrations.settings.bankFeesAccountTitle';
    case 'company':
      return 'bookkeep.integrations.settings.companyBankAccountTitle';
    case 'expenseClaims':
    case 'supplierInvoices':
      throw new Error('Not implemented');
    default:
      rejectUnexpectedValue('purpose', purpose);
  }
}

function getBankAccountTooltipForPurpose(
  purpose: BankAccountPurpose,
  t: TGlobalFunctionTyped,
): string | null {
  switch (purpose) {
    case 'spendesk':
      return null;
    case 'spendeskFs':
      return null;
    case 'supplierInvoices':
      return t('accounting.invoicesBankAccountTip');
    case 'expenseClaims':
      return t('accounting.expenseClaimsBankAccountTip');
    case 'bankFees':
      return null;
    case 'company':
      return t('accounting.classicBankAccountTip');
    default:
      rejectUnexpectedValue('purpose', purpose);
  }
}

export const shouldDisplaySfsDefaultBankAccountCodeWarning = ({
  isSfsMigration,
  isSelected,
  isFileBasedIntegration,
  sfsAccountCode,
}: {
  isSfsMigration: boolean;
  isSelected: boolean;
  isFileBasedIntegration: boolean;
  sfsAccountCode?: string | undefined;
}): boolean => {
  if (!isSfsMigration) {
    return false;
  }
  if (isFileBasedIntegration) {
    // File-based integration -> we show the callout if the SFS account code is the default one
    return (
      !sfsAccountCode ||
      [
        defaultAccountingCodes.fr.SPENDESKFS_ACCOUNT.toLowerCase(),
        defaultAccountingCodes.others.SPENDESKFS_ACCOUNT.toLowerCase(),
      ].includes(sfsAccountCode?.toLowerCase())
    );
  }
  // Native integration -> we show the callout if the SFS account code is empty
  return !isSelected && !sfsAccountCode;
};
