import isNil from 'lodash/isNil';

import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type PayableType } from './payableType';

export type CustomFieldType = 'List' | 'Boolean';

export type CustomFieldEligibleEntity =
  | 'payment'
  | 'expense'
  | 'request'
  | 'subscription';

export type CustomFieldScope = {
  team: {
    id: string;
  };
};

export type CustomFieldAssociation = {
  customFieldId: string;
  customFieldValueId: string;
  value: string;
};

export type CustomField = {
  id: string;
  name: string;
  type: CustomFieldType;
  isOptional: boolean;
  isSplittable: boolean;
  allowNewValue: boolean;
  scopes: CustomFieldScope[];
  eligibleEntities: CustomFieldEligibleEntity[];
  archiveDate: string | null;
  values: {
    id: string;
    name: string | boolean;
    archiveDate?: string | null;
  }[];
  valuesTotalCount: number;
};

/**
 * Filter a given list of custom fields to only keep the ones that are
 * applicable to an expense or a bulk of expenses, based on their existing
 * custom fields values, team value and expense types.
 *
 * @param {Array} customFields The custom fields list to filter.
 * @param {Object} customFieldsValues A key/value map of custom fields values,
 * where key is the custom field id and value is the value id or a boolean.
 * @param {String|null|undefined} teamId The team value of the expense or bulk
 * of expense, can be nil.
 * @param {Array} expenseTypes The payables expense types
 * @returns {Array} The filtered custom fields considered applicable.
 */
export function filterCustomFieldsForPayable(
  customFields: CustomField[],
  customFieldsValues: Record<string, boolean | string>,
  teamId: string | undefined,
  expenseTypes: PayableType[],
): CustomField[] {
  return customFields.filter((customField) => {
    const teamScopes = customField.scopes.filter(
      (scope) => scope.team !== undefined,
    );
    const isEligibleToTeam =
      teamScopes.length === 0 ||
      (teamId && teamScopes.some((scope) => scope.team.id === teamId));

    // We will display readonly values for archived custom fields
    const isArchived = !isNil(customField.archiveDate);
    const hasArchivedValue =
      isArchived && !isNil(customFieldsValues[customField.id]);

    return (
      isEligibleToTeam &&
      expenseTypes.every((expenseType) =>
        isEligibleToExpenseType(customField, expenseType),
      ) &&
      (!isArchived || hasArchivedValue)
    );
  });
}

export function isEligibleToExpenseType(
  customField: CustomField,
  expenseType: PayableType,
): boolean {
  switch (expenseType) {
    case 'expense_claim':
    case 'mileage_allowance':
    case 'per_diem_allowance': {
      return (
        customField.eligibleEntities.includes('expense') ||
        customField.eligibleEntities.includes('payment')
      );
    }
    case 'card_purchase':
    case 'invoice_purchase':
    case 'reversal': {
      return customField.eligibleEntities.includes('payment');
    }
    default:
      rejectUnexpectedValue('expenseType', expenseType);
  }
}

/**
 * Filter a given list of custom fields to only keep the ones that are
 * still active (i.e. not archived).
 *
 * @param {Array} customFields The custom fields list to filter.
 * @returns {Array} The filtered custom fields considered active.
 */
export function filterActiveCustomFields(
  customFields: CustomField[],
): CustomField[] {
  return customFields.filter((customField) => {
    const isArchived = !isNil(customField.archiveDate);
    return !isArchived;
  });
}
