import { useQuery } from '../../../core/api/hooks/useQuery';
import type {
  GetSfsMigrationTasksSummaryResponse,
  GetSfsMigrationTasksSummaryResponseError,
} from '../../common/models/tasks-summary';

export const useTasksSummary = ({ isEnabled }: { isEnabled: boolean }) => {
  return useQuery<
    GetSfsMigrationTasksSummaryResponse,
    GetSfsMigrationTasksSummaryResponseError
  >({
    key: ['tasks-summary'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/sfs-migration/tasks-summary`,
    },
  });
};
