import { Button, Panel, SkeletonText } from '@dev-spendesk/grapes';
import { type LocationDescriptor } from 'history';
import { type TFunction } from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { type QueryState } from 'src/core/api/queryState';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { getCountryFromCode } from 'src/core/config/country';
import { routeFor, routes } from 'src/core/constants/routes';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { EntityBreakdownBlock } from './EntityBreakdownBlock';
import { EntityGenericStatisticsBlock } from './EntityGenericStatisticsBlock';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import { useOrganisationReportingEntityDetails } from '../../../hooks/useOrganisationReportingEntityDetails';
import {
  type OrganisationReportingStatistics,
  type OrganisationReportingEntity,
  type OrganisationReportingEntityDetails,
} from '../../../types';

type CountryCode = Parameters<typeof getCountryFromCode>[0];

type EntityStatisticDefinitions = Record<
  keyof OrganisationReportingStatistics,
  {
    isAvailable: boolean;
    title: string;
    link: LocationDescriptor;
    label: string | null;
  }
>;

export type Props = {
  features: OrganisationFeatures;
  entity: OrganisationReportingEntity;
  onDataLoaded: (entityId: string, status: QueryState['status']) => void;
};

export const OrganisationReportingEntityStatistics = ({
  entity,
  features,
  onDataLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  const entityDetailsQueryState = useOrganisationReportingEntityDetails(
    entity,
    features,
  );

  useEffect(() => {
    if (entityDetailsQueryState.status === 'success') {
      onDataLoaded(entity.id, entityDetailsQueryState.status);
      return () => {};
    }
  }, [entityDetailsQueryState.status]);

  const entityStatistics: EntityStatisticDefinitions = {
    requestsToApprove: {
      isAvailable: features.requestsToApprove,
      title: t('organisation.reporting.page.entities.entity.requestsToApprove'),
      link: routeFor(routes.REQUESTS.path, {
        company: entity.id,
        type: 'to-approve',
      }),
      label: null,
    },
    invoicesToPay: {
      isAvailable: features.invoicesToPay,
      title: t('organisation.reporting.page.entities.entity.invoicesToPay'),
      link: routeFor(routes.INVOICES_PAY.path, {
        company: entity.id,
      }),
      label: null,
    },
    // TODO 2024-09-17: reenable
    //   lateReceipts: {
    //   isAvailable: features.lateReceipts,
    //   title: t('organisation.reporting.page.entities.entity.lateReceipts'),
    //   link: `${routeFor(routes.PAYMENTS_ALL.path, {
    //     company: entity.id,
    //   })}?completionDeadline=late`,
    //   label: null,
    // },
    payablesToReview: {
      isAvailable: features.payablesToReview,
      title: t('organisation.reporting.page.entities.entity.payablesToReview'),
      link: routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
        company: entity.id,
      }),
      label: null,
    },
  };

  const placeholder = '-';
  const setStatisticsIntoDefinitions = (
    entityDetails: OrganisationReportingEntityDetails,
  ) => {
    entityStatistics.requestsToApprove.label = String(
      entityDetails.requestsToApprove ?? placeholder,
    );
    entityStatistics.invoicesToPay.label = String(
      entityDetails.invoicesToPay ?? placeholder,
    );
    // TODO 2024-09-17: reenable
    //   entityStatistics.lateReceipts.label = String(
    //   entityDetails.lateReceipts ?? placeholder,
    // );
    entityStatistics.payablesToReview.label = String(
      entityDetails.payablesToReview ?? placeholder,
    );
  };

  return (
    <QuerySuspense
      queryState={entityDetailsQueryState}
      loading={
        <Panel className="box-border w-full">
          <div className="flex gap-l">
            <div className="flex flex-auto flex-wrap gap-l">
              <div className="flex-[3]">
                <EntityGenericStatisticsBlock
                  title={getBranchTypeTitle(entity, t)}
                  contents={[
                    <div key="name" className="font-medium">
                      <SkeletonText />
                    </div>,
                    <div key="country">
                      <SkeletonText />
                    </div>,
                  ]}
                  stack
                />
              </div>
              {features.wallet && (
                <div className="flex-[3]">
                  <EntityGenericStatisticsBlock
                    stack
                    title={t(
                      'organisation.reporting.page.entities.entity.walletBalance',
                    )}
                    contents={[
                      <div key="walletBalance" className="font-medium">
                        <SkeletonText />
                      </div>,
                      <div key="wallBalanceAddFunds">
                        <SkeletonText />
                      </div>,
                    ]}
                  />
                </div>
              )}
              {features.wallet && (
                <div className="flex-[10]">
                  <SkeletonText size="l" />
                  <div className="flex flex-row justify-between gap-xs pt-s">
                    {[...Array(3).keys()].map((key) => (
                      <div key={`skeleton-${key}`} className="flex-1">
                        <SkeletonText />
                        <SkeletonText />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {Object.entries(entityStatistics)
                // FIXME Perf issue: Filtering in DOM
                .filter(([, { isAvailable }]) => isAvailable)
                .map(([key, { title }]) => (
                  <div key={key} className="flex-[2]">
                    <EntityGenericStatisticsBlock
                      title={title}
                      contents={[<SkeletonText key="skeleton" />]}
                    />
                  </div>
                ))}
            </div>
            <div className="flex-0 w-fit self-center">
              <Button
                isDisabled
                className="whitespace-nowrap"
                variant="secondary"
                text={t('organisation.reporting.page.entities.entity.openLink')}
                iconName="external"
                iconPosition="left"
              />
            </div>
          </div>
        </Panel>
      }
      fallback={(queryError) => (
        <QueryError
          queryError={queryError}
          componentType="Callout"
          translations={{}}
        />
      )}
    >
      {(entityDetails) => {
        if (!entityDetails.isWalletActive) {
          return null;
        }

        const isAvailableFundsLow = entityDetails.breakdown.trend === 'warning';

        setStatisticsIntoDefinitions(entityDetails);

        return (
          <Panel className="box-border w-full">
            <div className="flex gap-l">
              <div className="flex flex-auto flex-wrap gap-l">
                <div className="flex-[3]">
                  <EntityGenericStatisticsBlock
                    title={getBranchTypeTitle(entityDetails, t)}
                    contents={[
                      <div key="name" className="font-medium">
                        {entityDetails.name}
                      </div>,
                      <div key="country">
                        {getPrettyCountryFromCode(entityDetails.country, t)}
                      </div>,
                    ]}
                    stack
                  />
                </div>
                {features.wallet && (
                  <div className="flex-[3]">
                    <EntityGenericStatisticsBlock
                      stack
                      title={t(
                        'organisation.reporting.page.entities.entity.walletBalance',
                      )}
                      contents={[
                        <div key="walletBalance" className="font-medium">
                          {formatMonetaryValue({
                            amount: entityDetails.walletBalance,
                            currency: entityDetails.currency,
                            precision: 2,
                          })}
                        </div>,
                        isAvailableFundsLow && (
                          <div key="wallBalanceAddFunds">
                            <Link
                              to={routeFor(routes.COMPANY_BANK_DETAIL.path, {
                                company: entityDetails.id,
                              })}
                              target="_blank"
                            >
                              <Button
                                variant="ghost"
                                text={t(
                                  'organisation.reporting.page.entities.entity.walletBalanceAddFunds',
                                )}
                                iconName="caret-right"
                                iconPosition="right"
                              />
                            </Link>
                          </div>
                        ),
                      ].filter((component) => component)}
                    />
                  </div>
                )}
                {features.wallet && (
                  <div className="flex-[10]">
                    <EntityBreakdownBlock
                      currency={entityDetails.currency}
                      breakdown={entityDetails.breakdown}
                    />
                  </div>
                )}
                {Object.entries(entityStatistics)
                  // FIXME Perf issue: Filtering in DOM
                  .filter(([, { isAvailable }]) => isAvailable)
                  .map(([key, { title, link, label }]) => (
                    <div key={key} className="flex-[2]">
                      <EntityGenericStatisticsBlock
                        title={title}
                        contents={[
                          label && (
                            <Link key={`link-${key}`} to={link} target="_blank">
                              <Button
                                variant="ghost"
                                text={label}
                                iconName="caret-right"
                                iconPosition="right"
                              />
                            </Link>
                          ),
                        ]}
                      />
                    </div>
                  ))}
              </div>
              <div className="flex-0 w-fit self-center">
                <Link
                  to={routeFor(routes.HOMEPAGE.path, {
                    company: entityDetails.id,
                  })}
                  target="_blank"
                >
                  <Button
                    className="whitespace-nowrap"
                    variant="secondary"
                    text={t(
                      'organisation.reporting.page.entities.entity.openLink',
                    )}
                    iconName="external"
                    iconPosition="left"
                  />
                </Link>
              </div>
            </div>
          </Panel>
        );
      }}
    </QuerySuspense>
  );
};

const getBranchTypeTitle = (
  { type }: OrganisationReportingEntity,
  t: TFunction<'global', undefined>,
): string => {
  switch (type) {
    case 'branch_currency':
      return t(
        'organisation.reporting.page.entities.entity.type.branchCurrency',
      );
    case 'branch_expense_entity':
      return t(
        'organisation.reporting.page.entities.entity.type.expenseEntity',
      );
    default:
      return t('organisation.reporting.page.entities.entity.type.legalEntity');
  }
};

const getPrettyCountryFromCode = (
  countryCode: CountryCode,
  t: TFunction<'global', undefined>,
) => {
  const country = getCountryFromCode(countryCode);

  if (country) {
    return t(country.translationKey);
  }

  return countryCode;
};
