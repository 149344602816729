import { Avatar, Tag, Tooltip } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import React, { type ReactElement } from 'react';

import { EllipsisTooltip } from 'common/components/EllipsisTooltip';

type Props = {
  avatar?: string;
  name?: string;
  isHidden?: boolean;
  tooltip?: ReactElement;
};

export const MemberTag = ({
  avatar,
  name = '',
  isHidden = false,
  tooltip,
}: Props) => {
  const content = (
    <Tag variant="neutral" className="max-w-full cursor-default" isRounded>
      <Avatar className="mr-xxs -ml-xxs" size="xs" src={avatar} text={name} />
      <EllipsisTooltip text={name} />
    </Tag>
  );

  return (
    <div
      className={classNames('flex items-center gap-s', { hidden: isHidden })}
    >
      {tooltip ? (
        <Tooltip triggerAsChild content={tooltip}>
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};
