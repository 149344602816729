import {
  type RawExpense,
  type AnyExpense,
  ExpenseType,
  type MileageAllowanceExpense,
  type SupplierExpense,
  type ExpenseClaim,
  type PerDiemAllowanceExpense,
  type RawMileageAllowanceExpense,
  type RawSupplierExpense,
  type RawExpensePreview,
} from '../types';

export const isRawMileageAllowanceExpense = (
  rawExpense: RawExpense,
): rawExpense is RawMileageAllowanceExpense => {
  return rawExpense.type === ExpenseType.mileageAllowance;
};

export const isRawSupplierExpense = (
  rawExpense: RawExpense,
): rawExpense is RawSupplierExpense => {
  return (
    rawExpense.type === ExpenseType.cardPurchase ||
    rawExpense.type === ExpenseType.invoicePurchase
  );
};

const userCounterpartyExpenseTypes = [
  ExpenseType.expenseClaim,
  ExpenseType.mileageAllowance,
  ExpenseType.perDiemAllowance,
];
const supplierCounterpartyExpenseTypes = [
  ExpenseType.cardPurchase,
  ExpenseType.invoicePurchase,
];

export const isUserCounterpartyRawExpensePreview = (
  rawExpensePreview: RawExpensePreview,
): boolean => {
  return userCounterpartyExpenseTypes.includes(rawExpensePreview.type);
};

export const isSupplierCounterpartyRawExpensePreview = (
  rawExpensePreview: RawExpensePreview,
): boolean => {
  return supplierCounterpartyExpenseTypes.includes(rawExpensePreview.type);
};

export const hasRawExpensePreviewSupplier = (
  rawExpensePreview: RawExpensePreview,
): boolean => {
  return (
    rawExpensePreview.type !== ExpenseType.mileageAllowance &&
    rawExpensePreview.type !== ExpenseType.perDiemAllowance
  );
};

export const isUserCounterpartyExpense = (
  expense: AnyExpense,
): expense is
  | ExpenseClaim
  | PerDiemAllowanceExpense
  | MileageAllowanceExpense => {
  return userCounterpartyExpenseTypes.includes(expense.type);
};

export const isSupplierCounterpartyExpense = (
  expense: AnyExpense,
): expense is SupplierExpense => {
  return supplierCounterpartyExpenseTypes.includes(expense.type);
};
