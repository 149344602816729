import { getCompanyId } from './globalSelectorsTyped';

export const getUsers = (state) => state.users.users;

export const getUsersIsLoading = (state) => state.users.isLoadingUsers;

export const getSelf = (state) => state.global.user;

export const getUserApprovalData = (state) => state.profile.userApprovalData;

export const getUserSlackProviderSettings = (state) =>
  state.profile.slackProviderSettings;

export const getUserPhoneNumber = (state) => {
  const { user } = state.global;
  return user.mobile_no && !user.is_mobile_needed
    ? `+${user.mobile_ext}${user.mobile_no}`
    : '';
};

export const getUserActiveFactorPhoneNumber = (state) => {
  const { factor } = state.mfaActivation;
  return factor && factor.type === 'phone' && factor.params;
};

export const getFactorPhoneNumber = (state) => {
  if (
    state.mfaActivation.factor &&
    state.mfaActivation.factor.state === 'active'
  ) {
    return state.mfaActivation.factor.params;
  }
};

export const getPhoneFactor = (state) => state.mfaActivation.factor;

export const getIsMobileNumber = (state) => {
  const { user } = state.global;
  return user.is_mobile_needed;
};

export const getDefaultAccountHolderName = (state) => getSelf(state).fullname;

export const getUserLang = (state) => state.global.config.user.lang;

export const getUserId = (state) => getSelf(state).id;

export const isAccountOwner = (state) => getSelf(state).is_account_owner;

export const isOrganisationOwner = (state) =>
  getSelf(state).is_organisation_owner;

export const isController = (state) => getSelf(state).is_controller;

export const isAdmin = (state) => getSelf(state).is_admin;

export const getUserPaymentCount = (state, companyId) => {
  const user = getSelf(state);
  const userCompanyData = user.data_by_company[companyId];
  return userCompanyData?.nbPaymentsFromBeginning ?? 0;
};

export const getIsUserOrganisationOwner = (state) =>
  getSelf(state).is_organisation_owner;

export const getUserGroupIds = (state) => {
  const companyId = getCompanyId(state);
  const user = getSelf(state);
  const userCompanyData = user.data_by_company[companyId];

  return userCompanyData?.groups_ids ?? [];
};
