import React from 'react';

import { type TaxAccountUpdate } from 'modules/bookkeep/settings/accounting';
import {
  type NewTaxAccount,
  type TaxAccount,
} from 'modules/bookkeep/settings/accounting/taxAccount';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import {
  TaxAccountLocalOnlySection,
  TaxAccountLocalOnlyWithDefaultAccountsSection,
} from './TaxAccountLocalOnlySection';
import { TaxAccountPullAndSetSection } from './TaxAccountPullAndSetSection/TaxAccountPullAndSetSection';
import { TaxAccountPullSection } from './TaxAccountPullSection/TaxAccountPullSection';
import {
  type IntegrationStatusWithIntegration,
  type TaxAccountsCapability,
} from '../../../../../../integration/status';
import { useGetDefaultTaxAccountQuery } from '../../../../hooks/useGetDefaultTaxAccountQuery';
import { useSetDefaultTaxAccountMutation } from '../../../../hooks/useSetDefaultTaxAccountMutation';
import { useSetTaxAccountMutation } from '../../../../hooks/useSetTaxAccountMutation';
import { useTaxAccountsQuery } from '../../../../hooks/useTaxAccountsQuery';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  taxAccountsCapability: TaxAccountsCapability;
}

export const TaxAccountsSection = ({
  integrationStatus,
  taxAccountsCapability,
}: Props) => {
  const taxAccountQueryState = useTaxAccountsQuery();
  const getDefaultTaxAccountQueryState = useGetDefaultTaxAccountQuery({
    isEnabled: taxAccountsCapability === 'localOnlyWithDefaultAccounts',
  });
  const [setDefaultTaxAccount, setDefaultTaxAccountQueryState] =
    useSetDefaultTaxAccountMutation();
  const [setTaxAccount, setTaxAccountQueryState, clearErrors] =
    useSetTaxAccountMutation();

  const handleChangeDefaultTaxAccount = async (
    taxAccount: TaxAccountUpdate,
  ) => {
    // eslint-disable-next-line @spendesk/must-use-result
    await setDefaultTaxAccount(taxAccount);
  };

  const handleAddTaxAccount = async (taxAccount: NewTaxAccount) => {
    return setTaxAccount({
      ...taxAccount,
      isArchived: false,
    });
  };

  const handleDeleteTaxAccount = async (taxAccount: TaxAccount) => {
    // eslint-disable-next-line @spendesk/must-use-result
    setTaxAccount({
      ...taxAccount,
      isArchived: true,
    });
  };

  switch (taxAccountsCapability) {
    case 'pullAndSet':
      return (
        <TaxAccountPullAndSetSection
          taxAccountQueryState={taxAccountQueryState}
          setTaxAccountQueryState={setTaxAccountQueryState}
          integration={integrationStatus.integration}
          clearErrors={clearErrors}
          onDelete={handleDeleteTaxAccount}
          onAdd={handleAddTaxAccount}
        />
      );
    case 'pull': {
      return (
        <TaxAccountPullSection
          taxAccountQueryState={taxAccountQueryState}
          integration={integrationStatus.integration}
        />
      );
    }
    case 'localOnlyWithDefaultAccounts':
      return (
        <TaxAccountLocalOnlyWithDefaultAccountsSection
          setTaxAccountQueryState={setTaxAccountQueryState}
          clearErrors={clearErrors}
          onDelete={handleDeleteTaxAccount}
          onAdd={handleAddTaxAccount}
          taxAccountQueryState={taxAccountQueryState}
          integrationStatus={integrationStatus}
          getDefaultTaxAccountQueryState={getDefaultTaxAccountQueryState}
          onDefaultChange={handleChangeDefaultTaxAccount}
          setDefaultTaxAccountQueryState={setDefaultTaxAccountQueryState}
        />
      );

    case 'localOnly':
      return (
        <TaxAccountLocalOnlySection
          setTaxAccountQueryState={setTaxAccountQueryState}
          clearErrors={clearErrors}
          onDelete={handleDeleteTaxAccount}
          onAdd={handleAddTaxAccount}
          taxAccountQueryState={taxAccountQueryState}
          integrationStatus={integrationStatus}
        />
      );
    case 'push':
      return <>TO DO: push</>;
    default:
      rejectUnexpectedValue('taxAccountsCapability', taxAccountsCapability);
  }
};
