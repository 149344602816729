import { z } from 'zod';

import { addressSchema } from './address';
import { mobileSchema } from './mobile';

export const shippingAddressSchema = z
  .object({
    kind: z.enum(['user', 'company']),
  })
  .merge(addressSchema);

export const shippingAddressWithPhoneNumberSchema = z
  .object({
    kind: z.enum(['user', 'company']),
  })
  .merge(addressSchema)
  .merge(mobileSchema);
