import {
  FormField,
  DatePicker,
  Button,
  IconButton,
} from '@dev-spendesk/grapes';
import { addMinutes, subMinutes } from 'date-fns';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { usePayableCounts } from 'modules/bookkeep/graphql/hooks';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BookkeepingStartDateConfirmationModal } from './BookkeepingStartDateConfirmationModal';
import { useSetBookkeepingStartDateMutation } from '../../../../hooks/useSetBookkeepingStartDateMutation';

import './BookkeepingStartDateSection.css';

export type Props = {
  bookkeepingStartDate?: Date;
  redirectTo?: string;
};

export function BookkeepingStartDateSection({
  bookkeepingStartDate,
  redirectTo,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [date, setDate] = useState(bookkeepingStartDate);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [setBookkeepingStartDate] = useSetBookkeepingStartDateMutation();

  const onConfirm = async () => {
    await setBookkeepingStartDate({
      payablesBookkeepingStartDate: localeDateToUTC(date),
    });
    setIsModalOpen(false);
    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  const payableCountsQueryResult = usePayableCounts({ to: date });

  return (
    <>
      <div className="box">
        <FormField label={t('accounting.bookkeepingStartDate.label')}>
          <div className="BookkeepingStartDateSection__input">
            <DatePicker
              fit="content"
              placement="bottom-start"
              value={date}
              onChange={(newDate) => {
                setDate(newDate || undefined);
              }}
              placeholder={t('accounting.bookkeepingStartDate.placeholder')}
            />
            {date && (
              <IconButton
                iconName="cross"
                variant="borderless"
                onClick={() => {
                  setDate(undefined);
                }}
                aria-label={t('misc.delete')}
              />
            )}
          </div>
        </FormField>
        <Button
          className="BookkeepingStartDateSection__cta"
          isDisabled={
            bookkeepingStartDate?.toISOString() === date?.toISOString()
          }
          text={t('accounting.bookkeepingStartDate.save')}
          variant="primary"
          onClick={async () => {
            if (!date) {
              await onConfirm();
              return;
            }
            setIsModalOpen(true);
          }}
        />
      </div>
      <BookkeepingStartDateConfirmationModal
        bookkeepingStartDate={date}
        hiddenPayablesCount={payableCountsQueryResult.data?.toPrepareCount}
        isOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={onConfirm}
      />
    </>
  );
}

// Will transform locale "2021-03-30T22:00:00.000Z"
// into UTC "2021-03-31T00:00:00.000Z"
// We need this transformation because we receive the locale date
// from the user input, but we need to send it to the server
// in a standardize way (UTC)
function localeDateToUTC(date: Date | undefined): Date | undefined {
  if (!date) {
    return;
  }
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) !== -1
    ? subMinutes(date, Math.abs(offset))
    : addMinutes(date, Math.abs(offset));
}
