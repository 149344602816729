import { useQueryClient } from '@tanstack/react-query';
import { type ClientInferResponseBody } from '@ts-rest/core';

import { useCompanyId } from 'src/sfs-migration/common/hooks/useCompany';
import { sfsMigrationQueryClient } from 'src/sfs-migration/common/utils/sfsMigrationQueryClient';
import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

const employeeCardQueryKey = (companyId: string) => [
  'employee-sfs-card',
  { companyId },
];

export type EmployeeSFSCard = ClientInferResponseBody<
  typeof sfsMigrationContract.cards.getEmployeeSfsCard,
  200
>['data'];

export const useEmployeeSfsCardQuery = () => {
  const companyId = useCompanyId();
  const { data, isLoading, error } =
    sfsMigrationQueryClient.cards.getEmployeeSfsCard.useQuery(
      employeeCardQueryKey(companyId),
      {
        params: { companyId },
      },
    );
  if (isLoading || error) {
    return undefined;
  }
  const { status, body } = data;
  if (status === 200) {
    return body.data;
  }
  return undefined;
};

export const useInvalidateEmployeeSfsCardQuery = () => {
  const queryClient = useQueryClient();
  const companyId = useCompanyId();
  return async () => {
    await queryClient.refetchQueries(employeeCardQueryKey(companyId), {
      exact: true,
    });
  };
};

export const getPhysicalCardActivationStatus = (
  response?: EmployeeSFSCard,
): {
  status: 'noCard' | 'todo' | 'completed';
} => {
  if (!response) {
    return { status: 'noCard' };
  }
  if (response.card?.cardStatus === 'PRE') {
    return { status: 'todo' };
  }

  return { status: 'completed' };
};
