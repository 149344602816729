import { combineReducers } from '@reduxjs/toolkit';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';
import * as groupsTypes from 'src/core/actionTypes/groups';

import suppliersReducer from './Suppliers/reducer';

const groupsInitialState = null;
const groupsReducer = (state = groupsInitialState, action) => {
  switch (action.type) {
    case groupsTypes.FETCH_GROUPS_SUCCESS:
      return action.payload.groups;
    default:
      return state;
  }
};

const customFieldsInitialState = null;
const customFieldsReducer = (state = customFieldsInitialState, action) => {
  switch (action.type) {
    case customFieldsTypes.FETCH_CF_SUCCESS:
      return action.payload.customFields;
    default:
      return state;
  }
};

export default combineReducers({
  groups: groupsReducer,
  customFields: customFieldsReducer,
  suppliers: suppliersReducer,
});
