import { isValidNumber } from 'libphonenumber-js';
import { z } from 'zod';

import { mobileSchemaNullable } from './types/mobile';
import { recardOrderSchema } from './types/recard-order';
import { shippingAddressSchema } from './types/shipping-address';

const putShippingAddressSchemaInput = z.discriminatedUnion('kind', [
  z.object({
    kind: z.literal('company'),
    phoneNumber: z
      .string()
      .nullish()
      .refine((val) => (!val ? true : isValidNumber(val)), {
        message: 'InvalidPhoneNumber',
      }),
  }),
  z
    .object({
      kind: z.literal('user'),
    })
    .merge(
      shippingAddressSchema.omit({ kind: true }).extend({
        phoneNumber: z
          .string()
          .nullable()
          .refine((val) => (val === null ? true : isValidNumber(val)), {
            message: 'InvalidPhoneNumber',
          }),
      }),
    ),
]);
const putShippingAddressSchemaOutput = z.discriminatedUnion('kind', [
  z.object({
    kind: z.literal('company'),
  }),
  z
    .object({
      kind: z.literal('user'),
    })
    .merge(
      shippingAddressSchema.omit({ kind: true }).merge(mobileSchemaNullable),
    ),
]);

const putRecardOrderSchema = recardOrderSchema
  .omit({
    id: true,
    shippingAddress: true,
    sfsCardId: true,
    trackingLink: true,
    trackingNumber: true,
  })
  .extend({ shippingAddress: putShippingAddressSchemaInput.nullable() });

export const putRecardOrdersSchema = z.object({
  recardOrders: z.array(putRecardOrderSchema),
});

export const putRecardOrdersSchemaResponse = z.object({
  recardOrders: z.array(
    putRecardOrderSchema.extend({
      shippingAddress: putShippingAddressSchemaOutput.nullable(),
    }),
  ),
});
