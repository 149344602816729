import { type MonetaryValue } from 'ezmoney';
import React, { useState, useEffect } from 'react';

import { ScaWireTransferConfirmationModalContainer } from 'modules/StrongCustomerAuthentication';
import { type ScaState } from 'modules/StrongCustomerAuthentication/types';
import { ModalMFAToken } from 'src/core/common/components/ModalMFA';
import { ScaWireTransferConfirmationEnrollmentModal } from 'src/core/modules/StrongCustomerAuthentication/components/ScaWireTransferConfirmationEnrollmentModal';
import { type AuthType } from 'src/core/modules/invoices/transfer';

import { WireTransfersApprovedModal } from './WireTransfersApprovedModal';
import { WireTransfersDeclinedModal } from './WireTransfersDeclinedModal';
import { WireTransfersNotEnoughFundsModal } from './WireTransfersNotEnoughFundsModal';
import { WireTransfersSummaryModal } from './WireTransfersSummaryModal';

enum ApproverModalStep {
  NotEnoughFunds,
  Summary,
  MfaToken,
  Confirmation,
  Sca,
  Declined,
  ScaEnrollment,
}

type Props = {
  amount: MonetaryValue;
  count: number;
  isApproving: boolean;
  showNotEnoughFundsWarning: boolean;
  isOpen: boolean;
  approvalAuthType?: AuthType | null;
  onClose: () => void;
  onApprove: () => void;
  onComplete: () => void;
};

const missingSCAErrors = [
  'accountOwnerIsMissingMfaPhoneFactor',
  'unableToCreateScaProcedure',
];

const WireTransferApproverModal = ({
  amount,
  count,
  isApproving,
  showNotEnoughFundsWarning,
  isOpen,
  approvalAuthType,
  onClose,
  onApprove,
  onComplete,
}: Props) => {
  const [currentStep, setCurrentStep] = useState<ApproverModalStep>(
    ApproverModalStep.Summary,
  );

  useEffect(() => {
    if (showNotEnoughFundsWarning) {
      setCurrentStep(ApproverModalStep.NotEnoughFunds);
    }
    if (
      !showNotEnoughFundsWarning &&
      currentStep === ApproverModalStep.NotEnoughFunds
    ) {
      setCurrentStep(ApproverModalStep.Summary);
    }
  }, [showNotEnoughFundsWarning, isOpen]);

  const handleContinue = () => {
    setCurrentStep(ApproverModalStep.Summary);
  };

  useEffect(() => {
    if (approvalAuthType === 'sca') {
      setCurrentStep(ApproverModalStep.Sca);
    } else if (approvalAuthType === 'mfa') {
      setCurrentStep(ApproverModalStep.MfaToken);
    }
  }, [approvalAuthType]);

  const handleApprove = async () => {
    try {
      await onApprove();
    } catch (error) {
      if (
        missingSCAErrors.includes(error.response?.data?.reason) ||
        missingSCAErrors.includes(error.data?.reason)
      ) {
        setCurrentStep(ApproverModalStep.ScaEnrollment);
      }
    }
  };

  const handleMfaTokenValidated = () => {
    setCurrentStep(ApproverModalStep.Confirmation);
  };

  const handleScaProceed = (state: ScaState): void => {
    if (state === 'approved') {
      setCurrentStep(ApproverModalStep.Confirmation);
    } else if (['rejected', 'timeout'].includes(state)) {
      setCurrentStep(ApproverModalStep.Declined);
    }
  };

  const handleComplete = () => {
    handleClose();
    onComplete();
  };

  const handleClose = () => {
    setCurrentStep(ApproverModalStep.Summary);
    onClose();
  };

  const renderScaModal = () => {
    const isScaModalOpen = isOpen && currentStep === ApproverModalStep.Sca;
    return (
      <ScaWireTransferConfirmationModalContainer
        isOpen={isScaModalOpen}
        onUseAlternateMethod={handleApprove}
        onClose={handleClose}
        onScaProceed={handleScaProceed}
      />
    );
  };

  const renderWireTransfersDeclinedModal = () => {
    const isWireTransfersDeclinedModalOpen =
      isOpen && currentStep === ApproverModalStep.Declined;

    return (
      <WireTransfersDeclinedModal
        isOpen={isWireTransfersDeclinedModalOpen}
        onClose={handleClose}
      />
    );
  };

  return (
    <>
      <WireTransfersNotEnoughFundsModal
        count={count}
        onContinue={handleContinue}
        isOpen={isOpen && currentStep === ApproverModalStep.NotEnoughFunds}
      />
      <WireTransfersSummaryModal
        amount={amount}
        count={count}
        isApproving={isApproving}
        canApprove
        showNotEnoughFundsWarning={showNotEnoughFundsWarning}
        onApprove={handleApprove}
        onClose={handleClose}
        isOpen={isOpen && currentStep === ApproverModalStep.Summary}
      />
      <ModalMFAToken
        onMfaTokenValidated={handleMfaTokenValidated}
        onClose={handleClose}
        isOpen={isOpen && currentStep === ApproverModalStep.MfaToken}
        flow="standard"
      />
      {renderScaModal()}
      {renderWireTransfersDeclinedModal()}
      <WireTransfersApprovedModal
        count={count}
        onComplete={handleComplete}
        isOpen={isOpen && currentStep === ApproverModalStep.Confirmation}
      />
      <ScaWireTransferConfirmationEnrollmentModal
        isOpen={isOpen && currentStep === ApproverModalStep.ScaEnrollment}
        onClose={() => {
          handleClose();
        }}
        onEnroll={onApprove}
      />
    </>
  );
};

export default WireTransferApproverModal;
