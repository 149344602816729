import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type CustomFieldDefinition } from '../models';

type CustomFieldsRequestError = {
  errorCode: string;
};

type CustomFieldsRequestResponse = CustomFieldDefinition[];

export const useCustomFieldsQuery = (): QueryState<
  CustomFieldsRequestResponse,
  CustomFieldsRequestError
> => {
  return useQuery<CustomFieldsRequestResponse>({
    key: `custom-fields`,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/custom-fields`,
    },
    reshapeData: (data) => data,
  });
};
