import * as Money from 'ezmoney';

import { type MigrationStatus } from '@sfs-migration/common/models/migration-status';
import { WalletSummary, WalletTransfer } from '@sfs-migration/wallet/models';
import { type CurrenciesKey } from 'src/core/config/money';

export type Account = {
  id: string;
  companyId: string;
  currency: CurrenciesKey;
  version: string;
  bankingProvider: 'sfs' | 'bankable';
} & BankInfo;

export const sfsUkExecutionUnlockDate = new Date('2024-06-25');

export type SfsLoad = {
  amount: number;
  currency: string;
  entry_date_time: string;
  load_type: string;
  pending: boolean;
  userName: string;
  balanceAfter: number | null;
};

export type BankInfo = UkBankInfo | EuBankInfo;

export type UkBankInfo = {
  sortCode: string;
  accountNumber: string;
  holderName: string;
};

export type EuBankInfo = {
  iban: string;
  bic: string;
  holderName: string;
};

type BankingProvider = 'sfs' | 'bankable';

export type FundingMethod =
  | 'wire-transfer-from-legacy-wallet'
  | 'sepa-transfer';

export type LegacyWalletFundingMethod = 'card' | 'sepa-transfer';

export const SEPATransfer = 'SEPA';
export const FPSTransfer = 'FPS';
export type TransferType = typeof SEPATransfer | typeof FPSTransfer;

export const isSfsAccountFunded = (migrationStatus: MigrationStatus) => {
  return ['InProgress', 'Done'].includes(migrationStatus);
};

const isAccountOnlyCompatibleWithUkBankInfo = (account: Account): boolean => {
  return account.bankingProvider === 'sfs' && account.currency === 'GBP';
};

export const isEuAccount = (
  account: Account,
): account is Account & EuBankInfo => {
  return (
    !isAccountOnlyCompatibleWithUkBankInfo(account) &&
    !!('iban' in account && account.iban && 'bic' in account && account.bic)
  );
};

export const isUkAccount = (
  account: Account,
): account is Account & UkBankInfo => {
  return (
    isAccountOnlyCompatibleWithUkBankInfo(account) ||
    !!(
      'sortCode' in account &&
      account.sortCode &&
      'accountNumber' in account &&
      account.accountNumber
    )
  );
};
export const getTransferType = (account: Account): TransferType => {
  if (isUkAccount(account)) {
    return FPSTransfer;
  }

  return SEPATransfer;
};

export const getMainAccountBankingProvider = (
  migrationStatus: MigrationStatus,
): BankingProvider => {
  return ['InProgress', 'Done'].includes(migrationStatus) ? 'sfs' : 'bankable';
};

type ParamsAfterSfsAccountIsMigrated = {
  legacyAccountWalletSummary: WalletSummary.DetailedSummary;
  isSfsAccountMigrated: true;
};

type ParamsBeforeSfsAccountIsMigrated = {
  legacyAccountWalletSummary: WalletSummary.DetailedSummary;
  amountAllocatedForWireTransfers: Money.MonetaryValue;
  todaysScheduledTransfersAmount: Money.MonetaryValue;
  isSfsAccountMigrated: false;
};

export type Params =
  | ParamsAfterSfsAccountIsMigrated
  | ParamsBeforeSfsAccountIsMigrated;

type ComputedAmounts = {
  recommendedAmountToTransfer: Money.MonetaryValue;
  requiredAmountOnLegacyWallet: Money.MonetaryValue;
  amountAllocatedOnSubscriptionCards: Money.MonetaryValue;
  amountAllocatedOnPhysicalCards: Money.MonetaryValue;
  amountAllocatedForSinglePurchaseCards: Money.MonetaryValue;
  moneyAvailableToBeTransferred: Money.MonetaryValue;
  amountAllocatedOnLegacyWallet: {
    totalAmountAllocatedOnCards: Money.MonetaryValue;
    amountAllocatedForWireTransfers?: Money.MonetaryValue;
  };
} | null;

export const computeRecommendedAmountToTransferFromLegacyAccount = ({
  legacyAccountWalletSummary,
  ...params
}: Params): ComputedAmounts => {
  const {
    totalAmountAllocatedOnCards,
    amountAllocatedOnSubscriptionCards,
    amountAllocatedOnPhysicalCards,
    amountAllocatedForSinglePurchaseCards,
    totalAmount: legacyWalletTotalAmount,
  } = WalletSummary.getWalletDetailsBreakdown(legacyAccountWalletSummary);
  const currency = legacyWalletTotalAmount.currency as CurrenciesKey;

  const requiredAmountOnLegacyWallet = params.isSfsAccountMigrated
    ? totalAmountAllocatedOnCards
    : Money.add(
        params.amountAllocatedForWireTransfers,
        totalAmountAllocatedOnCards,
      );

  const moneyAvailableToBeTransferred = Money.subtract(
    legacyWalletTotalAmount,
    requiredAmountOnLegacyWallet,
  );

  const transferLimit = WalletTransfer.getTransferLimit(currency);

  const maximumAmountToBeTransferred =
    params.isSfsAccountMigrated || !params.todaysScheduledTransfersAmount
      ? transferLimit
      : Money.subtract(transferLimit, params.todaysScheduledTransfersAmount);

  const recommendedAmountToTransfer =
    Money.compare(moneyAvailableToBeTransferred, maximumAmountToBeTransferred) <
    0
      ? moneyAvailableToBeTransferred
      : maximumAmountToBeTransferred;

  return {
    recommendedAmountToTransfer,
    requiredAmountOnLegacyWallet,
    amountAllocatedOnSubscriptionCards,
    amountAllocatedOnPhysicalCards,
    amountAllocatedForSinglePurchaseCards,
    moneyAvailableToBeTransferred,
    amountAllocatedOnLegacyWallet: {
      totalAmountAllocatedOnCards,
      amountAllocatedForWireTransfers: params.isSfsAccountMigrated
        ? undefined
        : params.amountAllocatedForWireTransfers,
    },
  };
};
