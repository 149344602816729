import { useQuery } from 'src/core/api/hooks/useQuery';

import { type DetailedSummary, type Summary } from '../../models/summary';

export const useSecondaryAccountWalletSummary = ({
  isEnabled,
}: {
  isEnabled: boolean;
}) => {
  return useQuery<Summary | DetailedSummary>({
    key: ['sfs-migration', 'secondary-account', 'wallet-summary'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/sfs-migration/secondary-account/wallet-summary`,
    },
  });
};
