import { Icon, type IconName } from '@dev-spendesk/grapes';
import React from 'react';

import './HomepageWalletDetailSecondaryAmount.scss';

type Props = {
  formatedAmount: string;
  formatedAmountDescription: string;
  isAccountLoaded: boolean;
  iconName: IconName;
};

export const HomepageWalletDetailSecondaryAmount = ({
  formatedAmount,
  formatedAmountDescription,
  isAccountLoaded,
  iconName,
}: Props) => {
  return (
    <div className="HomepageWalletDetailSecondaryAmount">
      <div className="flex h-l w-l items-center justify-center rounded-[1000px] border border-solid border-neutral-light bg-neutral-lightest">
        <Icon className="text-neutral-dark" name={iconName} size="m" />
      </div>
      <div className="mr-xxs">
        {!isAccountLoaded ? (
          <div className="flex flex-col gap-xxs">
            <div className="h-[22px] w-[100px] rounded-xs bg-neutral-lightest" />
            <div className="h-[18px] w-[120px] rounded-xs bg-neutral-lightest" />
          </div>
        ) : (
          <div className="flex gap-xs">
            <div>
              <h3 className="leading-[26px] text-neutral-darker body-xl">
                {formatedAmount}
              </h3>
              <p className="leading-[18px] text-neutral-dark body-m">
                {formatedAmountDescription}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
