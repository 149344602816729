/**
 * Assign to window.version an object with two properties:
 * - sha: contains the sha of the last deployed commit
 * - version: contains the application version. 0.0.0 stands for development version
 */
export function assignVersionToWindow(): void {
  if (process.env.REACT_APP_SHA) {
    window.version = {
      sha: process.env.REACT_APP_SHA,
    };
  }
}
