import { Navigation } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

import {
  NavigationLink,
  type NavigationLinkProps,
} from 'src/core/common/components/NavigationLink';

type HeaderWithNavigationProps = {
  children: ReactNode;
  links: NavigationLinkProps[];
  navigationLabel?: string;
};
export const HeaderWithNavigation = ({
  children,
  links,
  navigationLabel,
}: HeaderWithNavigationProps) => {
  return (
    <header className="sticky top-0 z-10 bg-white">
      <h1 className="px-m py-xxs text-complementary title-xl">{children}</h1>

      <Navigation
        variant="subNavigation"
        aria-label={navigationLabel}
        className="shrink-0"
        leftNavigationItems={links.map((link) => (
          <NavigationLink {...link} key={link.key} />
        ))}
      />
    </header>
  );
};
