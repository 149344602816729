import { useFormik, type FormikErrors } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { JournalCodeColumnEditModal } from './JournalCodeColumnEditModal';
import { type JournalCodeFormValues } from './form';

type Props = {
  isOpen: boolean;
  title: string;
  cardExpense: string;
  invoice: string;
  expenseClaim: string;
  subscription: string;
  description: string;
  isDeletable: boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
  onSaveColumn: (values: JournalCodeFormValues) => void;
};

export const JournalCodeColumnEditModalContainer = ({
  title,
  onSaveColumn,
  cardExpense,
  invoice,
  expenseClaim,
  subscription,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<JournalCodeFormValues>({
    initialValues: {
      title,
      cardExpense,
      invoice,
      expenseClaim,
      subscription,
    },
    validate: (values) => {
      const errors: FormikErrors<JournalCodeFormValues> = {};

      if (!values.title.trim()) {
        errors.title = t('exports.emptyColumnTitleError');
      }

      if (!values.cardExpense?.trim()) {
        errors.cardExpense = t(
          'bookkeep.settings.export.journalCodeOptions.emptyError',
        );
      }

      if (!values.invoice?.trim()) {
        errors.invoice = t(
          'bookkeep.settings.export.journalCodeOptions.emptyError',
        );
      }

      if (!values.expenseClaim?.trim()) {
        errors.expenseClaim = t(
          'bookkeep.settings.export.journalCodeOptions.emptyError',
        );
      }

      if (!values.subscription?.trim()) {
        errors.subscription = t(
          'bookkeep.settings.export.journalCodeOptions.emptyError',
        );
      }

      return errors;
    },
    onSubmit: (values) => {
      onSaveColumn({
        title: values.title,
        cardExpense: values.cardExpense,
        invoice: values.invoice,
        expenseClaim: values.expenseClaim,
        subscription: values.subscription,
      });
    },
  });

  return (
    <JournalCodeColumnEditModal title={title} {...rest} {...formikProps} />
  );
};
