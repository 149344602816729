import { type QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { type InvoicesCounts } from '../models';

type Params = {
  isEnabled?: boolean;
};

export const useFetchInvoicesCounts = ({ isEnabled = true }: Params = {}) => {
  return useQuery<InvoicesCounts, InvoicesCounts>({
    key: ['transfer-scheduling', 'counts'],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/counts`,
    },
    reshapeData: (data) => data,
    isEnabled,
  });
};

export const invalidateInvoicesCounts = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries(['transfer-scheduling', 'counts']);
};
