import React from 'react';

import { ProgressBox } from 'common/components/ProgressBox';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import {
  ProgressBoxAppraisalsList,
  ProgressBoxApproversList,
} from 'modules/approval';

import { type PurchaseOrderRequestDetails } from '../../../..';

type Props = {
  className?: string;
  request: PurchaseOrderRequestDetails;
};

export const PurchaseOrderRequestOngoingProgressBox = ({
  className,
  request,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <ProgressBox
      className={className}
      title={getTitleFromRequest(request, t)}
      iconName={request.state === 'approved' ? 'success' : 'clock-full'}
      content={getContentFromRequest(request, t)}
      variant={request.state === 'approved' ? 'success' : 'neutral'}
    />
  );
};

const getTitleFromRequest = (
  request: PurchaseOrderRequestDetails,
  t: TGlobalFunctionTyped,
) => {
  if (request.state === 'approved') {
    return t('requests.progressBox.approvedTitle');
  }
  if (request.approval?.directApprover) {
    return t('requests.progressBox.approvalStep.titleApprover');
  }
  return t('requests.progressBox.approvalStep.title');
};

const getContentFromRequest = (
  request: PurchaseOrderRequestDetails,
  t: TGlobalFunctionTyped,
) => {
  if (request.approval?.autoApproved) {
    return t('requests.progressBox.approvalStep.autoApprovedContent');
  }
  if (request.state === 'approved') {
    return (
      <>
        {t('requests.progressBox.approvalStep.contentAppraisal')}
        <ProgressBoxAppraisalsList
          hasNegativeMarginButton={false}
          requestId={request.id}
        />
      </>
    );
  }
  return (
    <ProgressBoxApproversList
      hasNegativeMarginButton={false}
      requestId={request.id}
    />
  );
};
