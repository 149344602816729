import { colors, Icon } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './WarningRow.module.css';

type Props = {
  text: string;
};

export const WarningRow = ({ text }: Props) => {
  return (
    <div className={`${styles.WarningRow} shadow-10`}>
      <Icon name="warning" color={colors.warning} />

      <div className="body-m ">{text}</div>
    </div>
  );
};
