import { useSelector } from 'react-redux';

import type { Language } from 'src/core/config/i18n';
import { type CurrenciesKey } from 'src/core/config/money';
import { getUser } from 'src/core/selectors/globalSelectors';

import { type Company } from './useCompany';

export type User = {
  // from API
  id: string;
  first_name: string;
  last_name: string;
  fullname: string;
  lang: Language;
  email: string;
  avatar: string | null;
  display_gender: string;
  display_name: string;
  gender: string;
  birth_date: string | null;
  birth_location_city: string | null;
  birth_location_country_code: string | null;
  is_requester: boolean;
  is_admin: boolean;
  is_controller: boolean;
  is_account_owner: boolean;
  is_organisation_owner: boolean;
  is_approver: boolean;
  is_delegating: boolean;
  has_plastic_card: boolean;
  has_device: boolean;
  mail_checked: boolean;
  intercom_hash: string;
  company_ids: string[];
  costCenter: null | {
    id: string;
    name: string;
  };
  cost_center_id?: string;
  data_by_company: {
    [companyId: string]: Company & {
      plastic_card: {
        id: string;
        banking_provider: 'marqeta' | 'sfs' | 'bankable';
        currency: CurrenciesKey;
      };
      groups_ids: string[];
      is_account_owner?: boolean;
      is_admin?: boolean;
      is_controller?: boolean;
      is_requester?: boolean;
    };
  };
  bankInfo?: {
    accountHolderName?: string;
    iban?: string;
    bic?: string;
    cuc?: string;
    sortCode?: string;
    accountNumber?: string;
    routingNumber?: string;
  };
  groups_admins: string[];
  // from enrichUser (src/core/utils/entities/user.js)
  is_group_admin: boolean;
  has_slack_enabled: boolean;
  has_slack_linked: boolean;
  notification_provider_users: {
    id: string;
    company_id: string;
    user_id: string;
    provider_user_id: string;
  }[];
  created_at: string;
  mobile_ext?: string;
  mobile_no?: string;
};

export const useUser = (): User => useSelector(getUser);
