import { type ClientInferResponseBody } from '@ts-rest/core';

import { type sfsMigrationContract } from 'src/ts-rest-contract/src';

import { useQuery } from '../../../core/api/hooks/useQuery';

export type TransfersFromLegacyWalletResponse = ClientInferResponseBody<
  typeof sfsMigrationContract.wireTransfers.getTransfersFromBankableToSfs,
  200
>;

export const useTransfersFromLegacyWalletQuery = ({
  isEnabled,
}: {
  isEnabled: boolean;
}) => {
  return useQuery<
    TransfersFromLegacyWalletResponse,
    { error: 'Unable to fetch Bankable to SFS transfers' }
  >({
    key: ['sfs-migration', 'transfers-from-bankable-to-sfs-account'],
    isEnabled,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/sfs-migration/transfers-from-bankable-to-sfs-account`,
    },
  });
};
