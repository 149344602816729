import reject from 'lodash/reject';
import size from 'lodash/size';
import some from 'lodash/some';

import { smartMerge } from './array';

export const mergeRates = (existingRates, newRate) => {
  return reject(
    smartMerge(existingRates, [newRate], 'rate'),
    (vat) => Number.parseFloat(vat.rate) === 0,
  );
};

export const removeRate = (existingRates, newRate) => {
  return reject(
    existingRates,
    (vat) => Number.parseFloat(vat.rate) === Number.parseFloat(newRate.rate),
  );
};

export const isPrediction = (rates) => {
  return size(rates) > 0 && !some(rates, (rate) => !rate.prediction_id);
};

export const isVatEnabled = (country) => country !== 'US';
