import { z } from 'zod';

export const getTransfersFromBankableToSfsSchema = z.object({
  transfers: z.array(
    z.object({
      id: z.string(),
      amount: z.number(),
      status: z.union([
        z.literal('pendingOrder'),
        z.literal('pendingReconciliation'),
        z.literal('orderFailed'),
        z.literal('reconciled'),
      ]),
      currency: z.string(),
      createdAt: z.date(),
      isAutomatic: z.boolean(),
    }),
  ),
});
