import { Callout, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

export const ProgressBoxLoadingSkeleton = () => (
  <Callout
    title={<SkeletonText width="85px" />}
    variant="neutral"
    className="justify-starts my-m flex flex-col gap-s"
    iconName="clock-full"
  >
    <SkeletonText width="200px" />
    <SkeletonText width="125px" />
  </Callout>
);
