import { type Dispatch } from '@reduxjs/toolkit';

import { getSelectedCompanyId } from 'modules/company';
import { baseAPI } from 'src/core/api/axios';

import * as requestActions from './actions';
import { toCamelCase, toSnakeCase } from './models';
import { type AppState } from '../../../reducers';
import { type LegacyRequest, type RequestAction } from '../types';

export const update = (request: LegacyRequest) => {
  return async (
    dispatch: Dispatch<RequestAction>,
    getState: () => AppState,
  ) => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);

    const requestWithExpenseCategory = {
      ...request,
      customFields: [
        ...request.customFields,
        {
          customFieldId: state.requests.expenseCategoryCustomFieldId,
          customFieldValueId: request.expenseCategory?.id,
          value: request.expenseCategory?.value,
        },
      ],
    };
    dispatch(requestActions.updateRequest());
    try {
      const { data } = await baseAPI.put(
        `/${companyId}/requests/${request.id}`,
        { ...toSnakeCase(requestWithExpenseCategory) },
      );
      dispatch(requestActions.updateSuccess(toCamelCase(data)));
    } catch (error) {
      dispatch(requestActions.updateFailure(error.response.data));
    }
  };
};
