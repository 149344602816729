import { z } from 'zod';

/**
 * This flags definition is ordered by priority.
 */
export const dofileCaseFlagSchema = z.enum([
  'for_recollection',
  'for_first_collect',
  'for_review',
  'all_checks_approved',
]);

export const dotfileCaseSchema = z.object({
  status: z.enum(['open', 'approved', 'rejected']),
  /**
   * Flags are ordered by priority in the array, the first one can be used
   * as the most important one.
   * Consider the array maybe empty.
   */
  flags: dofileCaseFlagSchema.array(),
  contactHasActions: z.boolean(),
  onboardingUrl: z.string(),
  businessType: z.enum(['association', 'company']).nullable(),
  companiesWithApprovedAOIds: z.array(z.string()),
  reuseAOIdStatus: z.enum(['reused_ok', 'reuse_failed']).optional(),
  companyReuseStatus: z
    .enum(['declined', 'not_requested', 'approved'])
    .optional(),
});

export const taskSchema = z.object({
  type: z.enum([
    'ReviewKyc',
    'ValidateCardOrder',
    'AcceptTermsAndConditions',
    'FundWallet',
    'AddBankAccountCode',
    'SubscriptionRecard',
    'PhysicalCardsActivation',
  ]),
  status: z.enum(['Completed', 'InProgress', 'ToDo', 'Blocked', 'Skipped']),
  completedAt: z.date().nullable(),
  deadline: z.date().nullable(),
  completedCount: z.number().optional(),
  totalCount: z.number().optional(),
  dotfile: dotfileCaseSchema.optional(),
});

export const stepSchema = z.object({
  startDate: z.date().nullable(),
  deadline: z.date().nullable(),
  status: z.enum(['Completed', 'InProgress', 'ToDo']),
  tasks: z.array(taskSchema),
});

export const stepInitationSchema = stepSchema.extend({
  tasks: z.array(
    taskSchema.extend({
      type: z.enum([
        'ReviewKyc',
        'ValidateCardOrder',
        'AcceptTermsAndConditions',
      ]),
    }),
  ),
});

export const stepActivationSchema = stepSchema.extend({
  tasks: z.array(
    taskSchema.extend({
      type: z.enum(['FundWallet']),
    }),
  ),
});

export const stepExecutionSchema = stepSchema.extend({
  tasks: z.array(
    taskSchema.extend({
      type: z.enum([
        'AddBankAccountCode',
        'SubscriptionRecard',
        'PhysicalCardsActivation',
      ]),
    }),
  ),
});

// Closing phase doesn't have tasks, so it will be an empty array
const stepClosingSchema = stepSchema.extend({
  tasks: z.array(taskSchema).length(0),
});

export const getSfsMigrationTasksResponseSchema = z.object({
  migrationInitiation: stepInitationSchema,
  migrationActivation: stepActivationSchema,
  migrationExecution: stepExecutionSchema,
  migrationClosing: stepClosingSchema,
});
