import uniqBy from 'lodash/uniqBy';
import { matchPath } from 'react-router-dom';

import { type Lang } from 'modules/profile/models/lang';
import type FEATURES from 'src/core/constants/features';

import { getHelpCenterBase } from './getHelpCenterBase';
import { getQuestionsList } from './getQuestionsList';
import { getQuestionsRouter } from './getQuestionsRouter';
import { type TGlobalFunctionTyped as TFunctionTyped } from '../../hooks/useTranslation';

interface GetQuestionsCommonParams {
  user: {
    is_account_owner: boolean;
    is_admin: boolean;
    is_controller: boolean;
  };
}

interface GetQuestionsParams extends GetQuestionsCommonParams {
  questionsRouter: ReturnType<typeof getQuestionsRouter>;
  helpCenterBase: string;
  pathname: string;
}

const getQuestions = ({
  user,
  pathname,
  helpCenterBase,
  questionsRouter,
}: GetQuestionsParams) => {
  const matchingQuestionsRoute = questionsRouter.find(({ path }) =>
    matchPath(pathname, {
      path,
      exact: false,
      strict: false,
    }),
  );

  if (!matchingQuestionsRoute) {
    return {};
  }

  const matchingQuestions = matchingQuestionsRoute.questions(user);
  const { title, helpCenterIndex } = matchingQuestionsRoute;
  const questions = uniqBy(matchingQuestions, (question) => question.link).map(
    (question) => ({
      ...question,
      link: `${helpCenterBase}${question.link}`,
    }),
  );

  return {
    helpCenterIndex: {
      ...helpCenterIndex,
      link: `${helpCenterBase}${helpCenterIndex.link}`,
    },
    questions,
    title,
  };
};

interface GetQuestionsFactoryParams extends GetQuestionsCommonParams {
  lang: Lang;
  t: TFunctionTyped;
  featureFlags: {
    [FEATURES.PAYMENT_INBOX]: boolean;
  };
}

export const getQuestionsFactory = ({
  t,
  featureFlags,
  user,
  lang,
}: GetQuestionsFactoryParams) => {
  const helpCenterBase = getHelpCenterBase({ lang });
  const questionsList = getQuestionsList({ t });
  const questionsRouter = getQuestionsRouter({
    featureFlags,
    questionsList,
    t,
  });

  return (pathname: string) =>
    getQuestions({
      user,
      pathname,
      helpCenterBase,
      questionsRouter,
    });
};
