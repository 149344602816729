import { Callout } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { CompanySectionLoader } from 'modules/company/components/CompanySectionLoader';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { GraphQLProvider } from 'src/core/modules/bookkeep/components/GraphQLProvider';

import { BookkeepingStartDateSection } from './BookkeepingStartDateSection';
import { useGetBookkeepingStartDateQuery } from '../../../../hooks/useGetBookkeepingStartDateQuery';

import './BookkeepingStartDateSection.css';

export const BookkeepingStartDateSectionContainer = () => {
  const companyId = useCompanyId();
  const history = useHistory();
  const { t } = useTranslation('global');
  const bookkeepingStartDateQueryResult = useGetBookkeepingStartDateQuery();

  const isComingFromPreparePage =
    history.location.state?.isNewBookkeepingPrepareAfresh;

  return (
    <GraphQLProvider>
      <div id="bookkeeping-start-date">
        <h3 className="IntegrationAccountingPage__section-title title-xl">
          {t('accounting.bookkeepingStartDate.title')}
        </h3>
        <p className="IntegrationAccountingPage__section-description body-m">
          {t('accounting.bookkeepingStartDate.content')}
        </p>
        {bookkeepingStartDateQueryResult.status === 'error' && (
          <Callout
            title={t('bookkeep.integrations.settings.errorLoading')}
            variant="alert"
          />
        )}
        {bookkeepingStartDateQueryResult.status === 'loading' && (
          <CompanySectionLoader className="BookkeepingStartDateSection__loader" />
        )}
        {bookkeepingStartDateQueryResult.status === 'success' && (
          <BookkeepingStartDateSection
            bookkeepingStartDate={
              bookkeepingStartDateQueryResult.data.bookkeepingStartDate
            }
            redirectTo={
              isComingFromPreparePage
                ? routeFor(routes.EXPENSE_INBOX_PREPARE.path, {
                    company: companyId,
                  })
                : undefined
            }
          />
        )}
      </div>
    </GraphQLProvider>
  );
};
