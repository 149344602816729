import React from 'react';

export const HomepageWalletPlaceholder = () => {
  return (
    <div className="box p-xs pb-m">
      <div className="flex flex-col gap-m">
        <div className="flex h-[176px] flex-col gap-xs rounded-xxs bg-neutral-lightest px-m py-l text-center" />
        <div className="flex w-full content-stretch items-stretch justify-center gap-xs">
          <div className="flex items-center gap-s">
            <div className="flex h-l w-l items-center justify-center rounded-[1000px] border border-solid border-neutral-light bg-neutral-lightest">
              <img
                className="text-neutral-dark"
                src="/static/img/homepage/card.svg"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-xxs">
              <div className="h-[22px] w-[120px] rounded-xs bg-neutral-lightest" />
              <div className="h-[18px] w-[150px] rounded-xs bg-neutral-lightest" />
            </div>
          </div>
          <div className="mx-xs inline-block w-[1px] bg-neutral-light" />
          <div className="flex items-center gap-s">
            <div className="flex h-l w-l items-center justify-center rounded-[1000px] border border-solid border-neutral-light bg-neutral-lightest">
              <img
                className="text-neutral-dark"
                src="/static/img/homepage/card-pending.svg"
                alt=""
              />
            </div>
            <div className="flex flex-col gap-xxs">
              <div className="h-[22px] w-[120px] rounded-xs bg-neutral-lightest" />
              <div className="h-[18px] w-[150px] rounded-xs bg-neutral-lightest" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
