import {
  Callout,
  colors,
  DATE_FORMAT,
  Icon,
  SkeletonText,
} from '@dev-spendesk/grapes';
import React from 'react';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { ErrorBoundary } from 'common/components/withErrorBoundary';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { type ExtendedRequestState } from 'modules/requests/models/requestState';
import { type ApproverType } from 'src/core/modules/company/structure/approval-flows';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import {
  type RequestApproval,
  useRequestApprovalQuery,
} from './useRequestApprovalQuery';
import { type UserDetail, useUsersByIdQuery } from './useUsersByIdQuery';
import {
  type ApprovalRule,
  type ApprovalRuleState,
  type ApprovalStep,
  type ApprovalStepState,
  type ApproversListItem,
  extractNonLegacyRequestApprovers,
  getListType,
  getApproversWithApproverType,
  type ListType,
} from '../../models/approver';
import { displayFullname } from '../../utils/displayFullname';
import { MembersCheckList } from '../MembersCheckList/MembersCheckList';

import './ProgressBoxApproversList.css';

type Props = {
  requestId: string;
  hasNegativeMarginButton?: boolean;
};

export const ProgressBoxApproversList = ({
  requestId,
  hasNegativeMarginButton,
}: Props) => {
  const requestApprovalQuery = useRequestApprovalQuery(requestId);
  const { t } = useTranslation('global');
  return (
    <ErrorBoundary
      context={{
        team: 'finance-controller',
        scope: 'ApproversList',
      }}
      fallbackComponent={
        <Callout variant="alert" title={t('approval.progressBox.loadError')} />
      }
    >
      <QuerySuspense
        queryState={requestApprovalQuery}
        loading={
          <div
            key="approvers-loader"
            className="justify-starts mt-l flex flex-col gap-s"
          >
            <SkeletonText width="125px" />
            <SkeletonText width="125px" />
            <SkeletonText width="125px" />
          </div>
        }
        fallback={(error) => (
          <QueryError
            queryError={error}
            componentType="Callout"
            logContext={{
              team: 'capture',
            }}
          />
        )}
      >
        {(request) => (
          <ProgressBoxApproversListWithRequest
            request={request}
            hasNegativeMarginButton={hasNegativeMarginButton}
          />
        )}
      </QuerySuspense>
    </ErrorBoundary>
  );
};

const ProgressBoxApproversListWithRequest = ({
  request,
  hasNegativeMarginButton,
}: {
  request: RequestApproval;
  hasNegativeMarginButton?: boolean;
}) => {
  const requestApprovers = extractNonLegacyRequestApprovers(request.approval);
  const usersQuery = useUsersByIdQuery(
    requestApprovers.map((approver) => approver.id),
  );

  return (
    <QuerySuspense
      queryState={usersQuery}
      loading={
        <div
          key="approvers-loader"
          className="justify-starts mt-l flex flex-col gap-s"
        >
          <SkeletonText width="125px" />
          <SkeletonText width="125px" />
          <SkeletonText width="125px" />
        </div>
      }
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Callout"
          logContext={{
            team: 'capture',
          }}
        />
      )}
    >
      {(users) => {
        return (
          <ProgressBoxApproversListWithResponseAndApprovers
            request={request}
            approvers={getApproversWithApproverType(requestApprovers, users)}
            hasNegativeMarginButton={hasNegativeMarginButton}
          />
        );
      }}
    </QuerySuspense>
  );
};


const ProgressBoxApproversListWithResponseAndApprovers = ({
  request,
  approvers,
  hasNegativeMarginButton,
}: {
  request: RequestApproval;
  approvers: (UserDetail & { approverType: ApproverType })[];
  hasNegativeMarginButton?: boolean;
}) => {
  const { t, localeFormat } = useTranslation('global');
  const user = useUser();
  if (request.auto_approved) {
    return null;
  }
  if (request.state !== 'pending') {
    return null;
  }
  const isDirectApprover = request.direct_approver ?? false;
  const listType = getListType(request.approval.rules);
  const approverListItems = getApproversListItem({
    listType,
    approvers,
    request,
  });

  const hideForTheDirectApprover =
    request.direct_approver && approverListItems.length === 1;
  return (
    <>
      {getLabelContent({
        approverListItems,
        listType,
        t,
        isDirectApprover,
        isOwnRequest: user.id === request.user_id,
      })}
      {!hideForTheDirectApprover && (
        <MembersCheckList
          hasNegativeMarginButton={hasNegativeMarginButton}
          members={approverListItems.map(({ approver, state }) => {
            let icon = (
              <Icon
                size="s"
                name="clock"
                color={colors.neutralDark}
                data-testid="clock-icon"
              />
            );
            if (state === 'approved') {
              icon = (
                <Icon
                  size="s"
                  name="success"
                  color={colors.success}
                  data-testid="success-icon"
                />
              );
            }
            if (state === 'in_review' && user.id === approver?.id) {
              icon = (
                <Icon
                  size="s"
                  name="clock-full"
                  color={colors.neutralDark}
                  className="invert"
                  data-testid="clock-full-icon"
                />
              );
            }
            return {
              id: approver?.id,
              avatar: approver?.avatar,
              icon,
              label: displayFullname({
                t,
                fullname: approver?.fullname,
                isSelf: user.id === approver?.id,
              }),
              approverType: approver.approverType,
              tooltip: approver?.appraisedAt ? (
                <>
                  {t('approval.progressBox.approvedTooltip')}
                  <br />
                  {approver.appraisedAt
                    ? localeFormat(
                      new Date(approver.appraisedAt),
                      DATE_FORMAT.LONG_WITH_TIME,
                    )
                    : ''}
                </>
              ) : undefined,
            };
          })}
          withIcons={listType === 'queue'}
        />
      )}
    </>
  );
};

const getLabelContent = ({
  approverListItems,
  listType,
  t,
  isDirectApprover,
  isOwnRequest,
}: {
  approverListItems: ApproversListItem<UserDetail>[];
  listType: ListType;
  t: TGlobalFunctionTyped;
  isDirectApprover: boolean;
  isOwnRequest: boolean;
}) => {
  if (isDirectApprover) {
    if (listType === 'any' && approverListItems.length > 1) {
      return t('requests.progressBox.approvalStep.contentEitherThis');
    }
    return t('requests.progressBox.approvalStep.directApprover');
  }
  if (approverListItems.length === 1) {
    return isOwnRequest
      ? t('requests.progressBox.approvalStep.content')
      : t('requests.progressBox.approvalStep.contentThis');
  }
  if (isOwnRequest) {
    if (listType === 'any') {
      return t('requests.progressBox.approvalStep.contentEither');
    }
    return t('requests.progressBox.approvalStep.contentOrder');
  }
  if (listType === 'any') {
    return t('requests.progressBox.approvalStep.contentEitherThis');
  }
  return t('requests.progressBox.approvalStep.contentOrderThis');
};

export const getApproversListItem = function <
  T extends { id: string; approverType: ApproverType },
>({
  listType,
  approvers,
  request,
}: {
  listType: ListType;
  approvers: T[];
  request: {
    state: ExtendedRequestState;
    approval: {
      state: ApprovalRuleState;
      rules: ApprovalRule[];
    };
  };
}): ApproversListItem<T & Partial<ApprovalStep['event']>>[] {
  const { rules } = request.approval;
  switch (request.state) {
    case 'approved':
    case 'pending':
      if (listType === 'any') {
        return getApproversAnyListItemByRuleState(request.approval, approvers);
      }
      return getApproversQueueListItemByRuleState(rules, approvers);
    case 'expired':
      return [];
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'refused':
    default:
      if (listType === 'any') {
        return getApproversAnyListItemByRuleState(request.approval, approvers);
      }
      return getApproversQueueListItemByRuleState(rules, approvers).filter(
        // in queue list we only show approver who denied request
        (listItem) => listItem.state === 'denied',
      );
  }
};

export const getApproversAnyListItemByRuleState = function <
  T extends { id: string; approverType: ApproverType },
>(
  approval: {
    state: ApprovalRuleState;
    rules: ApprovalRule[];
  },
  approvers: T[],
): ApproversListItem<T & Partial<ApprovalStep['event']>>[] {
  return (
    approval.rules
      .filter((rule) =>
        ['in_review', 'pending', 'approved', 'denied'].includes(rule.state),
      )
      // we don't display an AO. even if the AO can approve it only the assigned approvers have to be displayed in the list.
      // The AO rule is displayed only if it's the only rule available
      .filter((rule, _, rules) => rules.length === 1 || !rule.isAoRule)
      .filter((rule) => approval.state === rule.state)
      .map((rule) => {
        // Ignore the steps of a rule that have already been approved to look for the next step in the approval flow
        const nextApprovalStep = rule.steps.find(
          (step) => step.state !== 'approved',
        );
        if (!nextApprovalStep) {
          throw new Error(`Unexpected missing ongoing step in approval rule`);
        }
        const currentApprover = approvers.find(
          (approver) =>
            approver.id === nextApprovalStep.actingApproverId &&
            approver.approverType === nextApprovalStep.approverType,
        ) as T;
        return {
          approver: { ...currentApprover, ...nextApprovalStep.event },
          state: rule.state,
        };
      })
  );
};

const getApproversQueueListItemByRuleState = function <
  T extends { id: string; approverType: ApproverType },
>(
  rules: ApprovalRule[],
  approvers: T[],
): ApproversListItem<T & Partial<ApprovalStep['event']>>[] {
  const foundRule = rules.find((rule) =>
    ['in_review', 'approved', 'denied'].includes(rule.state),
  );
  if (!foundRule) {
    return [];
  }
  return foundRule.steps.map((step) => {
    const currentApprover = approvers.find(
      (approver) =>
        approver.id === step.actingApproverId &&
        approver.approverType === step.approverType,
    ) as T;
    return {
      approver: { ...currentApprover, ...step.event },
      state: getApprovalStateFromStepState(step.state),
    };
  });
};

const getApprovalStateFromStepState = (
  step: ApprovalStepState,
): ApprovalRuleState => {
  switch (step) {
    case 'in_review':
      return 'in_review';
    case 'upcoming':
    case 'pending':
      return 'pending';
    case 'approved':
      return 'approved';
    case 'denied':
      return 'denied';
    default:
      rejectUnexpectedValue('approvalStep', step);
  }
};
