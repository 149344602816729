import { type MonetaryValue } from 'ezmoney';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type WalletAmountAllocation } from '../wallet/walletSummary';

type RawWalletSummary = {
  id: string;
  name: string;
  status: 'active' | 'in-review';
  // totalAmount is returned by the backend only if the current user has the right to see wallet information (ie. is controller)
  totalAmount?: MonetaryValue;
  walletDetails?: {
    allocatedOnCards: {
      totalAmount: MonetaryValue;
      subscriptionCardsAmount: MonetaryValue;
      physicalCardsAmount: MonetaryValue;
      singlePurchaseCardsAmount: MonetaryValue;
    };
    available: {
      totalAmount: MonetaryValue;
    };
  };
};

type WalletAmountByAllocation = Record<
  WalletAmountAllocation,
  MonetaryValue | undefined
>;

export const useFetchCompanyWalletAmountQuery = (
  isEnabled: boolean,
): QueryState<WalletAmountByAllocation> => {
  const companyId = useCompanyId();

  return useQuery<WalletAmountByAllocation, RawWalletSummary>({
    key: ['wallet-summary', companyId],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/companies/${companyId}/wallet-summary`,
    },
    isEnabled,
    reshapeData: (data) => {
      return {
        total: data.totalAmount,
        allocatedOnCards: data.walletDetails?.allocatedOnCards.totalAmount,
        availableForNewRequests: data.walletDetails?.available.totalAmount,
      };
    },
  });
};
