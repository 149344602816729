import { type FiltersState } from 'modules/bookkeep/payables/containers/PayablesFiltersContainer/hooks/useFiltersContext';
import type { Member, PerDiem, Supplier } from 'modules/payable/models';

import type {
  RawMember,
  RawSupplier,
  RawCounterparty,
  RawMileageDetails,
  RawPerDiem,
  RawCreditNoteDetails,
} from './query';

export const reshapeMember = (rawMember: RawMember): Member => {
  if (rawMember.__typename === 'User') {
    return {
      type: 'user',
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      email: rawMember.email,
      avatar: rawMember.avatar,
    };
  }
  if (rawMember.__typename === 'SuspendedUser') {
    return {
      type: 'suspendedUser',
      givenName: rawMember.givenName,
      familyName: rawMember.familyName,
      avatar: undefined,
    };
  }
  return {
    type: 'invitee',
    email: rawMember.email,
  };
};

export const reshapeSupplier = (rawSupplier: RawSupplier): Supplier => {
  return {
    name: rawSupplier.name,
    thumbnailUrl: rawSupplier.thumbnailUrl,
  };
};

export const reshapeCounterparty = (
  rawCounterparty: RawCounterparty,
): Member | (Supplier & { type: 'supplier' }) => {
  if (rawCounterparty.__typename === 'Supplier') {
    return { type: 'supplier', ...reshapeSupplier(rawCounterparty) };
  }
  return reshapeMember(rawCounterparty);
};

export const reshapeMileageDetails = (rawMileageDetails: RawMileageDetails) => {
  return {
    distance: `${rawMileageDetails.distance.value}${rawMileageDetails.distance.unit}`,
  };
};

export const reshapePerDiem = (rawType: RawPerDiem): PerDiem => {
  return {
    duration: rawType.tripDays.length,
  };
};

export const reshapeCreditNoteDetails = (
  rawCreditNoteDetails: RawCreditNoteDetails,
) => {
  return {
    creditNoteNumber: rawCreditNoteDetails.creditNoteNumber,
    referenceInvoiceRequestNumber:
      rawCreditNoteDetails.referenceInvoiceRequestNumber,
    referenceInvoiceRequestId: rawCreditNoteDetails.referenceInvoiceRequestId,
  };
};

export const reshapeFilters = (state: FiltersState): FiltersState => {
  const reshapedPayableStates = [];
  if (state.payableStates) {
    if (state.payableStates.includes('toPrepare')) {
      reshapedPayableStates.push('created', 'unprepared');
    }
    if (state.payableStates.includes('toExport')) {
      reshapedPayableStates.push('prepared', 'to_accounting_failed');
    }
    if (state.payableStates.includes('exported')) {
      reshapedPayableStates.push('in_accounting', 'in_accounting_manually');
    }
  } else {
    // If we don't have state filters, we fetch every state except "discarded"
    reshapedPayableStates.push(
      'created',
      'prepared',
      'unprepared',
      'notBookkept',
      'to_accounting_pending',
      'to_accounting_failed',
      'in_accounting',
      'in_accounting_manually',
    );
  }

  return {
    ...state,
    payableStates:
      reshapedPayableStates.length > 0 ? reshapedPayableStates : undefined,
  };
};
