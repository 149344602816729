import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type DraftPurchaseOrderRequest } from '../../models';
import { type PurchaseOrderRequestDetails } from '../models';

export type UpdatePurchaseOrderRequestError = {
  reason:
    | 'invalidPayload'
    | 'invalidRequestType'
    | 'invalidRequestState'
    | 'missingPurchaseOrderFeature'
    | 'invalidStartDate'
    | 'invalidEndDate'
    | 'startDateCannotBeAfterEndDate'
    | 'startDateTooOld'
    | 'dateIntervalTooLong';
  error?: string;
};

type UpdatePurchaseOrderRequestResponse =
  | UpdatePurchaseOrderRequestError
  | PurchaseOrderRequestDetails;

export const useUpdatePurchaseOrderRequest = (
  requestId: string,
): MutationState<
  Partial<DraftPurchaseOrderRequest>,
  UpdatePurchaseOrderRequestResponse
> => {
  return useMutation<
    Partial<DraftPurchaseOrderRequest>,
    UpdatePurchaseOrderRequestResponse,
    UpdatePurchaseOrderRequestResponse
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/requests/purchase-orders/${requestId}`,
      method: 'patch',
    },
    options: {
      throwOnError: true,
    },
    reshapeData(data) {
      return data;
    },
  });
};
