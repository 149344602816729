import { createSelector } from '@reduxjs/toolkit';
import { isSameMonth } from 'date-fns';
import i18n from 'i18next';
import compact from 'lodash/compact';

import {
  getHasMoreItems,
  getItems,
  getIsFetching,
  getLimit,
  getOffset,
  getTotalCount,
} from 'common/redux/paginate';
import * as paginatedCursor from 'common/redux/paginateCursor';
import { RequestState } from 'common/redux/requestState';
import { getSelectedNodeRefs } from 'common/redux/selection';
import { getMonthsBetweenDates } from 'common/utils/getMonthsBetweenDates';
import { type PaymentMethod, getIsUsMarketCompany } from 'modules/company';
import { type AppState } from 'src/core/reducers';
import {
  getCompanyPaymentMethods,
  getIsLoadingPaymentMethods,
} from 'src/core/selectors/globalSelectors';
import { intersection } from 'src/core/utils/array';

import { type UsersScheduledPaymentsState } from './reducer';
import { toUserRequests, formatBatchDate, parseBatchDate } from '../models';
import {
  type UserScheduledPayments,
  type UserRequests,
  type ScheduledPaymentsBatch,
  USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY,
  type ScheduledPayment,
  type ExpenseClaimsCounts,
} from '../types';
import {
  isCsvOnlyPaymentMethod,
  filterScheduledPaymentsByIds,
  getScheduledPaymentsAmount,
  getScheduledPaymentsUsers,
} from '../utils';

export const getExpenseClaimsCounts = (state: AppState): ExpenseClaimsCounts =>
  state.expenseClaims.counts.data;

export const getIsExpenseClaimsCountsLoading = (state: AppState): boolean =>
  state.expenseClaims.counts.requestState === RequestState.Loading;

export const getPaymentsToConfirmCount = (
  state: AppState,
): number | undefined => getExpenseClaimsCounts(state).paymentsToConfirm;

export const getOpenedUserRequests = (state: AppState): UserRequests | null => {
  const { openedUserScheduledPayments } = state.expenseClaims;

  if (openedUserScheduledPayments) {
    return toUserRequests(openedUserScheduledPayments);
  }
  return openedUserScheduledPayments;
};

export const getHasMoreUsersScheduledPayments = (state: AppState) =>
  getHasMoreItems<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getUsersScheduledPayments = (state: AppState) =>
  getItems<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getIsFetchingUsersScheduledPayments = (state: AppState) =>
  getIsFetching<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getUsersScheduledPaymentsPaginationLimit = (state: AppState) =>
  getLimit<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getUsersScheduledPaymentsTotalCount = (state: AppState) =>
  getTotalCount<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getUsersScheduledPaymentsPaginationOffset = (state: AppState) =>
  getOffset<UserScheduledPayments, UsersScheduledPaymentsState['offset']>(
    state.expenseClaims.usersScheduledPayments,
  );

export const getUsersScheduledPaymentsSelection = (state: AppState) =>
  state.expenseClaims.usersScheduledPaymentsSelection;

export const getIsSendingToPayment = (state: AppState) =>
  state.expenseClaims.sendToPayment.isSendingToPayment;

export const getIsDownloadingCsv = (state: AppState) =>
  state.expenseClaims.sendToPayment.isDownloadingCsv;

export const getIsSendingMissingBankInfoReminders = (state: AppState) =>
  state.expenseClaims.sendToPayment.isSendingMissingBankInfoReminders;

const getSelectedUserIds = createSelector(
  getUsersScheduledPaymentsSelection,
  (selection) => {
    if (!selection) {
      return null;
    }
    return getSelectedNodeRefs(
      selection,
      USERS_SCHEDULED_PAYMENTS_LIST_ROOT_KEY,
    );
  },
);

export const getSelectedUserCount = createSelector(getSelectedUserIds, (ids) =>
  ids ? ids.length : 0,
);

export const getIsSelectedUsersEmpty = createSelector(
  getSelectedUserIds,
  (selectedUserIds) => {
    if (!selectedUserIds) {
      return false;
    }
    return selectedUserIds.length === 0;
  },
);

export const getSelectedUsersScheduledPayments = createSelector(
  getSelectedUserIds,
  getUsersScheduledPayments,
  (selectedUserIds, usersScheduledPayments) => {
    if (!selectedUserIds) {
      return [];
    }
    const selectedUsersScheduledPayments = selectedUserIds.map((userId) =>
      usersScheduledPayments.find(
        (userScheduledPayment) => userScheduledPayment.user.id === userId,
      ),
    );

    return compact(selectedUsersScheduledPayments);
  },
);

const getSelectedScheduledPaymentIds = createSelector(
  getSelectedUsersScheduledPayments,
  (selectedUsersScheduledPayments) => {
    return selectedUsersScheduledPayments.reduce(
      (accumulator: string[], userScheduledPayment) => {
        const scheduledPaymentIds = userScheduledPayment.scheduledPayments.map(
          (scheduledPayment) => scheduledPayment.id,
        );
        return accumulator.concat(scheduledPaymentIds);
      },
      [],
    );
  },
);

const getScheduledPayments = createSelector(
  getUsersScheduledPayments,
  (usersScheduledPayments) => {
    return usersScheduledPayments.reduce(
      (
        scheduledPayments: ScheduledPayment[],
        userScheduledPayments: UserScheduledPayments,
      ) => {
        return scheduledPayments.concat(
          userScheduledPayments.scheduledPayments,
        );
      },
      [],
    );
  },
);

const getAllPaymentMethodsByScheduledPaymentId = createSelector(
  getScheduledPayments,
  (scheduledPayments) => {
    const result: { [key: string]: PaymentMethod[] } = {};

    for (const scheduledPayment of scheduledPayments) {
      result[scheduledPayment.id] = scheduledPayment.availablePaymentMethods;
    }

    return result;
  },
);

export const getIsCsvOnlyPaymentMethod = createSelector(
  getCompanyPaymentMethods,
  (companyPaymentMethods) => {
    return isCsvOnlyPaymentMethod(companyPaymentMethods);
  },
);

export const getSelectedScheduledPaymentWithAlertIds = createSelector(
  getSelectedScheduledPaymentIds,
  getScheduledPayments,
  getIsCsvOnlyPaymentMethod,
  (selectedScheduledPaymentIds, scheduledPayments, isCsvOnly) => {
    if (isCsvOnly) {
      return [];
    }
    return selectedScheduledPaymentIds.filter((scheduledPaymentId) => {
      const scheduledPayment = scheduledPayments.find(
        ({ id }) => id === scheduledPaymentId,
      );

      return scheduledPayment?.availablePaymentMethods.length === 1;
    });
  },
);

export const getSelectedScheduledPaymentWithoutAlertIds = createSelector(
  getSelectedScheduledPaymentIds,
  getSelectedScheduledPaymentWithAlertIds,
  (selectedScheduledPaymentIds, scheduledPaymentWithAlertIds) => {
    return selectedScheduledPaymentIds.filter(
      (selectedScheduledPaymentId) =>
        !scheduledPaymentWithAlertIds.includes(selectedScheduledPaymentId),
    );
  },
);

const getSelectedScheduledPaymentsWithoutAlert = createSelector(
  getScheduledPayments,
  getSelectedScheduledPaymentWithoutAlertIds,
  (scheduledPayments, scheduledPaymentWithoutAlertIds: string[]) => {
    return filterScheduledPaymentsByIds(
      scheduledPayments,
      scheduledPaymentWithoutAlertIds,
    );
  },
);

const getSelectedScheduledPaymentsWithAlert = createSelector(
  getScheduledPayments,
  getSelectedScheduledPaymentWithAlertIds,
  (scheduledPayments, scheduledPaymentWithAlertIds) => {
    return filterScheduledPaymentsByIds(
      scheduledPayments,
      scheduledPaymentWithAlertIds,
    );
  },
);

export const getSelectedScheduledPaymentsWithoutAlertAmount = createSelector(
  getSelectedScheduledPaymentsWithoutAlert,
  (scheduledPayments) => {
    return getScheduledPaymentsAmount(scheduledPayments);
  },
);

export const getSelectedScheduledPaymentsWithoutAlertRequestIds =
  createSelector(
    getSelectedScheduledPaymentsWithoutAlert,
    (scheduledPayments) =>
      (scheduledPayments ?? []).flatMap(({ requests }) =>
        requests.map(({ id }) => id),
      ),
  );

export const getSelectedUsersWithoutAlertCount = createSelector(
  getSelectedScheduledPaymentsWithoutAlert,
  (scheduledPayments) => {
    return getScheduledPaymentsUsers(scheduledPayments).length;
  },
);

export const getSelectedScheduledPaymentsWithAlertAmount = createSelector(
  getSelectedScheduledPaymentsWithAlert,
  (scheduledPayments) => {
    return getScheduledPaymentsAmount(scheduledPayments);
  },
);

export const getSelectedScheduledPaymentsWithAlertRequestIds = createSelector(
  getSelectedScheduledPaymentsWithAlert,
  (scheduledPayments) =>
    (scheduledPayments ?? []).flatMap(({ requests }) =>
      requests.map(({ id }) => id),
    ),
);

const getSelectedUsersWithAlert = createSelector(
  getSelectedScheduledPaymentsWithAlert,
  (scheduledPayments) => {
    return getScheduledPaymentsUsers(scheduledPayments);
  },
);

export const getSelectedUsersWithAlertCount = createSelector(
  getSelectedUsersWithAlert,
  (selectedUsersWithAlert) => {
    return selectedUsersWithAlert.length;
  },
);

export const getSelectedUsersWithAlertIds = createSelector(
  getSelectedUsersWithAlert,
  (selectedUsersWithAlert) => {
    return selectedUsersWithAlert.map((user) => user.id);
  },
);

export const getSingleSelectedUserWithAlertName = createSelector(
  getSelectedUsersWithAlert,
  (selectedUsersWithAlert) =>
    selectedUsersWithAlert.length === 1
      ? `${selectedUsersWithAlert[0].firstName} ${selectedUsersWithAlert[0].lastName}`
      : undefined,
);

export const getHasSelectedUsersWithAlertOnly = createSelector(
  getSelectedUsersWithoutAlertCount,
  getSelectedUsersWithAlertCount,
  (withoutAlertCount, withAlertCount) =>
    withoutAlertCount === 0 && withAlertCount > 0,
);

export const getDefaultPaymentMethod = createSelector(
  getSelectedScheduledPaymentWithoutAlertIds,
  getAllPaymentMethodsByScheduledPaymentId,
  (
    selectedScheduledPaymentWithoutAlertIds,
    paymentMethodsByScheduledPaymentId,
  ) => {
    const paymentMethods = selectedScheduledPaymentWithoutAlertIds.map(
      (scheduledPaymentId) =>
        paymentMethodsByScheduledPaymentId[scheduledPaymentId],
    );
    const commonPaymentMethods: PaymentMethod[] = intersection(
      ...paymentMethods,
    );
    // Return the first one, because they should be ordered by priority
    return commonPaymentMethods[0] || null;
  },
);

export const getIsSendToPaymentLoading = (state: AppState) => {
  const isFetchingUsersScheduledPayments =
    getIsFetchingUsersScheduledPayments(state);
  const isLoadingPaymentMethods = getIsLoadingPaymentMethods(state);

  return isLoadingPaymentMethods || isFetchingUsersScheduledPayments;
};

export const getScheduledPaymentsBatchesPaginationLimit = (state: AppState) =>
  paginatedCursor.getLimit<ScheduledPaymentsBatch>(
    state.expenseClaims.scheduledPaymentsBatches,
  );

export const getScheduledPaymentsBatchesPaginationNextCursor = (
  state: AppState,
) =>
  paginatedCursor.getNextCursor<ScheduledPaymentsBatch>(
    state.expenseClaims.scheduledPaymentsBatches,
  );

export const getScheduledPaymentsBatchesRequestState = (state: AppState) =>
  paginatedCursor.getRequestState<ScheduledPaymentsBatch>(
    state.expenseClaims.scheduledPaymentsBatches,
  );

export const getHasScheduledPaymentsBatches = (state: AppState) =>
  paginatedCursor.getItems<ScheduledPaymentsBatch>(
    state.expenseClaims.scheduledPaymentsBatches,
  ).length > 0;

export const getHasMoreScheduledPaymentsBatches = createSelector(
  getHasScheduledPaymentsBatches,
  getScheduledPaymentsBatchesPaginationNextCursor,
  (
    hasScheduledPaymentsBatches,
    scheduledPaymentsBatchesPaginationNextCursor,
  ) => {
    return (
      hasScheduledPaymentsBatches &&
      Boolean(scheduledPaymentsBatchesPaginationNextCursor)
    );
  },
);

export const getScheduledPaymentsBatches = (
  state: AppState,
): ScheduledPaymentsBatch[] => {
  const scheduledPaymentsBatches =
    paginatedCursor.getItems<ScheduledPaymentsBatch>(
      state.expenseClaims.scheduledPaymentsBatches,
    );

  // FIXME: Remove empty scheduled payments batches because we don't want to display them in the interface (empty scheduled payments batches can happen after manual actions in the DB or when trying to confirm scheduled payments multiple times). This will be fixed in the backend, but in the meantime, we mitigate this issue on the frontend too.
  return scheduledPaymentsBatches.filter((scheduledPaymentBatch) => {
    return scheduledPaymentBatch.scheduledPaymentsCount > 0;
  });
};

export const getScheduledPaymentsBatchesCancelToken = (state: AppState) =>
  state.expenseClaims.scheduledPaymentsBatches.cancelToken;

export const getScheduledPaymentsBatchesByMonth = createSelector(
  getScheduledPaymentsBatches,
  (scheduledPaymentsBatches) => {
    const batchesByMonth = new Map<string, ScheduledPaymentsBatch[]>();
    let lastMonth;

    for (const batch of scheduledPaymentsBatches) {
      const month = parseBatchDate(batch.batchedAt);
      const formattedMonth = formatBatchDate(month, i18n.language);

      if (!lastMonth) {
        lastMonth = month;
      }

      if (!isSameMonth(lastMonth, month)) {
        for (const emptyMonth of getMonthsBetweenDates(month, lastMonth)) {
          batchesByMonth.set(formatBatchDate(emptyMonth, i18n.language), []);
        }
      }

      lastMonth = month;

      const batchesInMonth = batchesByMonth.get(formattedMonth) || [];

      batchesByMonth.set(formattedMonth, [...batchesInMonth, batch]);
    }

    return batchesByMonth;
  },
);

export const getIsFetchingScheduledPaymentsByBatchId = (
  state: AppState,
  batchId: string,
) => state.expenseClaims.scheduledPaymentsByBatchId[batchId].isFetching;

export const getHasFetchedScheduledPaymentsByBatchId = (
  state: AppState,
  batchId: string,
) => state.expenseClaims.scheduledPaymentsByBatchId[batchId].hasFetched;

export const getScheduledPaymentsCountByBatchId = (
  state: AppState,
  batchId: string,
) => state.expenseClaims.scheduledPaymentsByBatchId[batchId].count;

export const getScheduledPaymentsByBatchId = (
  state: AppState,
  batchId: string,
) => state.expenseClaims.scheduledPaymentsByBatchId[batchId].items;

export const getOpenedScheduledPayment = (state: AppState) =>
  state.expenseClaims.openedScheduledPayment;

export const getIsConfirmingScheduledPayments = (state: AppState) =>
  state.expenseClaims.confirmScheduledPayments.isLoading;

export const getScheduledPaymentsConfirmationAuthType = (state: AppState) =>
  state.expenseClaims.confirmScheduledPayments.authType;

export const getIsCancellingScheduledPayments = (state: AppState) =>
  state.expenseClaims.cancelScheduledPayments.isLoading;

export const getScheduledPaymentsBatchScheduledPaymentsCountById = (
  state: AppState,
  id: string,
) => {
  const scheduledPaymentsBatch = getScheduledPaymentsBatches(state).find(
    (batch) => batch.id === id,
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return scheduledPaymentsBatch!.scheduledPaymentsCount;
};

export const getShowWarningWhenNotEnoughFundsForWireTransfer = createSelector(
  getIsUsMarketCompany,
  (isUSMarket) => !isUSMarket,
);
