import {
  Button,
  FormField,
  Label,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import type { TypeFormValues } from './form';

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  isDeletable: boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
} & FormikProps<TypeFormValues>;

export const TypeColumnEditModal = ({
  isOpen,
  title,
  description,
  isDeletable,
  onRemoveColumn,
  onClose,
  // Formik
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('exports.columnEditModalTitle', { title })}
      subtitle={description}
      onClose={onClose}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(event: any) => {
            handleSubmit(event);
          }}
        />,
      ]}
    >
      <div className="text-left">
        <FormField
          label={t('exports.renameInputLabel')}
          alertMessage={
            touched.title && Boolean(errors.title) ? errors.title : undefined
          }
        >
          <TextInput
            name="title"
            id="title"
            value={values.title}
            onChange={handleChange}
            isInvalid={touched.title && Boolean(errors.title)}
          />
        </FormField>

        <div className="mt-m flex flex-col">
          <Label
            className="mb-xs"
            label={t('bookkeep.settings.export.typeOptions.header')}
          />
          {/* General */}
          <FormField
            label={t('bookkeep.settings.export.typeOptions.general')}
            className="mb-xs"
            alertMessage={
              touched.general && Boolean(errors.general)
                ? errors.general
                : undefined
            }
          >
            <TextInput
              name="typeGeneral"
              id="typeGeneral"
              value={values.general || ''}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('general', e.target.value);
              }}
              isInvalid={touched.general && Boolean(errors.general)}
            />
          </FormField>

          {/* Analytical */}
          <FormField
            label={t('bookkeep.settings.export.typeOptions.analytical')}
            className="mb-xs"
            alertMessage={
              touched.analytical && Boolean(errors.analytical)
                ? errors.analytical
                : undefined
            }
          >
            <TextInput
              name="typeAnalytical"
              id="typeAnalytical"
              value={values.analytical || ''}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('analytical', e.target.value);
              }}
              isInvalid={touched.analytical && Boolean(errors.analytical)}
            />
          </FormField>
        </div>

        {isDeletable && (
          <Button
            hasNegativeMargins
            className="mt-m"
            variant="ghost"
            iconName="minus-circle"
            iconPosition="left"
            text={t('exports.removeColumn')}
            onClick={onRemoveColumn}
          />
        )}
      </div>
    </Modal>
  );
};
