import Loader from 'src/core/common/components/legacy/Loader/Loader';
import { LazyLoadedSignature } from 'src/onboarding/components/Signature/LazyLoadedSignature';

type Props = {
  signingUrl?: string;
  onSignSuccess: () => void;
  onSignError: (error: Error) => void;
};

const AffidavitSignature = ({
  signingUrl,
  onSignSuccess,
  onSignError,
}: Props) => {
  return (
    // Make sure the height has a minimum for iOS Safari
    // If no min height then the Hellosign will load into a rectangle with 0 height :facepalm:
    <div className="fixed !inset-0 col-span-full row-span-full h-full min-h-[100vh]">
      {!signingUrl && <Loader />}
      {signingUrl && (
        <LazyLoadedSignature
          signatureURL={signingUrl}
          onSignSuccess={onSignSuccess}
          onSignError={onSignError}
        />
      )}
    </div>
  );
};

export { AffidavitSignature };
