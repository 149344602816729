import { DATE_FORMAT, UploadButton } from '@dev-spendesk/grapes';

import { FileReceipt } from 'common/components/FileReceipt/FileReceipt';
import { PanelPreviewsSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';

import { type Quote } from '../../../models';

type Props = {
  canAddQuotes: boolean;
  quotes: Quote[];
  onUpload(files: FileList): void;
};

export const PurchaseOrderRequestQuotes = ({
  canAddQuotes,
  quotes,
  onUpload,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  return (
    <PanelPreviewsSection
      title={t('requests.panel.purchaseOrder.quote')}
      previews={quotes.map((quote) => (
        <FileReceipt
          key={quote.id}
          previewName={t('requests.panel.purchaseOrder.uploadedOn', {
            date: localeFormat(new Date(quote.createdAt), DATE_FORMAT.SHORT),
          })}
          fileUrl={quote.viewUrl}
          fileMediaType={quote.mediaType}
        />
      ))}
      noPreviewContent={t('requests.panel.purchaseOrder.noQuoteAttached')}
    >
      {canAddQuotes && (
        <UploadButton
          className="mt-xs"
          accept="image/*, application/pdf"
          fit={quotes.length > 0 ? 'content' : 'parent'}
          isMultiple
          variant={quotes.length > 0 ? 'ghost' : 'primary'}
          iconName="plus-circle"
          text={t('requests.panel.uploadAnotherDocument')}
          onUpload={async (files) => {
            if (files) {
              await onUpload(files);
            }
          }}
        />
      )}
    </PanelPreviewsSection>
  );
};
