import { Tooltip } from '@dev-spendesk/grapes';
import { greaterThanOrEqual } from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { getReadableSpendingType } from 'modules/company/members/components/ApprovalPolicies/spendingTypes';
import { useAmountAlreadySpent } from 'modules/profile/hooks/useAmountAlreadySpent';
import { formatMonetaryValue } from "src/core/utils/monetaryValue";

import { SpendingLimitReachedMessage } from './SpendingLimitReachedMessage';
import { type SpendingPolicy } from '../../../../models/rules';
import './RequesterApprovalPolicy.css';

type Props = {
  spendingPolicy: SpendingPolicy;
};

export const RequesterApprovalPolicy = ({ spendingPolicy }: Props) => {
  const { t } = useTranslation('global');
  const amountAlreadySpent = useAmountAlreadySpent();

  switch (spendingPolicy.approvalNeeded) {
    case 'always':
      return (
        <div>
          {t('control.approvalPolicyWidget.approvalPolicyDetailsAlways')}
        </div>
      );

    case 'never':
      return (
        <div>
          {t('control.approvalPolicyWidget.approvalPolicyDetailsNever')}
        </div>
      );

    default: {
      const spendingTypesFormatted = spendingPolicy.spendingTypes
        .map((spendingType) => getReadableSpendingType(spendingType, t))
        .join(', ');
      const displayTooltip = spendingPolicy.spendingTypes.length > 1;

      return (
        <div>
          <div className="RequesterApprovalPolicy">
            <Trans
              i18nKey="control.approvalPolicyWidget.approvalPolicyDetails"
              components={[
                <Tooltip
                  key="approvalPolicyDetails"
                  content={spendingTypesFormatted}
                  isDisabled={!displayTooltip}
                >
                  <></>
                </Tooltip>,
              ]}
              values={{
                count: spendingPolicy.spendingTypes.length,
                maxTransaction: formatMonetaryValue(
                  spendingPolicy.maxTransaction,
                ),
                spendingLimit: formatMonetaryValue(
                  spendingPolicy.spendingLimit,
                ),
                spendingMethods: spendingTypesFormatted,
              }}
            />
          </div>
          {greaterThanOrEqual(
            amountAlreadySpent,
            spendingPolicy.spendingLimit,
          ) && <SpendingLimitReachedMessage />}
        </div>
      );
    }
  }
};
