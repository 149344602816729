import { useLocalStorage } from 'react-use';

const sessionStorageKey = 'members-bulk-onboarding-modal';

export const useShouldDisplayOnboardingModal = (): [boolean, () => void] => {
  const [shouldDisplayOnboardingModal, setShouldDisplayOnboardingModal] =
    useLocalStorage<string | undefined>(sessionStorageKey, undefined);

  return [
    !shouldDisplayOnboardingModal,
    () => setShouldDisplayOnboardingModal(new Date().toISOString()),
  ];
};
