import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import greenCheckIcon from './icons/greenCheck.svg';

import './MarqetaSetPinSuccess.css';

type Props = {
  onClose(): void;
};

export const MarqetaSetPinSuccess = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="MarqetaSetPinSuccess">
      <img
        alt=""
        src={greenCheckIcon}
        className="MarqetaSetPinSuccess__image"
      />
      <div className="MarqetaSetPinSuccess__message">
        {t('cards.setPinWidget.success')}
      </div>
      <Button
        text={t('cards.setPinWidget.continue')}
        className="MarqetaSetPinSuccess__button"
        onClick={onClose}
      />
    </div>
  );
};
