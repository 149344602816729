import compact from 'lodash/compact';
import get from 'lodash/get';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import replace from 'lodash/replace';
import size from 'lodash/size';
import trim from 'lodash/trim';

const LN = '\n';
const START_BLOCK = 'BEGIN:VCARD\nVERSION:3.0';
const END_BLOCK = `REV:${new Date().toISOString()}\nEND:VCARD`;

class VCard {
  constructor(fileName, options) {
    const userData = pick(options, [
      'firstName',
      'lastName',
      'nickname',
      'organisation',
      'email',
      'phone',
      'title',
      'avatar',
      'url',
      'note',
    ]);
    this.fileName = isString(fileName) ? fileName : 'contact.vcf';
    this.data = {
      firstName: null,
      lastName: null,
      nickname: null,
      email: null,
      phone: null,
      title: null,
      organisation: null,
      avatar: null,
      url: null,
      note: null,
      ...userData,
    };
    this.output = null;
  }

  download = () => {
    const content = this.getContent();
    const exportUrl = `data:text/vcard;charset=utf-8,${encodeURIComponent(
      content,
    )}`;
    const element = document.createElement('a');
    element.setAttribute('href', exportUrl);
    element.setAttribute('download', this.fileName);
    element.style.display = 'none';
    document.body.append(element);
    element.click();
    element.remove();
  };

  getContent = () =>
    compact([
      START_BLOCK,
      this.getFullname(),
      this.getIdentity(),
      this.getNickname(),
      this.getEmail(),
      this.getPhone(),
      this.getTitle(),
      this.getOrganisation(),
      this.getUrl(),
      this.getNote(),
      END_BLOCK,
    ]).join('');

  sanitize = (string_) => replace(trim(string_), /\n/, '');

  getFullname = () => {
    const firstName = this.sanitize(get(this.data, 'firstName'));
    const lastName = this.sanitize(get(this.data, 'lastName'));
    if (!size(firstName) && !size(lastName)) {
      return null;
    }
    return `FN;CHARSET=UTF-8:${firstName} ${lastName}${LN}`;
  };

  getIdentity = () => {
    const firstName = this.sanitize(get(this.data, 'firstName'));
    const lastName = this.sanitize(get(this.data, 'lastName'));
    if (!size(firstName) && !size(lastName)) {
      return null;
    }
    return `N;CHARSET=UTF-8:${lastName};${firstName};;;${LN}`;
  };

  getNickname = () => {
    const nickname = this.sanitize(get(this.data, 'nickname'));
    if (!size(nickname)) {
      return null;
    }
    return `NICKNAME:${nickname}${LN}`;
  };

  getEmail = () => {
    const email = this.sanitize(get(this.data, 'email'));
    if (!size(email)) {
      return null;
    }
    return `EMAIL;type=HOME,INTERNET:${email}${LN}`;
  };

  getPhone = () => {
    const phone = this.sanitize(get(this.data, 'phone'));
    if (!size(phone)) {
      return null;
    }
    return `TEL;TYPE=WORK,VOICE:${phone}${LN}`;
  };

  getTitle = () => {
    const title = this.sanitize(get(this.data, 'title'));
    if (!size(title)) {
      return null;
    }
    return `TITLE:${title}${LN}`;
  };

  getOrganisation = () => {
    const organisation = this.sanitize(get(this.data, 'organisation'));
    if (!size(organisation)) {
      return null;
    }
    return `ORG:${organisation}${LN}`;
  };

  getUrl = () => {
    const url = this.sanitize(get(this.data, 'url'));
    if (!size(url)) {
      return null;
    }
    return `URL:${url}${LN}`;
  };

  getNote = () => {
    const note = this.sanitize(get(this.data, 'note'));
    if (!size(note)) {
      return null;
    }
    return `NOTE:${note}${LN}`;
  };
}

export default VCard;
