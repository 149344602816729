import { Badge, NavigationItem } from '@dev-spendesk/grapes';
import React from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

export type NavigationLinkProps = {
  className?: string;
  key: string;
  text: string;
  rightAddon?: React.ReactNode;
  path: string;
  extraMatchingPaths?: string[];
  count?: number | string;
  isAlertBadge?: boolean;
  withDivider?: boolean;
  isActive?: boolean;
  isInDropdown?: boolean;
  onClick?: () => void;
};

const getBadgeVariant = (isAlertBadge: boolean, isLinkActive: boolean) => {
  if (isAlertBadge) {
    return 'alert';
  }

  return isLinkActive ? 'primary' : 'secondary';
};

export const NavigationLink = ({
  className,
  text,
  path,
  extraMatchingPaths = [],
  count,
  rightAddon,
  isAlertBadge = false,
  withDivider = false,
  isActive,
  isInDropdown = false,
  onClick,
  ...rest
}: NavigationLinkProps) => {
  const { pathname: currentPath } = useLocation();
  // if path contains query params, we ignore them to make sure we're matching the proper paths
  const pathToMatch = path.match(/^(.+)\?/)?.[1] ?? path;
  const isLinkActive = Boolean(
    matchPath(currentPath, {
      path: [pathToMatch].concat(extraMatchingPaths),
    }),
  );

  return (
    <NavigationItem
      {...rest}
      variant={isInDropdown ? 'subNavigation' : undefined}
      className={className}
      text={text}
      rightAddon={
        (rightAddon || count !== undefined) && (
          <>
            {rightAddon}
            {count !== undefined && (
              <Badge variant={getBadgeVariant(isAlertBadge, isLinkActive)}>
                {count}
              </Badge>
            )}
          </>
        )
      }
      component={NavLink}
      to={path}
      isActive={isActive ?? isLinkActive}
      withDivider={withDivider}
      onClick={onClick}
    />
  );
};
