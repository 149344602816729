import { Tab, TabList, Tabs } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUsersCountLimitFeatures } from 'modules/members/hooks/useUsersCountLimitFeatures';

import style from './MembersPaginated.module.css';
import { MembersPaginatedTableContainer } from '../../containers/MembersPaginatedTableContainer';
import { MembersBulkOnboardingModal } from '../MembersBulkOnboardingModal';
import { MembersTableHeader } from '../MembersTableHeader/MembersTableHeader';

type Props = {
  onInviteClick(): void;
  onMemberClick(member: { id: string }): void;
};

type TabState = 'active' | 'pending';

export const MembersPaginated = ({ onInviteClick, onMemberClick }: Props) => {
  const { t } = useTranslation('global');
  const hasUsersCountLimitFeature = useUsersCountLimitFeatures();

  const [searchPattern, setSearchPattern] = useState('');
  const [selectedTab, setSelectedTab] = useState<TabState>('active');

  const hasRemainingSeats = !hasUsersCountLimitFeature; // TODO: check the total amount of members with useRemainingSeats

  return (
    <>
      <MembersBulkOnboardingModal />
      <MembersTableHeader
        canInviteMembers={hasRemainingSeats}
        searchPattern={searchPattern}
        setSearchPattern={debounce(setSearchPattern, 500)}
        onInviteClick={onInviteClick}
        selectedMembersCount={0}
        onEditMembersClick={() => {}}
      />
      <Tabs
        tabIndex={selectedTab === 'active' ? 0 : 1}
        onChange={(id) => {
          setSelectedTab(id === 0 ? 'active' : 'pending');
        }}
      >
        <TabList
          className={classNames(
            '-mb-[1px] rounded-t-xs border-[1px] border-b-0 border-solid border-neutral-light bg-white pl-xxs',
            style.tabList,
          )}
        >
          <Tab className="gap-xs">{t('members.table.active')}</Tab>
          <Tab className="gap-xs">{t('members.table.pending')}</Tab>
        </TabList>
        <MembersPaginatedTableContainer
          selectedTab={selectedTab}
          onMemberClick={onMemberClick}
          searchPattern={searchPattern}
        />
      </Tabs>
    </>
  );
};
