import assignIn from 'lodash/assignIn';
import compact from 'lodash/compact';
import difference from 'lodash/difference';
import get from 'lodash/get';
import keys from 'lodash/keys';
import map from 'lodash/map';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';

import * as customFieldsTypes from 'src/core/actionTypes/customFields';
import * as groupsTypes from 'src/core/actionTypes/groups';
import * as notifTypes from 'src/core/modules/company/notifications-legacy/redux/actionTypes';
import { smartMerge } from 'src/core/utils/array';

import * as types from './actionTypes';

const initialState = {
  bulkSelectedPaymentIds: [],
  bulkSelectedMonths: [],
  bulkReceiptInvalidPayments: [],

  allPayments: [],
  allPaymentsById: {},
  allPaymentsInvoicesById: {},
  allPaymentsSelection: {
    all: false,
    include: [],
    exclude: [],
  },
  allPaymentsBulkActions: {},
  allPaymentsOpenedPayment: null,

  paymentsToExportCurrentlyOpen: null,
  paymentsToExportCurrentlyOpenInvoices: null,

  paymentsToRemind: {},

  exportedPayments: null,

  accountingStats: null,
  customFields: null,
  groups: null,
  notifications: null,
  connectors: null,

  expenses: null,

  firstUserPaymentId: null,
  lastLoadingPaymentId: null,

  behavior: {
    loader: true,
    error: '',
  },
  isPanelInvoicesLoading: false,
  isExportLoading: false,
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case notifTypes.FETCH_NOTIFICATIONS_REQUEST:
      return assignIn({}, state, {
        behavior: { ...state.behavior, loader: true },
      });

    case notifTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return assignIn({}, state, {
        notifications: action.payload,
        behavior: { ...state.behavior, loader: false },
      });

    case customFieldsTypes.FETCH_CF_SUCCESS:
      return assignIn({}, state, { customFields: action.payload.customFields });

    case groupsTypes.FETCH_GROUPS_SUCCESS:
      return assignIn({}, state, { groups: action.payload.groups });

    case types.UPDATE_FILTERS:
      return assignIn({}, state, { filters: action.payload });

    case types.HIDE_PAYMENT_PANEL:
      return assignIn({}, state, { bulkEditPayments: null });

    case types.RESET_ALL_PAYMENTS:
      return assignIn({}, state, {
        expenses: null,
        exportedPayments: null,
        allPayments: [],
        bulkSelectedMonths: [],
        bulkSelectedPaymentIds: [],
        bulkReceiptInvalidPayments: [],
      });

    case types.RESET_ALL_PAYMENTS_SELECTION:
      return assignIn({}, state, {
        allPaymentsSelection: initialState.allPaymentsSelection,
        allPaymentsCounters: null,
      });

    case types.UPDATE_ALL_PAYMENTS_SELECTION: {
      const selection = action.payload;
      let { all, include, exclude } = state.allPaymentsSelection;

      if ('all' in selection && !selection.all) {
        return assignIn({}, state, {
          allPaymentsSelection: { all: false, include: [], exclude: [] },
        });
      }

      if (selection?.all) {
        all = !!selection.all;
        include = [];
        exclude = [];
      } else {
        include = compact(
          difference(
            (include ?? []).concat(selection.include),
            selection.exclude,
          ),
        );
        exclude = compact(
          difference(
            (exclude ?? []).concat(selection.exclude),
            selection.include,
          ),
        );
      }

      return assignIn({}, state, {
        allPaymentsSelection: { all, include, exclude },
      });
    }

    case types.REMIND_INVOICES_LOADING:
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          remindInvoices: {
            processing: true,
          },
        },
      });

    case types.REMIND_INVOICES_SUCCESS: {
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          remindInvoices: {
            result: action.payload,
            processing: false,
            error: null,
          },
        },
      });
    }

    // end

    case types.DOWNLOAD_PAYMENTS_LOADING:
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          download: {
            processing: true,
          },
        },
      });

    case types.DOWNLOAD_PAYMENTS_SUCCESS: {
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          download: {
            processing: false,
            error: null,
          },
        },
      });
    }

    case types.BULK_EDIT_PAYMENTS_LOADING:
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          edit: {
            processing: true,
          },
        },
      });

    case types.BULK_EDIT_PAYMENTS_SUCCESS: {
      const bulkEditPayments = map(action.payload.editable.edges, 'node');
      return assignIn({}, state, {
        bulkEditPayments,
        allPaymentsBulkActions: {
          edit: {
            processing: false,
            error: null,
          },
        },
      });
    }

    case types.BULK_EDIT_PAYMENTS_FAILURE: {
      return assignIn({}, state, {
        allPaymentsBulkActions: {
          edit: {
            processing: false,
            error: true,
          },
        },
      });
    }

    case types.RESET_BULK_EDIT_PAYMENTS: {
      return assignIn({}, state, { bulkEditPayments: null });
    }

    case types.MARK_PAYMENTS_MISSING_RECEIPT_LOADING: {
      return {
        ...state,
        allPaymentsBulkActions: {
          ...state.allPaymentsBulkActions,
          markMissingReceipt: {
            processing: true,
          },
        },
      };
    }

    case types.MARK_PAYMENTS_MISSING_RECEIPT_FINISH: {
      return {
        ...state,
        allPaymentsBulkActions: {
          ...state.allPaymentsBulkActions,
          markMissingReceipt: {
            processing: false,
          },
        },
      };
    }

    case types.FETCH_ALL_PAYMENTS_COUNTERS_SUCCESS: {
      const allPaymentsCounters = reduce(
        keys(action.payload),
        (result, key) => ({
          ...result,
          [key]: action.payload[key].total,
        }),
        {},
      );
      return assignIn({}, state, { allPaymentsCounters });
    }

    case types.FETCH_ALL_PAYMENTS_SUCCESS:
      return assignIn({}, state, {
        ...action.payload,
        behavior: { ...state.behavior, loader: false },
        allPayments: smartMerge(
          state.allPayments,
          map(action.payload.payments.edges, 'node'),
          'databaseId',
        ),
        allPaymentsPageInfo: action.payload.payments.pageInfo,
        allPaymentStats: action.payload.payments.stats_by_month,
      });

    case types.FETCH_ALL_PAYMENTS_FAILURE:
      return assignIn({}, state, {
        errors: action.payload,
        behavior: { ...state.behavior, loader: false },
      });

    case types.FETCH_ALL_PAYMENTS_LOADING:
      return assignIn({}, state, {
        behavior: { ...state.behavior, loader: true },
      });

    case types.FETCH_USER_PAYMENTS_TO_REMIND_SUCCESS:
      return assignIn({}, state, {
        paymentsToRemind: action.payload.paymentsToRemind,
      });

    case types.SET_PAYMENTS_ALL_OPENED_PAYMENT:
      return assignIn({}, state, {
        allPaymentsOpenedPayment: action.payload,
      });

    case types.FETCH_SINGLE_PAYMENT_SUCCESS: {
      const paymentId = action.payload.id;
      const updatedPayment = action.payload;
      const updatedPayments = map(state.allPayments, (payment) => {
        // payment (from GraphQL) and updatedPayment (from REST) haven't the same
        // shape. To avoid any data breaking we only update specific attributes.
        // FIXME: we should reshape both payment entities into a common shape
        return payment.databaseId === paymentId
          ? {
              ...payment,
              completionDeadline: updatedPayment.completion_deadline,
              invoice_lost: updatedPayment.invoice_lost,
              missingReceipt: updatedPayment.missingReceipt,
            }
          : payment;
      });

      return {
        ...state,
        allPayments: updatedPayments,
        allPaymentsById: {
          ...state.allPaymentsById,
          [paymentId]: updatedPayment,
        },
        lastLoadingPaymentId:
          paymentId === state.lastLoadingPaymentId
            ? null
            : state.lastLoadingPaymentId,
      };
    }

    case types.FETCH_SINGLE_PAYMENT_LOADING: {
      return assignIn({}, state, {
        errors: false,
        lastLoadingPaymentId: action.payload,
      });
    }

    case types.FETCH_SINGLE_PAYMENT_FAILURE:
      return assignIn({}, state, {
        allPaymentsOpenedPayment: null,
        errors: 'Cannot fetch',
        lastLoadingPaymentId:
          action.payload === state.lastLoadingPaymentId
            ? null
            : state.lastLoadingPaymentId,
      });

    case types.UPDATE_SINGLE_PAYMENT_LOCALLY: {
      // Try to update one of the already-fetched payments with its new values,
      // as well as the currently opened payment
      const paymentToUpdate = action.payload;
      const allPaymentsOpenedPayment =
        get(action.payload, 'id') === state.allPaymentsOpenedPayment
          ? paymentToUpdate.id
          : state.allPaymentsOpenedPayment;

      const updatedPayments = map(state.allPayments, (p) =>
        p.databaseId === paymentToUpdate.id
          ? merge({}, p, omit(paymentToUpdate, 'id'), {
              user: {
                full_name:
                  paymentToUpdate.fullname || paymentToUpdate.full_name,
              },
            })
          : p,
      );

      return assignIn({}, state, {
        allPayments: updatedPayments,
        allPaymentsOpenedPayment,
        allPaymentsById: {
          ...state.allPaymentsById,
          [paymentToUpdate.id]: paymentToUpdate,
        },
      });
    }

    case types.FETCH_SINGLE_PAYMENT_INVOICES_SUCCESS:
      return assignIn({}, state, {
        allPaymentsInvoicesById: reduce(
          action.payload.invoices,
          (nextAllPaymentsInvoicesById, invoice) => ({
            ...nextAllPaymentsInvoicesById,
            // Append the current invoice to the accumulator
            [invoice.id]: {
              ...invoice,
              // Attach the payment ID to the invoice
              paymentId: action.payload.paymentId,
            },
          }),
          // Use previous state value as initial value of accumulator
          state.allPaymentsInvoicesById,
        ),
        isPanelInvoicesLoading: false,
      });
    case types.FETCH_SINGLE_PAYMENT_INVOICES_LOADING:
      return {
        ...state,
        isPanelInvoicesLoading: true,
      };

    case types.FETCH_SINGLE_PAYMENT_TO_EXPORT_INVOICES_SUCCESS:
      return assignIn({}, state, {
        paymentsToExportCurrentlyOpenInvoices: action.payload,
      });

    case types.DELETE_INVOICE_SUCCESS: {
      const newInvoices = state.paymentsToExportCurrentlyOpenInvoices
        ? reject(state.paymentsToExportCurrentlyOpenInvoices, {
            id: action.payload.id,
          })
        : null;

      const {
        [action.payload.id]: deleted, // FIXME: this resolves to `undefined`
        ...newInvoicesAllPayments
      } = state.allPaymentsInvoicesById;

      return assignIn({}, state, {
        paymentsToExportCurrentlyOpenInvoices: newInvoices,
        allPaymentsInvoicesById: newInvoicesAllPayments,
      });
    }

    // FIXME: we should do this in an action that uploads the invoices
    case types.INCREMENT_PAYMENT_INVOICES: {
      const updatedPayments = map(state.allPayments, (p) => {
        if (p.databaseId !== action.payload.id) {
          return p;
        }

        return {
          ...p,
          invoices: {
            ...p.invoices,
            total: get(p, 'invoices.total', 0) + 1,
          },
        };
      });

      return assignIn({}, state, {
        allPayments: updatedPayments,
      });
    }

    case types.SET_FIRST_USER_PAYMENT_ID: {
      return {
        ...state,
        firstUserPaymentId: action.payload,
      };
    }

    case types.DELETE_DOCUMENTARY_EVIDENCE_SUCCESS: {
      const { paymentId, documentaryEvidenceId } = action.payload;
      const previousPayment = state.allPaymentsById[paymentId];
      const isAffidavitProof =
        previousPayment.missingReceipt?.affidavit?.id === documentaryEvidenceId;
      const partialPaymentUpdate = {
        invoice_lost: isAffidavitProof ? false : previousPayment.invoice_lost,
        missingReceipt: isAffidavitProof
          ? undefined
          : previousPayment.missingReceipt,
      };
      const updatedPayments = map(state.allPayments, (payment) => {
        return payment.databaseId === paymentId
          ? { ...payment, ...partialPaymentUpdate }
          : payment;
      });

      // FIXME: we need to handle invoice deletion (receipt documentary evidence)
      return {
        ...state,
        allPayments: updatedPayments,
        allPaymentsById: {
          ...state.allPaymentsById,
          [paymentId]: { ...previousPayment, ...partialPaymentUpdate },
        },
      };
    }

    default:
      return state;
  }
};

export default paymentsReducer;
