import { type MonetaryValue, equal, add } from 'ezmoney';

import { type Payable } from '../../prepare-payables/models';

export type PayableType =
  | 'cardPurchase'
  | 'invoicePurchase'
  | 'claimedBill'
  | 'mileageAllowance'
  | 'perDiemAllowance'
  | 'reversal';

export type PayableState =
  | 'created'
  | 'prepared'
  | 'notBookkept'
  | 'unprepared'
  | 'discarded'
  | 'toAccountingPending'
  | 'toAccountingFailed'
  | 'inAccounting'
  | 'inAccountingManually';

export const getIsPayableFullyPaid = (payable: {
  grossAmount: MonetaryValue;
  allocations: {
    amount: MonetaryValue;
  }[];
}): boolean => {
  const allocationAmountsSum = payable.allocations.reduce(
    (sum, allocation) => add(sum, allocation.amount),
    { currency: payable.grossAmount.currency, amount: 0, precision: 0 },
  );
  return equal(payable.grossAmount, allocationAmountsSum);
};

export const getIsPayablePrepared = (payable: {
  state: PayableState;
}): boolean => {
  return payable.state !== 'unprepared' && payable.state !== 'created';
};

export const getIsPayableExported = (payable: {
  state: PayableState;
}): boolean => {
  return (
    payable.state === 'inAccounting' || payable.state === 'inAccountingManually'
  );
};

export const getIsPayableNotBookkept = (payable: {
  state: PayableState;
}): boolean => {
  return payable.state === 'notBookkept';
};

export const isSpanishDPRPayable = (
  payable: Pick<Payable, 'documentaryEvidence'>,
): boolean => !!payable?.documentaryEvidence?.compliances?.spainEreceipt;
