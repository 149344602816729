import React from 'react';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useAccessibleCardsLinks } from 'src/core/pages/CardsPage/CardsPage';

import { DisclosureItem } from '../DisclosureItem/DisclosureItem';
import { SubNavLink } from '../SubNavItem/SubNavItem';

export const MyCompanyLink = () => {
  const { t } = useTranslation('global');
  const company = useCompany();

  const hasAccessToWallet = useFeature(FEATURES.WALLET_ACCESS);
  const isCostCentersFeatureEnabled = useFeature(FEATURES.COST_CENTERS);
  const isCostCentersSettingsEnabled = useFeature(
    FEATURES.SETTINGS_COST_CENTERS,
  );

  const cardsLinks = useAccessibleCardsLinks();

  const canAccessWallet = hasAccessToWallet;
  const canAccessMembers = !company.churning_at;

  const canAccessCostCenters =
    isCostCentersFeatureEnabled && isCostCentersSettingsEnabled;

  return (
    <DisclosureItem summary={t('navigation.myCompany')} iconName="building">
      {canAccessWallet && (
        <li>
          <SubNavLink
            href={routeFor(routes.COMPANY_BANK.path, {
              company: company.id,
            })}
          >
            {t('navigation.companyWallet')}
          </SubNavLink>
        </li>
      )}
      {(canAccessMembers || canAccessCostCenters) && (
        <li>
          <SubNavLink
            activePaths={[
              routes.COMPANY_MEMBERS.path,
              routes.COMPANY_TEAMS.path,
              routes.COST_CENTERS.path,
            ]}
            href={routeFor(routes.COMPANY_MEMBERS.path, {
              company: company.id,
            })}
          >
            {t('navigation.organisation')}
          </SubNavLink>
        </li>
      )}
      {cardsLinks.length > 0 && (
        <li>
          <SubNavLink
            href={routeFor(cardsLinks[0].path, {
              company: company.id,
            })}
            activePaths={[
              routes.CARDS.path,
              routes.SUBSCRIPTIONS.path,
              routes.CARD.path,
            ]}
          >
            {t('misc.card_plural')}
          </SubNavLink>
        </li>
      )}
      <li>
        <SubNavLink
          href={routeFor(routes.COMPANY_POLICIES.path, {
            company: company.id,
          })}
          activePaths={[
            routes.COMPANY_POLICIES.path,
            routes.COMPANY_CONTROL_RULES.path,
            routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path,
            routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path,
          ]}
        >
          {t('navigation.companyRules')}
        </SubNavLink>
      </li>
    </DisclosureItem>
  );
};
