import {
  Tag,
  Button,
  Callout,
  Popover,
  type TriggerProps,
} from '@dev-spendesk/grapes';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import values from 'lodash/values';
import React, { type ReactNode, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { type Company } from 'modules/app/hooks/useCompany';
import { type User } from 'modules/app/hooks/useUser';
import { useHasAccessToKycStateRelatedFeatures } from 'modules/kyc/hooks/useHasAccessToKycStateRelatedFeatures';
import { routes, routeFor } from 'src/core/constants/routes';
import { type BankingProvider } from 'src/core/reducers/global';
import { formatMoney } from 'src/core/utils/money';
import {
  NO_RELOAD,
  HARD_RELOAD,
  type getReloadType,
} from 'src/core/utils/wallet';

import { CompanySwitcherNewBranchButton } from './CompanySwitcherNewBranchButton';
import styles from './CompanySwitcherPopover.module.css';
import { NewBranch } from '../NewBranch';
import OnboardingModal from '../NewBranch/modals/OnboardingModal';

type Props = {
  user: User;
  company: Company;
  reloadType: ReturnType<typeof getReloadType>;
  showWallet: boolean;
  children: (triggerProps: TriggerProps, isOpen: boolean) => ReactNode;
  className?: string;
};

export const CompanySwitcherPopover = ({
  user,
  company,
  reloadType,
  showWallet,
  children,
  className,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const [showNewBranchFlow, setShowNewBranchFlow] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<{
    id: string;
    bankingProvider: BankingProvider;
  } | null>(null);
  const hasAccessToKycStateRelatedFeatures =
    useHasAccessToKycStateRelatedFeatures();

  const getReloadMessage = () => {
    const message =
      reloadType === HARD_RELOAD
        ? t('wallet.warningReloadTip')
        : t('wallet.warningSoftReloadTip');
    const flashType = reloadType === HARD_RELOAD ? 'alert' : 'warning';

    return (
      reloadType !== NO_RELOAD && (
        <Callout variant={flashType} title={message} className="mb-s" />
      )
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.CompanySwitcherMenu__header}>
        <span className={styles.CompanySwitcherMenu__headerName}>
          {company.name}
        </span>
        {showWallet && company.type !== 'branch_expense_entity' && (
          <span>
            {formatMoney(
              company.balance_available_all_accounts,
              company.currency,
            )}
          </span>
        )}
      </div>
    );
  };

  const renderContent = (close: () => void) => {
    if (!showWallet || !hasAccessToKycStateRelatedFeatures) {
      return null;
    }

    return (
      <div className={styles.CompanySwitcherMenu__wallet}>
        {getReloadMessage()}
        <ul className="flex flex-col">
          {!isNil(company.balance_loaded_cards) && (
            <li className={styles.CompanySwitcherMenu__walletItem}>
              <span className={styles.CompanySwitcherMenu__walletItem__label}>
                {t('wallet.gauges.loadedOnCards')}
              </span>
              <span className={styles.CompanySwitcherMenu__walletItem__amount}>
                {formatMoney(company.balance_loaded_cards, company.currency)}
              </span>
            </li>
          )}
          {!isNil(company.balance_pending_cards) && (
            <li className={styles.CompanySwitcherMenu__walletItem}>
              <span className={styles.CompanySwitcherMenu__walletItem__label}>
                {t('wallet.gauges.pending')}
              </span>
              <span className={styles.CompanySwitcherMenu__walletItem__amount}>
                {formatMoney(company.balance_pending_cards, company.currency)}
              </span>
            </li>
          )}
          {!isNil(company.balance_available_all_accounts) && (
            <li className={styles.CompanySwitcherMenu__walletItem}>
              <span className={styles.CompanySwitcherMenu__walletItem__label}>
                {t('wallet.gauges.available')}
              </span>
              <span className={styles.CompanySwitcherMenu__walletItem__amount}>
                {formatMoney(
                  company.balance_available_all_accounts,
                  company.currency,
                )}
              </span>
            </li>
          )}
        </ul>
        <Button
          fit="parent"
          variant="primary"
          text={t('wallet.walletDetailsLink')}
          data-toggle="dropdown"
          onClick={() => {
            history.push(
              routeFor(routes.COMPANY_BANK.path, {
                company: company.id,
              }),
            );
            close();
          }}
        />
      </div>
    );
  };

  const renderCompanyButton = (
    companyToRenderButtonOf: Company,
    close: () => void,
  ) => {
    const hasAccessHappened = companyToRenderButtonOf.has_access_happened;
    const showPendingTag =
      !hasAccessHappened &&
      user.is_organisation_owner &&
      companyToRenderButtonOf.kyb_version === 2;

    return (
      <button
        key={companyToRenderButtonOf.id}
        type="button"
        className={styles.CompanySwitcherMenu__company}
        onClick={() => {
          if (!hasAccessHappened && companyToRenderButtonOf.kyb_version === 2) {
            setShowOnboardingModal(true);
            setSelectedCompany({
              id: companyToRenderButtonOf.id,
              bankingProvider: companyToRenderButtonOf.banking_provider,
            });
          } else {
            window.location.href = `/app/${companyToRenderButtonOf.id}`;
          }
          close();
        }}
      >
        <span>{companyToRenderButtonOf.name}</span>
        {hasAccessHappened &&
          showWallet &&
          !isNil(companyToRenderButtonOf.balance_available_all_accounts) && (
            <span>
              {formatMoney(
                companyToRenderButtonOf.balance_available_all_accounts,
                companyToRenderButtonOf.currency,
              )}
            </span>
          )}
        {showPendingTag && (
          <Tag variant="warning">{t('wallet.warningStepsRemaining')}</Tag>
        )}
      </button>
    );
  };

  const renderCompanies = (close: () => void) => {
    // eslint-disable-next-line lodash/matches-shorthand
    const companies = reject(values(user.data_by_company), { id: company.id });
    companies.sort((c1, c2) => c1.name.localeCompare(c2.name));
    return (
      <div className={styles.CompanySwitcherMenu__companies}>
        {companies.map((c) => renderCompanyButton(c, close))}
      </div>
    );
  };

  // Used to resume company branch creation
  const renderOnboardingModal = (close: () => void) => {
    return (
      showOnboardingModal &&
      selectedCompany && (
        <OnboardingModal
          companyId={selectedCompany.id}
          bankingProvider={selectedCompany.bankingProvider}
          onClose={() => {
            setShowOnboardingModal(false);
            setSelectedCompany(null);
            close();
          }}
        />
      )
    );
  };

  return (
    <>
      <Popover renderTrigger={children} className={className}>
        {(close) => (
          <div className={styles.CompanySwitcherMenu}>
            {renderHeader()}
            {renderContent(close)}
            {renderCompanies(close)}
            <CompanySwitcherNewBranchButton
              handleClick={() => {
                setShowNewBranchFlow(true);
                close();
              }}
            />
            {renderOnboardingModal(close)}
          </div>
        )}
      </Popover>
      {showNewBranchFlow && (
        <NewBranch onClose={() => setShowNewBranchFlow(false)} />
      )}
    </>
  );
};
