import React from 'react';

export const EntityGenericStatisticsBlock = ({
  title,
  contents,
  stack = false,
}: {
  title: string;
  contents: React.ReactNode[];
  stack?: boolean;
}) => (
  <div
    className={`box-border flex h-full w-full flex-col ${stack ? 'flex-start' : 'justify-between'} gap-xxs`}
  >
    <div className="text-neutral-dark">{title.toUpperCase()}</div>
    {React.Children.map(contents, (content) => (
      <div key={content?.toString()}>{content}</div>
    ))}
  </div>
);
