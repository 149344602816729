import { ListItem, ListView, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';
import { useSelector } from 'react-redux';

import { ErrorState } from 'common/components/ErrorState';
import { useTranslation } from 'common/hooks/useTranslation';
// TODO: get notifications from back-end
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { defaultNotifications } from 'src/core/config/notifications';
import type Features from 'src/core/constants/features';
import { getConfig } from 'src/core/selectors/globalSelectors';

import { UserNotifications } from './UserNotifications';
import styles from './UserNotifications.module.css';
import { useGetNotificationsQuery } from '../../hooks/useGetNotificationsQuery';
import {
  type UserNotification,
  type UserNotificationCategory,
} from '../../models';

const TABLE_LOADER_ROWS = 4;

type Props = {
  category: UserNotificationCategory;
};

function getUserNotificationsTitle(category: UserNotificationCategory): string {
  switch (category) {
    case 'General':
      return 'notifications.generalTitle';
    case 'Control':
      return 'notifications.controlTitle';
    case 'Usage':
      return 'notifications.usageTitle';
    default:
      throw new Error(`User notifications category ${category} is unknown`);
  }
}

function getUserNotificationsDescription(
  category: UserNotificationCategory,
): string {
  switch (category) {
    case 'General':
      return 'notifications.generalCompanySubtitle';
    case 'Control':
      return 'notifications.controlCompanySubtitle';
    case 'Usage':
      return 'notifications.usageCompanySubtitle';
    default:
      throw new Error(`User notifications category ${category} is unknown`);
  }
}

// TODO: get notifications from back-end
function getUserNotifications(
  category: UserNotificationCategory,
  roles: {
    isAccountOwner: boolean;
    isAdmin: boolean;
    isController: boolean;
    isRequester: boolean;
  },
  notifications: UserNotification[],
  companyFeatures: Record<Features, boolean>,
): UserNotification[] {
  return Object.values(defaultNotifications)
    .filter(
      (notification) =>
        notification?.category === category &&
        ((roles.isAccountOwner && notification.userShow.includes('owner')) ||
          (roles.isAdmin && notification.userShow.includes('admin')) ||
          (roles.isController &&
            notification.userShow.includes('controller')) ||
          (roles.isRequester && notification.userShow.includes('requester'))) &&
        (notification.featuresShow
          ? notification.featuresShow.every(
              (feature: Features) => companyFeatures[feature],
            )
          : true),
    )
    .map((notification) => {
      const apiNotification = notifications.find(
        (n) => n.notifCode === notification.notif_code,
      );
      return {
        title: notification.title,
        user: notification.user,
        checked: apiNotification?.checked ?? notification.checked ?? false,
        notifCode: notification.notif_code,
        value: apiNotification?.value ?? notification.value ?? null,
      };
    });
}

export const UserNotificationsContainer = ({ category }: Props) => {
  const { t } = useTranslation();
  const config = useSelector(getConfig);
  const { features } = config;

  const getNotificationsQuery = useGetNotificationsQuery();
  const userRoles = useUserRoles();

  if (category === 'Control' && !userRoles.isController) {
    return null;
  }

  if (getNotificationsQuery.status === 'error') {
    return (
      <ErrorState
        className="ml-xxl justify-start"
        title={t('notifications.loadErrorTitle')}
        description={t('notifications.loadErrorDescription')}
      />
    );
  }

  return (
    <section
      aria-labelledby={`user-notifications-${category}`}
      className="UserNotificationsPage__section"
    >
      <h1
        id={`user-notifications-${category}`}
        className="text-complementary title-xl"
      >
        {t(getUserNotificationsTitle(category))}
      </h1>
      <p className="text-neutral-dark body-m">
        {t(getUserNotificationsDescription(category))}
      </p>
      <ListView className="mt-m">
        {getNotificationsQuery.status === 'loading' &&
          Array.from({ length: TABLE_LOADER_ROWS }, (_, key) => (
            <ListItem key={key}>
              <div className={styles.loaderContainer}>
                <SkeletonText width="20%" size="l" />
                <SkeletonText className="mt-xs" width="70%" size="s" />
              </div>
            </ListItem>
          ))}
        {getNotificationsQuery.status === 'success' && (
          <UserNotifications
            userNotifications={getUserNotifications(
              category,
              userRoles,
              getNotificationsQuery.data,
              features,
            )}
          />
        )}
      </ListView>
    </section>
  );
};
