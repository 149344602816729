import isEmpty from 'lodash/isEmpty';

import { ExpenseType } from '../../types';
import {
  isUserCounterpartyExpense,
  isSupplierCounterpartyExpense,
} from '../../utils';

/**
 * Determines if the team field of an expense should be displayed or not in
 * the UI.
 *
 * It should be displayed if the team value is not empty (of course) or if there
 * are available team values to be choosen. This way, companies without teams
 * won't see the field, unless the expense has a team value (ie. they had teams
 * before) and because it's mandatory if the company has teams, we will show
 * it regardless if it has a value or not.
 *
 * @param {*} teamValue The value of the `team` field of the expense.
 * @param {Array} availableTeams An array of available team values.
 * @returns {Boolean}
 */
export const shouldDisplayTeam = (teamValue, availableTeams) =>
  teamValue || !isEmpty(availableTeams);

export const isMissingEmployeeAccount = (expense) => {
  const hasUserCounterparty = isUserCounterpartyExpense(expense);
  return (
    hasUserCounterparty &&
    !expense.employeeAccount &&
    // Do not show warning if account payable automation is active (BK 2.0)
    !expense.automation?.accountPayable?.isActive
  );
};

export const isMissingSupplierAccount = (expense) => {
  return isSupplierCounterpartyExpense(expense) && !expense.supplierAccount;
};

export const isSupplierAccountMandatory = (expense) => {
  return expense.type === ExpenseType.invoicePurchase;
};
