import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { useSFSMigration } from 'src/sfs-migration/used-in-js-app/hooks/useSFSMigration';

import { MissingFundsWarningCallout } from './MissingFundsWarningCallout';
import { RunningCardsWarningCallout } from './RunningCardsWarningCallout';
import { useSecondaryAccountWalletSummary } from '../hooks/main-app/use-secondary-account-wallet-summary';

export const LegacyWalletCallout = ({ companyId }: { companyId: string }) => {
  const hasTMPSFSMigrationPage = useFeature(FEATURES.TMP_SFS_MIGRATION_PAGE);

  const sfsMigrationQueryState = useSFSMigration({
    isEnabled: hasTMPSFSMigrationPage,
  });

  const sfsMigration = unwrapQuery(sfsMigrationQueryState);

  const legacyWalletSummaryQueryState = useSecondaryAccountWalletSummary({
    isEnabled: sfsMigration?.status === 'InProgress',
  });

  const legacyWalletSummary = unwrapQuery(legacyWalletSummaryQueryState);

  if (!legacyWalletSummary) {
    return null;
  }

  return (
    <>
      <MissingFundsWarningCallout
        legacyWalletSummary={legacyWalletSummary}
        companyId={companyId}
      />
      <RunningCardsWarningCallout
        legacyWalletSummary={legacyWalletSummary}
        companyId={companyId}
      />
    </>
  );
};
