import { Input } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { OrganisationReportingEntityListSearch } from './OrganisationReportingEntityListSearch';
import { type OrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import { type OrganisationReportingEntity } from '../../../types';
import { OrganisationReportingEntityStatistics } from '../OrganisationReportingEntityStatistics';

export const OrganisationReportingEntityList = ({
  features,
  entities,
  onDataLoaded,
}: {
  features: OrganisationFeatures;
  entities: OrganisationReportingEntity[];
  onDataLoaded: () => void;
}) => {
  const { t } = useTranslation('global');

  const [isSearchInputDisabled, setIsSearchInputDisabled] = useState(true);
  const [queryStates, setQueryStates] = useState<
    { entityId: string; status: QueryState['status'] }[]
  >([]);

  const entityIds = entities.map(({ id }) => id);
  useEffect(() => {
    const hasAllQueriesSucceeded = entityIds.every((id) =>
      queryStates.find(({ entityId }) => id === entityId),
    );

    if (hasAllQueriesSucceeded) {
      setIsSearchInputDisabled(false);
      onDataLoaded();
      return () => {};
    }
  }, [queryStates]);

  return (
    <OrganisationReportingEntityListSearch
      entities={entities}
      searchableFields={['id', 'name']}
    >
      {({ filteredEntities, search, setSearch }) => (
        <div className="flex flex-col gap-s">
          <div className="flex flex-row justify-between gap-l">
            <h2 className="title-xl">
              {t('organisation.reporting.page.entities.title')}
            </h2>

            <div>
              <Input
                isDisabled={isSearchInputDisabled}
                className="min-w-[312px]"
                placeholder={t(
                  'organisation.reporting.page.entities.searchPlaceholder',
                )}
                onChange={(event) => setSearch(event.target.value)}
                value={search}
              />
            </div>
          </div>

          {filteredEntities.map((entity) => (
            <OrganisationReportingEntityStatistics
              key={entity.id}
              entity={entity}
              features={features}
              onDataLoaded={(entityId, status) =>
                setQueryStates((previousQueryStates) => [
                  ...previousQueryStates,
                  { entityId, status },
                ])
              }
            />
          ))}
        </div>
      )}
    </OrganisationReportingEntityListSearch>
  );
};
