import { z } from 'zod';

import { addressSchema } from './types/address';
import { userSchema } from './types/user';

export const getPhysicalCardsMigrationSchema = z.array(
  z
    .object({
      user: userSchema,
    })
    .merge(
      z.object({
        card: z.object({
          id: z.string(),
          accountId: z.string(),
          cardStatus: z.string(),
          cardName: z.string(),
        }),
        recardOrder: z.object({
          id: z.string(),
          state: z.string(),
          shippingAddress: addressSchema,
          userId: z.string(),
          bkbCardId: z.string(),
          sfsCardId: z.string(),
          trackingLink: z.string().nullable(),
          trackingNumber: z.string().nullable(),
        }),
      }),
    ),
);
