import { Button, Modal } from '@dev-spendesk/grapes';
import { isSameDay, startOfToday } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import illustration from '../../assets/ninjaGeorges.png';

type Props = {
  taskCount: number;
  ctaLink: string;
  customerGroup: 'EEA' | 'UK';
  isLate: boolean;
  hasUncompleteTasks: boolean;
};
const modalStorageKey = 'sfs-migration-banner-modal';

export const SFSMigrationBannerModal = ({
  ctaLink,
  isLate,
  hasUncompleteTasks,
  taskCount,
  customerGroup,
}: Props) => {
  const { t } = useTranslation('sfs-migration');
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const [lastShownDate, setLastShownDate] = useLocalStorage<string>(
    modalStorageKey,
    '',
  );
  useEffect(() => {
    if (
      isLate &&
      hasUncompleteTasks &&
      (!lastShownDate || !isSameDay(new Date(lastShownDate), startOfToday()))
    ) {
      /**
       * The modal should be displayed in the following case:
          - The user has a the red banner (meaning user is late)
          - The user has tasks to complete, except if the only tasks to do is KYC “In progress” (to avoid displaying these accelerations when users are waiting for us to review the KYC)
       */
      setIsBannerModalOpen(true);
    }
  }, [isLate, hasUncompleteTasks, lastShownDate]);

  if (!isBannerModalOpen) {
    return null;
  }

  return (
    <Modal
      illustration={<img src={illustration} alt="" className="h-[424px]" />}
      illustrationHeight="424px"
      isOpen={isBannerModalOpen}
      onClose={() => {
        setIsBannerModalOpen(false);
        setLastShownDate(startOfToday().toISOString());
      }}
      title={t('appDesktopBannerModal.title', { count: taskCount })}
      subtitle={
        customerGroup === 'UK'
          ? t('appDesktopBannerModal.subtitleUkCustomerGroup', {
            count: taskCount,
          })
          : t('appDesktopBannerModal.subtitle', { count: taskCount })
      }
      actions={[
        <Button
          key="cancel"
          onClick={() => {
            setIsBannerModalOpen(false);
            setLastShownDate(startOfToday().toISOString());
          }}
          text={t('appDesktopBannerModal.cancel')}
          variant="secondary"
        />,
        <Button
          key="check-my-tasks"
          href={ctaLink}
          component="a"
          onClick={() => {
            setIsBannerModalOpen(false);
            setLastShownDate(startOfToday().toISOString());
          }}
          text={t('appDesktopBannerModal.confirm', { count: taskCount })}
          variant="primary"
        />,
      ]}
    />
  );
};
