import React from 'react';

import { ImageViewerModal, PDFViewerModal } from 'common/components/FileViewer';

export type MediaType =
  | 'application/pdf'
  | 'image/png'
  | 'image/jpeg'
  | 'image/gif';

type FileViewerModalWrapperProps = {
  fileUrl: string;
  fileMediaType: MediaType;
  onModalClose: () => void;
};

export const FileViewerModalWrapper = ({
  fileUrl,
  fileMediaType,
  onModalClose,
}: FileViewerModalWrapperProps) =>
  fileMediaType === 'application/pdf' ? (
    <PDFViewerModal
      url={fileUrl}
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onModalClose}
    />
  ) : (
    <ImageViewerModal
      withoutCrossOrigin
      url={fileUrl}
      withZoom
      withRotate
      onDownload={() => window.open(fileUrl, '_blank')}
      onClose={onModalClose}
    />
  );
