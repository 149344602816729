import { Callout, FormField, SkeletonButton } from '@dev-spendesk/grapes';
import React from 'react';

import { useCustomFieldsQuery } from 'modules/budgets/apis';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense/';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import {
  CustomFieldsFormField,
  type CustomFieldsFormFieldProps,
} from './CustomFieldsFormField';

export type Props = Omit<CustomFieldsFormFieldProps, 'customFields'>;

export const CustomFieldsFormFieldContainer = (props: Props) => {
  const customFieldsQueryState = useCustomFieldsQuery();

  const { t } = useTranslation('global');
  const { fit } = props;

  return (
    <QuerySuspense
      queryState={customFieldsQueryState}
      loading={
        <FormField
          label={t('forms.customField.label')}
          className={props.className}
        >
          <SkeletonButton width={fit === 'parent' ? '100%' : undefined} />
        </FormField>
      }
      fallback={(error) => (
        <FormField
          label={t('forms.customField.label')}
          className={props.className}
        >
          <QueryError
            queryError={error}
            componentType="Callout"
            translations={{}}
          />
        </FormField>
      )}
    >
      {({ customFields }) =>
        customFields.length > 0 ? (
          <CustomFieldsFormField customFields={customFields} {...props} />
        ) : (
          <FormField
            label={t('forms.customField.label')}
            className={props.className}
          >
            <Callout
              variant="info"
              title={t('forms.customField.noneCreated')}
            />
          </FormField>
        )
      }
    </QuerySuspense>
  );
};
