import { z } from 'zod';

import { recardOrderSchema } from './types/recard-order';
import { sfsCardSchema } from './types/sfs-card';

export const getEmployeeSfsCardSchema = z
  .object({
    card: sfsCardSchema
      .pick({
        id: true,
        accountId: true,
        cardStatus: true,
        cardName: true,
      })
      .nullable(),
    recardOrder: recardOrderSchema.nullable(), // whole recardOrder could be null if the AO hasn't interacted yet
  })
  .optional();
