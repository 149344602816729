/* eslint-disable no-nested-ternary */
import { Button, FormField, Modal } from '@dev-spendesk/grapes';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete';
import { PhoneNumberInput } from 'src/core/common/components/PhoneNumberInput';
import { getCountryFromNumber } from 'src/core/common/components/PhoneNumberInput/helpers';
import {
  FormSubmitButton,
  FormTextInput,
  useForm,
} from 'src/core/common/utils/forms';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useNotifications } from 'src/core/modules/app/notifications';
import { type EmployeeSFSCard } from 'src/sfs-migration/routes/[companyId]/overview/hooks/useEmployeeSfsCardQuery';
import { type AllowedCountryCode } from 'src/sfs-migration/routes/[companyId]/renew-cards/components/ModalEditDeliveryAddress';
import {
  allowedShippingCountriesForSfsEeaCardOrders,
  allowedShippingCountriesForSfsUkCardOrders,
} from 'src/ts-rest-contract/src';

import { useInvalidateEmployeeCardQuery } from '../../hooks/useEmployeeCardQuery';
import { useReorderSfsCardMutation } from '../../hooks/useReorderSfsCardMutation';

export type ShippingAddress = NonNullable<
  NonNullable<NonNullable<EmployeeSFSCard>['recardOrder']>['shippingAddress']
>;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shippingAddress: ShippingAddress;
};

type FormValues = {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  zipcode: string;
  country: AllowedCountryCode;
  phoneNumber: string;
};

type FormErrors = {
  addressLine1?: boolean;
  city?: boolean | string;
  zipcode?: boolean | string;
  country?: boolean;
  phoneNumber?: boolean;
};

export const ReorderSFSFormModal = ({
  isOpen,
  onClose,
  shippingAddress,
}: Props) => {
  const { t } = useTranslation('sfs-migration');
  const { dangerNotif, successNotif } = useNotifications();
  const company = useCompany();
  const isUkCompany = company.currency === 'GBP';

  const shippingCountriesList = isUkCompany
    ? allowedShippingCountriesForSfsUkCardOrders
    : allowedShippingCountriesForSfsEeaCardOrders;

  const invalidateEmployeeCardQuery = useInvalidateEmployeeCardQuery();
  const [reorderSfsCardMutation] = useReorderSfsCardMutation();

  const { mobileExt, mobileNo, ...rest } = shippingAddress;

  const form = useForm<FormValues, FormErrors>({
    initialValues: {
      ...rest,
      phoneNumber: `+${mobileExt}${mobileNo}`,
    },
    validate(values) {
      const errors: FormErrors = {};
      if (!values.addressLine1 || values.addressLine1.trim() === '') {
        errors.addressLine1 = true;
      }
      if (!values.city || values.city.trim() === '') {
        errors.city = true;
      }
      if (!values.zipcode || values.zipcode.trim() === '') {
        errors.zipcode = true;
      }
      if (values.zipcode.length > 10) {
        errors.zipcode = t('renewCards.officeAddressModal.zipCodeTooLongError');
      }

      // Check city length
      if (isUkCompany && values.city.length + values.zipcode.length > 39) {
        errors.city = t('renewCards.officeAddressModal.cityTooLongError', {
          maxChar: 39 - values.zipcode.length,
        });
      }

      if (
        !values.country ||
        values.country.trim() === '' ||
        // @ts-expect-error type error because GB is not in the list for EEA.
        !shippingCountriesList.includes(values.country)
      ) {
        errors.country = true;
      }

      try {
        const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
        const isValid = isValidPhoneNumber(parsedPhoneNumber.number);
        if (!isValid) {
          errors.phoneNumber = true;
        }
      } catch {
        errors.phoneNumber = true;
      }
      return errors;
    },
    onSubmit: async (values) => {
      const { phoneNumber, ...address } = values;
      try {
        const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
        await reorderSfsCardMutation({
          address,
          phoneNumber: {
            mobileExt: parsedPhoneNumber.countryCallingCode,
            mobileNo: parsedPhoneNumber.nationalNumber,
          },
        });
        successNotif(t('renewCards.updateCardAddressSuccess'));
        await invalidateEmployeeCardQuery();
        onClose();
      } catch {
        dangerNotif(t('renewCards.updateCardAddressError'));
      }
    },
  });

  return (
    <Modal
      iconName="pen"
      iconVariant="primary"
      isOpen={isOpen}
      title={t('phase-execution.cardShipment.orderFormModalTitle')}
      onClose={onClose}
      actions={
        <>
          <Button
            text={t('renewCards.officeAddressModal.cancel')}
            variant="secondary"
            onClick={onClose}
            isDisabled={form.isSubmitting}
          />
          <FormSubmitButton
            text={t('renewCards.officeAddressModal.save')}
            form={form}
          />
        </>
      }
    >
      <div>
        <form
          className="mx-auto flex w-[500px] flex-col gap-s p-s"
          onSubmit={form.handleSubmit}
        >
          <FormTextInput
            fit="parent"
            label={t('renewCards.officeAddressModal.addressLabel')}
            placeholder={t('renewCards.officeAddressModal.addressPlaceholder')}
            form={form}
            name="addressLine1"
          />
          <FormTextInput
            fit="parent"
            label={t('renewCards.officeAddressModal.addressLabel2')}
            placeholder={t('renewCards.officeAddressModal.addressPlaceholder')}
            form={form}
            name="addressLine2"
            hint={t('renewCards.officeAddressModal.optionalHint')}
          />
          <FormTextInput
            fit="parent"
            label={t('renewCards.officeAddressModal.cityLabel')}
            placeholder={t('renewCards.officeAddressModal.cityPlaceholder')}
            form={form}
            name="city"
            maxLength={isUkCompany ? 39 - form.values.zipcode.length : 40}
          />
          <div className="flex gap-s">
            <div className="flex-1">
              <FormTextInput
                fit="parent"
                label={t('renewCards.officeAddressModal.zipLabel')}
                placeholder={t('renewCards.officeAddressModal.zipPlaceholder')}
                form={form}
                maxLength={10}
                name="zipcode"
              />
            </div>
            <div className="flex-1">
              <FormField
                label={t('renewCards.officeAddressModal.countryLabel')}
              >
                <CountryAutocomplete
                  className="w-full"
                  countryCode={form.values.country}
                  onSelect={(countryCode: AllowedCountryCode) => {
                    form.setFieldValue('country', countryCode);
                  }}
                  countryFilter={(countries) => {
                    return countries.filter((country) => {
                      return shippingCountriesList.includes(
                        // @ts-expect-error country.alpha2 can be not an element of the bankable list
                        country.alpha2,
                      );
                    });
                  }}
                />
              </FormField>
            </div>
          </div>
          <div className="flex">
            <FormField
              className="mb-m w-full"
              label={t(
                'phase-initiation.renewCards.officeAddressModal.phoneNumberLabel',
              )}
              alertMessage={
                form.errors.phoneNumber
                  ? t(
                      'phase-initiation.renewCards.officeAddressModal.phoneNumberError',
                    )
                  : ''
              }
            >
              <PhoneNumberInput
                value={form.values.phoneNumber}
                onChange={(phoneNumber) => {
                  form.setFieldValue('phoneNumber', phoneNumber ?? '');
                }}
                name="phoneNumber"
                placeholder={t(
                  'phase-initiation.renewCards.officeAddressModal.phoneNumberPlaceholder',
                )}
                initialCountry={getCountryFromNumber(form.values.phoneNumber)}
                className="w-full"
              />
            </FormField>
          </div>
        </form>
      </div>
    </Modal>
  );
};
