import { type MonetaryValue } from 'ezmoney';
import React from 'react';
import { useSelector } from 'react-redux';

import { useWalletTransactionForecastQuery } from 'modules/company';
import { getShowWarningWhenNotEnoughFundsForWireTransfer } from 'modules/reimbursements/redux/selectors';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { isAccountOwner as getIsUserAccountOwner } from 'src/core/selectors/users';

import WireTransferModal from './WireTransferModal';

type Props = {
  amount: MonetaryValue;
  count: number;
  isApproving: boolean;
  disableAoAutoApprove?: boolean;
  isSendingToPayment: boolean;
  isOpen: boolean;
  onClose: () => void;
  onApprove: (options?: { onSuccess: () => void }) => void;
  onSendToPayment: (options?: { onSuccess: () => void }) => void;
  onComplete: () => void;
};

export const WireTransferModalContainer = ({
  amount,
  count,
  isApproving,
  disableAoAutoApprove,
  isSendingToPayment,
  isOpen,
  onClose,
  onApprove,
  onSendToPayment,
  onComplete,
}: Props) => {
  const isUserAccountOwner = useSelector(getIsUserAccountOwner);
  // FIXME: this condition should change
  // We don't handle the AO flow yet in expense claims so we use a temporary
  // `disableAoAutoApprove` prop to disable it only for expense claims
  const canApprove = !disableAoAutoApprove && isUserAccountOwner;

  const showWarningWhenNotEnoughFundsForWireTransfer = useSelector(
    getShowWarningWhenNotEnoughFundsForWireTransfer,
  );
  const walletTransactionForecastQuery =
    useWalletTransactionForecastQuery()(amount);

  return (
    <QuerySuspense
      queryState={walletTransactionForecastQuery}
      loading={null}
      fallback={() => (
        // In case of error in the query, we fallback to the 'normal' flow (without not enough funds warning)
        // Since it shouldn't be blocking for the wire transfer flow
        <WireTransferModal
          amount={amount}
          count={count}
          isApproving={isApproving}
          canApprove={canApprove}
          isSendingToPayment={isSendingToPayment}
          isOpen={isOpen}
          showNotEnoughFundsWarning={false}
          onClose={onClose}
          onApprove={onApprove}
          onSendToPayment={onSendToPayment}
          onComplete={onComplete}
        />
      )}
    >
      {({ hasEnoughFunds }) => {
        const showNotEnoughFundsWarning =
          showWarningWhenNotEnoughFundsForWireTransfer && !hasEnoughFunds;

        return (
          <WireTransferModal
            amount={amount}
            count={count}
            isApproving={isApproving}
            canApprove={canApprove}
            isSendingToPayment={isSendingToPayment}
            isOpen={isOpen}
            showNotEnoughFundsWarning={showNotEnoughFundsWarning}
            onClose={onClose}
            onApprove={onApprove}
            onSendToPayment={onSendToPayment}
            onComplete={onComplete}
          />
        );
      }}
    </QuerySuspense>
  );
};
