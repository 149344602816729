import { createContext, type ReactNode, useContext } from 'react';

export const PageViewerContext = createContext<
  undefined | ((content: ReactNode | undefined) => void)
>(undefined);

export const usePageViewer = () => {
  const pageViewerContext = useContext(PageViewerContext);
  if (!pageViewerContext) {
    throw new Error('usePageViewer needs to used within a PageViewerContext');
  }
  return pageViewerContext;
};
