import { useState } from 'react';

import { type OrganisationReportingEntity } from '../../../types';

export type SearchableField = keyof OrganisationReportingEntity;

export const OrganisationReportingEntityListSearch = ({
  entities,
  searchableFields,
  children,
}: {
  entities: OrganisationReportingEntity[];
  searchableFields: SearchableField[];
  children: ({
    search,
    setSearch,
    filteredEntities,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredEntities: OrganisationReportingEntity[];
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState('');
  const simplifiedSearch = search.toLowerCase();

  return children({
    search,
    setSearch,
    filteredEntities: entities
      .filter((entity) =>
        searchableFields
          .map((field) => entity[field].toString().toLowerCase())
          .some((entityField) => entityField.includes(simplifiedSearch)),
      )
      .sort((a, b) => a.name.localeCompare(b.name)),
  });
};
