import * as R from '@dev-spendesk/general-type-helpers/Result';
import { fromNumber, type MonetaryValue, subtract, toNumber } from 'ezmoney';
import isFinite from 'lodash/isFinite';
import truncate from 'lodash/truncate';

import type * as DraftInvoiceRequestValidation from './draftInvoiceRequestValidation';
import type * as DraftPaymentSchedule from './draftPaymentSchedule';
import type * as InvoiceRequest from './invoiceRequest';

export type Entity = Omit<
  UndefinableExceptFor<
    InvoiceRequest.Entity,
    | 'id'
    | 'customFieldsAssociations'
    | 'currency'
    | 'description'
    | 'invoiceDetailsId'
    | 'originalAmountInCompanyCurrency'
    | 'paymentMethod'
    | 'status'
    | 'requesterId'
  >,
  'paymentSchedule'
> & {
  paymentSchedule: DraftPaymentSchedule.Entity;
};

export const paymentReferenceMaxLength = 20;

export const canChangeSupplier = (draftInvoiceRequest: Entity): boolean =>
  draftInvoiceRequest.purchaseOrderId === undefined;

export const canEditCurrency = (draftInvoiceRequest: Entity): boolean =>
  draftInvoiceRequest.purchaseOrderId === undefined;

export const canEditAmount = (draftInvoiceRequest: Entity): boolean => {
  return !hasAppliedCredit(draftInvoiceRequest);
};

export const convertAmountToMonetaryValue = ({
  amount,
  currency,
}: Entity): MonetaryValue | undefined =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  isFinite(amount) ? fromNumber(amount!, currency, 2) : undefined;

export const getTotalAmount = (
  invoiceRequest: Entity,
): MonetaryValue | undefined => {
  const { appliedCredit, currency } = invoiceRequest;
  const subtotal = convertAmountToMonetaryValue(invoiceRequest);

  if (!subtotal) {
    return undefined;
  }

  if (appliedCredit) {
    const subtotalWithCreditAppliedAmount = subtract(subtotal, appliedCredit);
    return toNumber(subtotalWithCreditAppliedAmount) > 0
      ? subtotalWithCreditAppliedAmount
      : fromNumber(0, currency, 2);
  }

  return subtotal;
};

export const hasPaymentsToSchedule = (invoiceRequest: Entity): boolean => {
  const totalAmount = getTotalAmount(invoiceRequest);

  // we do not have any payment schedule when
  // - a credit is applied and the amount is 0
  // - the payment method is direct debit (ie. paid outside spendesk)
  return (
    invoiceRequest.paymentMethod === 'wireTransfer' &&
    totalAmount !== undefined &&
    totalAmount.amount > 0
  );
};

export const hasAppliedCredit = (
  invoiceRequest: Entity,
): invoiceRequest is WithRequired<
  Entity,
  'appliedCredit' | 'appliedCreditNoteIds'
> =>
  Boolean(invoiceRequest.appliedCredit) &&
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  toNumber(invoiceRequest.appliedCredit!) > 0 &&
  Boolean(invoiceRequest.appliedCreditNoteIds) &&
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  invoiceRequest.appliedCreditNoteIds!.length > 0;

export const canApplyCredit = (invoiceRequest: Entity): boolean =>
  invoiceRequest.paymentMethod === 'wireTransfer';

export const updatePaymentScheduleOnAmountChange = (
  invoiceRequest: Entity,
  newAmount: number | undefined,
): Entity['paymentSchedule'] => {
  // We only update the payment schedule if there is only one payment; since the amount of this single payment must match the amount of the invoice request
  return invoiceRequest.paymentSchedule.length !== 1
    ? invoiceRequest.paymentSchedule
    : [{ ...invoiceRequest.paymentSchedule[0], amount: newAmount }];
};

export const updateAppliedCreditOnCurrencyChange = (
  newCurrency: string,
): MonetaryValue => {
  // The applied credit is always 0 since we cannot edit the currency if credit > 0, but the conversion to the new currency is needed to be able to some calculation like `Money.subtract(invoice.amount - invoice.appliedCredit)`
  return fromNumber(0, newCurrency, 2);
};

export const isEligibleToSepa =
  (companyCurrency: string) =>
  (draft: Entity): boolean =>
    companyCurrency === 'EUR' &&
    draft.currency === 'EUR' &&
    draft.paymentMethod === 'wireTransfer';

export const validateWith =
  (companyCurrency: string) =>
  async (
    draft: Entity,
    validationFunction: (
      draft: Entity,
    ) => Promise<DraftInvoiceRequestValidation.Errors>,
  ): Promise<
    R.Result<
      {
        reason: 'ValidationError';
        error: DraftInvoiceRequestValidation.Errors;
      },
      InvoiceRequest.Entity
    >
  > => {
    const errors = await validationFunction(draft);
    return Object.keys(errors).length > 0
      ? R.toFailure({ reason: 'ValidationError', error: errors })
      : R.toSuccess(toInvoiceRequest(companyCurrency)(draft));
  };

// Theoretically this function should be private, use validateWith instead
// it is exported for convenience because sometimes we deal with an already validated draft (cf submit function of formik that has already run the validation)
export const toInvoiceRequest =
  (companyCurrency: string) =>
  (validatedDraftInvoiceRequest: Entity): InvoiceRequest.Entity => ({
    id: validatedDraftInvoiceRequest.id,
    appliedCredit: validatedDraftInvoiceRequest.appliedCredit,
    appliedCreditNoteIds: validatedDraftInvoiceRequest.appliedCreditNoteIds,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    amount: validatedDraftInvoiceRequest.amount!,
    costCenterId: validatedDraftInvoiceRequest.costCenterId,
    currency: validatedDraftInvoiceRequest.currency,
    customFieldsAssociations:
      validatedDraftInvoiceRequest.customFieldsAssociations,
    description: validatedDraftInvoiceRequest.description,
    dueDate: validatedDraftInvoiceRequest.dueDate,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    emissionDate: validatedDraftInvoiceRequest.emissionDate!,
    originalAmountInCompanyCurrency:
      validatedDraftInvoiceRequest.originalAmountInCompanyCurrency,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    invoiceNumber: validatedDraftInvoiceRequest.invoiceNumber!,
    invoiceDetailsId: validatedDraftInvoiceRequest.invoiceDetailsId,
    paymentMethod: validatedDraftInvoiceRequest.paymentMethod,
    paymentReference: isEligibleToSepa(companyCurrency)(
      validatedDraftInvoiceRequest,
    )
      ? truncate(validatedDraftInvoiceRequest.paymentReference, {
          length: paymentReferenceMaxLength,
        })
      : undefined,
    paymentSchedule: hasPaymentsToSchedule(validatedDraftInvoiceRequest)
      ? (validatedDraftInvoiceRequest.paymentSchedule as InvoiceRequest.Entity['paymentSchedule'])
      : [],
    paymentDate:
      validatedDraftInvoiceRequest.paymentMethod === 'directDebit'
        ? validatedDraftInvoiceRequest.paymentDate
        : undefined,
    purchaseOrderId: validatedDraftInvoiceRequest.purchaseOrderId,
    status: validatedDraftInvoiceRequest.status,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    supplierId: validatedDraftInvoiceRequest.supplierId!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    supplierName: validatedDraftInvoiceRequest.supplierName!,
    teamId: validatedDraftInvoiceRequest.teamId,
    requesterId: validatedDraftInvoiceRequest.requesterId,
  });
