import UnexpectedError from './UnexpectedError';

import './UnexpectedErrorContainer.scss';

export const UnexpectedErrorContainer = () => {
  return (
    <div className="UnexpectedErrorContainer">
      <UnexpectedError />
    </div>
  );
};
