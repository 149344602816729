import { type MonetaryValue } from 'ezmoney';
import React from 'react';

import WireTransferApproverModal from './WireTransferApproverModal';
import WireTransferControllerModal from './WireTransferControllerModal';

type Props = {
  amount: MonetaryValue;
  count: number;
  isApproving: boolean;
  canApprove: boolean;
  isSendingToPayment: boolean;
  isOpen: boolean;
  showNotEnoughFundsWarning: boolean;
  onClose: () => void;
  onApprove: () => void;
  onSendToPayment: (options?: { onSuccess: () => void }) => void;
  onComplete: () => void;
};

const WireTransferModal = ({
  amount,
  count,
  isApproving,
  canApprove,
  isSendingToPayment,
  isOpen,
  showNotEnoughFundsWarning,
  onClose,
  onApprove,
  onSendToPayment,
  onComplete,
}: Props) => {
  if (canApprove) {
    return (
      <WireTransferApproverModal
        amount={amount}
        count={count}
        showNotEnoughFundsWarning={showNotEnoughFundsWarning}
        isApproving={isApproving}
        onClose={onClose}
        onApprove={onApprove}
        onComplete={onComplete}
        isOpen={isOpen}
      />
    );
  }
  return (
    <WireTransferControllerModal
      amount={amount}
      count={count}
      showNotEnoughFundsWarning={showNotEnoughFundsWarning}
      isSendingToPayment={isSendingToPayment}
      isOpen={isOpen}
      onClose={onClose}
      onSendToPayment={onSendToPayment}
      onComplete={onComplete}
    />
  );
};

export default WireTransferModal;
